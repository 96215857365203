import {
    Component,
    ElementRef,
    Input,
    OnChanges,
} from '@angular/core';
import { Button } from './button';


@Component({
               selector:  'outlined-button, button[flat-button]',
               template:  `
                              <button #button
                                      class="mdc-button mdc-button--outlined"
                                      [disabled]="disabled"
                              >
                                  <div class="mdc-button__ripple"></div>
                                  <icon *ngIf="!leadingIcon.isEmpty()" class="mdc-button__icon"
                                        [icon]="leadingIcon"
                                  ></icon>
                                  <span class="mdc-button__label">
                                        <ng-content></ng-content>
                                    </span>
                                  <icon *ngIf="!trailingIcon.isEmpty()" class="mdc-button__icon"
                                        [icon]="trailingIcon"
                                  ></icon>
                              </button>
                          `,
               styles: [require('./flat-button.scss')],
           })
export class OutlinedButton extends Button implements OnChanges {
    @Input() private leadingIcon: string = '';
    @Input() private trailingIcon: string = '';

    public constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    public ngOnChanges(changes: any): void {
        if (changes.disabled && changes.disabled.currentValue === true) {
            this.button.nativeElement.blur();
        }
    }

}
