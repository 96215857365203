import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { DialogBase } from './dialog-base';

@Component({
               selector: 'feature-explain-dialog',
               template: `
                             <div #dialog
                                  class="mdc-dialog dialog"
                                  role="alertdialog"
                                  aria-modal="true"
                             >
                                 <div class="mdc-dialog__container dialog__container">
                                     <div class="mdc-dialog__surface dialog__surface">
                                         <div class="mdc-dialog__title dialog__title">
                                             <icon class="dialog__title__close-icon" icon="close" (click)="close()"></icon>
                                         </div>
                                         <div class="mdc-dialog__content dialog__content">
                                             <div class="dialog__content__img">
                                                 <img [src]="img" alt="">
                                             </div>
                                             <div class="dialog__content__wave">
                                                 <img src="./assets/img/wave/incognito-explain.svg">
                                             </div>

                                             <div class="dialog__content__text">
                                                 <div class="dialog__content__title">
                                                     {{title}}
                                                 </div>

                                                 <div class="dialog__content__text" [innerHTML]="text"></div>
                                             </div>
                                         </div>
                                         <footer *ngIf="!buttonText.isEmpty()"
                                                 class="mdc-dialog__actions dialog__actions"
                                         >
                                             <raised-button premium
                                                            class="dialog__action"
                                                            (click)="onClick($event)"
                                             >
                                                 {{buttonText}}
                                             </raised-button>
                                         </footer>
                                     </div>
                                 </div>
                                 <div class="mdc-dialog__scrim"></div>
                             </div>`,
               styles: [require('./feature-explain-dialog.scss')],
           })

export class FeatureExplainDialog extends DialogBase {
    @Input() private title: string = '';
    @Input() private text: string = '';
    @Input() private img: string = '';
    @Input() private buttonText: string = '';
    @Output() private buttonAction = new EventEmitter();

    private onClick($event) {
        $event.stopPropagation();
        this.buttonAction.emit($event);
        this.close();
    }
}
