/**
 * Created by olifra on 10.04.2017.
 */

import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ShareModule } from '../../shared/shared.module';
import { OuterComponentModule } from '../outer-component';
import { LogoutGloassarRating } from './components/glossar/logout-gloassar-rating';
import { LogoutGlossarArticle } from './components/glossar/logout-glossar-article';
import { Logout } from './components/logout';
import { LogoutAwards } from './components/awards/logout-awards';
import { LogoutFooter } from './components/logout-footer';
import { LogoutGlossar } from './components/glossar/logout-glossar';
import { LogoutLovestory } from './components/lovestory/logout-lovestory';
import { LogoutSingleevents } from './components/singleevents/logout-singleevents';
import { LogoutTitleBox } from './components/logout-title-box';
import { LogoutSingleeventsItem } from './components/singleevents/logout-singleevents-item';

@NgModule({
              imports:         [
                  BrowserModule,
                  ShareModule,
                  ReactiveFormsModule,
                  RouterModule,
                  OuterComponentModule,
              ],
              declarations:    [
                  Logout,
                  LogoutTitleBox,
                  LogoutAwards,
                  LogoutFooter,
                  LogoutGlossar,
                  LogoutGlossarArticle,
                  LogoutGloassarRating,
                  LogoutLovestory,
                  LogoutSingleevents,
                  LogoutSingleeventsItem,
              ],
              exports:         [Logout],
          })
export class LogoutModule {
}
