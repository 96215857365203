import { Component } from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'schlagerherzen-startpage-banner',
               template:  `
                              <div class="banner" (click)="onClick()">
                                  <span class="banner__header">{{'BANNER.SCHLAGERHERZEN.STARTPAGE.TITLE'|translate}}</span>
                                  <img class="banner__schlagerherzen-logo" src="./assets/schlagerparadies/logo-schlagerherzen-red.svg" />
                                  <div class="banner__logos">
                                      <img class="banner__logos__bk" src="./assets/schlagerparadies/logo-bildkontakte-full.svg"/>
                                      <img class="banner__logos__rsp" src="./assets/schlagerparadies/logo-radio-schlagerparadies.svg"/>
                                  </div>
                                  <div class="banner__footer">
                                      <div class="banner__footer__container">
                                          <span class="banner__footer__container__caption">{{ 'BANNER.SCHLAGERHERZEN.STARTPAGE.MODERATION'|translate }}</span>
                                          <span class="banner__footer__container__moderator">{{ 'BANNER.SCHLAGERHERZEN.STARTPAGE.MODERATOR'|translate }}</span>
                                      </div>
                                      <raised-button class="banner__footer__cta">{{ 'BANNER.SCHLAGERHERZEN.STARTPAGE.CTA'|translate }}</raised-button>
                                  </div>
                                  <img class="banner__moderation" src="./assets/schlagerparadies/annemarie-small.png">
                              </div>
                          `,
               styles: [require('./schlagerherzen-startpage-banner.scss')],
           })
export class SchlagerherzenStartpageBanner {
    public constructor(private navigationService: NavigationService) {
    }

    onClick() {
        this.navigationService.navigateTo(Routes.SchlagerparadiesOverview);
    }
}