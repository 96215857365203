import {
    Component,
    ViewChild,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { ModalDialog } from '../../../service/global-modal.service';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

import { Modal } from '../modal/modal';

@Component({
            selector: 'bk-modal-premium-payment-error',
            template: `
                      <ng-container>
                          <modal #modal no-border>
                              <div class="premium-bought-content">
                                  <img src="./assets/img/picCrownError.svg" class="image">
                                  <div class="title">
                                      {{ 'MODAL.PREMIUM-PAYMENT-ERROR.TITLE'|translate:translateVars }}
                                  </div>
                                  <div class="text markdown p-nomargin checkbox-list" [innerHTML]="'MODAL.PREMIUM-PAYMENT-ERROR.TEXT'|translate:translateVars|markdown"></div>
                                  <raised-button class="action" (click)="goToPremiumOverview()">{{ 'MODAL.PREMIUM-PAYMENT-ERROR.ACTION'|translate }}</raised-button>
                              </div>
                          </modal>
                      </ng-container>
                      `,
            styles: [require('./bk-modal-premium-payment-error.scss')]
           })
export class BkModalPremiumPaymentError implements ModalDialog {
    @ViewChild('modal', { static: true }) private bkModal: Modal;

    static readonly typeId = 'BkModalPremiumPaymentError';

    get translateVars() {
        return {
        };
    }

    constructor(
        private navigationService :NavigationService
    ) {
    }

    open(config) {
        this.bkModal.open();
    }

    close() {
        this.bkModal.close();
    }

    get typeId(): string {
        return BkModalPremiumPaymentError.typeId;
    }

    goToPremiumOverview() {
        this.close();
        this.navigationService.navigateTo(Routes.PremiumOverview);
    }
}
