import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ImageSize,
    MatchingDecision,
    ProfileInfo,
} from 'BKModels';
import {
    MatchingService,
    StoreService,
} from 'BKService';
import { addLogoutEventListener } from 'BKUtils';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import { Modal } from '../modal/modal';
import { BkModalMatch } from './bk-modal-match';

@Component({
            selector: 'bk-missed-chance-matching',
            template: `
                          <ng-container>
                              <modal #modal
                                     no-border
                                     [closeIcon]="false"
                                     [backgroundClose]="true"
                              >
                                  <div class="content">
                                      <img-with-loading-indicator class="image" [src]="image"></img-with-loading-indicator>
                                      <div class="title markdown p-nomargin" [innerHTML]="'MODAL.MATCH.MISSED_CHANCE.TITLE'|translate|markdown"></div>
                                      <div class="text markdown p-nomargin" [innerHTML]="'MODAL.MATCH.MISSED_CHANCE.TEXT'|translate:translationVars|markdown"></div>
                                      <div class="setting">
                                          <checkbox [(value)]="dontShow">{{ 'MODAL.MATCH.MISSED_CHANCE.DONT_SHOW'|translate }}</checkbox>
                                      </div>
                                      <div class="actions">
                                          <raised-button (click)="matchYes()">{{ 'MODAL.MATCH.MISSED_CHANCE.ACTION.MATCH'|translate }}</raised-button>
                                          <flat-button (click)="close()">{{ 'MODAL.MATCH.MISSED_CHANCE.ACTION.CLOSE'|translate }}</flat-button>
                                      </div>
                                  </div>
                              </modal>
                          </ng-container>
                      `,
            styles: [require('./bk-missed-chance-matching.scss')]
           })
export class BkMissedChanceMatching implements ModalDialog {
    @ViewChild('modal', { static: true }) private modal: Modal;

    private user: ProfileInfo;

    static readonly typeId = 'BkMissedChanceMatching';

    get dontShow(): boolean {
        return this.storeService.matchingSecondChanceDontShow.getValues();
    }

    set dontShow(value: boolean) {
        this.storeService.matchingSecondChanceDontShow.next(value);
    }

    open(config) {
        if (this.dontShow) return;

        if (!config?.user) throw 'Missing user config for BkModalMatch';

        this.user = config?.user;

        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    get typeId(): string {
        return BkMissedChanceMatching.typeId;
    }

    get translationVars() {
        return {
            username: this.user?.nickname ?? ''
        };
    }

    get image(): string {
        if (!this.user) return "";

        return this.user.getProfilePictureUrl(ImageSize['285x285']);
    }

    matchYes() {
        this.matchingService.setDecision(this.user, MatchingDecision.Yes);
        this.matchingService.removeMatchedItemFromQueue(this.user);
        this.user.decisionOwner = MatchingDecision.Yes;
        this.close();
        this.globalModalService.open(BkModalMatch.typeId, { user: this.user });
    }

    constructor(
        private matchingService: MatchingService,
        private globalModalService: GlobalModalService,
        private storeService: StoreService
    ) {}
}
