import {
    Injectable,
    OnDestroy,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CountrySelectService implements OnDestroy {
    private unsubscribe$ = new Subject();

    public static aZ: SelectItem[] = [
        {
            value: 'AL',
            name:  'Albanien',
        },
        {
            value: 'AND',
            name:  'Andorra',
        },
        {
            value: 'B',
            name:  'Belgien',
        },
        {
            value: 'BG',
            name:  'Bulgarien',
        },
        {
            value: 'BIH',
            name:  'Bosnien Herzegowina',
        },
        {
            value: 'BY',
            name:  'Weißrußland',
        },
        {
            value: 'CY',
            name:  'Zypern',
        },
        {
            value: 'CZ',
            name:  'Tschechien',
        },
        {
            value: 'DK',
            name:  'Dänemark',
        },
        {
            value: 'E',
            name:  'Spanien',
        },
        {
            value: 'EW',
            name:  'Estland',
        },
        {
            value: 'F',
            name:  'Frankreich',
        },
        {
            value: 'FIN',
            name:  'Finnland',
        },
        {
            value: 'FL',
            name:  'Liechtenstein',
        },
        {
            value: 'GB',
            name:  'Großbritannien',
        },
        {
            value: 'GBZ',
            name:  'Gibraltar',
        },
        {
            value: 'GR',
            name:  'Griechenland',
        },
        {
            value: 'H',
            name:  'Ungarn',
        },
        {
            value: 'HR',
            name:  'Kroatien',
        },
        {
            value: 'I',
            name:  'Italien',
        },
        {
            value: 'IRL',
            name:  'Irland',
        },
        {
            value: 'L',
            name:  'Luxemburg',
        },
        {
            value: 'LT',
            name:  'Litauen',
        },
        {
            value: 'LV',
            name:  'Lettland',
        },
        {
            value: 'MC',
            name:  'Monaco',
        },
        {
            value: 'MD',
            name:  'Moldavien',
        },
        {
            value: 'MK',
            name:  'Mazedonien',
        },
        {
            value: 'MT',
            name:  'Malta',
        },
        {
            value: 'N',
            name:  'Norwegen',
        },
        {
            value: 'NL',
            name:  'Niederlande',
        },
        {
            value: 'P',
            name:  'Portugal',
        },
        {
            value: 'RO',
            name:  'Rumänien',
        },
        {
            value: 'RSM',
            name:  'San Marino',
        },
        {
            value: 'RUS',
            name:  'Rußland',
        },
        {
            value: 'S',
            name:  'Schweden',
        },
        {
            value: 'SK',
            name:  'Slowakische Republik',
        },
        {
            value: 'SLO',
            name:  'Slowenien',
        },
        {
            value: 'TR',
            name:  'Türkei',
        },
        {
            value: 'UA',
            name:  'Ukraine',
        },
        {
            value: 'USA',
            name:  'USA - United States of America',
        },
        {
            value: 'YU',
            name:  'Jugoslawien',
        },
    ];

    public aZ = CountrySelectService.aZ;

    public frequent: SelectItem[] = [
        {
            value: 'D',
            name:  'Deutschland',
        },
        {
            value: 'A',
            name:  'Österreich',
        },
        {
            value: 'PL',
            name:  'Polen',
        },
        {
            value: 'CH',
            name:  'Schweiz',
        },
    ];

    public constructor(private translate: TranslateService) {
        this.translate.get('COUNTRY.OPTIONS.D')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((str) => {
                this.frequent[0].name = str;
            });
        this.translate.get('COUNTRY.OPTIONS.A')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((str) => {
                this.frequent[1].name = str;
            });
        this.translate.get('COUNTRY.OPTIONS.PL')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((str) => {
                this.frequent[2].name = str;
            });
        this.translate.get('COUNTRY.OPTIONS.CH')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((str) => {
                this.frequent[3].name = str;
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getNameFormShort(short: string): string {
        return this.frequent
                   .concat(CountrySelectService.aZ)
                   .filter(cur => cur.value === short)
                   .concat(this.getDefaultSelection())[0].name;
    }

    private getDefaultSelection() {
        const url = window.location.href;

        if (url.indexOf('localhost') != -1) return this.frequent[0];

        const adressArray = url.split('/')[0].split('.');
        switch (adressArray[2]) {
            case 'de':
            case 'en':
                return this.frequent[0];
            case 'at':
                return this.frequent[1];
            case 'pl':
                return this.frequent[2];
            case 'ch':
                return this.frequent[3];
            default:
                return this.frequent[0];
        }
    }
}
