
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
               selector: 'search-nickname',
               template: `
        <div class="search-nickname">
            <div class="search-nickname__input">
                <text-field [placeholder]="'SEARCH.NICKNAME' | translate"
                            [value]="value"
                            (valueChange)="valueChange.emit($event)"
                            leadingIcon="search-outline"
                ></text-field>
            </div>

            <icon-button icon="close" (click)="close()"></icon-button>
        </div>
    `,
               styles: [require('./search-nickname.scss')],
           })

export class SearchNickname implements OnInit {
    @Input() private value: string;
    @Output() private valueChange: EventEmitter<any> = new EventEmitter();

    @Output('close') private _close: EventEmitter<any> = new EventEmitter();

    public ngOnInit() {
        this.value = '';
    }

    private close() {
        this._close.emit();
    }

    private delete($event) {
        this.value = '';
        this.valueChange.emit('');
    }
}
