import { isNumeric } from 'rxjs/internal-compatibility';

export enum DurationUnit {
    Day, Week, Month, Year
}


export class Duration {
    constructor(public amount: number, public unit: DurationUnit) {
    }

    // eslint-disable-next-line getter-return
    public get days(): number {
        switch (this.unit) {
            case DurationUnit.Day:
                return this.amount;
            case DurationUnit.Week:
                return this.amount * 7;
            case DurationUnit.Month:
                return this.amount * 30;
            case DurationUnit.Year:
                return this.amount * 365;
        }
    }

    public get momentDurationUnit(): string {
        return Duration.momentDurationUnitMap[this.unit];
    }

    public get isEmpty(): boolean {
        return this.amount == 0;
    }

    private static momentDurationUnitMap: { [key in DurationUnit]: string } = {
        [DurationUnit.Day]:   'days',
        [DurationUnit.Week]:  'weeks',
        [DurationUnit.Month]: 'months',
        [DurationUnit.Year]:  'years',
    };

    public static parsePeriod(str: string = ''): Duration {
        str = str ? str : '';
        let parsing = true;
        let length: number = 0;
        let unit: DurationUnit = DurationUnit.Day;

        str.split('').forEach((value) => {
            if (parsing) {
                if (isNumeric(value)) {
                    length = length * 10 + parseInt(value);
                } else {
                    parsing = false;
                    switch (value) {
                        case 'D':
                            unit = DurationUnit.Day;
                            break;
                        case 'W':
                            unit = DurationUnit.Week;
                            break;
                        case 'M':
                            unit = DurationUnit.Month;
                            break;
                        case 'A':
                            unit = DurationUnit.Year;
                            break;
                    }
                }
            }
        });

        return new Duration(length, unit);
    }


    public get durationTranslationKey() {
        const key = ['DURATION'];

        switch (this.unit) {
            case DurationUnit.Day:
                key.push('DAY');
                break;
            case DurationUnit.Week:
                key.push('WEEK');
                break;
            case DurationUnit.Month:
                key.push('MONTH');
                break;
            case DurationUnit.Year:
                key.push('YEAR');
        }

        if(this.amount !== 1) key.push('MULTIPLE');
        return key.join('.')
    }
}
