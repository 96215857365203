import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
               selector: 'premium-landing-page-testimonials',
               template: `
                             <div class="premium-testimonials" *ngIf="testimonials.length">
                                 <div class="premium-testimonials__content_wrap">
                                     <div class="premium-testimonials__title">{{'PREMIUM_LANDINGPAGE.TESTIMONIALS.TITLE' | translate}}</div>
                                     <div class="premium-testimonials__container">
                                         <div class="premium-testimonials__entry" *ngFor="let testimonial of testimonials">
                                             <div class="premium-testimonials__entry__from">{{ testimonial.from | translate }}</div>
                                             <div class="premium-testimonials__entry__text">{{ testimonial.text | translate }}</div>
                                         </div>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-testimonials.scss')],
           })

export class PremiumLandingPageTestimonials {
    constructor(private translate: TranslateService) {
    }

    private get testimonials() {
        return [
            new Testimonial(
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_1.TITLE',
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_1.TEXT'
            ),
            new Testimonial(
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_2.TITLE',
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_2.TEXT'
            ),
            new Testimonial(
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_3.TITLE',
                'PREMIUM_LANDINGPAGE.TESTIMONIAL_3.TEXT'
            ),
        ];
    }

}

class Testimonial {
    constructor(public from: String, public text: String) {
    }
}