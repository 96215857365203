import { CurrentUser } from './user';

export interface Zone {
    name: string;
    state: boolean;
    refreshTime: number;
    isActiveForUser: (currentUser: CurrentUser) => boolean;
    dimension: [number, number][];
    sizeMapping: ZoneSize[]
}

export enum SlotCommandType {
    Define,
    Display,
    Destroy
}

export interface SlotCommand {
    slotId: string,
    slotName: string,
    type: SlotCommandType
}

export interface ZoneSize {
    screenDimension: [number, number],
    zoneDimension: [number, number]|any
}
