import { Component } from '@angular/core';
import { ProfileEditService } from '../../../../service/profile-edit.service';
import { AbstractLabel } from './abstract-label';

@Component({
               selector: 'movie-labels',
               template: `
                             <label-box [title]="'PROFILE_EDIT.MOVIES.TITLE'|translate" [pristine]="pristine"
                                        [allowExpandSelectables]="isSelectableExpandable"
                                        (select)="select($event)"
                                        (deselect)="deselect($event)"
                                        (revert)="loadData()"
                                        (save)="save()"
                                        (expandSelectables)="expandSelectables()"
                                        [selected-labels]="selectedLabels"
                                        [selectable-labels]="selectableLabels"
                             ></label-box>`,
           })
export class MovieLabel extends AbstractLabel {

    constructor(protected profileEditService: ProfileEditService) {
        super();
        this.loadData();
    }

    public loadData() {
        Promise.all([
                        this.profileEditService.getAllMovieLabels(),
                        this.profileEditService.getSelectedMovieLabels(),
                    ])
               .then(([labels, selectedLabels]) => {
                   this.labels = labels;
                   this.selectedLabels = selectedLabels;
                   this.selectableLabels = [];
                   this.updateSelectableLabels();
                   this.pristine = true;
               });
    }

    public save() {
        this.pristine = true;
        this.profileEditService.saveSelectedMovieLabels(this.selectedLabels).then(state => this.pristine = state);
        this.saveEvent.emit();
    }
}