import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { BkConfig } from 'BKConfig';
import { RssService } from 'BKService';
import { Article } from '../../../../service/rss/childs/rss-feed';

@Component({
               selector:  'logout-glossar',
               template:  `
                              <div class="logout-glossar" *ngIf="articles.length">
                                  <div class="logout-glossar__title">
                                      {{'LOGOUT.GLOSSAR.TITLE' | translate}}
                                  </div>
                                  
                                  <div class="logout-glossar__article-list">
                                      <div *ngFor="let article of articles" class="logout-glossar__article">
                                          <logout-glossar-article [article]="article"></logout-glossar-article>
                                      </div>
                                  </div>
                                  <div class="logout-glossar__button">
                                      <raised-button (click)="toGlossar()">{{'LOGOUT.GLOSSAR.BUTTON' | translate}}</raised-button>
                                  </div>
                              </div>
                          `,
               styles: [require('./logout-glossar.scss')],
           })

export class LogoutGlossar implements OnInit {
    @Input() private articleCount =3;
    private articles: Article[] = [];

    public constructor(private rssService: RssService) {
    }

    public ngOnInit(): void {
        this.rssService.glossary.getEntrys(this.articleCount, 'bildkontakte').then(articles => this.articles = articles);
    }


    public toGlossar() {
        window.open(BkConfig.glossaryUrl, '_blank');
    }

}
