import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SliderFormatter } from 'BKBaseClass';
import {
    CurrentUser,
    Gender,
    OrderReason,
    QuickSearchCriteria,
    SliderFormatterBase,
} from 'BKModels';
import * as cloneDeep from 'lodash.clonedeep';
import { PremiumService } from '../../../../service/premium.service';
import { CountrySelect } from '../../../../shared/mat-design/country-select/country-select';
import { Geolocation } from '../../../../shared/mat-design/geolocation/geolocation';
import { SearchModal } from '../search-modal/search-modal';

var scrollIntoView = require('scroll-into-view');

class DistanceFormatter extends SliderFormatter implements SliderFormatterBase {
    public constructor() {
        super();
    }

    public to(value: number): string {
        return `${super.to(value)} km`;
    }
}

@Component({
               selector:  'search-quick',
               template: `
                             <search-modal #modal [title]="'SEARCH.MODAL.QUICK_SEARCH' | translate"
                                           (save)="save()"
                                           [disable]="disabled"
                             >
                                 <div class="search-quick__content">
                                     <search-modal-box [title]="'SEARCH.EDIT_BOX.GENDER.LABEL' | translate" id="gender">
                                         <radio-panel border
                                                      desktop-horizontal
                                                      [options]="genderOptions"
                                                      [(ngModel)]="criteria.gender"
                                         ></radio-panel>
                                     </search-modal-box>
                                     <search-modal-box [title]="'SEARCH.EDIT_BOX.AGE_OF.LABEL' | translate" id="ageRange">
                                         <from-till [(value)]="criteria.ageRange"></from-till>
                                     </search-modal-box>

                                     <separator class="search-quick__separator"></separator>

                                     <search-modal-box id="country">
                                         <country-select #countrySelect [(value)]="country"></country-select>
                                     </search-modal-box>
                                     <search-modal-box id="geolocation">
                                         <geolocation #geolocationRef
                                                      [value]="geolocation"
                                                      (valueChange)="geolocation = $event"
                                                      [country]="criteria.country"
                                                      [showPremiumIcon]="!currentUser.isPremium()"
                                                      (focus)="focusGeolocation($event)"
                                         ></geolocation>
                                     </search-modal-box>

                                     <search-modal-box [title]="'SEARCH.EDIT_BOX.DISTANCE.LABEL' | translate"
                                                       [showPremiumLink]="!currentUser.isPremium()"
                                                       [orderReason]="OrderReason.SearchRadius"
                                     >
                                         <div *ngIf="!currentUser.isPremium()" class="distance">
                                             <div class="premium-area">
                                                 <div class="premium-area__mask premium-area__mask__left"></div>
                                                 <icon icon="crown-outline"></icon>
                                                 {{'SEARCH.QUICK_SEARCH.PREMIUM' | translate}}
                                                 <div class="premium-area__mask premium-area__mask__right"></div>
                                             </div>

                                             <range class="search-distance-range"
                                                    id="distance"
                                                    [config]="basicRange"
                                                    [(ngModel)]="criteria.distance"
                                                    [noMarginLeft]="true"
                                             ></range>
                                         </div>

                                         <range class="search-distance-range"
                                                *ngIf="currentUser.isPremium()"
                                                id="distance"
                                                [config]="premiumRange"
                                                [(ngModel)]="criteria.distance"
                                         ></range>

                                     </search-modal-box>
                                 </div>
                             </search-modal>
                         `,
               styles: [require('./search-quick.scss')],
           })

export class SearchQuick implements AfterViewInit {
    @ViewChild('modal', { static: true }) private modal: SearchModal;
    @ViewChild('countrySelect', { static: true }) private countrySelect: CountrySelect;
    @ViewChild('geolocationRef', { static: false }) private geolocationRef: Geolocation;
    @Input() private currentUser: CurrentUser = new CurrentUser();
    private _criteria: QuickSearchCriteria;
    private oldCriteria: QuickSearchCriteria;
    private OrderReason = OrderReason;

    private filterPips(value, type) {
        if (value <= 50) {
            return value % 10 == 0 ? 0 : -1;
        }
        if (value > 50 && value < 200) {
            return value % 25 == 0 ? 0 : -1;
        }
        return value % 50 == 0 ? 0 : -1;
    }

    private rangeConfig = {
        tooltips: [true],
        format:   new DistanceFormatter(),
        connect:  [true, false],
        snap:     true,
        pips:     {
            mode:    'steps',
            density: 3,
            filter:  this.filterPips,
        },
    };

    private get basicRange() {
        const rangeConfig = Object.assign({}, this.rangeConfig);
        const steps = [75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500];
        rangeConfig['range'] = this.generateRanges(steps);
        return rangeConfig;
    }

    private get premiumRange() {
        const rangeConfig = Object.assign({}, this.rangeConfig);
        const steps = [10, 20, 30, 40, 50, 75, 100, 125, 150, 200, 250, 300, 350, 400, 450, 500];
        rangeConfig['range'] = this.generateRanges(steps);
        return rangeConfig;
    }

    private generateRanges(steps: number[]): object {
        return steps.reduce((res, it, index, arr) => {
            const percentStep = Math.floor(100 / (arr.length - 1) * 100) / 100;
            if (index === 0) {
                res['min'] = it;
            } else if (index === arr.length - 1) {
                res['max'] = it;
            } else {
                res[`${percentStep * index}%`] = it;
            }
            return res;
        }, {});
    }

    @Input()
    private set criteria(val: QuickSearchCriteria) {
        this._criteria = cloneDeep(val);
        this.oldCriteria = val;
    }

    private get criteria() {
        return this._criteria;
    }

    @Output() private criteriaChange = new EventEmitter();

    private genderOptions = [
        {
            name:  this.translate.instant('SEARCH.GENDER.OPTIONS.M'),
            value: Gender.Male,
        }, {
            name:  this.translate.instant('SEARCH.GENDER.OPTIONS.W'),
            value: Gender.Female,
        }, {
            name:  this.translate.instant('SEARCH.GENDER.OPTIONS.B'),
            value: Gender.Both,
        },
    ];

    private get geolocation() {
        return this.criteria.geolocation;
    }

    private set geolocation(val) {
        if (typeof val == 'string') return;

        if (!val || (!val.geoId && !val.displayValue && !(val as any).value)) {
            this.criteria.geolocation = {
                geoId:        null,
                displayValue: null,
            };
        } else if (typeof val === 'object') {
            this.criteria.geolocation = {
                geoId:        val.geoId || (val as any).value,
                displayValue: val.displayValue,
            };
        }
    }

    private get country() {
        return this.criteria.country;
    }

    private set country(val) {
        this.criteria.country = val;
        if (this.geolocationRef) this.geolocationRef.clear();
    }

    constructor(private translate: TranslateService,
                private premiumService: PremiumService
    ) {
    }

    public ngAfterViewInit() {
        this.layout();
    }

    public show(key?) {
        this.modal.show();
        this.reloadData();
        this.layout().then(() => {
            if (key) {
                setTimeout(() => document.getElementById(key).scrollIntoView(true));
            }
        });
    }

    private save() {
        this.criteriaChange.emit(this.criteria);
    }

    private reloadData() {
        this.criteria = this.oldCriteria;
    }

    private layout(): Promise<any> {
        return Promise.all([this.countrySelect.layout(), this.geolocationRef.layout()]);
    }

    private get disabled(): boolean {
        return this.criteria.geolocation.geoId === null && this.criteria.geolocation.displayValue === null;
    }


    private focusGeolocation($event) {
        if (!this.currentUser.isPremium()) {
            this.premiumService.navigateToPremium(OrderReason.SearchRadius);
        }
    }

}
