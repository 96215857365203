/**
 * Created by olifra on 15.05.2017.
 */
import { getImageUrl } from 'BKUtils';

export enum ImageStatus {
    NotApproved,
    Ok,
    OkNotMainPic,
    OkButNoRating,
    OkMainPic = 5,
    RejectOld,
    OkButDeactivate = 7,
    OkNotMainPicButDeactivate,
    Reject,
}

/**
 * Object with all ImageSize
 {{string: string}}
 */
export enum ImageSize {
    '70x70'=    '70x70',
    '100x100'=  '100x100',
    '110x110'=  '110x110',
    '130x130'=  '130x130',
    '150x150'=  '150x150',
    '160x160'=  '160x160',
    '285x285'=  '285x285',
    '530x530'=  '530x530',
    '640x640'=  '640x640',
    '640x854'=  '640x854',
    '750x1000'= '750x1000',
    '851x1136'= '851x1136',
}

/**
 * Bilder Klasse
 */
export class Image {
    public description: string = '';

    public image: string = '';

    public main: boolean = false;

    public static Placeholder = new Image();

    /**
     * Status des Bildes
     * - 0 : Nicht freigegeben, noch in Bearbeitung
     * - 1 : Ok
     * - 2 : Ok, aber nicht als Hauptbild
     * - 3 : Ok, aber Bewertungen nicht möglich
     * - 6 : Bild wurde abgelehnt (Wird nicht mehr verwendet ist Altlast)
     * - 7 : Ok, aber vom user deaktiviert
     * - 8 : Wie 2, aber vom user deaktiviert
     * - 9 : Bild wurde abgelehnt
     *
     {ImageStatus}
     */
    public status: ImageStatus = ImageStatus.Ok;


    public static create(image: Image): Image {
        const res: Image = new Image();
        res.image = image.image;
        res.status = image.status;
        res.description = image.description;
        res.main = image.main;
        return res;
    }

    public static createMainImage(src: string): Image {
        const res: Image = new Image();
        res.image = src;
        res.status = ImageStatus.Ok;
        res.description = '';
        res.main = true;
        return res;
    }

    public static createMultiple(images: Image[] = []): Image[] {
        return images.map(image => Image.create(image));
    }

    public static getUrlArray(resolution: string, images: Image[], blur: boolean = false): string[] {
        return images.map((image, index) => index === 0 ? image.getUrl(resolution) : image.getUrl(resolution, blur));
    }

    public static getUrlFromString(resolution: string, img: string, blur: boolean= false): string {
        return getImageUrl(img, resolution, blur);
    }

    public getUrl(resolution: string, blur: boolean = false): string {
        return getImageUrl(this.image, resolution, blur);
    }

    public getUrlNextAvailableSize(w: number, h: number, blur: boolean = false): string {
        let resolution = ImageSize['851x1136'];
        for (let size in ImageSize) {
            if (!ImageSize.hasOwnProperty(size)) continue;
            let [sizeW, sizeH] = size.split('x').map(value => Number(value));
            if (sizeW > w && sizeH > h) {
                resolution = ImageSize[size];
                break;
            }
        }
        return this.getUrl(resolution, blur);
    }

    public isMainPicOk(): boolean {
        return this.isOk() || this.isOkButDeactivate() || this.isOkButNoRating();
    }

    public isNotApproved(): boolean {
        return this.status === ImageStatus.NotApproved;
    }

    public isOk(): boolean {
        return this.status === ImageStatus.Ok;
    }

    public isOkButDeactivate(): boolean {
        return this.status === ImageStatus.OkButDeactivate;
    }

    public isOkButNoRating(): boolean {
        return this.status === ImageStatus.OkButNoRating;
    }

    public isOkNotMainPic(): boolean {
        return this.status === ImageStatus.OkNotMainPic;
    }

    public isOkNotMainPicButDeactivate(): boolean {
        return this.status === ImageStatus.OkNotMainPicButDeactivate;
    }

    public isReject(): boolean {
        return (this.status === ImageStatus.Reject || this.status === ImageStatus.RejectOld);
    }

    /**
     * Returns true when image can be used as main image
     */
    public canChangeToMain(): boolean {
        return !this.main && this.isMainPicOk();
    }

    /**
     * Returns true when image can be hidden for other members
     */
    public canChangeToDeactivated(): boolean {
        return !this.main && !this.isReject() && !this.isOkButDeactivate() && !this.isOkNotMainPicButDeactivate() && !this.isNotApproved();
    }

    /**
     * Returns true when image can be made visible for other members again
     */
    public canChangeToActivated(): boolean {
        return !this.main && !this.isReject() && (this.isOkButDeactivate() || this.isOkNotMainPicButDeactivate());
    }

    /**
     * Returns true if image can be deleted
     */
    public canChangeToDeleted(): boolean {
        return !this.main;
    }

    public equals(other: Image): boolean {
        if (!other) return false;
        return other.image === this.image && other.status === this.status && other.description === this.description && other.main === this.main;
    }
}
