/**
 * Created by olifra on 12.06.2017.
 */
import {
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
               selector:      'singleevents-card',
               template: `
                             <card-title title="Singleevents">
                                 <div class="grid-container">
                                     <div *ngFor="let event of events" class="events row">
                                         <div class="events__item gr-4@tablet gr-9@mobile">
                                             <div class="events__item__title">
                                                 Eventtyp
                                             </div>
                                             <div class="events__item__text--eventtyp">
                                                 <!--{{event.eventtype | truncate: 45}}-->
                                                 {{event.eventtype}}
                                             </div>
                                         </div>
                                         <div class="events__item gr-4@tablet gr-3@mobile">
                                             <div class="events__item__title">
                                                 Ort
                                             </div>
                                             <div class="events__item__text">
                                                 {{event.place}}
                                             </div>
                                         </div>
                                         <div class="events__item gr-4@tablet gr-6@mobile">
                                             <div class="events__item__title">
                                                 Altersberereich
                                             </div>
                                             <div class="events__item__text">
                                                 {{event.agerange}}
                                             </div>
                                         </div>
                                         <div class="events__item gr-4@tablet gr-3@mobile">
                                             <div class="events__item__title">
                                                 Preis
                                             </div>
                                             <div class="events__item__text">
                                                 {{event.price}} €
                                             </div>
                                         </div>
                                         <div class="events__item gr-4@tablet gr-3@mobile">
                                             <div class="events__item__title">
                                                 Datum
                                             </div>
                                             <div class="events__item__text">
                                                 {{event.date}}
                                             </div>
                                         </div>
                                         <!--push-3@mobile-->
                                         <div class="events__item gr-4@tablet gr-6@mobile push-3@mobile">
                                             <flat-button class="events__item__button">
                                                 Zum Singlevent
                                             </flat-button>
                                         </div>
                                         <div class="gr-12">
                                             <separator></separator>
                                         </div>
                                     </div>
                                     <div class="row">
                                         <div class="gr-12">
                                             <div class="bk-home__card__content__link--center">
                                                 <arrow-link>Weitere Singleevents</arrow-link>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </card-title>
                         `,
               styles:     [require('./singleevents.scss')],
               encapsulation: ViewEncapsulation.None,
           })
export class SingleeventsCardComponent {
    @Input() private events: any[];
}
