/**
 * Created by olifra on 10.05.2017.
 */
import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { ResetState } from 'BKModels';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector: 'reset-change-sucessful',
               template: `
                   <div class="bk-reset-password__checked"></div>
                   <div class="bk-reset-password__wrapper--title">
                       <form-input-wrapper>
                           <p>{{'PASSWORD_RECOVERY.SUCESS' | translate}}</p>
                       </form-input-wrapper>
                   </div>
                   <div class="bk-reset-password__wrapper--title">
                       <form-input-wrapper>
                           <a bk-link [routerLink]="navigationService.getRoute(Routes.Login)">{{'GLOBAL.BACK_TO_LOGIN' |translate}}</a>
                       </form-input-wrapper>
                   </div>
               `,
               styles: [require('../container/password-recovery.scss')],
           })
export class ChangeSucessful {
    @Output() private setState: EventEmitter<any> = new EventEmitter<any>();
    private Routes = Routes;

    public constructor(private navigationService: NavigationService) {
    }


    private sendEmail() {
        this.setState.emit(ResetState.changeSuccessfull);
    }
}
