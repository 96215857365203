import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Disabled } from '../../base-classes/disabled';


@Component({
               selector:  'menu-item',
               template:  `
                            <li class="mdc-list-item menu-item" [class.mdc-list-item--disabled]="disabled" role="menuitem">
                                <span *ngIf="!iconName.isEmpty()" class="mdc-list-item__graphic">
                                    <icon [icon]="iconName"></icon>
                                </span>
                                <span class="mdc-list-item__text"><ng-content></ng-content></span>
                            </li>                          `,
               styles: [require('./menu-item.scss')],
           })
export class MenuItem extends Disabled {
    @Input() private iconName: string = '';

    public constructor(elementRef: ElementRef) {
        super(elementRef);
    }

}
