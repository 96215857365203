import {
    AfterViewChecked,
    Directive,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';

@Directive({
               selector: '[match-height]',
           })
export class MatchHeightDirective implements AfterViewChecked {
    @Input('match-height') private selector: any;

    public constructor(private el: ElementRef) {
    }

    public matchHeight(parent: HTMLElement, selector: string) {
        if (!parent) return;
        const children = parent.querySelectorAll(selector);


        if (!children) return;

        Array.from(children)
             .forEach((x: HTMLElement) => x.style.height = 'initial');

        const maxHeight = Array.from(children)
                               .map(x => x.getBoundingClientRect().height)
                               .reduce((prev, curr) => (curr > prev ? curr : prev), 0);

        Array.from(children)
             .forEach((x: HTMLElement) => x.style.height = `${maxHeight}px`);
    }

    public ngAfterViewChecked() {
        this.onResize();
    }

    @HostListener('window:resize')
    public onResize() {
        this.matchHeight(this.el.nativeElement, this.selector);
    }
}
