import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
} from '@angular/forms';
import {
    ApiService,
    CurrentUserService,
    SettingsService,
} from 'BKService';
import {
    PasswordValidator,
    required,
} from 'BKShared/Validator';
import { Logger } from 'BKUtils';
import { throwError as observableThrowError } from 'rxjs';

@Component({
               selector:  'settings-change-password',
               template:  `
                              <card-title>
                                  <div class="settings-change-password">
                                      <div class="settings-change-password__left">
                                          <form [formGroup]="passwordForm">
                                              <text-field class="settings-change-password__input"
                                                          [placeholder]="'SETTINGS.PASSWORD_CHANGE.OLD_PASSWORD' | translate"
                                                          formControlName="oldPassword"
                                                          type="password"
                                              ></text-field>

                                              <text-field class="settings-change-password__input"
                                                          [placeholder]="'SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD' | translate"
                                                          formControlName="newPassword"
                                                          type="password"
                                                          [valid]="valid('newPassword')"
                                                          [errorText]="errorText('newPassword')"
                                              ></text-field>
                                              <text-field class="settings-change-password__input"
                                                          [placeholder]="'SETTINGS.PASSWORD_CHANGE.NEW_PASSWORD_REPETITION' | translate"
                                                          formControlName="newPasswordRepetition"
                                                          type="password"
                                                          [valid]="valid('newPasswordRepetition')"
                                                          [errorText]="errorText('newPasswordRepetition')"
                                              ></text-field>
                                          </form>
                                      </div>

                                      <div class="settings-change-password__right">
                                          <div class="settings-change-password__box">
                                              <icon class="settings-change-password__box__icon" icon="info-outline"></icon>
                                              <div class="settings-change-password__box__text" [innerHTML]="'SETTINGS.PASSWORD_CHANGE.GUIDELINE' | translate | markdown"></div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="settings-change-password__footer">
                                      <raised-button [disabled]="passwordForm.invalid"
                                                     fit-content
                                                     class="settings-change-password__button"
                                                     (click)="changePassword()"
                                      >
                                          {{'SETTINGS.PASSWORD_CHANGE.CHANGE_PASSWORD' | translate}}
                                      </raised-button>
                                  </div>

                              </card-title>
                          `,
               styles: [require('./settings-change-password.scss')],
           })

export class SettingsChangePassword implements OnInit {
    private passwordForm: FormGroup;

    public constructor(private fb: FormBuilder, private api: ApiService, private settingsService: SettingsService) {
    }

    public ngOnInit() {
        const newPassword = new FormControl('', [PasswordValidator.validate, required]);
        this.passwordForm = this.fb
                                .group({
                                           oldPassword:           ['', [required]],
                                           newPassword,
                                           newPasswordRepetition: ['', [required, PasswordValidator.repetition(newPassword)]],
                                       });
    }

    private changePassword() {
        const oldPassword = this.passwordForm.controls.oldPassword.value;
        const newPassword = this.passwordForm.controls.newPassword.value;
        const newPasswordRepetition = this.passwordForm.controls.newPasswordRepetition.value;

        this.settingsService.changePassword(oldPassword, newPassword, newPasswordRepetition);
    }


    private errorText(controlName: string): string {
        const errors = this.passwordForm.controls[controlName].errors;
        return errors ? Object.keys(errors)[0] : '';
    }

    private valid(controlName: string): boolean {
        return !this.passwordForm.controls[controlName].errors || this.passwordForm.controls[controlName].pristine;
    }


}
