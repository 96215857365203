/**
 * Created by olifra on 14.06.2017.
 */

/**
 * Stellte den Service zur die Übersetzung zurverfügungen
 * und setzt die Sprache
 */
export class Translate {
    /**
     * Create an Array for the translationskeys of dialogs
     {string} key
     {string[]}
     */
    public static createDialogKey(key: string): string[] {
        return [
            `${key}.ACCEPT`,
            `${key}.CONTENT`,
            `${key}.REJECT`,
            `${key}.TITLE`,
        ];
    }

    /**
     * Create an Array For Multipletranslation if the only Differnt is a Number
     {string} key
     {number} size
     {string[]}
     */
    public static createKeyArray(key: string, from: number, to: number): string[] {
        const res: string[] = [];
        for (let i = from; i < to; i++) {
            res.push(`${key}.${i}`);
        }

        return res;
    }
}
