/**
 * Created by denjas on 11.04.2017.
 */
import {
    Component,
    Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * Input Wrapper
 */
@Component({
               selector: 'form-input-wrapper',
               template: `
        <div class="bk-input__wrapper">
            <label class="form-text bk-input__main-label">{{ label }}</label>
            <ng-content></ng-content>
        </div>
    `,
               styles:   [
                   `
        .bk-input__main-label {
            display: block;
            margin: 5px 0;
        }
    `,
               ],
           })
export class InputWrapper {
    @Input() private control: FormControl;

    @Input() private label: string = '';
}
