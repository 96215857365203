import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from 'BKService';
import { growShrink } from 'BKUtils';

@Component({
               selector: 'premium-landing-page-faq',
               template: `
                             <div class="premium-faq">
                                 <div class="premium-faq__content_wrap">
                                     <div class="premium-faq__title">{{'PREMIUM_LANDINGPAGE.FAQ.TITLE' | translate}}</div>
                                     <div class="premium-faq__container">

                                         <div
                                                 class="premium-faq__entry"
                                                 *ngFor="let item of faq"
                                                 [class.premium-faq__entry__open]="item.isOpen"
                                                 (click)="toogleOpenState(item)"
                                         >
                                             <div class="premium-faq__entry__question">
                                                 {{ item.question | translate }}

                                                 <icon
                                                         icon="arrow-down"
                                                         class="premium-faq__entry__arrow-down"

                                                 ></icon>
                                                 <icon
                                                         icon="arrow-up"
                                                         class="premium-faq__entry__arrow-up"
                                                 ></icon>

                                             </div>
                                             <div *ngIf="item.isOpen"
                                                  class="premium-faq__entry__answer"
                                                  [@growShrink]="false"
                                                  [innerHTML]="item.answer | translate | markdown"
                                             >
                                             </div>
                                         </div>

                                         <premium-support class="premium-faq__support"></premium-support>

                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-faq.scss')],
               animations: [growShrink],
           })

export class PremiumLandingPageFaq {

    _items = [];

    constructor(
        private translate: TranslateService,
        private trackingService: TrackingService,
    ) {

        this._items = [
            new FAQ(
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_1.TITLE',
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_1.TEXT',
                false,
                'FragenPremiumMachen',
            ),
            new FAQ(
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_2.TITLE',
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_2.TEXT',
                false,
                'FragenPremiumWerden',
            ),
            new FAQ(
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_3.TITLE',
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_3.TEXT',
                false,
                'FragenPremiumBezahlung',
            ),
            new FAQ(
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_4.TITLE',
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_4.TEXT',
                false,
                'FragenFunktionierenPremiumAbo',
            ),
            new FAQ(
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_5.TITLE',
                'PREMIUM_LANDINGPAGE.FAQ.ENTRY_5.TEXT',
                false,
                'FragenPremiumKuendigen',
            ),
        ];
    }

    private get faq() {
        return this._items;
    }

    private toogleOpenState(faq: FAQ) {
        this.trackingService.hit('Premium', 'KaufenSeite', faq.trackingLabel);


        faq.isOpen = !faq.isOpen;
        for (let item of this.faq) {
            if (item.isOpen && item !== faq) {
                item.isOpen = false;
            }
        }
    }
}


class FAQ {
    constructor(public question: string, public answer: string, public isOpen: Boolean, public trackingLabel: string) {
    }
}
