import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    CashReference,
    CurrentUser,
} from 'BKModels';
import { TrackingService } from 'BKService';
import { Tariff } from '../../../../../models/tariff';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { PaymentService } from '../../../../../service/payment.service';

@Component({
               selector:  'premium-booking-page-payment-cash',
               template:  `
                              <div class="premium-booking-page-payment-cash__title">{{'PREMIUM.BOOKING.PAYMENT.CASH.TITLE' | translate | replace:'price':cashPrice:true}}</div>
                              <div class="premium-booking-page-payment-cash__adress" [innerHTML]="'GLOBAL.ADDRESS' | translate | markdown"></div>

                              <div class="premium-booking-page-payment-cash__info__title">
                                  {{'PREMIUM.BOOKING.PAYMENT.CASH.INFO.TITLE' | translate}}
                              </div>
                              <div class="premium-booking-page-payment-cash__info">
                                  <div class="premium-booking-page-payment-cash__info__name">{{'PREMIUM.BOOKING.PAYMENT.CASH.INFO.INRENDED_PURPOSE' | translate}}</div>
                                  <div class="premium-booking-page-payment-cash__info__data">{{intendedPurpose}}</div>
                              </div>
                              <div class="premium-booking-page-payment-cash__info">
                                  <div class="premium-booking-page-payment-cash__info__name">{{'PREMIUM.BOOKING.PAYMENT.CASH.INFO.ID' | translate}}</div>
                                  <div class="premium-booking-page-payment-cash__info__data">
                                      <span class="premium-booking-page-payment-cash__info--bold">{{currenUser.id.toString()}}</span>
                                  </div>
                              </div>
                              <div class="premium-booking-page-payment-cash__info">
                                  <div class="premium-booking-page-payment-cash__info__name">{{'PREMIUM.BOOKING.PAYMENT.CASH.INFO.NICKNAME' | translate}}</div>
                                  <div class="premium-booking-page-payment-cash__info__data">{{currenUser.nickname}}</div>
                              </div>

                              <premium-booking-page-payment-price [price]="tariff.formattedPrice"></premium-booking-page-payment-price>

                              <div class="premium-booking-page-payment-cash__button">
                                  <raised-button (click)="clickButton()">{{'PREMIUM.BOOKING.PAYMENT.CASH.BUTTON' | translate}}</raised-button>
                              </div>

                          `,
               styles: [require('./premium-booking-page-payment-cash.scss')],
           })


export class PremiumBookingPagePaymentCash implements OnInit {
    @Input() private currenUser: CurrentUser;
    @Input() private tariff: Tariff;
    private reference: CashReference;

    public constructor(
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        this.paymentService.cashReference(this.currenUser.id, this.tariff.tariffId).then(ref => {
            this.reference = Object.assign({}, ref);
            this.reference.intendedPurpose = this.reference.intendedPurpose.match(/.{1,4}/g).join('-');
        });
    }

    public get intendedPurpose() {
        if (!this.reference) return '';

        return this.reference.intendedPurpose;
    }

    protected get cashPrice(): String {
        let price = this.tariff.price;

        if (price % 5 != 0) {
            let factor = Math.floor((price / 5));
            factor++;
            price = 5 * factor;
        }

        return price.toFixed(2).replace('.', ',');
    }


    private clickButton() {
        this.trackingService.hit('Premium', 'ZahlungsartSeite', 'KaufenGlueckfinden');
        this.paymentService
            .payCash(this.tariff)
            .then(() => this.navigationService.navigateTo(Routes.PremiumOverview));
    }


}
