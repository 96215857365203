import {
    Component,
    Directive,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { growShrink } from 'BKUtils';

@Component({
               selector: 'faq-item',
               template: `
                             <div
                                     class="faq__entry"
                                     [class.faq__entry__open]="open"
                                     (click)="click.emit()"
                             >
                                 <div class="faq__entry__question">
                                     {{ question | translate }}
                                     <icon
                                             icon="arrow-down"
                                             class="faq__entry__arrow-down"

                                     ></icon>
                                     <icon
                                             icon="arrow-up"
                                             class="faq__entry__arrow-up"
                                     ></icon>
                                 </div>
                                 <div *ngIf="open"
                                      class="faq__entry__answer"
                                      [@growShrink]="false"
                                 >
                                     <ng-content></ng-content>
                                 </div>
                             </div>
                         `,
               styles: [require('./faq.scss')],
               animations: [growShrink],
           })
export class FaqItem {
    @Input() public question: string;
    @Input() public open: boolean;

    @Output() public click = new EventEmitter();
}
