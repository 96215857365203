import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrderReason } from 'BKModels';
import {
    CurrentUserService, TrackingService,
} from 'BKService';
import { Product } from '../../../../../models/product';
import { NavigationService } from '../../../../../service/navigation.service';
import { PaymentService } from '../../../../../service/payment.service';
import { DurationUnit } from '../../../../../utils/duration';
import { PaypalLink } from '../../../../../utils/paypal-link';

@Component({
               selector: 'premium-landing-page-offers',
               template: `
                             <div #offerContainer class="premium-landing-page-offers">
                                 <div class="premium-landing-page-offers__content_wrap">
                                     <div class="premium-landing-page-offers__title">{{'PREMIUM_LANDINGPAGE.OFFERS.TITLE' | translate}}</div>
                                     <div class="premium-landing-page-offers__text">{{'PREMIUM_LANDINGPAGE.OFFERS.TEXT' | translate}}</div>
                                     <div class="premium-landing-page-offers__container">
                                         <div class="premium-landing-page-offers__offer" *ngFor="let offer of offers">
                                             <premium-landing-page-offer-box
                                                     [percentSaved]="getDiscount(offer)"
                                                     [duration]="offer.period"
                                                     [price]="offer.formattedPrice"
                                                     [pricePerMonth]="offer.formattedPricePerMonth"
                                                     [showPaypal]="isInPaypalTest"
                                                     (chooseTariff)="chooseTariff(offer)"
                                                     (choosePaypal)="choosePaypal(offer)"
                                             ></premium-landing-page-offer-box>
                                         </div>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-offers.scss')],
           })
export class PremiumLandingPageOffers implements OnInit {
    @ViewChild('offerContainer', { static: true }) private offerContainer: ElementRef;
    @Input() private orderReason: OrderReason = OrderReason.None;
    private offers: Product[] = [];
    private expensivePremiumProduct: Product;

    private isInPaypalTest: boolean = false;

    constructor(
        private currentUserService: CurrentUserService,
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this.paymentService
            .productList
            .then((value) => this.offers = value.premium);

        this.paymentService.mostExpensivePremiumProduct.then(res => {
            this.expensivePremiumProduct = res;
        });

        this.currentUserService.currentUser.then((currentUser) => {
            let testGroup = currentUser.testGroups.find(value => value.name.startsWith("premiumOfferPaypalButton"));
            this.isInPaypalTest = !!testGroup && testGroup.group === 1;
        });
    }

    public scrollToOffers() {
        this.offerContainer.nativeElement.scrollIntoView(true);
    }

    private chooseTariff(offer: Product) {
        if (offer.period.unit === DurationUnit.Month) {
            this.trackingService.hit('Premium', 'KaufenSeite', `${offer.period.amount}MonatPremium`);
        }
        this.navigationService.navigateToPurchasePage(offer.tariffId, this.orderReason);
    }

    private choosePaypal(offer: Product) {
        this.currentUserService.currentUser.then((currentUser) => {
            const link = new PaypalLink.Builder()
                .withActiveSubscription(currentUser.hasSubscription)
                .locale(this.translate.currentLang)
                .item(offer, currentUser)
                .name(this.translate.instant(offer.nameTranslationKey))
                .build();

            this.trackingService.hit('Premium', 'LandingPage', 'WeiterPayPal');
            window.open(link, '_blank');
        });
    }

    private getDiscount(offer) {
        if (this.expensivePremiumProduct) {
            return (1 - (offer.pricePerDay / this.expensivePremiumProduct.pricePerDay)) * 100;
        }
        return 0;
    }

}
