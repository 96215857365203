import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'separator',
               template:  `
        <div class="separator">
            <div class="separator__box"
                 [style.height]="thickness"
            ></div>
            <div *ngIf="!label.isEmpty()"
                 class="separator__label"
            >{{label}}
            </div>
            <div class="separator__box"
                 [style.height]="thickness"
            ></div>
        </div>`,
               styles: [require('./separator.component.scss')],
           })
export class Separator {
    @Input() private label: string = '';
    @Input() private thickness = '2px';

}
