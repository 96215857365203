import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'logout-gloassar-rating',
               template:  `
                          <div class="logout-gloassar-rating">
                              <icon *ngFor="let i of [1,2,3,4,5]" class="logout-gloassar-rating__icon" [icon]="getIcon(i)"></icon>
                              <div class="logout-gloassar-rating__text">
                                  {{rating}}
                              </div>
                          </div>
                          `,
               styles: [require('./logout-gloassar-rating.scss')],
           })

export class LogoutGloassarRating {
    @Input() rating: number;

    private getIcon(number: number): string {
        return number <= this.rating ? 'star' : 'star-border';
    }

}
