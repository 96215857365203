import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector: 'card-title',
               template: `
        <div class="mdc-card card">
            <div *ngIf="!title.isEmpty() || !infoIcon.isEmpty()" class="card__header">
                <div *ngIf="!title.isEmpty()" class="card__header__text">{{title}}</div>
                <icon *ngIf="!infoIcon.isEmpty()" class="card__header__info" [icon]="infoIcon" (click)="clickInfo.emit($event)"></icon>
            </div>
            <div class="card__content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
               styles: [require('./card-title.scss')],
           })
export class CardTitle {
    @Input() private title: string = '';
    @Input() private infoIcon: string = '';
    @Output() private clickInfo = new EventEmitter();
}
