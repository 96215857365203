import { Injectable } from '@angular/core';
import { ApiResponseFrame } from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiRequestService } from '../api-request.service';

export class MicrotransactionsApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public buy(itemType: any, expectedPrice: any, subjectId ?: number): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.microtrans.buy, {
                       body: {
                           itemType,
                           expectedPrice,
                           subjectId,
                       },
                   });
    }

    public currentCoins(): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.microtrans.currentCoins);
    }

    public history(limit: number, offset: number): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.microtrans.history, {
                       body: {
                           limit,
                           offset,
                       },
                   });
    }

    public list(): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.microtrans.list);
    }
}
