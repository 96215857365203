import { Component } from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector:  'footer-links',
               template:  `
                              <div class="footer-links__container">

                                  <div class="footer-links__entry">
                                      <span class="side-nav-footer__copyright">&copy;{{currentYear}} {{'BILDKONTAKTE.BK'|translate}}</span>
                                      |
                                  </div>

                                  <div class="footer-links__entry">
                                      <a class="footer-links__entry__link"
                                         href="https://www.bildkontakte.de/app/agb/"
                                         target="_blank"
                                      >
                                          {{'FOOTER.AGB'|translate}}
                                      </a> |
                                  </div>
                                  <div class="footer-links__entry">
                                      <a class="footer-links__entry__link"
                                         href="https://www.bildkontakte.de/app/privacy/"
                                         target="_blank"
                                      >
                                          {{'FOOTER.DATA_PROTECTION'|translate}}
                                      </a> |
                                  </div>

                                  <div class="footer-links__entry">
                                      <a class="footer-links__entry__link"
                                         href="https://www.bildkontakte.de/pops/impressum.php"
                                         target="_blank"
                                      >
                                          {{'FOOTER.IMPRESSUM'|translate}}
                                      </a> |
                                  </div>
                                  
                                  <div class="footer-links__entry">
                                      <a class="footer-links__entry__link"
                                         [routerLink]="navigationService.getRoute(Routes.CancelContracts)"
                                         target="_blank"
                                      >
                                          {{'FOOTER.CANCEL_CONTRACTS'|translate}}
                                      </a>
                                  </div>
                              </div>

                          `,
               styles: [require('./footer-links.scss')],
           })

export class FooterLinks {
    Routes = Routes;

    private currentYear: number = new Date().getFullYear();

    constructor(
        private navigationService: NavigationService
    ) {
    }
}
