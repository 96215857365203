import {
    Gender,
    OrderReason,
} from 'BKModels';
import { Log } from '../../../../../utils/log-decorator';

export class OrderReasonVariant {
    private readonly defaultPath = '/assets/img/premium-landingpage';

    constructor(private variant: string = "", private imageName: string = 'default') {
    }

    private defaultTranslationKey = 'PREMIUM_LANDINGPAGE.USP';

    public get title(): string {
        return this.createTranslationKey('TITLE');
    }

    public get text(): string {
        return this.createTranslationKey('TEXT');
    }

    public get usp(): string[] {
        return [
            this.createTranslationKey('USP_1'),
            this.createTranslationKey('USP_2'),
            this.createTranslationKey('USP_3'),
        ];
    }

    private createTranslationKey(key: string){
        let res =  `${this.defaultTranslationKey}`;
        if(!this.variant.isEmpty()) res = `${res}.${this.variant}`;
        return `${res}.${key}`
    }

    public image(gender: Gender, age: number): string {
        if(this.imageName === 'default') return `url('${this.defaultPath}/premium-landingpage-default.png')`;
        return `url(${this.imagePath(gender, age)}/${this.imageName})`;
    }

    public imagePath(gender: Gender, age: number): string {
        if (gender === Gender.Male) {
            if (age <= 40) {
                return `${this.defaultPath}/male-young`
            } else {
                return `${this.defaultPath}/male-old`
            }
        } else {
            if (age <= 40) {
                return `${this.defaultPath}/female-young`
            } else {
                return `${this.defaultPath}/female-old`
            }
        }
    }

}



