import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { FailedChargebackPaymentInfo } from '../../../service/premium.service';
import { DialogBox } from '../dialog/dialog-box';
import { growShrink } from 'BKUtils';
import * as moment from 'moment';

@Component({
               selector:  'bk-modal-id-in-progress',
               template:  `
                                  <dialog-box #dialog
                                              [title]="'NOTIFICATION.ID-IN-PROGRESS.TITLE'|translate"
                                              [acceptText]="'GLOBAL.CLOSE'|translate"
                                  >
                                      <div class="markdown first-last-nomargin" [innerHtml]="'ID_REQUESTED.IN_PROGRESS'|translate|markdown"></div>
                                  </dialog-box>
                          `,
               styles: [require('./bk-modal-chargeback-payment-failed.scss')],
               animations: [growShrink],
           })
export class BkModalIdInProgress implements ModalDialog {
    @ViewChild('dialog', { 'static': true }) private dialog: DialogBox;
    static readonly typeId = 'BkModalIdInProgress';
    public get typeId(): string {
        return BkModalIdInProgress.typeId;
    }

    public close(): void {
        this.dialog.close();
    }

    public open(config): void {
        this.dialog.open();
    }

}
