import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class AccountActivationNotification extends Notification {
    public notificationId = '922cc4ca-0860-4f47-82d1-cdca8c74afb8';

    public types = NotificationType.StartPage | NotificationType.Growl;
    public style = NotificationStyle.Success;
    public contentKey = 'NOTIFICATION.ACCOUNT_ACTIVATION.TEXT';
    public titleKey = 'NOTIFICATION.ACCOUNT_ACTIVATION.TITLE';

    public foldable = true;
}
