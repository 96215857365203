import {
    AfterContentInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUser,
    Language,
    ProfileInfo,
} from 'BKModels';
import {
    ApiService,
    CommunicationService,
    CountrySelectService,
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import {
    required,
    UsernameValidator,
} from 'BKShared/Validator';
import {
    Subscription,
} from 'rxjs';
import { HeaderService } from '../../../../../service/header.service';
import { ProfileEditRouteFragment } from '../../../../../service/navigation.service';
import {
    Cache,
    CacheKey,
} from '../../../../../utils/cache';
import { NavigationElement } from '../navigation-element/navigation-element';
import { NavigationList } from '../navigation-element/navigation-list';
import { ProfileEditNavigationService } from '../profile-edit-navigation.service';

var scrollIntoView = require('scroll-into-view');

export const maxLengthMap = {
    email:         50,
    personOfDream: 80,
    career:        35,
    like:          255,
    dislike:       255,
    description:   10000,
    aboutme:       255,
    motto:         70,
    hobbys:        80,
};

@Component({
               selector:  'profile-edit',
               template:  `
                              <subheader class="is-hidden-tablet">
                                  <navigation-list #navigationList></navigation-list>
                              </subheader>

                              <form [formGroup]="formular">
                                  <edit-images-box [attr.id]="ProfileEditRouteFragment.Images" class="profile-edit__list__card"></edit-images-box>
                                  <profile-edit-main [attr.id]="ProfileEditRouteFragment.MainData"
                                                     [group]="formular.controls.main"
                                                     (save)="saveBasicData()"
                                                     (reset)="reset()"
                                                     (focusInput)="closeHeader()"
                                                     class="profile-edit__list__card"
                                  ></profile-edit-main>
                                  <profile-edit-look [attr.id]="ProfileEditRouteFragment.Look"
                                                     [group]="formular.controls.look"
                                                     (save)="saveLooks()"
                                                     (reset)="reset()"
                                                     class="profile-edit__list__card"
                                  ></profile-edit-look>
                                  <profile-edit-search [attr.id]="ProfileEditRouteFragment.Search"
                                                       [group]="formular.controls.search"
                                                       [curProfile]="curProfile"
                                                       (save)="saveSearchSettings()"
                                                       (reset)="reset()"
                                                       (focusInput)="closeHeader()"
                                                       class="profile-edit__list__card"
                                  ></profile-edit-search>
                                  <food-labels class="profile-edit__labels" (save)="send()"></food-labels>
                                  <music-labels class="profile-edit__labels" (save)="send()"></music-labels>
                                  <movie-labels class="profile-edit__labels" (save)="send()"></movie-labels>
                                  <profile-edit-personal [attr.id]="ProfileEditRouteFragment.Personal"
                                                         [group]="formular.controls.personal"
                                                         (save)="savePersonalData()"
                                                         (reset)="reset()"
                                                         (focusInput)="closeHeader()"
                                                         class="profile-edit__list__card"
                                  ></profile-edit-personal>
                                  <authenticity-check [attr.id]="ProfileEditRouteFragment.Authenticity"></authenticity-check>
                              </form>
                          `,
               styles: [require('./profile-edit.scss')],
           })
export class ProfileEdit implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild('navigationList', { static: true }) private navigationList: NavigationList;
    private curProfile: ProfileInfo = new ProfileInfo();
    private curUser: CurrentUser = new CurrentUser();

    private currentUserSubscription: Subscription;
    private currentUserProfileInfoSubscription: Subscription;
    private routeFragmentSubscription: Subscription;
    private scrollRequestSubscription: Subscription;

    private formular: FormGroup;

    private ProfileEditRouteFragment = ProfileEditRouteFragment;

    public constructor(
        private currentUserService: CurrentUserService,
        private formBuilder: FormBuilder,
        private api: ApiService,
        private countryService: CountrySelectService,
        private router: Router,
        private translate: TranslateService,
        private communicationService: CommunicationService,
        private route: ActivatedRoute,
        private matchingService: MatchingService,
        private trackingService: TrackingService,
        private headerService: HeaderService,
        private profileEditNavigationService: ProfileEditNavigationService
    ) {
    }

    public ngAfterContentInit() {
        this.buildForm();
    }

    public ngOnInit() {
        this.scrollRequestSubscription = this.profileEditNavigationService.scrollRequest$.subscribe(
            to => this.scrollTo(to)
        );

        this.currentUserProfileInfoSubscription = this.currentUserService
            .currentUserProfileInfo
            .subscribe(cur => this.curProfile = cur);

        this.currentUserSubscription = this.currentUserService
            .currentUserObservable
            .subscribe(cur => this.curUser = cur);

        this.routeFragmentSubscription = this.route
            .fragment
            .subscribe(fragment => {
                setTimeout(() => {
                    const navigationElement = this.profileEditNavigationService.navigationElements.find(element => element.id === fragment);
                    if (navigationElement) this.profileEditNavigationService.requestScroll(navigationElement.id);
                    this.routeFragmentSubscription.unsubscribe();
                }, 100);
            });
    }

    public ngOnDestroy() {
        this.currentUserProfileInfoSubscription.unsubscribe();
        this.currentUserSubscription.unsubscribe();
        this.routeFragmentSubscription.unsubscribe();
        this.scrollRequestSubscription.unsubscribe();
    }

    private buildForm() {
        this.formular = this.formBuilder
                            .group({
                                       main:     this.buildMainForm(),
                                       look:     this.buildLookForm(),
                                       search:   this.buildSearchForm(),
                                       personal: this.buildPersonalForm(),
                                   });
    }

    private buildLookForm(): FormGroup {
        const lookValues = this.getLookValues();
        return this.formBuilder
                   .group({
                              size:      [lookValues.size],
                              figure:    [lookValues.figure],
                              eyecolor:  [lookValues.eyecolor],
                              haircolor: [lookValues.haircolor],
                          });
    }

    private buildMainForm(): FormGroup {
        const mainValue = this.getMainValues();
        return this.formBuilder
                   .group({
                              nickname:     [
                                  {
                                      value:    mainValue.nickname,
                                      disabled: true,
                                  },
                                  [UsernameValidator.username],
                                  [UsernameValidator.used(this.api)],
                              ],
                              birthdate:    [
                                  {
                                      value:    mainValue.birthdate,
                                      disabled: true,
                                  }, [required],
                              ],
                              relationship: [mainValue.relationship, [required]],
                              country:      [mainValue.country],
                              city:         [mainValue.city],
                          });
    }

    private buildPersonalForm(): FormGroup {
        const personalValue = this.getPersonalValue();
        const langForm = this.formBuilder
                             .group(personalValue.languages);

        return this.formBuilder
                   .group({
                              children:          [personalValue.children],
                              numOfChildren:     [personalValue.numOfChildren],
                              childrenWish:      [personalValue.childrenWish],
                              numOfChildrenWish: [personalValue.numOfChildrenWish],
                              career:            [personalValue.career],
                              like:              [personalValue.like],
                              dislike:           [personalValue.dislike],
                              description:       [personalValue.description],
                              aboutme:           [personalValue.aboutme],
                              motto:             [personalValue.motto],
                              hobbys:            [personalValue.hobbys],
                              smoker:            [personalValue.smoker],
                              languages:         langForm,
                          });
    }

    private buildSearchForm(): FormGroup {
        const searchValue = this.getSearchValue();
        return this.formBuilder
                   .group({
                              searchGender:  [searchValue.searchGender],
                              searchAge:     [searchValue.searchAge],
                              contactWish:   [searchValue.contactWish],
                              personOfDream: [searchValue.personOfDream],
                          });
    }

    private createRequestObject(): any {
        const req: any = {};
        const values = Object.values(this.formular.value)
                             .reduce((res, cur) => {
                                 Object.assign(res, cur);
                                 return res;
                             }, {});
        for (const key in values as any) {
            const cur = values[key];
            switch (key) {
                case 'searchAge':
                    req.ageFrom = cur[0];
                    req.ageTill = cur[1];
                    break;
                case 'birthDate': {
                    const pad = (num: number) => (num < 10 ? `0${num}` : num);
                    req.birthday = `${cur.getFullYear()}-${pad(cur.getMonth() + 1)}-${pad(cur.getDate())}`;
                    break;
                }
                case 'description':
                    req.about = cur;
                    break;
                case 'figure':
                    if (cur !== 0) req.figure = cur;
                    break;
                case 'country':
                    // do nothing
                    break;
                case 'city':
                    if (cur.hasOwnProperty('value')) {
                        req.geoId = cur.value;
                    }
                    break;
                case 'languages':
                    req[key] = Object.keys(cur)
                                     .reduce((res, i) => {
                                         if (cur[i]) {
                                             res.push(i);
                                         }
                                         return res;
                                     }, []);
                    break;
                default:
                    req[key] = cur.toString().replace(/\r\n/g, '\n');
            }
        }
        return req;
    }

    private getLookValues(): any {
        return {
            size:      this.curProfile.size,
            figure:    this.curProfile.figure,
            eyecolor:  this.curProfile.eyecolor,
            haircolor: this.curProfile.haircolor,
        };
    }

    private getMainValues(): any {
        return {
            nickname:     this.curProfile.nickname,
            birthdate:    this.curProfile.birthdate,
            relationship: this.curProfile.relationship,
            country:      this.curProfile.country,
            city:         this.curProfile.city,
        };
    }

    private getPersonalValue() {
        const languages = Object.values(Language)
                                .reduce((res, cur) => {
                                    res[cur] = this.curProfile.languages.indexOf(cur) !== -1;
                                    return res;
                                }, {});
        return {
            children:          this.curProfile.children,
            numOfChildren:     this.curProfile.numOfChildren,
            childrenWish:      this.curProfile.childrenWish,
            numOfChildrenWish: this.curProfile.numOfChildrenWish,
            career:            this.curProfile.career,
            like:              this.curProfile.like,
            dislike:           this.curProfile.dislike,
            description:       this.curProfile.description,
            aboutme:           this.curProfile.aboutme,
            motto:             this.curProfile.motto,
            hobbys:            this.curProfile.hobbys,
            smoker:            this.curProfile.smoker,
            languages,
        };
    }

    private getSearchValue() {
        return {
            searchGender:  this.curProfile.searchGender,
            searchAge:     [this.curProfile.ageFrom, this.curProfile.ageTill],
            contactWish:   this.curProfile.contactWish,
            personOfDream: this.curProfile.personOfDream,
        };
    }

    private reset() {
        this.formular.reset({
                                main:     this.getMainValues(),
                                look:     this.getLookValues(),
                                search:   this.getSearchValue(),
                                personal: this.getPersonalValue(),
                            });
        this.formular.markAsPristine();
    }

    private saveBasicData() {
        this.trackingService.hit('ProfilBearbeiten', 'Speichern', 'SektionHauptdaten');
        this.send();
    }


    private saveLooks() {
        this.trackingService.hit('ProfilBearbeiten', 'Speichern', 'SektionAussehen');
        this.send();
    }


    private saveSearchSettings() {
        this.trackingService.hit('ProfilBearbeiten', 'Speichern', 'SektionDuSuchst');
        this.send();
    }


    private savePersonalData() {
        this.trackingService.hit('ProfilBearbeiten', 'Speichern', 'SektionPersoenliches');
        this.send();
    }

    private send() {
        const main: FormGroup = this.formular.controls.main as FormGroup;
        if (this.formular.dirty) {
            this.formular.markAsPristine();
            this.currentUserService.changeProfileInfoBundle(this.createRequestObject()).then(() => {
                    this.communicationService.growl.positive(this.translate.instant('PROFILE.CHANGE.SUCCESS'));
                    this.matchingService.reload();
                    Cache.getInstance().reload(CacheKey.SelectedUser);
                })
                .catch(() => {
                    this.communicationService.growl.negative(this.translate.instant('PROFILE.CHANGE.FAILED'));
                });

        }

        Cache.getInstance().clear();
    }

    private closeHeader() {
        this.headerService.hideExtraContent();
    }

    private startScrolling() {
        this.profileEditNavigationService.scrollState(true);
    }

    private endScrolling(type) {
        if (type === 'complete') this.profileEditNavigationService.scrollState(false);
    }

    private scrollToNavigationElement(item: NavigationElement) {
        this.startScrolling();

        item.select = true;
        scrollIntoView(
            item.element,
            {
                align: {
                    top:       0,
                    topOffset: this.navigationList.headerHeight,
                },
            },
            (type) => this.endScrolling(type),
        );
    }

    private scrollTo(section: ProfileEditRouteFragment) {
        const item = this.profileEditNavigationService.navigationElements.find(value => value.id === section);
        if (item) this.scrollToNavigationElement(item);
    }
}
