/* eslint-disable import/no-extraneous-dependencies */
/*
 * Angular bootstraping
 */
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { bootloader } from '@angularclass/hmr';
import { Logger } from 'BKUtils';
import 'hammerjs';

/*
 * App Module
 * our top level module that holds all of our components
 */
import { AppModule } from './app/app.module';

/*
 * Bootstrap our Angular app with a top level NgModule
 */

// eslint-disable-next-line
export function main(): Promise<any> {
    return platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch(err => Logger.error(err));
}

// needed for hmr
// in prod this is replace for document ready
bootloader(main);
