import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    Cacheable,
    CacheKey,
} from '../../../utils/cache';
import {
    Article,
    RssFeed,
} from './rss-feed';

/**
 * Service that handel the magazin
 */
@Injectable()
export class MagazineRss extends RssFeed {

    @Cacheable(CacheKey.Magazin)
    public getEntrys(count: number): Promise<Article[]> {
        return this.httpClient
                   .get(BkConfig.magazinUrl, { responseType: 'text' })
                   .toPromise()
                   .then((cur: any) => this.parseRss(cur, count));
    }

    private parseRss(rssFeed: string, count: number): Article[] {
        // Create a Parser
        const parser = new DOMParser();
        // Get all Itemsnodes
        const feed = parser.parseFromString(rssFeed, 'text/xml')
                           .querySelectorAll('item');
        // getList count number of items and parse they to Json
        return Array.from(feed)
                    .slice(0, count)
                    .map(this.parseToJson);
    }


    private parseToJson(item: Element): Article {
        const description = item.querySelector('description').textContent;
        const parser = new DOMParser();

        const article = new Article();
        article.title = item.querySelector('title').textContent;
        article.link = item.querySelector('link').textContent;
        article.image = parser.parseFromString(description, 'text/xml')
                              .querySelector('img')
                              .getAttribute('src')
                              .replace('80x80', '325x200');
        article.text = description.split('</div>')[1];



        return article;
    }
}
