import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { MatchingWishPartnerDataSource } from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import { NavigationService } from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

/**
 * MatchingWantToMeetComponent
 */
@Component({
               selector: 'matching-want-to-meet',
               template: `
                             <div class="bk-matching-want-to-meet">
                                 <div>
                                     <bk-matching-list-header [title]="'MATCHING.YOU_WANT.HEADER.TITLE' | translate">
                                         <span [innerHTML]="'MATCHING.YOU_WANT.HEADER.TEXT'| translate | markdown"></span>
                                     </bk-matching-list-header>

                                     <list-container #listContainer
                                                     [dataSource]="listDataSource"
                                     >
                                         <ng-template let-item>
                                             <list-item-selector two-columns-max
                                                                 [listItem]="item"
                                                                 (onOpenProfile)="onOpenProfile()"
                                                                 (onAddFavorite)="onAddFavorite()"
                                                                 (onOpenConversation)="onOpenConversation()"
                                                                 (onDeleteFavorite)="onDeleteFavorite()"
                                                                 (gotoPremium)="gotoPremium()"
                                             ></list-item-selector>
                                         </ng-template>
                                     </list-container>
                                 </div>
                             </div>

                         `,
           })
export class MatchingWantToMeet implements OnInit {
    private currentUser: CurrentUser = new CurrentUser();
    private listDataSource: MatchingWishPartnerDataSource;

    public constructor(
        private currentUserService: CurrentUserService,
        private matchingService: MatchingService,
        private trackingService: TrackingService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private dataSourceFactory: DataSourceFactory,
    ) {
    }

    public ngOnInit() {
        this.currentUserService
            .currentUser
            .then((currentUser) => this.currentUser = currentUser);

        this.dataSourceFactory.createMatchingWishPartnerDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });

    }

    private get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    private onOpenProfile() {
        this.trackingService.hit('Matching', 'Wunschpartner', 'ProfilAufgerufen');
    }

    private onOpenConversation() {
        this.trackingService.hit('Matching', 'Wunschpartner', 'Konversation');
    }

    private onAddFavorite() {
        this.trackingService.hit('Matching', 'Wunschpartner', 'FavoritHinzugefuegt');
    }

    private onDeleteFavorite() {
        this.trackingService.hit('Matching', 'Wunschpartner', 'FavoritGeloescht');
    }

    private gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.WishPartner);
    }
}
