import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Logger } from 'BKUtils';
import { EmojiPipe } from '../pipes/emoji.pipe';


@Directive({
               selector: '[contentEditable]',
               host:     {
                   contenteditable: 'true',
                   '[attr.data-placeholder]': 'placeholder',
                   '(input)': 'change($event)',
                   '[id]': '"contentEditable"',
                   // '[style.display]': '"inline-block"',
               },
           })
export class ContentEditableDirective {
    @Input() private placeholder: string = '';

    @Output() private textChange = new EventEmitter();

    private value: string = '';

    private get ele(): any {
        return this._elRef.nativeElement;
    }

    public constructor(
        private _emojiPipe: EmojiPipe,
        private _elRef: ElementRef,
    ) {
    }


    public addIcon(icon: string) {
        const emojis = this.parseEmojisBack(this.ele.innerHTML);
        const newLine = `${emojis}${icon}`;
        const html = this.parseHtml(newLine);
        this.ele.innerHTML = html;
        const pE = this.parseEmojisBack(html);
        const back = this.parseBack(pE);
        this.textChange.emit(back);
    }

    public reset() {
        this.ele.textContent = '';
        this.textChange.emit('');
    }

    private change(event: Event) {
        const emojis = this.parseEmojisBack(this.ele.innerHTML);
        this.changeText(emojis);
    }

    private changeText(emojis: string) {
        const html = this.parseHtml(emojis);
        const selection = this.getSelection();
        this.ele.innerHTML = html;
        this.setSelection(selection);
        const pE = this.parseEmojisBack(html);
        const back = this.parseBack(pE);
        this.textChange.emit(back);
    }

    private getChildContainer(ele, sel) {
        return sel.reduce((res, cur) => res.childNodes[cur], ele);
    }

    private getSelection() {
        const range = document.getSelection()
                              .getRangeAt(0);
        return {
            container: this.pathFromNode(range.startContainer, this.ele),
            offset:    range.startOffset,
        };
    }

    private parseBack(val: string): string {
        return this.parseEmojisBack(val)
                   .trim()
                   .replace(/<br(\s*)\/*>/ig, '\r\n')
                   .replace(/<div>/ig, '\r\n')
                   .replace(/<\/div>/ig, '');
    }

    private parseEmojisBack(val: string): string {
        let res = val;
        const tmp = res.match(/<img .*?>/gi) || [];
        tmp.forEach((cur) => {
            const matches = cur.match(/alt=["|'].*?["|']/);
            const name = matches[0].replace(/alt=["|']/, '')
                                   .replace(/["|']/, '');
            res = res.replace(cur, `${name}`);
        });
        return res;
    }

    private parseHtml(val: string): string {
        return this._emojiPipe.transform(val);
    }

    private pathFromNode(node, reference) {
        const traverse = (nodeVal, acc) => {
            if (nodeVal === reference) {
                return acc;
            }
            const parent = nodeVal.parentNode;
            const children = Array.from(parent.childNodes);
            const index = [...children].indexOf(nodeVal);
            return traverse(parent, [index, ...acc]);
        };
        return traverse(node, []);
    }

    private setSelection(selection) {
        const range = document.createRange();
        const sel = window.getSelection();
        const container = this.getChildContainer(this.ele, selection.container);
        const { offset } = selection;

        Logger.debug(container.length, offset);
        if (container.length < offset) {
            range.setStartAfter(container.nextSibling);
        } else {
            range.setStart(container, offset);
        }


        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
    }
}
