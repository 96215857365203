import { Component } from '@angular/core';

/**
 * Component for favorite-tabs
 */
@Component({
               selector:  'favorite',
               template:  `
                              <subheader class="is-hidden-tablet">
                                  <div class="title-bar">
                                      <div class="title">{{ 'LAYOUT.SIDE_NAV.FAVORITE'|translate }}</div>
                                      <separator thickness="1px"></separator>
                                  </div>
                                  <favorite-tabbar></favorite-tabbar>
                              </subheader>
                              <router-outlet></router-outlet>
                          `,
               styles: [require('./favorite.scss')]
           })
export class Favorite {
}
