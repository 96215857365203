import {
    Directive,
    EventEmitter,
    HostListener,
    Output,
} from '@angular/core';

@Directive({ selector: '[arrow-left][arrow-right]' })
export class ArrowLeftRightDirective {
    @Output('arrow-left') private arrowLeft: EventEmitter<any> = new EventEmitter();

    @Output('arrow-right') private arrowRight: EventEmitter<any> = new EventEmitter();

    @HostListener('document:keydown', ['$event'])
    private keydown($event: KeyboardEvent) {
        if ($event.key === 'ArrowLeft') {
            //       $event.preventDefault();
            this.arrowLeft.emit();
        } else if ($event.key === 'ArrowRight') {
            //       $event.preventDefault();
            this.arrowRight.emit();
        }
    }
}

@Directive({ selector: '[esc-key]' })
export class EscKeyDirective {
    @Output('esc-key') private escKey: EventEmitter<any> = new EventEmitter();

    @HostListener('document:keydown', ['$event'])
    private keydown($event: KeyboardEvent) {
        if (event.defaultPrevented) {
            return; // Do nothing if the event was already processed
        }


        if ($event.key === 'Escape') {
            //   $event.preventDefault();
            this.escKey.emit();
        }
    }
}
