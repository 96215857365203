import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import { ApiResponseFrame } from 'BKModels';
import { Endpoints } from 'BKUtils';
import { Observable } from 'rxjs';
import { ApiRequestService } from '../api-request.service';

interface PasswordPayload {
    password: string;
    passwordRepetition: string;
}

/**
 * Class for password recovery api
 */
export class PasswordApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public change(oldPassword: string, newPassword: string, passwordRepetition: string): Promise<ApiResponseFrame<any>> {
        const body = {
            'passwordChange[oldPassword]':                     oldPassword,
            'passwordChange[newPassword][password]':           newPassword,
            'passwordChange[newPassword][passwordRepetition]': passwordRepetition,
        };

        return this._apiRequestService.createPost(Endpoints.settings.password.change, { body });
    }

    /**
     * Change the password
     *
     {PasswordPayload} payload
     {string} token
     {OldObservable<ApiResponseFrame<any>>}
     */
    public changePasswordToken(payload: PasswordPayload, token: string): Promise<ApiResponseFrame<any>> {
        const body = {
            'password_recovery[token]':            token,
            'password_recovery[password][first]':  payload.password,
            'password_recovery[password][second]': payload.passwordRepetition,
            client_id:                             BkConfig.client.id,
        };

        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.password.recoveryFinish, { body });
    }

    /**
     * Sending the Email for the Passwordrecovery
     {string} email
     {OldObservable<ApiResponseFrame<any>>}
     */
    public sendEmail(email: string): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.settings.password.recovery,
                                                     {
                                                         query: {
                                                             email,
                                                             client_id: BkConfig.client.id,
                                                         },
                                                     });
    }
}
