import {
    Component,
    Input,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import { TrackingService } from 'BKService';
import { RadioUsersDataSource } from '../../../../models/list-data-sources';
import { PremiumService } from '../../../../service/premium.service';

@Component({
    selector:           'schlagerparadies-single-list',
    template:           `
                  <div>
                      <list-container [dataSource]="listDataSource"
                                      [singleFetch]="true"
                      >
                          <ng-template let-item>
                              <list-item-selector two-columns-max
                                                  [listItem]="item"
                                                  (onOpenProfile)="onOpenProfile()"
                                                  (onAddFavorite)="onAddFavorite()"
                                                  (onOpenConversation)="onOpenConversation()"
                                                  (onDeleteFavorite)="onDeleteFavorite()"
                                                  (gotoPremium)="gotoPremium()"
                              ></list-item-selector>
                          </ng-template>
                      </list-container>
                  </div>`,
           })
export class SchlagerparadiesSingleList {
    @Input() private listDataSource: RadioUsersDataSource;

    public constructor(
        private trackingService: TrackingService,
        private premiumService: PremiumService,
    ) {
    }

    private onOpenProfile() {
        this.trackingService.hit('Schlagerherzen', 'SingleListe', 'ProfilAufgerufen');
    }

    private onOpenConversation() {
        this.trackingService.hit('Schlagerherzen', 'SingleListe', 'Konversation');
    }

    private onAddFavorite() {
        this.trackingService.hit('Schlagerherzen', 'SingleListe', 'FavoritHinzugefuegt');
    }

    private onDeleteFavorite() {
        this.trackingService.hit('Schlagerherzen', 'SingleListe', 'FavoritGeloescht');
    }

    private gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.ProfileVisitor);
    }
}
