import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';

@Component({
               selector: 'lovestory-formular-item',
               template: `
        <div class="ls-form-item">
            <div class="ls-form-item__headline">
                <div class="ls-form-item__headline__circle"
                     [class.ls-form-item__headline__circle--active]="active"
                >
                    <ng-container *ngIf="!filled">{{number}}</ng-container>
                    <ng-container *ngIf="filled">
                        <icon class="ls-form-item__headline__circle__icon" icon="check"></icon>
                    </ng-container>
                </div>
                <div class="ls-form-item__headline__title"
                     [class.ls-form-item__headline__title--active]="active"
                >{{title}}
                </div>
            </div>

            <div *ngIf='active'
                 class="ls-form-item__content"
            >
                <form [formGroup]="group">
                    <div>{{'LOVESTORY.FORMULAR.ANSWER.YOU' | translate}}</div>
                    <text-area maxlength="255"
                               formControlName="yourAnswer"
                    ></text-area>
                    <div>{{'LOVESTORY.FORMULAR.ANSWER.PARTNER' | translate}}</div>
                    <text-area maxlength="255"
                               formControlName="partnerAnswer"
                    ></text-area>
                    <div class="ls-form-item__wrapper">
                        <raised-button class="ls-form-item__content__button"
                                       [disabled]="!bothFilled()"
                                       (click)="continue($event)"
                        > Weiter
                        </raised-button>
                    </div>
                </form>
            </div>
        </div>
    `,
               styles: [require('./lovestory-formular-item.scss')],
               providers: [
                   getValueAccessorProvider(LovestoryFormularItem),
               ],
           })

export class LovestoryFormularItem extends ValueAccessorBase<string[]> {
    @Output('setActive') private _setActive: EventEmitter<any> = new EventEmitter();

    @Input() private active: boolean = true;

    private filled: boolean = false;
    @Input() private group: FormGroup = new FormGroup({});
    @Input() private number: number;
    @Input() private title: string = '';

    private actived() {
        if (!this.active) {
            this._setActive.emit(this.number);
        }
    }

    private bothFilled(): boolean {
        return this.group.controls.yourAnswer.valid && this.group.controls.partnerAnswer.valid;
    }


    private continue($event) {
        this.filled = true;
        this._setActive.emit(this.number + 1);
    }
}
