import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Online,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import { translationKeyForOnline } from 'BKUtils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Online state component
 *
 *
 */
@Component({
               selector:  'online-state',
               template:  `
                              <div class="online-wrapper">
                                  <div *ngIf="hasOnlineState"
                                       class="online-state online-state__circle"
                                       [class.online-state__offline]="isOffline"
                                       [class.online-state__online]="isOnline"
                                       [class.online-state__away]="isAway"
                                       [class.online-state__online_mobile]="isOnlineMobile"
                                       [class.online-state__away_mobile]="isAwayMobile"
                                       [class.online-state__just_offline]="isRecentlyOnline"
                                  >
                                  </div>
                                  <span class="online-state__text" *ngIf="type == 'text'">{{ onlineStateKey|translate }}</span>
                              </div>
                          `,
               styles: [require('./online-state.scss')],
           })
export class OnlineState implements OnInit, OnDestroy {
    @Input() private state: Online;
    @Input() private type: string = 'text';
    private currentUser: CurrentUser;
    private unsubscribe$ = new Subject();

    private get hasOnlineState(): boolean {
        return (this.state ?? Online.NotAvailable) !== Online.NotAvailable;
    }

    private get onlineStateKey(): string {
        return translationKeyForOnline(this.state);
    }

    private get isOffline(): boolean {
        return this.state === Online.Offline;
    }

    private get isOnline(): boolean {
        return this.state === Online.Online;
    }

    private get isAway(): boolean {
        return this.state === Online.Away;
    }

    private get isOnlineMobile(): boolean {
        return this.state === Online.MobilOnline;
    }

    private get isAwayMobile(): boolean {
        return this.state === Online.MobilAway;
    }

    private get isRecentlyOnline(): boolean {
        return this.state === Online.RecentlyOnline;
    }

    constructor(private currentUserService: CurrentUserService) {
    }

    public ngOnInit() {
        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(cur => this.currentUser = cur);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
