import {
    Component,
    Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import { Product } from '../../../../../models/product';
import { Tariff } from '../../../../../models/tariff';
import { PaypalLink } from '../../../../../utils/paypal-link';

@Component({
               selector:  'premium-booking-page-payment-credit',
               template:  `

                              <div class="premium-booking-page-payment-credit__title">{{'PREMIUM.BOOKING.PAYMENT.CREDIT.TITLE' | translate}}</div>

                              <premium-booking-page-payment-price [price]="tariff.formattedPrice"></premium-booking-page-payment-price>

                              <div class="premium-booking-page-payment-credit__button">
                                  <raised-button premium (click)="onClick()">{{'PREMIUM.BOOKING.PAYMENT.CREDIT.BUTTON' | translate}}</raised-button>
                              </div>
                              <premium-booking-page-safe-paying></premium-booking-page-safe-paying>
                          `,
               styles: [require('./premium-booking-page-payment-credit.scss')],
           })

export class PremiumBookingPagePaymentCredit {
    @Input() private currentUser: CurrentUser;
    @Input() private tariff: Tariff;


    public constructor(
        private translate: TranslateService,
        private trackingService: TrackingService,
    ) {
    }

    private get paypalLink() {
        return new PaypalLink.Builder()
            .withActiveSubscription(this.currentUser.hasSubscription)
            .locale(this.translate.currentLang)
            .name(this.translate.instant(this.tariff.nameTranslationKey))
            .item(this.tariff, this.currentUser)
            .build();
    }

    private onClick() {
        this.trackingService.hit('Premium', 'ZahlungsartSeite', 'WeiterKreditkartenzahlung');
        window.open(this.paypalLink, '_blank');
    }
}
