import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Wird benutzt um in Routenhiterlegt zu werden für die
 * noch keine Seite besteht
 * b
 */
@Component({
               selector: 'workInProgress',
               template: `
        <h1>{{name}}</h1>
    `,
           })
export class WorkInProgress implements OnInit {
    private name: string = '';

    /**
     * constructor
     {ActivatedRoute} route
     */
    public constructor(private route: ActivatedRoute) {
    }

    public ngOnInit(): void {
        this.name = this.route.snapshot.url[0].path;
    }
}
