import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { AuthenticityCheckModal } from '../../authenticity-check-modal';

@Component({
               selector:  'authenticity-check-phone',
               template:  `
                              <authenticity-check-card-layout
                                      icon="phone"
                                      [title]="'ECHECK.PHONE.PHONECHECK'|translate"
                                      [description]="'ECHECK.PHONE.DESCRIPTION'|translate"
                                      [pendingDescription]="'ECHECK.STATE.PENDING.DESCRIPTION' | translate"
                                      [verifiedDescription]="'ECHECK.STATE.VERIFIED.DESCRIPTION' | translate"
                                      (clickButton)="modal.open()"
                                      [verifyText]="'ECHECK.PHONE.VERIFY_TEXT'|translate"
                                      [hasCheckDone]="currentUser.getEcheckMethode().tele"
                              ></authenticity-check-card-layout>


                              <authenticity-check-modal #modal
                                                        [title]="'ECHECK.PHONE.MODAL.TITLE' | translate"
                                                        [description]="'ECHECK.PHONE.MODAL.DESCRIPTION' | translate:param | markdown"
                              ></authenticity-check-modal>
                          
                          `,
               styles: [require('./authenticity-check-phone.scss')],
           })

export class AuthenticityCheckPhone {
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @ViewChild('modal', { static: true }) private modal: AuthenticityCheckModal;

    get param() {
        const id = this.currentUser.id || '';
        return {
            id: id.toString(),
        };
    }
}
