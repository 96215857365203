import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    BillingAddress,
    SepaAccount,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import { OpenInvoice } from '../../../../models/open-invoice';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { PaymentService } from '../../../../service/payment.service';
import { PaymentDebit } from '../../../../shared/mat-design/payment-debit/payment-debit';
import {
    Cache,
    CacheKey,
} from '../../../../utils/cache';

@Component({
               selector:  'settings-debit-again',
               template:  `
                              <subheader class="is-hidden-tablet">
                                  <settings-debit-again-header-bar></settings-debit-again-header-bar>
                              </subheader>

                              <settings-sepa-data
                                      [billingAddress]="billingAddress"
                                      [sepaAccount]="sepaAccount"
                                      [inProgress]="inProgress"

                                      [title]="'SETTINGS.DEBIT-AGAIN.TITLE' | translate"
                                      [text]="'SETTINGS.DEBIT-AGAIN.TEXT' | translate:{'price':invoice.formatPrice}"
                                      [saveTitle]="'SETTINGS.DEBIT-AGAIN.BUTTON' | translate"
                                      (save)="save($event)"
                              ></settings-sepa-data>

                          `,
               styles: [require('./settings-debit-again.scss')],
           })

export class SettingsDebitAgain implements OnInit {
    @ViewChild('debit', { static: true }) private debitForm: PaymentDebit;
    billingAddress: BillingAddress = new BillingAddress();
    sepaAccount: SepaAccount = new SepaAccount();
    invoice: OpenInvoice = new OpenInvoice();
    inProgress: boolean = false;


    public constructor(private paymentService: PaymentService, private navigationService: NavigationService, private currentUserService: CurrentUserService) {
    }

    public ngOnInit(): void {
        this.paymentService.billingAddress().then(billingAddress => this.billingAddress = billingAddress);
        this.paymentService.sepaAccount().then(sepaAccount => this.sepaAccount = sepaAccount);
        this.paymentService.openInvoices().then(invoice => this.invoice = invoice);
    }

    save({ billingAddress, sepaAccount }) {
        this.inProgress = true;
        this.paymentService.debitAgain(billingAddress, sepaAccount).then(() => {
            this.navigationService.navigateTo(Routes.PremiumOverview);
            this.currentUserService.loadCurrentUser();
            Cache.getInstance().delete(CacheKey.OpenInvoice);
        }).finally(() => this.inProgress = false);
    }
}
