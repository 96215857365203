/**
 * Created by olifra on 24.05.2017.
 */
import { Component } from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector:  'bk-invalid-link',
               template: `
                             <form-input-wrapper>
                                 <div class="bk-reset-password__wrapper--title">
                                     <h2>{{'PASSWORD_RECOVERY.INVALID_TOKEN.INVALID_TOKEN' | translate}}</h2>
                                     <p>{{'PASSWORD_RECOVERY.INVALID_TOKEN.REASONS_FOR_THAT' | translate}}</p>
                                 </div>
                             </form-input-wrapper>
                             <separator></separator>
                             <form-input-wrapper>
                                 <p>{{'PASSWORD_RECOVERY.INVALID_TOKEN.REASONS.0' | translate}}</p>
                             </form-input-wrapper>
                             <form-input-wrapper>
                                 <p>{{'PASSWORD_RECOVERY.INVALID_TOKEN.REASONS.1' | translate}}</p>
                             </form-input-wrapper>
                             <separator></separator>
                             <form-input-wrapper>
                                 <a bk-link [routerLink]="link">{{'GLOBAL.BACK_TO_RECOVERYMAIL' | translate}}</a>
                             </form-input-wrapper>
                         `,
               styles: [require('../container/password-recovery.scss')],
           })
export class InvalidLinkComponent {
    public constructor(private navigationService: NavigationService) {
    }

    private get link() {
        return this.navigationService.getRoute(Routes.SendRecoveryEmail)
    }
}
