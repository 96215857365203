import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';
import { Chat } from './chat';
import {
    PoBox,
    PoBoxAll,
    PoBoxUnread,
} from './po-box';
import { PoBoxHeader } from './po-box/po-box-header/po-box-header';
import { PoBoxSystem } from './po-box/po-box-system/po-box-system';

const poBoxRoutes: NgRoutes = [

    {
        path:     '',
        children: [{
            path:      '',
            component: PoBoxHeader,
            outlet:    'header',
        }, {
            path:      '',
            component: PoBox,
            children:  [
                {
                    path:      routeName(Routes.MessagesAll),
                    component: PoBoxAll,
                }, {
                    path:      routeName(Routes.MessagesUnread),
                    component: PoBoxUnread,
                }, {
                    path:      routeName(Routes.MessagesSystem),
                    component: PoBoxSystem,
                },
            ],
        },
        ],
    }, {
        path:     routeName(Routes.MessagesConversation),
        children: [
            { path: '', component: Chat },
            { path: '', component: InternDefaultHeader, outlet: 'header', data: { title: '' } },
        ],

    },
];

export const PoBoxRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(poBoxRoutes);
