/**
 * Created by olifra on 04.07.2017.
 */

import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { LovestoryModalItem } from './lovestory-modal-item/lovestory-modal-item';
import { LovestoryModal } from './lovestory-modal/lovestory-modal';


@NgModule({
              imports:      [
                  ShareModule,
              ],
              exports:      [LovestoryModal],
              declarations: [
                  LovestoryModal,
                  LovestoryModalItem,
              ],
              providers:    [],
          })
export class LovestoryModalModule {
}
