import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'chat-input-error-box',
               template:  `
                              <div class="chat-input-error-box">
                                  <div class="chat-input-error-box__text" [innerHTML]="content"></div>
                                  <div class="chat-input-error-box__content">
                                      <ng-content></ng-content>
                                  </div>
                              </div>
                          `,
               styles: [require('./chat-input-error-box.scss')],
           })

export class ChatInputErrorBox {
    @Input() private title: string = '';
    @Input() private content: string = '';
}
