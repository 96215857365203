import {
    Component,
    Input,
} from '@angular/core';
import {
    DetailedConversationUnreadCounts,
} from 'BKModels';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { PostboxState } from '../po-box-list/po-box-list';

@Component({
               selector: 'po-box-header-tabbar',
               template: `
                             <div class="po-box-chip-bar scroll-horizontal-hint-bp2-max">
                                 <navigation-chip (click)="clickChip(Routes.MessagesAll, 'Alle')" [select]="navigationService.isRouteActive(Routes.MessagesAll)">
                                     <span class="chip-content">
                                         {{ 'POST_BOX.HEADER.ALL' | translate }}
                                         <badge [count]="counts.nonSystem"></badge>
                                     </span>
                                 </navigation-chip>
                                 <navigation-chip (click)="clickChip(Routes.MessagesSystem, 'Benachrichtigungen')" [select]="navigationService.isRouteActive(Routes.MessagesSystem)">
                                     <span class="chip-content">
                                         {{ 'POST_BOX.HEADER.SYSTEM_MESSAGES' | translate }}
                                             <badge [count]="counts.system"></badge>
                                     </span>
                                 </navigation-chip>     
                                 <navigation-chip (click)="clickChip(Routes.MessagesUnread, 'Ungelesen')" [select]="navigationService.isRouteActive(Routes.MessagesUnread)">
                                     <span class="chip-content">
                                         {{ 'POST_BOX.HEADER.UNREAD' | translate }}
                                         <badge [count]="counts.total"></badge>
                                     </span>
                                 </navigation-chip>
                             </div>
                         `,
               styles: [require('./po-box-header-tabbar.scss')]
           })

export class PoBoxHeaderTabbar {
    @Input() private counts: DetailedConversationUnreadCounts = new DetailedConversationUnreadCounts()

    private Routes = Routes;

    constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {
    }

    private clickChip(route: Routes, label: string) {
        PostboxState.previousState.reset();
        this.trackingService.hit('Postfach', 'TabGeklickt', label);
        this.navigationService.navigateTo(route);
    }

}
