import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'footer-flag-item',
               template: `
        <li class="flag-item">
            <a [href]="link">
                <img class="flag-item__img" [src]="img">
            </a>
        </li>`,
               styles:   [
                   `
            .flag-item {
                margin: 10px;
            }

            .flag-item__img {
                width: 25px;
                display: block;
            }

        `,
               ],
           })

export class FooterFlagItem {
    @Input() private img: string = '';
    @Input() private link: string = '';
}
