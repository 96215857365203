import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

export interface AllowedValue<T> {
    value: T;
    textKey: string;
}

@Component({
               selector:  'profile-interview-single',
               template:  `
                   <div class="selection-container">
                       <chip *ngFor="let item of allowed" 
                             [select]="value === item.value"
                             (click)="valueChange.emit(item.value)">
                           {{ item.textKey|translate }}
                       </chip>
                   </div>
                          `,
               styles: [require('./profile-interview-single.scss')],
           })
export class ProfileInterviewSingle {
    @Input() allowed: AllowedValue<unknown>[] = [];
    @Input() value: any = null;
    @Output() valueChange = new EventEmitter();
}
