import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Menu } from 'app/shared/mat-design/menu/menu';
import { Conversation } from 'BKModels';
import { ModalService } from 'BKService';
import {
    Subject,
    Subscription,
} from 'rxjs';
import { ConversationService } from '../../../../../service/conversation.service';
import { MessageService } from '../../../../../service/message.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';

@Component({
               selector:  'po-box-list-chat',
               template: `
                             <div class="list-chats"
                                  [class.list-chats--unseen]="!chat.isSeen()"
                                  [class.list-chats--busy]="isBusy"
                             >
                                 <div class="list-chats__wrapper" (click)="clickItem()">
                                     <div class="list-chats__content">
                                         <po-box-list-chat-image [chat]="chat" (openProfile)="openProfile($event)"></po-box-list-chat-image>
                                         <po-box-list-chat-text [chat]="chat"></po-box-list-chat-text>
                                         <po-box-list-chat-info [chat]="chat"></po-box-list-chat-info>
                                         <div class="list-chats__content__menu">
                                             <menu directionX="left" icon="expand-more" tiny (click)="$event.stopPropagation()" #menu (onOpen)="onMenuOpen()">
                                                 <menu-item (click)="onMarkAsUnread()">
                                                     {{'POST_BOX.CONVERSATION.MENU.MARK_UNREAD' | translate}}
                                                 </menu-item>
                                                 <menu-item (click)="onDeleteConversation()">
                                                     {{'POST_BOX.CONVERSATION.MENU.DELETE' | translate}}
                                                 </menu-item>
                                                 <menu-item *ngIf="chat.pinned" (click)="onUnpinConversation()">
                                                     {{'POST_BOX.CONVERSATION.MENU.UNPIN' | translate}}
                                                 </menu-item>
                                                 <menu-item *ngIf="!chat.pinned" (click)="onPinConversation()">
                                                     {{'POST_BOX.CONVERSATION.MENU.PIN' | translate}}
                                                 </menu-item>
                                             </menu>
                                             <loading-indicator black class="list-chats__content__menu-loading" *ngIf="isBusy"></loading-indicator>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <dialog-box #lockedDialog [title]="'POST_BOX.DIALOG.LOCKED_USER.TITLE' | translate"
                                         [acceptText]="'GLOBAL.OK' | translate"
                             >
                                 {{"POST_BOX.DIALOG.LOCKED_USER.TEXT" | translate:chat.partner}}
                             </dialog-box>

                             <dialog-box #deleteDialog [title]="'POST_BOX.DIALOG.DELETE.TITLE' | translate"
                                         [rejectText]="'POST_BOX.DIALOG.DELETE.DELETE_ACTION' | translate"
                                         [acceptText]="'GLOBAL.CANCEL' | translate"
                                         (onReject)="deleteConversation()"
                             >
                                 {{"POST_BOX.DIALOG.DELETE.TEXT" | translate:chat.partner}}
                             </dialog-box>
                         `,
               styles: [require('./po-box-list-chat.scss')],
           })

export class PoBoxListChat implements OnInit, OnDestroy {
    @ViewChild('lockedDialog', { static: true }) lockedDialog: DialogBox;
    @ViewChild('deleteDialog', { static: true }) deleteDialog: DialogBox;
    @ViewChild('menu', { static: true }) menu: Menu;
    @Input() chat: Conversation;
    @Output() updateState: EventEmitter<any> = new EventEmitter();
    @Output() private navigateToChat = new EventEmitter();

    private static menuOpenedSubject = new Subject<string>();
    private static menuOpened$ = PoBoxListChat.menuOpenedSubject.asObservable();

    private menuOpenendSubscription = new Subscription();

    isBusy = false;

    public constructor(
        private modalService: ModalService,
        private messageService: MessageService,
        private conversationService: ConversationService
    ) {
    }

    clickItem() {
        if (this.isBusy) return;

        if (this.chat.partner.isSuspended) {
            this.lockedDialog.open();
        } else {
            this.navigateToChat.emit(this.chat.id);
        }
    }

    openProfile(event) {
        event.stopPropagation();
        if (this.isBusy) return;

        this.modalService.profileView.open(this.chat.partner.id);
    }

    onMarkAsUnread() {
        this.isBusy = true;
        this.conversationService.markAsUnreadMultiple([this.chat.id])
            .then(() => {
                this.chat.state = 'unread';
                this.updateState.emit();
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onDeleteConversation() {
        this.deleteDialog.open();
    }

    deleteConversation() {
        this.isBusy = true;
        this.conversationService.delete([this.chat.id])
            .then(value => {
                this.updateState.emit();
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onPinConversation() {
        this.isBusy = true;
        this.conversationService.pinConversations([this.chat.id])
            .then(() => {
                this.chat.pinned = true;
                this.updateState.emit();
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onUnpinConversation() {
        this.isBusy = true;
        this.conversationService.unpinConversations([this.chat.id])
            .then(() => {
                this.chat.pinned = false;
                this.updateState.emit();
            })
            .finally(() => {
                this.isBusy = false;
            });
    }

    onMenuOpen() {
        PoBoxListChat.menuOpenedSubject.next(this.chat.id);
    }

    onOtherMenuOpened() {
        this.menu.close();
    }

    public ngOnDestroy(): void {
        this.menuOpenendSubscription.unsubscribe();
    }

    public ngOnInit(): void {
        this.menuOpenendSubscription = PoBoxListChat.menuOpened$
                                                    .subscribe(id => {
                                                        if (id === this.chat.id) return;
                                                        this.onOtherMenuOpened();
                                                    });
    }

}
