import {
    ElementRef,
    Input,
} from '@angular/core';

export class Disabled {
    private _disabled = false;
    @Input() protected set disabled(value: boolean){
        this._disabled = value;
        this.setDisabledAttribute(value)
    }
    protected get disabled(): boolean {
        return this._disabled;
    }

    public constructor(private elementRef: ElementRef) {

    }


    private setDisabledAttribute(value) {
        if(!this.elementRef.nativeElement) return;


        if(value) {
            this.elementRef.nativeElement.setAttribute("disabled", "");
        }else {
            this.elementRef.nativeElement.removeAttribute("disabled");
        }
    }

}
