import { Injectable } from '@angular/core';
import { Label } from '../models/label';
import { ApiService } from './api';

@Injectable()
export class ProfileEditService {
    constructor(private apiService: ApiService) {
    }

    public getAllFoodLabels(): Promise<Label[]> {
        return this.apiService.profile.getAllFoodLabels();
    }

    public getSelectedFoodLabels(): Promise<Label[]> {
        return this.apiService.profile.getSelectedFoodLabels();
    }

    public saveSelectedFoodLabels(labels: Label[]): Promise<boolean> {
        return this.apiService.profile.saveSelectedFoodLabels(labels);
    }

    public getAllMovieLabels(): Promise<Label[]> {
        return this.apiService.profile.getAllMovieLabels();
    }

    public getSelectedMovieLabels(): Promise<Label[]> {
        return this.apiService.profile.getSelectedMovieLabels();
    }

    public saveSelectedMovieLabels(labels: Label[]): Promise<boolean> {
        return this.apiService.profile.saveSelectedMovieLabels(labels);
    }

    public getAllMusicLabels(): Promise<Label[]> {
        return this.apiService.profile.getAllMusicLabels();
    }

    public getSelectedMusicLabels(): Promise<Label[]> {
        return this.apiService.profile.getSelectedMusicLabels();
    }

    public saveSelectedMusicLabels(labels: Label[]): Promise<boolean> {
        return this.apiService.profile.saveSelectedMusicLabels(labels);
    }
}