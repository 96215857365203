/**
 * Created by olifra on 02.05.2017.
 */

import { NgModule } from '@angular/core';
import { AsyncImageLoadDirective } from './async-image-load';
import { ClickOutsideDirective } from './click-outside.directive';
import { ContentEditableDirective } from './contentEditable.directive';
import { ArrowLeftRightDirective, EscKeyDirective } from './key.directive';
import { RippleDirective } from './ripple-effect.directive';
import { MatchHeightDirective } from './same-height.directive';
import { Swipe } from './swipe';
import { TrimInputDirective } from './trim-input.directive';

const directive = [
    ArrowLeftRightDirective,
    ClickOutsideDirective,
    ContentEditableDirective,
    EscKeyDirective,
    RippleDirective,
    Swipe,
    MatchHeightDirective,
    AsyncImageLoadDirective,
    TrimInputDirective
];

@NgModule({
              imports:      [],
              exports:      [...directive],
              declarations: [...directive],
              providers:    [...directive],
          })
export class DirectiveModule {
}

export * from './textarea-autosize.directive';





