import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { interval } from 'rxjs';

@Component({
               selector:  'logout-title-box',
               template:  `
                              <div class="logout-title-box">
                                  <div class="logout-title-box__text">
                                      <div class="logout-title-box__text__title">{{'LOGOUT.TITLE' | translate}}</div>
                                      <div class="logout-title-box__text__subtitle">{{tippKey | translate}}</div>
                                  </div>

                              </div>
                          `,
               styles: [require('./logout-title-box.scss')],
           })

export class LogoutTitleBox {
    private tipCount = 5;
    private tipDurationMs = 5*1000;
    private tippId: number = this.randomNumber;

    private get tippKey(): string {
        return `LOGOUT.SUBTITLE.TIPP.${this.tippId}`
    }

    //@todo passt vom Layout nicht
    //private interval = interval(this.tipDurationMs).subscribe(() => this.tippId = this.randomNumber);

    private get randomNumber() : number {
        return Math.floor(Math.random() * this.tipCount) + 1
    }
}
