/**
 * Created by olifra on 10.07.2017.
 */
import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/**
 * Pipe um die Haarfarbe zu übersetzen
 */
@Pipe({
          name: 'haircolor',
      })
class HaircolorPipe implements PipeTransform {
    public transform(value: string | number): string {
        return `PROFILE_INFO.HAIR_COLOR.OPTIONS.${value}`;
    }
}

/**
 * Pipe um das Geschlecht zu übersetzen
 */
@Pipe({
          name: 'gender',
      })
class GenderPipe implements PipeTransform {
    public transform(value: string): string {
        return `PROFILE_INFO.GENDER.OPTIONS.${value.toUpperCase()}`;
    }
}

/**
 * Pipe um die Augenfarbe zu übersetzen
 */
@Pipe({
          name: 'eyecolor',
      })
class EyecolorPipe implements PipeTransform {
    public transform(value: string | number = ''): string {
        return `PROFILE_INFO.EYE_COLOR.OPTIONS.${value}`;
    }
}

/**
 * Pipe um den KontaktWunsch zu übersetzen
 */
@Pipe({
          name: 'contactWish',
      })
class ContactWishPipe implements PipeTransform {
    public transform(value: string | number): string {
        return `PROFILE_INFO.CONTACT_WISH.OPTIONS.${value}`;
    }
}

/**
 * Pipe to translate the relationship
 */
@Pipe({
          name: 'relationship',
      })
class RelationshipPipe implements PipeTransform {
    public transform(value: string | number): string {
        return `PROFILE_INFO.RELATIONSHIP.OPTIONS.${value}`;
    }
}

/**
 * Pipe to translate the zodiac sign
 */
@Pipe({
          name: 'zodiacSign',
      })
class ZodiacSignPipe implements PipeTransform {
    public transform(value: string = ''): string {
        return `PROFILE_INFO.ZODIAC_SIGN.OPTIONS.${value.toUpperCase()}`;
    }
}

/**
 * Pipe to translate the smoker-status
 */
@Pipe({
          name: 'smoker',
      })
class SmokerPipe implements PipeTransform {
    public transform(value: string | number): string {
        return `PROFILE_INFO.SMOKER.OPTIONS.${value}`;
    }
}

/**
 * Pipe um der Sprachen zu übersetzen
 */
@Pipe({
          name: 'languages',
      })
class LanguagePipe implements PipeTransform {
    public transform(value: string = ''): string {
        return `PROFILE_INFO.LANGUAGES.OPTIONS.${value.toUpperCase()}`;
    }
}

/**
 * Pipe to translate the state
 */
@Pipe({
          name: 'state',
      })
class StatePipe implements PipeTransform {
    public transform(value: string): string {
        return `PROFILE_INFO.STATE.OPTIONS.${value}`;
    }
}

/**
 * Pipe um des Landes zu übersetzen
 */
@Pipe({
          name: 'country',
      })
export class CountryPipe implements PipeTransform {
    public transform(value: string): string {
        return `COUNTRY.OPTIONS.${value}`;
    }
}

/**
 * Pipe zur Übersetzung der Figur
 */
@Pipe({
          name: 'bodyshape',
      })
class BodyshapePipe implements PipeTransform {
    public transform(value: string | number): string {
        return `PROFILE_INFO.BODYSHAPE.OPTIONS.${value}`;
    }
}


export const profileInfoPipes = [
    EyecolorPipe,
    HaircolorPipe,
    GenderPipe,
    ContactWishPipe,
    RelationshipPipe,
    ZodiacSignPipe,
    SmokerPipe,
    LanguagePipe,
    CountryPipe,
    StatePipe,
    BodyshapePipe,
];
