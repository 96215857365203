import {
    AfterViewInit,
    Directive,
    ElementRef,
    Input,
} from '@angular/core';

@Directive({ selector: '[async-src]' })
export class AsyncImageLoadDirective implements AfterViewInit {

    @Input('async-src') private asyncSrc: string;

    private element;

    constructor(el: ElementRef) {
        this.element = el.nativeElement;
    }

    public ngAfterViewInit(): void {
        const img = new Image();
        img.onload = () => {
            if(this.element.nodeName === "IMG") {
                this.element.src = this.asyncSrc;
            } else {
              this.element.style.backgroundImage = `url(${this.asyncSrc})`;
            }
        };
        img.src = this.asyncSrc;
    }
}
