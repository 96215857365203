/**
 * Component for the AccordionTabs
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { tabContent } from 'BKUtils';


@Component({
               selector:   'accordion-tab-content',
               template:   `
        <div class="accordion-tab-content"
             [@tabContent]="state"
             (@tabContent.done)="onToggleDone.emit($event)"
             [style.height]="0"
        >
            <div class="accordion-tab-content__text">
                <ng-content></ng-content>
            </div>
        </div>
    `,
               styles:  [require('./accordion-tab-content.scss')],
               animations: [tabContent],
           })

export class AccordionTabContent {
    @Output() private onToggleDone = new EventEmitter();
    @Input() private state: string;
}
