export enum AchievementId {
    Registration = 1,
    EmailValidation,
    ProfileFilling,
    ProfileImage,
    AuthenticityCheck,
    DailyLogin,
    AppStartCoins,
    AppStartPremium,
    FyberReward,
    AppStartPremiumNewsletter,
    AppStartPremiumChainLetter,
    TestedPremium,
    FirstTimeMatching
}

/**
 * Class for a single Achievement
 */
export class Achievement {
    /**
     * Achievement unlocked at
     {number}
     * @readonly
     */
    public readonly entryAt: number;

    /**
     * Id of the Achievement
     {AchievementId}
     * @readonly
     */
    public readonly id: AchievementId;

    /**
     * constructor
     {number} entryAt
     {AchievementId} id
     */
    public constructor(entryAt: number, id: AchievementId) {
        this.entryAt = entryAt;
        this.id = id;
    }

    public static createMultiple(val: any[]): Achievement[] {
        return val.map((cur) => {
            const { entryAt } = cur;
            const id = cur.achievmentId ? cur.achievmentId : cur.id;
            return new Achievement(entryAt, id);
        });
    }
}

/**
 * Object for all Achievements
 */
export class Achievements {
    public achievements: Achievement[] = [];

    public constructor(val: Achievement[] = []) {
        this.achievements = val;
    }

    public static create(val: any[] = [new Achievement(0, AchievementId.FirstTimeMatching)]): Achievements {
        const res = new Achievements();
        if (val instanceof Achievements) {
            res.achievements = Achievement.createMultiple((val as Achievements).achievements);
        } else {
            res.achievements = Achievement.createMultiple(val);
        }
        return res;
    }

    public getAll(): Achievement[] {
        return this.achievements;
    }

    public achieved(id: AchievementId): boolean {
        return this.filter(id).length !== 0;
    }

    private filter(id: AchievementId): Achievement[] {
        return this.achievements.filter(cur => cur.id === id);
    }
}
