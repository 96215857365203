import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
          name: 'bypassSanitizer',
      })
export class BypassSanitizerPipe implements PipeTransform {
    constructor(private sanitizier: DomSanitizer) {
    }
    public transform(value) {
        return this.sanitizier.bypassSecurityTrustHtml(value);
    }
}
