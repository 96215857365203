import { Component } from '@angular/core';

@Component({
               selector: 'profile-visitor-header',
               template: `<intern-default-header [title]="'LAYOUT.SIDE_NAV.VISITOR' | translate" [closeable]="true">
                   <profile-visitor-tabbar></profile-visitor-tabbar>
               </intern-default-header>`,
           })
export class ProfileVisitorHeader {
}
