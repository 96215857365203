import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:           'list-advertisement-item',
               template:           `
                             <div class="md-card advertisement">
                                 <ad-slot [slotName]="slotId"></ad-slot>
                             </div>`,
               styles:          [require('./list-advertisement-item.scss')],
           })

export class ListAdvertisementItem {
    @Input() public slotId: string = ''
}
