/* eslint-disable no-underscore-dangle,no-prototype-builtins */
import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { ApiService } from 'BKService';
import { isEmpty } from 'BKShared/Validator';

/**
 * Validation Objekt für die E-Mail Adresse
 */
export class EmailValidator {
    /**
     * Regulärer Ausdruck zum aufbau der Email adresse
     {RegExp}
     */
        // eslint-disable-next-line max-len
    public static emailRegex: RegExp = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9]+)*\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]+)*$/;

    /**
     * Überprüft die Email und gibt den den Fehler zurück
     {AbstractControl} control
     {KeyValue}
     */
    public static email(control: AbstractControl): KeyValue {
        return EmailValidator.emailString(control.value);
    }

    public static emailString(emailAddress: string): KeyValue {
        if (isEmpty(emailAddress)) {
            return { 'ERROR.VALIDATOR.EMAIL.REQUIRED': true };
        }

        if (!EmailValidator.emailRegex.test(emailAddress)) {
            if (emailAddress.length > 3) {
                const indexAt = emailAddress.indexOf('@');
                if (indexAt === -1) {
                    return { 'ERROR.VALIDATOR.EMAIL.MISSING_AT_SIGN': true };
                }
                const lastIndexPoint = emailAddress.lastIndexOf('.');
                if (lastIndexPoint < indexAt) {
                    return { 'ERROR.VALIDATOR.EMAIL.INCORRECT': true };
                }
            }
            return { 'ERROR.VALIDATOR.EMAIL.INCORRECT': true };
        }
        return null;
    }

    private static emailTimeout;

    /**
     * Überprüft ob die Email noch verfügbar ist
     {ApiService} api
     {AsyncValidatorFn}
     */
    public static used(api: ApiService): AsyncValidatorFn {
        return (c: AbstractControl): Promise<ValidationErrors | null> => {
            clearTimeout(this.emailTimeout);
            return new Promise((resolve, reject) => {
                this.emailTimeout = setTimeout(() => {
                    api.validation.email(c.value)
                        .then(() => resolve(null))
                        .catch((res: any) => {
                            switch (res.status) {
                                case 429:
                                    resolve({ 'ERROR.VALIDATOR.EMAIL.TO_MANY_REQUESTS': true });
                                    break;
                                case 405:
                                    resolve({ 'ERROR.VALIDATOR.EMAIL.TO_MANY_REQUESTS': true });
                                    break;
                                case 400:
                                    var error = res.error.data;
                                    if (error.hasOwnProperty('formErrors')) {
                                        var formErrors = error.formErrors.email;
                                        if (formErrors.includes('email_invalid')) {
                                            resolve({ 'ERROR.VALIDATOR.EMAIL.INCORRECT': true });
                                        } else if (formErrors.includes('email_blocked')) {
                                            resolve({ 'ERROR.VALIDATOR.EMAIL.BLOCKED': true });
                                        } else if (formErrors.includes('email_already_taken')) {
                                            resolve({ 'ERROR.VALIDATOR.EMAIL.IN_USE': true });
                                        } else {
                                            resolve({ 'ERROR.VALIDATOR.EMAIL.UNKNOWN': true });
                                        }
                                    }
                                    break;
                                default:
                                    resolve(null);
                           }
                       });
                }, 500);
            });
        };
    }
}
