import { ProfileInfo } from './user';


export class FavWithNotice {
    public entryAt: number = 0;

    public favorite: ProfileInfo = new ProfileInfo();

    public notice: string = '';


    public static create(data: any): FavWithNotice {
        const res = new FavWithNotice();
        res.entryAt = data.entryAt;
        if(data.hasOwnProperty("favorite")) {
            res.favorite = ProfileInfo.create(data.favorite);
        }else {
            res.favorite = ProfileInfo.create(data.deletedFavorite);
        }


        res.notice = data.notice;

        return res;
    }
}
