import {
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
    BillingAddress,
    OrderReason,
    PremiumError,
    SepaAccount,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    startLoadingBar,
    stopLoadingBar,
} from 'BKUtils';
import { PaymentType } from '../../../../../models/product';
import { Tariff } from '../../../../../models/tariff';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { PaymentService } from '../../../../../service/payment.service';
import {
    ECommerceTrackingCheckoutStep,
    ECommerceTrackingService,
} from '../../../../../service/tracking/e-commerce-tracking.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import { PaymentDebit } from '../../../../../shared/mat-design/payment-debit/payment-debit';
import { Cache } from '../../../../../utils/cache';


@Component({
               selector:  'premium-booking-page-payment-debit',
               template:  `
                              <div class="premium-booking-page-payment-debit" #container>
                                  <div class="premium-booking-page-payment-debit__title">{{'PREMIUM.BOOKING.PAYMENT.DEBIT.TITLE' | translate}}</div>

                                  <payment-debit #debit
                                                 [(sepaAccount)]="sepaAccount"
                                                 [(billingAddress)]="billingAddress"
                                  ></payment-debit>
                                  <premium-booking-page-payment-price [price]="tariff.formattedPrice"></premium-booking-page-payment-price>

                                  <raised-button premium
                                                 *ngIf="!this.debitForm.secondStep"
                                                 class="premium-booking-page-payment-debit__button"
                                                 (click)="toStepTwo()"
                                  >
                                      {{'PREMIUM.BOOKING.PAYMENT.DEBIT.PAY_BUTTON' | translate}}
                                  </raised-button>


                                  <raised-button premium
                                                 *ngIf="this.debitForm.secondStep"
                                                 class="premium-booking-page-payment-debit__button"
                                                 [disabled]="runDebitCall"
                                                 (click)="pay()"
                                  >
                                      {{'PREMIUM.BOOKING.PAYMENT.DEBIT.PAY_BUTTON.SECOND_STEP' | translate}}
                                  </raised-button>


                                  <premium-booking-page-safe-paying></premium-booking-page-safe-paying>
                              </div>

                              <dialog-box #error
                                          [title]="errorMsg.title | translate"
                                          [acceptText]="'GLOBAL.OK' | translate"
                              >
                                  {{errorMsg.text | translate}}
                              </dialog-box>

                          `,
               styles: [require('./premium-booking-page-payment-debit.scss')],
           })
export class PremiumBookingPagePaymentDebit implements OnInit {
    private _tariff: Tariff;

    @Input()
    private set tariff(tariff) {
        this._tariff = tariff;
        if (tariff.tariffId !== 0) {
            this.eCommerceTrackingService.trackCheckout(this.tariff, PaymentType.Bank, ECommerceTrackingCheckoutStep.DebitDetails);
        }
    }

    private get tariff(): Tariff {
        return this._tariff;
    }

    @Input() private orderReason: OrderReason = OrderReason.None;
    @Input() private offerId: string = null;
    @ViewChild('error', { static: true }) private errorDialog: DialogBox;
    @ViewChild('debit', { static: true }) private debitForm: PaymentDebit;
    @ViewChild('container', { static: true }) private container: ElementRef;

    private _billingAddress: BillingAddress = new BillingAddress();
    private _sepaAccount: SepaAccount = new SepaAccount();

    get billingAddress(): BillingAddress {
        return this._billingAddress;
    }

    set billingAddress(billingAddress: BillingAddress) {
        this._billingAddress = billingAddress;
        this.paymentService.setBillingAddress(billingAddress);
    }

    get sepaAccount(): SepaAccount {
        return this._sepaAccount;
    }

    set sepaAccount(sepaAccount: SepaAccount) {
        this._sepaAccount = sepaAccount;
        this.paymentService.setSepaAccount(sepaAccount);
    }

    private errorNumber: number;
    runDebitCall = false;

    public constructor(
        private formBuilder: FormBuilder,
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private eCommerceTrackingService: ECommerceTrackingService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        this.paymentService.billingAddress().then(billingAddress => this.billingAddress = billingAddress);
        this.paymentService.sepaAccount().then(sepaAccount => this.sepaAccount = sepaAccount);
    }

    toStepTwo() {
        if (!this.debitForm.secondStep && this.debitForm.firstInputsValid()) {
            this.debitForm.markFormPristine();
            this.trackingService.hit('Premium', 'ZahlungsartSeite', 'BezahlenGeniessen');

            this.eCommerceTrackingService.trackCheckout(this.tariff, PaymentType.Bank, ECommerceTrackingCheckoutStep.PaymentAddress, () => this.debitForm.secondStep = true);
        } else {
            this.container.nativeElement.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth"
            });
            this.debitForm.markFormDirty();
        }
    }

    pay() {
        if (this.debitForm.form.valid) {
            this.runDebitCall = true;
            startLoadingBar();
            this.paymentService
                .orderWithDebit(this.tariff, this.orderReason, this.offerId)
                .then(premiumDebitResult => {
                    this.currentUserService.loadCurrentUser();
                    Cache.getInstance().clear();

                    this.eCommerceTrackingService.trackPurchase(this.tariff, premiumDebitResult, () => {
                        this.navigationService.navigateTo(Routes.PremiumBookingAfterSale, {
                            urlParam: {
                                [RouteParameter.TariffId]:     this.tariff.tariffId,
                                [RouteParameter.PurchaseDate]: new Date().getTime(),
                                [RouteParameter.StartDate]:    new Date(premiumDebitResult.startAt).getTime(),
                                [RouteParameter.PaymentType]:  PaymentType.Bank,
                            },
                        });
                    });

                })
                .catch(err => {
                    this.errorNumber = err.errno;
                    this.trackingService.hit('Premium', 'Bankeinzug', 'Fehlermeldung: Buchung fehlgeschlagen', `${err.errno}`);
                    this.errorDialog.open();
                })
                .finally(() => {
                    this.runDebitCall = false;
                    stopLoadingBar();
                });
        } else {
            this.container.nativeElement.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth"
            });
            this.debitForm.markFormDirty();
        }
    }

    private errorMapping = {
        [PremiumError.PaymentNotAllowed]: {
            text:  'ERROR.PREMIUM.PAYMENT_NOT_ALLOWED',
            title: 'ERROR.PREMIUM.PAYMENT_NOT_ALLOWED.TITLE',
        },
        [PremiumError.TariffUnnkown]:     {
            text:  'ERROR.PREMIUM.TARIFF_UNKNOW',
            title: 'ERROR.PREMIUM.TARIFF_UNKNOW.TITLE',
        },
        [PremiumError.UnprocessedDebit]:  {
            text:  'ERROR.PREMIUM.UNPROCESSED_DEBIT',
            title: 'ERROR.PREMIUM.UNPROCESSED_DEBIT.TITLE',
        },
        [PremiumError.OpenBill]:          {
            text:  'ERROR.PREMIUM.OPEN_BILL',
            title: 'ERROR.PREMIUM.OPEN_BILL.TITLE',
        },
    };


    get errorMsg() {
        return this.errorMapping[this.errorNumber] || {
            text:  'ERROR.PREMIUM.UNKNOW',
            title: 'ERROR.PREMIUM.UNKNOW.TITLE',
        };
    }

}


