import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
} from 'BKModels';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { ImgUpload } from '../../intern-components/img-upload/img-upload';

@Component({
               selector: 'favorite-empty',
               template: `
        <list-empty [title]="'FAVORITES.EMPTY_LIST.TITLE' | translate"
                    [img]="'./assets/img/favorites/no-fav-icon.svg'"
                    [text]="'FAVORITES.EMPTY_LIST.INFO_TEXT' | translate"
        >
            <raised-button
                class="favorite-empty__button "
                [routerLink]="searchLink"
            >
                {{'FAVORITES.EMPTY_LIST.SEARCH' | translate}}
            </raised-button>
        </list-empty>
    `,
               styles: [require('./favorite-empty.scss')],
           })

export class FavoriteEmpty {
    @Input() private currentUser: CurrentUser;

    @ViewChild(ImgUpload, { static: true }) private imageUpload: ImgUpload;

    public constructor(private navigationService: NavigationService) {

    }
    private get searchLink() {
        return this.navigationService.getRoute(Routes.Search);
    }
}
