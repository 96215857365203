import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { CommunicationService } from 'BKService';
import { Subscription } from 'rxjs';
import {
    MailSettingsInterval,
    MailSettingsType,
} from '../../../../service/api/calls/settings.api';
import {
    MailSettingsService,
} from '../../../../service/mail-settings.service';

@Component({
               selector:  'mail-settings-profile-visitor',
               template:  `
                              <div class="page">
                                  <communication></communication>
                                  <single-page-header trackingCategoryName="MailSettings" trackingPageName="Profilbesucher" class="header"></single-page-header>
                                  <loading-spinner *ngIf="loading"></loading-spinner>
                                  <div class="container" *ngIf="!loading">
                                      <mail-settings-card
                                              *ngIf="!saved"
                                              class="settings"
                                              settingsTitle="MAIL_SETTINGS.PROFILE_VISITOR.TITLE"
                                              settingsText="MAIL_SETTINGS.PROFILE_VISITOR.SETTINGS_TEXT"
                                              disableText="MAIL_SETTINGS.PROFILE_VISITOR.DISABLE_TEXT"
                                              [intervalSettings]="mailSettingsService.intervals[MailSettingsType.ProfileVisitor]"
                                              [showIntervalSettings]="true"
                                              [(interval)]="interval"
                                              (save)="save()"
                                              (disable)="disable()"
                                      ></mail-settings-card>
                                      
                                      <mail-settings-changed
                                          *ngIf="saved"
                                          class="changed"
                                          [titleKey]="interval === MailSettingsInterval.Never ? 'MAIL_SETTINGS.PROFILE_VISITOR.DISABLED.TITLE' : 'MAIL_SETTINGS.PROFILE_VISITOR.CHANGED.TITLE'"
                                          [textKey]="interval === MailSettingsInterval.Never ? 'MAIL_SETTINGS.PROFILE_VISITOR.DISABLED.DESCRIPTION' : 'MAIL_SETTINGS.PROFILE_VISITOR.CHANGED.DESCRIPTION'"
                                          [image]="interval === MailSettingsInterval.Never ? './assets/img/settings/picDeactivateMail.svg' : './assets/img/settings/picChangeValue.svg'"
                                      ></mail-settings-changed>
                                  </div>
                                  <bk-footer-out class="footer"></bk-footer-out>
                              </div>
                          `,
               styles: [require('./mail-settings-profile-visitor.scss')],
           })

export class MailSettingsProfileVisitor implements OnInit, OnDestroy {
    MailSettingsInterval = MailSettingsInterval;

    private intervalSubscription = new Subscription();

    loading = true;

    saving = false;

    MailSettingsType = MailSettingsType;

    interval: MailSettingsInterval = MailSettingsInterval.Always;

    saved = false;

    public constructor(
        private communicationService: CommunicationService,
        private mailSettingsService: MailSettingsService
    ) {}

    public ngOnInit(): void {
        this.intervalSubscription = this.mailSettingsService.profileVisitor$.subscribe((value) => {
            this.interval = value;
            this.loading = false;
            this.intervalSubscription.unsubscribe();
        });
        this.mailSettingsService.update();
    }

    public ngOnDestroy(): void {
        this.intervalSubscription.unsubscribe();
    }

    save() {
        this.update(this.interval);
    }

    disable() {
        this.update(MailSettingsInterval.Never);
    }

    private update(interval: MailSettingsInterval) {
        this.saving = true;
        this.mailSettingsService.setProfileVisitor(interval)
            .then(value => {
                this.saved = true;
            })
            .catch(_ => {
                this.communicationService.growl.settings.emailSettings.handleError();
            })
            .finally(() => {
                this.saving = false;
            });
    }
}
