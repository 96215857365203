import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class OpenInvoiceNotification extends Notification {
    public static notificationTypeId = '6d1329cc-8b4c-4d43-a383-9994f9551b88';

    public notificationId = OpenInvoiceNotification.notificationTypeId;

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Error;
    public importance = 1000;

    public contentKey = 'NOTIFICATION.REVERSE-DEBIT.TEXT';
    public titleKey = 'NOTIFICATION.REVERSE-DEBIT.TITLE';

    public foldable = false;

    constructor(nickname: string, route: any) {
        super();
        this.contentParams = { nickname, route };
    }
}
