import {
    AfterContentChecked,
    Directive,
    ElementRef,
    HostListener,
    Input,
} from '@angular/core';

@Directive({
               selector: 'textarea[autosize]',
           })
export class TextareaAutosizeDirective implements AfterContentChecked {
    @Input('autosize') private enable: boolean = false;

    public constructor(public element: ElementRef) {
    }

    public ngAfterContentChecked() {
        this.resize();
    }

    @HostListener('input', ['$event.target'])
    public onInput() {
        this.resize();
    }

    public resize() {
        const element = this.element.nativeElement;
        const { style } = element;
        if (this.enable) {
            style.overflow = 'hidden';
            style.height = 'auto';
            style.height = `${element.scrollHeight}px`;
            style.maxHeight = `${element.scrollHeight}px`;
        } else {
            const rows = element.getAttribute('rows');

            style.height = `${rows * 25 + 32}px`;
            style.maxHeight = `${rows * 25 + 32}px`;
        }
    }
}
