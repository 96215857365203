import { Component } from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector: 'settings-tabbar',
               template: `
                             <div class="settings-chip-bar scroll-horizontal-hint-bp2-max">
                                 <navigation-chip (click)="account()" [select]="navigationService.isRouteActive(Routes.SettingsAccount)">
                                     {{ 'SETTINGS.ACCOUNT' | translate }}
                                 </navigation-chip>
                                 <navigation-chip (click)="privacy()" [select]="navigationService.isRouteActive(Routes.SettingsPrivacy)">
                                     {{ 'SETTINGS.PRIVACY' | translate }}
                                 </navigation-chip>
                                 <navigation-chip (click)="notifications()" [select]="navigationService.isRouteActive(Routes.SettingsNotification)">
                                     {{ 'SETTINGS.NOTIFICATION' | translate }}
                                 </navigation-chip>
                                 <navigation-chip (click)="password()" [select]="navigationService.isRouteActive(Routes.SettingsPassword)">
                                     {{ 'SETTINGS.PASSWORD' | translate }}
                                 </navigation-chip>
                                 <navigation-chip (click)="ignores()" [select]="navigationService.isRouteActive(Routes.SettingsIgnoreList)">
                                     {{ 'SETTINGS.IGNORES' | translate }}
                                 </navigation-chip>
                             </div>
                         `,
               styles: [require('./settings-tabbar.scss')]
           })

export class SettingsTabbar {
    Routes = Routes;

    public constructor(
        public trackingService: TrackingService,
        public navigationService: NavigationService,
    ) {
    }

    private track(label: string) {
        this.trackingService.hit('Einstellungen', 'TabGeklickt', label);
    }

    account() {
        this.track('AccountBearbeiten');
        this.navigationService.navigateTo(Routes.SettingsAccount);
    }

    privacy() {
        this.track('Privatsphaere');
        this.navigationService.navigateTo(Routes.SettingsPrivacy);
    }

    notifications() {
        this.track('Benachrichtigungen');
        this.navigationService.navigateTo(Routes.SettingsNotification);
    }

    password() {
        this.track('PasswortAendern');
        this.navigationService.navigateTo(Routes.SettingsPassword);
    }

    ignores() {
        this.track('IgnoreListe');
        this.navigationService.navigateTo(Routes.SettingsIgnoreList);
    }
}
