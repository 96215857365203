import * as moment from 'moment';

export class SingleEvent {
    public eventId: string;
    public name: string;
    public price: string;
    public minAge: string;
    public maxAge: string;
    public city: string;
    public type: SingleEventType = SingleEventType.NONE;
    private _date;

    public static create(data: SingleEvent): SingleEvent {
        let event = new SingleEvent();
        event.eventId = data.eventId;
        event.name = data.name;
        event.price = data.price;
        event.maxAge = data.maxAge;
        event.minAge = data.minAge;
        event.city = data.city;
        event.type = data.type || SingleEventType.NONE;
        event.date = moment(data.date);
        return event;
    }

    public set date(val) {
        this._date = moment(val);
    }

    public get date() {
        return this._date.format("DD.MM.YY")
    }

}


export enum SingleEventType {
    NONE = '',
    Sport = 'SPORTLICH',
    Culture = 'KULTUR',
    Dinning = 'GENIEßERISCH',
    Discover = 'ENTDECKEN',
    Dating = 'FLIRTEN',
}
