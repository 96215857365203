import { Component } from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector: 'favorite-tabbar',
               template: `
                   <div class="favorite-chip-bar">
                       <navigation-chip (click)="clickExisting()" [select]="existingSelected">
                           {{ 'FAVORITES.TAB.FAVORITES' | translate }}
                       </navigation-chip>
                       <navigation-chip (click)="clickDeleted()" [select]="deletedSelected">
                           {{ 'FAVORITES.TAB.DELETED_FAVORITES' | translate }}
                       </navigation-chip>
                   </div>
                         `,
               styles: [require('./favorite-tabbar.scss')]
           })

export class FavoriteTabbar {
    private get existingSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.FavoriteExist);
    }

    private get deletedSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.FavoriteDeleted);
    }

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {
    }

    private trackSelectionDeleted() {
        this.trackingService.hit('Favoriten', 'TabGeklickt', 'GeloeschteFavoriten');
    }

    private clickExisting() {
        this.navigationService.navigateTo(Routes.FavoriteExist);
    }

    private clickDeleted() {
        this.trackSelectionDeleted();
        this.navigationService.navigateTo(Routes.FavoriteDeleted);
    }
}
