import {
    Component,
    Input,
} from '@angular/core';
import { LovestoryListItem } from 'BKModels';

@Component({
               selector:  'logout-lovestory-box',
               template:  `
                              <div class="mdc-card logout-lovestory-box">
                                  <div class="logout-lovestory-box__content">
                                      <div class="logout-lovestory-box__img"
                                           [style.backgroundImage]="image"
                                      ></div>

                                      <div class="logout-lovestory-box__text">
                                          <div class="is-hidden-tablet">
                                              <icon class="logout-lovestory-box__icon" icon="format-quote"></icon>
                                          </div>

                                          {{lovestory.summary}}

                                          <div class="logout-lovestory-box__text--author">
                                              {{ 'LOGOUT.LOVESTORY.PAIR'|translate:{
                                              'nameFemale': lovestory.nameFemale,
                                              'nameMale': lovestory.nameMale
                                          } }}
                                          </div>
                                      </div>
                                      <icon class="logout-lovestory-box__icon is-hidden-desktop" icon="format-quote"></icon>
                                  </div>
                              </div>
                          `,
               styles: [require('./logout-lovestory-box.scss')],
           })

export class LogoutLovestoryBox {
    @Input() private lovestory: LovestoryListItem = new LovestoryListItem();

    private get image() {
        return `url(${this.lovestory.mainImageUrl})`;
    }
}
