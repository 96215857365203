import {
    Component,
    Input,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import { PremiumService } from '../../../../service/premium.service';

@Component({
               selector: 'search-modal-box',
               template: `
                             <div class="search-modal-box">
                                 
                                 <div class="search-modal-box__text">
                                     <div class="search-modal-box__title" *ngIf="!title.isEmpty()">{{title}}</div>

                                     <a *ngIf="showPremiumLink" class="link--blue" (click)="toPremium()">{{'SEARCH.QUICK_SEARCH.BECOME_PREMIUM' | translate}}</a>    
                                 </div>
                                 
                                 <div class="search-modal-box__content">
                                     <ng-content></ng-content>
                                 </div>
                             </div>`,
               styles: [require('./search-modal-box.scss')],
           })

export class SearchModalBox {
    @Input() private title: string = '';
    @Input() private showPremiumLink: boolean = false;
    @Input() private orderReason: OrderReason = OrderReason.None

    constructor(private premiumService: PremiumService) {
    }

    private toPremium() {
        this.premiumService.navigateToPremium(this.orderReason);
    }
}
