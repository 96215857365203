import { Component } from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../../../service/navigation.service';

@Component({
               selector:  'lovestory-formular-success',
               template:  `
        <card padding>
            <div class="ls-form-success">
                <div class="ls-form-success__img"></div>
                <div class="ls-form-success__content">
                    <div class="ls-form-success__content__title">
                        {{'LOVESTORY.SUCCESS.TITLE' | translate}}
                    </div>

                    <div class="ls-form-success__content__text">
                        {{'LOVESTORY.SUCCESS.TEXT' | translate}}
                    </div>

                    <div class="ls-form-success__content__wrapper">
                        <arrow-link [routerLink]="navigationService.getRoute(Routes.Startpage)"
                                    class="ls-form-success__content__wrapper__link"
                        >
                            {{'LAYOUT.SIDE_NAV.STARTPAGE' | translate}}
                        </arrow-link>
                        <arrow-link [routerLink]="navigationService.getRoute(Routes.Lovestory)"
                                    class="ls-form-success__content__wrapper__link"
                        >
                            Lovestorys
                        </arrow-link>
                    </div>
                </div>
            </div>
        </card>
    `,
               styles: [require('./lovestory-formular-success.scss')],

           })

export class LovestoryFormularSuccess {
    private Routes = Routes;
    public constructor(private navigationService: NavigationService) {
    }
}
