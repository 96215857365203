/**
 * Created by olifra on 27.06.2017.
 */
import {
    ApiResponseFrame,
    Conversation,
    ConversationFilter,
    DetailedConversationUnreadCounts,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { SpamReportReason } from '../../../models/api-enums';
import { Conversations } from '../../../models/messages';
import { ApiRequestService } from '../api-request.service';

export class ConversationApi {

    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public deleteMultiple(conversationIds: string[]): Promise<boolean> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.conversation.deleteMultiple, {
                       body: {
                           conversationIds,
                       },
                   }).then(res => res.data.state);
    }

    public getAll(limit: number, offset: number, filter: ConversationFilter = ConversationFilter.All): Promise<Conversation[]> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<Conversations>>(Endpoints.conversation.all, {
                       body: {
                           limit,
                           offset,
                           filter,
                       },
                   })
                   .then(res => Conversation.createMultiple(res.data.conversations));
    }

    public markAsUnreadMultiple(conversationIds: string[]): Promise<boolean> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.conversation.markAsUnreadMultiple, {
                       body: {
                           conversationIds,
                       },
                   }).then(res => res.data.state);
    }

    public report(convId: string, reason: SpamReportReason, description: string): Promise<boolean> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.conversation.report, {
                       endpointValue: {
                           convId,
                       },
                       body:          {
                           reason:      reason,
                           description: description,
                       },
                   }).then(res => res.data.state);
    }

    public pinConversations(conversationIds: string[]): Promise<boolean> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.conversation.pin, {
                       body: {
                           conversationIds: conversationIds,
                       },
                   }).then(res => res.data.state);
    }

    public unpinConversations(conversationIds: string[]): Promise<boolean> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.conversation.unpin, {
                       body: {
                           conversationIds: conversationIds,
                       },
                   }).then(res => res.data.state);
    }

    public fetchDetailedConversationCounts(): Promise<DetailedConversationUnreadCounts> {
        return this._apiRequestService
           .createGet<ApiResponseFrame<any>>(Endpoints.conversation.detailedNumberOfUnreadConversations)
           .then(response => {
                return response.data.counts;
            }
        );
    }
}
