import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCChip } from '@material/chips/chip';
import { MDCChipSet } from '@material/chips/index';

@Component({
               selector:  'navigation-chip',
               template:  `
                              <div #chipset class="mdc-chip-set mdc-chip-set--choice chip-set">
                                  <div class="chip-wrapper">
                                      <div class="chip mdc-chip"
                                           [class.mdc-chip--selected]="select"
                                           tabindex="0"
                                           #chip
                                      >
                                          <div class="mdc-chip__ripple"></div>
                                          <icon *ngIf="!icon.isEmpty()"
                                                class="mdc-chip__icon mdc-chip__icon--leading"
                                                [icon]="icon"
                                          ></icon>

                                          <div class="mdc-chip__text">
                                              <ng-content></ng-content>
                                          </div>

                                          <icon *ngIf="closeable"
                                                class="test mdc-chip__icon mdc-chip__icon--trailing"
                                                (click)="close($event)"
                                                tabindex="0"
                                                role="button"
                                                icon="close"
                                          ></icon>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./navigation-chip.scss')],
           })

export class NavigationChip implements AfterViewInit {
    @Output('close') _close = new EventEmitter();
    private chip: MDCChip;
    private chipSet: MDCChipSet;
    @ViewChild('chip', { static: true }) private chipRef: ElementRef;
    @ViewChild('chipset', { static: true }) private chipSetRef: ElementRef;
    @Input() private closeable: boolean = false;
    @Input() private icon: string = '';

    @Input() private select: boolean = false;

    public ngAfterViewInit(): void {
        this.chipSet = new MDCChipSet(this.chipSetRef.nativeElement);
        this.chip = new MDCChip(this.chipRef.nativeElement);
    }

    private close($event: Event) {
        $event.stopPropagation();
        this._close.emit($event);
    }
}
