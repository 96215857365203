import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    Input,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { growShrink } from 'BKUtils';
import {
    Notification,
    NotificationStyle,
} from '../../../service/notification/Notification';

@Component({
               selector:  'notification-box',
               template: `
                             <div class="bk-home-notification"
                                  [class.bk-home-notification--success]="isSuccess"
                                  [class.bk-home-notification--error]="isError"
                                  [class.bk-home-notification--warning]="isWarning"
                                  [class.bk-home-notification--info]="isInfo"
                                  [class.bk-home-notification--elevated]="elevated"
                             >
                                 <div class="bk-home-notification__title" 
                                      *ngIf="!notification.titleKey.isEmpty()"
                                      (click)="toggleFold()">
                                     <icon class="bk-home-notification__title-icon" [icon]="icon[notification.style]"></icon>
                                     {{notification.titleKey|translate:notification.titleParams}}
                                     <icon *ngIf="notification.foldable"
                                           class="bk-home-notification__fold-icon"
                                           [icon]="folded ? 'arrow-drop-down': 'arrow-drop-up'"
                                     ></icon>
                                 </div>

                                 <div [class.folded]="notification.foldable && folded" [@growShrink]="false">
                                     <span class="bk-home-notification__content checkbox-list markdown first-last-nomargin"
                                           [innerHTML]="notification.contentKey|translate:notification.contentParams|markdown"
                                     ></span>
                                     <div class="extra-content">
                                         <ng-container #extraContent></ng-container>
                                     </div>
                                 </div>

                                 
                                 <ng-container *ngIf="!notification.actionKey.isEmpty()">
                                     <flat-button *ngIf="!notification.premiumAction"
                                                  (click)="notification.action()" class="bk-home-notification__action">
                                         {{ notification.actionKey|translate }}
                                     </flat-button>
                                     <flat-button premium
                                                  *ngIf="notification.premiumAction"
                                                  (click)="notification.action()" class="bk-home-notification__action">
                                         {{ notification.actionKey|translate }}
                                     </flat-button>
                                 </ng-container>

                             </div>
                         `,
               styles: [require('./notification-box.scss')],
               animations: [growShrink],
           })
export class NotificationBox implements AfterViewInit, OnDestroy {
    @ViewChild('extraContent', { read: ViewContainerRef }) private extraContent: ViewContainerRef;
    @Input() private notification: Notification;
    @Input() private elevated: boolean = false;

    private folded = true;

    private get isSuccess(): boolean {
        return this.notification.style === NotificationStyle.Success;
    }

    private get isError(): boolean {
        return this.notification.style === NotificationStyle.Error;
    }

    private get isWarning(): boolean {
        return this.notification.style === NotificationStyle.Warning;
    }

    private get isInfo(): boolean {
        return this.notification.style === NotificationStyle.Information;
    }

    private toggleFold() {
        this.folded = !this.folded;
    }

    private icon: { [key in NotificationStyle]: string } = {
        [NotificationStyle.Information]:    '',
        [NotificationStyle.Success]: 'info-outline',
        [NotificationStyle.Warning]: 'info-outline',
        [NotificationStyle.Error]:   'info-outline',
    };

    public ngAfterViewInit(): void {
        this.notification.addExtraContent(this.extraContent, this.componentFactoryResolver);
    }

    public ngOnDestroy(): void {
        this.notification.removeExtraContent();
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    }
}


