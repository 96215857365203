import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { ActiveUsersList } from './active-users';
import { ActiveUsersHeader } from './active-users-header';

const activeUsersRoutes: Routes = [
    {
        path:     '',
        children: [{ path: '', component: ActiveUsersList },
            { path: '', component: ActiveUsersHeader, outlet: 'header' },
        ],

    },
];

export const ActiveUsersRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(activeUsersRoutes);
