import { ApiResponseFrame } from 'BKModels';
import { Offer } from '../../../models/offer';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export class PushApi {
    /**
     * constructor
     *
     {ApiRequestService} apiRequestService
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public subscribe(subscription: PushSubscription): Promise<void> {
        return this.apiRequestService
            .createPost(Endpoints.push.subscribe, {
                body: subscription,
                json: true
            });
    }

    public unsubscribe(endpoint: string): Promise<void> {
        return this.apiRequestService
                   .createPost(Endpoints.push.unsubscribe, {
                       body: { endpoint },
                       json: true
                   });
    }

}
