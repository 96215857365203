import {
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUserService,
} from 'BKService';
import { PremiumDetails } from '../../../../../models/premium-details';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import {
    PremiumService,
} from '../../../../../service/premium.service';
import {
    Cache,
    CacheKey,
} from '../../../../../utils/cache';


enum PremiumCancelSteps {
    List = 0,
    Reason = 1,
    Successful = 2
}

@Component({
               selector:  'premium-cancel',
               template:  `
                              <card>
                                  <div class="premium-cancel">
                                      <premium-cancel-step-list *ngIf="step == PremiumCancelSteps.List" 
                                                                [premiumDetails]="premiumDetails"
                                                                (cancel)="cancel()"
                                                                (next)="step = PremiumCancelSteps.Reason"
                                      ></premium-cancel-step-list>

                                      <premium-cancel-reason *ngIf="step == PremiumCancelSteps.Reason"
                                                             [premiumDetails]="premiumDetails"
                                                             (cancel)="cancel()"
                                                             (confirm)="confirm($event)"
                                      ></premium-cancel-reason>

                                      <premium-cancel-successful *ngIf="step == PremiumCancelSteps.Successful"
                                                                 [premiumDetails]="premiumDetails"
                                                                 [email]="currentUserService.cachedProfileInfo.email"
                                                                 (buttonClick)="navigationService.navigateTo(Routes.Startpage)"
                                      ></premium-cancel-successful>
                                  </div>
                              </card>
                          `,
               styles: [require('./premium-cancel.scss')],
           })

export class PremiumCancel implements OnInit {
    private premiumDetails: PremiumDetails;

    private PremiumCancelSteps = PremiumCancelSteps;
    private step: PremiumCancelSteps = PremiumCancelSteps.List;
    private Routes = Routes;

    public constructor(
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private currentUserService: CurrentUserService,
    ) {
    }

    public ngOnInit(): void {
        this.premiumService.getDetails().then(premiumDetails => this.premiumDetails = premiumDetails);
    }

    private confirm(data:  {reason, notes}) {
        this.premiumService.cancel(data.reason, data.notes).then(() => {
            this.step = PremiumCancelSteps.Successful;
            this.currentUserService.loadCurrentUser();
            Cache.getInstance().delete(CacheKey.PremiumDetails)
        });
    }

    private cancel() {
        this.navigationService.navigateTo(Routes.PremiumOverview)
    }
}
