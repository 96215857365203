import { Injectable } from '@angular/core';
import { UserBaseView } from 'BKModels';
import { ApiService } from 'BKService';

@Injectable({ providedIn: 'root' })
export class RadioService {
    constructor(private api: ApiService) {}

    public async upcomingUsers(): Promise<UserBaseView[]> {
        return this.api.radio.upcoming();
    }

    public async previousUsers(): Promise<UserBaseView[]> {
        return this.api.radio.previous();
    }

    public async apply(name: string, phone: string, message: string): Promise<boolean> {
        return this.api
                   .radio
                   .application(name, phone, message)
                   .then(value => value.state);
    }

    public async isOnAir(): Promise<boolean> {
        return this.api.radio.isOnAir().then(value => value.state);
    }
}