import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RoundProgressComponent } from './round-progress.component';
import { RoundProgressEase } from './round-progress.ease';
import { RoundProgressService } from './round-progress.service';

@NgModule({
              imports:      [CommonModule],
              declarations: [RoundProgressComponent],
              exports:      [RoundProgressComponent],
              providers:    [RoundProgressService, RoundProgressEase],
          })
export class RoundProgressModule {
}

export * from './round-progress.component';
export * from './round-progress.service';
export * from './round-progress.ease';
