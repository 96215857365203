import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector: 'img-with-loading-indicator',
               template: `
                             <ng-container>
                                 <img #image class="image" [src]="src" [alt]="alt || ''" (load)="load.emit($event)">
                                 <div class="loading" *ngIf="!image.complete">
                                     <loading-indicator [style.width]="indicatorWidth" [style.height]="indicatorHeight"></loading-indicator>
                                 </div>
                             </ng-container>
                         `,
               styles: [require('./img-with-loading-indicator.scss')]
           })
export class ImgWithLoadingIndicator {
    @Input() src: string;
    @Input() alt: string;
    @Input() indicatorWidth = '32px';
    @Input() indicatorHeight = '32px';
    @Output() load = new EventEmitter();
}
