import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'authenticity-check-card-layout',
               template:  `
                              <div class="authenticity-check"
                                   [class.authenticity-check--error]="error"
                                   [class.authenticity-check--pending]="pending && !hasCheckDone"
                                   [class.authenticity-check--verified]="hasCheckDone"
                              >
                                  <div class="authenticity-check__icon">
                                      <div class="authenticity-check__icon__image">
                                          <icon [icon]="icon"></icon>
                                      </div>
                                  </div>
                                  <div class="authenticity-check__title">{{title}}</div>
                                  <div class="authenticity-check__description" [innerHtml]="descriptionText"></div>

                                  <raised-button class="authenticity-check__button" (click)="clickButton.emit($event)">
                                      {{verifyText}}
                                  </raised-button>

                                  <div class="authenticity-check__footer">
                                      <div *ngIf="hasCheckDone" class="authenticity-check__footer__text">{{'ECHECK.STATE.VERIFIED' | translate}}</div>
                                      <div *ngIf="pending && !hasCheckDone" class="authenticity-check__footer__text"> {{'ECHECK.STATE.IS-CHECKING' | translate}} </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./authenticity-check-card-layout.scss')],
           })

export class AuthenticityCheckCardLayout {
    @Input() protected verifyText: string = '';
    @Output() private clickButton = new EventEmitter();
    @Input() private description: string = '';
    @Input() private pendingDescription: string = '';
    @Input() private verifiedDescription: string = '';
    @Input() private error: boolean = false;
    @Input() private hasCheckDone: boolean = false;
    @Input() private icon: string;
    @Input() private pending: boolean = false;
    @Input() private title: string;

    private get descriptionText() {
        if (this.hasCheckDone) {
            return this.verifiedDescription;
        }

        if (this.pending) {
            return this.pendingDescription;
        }

        return this.description;
    }

}
