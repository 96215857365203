import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
    CurrentUser,
    ExtendedSearchCriteria,
    Gender,
    OrderReason,
    QuickSearchCriteria,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    combineLatest,
    Subject,
} from 'rxjs';
import {
    debounceTime,
    skipWhile,
    takeUntil,
} from 'rxjs/operators';
import {
    ActiveUserDataSource,
    SearchDataSource,
    SearchUsernameDataSource,
} from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';
import { GlobalModalService } from '../../../../service/global-modal.service';
import {
    NavigationService,
    SearchRouteFragment,
} from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { BkModalProfilePictureMessage } from '../../../../shared/mat-design/bk-modals/bk-modal-profile-picture-message';
import {
    extendHeader,
    shrinkHeader,
} from '../../../../store/header.actions';
import { BKStore } from '../../../../store/store';
import { SearchExtended } from '../extended/search-extended';
import { SearchQuick } from '../quick/search-quick';
import { SearchSubheader } from '../search-header/search-subheader';
import {
    SearchService,
    SearchVisiblePage,
} from '../search.service';

@Component({
               selector:  'search',
               template: `
                             <div>
                                 <search-subheader #header class="is-hidden-tablet" [currentUser]="currentUser"></search-subheader>
                                 <div class="search__content">
                                     <list-empty *ngIf="isEmpty && !isLoading"
                                                 [title]="'SEARCH.EMPTY_LIST.TITLE' | translate"
                                                 [text]="'SEARCH.EMPTY_LIST.TEXT' | translate"
                                     ></list-empty>

                                     <list-container [dataSource]="listDataSource" (scroll)="onScroll($event)">
                                         <ng-template let-item>
                                             <list-item-selector two-columns-max
                                                                 [listItem]="item"
                                                                 (onOpenConversation)="onOpenConversation()"
                                             ></list-item-selector>
                                         </ng-template>
                                     </list-container>

                                     <search-list-placeholder class="search__content__placeholder" *ngIf="showLowPlaceholder"></search-list-placeholder>

                                     <div class="search__content__active-users__basic-button" *ngIf="showActiveUsersButton">
                                         <raised-button (click)="gotoPremiumForActiveUserList()" premium>{{'ACTIVE_USERS_PREVIEW.VIEW_FULL'|translate}}</raised-button>
                                     </div>
                                 </div>

                                 <search-extended #extendend
                                                  [criteria]="extendedCriteria"
                                                  (criteriaChange)="onChangeCriteria($event)"
                                 ></search-extended>

                                 <search-quick #quick
                                               [currentUser]="currentUser"
                                               [criteria]="quickCriteria"
                                               (criteriaChange)="saveQuickSearch($event)"
                                 ></search-quick>
                             </div>
                         `,
               styles: [require('./search.scss')],
           })

export class Search implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    private lastScrollPosition = 0;
    currentUser: CurrentUser = new CurrentUser();

    @ViewChild('header', { static: true }) private header: SearchSubheader;
    @ViewChild('extendend', { static: true }) private extendendSearch: SearchExtended;
    @ViewChild('quick', { static: true }) private quickSearch: SearchQuick;

    extendedCriteria: ExtendedSearchCriteria = new ExtendedSearchCriteria();
    quickCriteria: QuickSearchCriteria = new QuickSearchCriteria();

    private searchNickname: string = '';

    listDataSource: SearchDataSource | SearchUsernameDataSource | ActiveUserDataSource;

    public constructor(
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        public searchService: SearchService,
        private activatedRoute: ActivatedRoute,
        private trackingService: TrackingService,
        private dataSourceFactory: DataSourceFactory,
        private globalModalService: GlobalModalService,
        private store: Store<BKStore>
    ) {
    }

    public ngOnInit() {
        this.header.show();

        this.currentUserService.currentUser.then(currentUser => {
            this.currentUser = currentUser;

            if (!currentUser.profileImgExist()) {
                this.globalModalService.open(BkModalProfilePictureMessage.typeId);
                return;
            }
        });

        this.dataSourceFactory.createSearchDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });

        combineLatest([
                          this.searchService.extendedCriteria.pipe(takeUntil(this.unsubscribe$)),
                          this.searchService.quickCriteria.pipe(takeUntil(this.unsubscribe$)),
                          this.searchService.visiblePage$.pipe(takeUntil(this.unsubscribe$)),
                      ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(([extendedCriteria, quickCriteria, visiblePage]) => {
                this.extendedCriteria = extendedCriteria;
                this.quickCriteria = quickCriteria;

                if (visiblePage === SearchVisiblePage.NicknameSearch) {
                    this.dataSourceFactory.createSearchUsernameDataSource().then(dataSource => {
                        this.listDataSource = dataSource;
                        this.listDataSource.loadMore();
                    });
                } else if (visiblePage === SearchVisiblePage.Search) {
                    this.dataSourceFactory.createSearchDataSource().then(dataSource => {
                        this.listDataSource = dataSource;
                        this.listDataSource.loadMore();
                    });
                } else {
                    this.dataSourceFactory.createActiveUserDataSource().then(dataSource => {
                        this.listDataSource = dataSource;
                        this.listDataSource.loadMore();
                    });
                }

                this.header.layout();
            });

        this.searchService.nicknameSearch
            .pipe(
                takeUntil(this.unsubscribe$),
                skipWhile(val => val === this.searchNickname),
                debounceTime(1500),
            )
            .subscribe(res => {
                this.searchNickname = res;
                if (this.searchNickname.length >= SearchService.minNicknameSearchLength) {
                    this.listDataSource.clear();
                    this.listDataSource.loadMore();
                } else if (this.searchService.visiblePage === SearchVisiblePage.NicknameSearch) {
                    this.listDataSource.clear();
                }
            });

        if (this.currentUserService.cachedCurrentUser.isPremium() && this.activatedRoute.snapshot.fragment === SearchRouteFragment.OpenExtendedSearch) {
            setTimeout(() => this.showExtendedSearch());
        }

        document.addEventListener(SearchService.EventKeys.showQuickSearch, this.showQuickSearch);
        document.addEventListener(SearchService.EventKeys.showExtendedSearch, this.showExtendedSearch);
    }

    public ngOnDestroy(): void {
        document.removeEventListener(SearchService.EventKeys.showQuickSearch, this.showQuickSearch);
        document.removeEventListener(SearchService.EventKeys.showExtendedSearch, this.showExtendedSearch);

        this.searchService.visiblePage = SearchVisiblePage.Search;
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private showQuickSearch = (event?) => {
        if (event) this.quickSearch.show(event.detail);
        else this.quickSearch.show();
    };

    private showExtendedSearch = (event?) => {
        if (this.currentUser.isPremium()) {
            const detail = event ? event.detail : null;
            this.extendendSearch.show(detail);
        } else {
            this.premiumService.navigateToPremium(OrderReason.ExtendedSearch);
        }
    };

    get showActiveUsersButton(): boolean {
        return this.searchService.visiblePage === SearchVisiblePage.ActiveUsers && !this.currentUser.isPremium() && this.listDataSource && !this.listDataSource.isLoading;
    }

    get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    get length(): number {
        return !!this.listDataSource ? this.listDataSource.length : 0;
    }

    get showLowPlaceholder(): boolean {
        return this.length < 5 && !this.isLoading && this.searchService.visiblePage === SearchVisiblePage.Search && !this.isEmpty;
    }

    private genderTrackingMapping = {
        [Gender.Male]:   'Maenner',
        [Gender.Female]: 'Frauen',
        [Gender.Both]:   'MaennerFrauen',
    };

    saveQuickSearch(criteria: QuickSearchCriteria) {
        if (criteria.gender !== this.quickCriteria.gender) {
            this.trackingService.hit('Suche', 'GeschlechtAusgewaehlt', this.genderTrackingMapping[criteria.gender]);
        }
        this.searchService.quickCriteria.next(criteria);
    }

    onOpenConversation() {
        if (this.extendedCriteria.isEmpty) {
            this.trackingService.hit('Suche', 'Konversation', 'NachrichtBegonnen');
        } else {
            this.trackingService.hit('ErweiterteSuche', 'Konversation', 'NachrichtBegonnen');
        }
    }

    onChangeCriteria(event) {
        this.searchService.extendedCriteria.next(event);
        this.searchService.visiblePage = SearchVisiblePage.Search;
    }

    gotoPremiumForActiveUserList() {
        this.premiumService.navigateToPremium(OrderReason.ActivityList);
    }

    onScroll(scrollPosition){
        if( this.lastScrollPosition > scrollPosition ){
            if(this.lastScrollPosition - scrollPosition > 300 || scrollPosition === 0){
                this.store.dispatch(extendHeader());
                this.lastScrollPosition = scrollPosition;
            }
        }else{
            this.lastScrollPosition = scrollPosition;
            this.store.dispatch(shrinkHeader());
        }
    }
}
