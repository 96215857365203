/**
 * Created by olifra on 27.06.2017.
 */
import {
    ApiResponseFrame,
    IgnoredUser,
    IgnoreResponse,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiRequestService } from '../api-request.service';

/**
 * Ignore Api Calls
 */
export class IgnoreApi {
    public constructor(private apiRequestService: ApiRequestService,) {}

    public add(ignoreId: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.ignore.add, {
                       body: {
                           ignoreId,
                       }
                   });
    }

    public list(limit: number, offset: number = 0): Promise<ApiResponseFrame<IgnoreResponse>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<IgnoreResponse>>(Endpoints.ignore.list, {
                       query: {
                           limit,
                           offset,
                       }
                   })
                   .then(res => {
                       res.data.ignores = res.data.ignores.map(cur => IgnoredUser.create(cur));
                       return res;
                   });
    }

    public remove(ignoreId: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.ignore.remove, {
                       body:  {
                           ignoreId,
                       }
                   });
    }

}
