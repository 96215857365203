import {
    AfterViewInit,
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import {
    OrderReason,
    UserBaseView,
} from 'BKModels';
import {
    ModalService,
    TrackingService,
} from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { Tabs } from '../../../../shared/mat-design/tabs/tabs';

enum TabId {
    LastestUser,
    NearBy,
    Verified,
}

@Component({
               selector:  'user-preview',
               template:  `
                              <div class="mdc-card">
                                  <div class="user-preview">
                                      <tabs #tabbar
                                            [fitContent]="true"
                                            (change)="change($event)"
                                      >
                                          <tab [title]="'BK_HOME.TABS.LATEST_USER.TITLE' | translate"
                                               [hidden]="newHere.isEmpty()"
                                               (onClick)="track('NeuDabei')"
                                          >
                                              <bk-picture-preview-card [items]="newHere"
                                                                       [content]="'BK_HOME.TABS.LATEST_USER.CONTENT' | translate"
                                                                       (clickProfile)="openProfile($event, OrderReason.NewUser)"
                                              >

                                                  <flat-button premium
                                                               *ngIf="!premium"
                                                               (click)="premiumService.navigateToPremium(OrderReason.NewUser)"
                                                  >
                                                      {{'BK_HOME.TABS.LATEST_USER.LINK.BASIC' | translate}}
                                                  </flat-button>
                                              </bk-picture-preview-card>
                                          </tab>

                                          <tab [title]="'BK_HOME.TABS.NEAR_BY.TITLE' |translate"
                                               [hidden]="nearBy.isEmpty()"
                                               (onClick)="track('InDeinerNaehe')"
                                          >
                                              <bk-picture-preview-card [items]="nearBy"
                                                                       [content]="'BK_HOME.TABS.NEAR_BY.CONTENT' | translate"
                                                                       (clickProfile)="openProfile($event, OrderReason.NearByUser)"
                                              >

                                                  <flat-button premium
                                                               *ngIf="!premium"
                                                               (click)="premiumService.navigateToPremium(OrderReason.NearByUser)"
                                                  >
                                                      {{'BK_HOME.TABS.NEAR_BY.LINK.BASIC' | translate}}
                                                  </flat-button>
                                              </bk-picture-preview-card>
                                          </tab>


                                          <tab [title]="'BK_HOME.TABS.REAL.TITLE' | translate"
                                               [hidden]="real.isEmpty()"
                                               (onClick)="track('Verifiziert')"
                                          >
                                              <bk-picture-preview-card [items]="real"
                                                                       [content]="'BK_HOME.TABS.REAL.CONTENT' | translate"
                                                                       (clickProfile)="openProfile($event, OrderReason.RealUser)"
                                              >
                                                  
                                                  <flat-button [routerLink]="navigationService.getRoute(Routes.MyProfile)"
                                                               [fragment]="ProfileEditRouteFragment.Authenticity"
                                                  >
                                                      {{'BK_HOME.TABS.REAL.LINK.BASIC' | translate}}
                                                  </flat-button>

                                              </bk-picture-preview-card>
                                          </tab>
                                      </tabs>
                                  </div>
                              </div>
                          `,
               styles: [require('./user-preview.scss')],
           })

export class UserPreview implements AfterViewInit {
    @Input() private premium: boolean = false;
    @Input() private nearBy: UserBaseView[] = [];
    @Input() private newHere: UserBaseView[] = [];
    @Input() private real: UserBaseView[] = [];
    @ViewChild('tabbar', { static: true }) private tabbar: Tabs;

    private static _lastSelectedTab = TabId.LastestUser;

    private get lastSelectedTab(): TabId {
        if (this.tabIsEmpty(UserPreview._lastSelectedTab)) return this.firstVisibleTab();
        else return UserPreview._lastSelectedTab;
    }

    private set lastSelectedTab(tabId: TabId) {
        UserPreview._lastSelectedTab = tabId;
    }

    private Routes = Routes;
    private ProfileEditRouteFragment = ProfileEditRouteFragment;
    private OrderReason = OrderReason;

    public constructor(
        private modalService: ModalService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private trackingService: TrackingService,
    ) {
    }

    public ngAfterViewInit() {
        this.tabbar.setTabActive(this.lastSelectedTab);
    }

    private openProfile(item: UserBaseView, orderReason: OrderReason) {
        if (item.basicView) {
            this.premiumService.navigateToPremium(orderReason);
        } else {
            this.modalService.profileView.open(item.id);
        }
    }

    private change($event) {
        this.lastSelectedTab = $event;
    }

    private track(label: string) {
        this.trackingService.hit('ProfilStartseite', 'TabGeklickt', label);
    }

    private firstVisibleTab(): TabId {
        for (let x of [TabId.LastestUser, TabId.NearBy, TabId.Verified]) {
            if (!this.tabIsEmpty(x)) return x;
        }
        return TabId.Verified;
    }

    private tabIsEmpty(tabId: TabId): boolean {
        switch (tabId) {
            case TabId.LastestUser: return this.newHere.isEmpty();
            case TabId.NearBy: return this.nearBy.isEmpty();
            case TabId.Verified: return this.real.isEmpty();
        }
    }

}
