import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    Conversation,
    UserBaseView,
} from 'BKModels';
import { TrackingService } from 'BKService';
import { PremiumDetails } from '../../../../../models/premium-details';
import { UserCounts } from '../../../../../models/user-counts';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'settings-account-delete-premium-step-list',
               template:  `
                              <settings-account-delete-title [title]="'ACCOUNT.DELETE.STEP.LIST.TITLE' | translate"
                                                             [subtitle]="'ACCOUNT.DELETE.STEP.LIST.SUBTITLE' | translate"
                                                             [text]="'ACCOUNT.DELETE.STEP.LIST.TEXT' | translate"
                              ></settings-account-delete-title>

                              <cancel-list-header *ngIf="conversations.length == 2" [titleText]="'ACCOUNT.DELETE.STEP.LIST.MESSAGES' | translate" (action)="openPostbox()">
                                  <div class="settings-account-delete-step-list__item" (click)="openPostbox();">
                                      <div class="settings-account-delete-step-list__conversation" *ngFor="let conv of conversations">
                                          <div class="settings-account-delete-step-list__conversation__user">Von {{conv.partner.nickname }}:</div>
                                          <div class="settings-account-delete-step-list__conversation__msg">{{ conv.lastMessage }}</div>
                                      </div>
                                  </div>

                              </cancel-list-header>

                              <cancel-user-list *ngIf="interestedInMeUserList.length >= listSize"
                                                [userList]="interestedInMeUserList"
                                                (action)="openInterestedInMe()"
                                                [titleText]="'ACCOUNT.DELETE.STEP.LIST.INTERESTED_IN_ME.TITLE'|translate:{count:this.counts.openMatches.toString()}"
                                                [buttonText]="'ACCOUNT.DELETE.STEP.LIST.INTERESTED_IN_ME.ACTION'|translate"
                              ></cancel-user-list>
                              <cancel-user-list *ngIf="matchPartnerUserList.length >= listSize"
                                                [userList]="matchPartnerUserList"
                                                (action)="openMatchPartners()"
                                                [titleText]="'ACCOUNT.DELETE.STEP.LIST.MATCH_PARTNERS.TITLE'|translate:{count:this.counts.matches.toString()}"
                                                [buttonText]="'ACCOUNT.DELETE.STEP.LIST.MATCH_PARTNERS.ACTION'|translate"
                              ></cancel-user-list>
                              <cancel-user-list *ngIf="profileVisitorList.length >= listSize"
                                                [userList]="profileVisitorList"
                                                (action)="openProfileVisitors()"
                                                [titleText]="'ACCOUNT.DELETE.STEP.LIST.PROFILE_VISITORS.TITLE'|translate:{count:this.counts.profileVisitors.toString()}"
                                                [buttonText]="'ACCOUNT.DELETE.STEP.LIST.PROFILE_VISITORS.ACTION'|translate"
                              ></cancel-user-list>
                              <cancel-user-list *ngIf="favoritesList.length >= listSize"
                                                [userList]="favoritesList"
                                                (action)="openFavorites()"
                                                [titleText]="'ACCOUNT.DELETE.STEP.LIST.FAVORITE.TITLE'|translate:{count:this.counts.favorites.toString()}"
                                                [buttonText]="'ACCOUNT.DELETE.STEP.LIST.FAVORITE.ACTION'|translate"
                              ></cancel-user-list>

                              <cancel-list-header [noLink]="true" [titleText]="'ACCOUNT.DELETE.STEP.LIST.DELETE' | translate"></cancel-list-header>
                              
                              <premium-day-box *ngIf="premiumDetails.active.remainingDays > 0"
                                               [text]="translationKeyBox | translate:{ remainingDays: premiumDetails.active.remainingDays.toString() }"
                              ></premium-day-box>
                              
                              <settings-account-delete-footer [cancelText]="'ACCOUNT.DELETE.STEP.LIST.CANCEL' | translate"
                                                              (cancel)="onCancel($event);"
                                                              [nextText]="'ACCOUNT.DELETE.STEP.LIST.NEXT' | translate"
                                                              (next)="onNext($event);"
                              ></settings-account-delete-footer>

                          `,
               styles: [require('./settings-account-delete-step-list.scss')],
           })
export class SettingsAccountDeletePremiumStepList {
    @Output() private cancel = new EventEmitter();
    @Output() private next = new EventEmitter();

    @Input() private listSize = 5;
    @Input() private interestedInMeUserList: UserBaseView[] = [];
    @Input() private matchPartnerUserList: UserBaseView[] = [];
    @Input() private profileVisitorList: UserBaseView[] = [];
    @Input() private favoritesList: UserBaseView[] = [];
    @Input() private premiumDetails: PremiumDetails;
    @Input() private conversations: Conversation[] = [];
    @Input() private counts: UserCounts;

    constructor(private navigationService: NavigationService, private trackingService: TrackingService) {
    }

    private get  translationKeyBox(): string {
        return this.premiumDetails.active.remainingDays > 1 ? 'ACCOUNT.DELETE.REMAINING_PREMIUM.MULTIPLE' : 'ACCOUNT.DELETE.REMAINING_PREMIUM.SINGLE';
    }

    private openInterestedInMe() {
        this.track('VerlusteSinglesMoechtenDich');
        this.navigationService.navigateTo(Routes.MatchingLikeYou);
    }

    private openMatchPartners() {
        this.track('VerlusteMatchpartner');
        this.navigationService.navigateTo(Routes.MatchingPartner);
    }

    private openProfileVisitors() {
        this.track('VerlusteProfilbesucher');
        this.navigationService.navigateTo(Routes.ProfileVisitors);
    }

    private openFavorites() {
        this.track('VerlusteFavoriten');
        this.navigationService.navigateTo(Routes.Favorite);
    }

    private openPremiumOverview() {
        this.track('VerlustePremiumtage');
        this.navigationService.navigateTo(Routes.PremiumOverview);
    }

    private openPostbox() {
        this.navigationService.navigateTo(Routes.MessagesUnread);
    }

    private track(label: string, action: string = 'ProzessVerlassen') {
        this.trackingService.hit('AccountLoeschen', action, label);
    }

    private onCancel(event) {
        this.cancel.emit(event);
        this.track('VerlusteAbbrechen');
    }

    private onNext(event) {
        this.next.emit(event);
        this.track('Fortfahren', 'WeiterVerluste');
    }

}
