import { OrderReason } from './order-reason';

export enum OfferState {
    Open = 'open',
    InProgress = 'inProgress',
    Purchase = 'purchase',
    Canceled = 'canceled'
}

export enum OfferType {
    LegacyWelcomeOffer = 'legacyWelcomeOffer',
    LegacyWelcomeDelayedOffer = 'legacyWelcomeDelayedOffer',
    RecurringOffer = 'recurringOffer',
    BirthdayOffer = 'birthdayOffer',
    LoyaltyOffer = 'loyaltyOffer'
}

const offerTypeOrderReasonMap: { [key in OfferType]: OrderReason } = {
    [OfferType.LegacyWelcomeOffer]:         OrderReason.LegacyWelcomeOffer,
    [OfferType.LegacyWelcomeDelayedOffer]:  OrderReason.LegacyWelcomeDelayedOffer,
    [OfferType.RecurringOffer]:             OrderReason.RecurringOffer,
    [OfferType.BirthdayOffer]:              OrderReason.BirthdayOffer,
    [OfferType.LoyaltyOffer]:              OrderReason.LoyaltyOffer,
};

export class Offer {
    public constructor(
        public id: string,
        public offerType: OfferType,
        public state: OfferState,
        public tariffId: number,
        public expireAt: Date,
    ) {
    }

    public static create(res: Offer): Offer {
        return new Offer(res.id, res.offerType, res.state, res.tariffId, res.expireAt);
    }

    public get orderReason(): OrderReason {
        return offerTypeOrderReasonMap[this.offerType];
    }

    public static isOrderReasonFormOffer(orderReason: OrderReason): boolean {
        return Object.values(offerTypeOrderReasonMap).includes(orderReason);
    }

}