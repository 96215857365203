import {
    Component,
    Input,
} from '@angular/core';
import {
    ImageSize,
    MatchingDecision,
    ProfileInfo,
} from 'BKModels';

@Component({
               selector:  'matching-film-roll-item',
               template:  `
                              <div class="matching-film-roll-item"
                                   [ngStyle]="{'background-image' : url}"
                              ></div>
                              <div [ngClass]="'matching-film-roll-item__overlay'"
                                   [class]="class"
                              ></div>
                          `,
               styles: [require('./matching-film-roll-item.scss')],
           })

export class MatchingFilmRollItem {
    @Input() private user: ProfileInfo = new ProfileInfo();

    private get url(): string {
        return `url(${this.user.getProfilePictureUrl(ImageSize['640x854'])})`;
    }

    private get class(): string {
        switch (this.user.decisionOwner) {
            case MatchingDecision.NoDecision:
                return 'matching-film-roll-item__overlay--unmachted';
            case MatchingDecision.Yes:
                return 'matching-film-roll-item__overlay--yes';
            case MatchingDecision.No:
                return 'matching-film-roll-item__overlay--no';
            case MatchingDecision.Maybe:
                return 'matching-film-roll-item__overlay--maybe';
            default:
                return '';
        }
    }
}
