import {
    Component,
    Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import { Tariff } from '../../../../../models/tariff';
import { PaypalLink } from '../../../../../utils/paypal-link';

@Component({
               selector:  'premium-booking-page-payment-paypal',
               template:  `
                              <div class="premium-booking-page-payment-paypal__title">{{'PREMIUM.BOOKING.PAYMENT.PAYPAL.TITLE' | translate}}</div>

                              <premium-booking-page-payment-price [price]="tariff.formattedPrice"></premium-booking-page-payment-price>

                              <div class="premium-booking-page-payment-paypal__button">
                                  <paypal-button (click)="track()" [link]="this.paypalLink"></paypal-button>
                              </div>
                              <premium-booking-page-safe-paying></premium-booking-page-safe-paying>
                          `,
               styles: [require('./premium-booking-page-payment-paypal.scss')],
           })

export class PremiumBookingPagePaymentPaypal {
    @Input() private currentUser: CurrentUser;
    @Input() private tariff: Tariff;

    public constructor(
        private translate: TranslateService,
        private trackingService: TrackingService,
    ) {
    }

    private get paypalLink() {
        return new PaypalLink.Builder()
            .withActiveSubscription(this.currentUser.hasSubscription)
            .locale(this.translate.currentLang)
            .item(this.tariff, this.currentUser)
            .name(this.translate.instant(this.tariff.nameTranslationKey))
            .build();
    }

    private track() {
        this.trackingService.hit('Premium', 'ZahlungsartSeite', 'WeiterPayPal');
    }
}

