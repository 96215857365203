import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import { formatAmount } from 'BKUtils';
import * as moment from 'moment';
import {
    PremiumDetails,
    PremiumSubscription,
    SubscriptionType,
} from '../../../../../models/premium-details';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import {
    Duration,
    DurationUnit,
} from '../../../../../utils/duration';

@Component({
               selector:  'premium-info',
               template:  `
                              <div class="premium-info" *ngIf="currentUser.isPremium() && details">
                                  <p class="premium-info__text">
                                      <b>{{'PREMIUM_OVERVIEW.STATUS' | translate}}</b>: {{statusText | translate}}
                                      <icon class="premium-info__icon"
                                            icon="crown-outline"
                                            [class.premium-info__icon--premium]="currentUser.isPremium()"
                                      ></icon>
                                  </p>

                                  <ng-container *ngIf="details.active.isUnknown; else premiumDetailKnown">
                                      <p class="premium-info__text">
                                          <b>{{'PREMIUM_OVERVIEW.EXPIRED_AT' | translate}}</b>: {{expiredAt}}
                                          <span *ngIf="!details.hasSubscription" class="link" (click)="extendPremium($event)">{{'PREMIUM_OVERVIEW.EXTEND_NOW' | translate}}</span>
                                      </p>
                                  </ng-container>

                                  <ng-template #premiumDetailKnown>

                                      <p class="premium-info__text" *ngIf="!details.active.isPresent">
                                          <b>{{'PREMIUM_OVERVIEW.BOOKED_TARIF' | translate}}</b>: {{booked}}
                                      </p>
                                      <p class="premium-info__text" *ngIf="details.active.isPresent">
                                          <b>{{'PREMIUM_OVERVIEW.BOOKED_TARIF' | translate}}</b>: {{presentTariff}}
                                      </p>

                                      <p class="premium-info__text" *ngIf="!details.active.isPresent">
                                          <b>{{'PREMIUM_OVERVIEW.PAYMENT' | translate}}</b>: {{payment | translate}}
                                      </p>

                                      <p class="premium-info__text">
                                          <b>{{'PREMIUM_OVERVIEW.PAYMENT-DUE' | translate}}</b>: {{expiredAt}}
                                          <span *ngIf="!details.hasSubscription" class="link" (click)="extendPremium($event)">{{'PREMIUM_OVERVIEW.EXTEND_NOW' | translate}}</span>
                                      </p>

                                  </ng-template>

                                  <p class="premium-info__text">
                                      <a (click)="toAccountSettings()">{{'PREMIUM_OVERVIEW.ACCOUNT_SETTING' | translate}}</a>
                                  </p>

                                  <div class="premium-info__subscription" *ngFor="let subscriptionData of subscriptions">
                                      <p class="premium-info__text">
                                          <b>{{'PREMIUM_OVERVIEW.EXTENSION_TARIFF' | translate}}</b>: {{ subscriptionData.period.amount }} {{subscriptionData.period.durationTranslationKey|translate}}
                                                                                                    ({{ subscriptionData.price.toCurrencyString() }})
                                      </p>
                                      <p class="premium-info__text">
                                          <b *ngIf="subscriptionData.isPayPal">{{'PREMIUM_OVERVIEW.EXPECTED_EXTENSION' | translate}}</b>
                                          <b *ngIf="!subscriptionData.isPayPal">{{'PREMIUM_OVERVIEW.PAYMENT' | translate}}</b>: {{subscriptionData.typeTranslationKey | translate}}
                                          <icon *ngIf="subscriptionData.isPayPal" class="premium-info__text__icon" icon="info-outline" (click)="dialogBox.open()"></icon>
                                      </p>
                                      <p *ngIf="subscriptionData.isDebit">
                                          <a (click)="changePaymentData()">{{'PREMIUM_OVERVIEW.CHANGE_PAMENT' | translate}}</a>
                                      </p>
                                  </div>

                              </div>
                              <dialog-box #paypalDialog
                                          [acceptText]="'GLOBAL.OK' | translate"
                              >
                                  {{'PREMIUM_OVERVIEW.PAYPAL.DIALOG' | translate}}
                              </dialog-box>
                          `,
               styles: [require('./premium-info.scss')],
           })

export class PremiumInfo {
    @Input() private details: PremiumDetails;
    @Input() private currentUser: CurrentUser;
    @Output() private extendNow = new EventEmitter();
    @ViewChild('paypalDialog', { static: true }) private dialogBox: DialogBox;

    private Routes = Routes;

    public constructor(
        private translate: TranslateService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private get expiredAt() {
        return moment(this.details.active.expireAt).format('DD.MM.YYYY');
    }

    private get statusText(): string {
        return this.currentUser.isPremium() ? 'PREMIUM_OVERVIEW.STATUS.PREMIUM' : 'PREMIUM_OVERVIEW.STATUS.BASIC';
    }

    private getPeriodTranslation(period: Duration) {
        return `${period.amount} ${this.translate.instant(period.durationTranslationKey)}`;
    }

    private getTariff(tariffInfo: PremiumSubscription): string {
        const periodString = this.getPeriodTranslation(tariffInfo.period);
        let price = tariffInfo.price;
        let pricePeriod = '';
        if (tariffInfo.period.unit === DurationUnit.Month && tariffInfo.period.amount > 1) {
            pricePeriod = '/' + this.translate.instant(tariffInfo.period.durationTranslationKey);
            price = price / tariffInfo.period.amount;
        }

        return `${periodString} (${formatAmount(price)}${pricePeriod})`;
    }

    private get booked() {
        return this.getTariff(this.details.active);
    }

    private get presentTariff() {
        const tariffInfo = this.details.active;
        const periodString = this.getPeriodTranslation(tariffInfo.period);

        return `${periodString} ${this.translate.instant('PREMIUM_OVERVIEW.PRESENT')}`;

    }

    private get subscriptions(): PremiumSubscription[] {
        return this.details.subscriptions;
    }

    private get payment() {
        return this.details.active.typeTranslationKey;
    }

    private toAccountSettings() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'AccountBearbeiten')
        this.navigationService.navigateTo(Routes.SettingsAccount)
    }

    private changePaymentData() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'ZahlungsdatenAendern')
        this.navigationService.navigateTo(Routes.SettingChangeSepa)
    }

    private extendPremium(event) {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'JetztVerlaengernBegonnen')
        this.extendNow.emit(event);
    }
}
