import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';
import { ProfileEditHeader } from './profile-edit/profile-edit-header/profile-edit-header';
import { ProfileEdit } from './profile-edit/profile-edit/profile-edit';


const profileEditRoutes: Routes = [
    {
        path: '',
        children: [{
            path: '',
            component: ProfileEdit,
        },
            { path: '', component: ProfileEditHeader, outlet: 'header'},
        ],

    },
];


export const profileEditRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(profileEditRoutes);
