import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import { AuthenticationPremiumCancel } from '../../../app.resolver';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';
import { PremiumCancel } from './premium-cancel/premium-cancel/premium-cancel';
import { PremiumOverview } from './premium-overview/premium-overview/premium-overview';


const premiumRoute: NgRoutes = [
    {
        path:     '',
        children: [{
            path:      '',
            component: InternDefaultHeader,
            outlet:    'header', data: { title: 'LAYOUT.SIDE_NAV.PREMIUM' },
        }, {
            path:      '',
            component: PremiumOverview,
        }, {
            path:        routeName(Routes.PremiumCancel),
            component:   PremiumCancel,
            canActivate: [AuthenticationPremiumCancel],
        },
        ],
    },
];

export const PremiumRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(premiumRoute);
