import { Component } from '@angular/core';

@Component({
               selector:  'premium-booking-page-safe-paying',
               template:  `
                          <div class="premium-booking-page-safe-paying">
                              <icon icon="lock"></icon>
                              {{'PREMIUM.BOOKING.PAYMENT.SAFE_PAYING' | translate}}
                          </div>
                          `,
               styles: [require('./premium-booking-page-safe-paying.scss')],
           })

export class PremiumBookingPageSafePaying {
}
