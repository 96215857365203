import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { HttpDebugItemComponent } from './http-debug-item.component';
import { HttpDebugComponent } from './http-debug.component';
import {
    HttpDebugService,
    HttpLogger,
} from './http-debug.service';

@NgModule({
              declarations: [HttpDebugComponent, HttpDebugItemComponent],
              exports:      [HttpDebugComponent],
              imports:      [
                  CommonModule,
              ],
          })
export class HttpDebugModule {
    public static forRoot(): ModuleWithProviders<HttpDebugModule> {
        return {
            ngModule:  HttpDebugModule,
            providers: [
                HttpDebugService,
                {
                    provide:  HTTP_INTERCEPTORS,
                    useClass: HttpLogger,
                    multi:    true,
                },
            ],
        };
    }
}
