import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUser,
    FavWithNotice,
    ImageSize,
} from 'BKModels';
import {
    ApiService,
    TrackingService,
} from 'BKService';
import { FavoriteService } from '../../../../service/favorite.service';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';
import { SubModal } from '../../../../shared/mat-design/modal/sub-modal';

@Component({
               selector:  'favorite-item',
               template:  `
                              <tile [img]="item.favorite.getProfilePictureUrl(imageSize['640x640'])"
                                    [title]="item.favorite.nickname"
                                    [subtitle]="subtitle"
                                    (onClick)="openProfile()"
                              >
                                  <ng-container buttons>
                                      <card-button *ngIf="item.notice.isEmpty()" (click)="modalOpen = true"
                                      >{{'FAVORITES.BUTTON.ADD_NOTICE' | translate}}</card-button>
                                      <card-button *ngIf="!item.notice.isEmpty()" (click)="modalOpen = true"
                                      >{{'FAVORITES.BUTTON.SHOW_NOTICE' | translate}}</card-button>
                                  </ng-container>


                                  <card-action actions icon="delete" (click)="deleteDialog.open()"></card-action>
                              </tile>

                              <dialog-box #deleteDialog
                                          [title]="'FAVORITES.DIALOG.TITLE' | translate:item.favorite"
                                          [acceptText]="'GLOBAL.YES' | translate"
                                          [rejectText]="'GLOBAL.NO' | translate"
                                          (onAccept)="delete()"
                              ></dialog-box>


                              <sub-modal #favoriteNote
                                         [user]="item.favorite"
                                         [rejectText]="'FAVORITES.NOTE.REJECT-TEXT' | translate"
                                         [acceptText]="'FAVORITES.NOTE.ACCEPT-TEXT' | translate"
                                         (onAccept)="changeNote()"

                              >
                                  <text-area [placeholder]="'FAVORITES.NOTE.PLACEHOLDER' | translate | replace:'nickname':item.favorite.nickname:true"
                                             [(value)]="item.notice"
                                             rows="4"
                                             maxLength="2500"
                                             *ngIf="isModalOpen"
                                  ></text-area>
                              </sub-modal>




                          `,
               styles: [require('../../../../shared/mat-design/list/list-item-selector.scss'), require('./favorite-item.scss')],
           })
export class FavoriteItem {
    @Output('delete') private _delete: EventEmitter<any> = new EventEmitter();
    @Output('openProfile') private _openProfile = new EventEmitter<number>();
    @Input() private currentUser: CurrentUser;
    @ViewChild('deleteDialog', { static: true }) private deleteDialog: DialogBox;
    @Input() private exist: boolean = true;
    @ViewChild('favoriteNote', { static: true }) private favoriteNoticeModal: SubModal;

    @Input() private item: FavWithNotice = new FavWithNotice();

    private _modalOpen: boolean = false;
    private imageSize = ImageSize;

    @Input()
    private set modalOpen(open: boolean) {
        this._modalOpen = false;
        if (open) {
            this.favoriteNoticeModal.open();
        } else {
            this.favoriteNoticeModal.close();
        }
    }

    private get subtitle(): string {
        return this.exist ? `${this.item.favorite.age}, ${this.item.favorite.city}` : '';
    }

    public constructor(
        private favoriteService: FavoriteService,
        private translate: TranslateService,
        private trackingService: TrackingService) {
    }

    private changeNote() {
        this.trackingService.hit('Favoriten', 'NotizHinzufuegen', 'NotizHinzugefuegt')
        this.favoriteService.changeNotice(this.item.favorite.id, this.item.notice);
    }

    private delete() {
        this._delete.emit(this.item.favorite);
    }

    private openProfile() {
        if (!this.exist) return;
        this._openProfile.emit(this.item.favorite.id);
    }

    private get isModalOpen() : boolean {
        if(!this.favoriteNoticeModal)return false;

        return this.favoriteNoticeModal.isOpen;
    }
}
