/**
 * Created by olifra on 15.05.2017.
 */

import {
    ApiContactWish,
    ApiRelationship,
} from '../api-enums';


export const getEnumKeys = (enumObject: any): Array<string> => {
    return Object.keys(enumObject).filter(cur => cur !== '0' &&  !parseInt(cur,10));
};

export const getEnumValues = (enumObject: any): Array<number | string> => {
    return getEnumKeys(enumObject).map(cur => enumObject[cur]);
};

export const getEnumLength = (enumObject: any): number => {
    return getEnumKeys(enumObject).length;
};

export enum Gender {
    Male = 'm',
    Female = 'w',
    Both = 'e',
    None = '',
}

export enum ZodiacSign {
    Aquarius =  'wm',
    Fish =        'fi',
    Aries =       'wi',
    Taurus =      'st',
    Gemini =     'zw',
    Cancer =     'kr',
    Leo =         'lo',
    Virgo =       'ju',
    Libra =       'wg',
    Scorpio =     'sk',
    Sagittarius = 'sc',
    Capricorn =   'sb',
}

export enum AccountState {
    Activated,
    Deactivated,
    InRegistration,
    InRegistrationWithImage,
    PreActivated,
    OpenBill = 7,
}

// eslint-disable-next-line no-shadow
export enum Relationship {
    AskMe = 0,
    Single = 1,
    Separately = 3,
    Divorced = 4,
    Widowed = 6,
    SingleParenting = 10,
}

export const createRelationshipFromApiRelationship = (value: ApiRelationship): Relationship => {
    switch (value) {
        case ApiRelationship.FindItOut:
            return Relationship.AskMe;
        case ApiRelationship.Single:
            return Relationship.Single;
        case ApiRelationship.Married:
            return Relationship.AskMe;
        case ApiRelationship.Separately:
            return Relationship.Separately;
        case ApiRelationship.Divorced:
            return Relationship.Divorced;
        case ApiRelationship.Relationship:
            return Relationship.AskMe;
        case ApiRelationship.Widowed:
            return Relationship.Widowed;
        case ApiRelationship.Engaged:
            return Relationship.AskMe;
        case ApiRelationship.Others:
            return Relationship.AskMe;
        case ApiRelationship.OpenRelationship:
            return Relationship.AskMe;
        case ApiRelationship.SingleParenting:
            return Relationship.SingleParenting;

    }
};

export enum Online {
    NotAvailable = -1,

    Offline,
    // eslint-disable-next-line no-shadow
    Online,
    Away,
    MobilOnline,
    MobilAway,
    Invisible,
    RecentlyOnline
}

export enum SexOrientation {
    Hetero,
    Homo,
    Bi,
    Others,
}

export enum ContactWish {
    AskMe = 0,
    LetsSee = 1,
    Partnership = 2,
    Chat = 3,
    Acquaintance = 5,
    Friendship = 6,
}

export const createContactWishFromApiContactWish = (value: ApiContactWish): ContactWish => {
    switch (value) {
        case ApiContactWish.LetsSee:
            return ContactWish.LetsSee;
        case ApiContactWish.AllContacts:
            return ContactWish.LetsSee;
        case ApiContactWish.Others:
            return ContactWish.LetsSee;
        case ApiContactWish.Partnership:
            return ContactWish.Partnership;
        case ApiContactWish.EMailAcquaintance:
            return ContactWish.Chat;
        case ApiContactWish.NicePeople:
            return ContactWish.Acquaintance;
        case ApiContactWish.TravelPartner:
            return ContactWish.Friendship;
        case ApiContactWish.SportsPartner:
            return ContactWish.Friendship;
        default:
            return ContactWish.LetsSee;
    }
};

export enum Children {
    Yes = 1,
    AskMe = 5,
    No = 9,
}

export enum ChildrenWish {
    Yes = 1,
    DontKnow,
    AskMe = 5,
    No = 9,
}

export enum Smoker {
    AskMe,
    Yes,
    No,
    Sometimes,
}

export enum EyeColor {
    Undefined,
    Blue = 1,
    Green,
    Brown,
    Greyblue,
    Greygreen,
    Bluegreen,
    Grenbrown,
    Others,
}

export enum HairColor {
    Undefined,
    Blond = 1,
    Darkblond,
    Black,
    Brown,
    Gray,
    White,
    Red,
    BaldHead,
    Other,
}

export enum CustomerLifeTimePhase {
    NotLoaded = -1,
    Registration,
    Validation,
    PreValidated,
    FailedValidation,
    EarlyBasic,
    AverageBasic,
    LongtimeBasic,
    EarlyPremium,
    AveragePremium,
    LongtimePremium,
}


export enum BodyType {
    AskMe = 0,
    Slim = 1,
    Athletic = 2,
    Normal = 3,
    ABitMore = 4,
    Corpulent = 5
}

export enum Language {
    Arabic = 'ar',
    Chinese = 'zh',
    German = 'de',
    Danish = 'da',
    English = 'en',
    Finnish = 'fi',
    French = 'fr',
    Greek = 'el',
    Hindi = 'hi',
    Italian = 'it',
    Japanese = 'ja',
    Korean = 'ko',
    Croatian = 'hr',
    Dutch = 'nl',
    Norwegian = 'no',
    Polish = 'pl',
    Portuguese = 'pt',
    Russian = 'ru',
    Swedish = 'sv',
    Spanish = 'es',
    Thai = 'th',
    Turkish = 'tr',
    Vietnamese = 'vi',
    Others = 'andere',
}
