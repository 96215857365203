import { Component } from '@angular/core';
import { NavigationService } from '../../../../../../service/navigation.service';

@Component({
               selector: 'lovestory-header',
               template: `
                             <intern-header>
                                 <div left>
                                     <intern-header-left class="is-hidden-tablet"></intern-header-left>
                                     <back-arrow class="is-hidden-desktop" (back)="navigationService.navigateBack()"></back-arrow>
                                 </div>
                                 
                                <intern-header-title center>
                                    {{'LOVESTORY.TITLE' | translate}}
                                </intern-header-title>
                                
                                <intern-header-right right></intern-header-right>
                            </intern-header>   
                         
                         `,
           })

export class LovestoryHeader {
    public constructor(private navigationService: NavigationService) {
    }
}
