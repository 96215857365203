import {
    Component,
    Input,
} from '@angular/core';
import {
    AccountState,
    CurrentUser,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { leftInRightOut } from 'BKUtils';
import * as moment from 'moment';
import { BadgeCounts } from '../../../../models/badge-counts';
import { GlobalModalService } from '../../../../service/global-modal.service';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { RandomQuoteService } from '../../../../service/random-quote.service';
import { BkModalBecomeVip } from '../../../../shared/mat-design/bk-modals/bk-modal-become-vip';

@Component({
               selector:   'bk-asll',
               template:   `
                               <card class="asll">
                                   <div class="asll__quote" [class.noCounts]="!hasCounts">
                                       <div class="asll__quote-greeting">{{ greetingKey|translate:{'username': user.nickname } }}</div>
                                       <div class="asll__quote-text">„{{ (randomQuoteService.quote$|async).text }}“</div>
                                       <div class="asll__quote-source">- {{ (randomQuoteService.quote$|async).source }}</div>
                                   </div>
                                   <div *ngIf="hasCounts">
                                       <div class="asll__subtitle">{{"BK_HOME.ASLL.SUBTITLE" | translate}}</div>
                                       <div class="asll__content">
                                           <asll-item
                                                   *ngIf="inviteAsVip"
                                                   [highlighted]="true"
                                                   [showCounter]="false"
                                                   [counter]="0"
                                                   (clickLink)="showBecomeVipModal()"
                                                   icon="error-outline"
                                                   class="asll__content__item"
                                           >
                                               {{"BK_HOME.ASLL.BECOME_VIP" | translate}}
                                           </asll-item>  
                                           <asll-item
                                                   *ngIf="badgeCounts.unreadMessages > 0"
                                                   [counter]="badgeCounts.unreadMessages"
                                                   (clickLink)="toUnreadMessages()"
                                                   icon="chat-outline"
                                                   class="asll__content__item"
                                           >
                                               {{"BK_HOME.ASLL.NEW_MESSAGES" | translate}}
                                           </asll-item>
                                           <asll-item
                                                   *ngIf="badgeCounts.openMatches > 0"
                                                   [counter]="badgeCounts.openMatches"
                                                   (clickLink)="toMatchingLikeYou()"
                                                   icon="match-outline"
                                                   class="asll__content__item"
                                           >
                                               {{"BK_HOME.ASLL.NEW_MATCHES" | translate}}
                                           </asll-item>
                                           <asll-item
                                                   *ngIf="badgeCounts.profileVisitors > 0"
                                                   [counter]="badgeCounts.profileVisitors"
                                                   (clickLink)="toProfileVisitors()"
                                                   icon="visitor-outline"
                                                   class="asll__content__item"
                                           >
                                               {{"BK_HOME.ASLL.NEW_VISITOR" | translate}}
                                           </asll-item>
                                       </div>
                                   </div>
                               </card>
                           `,
               styles:  [require('./asll.scss')],
               animations: [leftInRightOut],
           })
export class Asll {
    @Input() user: CurrentUser;
    @Input() badgeCounts: BadgeCounts = new BadgeCounts();

    get inviteAsVip(): boolean {
        return this.user.accountState === AccountState.Activated && this.user.age > 20 && ['D', 'AT', 'CH'].contains(e => e === this.user.country) && this.user.logins >= 30;
    }

    get hasCounts(): boolean {
        return this.badgeCounts.unreadMessages > 0
            || this.badgeCounts.openMatches > 0
            || this.badgeCounts.profileVisitors > 0
            || this.inviteAsVip;
    }

    get greetingKey(): string {
        let hour = moment().hour();
        if (hour >= 18) return "BK_HOME.ASLL.GREETING.EVENING";
        else if (hour >= 10) return "BK_HOME.ASLL.GREETING.DAY";
        else if (hour >= 5) return "BK_HOME.ASLL.GREETING.MORNING";
        else return "BK_HOME.ASLL.GREETING.NIGHT";
    }

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        public randomQuoteService: RandomQuoteService,
        private globalModalService: GlobalModalService,
        private currentUserService: CurrentUserService
    ) {}

    private trackClick(label: string) {
        this.trackingService.hit('Startseite', 'ASLL', label);
    }

    toUnreadMessages() {
        this.navigationService.navigateTo(Routes.MessagesUnread);
        this.trackClick('NeueNachricht');
    }

    toMatchingLikeYou() {
        this.navigationService.navigateTo(Routes.MatchingLikeYou);
        this.trackClick('SinglesKennenlernen');
    }

    toProfileVisitors() {
        this.navigationService.navigateTo(Routes.ProfileVisitors);
        this.trackClick('Profilbesucher');
    }

    showBecomeVipModal() {
        this.globalModalService.open(BkModalBecomeVip.typeId, this.currentUserService.cachedCurrentUser.nickname);
    }
}
