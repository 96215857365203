import { Injectable } from '@angular/core';
import { ConversationFilter } from 'BKModels';
import {
    ActiveUserDataSource,
    ActiveUserPreviewDataSource,
    ConversationListDataSource,
    FavoriteDataSource,
    FavoriteDeletedDataSource,
    IgnoreDataSource,
    LovestoryDataSource,
    MatchingPartnerDataSource,
    MatchingSecondChanceDataSource,
    MatchingWantYouDataSource,
    MatchingWishPartnerDataSource,
    ProfilesVisitedDataSource,
    ProfilesVisitedPreviewDataSource,
    ProfileVisitorDataSource,
    ProfileVisitorPreviewDataSource,
    RadioUsersDataSource,
    SearchDataSource,
    SearchUsernameDataSource,
} from '../models/list-data-sources';
import { AdsService } from '../module/intern/ads/ads.service';
import { RadioService } from '../module/intern/schlagerparadies/radio.service';
import { SearchService } from '../module/intern/search/search.service';
import { ConversationService } from './conversation.service';
import { FavoriteService } from './favorite.service';
import { IgnoreService } from './ignore.service';
import { ListService } from './list.service';
import { LovestoryService } from './lovestory.service';
import { MatchingService } from './matching.service';
import { CurrentUserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class DataSourceFactory {

    public constructor(
        private currentUserService: CurrentUserService,
        private matchingService: MatchingService,
        private listService: ListService,
        private searchService: SearchService,
        private radioService: RadioService,
        private conversationService: ConversationService,
        private lovestoryService: LovestoryService,
        private ignoreService: IgnoreService,
        private favoriteService: FavoriteService,
        private adsService: AdsService,
    ) {
    }

    public createMatchingPartnerDataSource(): Promise<MatchingPartnerDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new MatchingPartnerDataSource(this.matchingService, !currentUser.isPremium()));
    }

    public createMatchingWishPartnerDataSource(): Promise<MatchingWishPartnerDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new MatchingWishPartnerDataSource(this.matchingService, !currentUser.isPremium()));
    }

    public createMatchingWantYouDataSource(): Promise<MatchingWantYouDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new MatchingWantYouDataSource(this.matchingService, !currentUser.isPremium()));
    }

    public createMatchingSecondChanceDataSource(): Promise<MatchingSecondChanceDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new MatchingSecondChanceDataSource(this.matchingService, !currentUser.isPremium()));
    }

    public createProfileVisitorsDataSource(): Promise<ProfileVisitorDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ProfileVisitorDataSource(this.listService, !currentUser.isPremium()));
    }

    public createProfilesVisitedDataSource(): Promise<ProfilesVisitedDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ProfilesVisitedDataSource(this.listService));
    }

    public createProfileVisitorsPreviewDataSource(): Promise<ProfileVisitorPreviewDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ProfileVisitorPreviewDataSource(this.listService));
    }

    public createProfilesVisitedPreviewDataSource(): Promise<ProfilesVisitedPreviewDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ProfilesVisitedPreviewDataSource(this.listService));
    }

    public createSearchDataSource(): Promise<SearchDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(() => new SearchDataSource(this.searchService, this.adsService));
    }

    public async createRadioUpcomingUsersDataSource(): Promise<RadioUsersDataSource> {
        return new RadioUsersDataSource(this.radioService, true);
    }

    public async createRadioPreviousUsersDataSource(): Promise<RadioUsersDataSource> {
        return new RadioUsersDataSource(this.radioService, false);
    }

    public createSearchUsernameDataSource(): Promise<SearchUsernameDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new SearchUsernameDataSource(this.searchService, !currentUser.isPremium()));
    }

    public createAllConversationListDataSource(): Promise<ConversationListDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ConversationListDataSource(this.conversationService, ConversationFilter.AllWithoutSystem, !currentUser.isPremium()));
    }

    public createUnreadConversationListDataSource(): Promise<ConversationListDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ConversationListDataSource(this.conversationService, ConversationFilter.Unread, !currentUser.isPremium()));
    }

    public createOnlySystemConversationListDataSource(): Promise<ConversationListDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ConversationListDataSource(this.conversationService, ConversationFilter.SystemOnly, !currentUser.isPremium()));
    }

    public createLovestoryDataSource(): Promise<LovestoryDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new LovestoryDataSource(this.lovestoryService, !currentUser.isPremium()));
    }

    public createIgnoreDataSource(): Promise<IgnoreDataSource> {
        return Promise.resolve(new IgnoreDataSource(this.ignoreService));
    }

    public createFavoriteDataSource(): Promise<FavoriteDataSource> {
        return Promise.resolve(new FavoriteDataSource(this.favoriteService));
    }

    public createFavoriteDeletedDataSource(): Promise<FavoriteDataSource> {
        return Promise.resolve(new FavoriteDeletedDataSource(this.favoriteService));
    }

    public createActiveUserDataSource(): Promise<ActiveUserDataSource> {
        return this.currentUserService
                   .currentUser
                   .then(currentUser => new ActiveUserDataSource(this.listService, currentUser.premium));
    }

    public createActiveUserPreviewDataSource(): Promise<ActiveUserPreviewDataSource> {
        return Promise.resolve(new ActiveUserPreviewDataSource(this.listService));
    }

}
