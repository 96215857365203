import { ProfileEditBox } from './profile-edit-box/profile-edit-box';
import { ProfileEditLook } from './profile-edit-look/profile-edit-look';
import { ProfileEditMain } from './profile-edit-main/profile-edit-main';
import { ProfileEditPersonal } from './profile-edit-personal/profile-edit-personal';
import { ProfileEditSearch } from './profile-edit-search/profile-edit-search';

export default [
    ProfileEditMain,
    ProfileEditPersonal,
    ProfileEditBox,
    ProfileEditSearch,
    ProfileEditLook,
];
