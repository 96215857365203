import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'premium-day-box',
               template:  `
                              <icon icon="warning" class="icon"></icon>
                              <div class="text" [innerHTML]="text | markdown"></div>
                          `,
               styles: [require('./premium-day-box.scss')],
           })
export class PremiumDayBox {
    @Input() private text: string;
}