import { Injectable } from '@angular/core';
import { ProfileInfo } from 'BKModels';
import { ApiService } from 'BKService';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';
import { ConversationService } from '../../../service/conversation.service';

class PoBoxBase {
    protected _reload: BehaviorSubject<any> = new BehaviorSubject({});

    public get reload(): Observable<any> {
        return this._reload.asObservable();
    }

    public constructor(protected conversationService: ConversationService) {

    }

    protected reset() {
        this._reload.next({});
    }
}


class Provider {
    private subject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public close(): void {
        this.subject.next(false);
    }

    public open(): void {
        this.subject.next(true);
    }

    public toggle(): void {
        this.subject.next(!this.subject.value);
    }

    public value(): Observable<boolean> {
        return this.subject.asObservable();
    }
}


@Injectable()
export class PoBoxService extends PoBoxBase {

    public search: Provider = new Provider();

    private _searchValue: BehaviorSubject<string> = new BehaviorSubject('');

    public get searchValue(): Observable<string> {
        return this._searchValue.asObservable();
    }

    private _selected: BehaviorSubject<string[]> = new BehaviorSubject([]);

    public get selected(): Observable<string[]> {
        return this._selected.asObservable();
    }

    public constructor(conversationService: ConversationService) {
        super(conversationService);
    }

    public delete() {
        this.conversationService
            .delete(this._selected.value)
            .then(() => this.reset());
    }

    public markAsUnread() {
        this.conversationService
            .markAsUnreadMultiple(this._selected.value)
            .then(() => {
                this.reset();
            });
    }

    public reset() {
        this._selected.next([]);
        this.search.close();
        this._searchValue.next('');
        super.reset();
    }

    public selectedAdd(id: string) {
        const arr = this._selected.value;
        arr.push(id);

        this._selected.next(arr);
    }

    public selectedRemove(id: string) {
        const arr = this._selected.value;
        const index = arr.indexOf(id);
        arr.splice(index, 1);
        this._selected.next(arr);
    }

    public setSearchValue(val: string) {
        this._searchValue.next(val);
    }

    public pinConversations(conversationIds: []){
        return this.conversationService.pinConversations(conversationIds);
    }

    public unpinConversations(conversationIds: []){
        return this.conversationService.unpinConversations(conversationIds);
    }
}
