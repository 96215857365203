import { BkConfig } from 'BKConfig';

export class Answer {
    public female: string = '';

    public male: string = '';

    public static create(answer: Answer): Answer {
        const res = new Answer();
        res.female = answer.female;
        res.male = answer.male;
        return res;
    }
}


export class LovestoryBase {
    public id: number = 0;

    public images: string[] = [];

    public mainImage: string = '';

    public nameFemale: string = '';

    public nameMale: string = '';

    public summary: string = '';

    private url: string = BkConfig.lovestoryImageApi;

    public createImg(img: string): string {
        return `${this.url}${img}.jpg`;
    }

    public get mainImageUrl(): string {
        return this.createImg(this.mainImage);
    }
}


export class Answers {
    public becomeAware = new Answer();
    public continuation = new Answer();
    public firstContact = new Answer();
    public firstDate = new Answer();
    public recommendation = new Answer();

    public static create(answers: Answers) {
        const res = new Answers();
        res.recommendation = Answer.create(answers.recommendation);
        res.firstContact = Answer.create(answers.firstContact);
        res.firstDate = Answer.create(answers.firstDate);
        res.becomeAware = Answer.create(answers.becomeAware);
        res.continuation = Answer.create(answers.continuation);
        return res;
    }
}

export class LovestoryListItem extends LovestoryBase {
    public recommendation: string;


    public static create(val): LovestoryListItem {
        const res = new LovestoryListItem();

        res.id = val.id;
        res.images = val.images;
        res.mainImage = val.mainImage;
        res.nameFemale = val.nameFemale;
        res.nameMale = val.nameMale;
        res.recommendation = val.recommendation;
        res.summary = val.summary;
        return res;
    }
}

export class Lovestory extends LovestoryBase {
    public answers = new Answers();


    public static create(val): Lovestory {
        const res = new Lovestory();

        res.id = val.id;
        res.images = val.images;
        res.mainImage = val.mainImage;
        res.nameFemale = val.nameFemale;
        res.nameMale = val.nameMale;
        res.summary = val.summary;

        res.answers = Answers.create(val.answers);

        return res;
    }

    public getImages(): string[] {
        return this.images.map(cur => this.createImg(cur));
    }
}
