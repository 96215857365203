import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../navigation.service';
import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class MissingDoubleOptInNotification extends Notification {
    public static notificationTypeId = 'd4828e61-accd-4d95-a910-fc5e87da04b6';

    public notificationId = MissingDoubleOptInNotification.notificationTypeId;

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Error;
    public importance = 1000;

    public contentKey = 'NOTIFICATION.MISSING_DOUBLE_OPTIN.TEXT';
    public titleKey = 'NOTIFICATION.MISSING_DOUBLE_OPTIN.TITLE';
    public actionKey = 'NOTIFICATION.MISSING_DOUBLE_OPTIN.ACTION';

    public foldable = true;

    public action = () => {
        this.navigationService.navigateTo(Routes.RegCode);
    }

    constructor(private navigationService: NavigationService) {
        super();
        this.contentParams = { };
    }
}
