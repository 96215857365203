import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    MatchingDecision,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import { PremiumService } from '../../../../../service/premium.service';

enum MatchingStage {
    DecisionMaking,
    NoContingent,
    Decided
}

@Component({
               selector:  'profile-matching',
               template:  `
                              <div class="profile-matching">
                                  <profile-matching-has-match *ngIf="currentUser.premium && user.isMatch" [user]="user"></profile-matching-has-match>
                                 
                                  <ng-container *ngIf="!user.isMatch || !currentUser.premium">
                                      <div class="profile-matching__title">
                                          <span *ngIf="stage === MatchingStage.DecisionMaking">{{ user.gender | i18nSelect:msgMappingMatchingTitle | translate }}</span>
                                          <span *ngIf="stage === MatchingStage.NoContingent">{{'PROFILE_VIEW.MATCHING.TITLE.CONTINGENT_USED_UP' | translate}}</span>
                                          <span *ngIf="stage === MatchingStage.Decided">{{'MATCHING.YOUR_ANSWER' | translate }}</span>
                                      </div>

                                      <div class="profile-matching__decisions" *ngIf="stage === MatchingStage.DecisionMaking">
                                          <profile-matching-decision [type]="matchingDecision.No"
                                                                     [disabled]="!hasMatchingContingent || user.isOwnMatchingDecisionPositive"
                                                                     [tonedDown]="!user.isNoOwnerDecision && !user.isOwnMatchingDecisionNo"
                                                                     (decide)="makeADecision($event)"
                                                                     pointer
                                          ></profile-matching-decision>
                                          <profile-matching-decision [type]="matchingDecision.Maybe"
                                                                     [disabled]="!hasMatchingContingent || user.isOwnMatchingDecisionYes"
                                                                     [tonedDown]="!user.isNoOwnerDecision && !user.isOwnMatchingDecisionMaybe"
                                                                     (decide)="makeADecision($event)"
                                                                     pointer
                                          ></profile-matching-decision>
                                          <profile-matching-decision [type]="matchingDecision.Yes"
                                                                     [disabled]="!hasMatchingContingent || user.isOwnMatchingDecisionMaybe"
                                                                     [tonedDown]="!user.isNoOwnerDecision && !user.isOwnMatchingDecisionYes"
                                                                     (decide)="makeADecision($event)"
                                                                     pointer
                                          ></profile-matching-decision>
                                      </div>
                                      <div class="profile-matching__last-decision" *ngIf="stage === MatchingStage.Decided">
                                          {{ lastDecisionTextKey|translate }}
                                          <div *ngIf="currentUser.premium && user.decisionOwner === matchingDecision.No">
                                              <a (click)="makeADecision(matchingDecision.Yes)">{{ 'PROFILE_VIEW.MATCHING.CHANGE_DECISION_TO_YES'|translate }}</a>
                                          </div>
                                      </div>
                                      <div class="profile-matching__premium" 
                                           *ngIf="!currentUser.premium && stage === MatchingStage.Decided && user.decisionOwner !== matchingDecision.No" 
                                           [innerHTML]="'PROFILE_VIEW.MATCHING.PREMIUM_TIP'|translate:{name:user.nickname}|markdown">
                                      </div>  
                                      <div class="profile-matching__premium" 
                                           *ngIf="!currentUser.premium && stage === MatchingStage.Decided && user.decisionOwner === matchingDecision.No" 
                                           [innerHTML]="'PROFILE_VIEW.MATCHING.PREMIUM_TIP.CHANGE_DECISION'|translate:{name:user.nickname}|markdown">
                                      </div>
                                  </ng-container>

                              </div>
                          `,
               styles: [require('./profile-matching.scss')],
           })

export class ProfileMatching {
    MatchingStage = MatchingStage;
    @Output() decide = new EventEmitter();
    matchingDecision = MatchingDecision;
    msgMappingMatchingTitle: KeyValue = {
        m: 'MATCHING.TITLE.MALE',
        w: 'MATCHING.TITLE.FEMALE',
        p: 'MATCHING.TITLE.PAIR',
    };
    @Input() user: ProfileInfo;
    @Input() currentUser = new CurrentUser();

    get lastDecisionTextKey(): string {
        switch (this.user.decisionOwner) {
            default:
            case MatchingDecision.NoDecision:
                return 'PROFILE_VIEW.MATCHING.DECISION.NONE';
            case MatchingDecision.Yes:
                return 'PROFILE_VIEW.MATCHING.DECISION.YES';
            case MatchingDecision.No:
                return 'PROFILE_VIEW.MATCHING.DECISION.NO';
            case MatchingDecision.Maybe:
                return 'PROFILE_VIEW.MATCHING.DECISION.MAYBE';
        }

    }

    constructor(private premiumService: PremiumService) {}

    get stage(): MatchingStage{
        if( this.hasMatchingContingent && this.user.isNoOwnerDecision ){
            return MatchingStage.DecisionMaking;
        } else if( !this.hasMatchingContingent ){
            return MatchingStage.NoContingent;
        }
        return MatchingStage.Decided;
    }

    get hasMatchingContingent() {
        return this.currentUser.remainingMatchingContingent > 0;
    }

    makeADecision(event: any) {
        if (!this.hasMatchingContingent) {
            this.premiumService.navigateToPremium(OrderReason.UnlimitedMatching);

            return;
        }

        if (this.stage === MatchingStage.Decided && this.user.isOwnMatchingDecisionPositive){
            return;
        }

        if (this.stage === MatchingStage.Decided && this.user.isOwnMatchingDecisionNo && !this.currentUser.premium){
            this.premiumService.navigateToPremium(OrderReason.ChangeNegativeMatchDecision);

            return;
        }

        this.decide.emit(event);
    }

}
