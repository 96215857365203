import { Location } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ImageSize,
    ProfileInfo,
} from 'BKModels';
import { ModalService } from 'BKService';
import { ChatListEntry } from '..';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'chat-header',
               template:  `

                              <subheader-menu [showBack]="true"
                                              [showMenu]="!edit"
                                              (back)="goBack()"
                              >
                                  <div class="chat-header__center" (click)="openProfileView()">
                                      <img class="chat-header__center__img" [src]="img">
                                      <div class="chat-header__center__name">{{curPartner.nickname}}</div>
                                      <icon *ngIf="isOnline" class="chat-header__center__icon"
                                            icon="dot"
                                      ></icon>
                                  </div>

                                  <div *ngIf="edit">
                                      <div class="chat-header__edit-menu">
                                          <icon-button (click)="editChange.emit(false)"
                                                       color
                                                       icon="cancel"
                                          ></icon-button>
                                          <icon-button (click)="deleteMessages.emit()"
                                                       [disabled]="selectedMessagesCount == 0"
                                                       color
                                                       icon="delete"
                                          ></icon-button>
                                      </div>
                                  </div>

                                  <subheader-menu-items>
                                      <ng-container *ngIf="!curPartner.vip">
                                          <menu-item *ngIf="!curPartner.ignore" (click)="ignoreAdd.emit($event)">{{'CHAT.HEADER.MENU.IGNORE' | translate}}</menu-item>
                                          <menu-item *ngIf="curPartner.ignore" (click)="ignoreRemove.emit($event)">{{'CHAT.HEADER.MENU.IGNORE_REMOVE' | translate}}</menu-item>
                                      </ng-container>
                                      <menu-item (click)="openSpamReport.emit()">{{'CHAT.HEADER.MENU.REPORT_CONVERSATION'|translate}}</menu-item>
                                      <menu-item (click)="editChange.emit(true)">{{'CHAT.HEADER.MENU.DELETE_MESSAGES' | translate}}</menu-item>
                                  </subheader-menu-items>
                              </subheader-menu>
                          `,
               styles: [require('./chat-header.scss')],
           })
export class ChatHeader {
    @Output() private ignoreRemove: EventEmitter<any> = new EventEmitter();
    @Input() private curPartner: ProfileInfo = new ProfileInfo();
    @Output() private ignoreAdd: EventEmitter<any> = new EventEmitter();
    @Input() private conversationId: string;
    @Input() private edit: boolean = false;
    @Input() private selectedMessages: Array<ChatListEntry> = [];

    @Output() private deleteMessages = new EventEmitter();
    @Output() private editChange = new EventEmitter();
    @Output() private openSpamReport = new EventEmitter();


    private get img(): string {
        if (!this.curPartner.getProfilePictureUrl) return '';
        return this.curPartner.getProfilePictureUrl(ImageSize['70x70']);
    }

    private get isOnline(): boolean {
        return this.curPartner.isOnline || this.curPartner.isOnlineMobile;
    }

    public constructor(
        private location: Location,
        private modalService: ModalService,
        private navigationService: NavigationService) {
    }

    private openProfileView() {
        this.modalService.profileView.open(this.curPartner.id);
    }

    private get selectedMessagesCount() {
        return this.selectedMessages.length;
    }

    private goBack() {
        this.navigationService.navigateTo(Routes.MessagesAll);
    }
}
