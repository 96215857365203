export class DialogState {
    private state: boolean = false;

    public close() {
        this.state = false;
    }

    public get(): boolean {
        return this.state;
    }

    public open() {
        this.state = true;
    }
}

export class DialogText {
    public accept: string = '';

    public content: string = '';

    public reject: string = '';

    public title: string = '';
}
