import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    interval,
    Subscription,
} from 'rxjs';
import { ActiveUserPreviewDataSource } from '../../../models/list-data-sources';
import {
    SearchService,
    SearchVisiblePage,
} from '../../../module/intern/search/search.service';
import { DataSourceFactory } from '../../../service/data-source-factory';
import { GlobalModalService } from '../../../service/global-modal.service';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';
import { PremiumService } from '../../../service/premium.service';
import { BkModalProfilePictureMessage } from '../bk-modals/bk-modal-profile-picture-message';

@Component({
               selector:  'active-users-preview',
               template:  `
                              <div #viewContainer>
                                  <div class="active-list-title">{{ 'ACTIVE_USERS_PREVIEW.TITLE'|translate }}</div>
                                  <div *ngIf="!!this.currentUser" class="active-list-info">
                                      <div class="active-list-settings">
                                          <span><icon icon="wc" class="settings-icon"></icon>{{ genderTextKey|translate }}</span>
                                          <span><icon icon="event" class="settings-icon"></icon>{{ 'ACTIVE_USERS_PREVIEW.SEARCH_AGE'|translate:ageParams }}</span>
                                      </div>
                                      <a (click)="onOpenSettings()" class="settings-link">{{ 'ACTIVE_USERS_PREVIEW.SEARCH_SETTINGS'|translate }}</a>
                                  </div>
                                  <list-container *ngIf="listDataSource" [dataSource]="listDataSource" [singleFetch]="true">
                                      <ng-template let-item>
                                          <list-item-selector two-columns
                                                              [listItem]="item"
                                                              (onOpenProfile)="onOpenProfile()"
                                          ></list-item-selector>
                                      </ng-template>
                                  </list-container>
                                  <div class="action">
                                      <ng-container *ngIf="currentUser.isPremium(); else nonPremium" >
                                        <raised-button (click)="onOpenFullList()">{{ 'ACTIVE_USERS_PREVIEW.VIEW_FULL'|translate }}</raised-button>
                                      </ng-container>
                                      <ng-template #nonPremium>
                                          <raised-button premium (click)="gotoPremium()">{{ 'ACTIVE_USERS_PREVIEW.VIEW_FULL'|translate }}</raised-button>
                                      </ng-template>
                                  </div>
                              </div>
                          `,
               styles: [require('./active-users-preview.scss')],
           })
export class ActiveUsersPreview implements OnInit, OnDestroy {
    @ViewChild('viewContainer', { read: ElementRef, static: false }) viewContainerRef: ElementRef<HTMLElement>

    private listDataSource: ActiveUserPreviewDataSource;

    private currentUserSubscription = new Subscription();

    private intervalSubscription = new Subscription();

    private currentUser: CurrentUser|null = null;

    get genderTextKey(): string {
        if (!this.currentUser) return '-';

        switch (this.currentUser.searchGender) {
            case Gender.Male:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_MALE';
            case Gender.Female:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_FEMALE';
            case Gender.Both:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_BOTH';
            case Gender.None:
            default:
                return '-';
        }
    }

    get ageParams(): { from: string, to: string } {
        if (!this.currentUser) return { from: '0', to: '0' };

        return {
            'from': this.currentUser.ageFrom.toString(),
            'to': this.currentUser.ageTill.toString(),
        };
    }

    onOpenSettings() {
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }

    onOpenProfile() {
        this.trackingService.hit('Startseite', 'AktiveMitglieder', 'ProfilAufgerufen');
    }

    onOpenFullList() {
        this.searchService.visiblePage = SearchVisiblePage.ActiveUsers;
        this.navigationService.navigateTo(Routes.Search);
    }

    public gotoPremium() {
        if (!this.currentUser.profileImgExist()) {
            this.globalModalService.open(BkModalProfilePictureMessage.typeId);
        } else {
            this.premiumService.navigateToPremium(OrderReason.ActivityListPreview);
        }
    }

    public ngOnInit(): void {
        this.currentUserSubscription = this.currentUserService.currentUserObservable.subscribe(value => {
            this.currentUser = value;
        });
        this.dataSourceFactory.createActiveUserPreviewDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.refresh();

            // Regular updates
            this.intervalSubscription = interval(5 * 60 * 1000).subscribe(() => {
                if (this.viewContainerRef && this.viewContainerRef.nativeElement.offsetParent !== null) {
                    this.listDataSource.refresh();
                }
            });
        });
    }

    public ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
        this.intervalSubscription.unsubscribe();
    }

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private currentUserService: CurrentUserService,
        private premiumService: PremiumService,
        private searchService: SearchService,
        private globalModalService: GlobalModalService
    ) {}
}
