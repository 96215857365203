import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    ApiResponseFrame,
    BadgeCounts,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import {
    Offer,
    OfferType,
} from '../../../../models/offer';
import { ListService } from '../../../../service/list.service';
import { NotificationType } from '../../../../service/notification/Notification';
import { NotificationService } from '../../../../service/notification/notification.service';
import { OfferService } from '../../../../service/offer.service';
import { OnBoarding } from '../on-boarding/on-boarding';

@Component({
               selector:  'bk-startpage',
               template:  `
                              <div class="mdc-layout-grid">
                                  <ng-container *ngFor="let item of (notificationService.notifications$|async)">
                                      <notification-box *ngIf="item.hasType(NotificationType.StartPage)" [notification]="item" [elevated]="true"></notification-box>
                                  </ng-container>

                                  <div class="mdc-layout-grid__inner" *ngIf="offersWithBanner.length > 0">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <banner-selector [offer]="offersWithBanner[0]"></banner-selector>
                                      </div>
                                  </div>

                                  <div class="mdc-layout-grid__inner" *ngIf="showOnBoarding">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <on-boarding [currentUser]="currentUserService.currentUserObservable | async"></on-boarding>
                                      </div>
                                  </div>
                                  <div class="mdc-layout-grid__inner">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <bk-asll [user]="currentUserService.currentUserObservable | async" [badgeCounts]="badgeCounts"></bk-asll>
                                          <ad-slot slotName="BkRL_Home_unterASLL"></ad-slot>
                                      </div>
                                  </div>
                                  <ad-slot slotName="BkRL_Home_unterASLL"></ad-slot>
                                  <div *ngIf="showQuestions" class="mdc-layout-grid__inner" >
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <profile-interview (noQuestions)="showQuestions = false;"></profile-interview>
                                      </div>
                                  </div>
                                  <div class="mdc-layout-grid__inner" *ngIf="(matchingService.matchingQueue|async).length > 0">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <user-daily-suggestion [premium]="(currentUserService.currentUserObservable|async).isPremium()" 
                                                                 [contingent]="(currentUserService.currentUserObservable|async).remainingMatchingContingent">
                                          </user-daily-suggestion>
                                      </div>
                                  </div>
                                  <div class="mdc-layout-grid__inner" *ngIf="showPreview">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <user-preview [premium]="(currentUserService.currentUserObservable | async).isPremium()"
                                                        [nearBy]="nearBy"
                                                        [newHere]="newHere"
                                                        [real]="real"
                                          ></user-preview>
                                      </div>
                                  </div>
                                  <div class="mdc-layout-grid__inner" *ngIf="showSelectedUsers">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <selected-partner [userList]="selectedUserList"></selected-partner>
                                      </div>
                                  </div>
                                  <div class="mdc-layout-grid__inner">
                                      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                          <lovestory-card></lovestory-card>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [
                   require('./startpage.scss'),
               ],
           })
export class Startpage implements OnInit, OnDestroy {
    NotificationType = NotificationType;

    private currentUserSubscription = new Subscription();

    showOnBoarding = false;

    showQuestions = true;

    @ViewChild(OnBoarding, { static: true }) private onBoarding: OnBoarding;

    selectedUserList: ProfileInfo[] = [];
    nearBy: UserBaseView[] = [];
    newHere: UserBaseView[] = [];
    real: UserBaseView[] = [];
    badgeCounts: BadgeCounts = new BadgeCounts();
    private offers: Offer[];

    get showPreview(): boolean {

        let user = this.currentUserService.cachedCurrentUser;
        if(user.isInRegistration() || user.isInRegistrationWithImage()){
            return false;
        }

        return !this.real.isEmpty() || !this.newHere.isEmpty() || !this.nearBy.isEmpty();
    }

    get showSelectedUsers(): boolean{
        let user = this.currentUserService.cachedCurrentUser;
        return !(user.isInRegistration() || user.isInRegistrationWithImage());
    }

    get offersWithBanner(): Offer[] {
        if( !this.offers ) return [];

        return this.offers.filter((offer: Offer) => {
            return offer.offerType === OfferType.LegacyWelcomeOffer
                || offer.offerType === OfferType.LegacyWelcomeDelayedOffer
                || offer.offerType === OfferType.BirthdayOffer
                || offer.offerType === OfferType.LoyaltyOffer
                || offer.offerType === OfferType.RecurringOffer;
        });
    }

    public constructor(
        public currentUserService: CurrentUserService,
        private listService: ListService,
        private offerService: OfferService,
        public notificationService: NotificationService,
        public matchingService: MatchingService,
    ) {
    }

    public ngOnInit(): void {
        this.offerService.loadOffer().then(offers => {
            this.offers = offers;
        });

        this.currentUserSubscription = this.currentUserService.currentUserObservable.subscribe(currentUser => {
            this.showOnBoarding = !currentUser.profileImgExist() || !currentUser.filledMandatoryDetails;
            this.listService.selectedUser(5, 0, currentUser.isPremium())
                .then(list => this.selectedUserList = list.result);
        });

        this.currentUserService.refreshBadgeCount().then(badgeCounts => this.badgeCounts = badgeCounts);

        this.listService.latestUser(BkConfig.bkHomePicLimit, 0).then((res: ApiResponseFrame<any>) => this.newHere = res.data.result);
        this.listService.userNearBy(BkConfig.bkHomePicLimit, 0).then((res: ApiResponseFrame<any>) => this.nearBy = res.data.result);
        this.listService.realUser(this.currentUserService.cachedCurrentUser, BkConfig.bkHomePicLimit, 0).then((res: ApiResponseFrame<any>) => this.real = res.data.result);
    }

    public ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
    }

}
