/**
 * Created by olifra on 24.05.2017.
 */

import {
    HttpClient,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BkConfig } from 'BKConfig';
import {
    ApiRequestOptions,
    ApiResponseFrame,
    ErrorNumber,
    IApiRequestOptions,
} from 'BKModels';
import {
    dispatchForcedLogoutEvent,
    Endpoint,
    Endpoints,
} from 'BKUtils';
import {
    Observable,
    Subscriber,
    throwError as observableThrowError,
    TimeoutError,
} from 'rxjs';
import {
    catchError,
    map,
    takeUntil,
    timeout,
} from 'rxjs/operators';

import { StoreService } from '../storage/store.service';

type HttpClientResponse<T> = Observable<HttpResponse<T>>;

interface Options {
    headers: {
        [header: string]: string | string[];
    },
    observe: 'response'
    withCredentials: boolean,
}

enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
}

/**
 * Apirequest Base Klasse
 */
@Injectable({ providedIn: 'root' })
export class ApiRequestService {
    static readonly defaultOption: IApiRequestOptions = {
        endpointValue: {},
        query:         {},
        body:          {},
        json:          false,
    };

    public constructor(
        private http: HttpClient,
        private router: Router,
        private storeService: StoreService
    ) {
    }

    public cancelRequest(sub: Subscriber<any>) {
        if (sub) sub.error('cancel');
    }

    public createGet<T>(endpoint: Endpoint, options: IApiRequestOptions = ApiRequestService.defaultOption): Promise<T> {
        let query = options.query || {};
        Object.assign(query, options.body || {});

        const finalEndpoint = this.createFinalEndpoint(endpoint, options.endpointValue, query);
        const request = this.http.get<T>(finalEndpoint, this.createOptions());
        return this.wrapRequest<T>(request, options);
    }

    public createPatch<T>(endpoint: Endpoint, options: IApiRequestOptions = ApiRequestService.defaultOption): Promise<T> {
        const finalEndpoint = this.createFinalEndpoint(endpoint, options.endpointValue, options.query);
        const body = options.json ? options.body : this.createFormData(options.body);
        const request = this.http.patch<T>(finalEndpoint, body, this.createOptions());
        return this.wrapRequest<T>(request, options);
    }

    public createPost<T>(endpoint: Endpoint, options: IApiRequestOptions = ApiRequestService.defaultOption): Promise<T> {
        const finalEndpoint = this.createFinalEndpoint(endpoint, options.endpointValue, options.query);
        const body = options.json ? options.body : this.createFormData(options.body);
        const request = this.http.post<T>(finalEndpoint, body, this.createOptions());

        return this.wrapRequest<T>(request, options);
    }

    private createFinalEndpoint(endpointBlock: Endpoint, urlValues: KeyValue = {}, query: KeyValue = {}): string {
        const path = BkConfig.apiPath;
        const endpoint = endpointBlock(urlValues);
        const formData = this.createFormData(query);
        // Ignore the following line because it actually is defined to work in JS
        // @ts-ignore
        const searchParams = new URLSearchParams(formData.entries());
        return `${path}/${endpoint}?${searchParams}`;
    }

    private createOptions(): Options {
        return {
            headers:         {
                Authorization:   `Bearer ${this.storeService.token.getValues()}`,
                'X-App-Version': BkConfig.version,
            },
            withCredentials: true,
            observe:         'response',
        };
    }

    // eslint-disable-next-line consistent-return
    private errorHandler(error: any) {
        if (error instanceof TimeoutError) {
            return {
                'error': {
                    'error': 'request timeout',
                },
            };
        }

        if (error === 'cancel') {
            return;
        }

        if (!error.ok && error.statusText !== 'Unknown Error') {
            if (error.status === 401 && error.url.indexOf(Endpoints.authentication.login()) === -1) {
                dispatchForcedLogoutEvent();
                return;
            }
        }
        return observableThrowError(error);
    }

    private createFormData(data: KeyValue): FormData {
        const body = new FormData();

        this.createFormDataRecursive(body, [], data);

        return body;
    }

    private createFormDataRecursive(formData: FormData, path: string[], data: any) {
        if (typeof data === 'undefined') return;

        if (typeof data === 'function') return;
        else if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                this.createFormDataRecursive(formData, [...path, i.toString()], data[i]);
            }
        }
        else if (typeof data === 'object') {
            const keyName = path.map((v, i, array) => i > 0 ? `[${v}]` : `${v}`).join('');
            if (data instanceof File) {
                formData.append(keyName, data);
            } else if (data.hasOwnProperty('filename')) {
                formData.append(keyName, data.content, data.filename);
            }else{
                for (const key in data) {
                    this.createFormDataRecursive(formData, [...path, key], data[key]);
                }
            }
        }
        else {
            formData.append(path.map((v, i, array) => i > 0 ? `[${v}]` : `${v}`).join(''), data);
        }
    }

    private handelPayloadFrame(res: ApiResponseFrame<any>) {
        if (res !== null && res.error) {
            if (res.errno === ErrorNumber.GENERAL_FORBIDDEN || res.errno === ErrorNumber.GENERAL_NO_SUBJECT_ID) {
                dispatchForcedLogoutEvent();
            }

            throw res;
        }

    }

    private resolveArray(name: string, obj: KeyValue, body: FormData) {
        Object.keys(obj).forEach(key => {
            const cur = obj[key];
            if (typeof cur !== 'function') {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((cur, i) => {
                        body.append(`${name}[${key}][${i}]`, cur);

                    });
                } else {
                    body.append(`${name}[${key}]`, obj[key]);
                }
            }
        });
    }

    private wrapRequest<T>(request: HttpClientResponse<T>, options: IApiRequestOptions): Promise<T> {
        const validOptions = ApiRequestOptions.create(options);
        const apiCall = request.pipe(
            takeUntil(validOptions.observable),
            map((res: any) => {
                this.handelPayloadFrame(res.body);
                return res.body;
            }),
            timeout(90000), //todo: revert to 10000 when database latency is restored to a normal level
            catchError(this.errorHandler.bind(this)),
        );
        return apiCall.toPromise();
    }
}

