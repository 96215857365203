import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import * as moment from 'moment';

@Component({
               selector:  'audio-player',
               template:  `
                              <div>
                                  <div class="controls">
                                      <icon icon="replay-10" class="icon" (click)="rewind10()"></icon>
                                      <icon *ngIf="!playing" icon="play-arrow" class="icon" (click)="play()"></icon>
                                      <icon *ngIf="playing" icon="pause-black-24dp" class="icon" (click)="pause()"></icon>
                                      <icon icon="stop" class="icon" (click)="stop()"></icon>
                                      <span class="timer">{{ currentPosition }} | {{ length }}</span>
                                  </div>
                              </div>
                          `,
               styles: [require('./audio-player.scss')],
           })

export class AudioPlayer implements OnInit, OnDestroy {
    @Input() private autoPlay: boolean = false;
    @Input() private audioSource: string;

    private _playing = false;
    @Output()
    private get playing(): boolean {
        return this._playing;
    }

    private set playing(v: boolean) {
        this._playing = v;
    }

    private _playable = false;
    @Output()
    private get playable(): boolean {
        return this._playable;
    }

    private set playable(v: boolean) {
        this._playable = v;
    }

    private audio = new Audio();

    private length: string = '--:--';
    private currentPosition: string = '--:--';

    private eventHandlers: { name: string, handler: any }[] = [
        { name: 'canplaythrough', handler: ev => this.onCanPlayThrough(ev) },
        { name: 'timeupdate', handler: ev => this.onTimeUpdate(ev) },
        { name: 'ended', handler: ev => this.onEnded(ev) },
    ];

    public ngOnDestroy(): void {
        this.stop();
        this.removeEventHandlers();
    }

    public ngOnInit(): void {
        this.addEventHandlers();

        this.audio.src = this.audioSource;
        this.audio.load();
    }

    private addEventHandlers() {
        for (var h of this.eventHandlers) this.audio.addEventListener(h.name, h.handler);
    }

    private removeEventHandlers() {
        for (var h of this.eventHandlers) this.audio.removeEventListener(h.name, h.handler);
    }

    play() {
        this.playing = true;
        this.audio.play();
    }

    pause() {
        this.playing = false;
        this.audio.pause();
    }

    stop() {
        this.playing = false;
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    rewind10() {
        this.audio.currentTime = Math.max(0, this.audio.currentTime - 10);
    }

    private onCanPlayThrough(event: Event) {
        this.playable = true;
        const format = this.audio.duration >= 3600 ? 'HH:mm:ss' : 'mm:ss';
        this.length = moment.utc(this.audio.duration * 1000).format(format);
        this.currentPosition = moment.utc(this.audio.currentTime * 1000).format(format);

        if (this.autoPlay) {
            this.play();
        }
    }

    private onTimeUpdate(event: Event) {
        const format = this.audio.duration >= 3600 ? 'HH:mm:ss' : 'mm:ss';
        this.currentPosition = moment.utc(this.audio.currentTime * 1000).format(format);
    }

    private onEnded(event: Event) {
        this.stop();
    }
}
