import {
    AfterViewInit,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderReason } from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { getScrollContainer } from 'BKUtils';
import { PremiumLandingPageFragment } from '../../../../../service/navigation.service';
import { PremiumLandingPageOffers } from '../premium-landing-page-offers/premium-landing-page-offers';

@Component({
               selector:  'premium-landing-page',
               template:  `
                              <premium-landing-page-header></premium-landing-page-header>
                              <premium-landing-page-usp (buttonClick)="scrollToOffers()"
                                                        [orderReason]="orderReason"
                                                        [currentUser]="currentUserService.cachedCurrentUser"
                              ></premium-landing-page-usp>
                              <premium-landing-page-offers #premiumLandingPageOffers [orderReason]="orderReason"></premium-landing-page-offers>
                              <premium-landing-page-testimonials></premium-landing-page-testimonials>
                              <premium-landing-page-awards></premium-landing-page-awards>
                              <premium-landing-page-faq></premium-landing-page-faq>
                              <premium-landing-page-footer></premium-landing-page-footer>
                          `,
               styles: [require('./premium-landing-page.scss')],
           })

export class PremiumLandingPage implements AfterViewInit, OnInit {
    @ViewChild('premiumLandingPageOffers', { static: true }) private premiumLandingPageOffers: PremiumLandingPageOffers;
    private orderReason: OrderReason = OrderReason.None;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit() {
        getScrollContainer().scroll({ top: 0 });
    }

    public ngAfterViewInit() {
        const currentRoute = this.activatedRoute.snapshot;

        if (currentRoute.fragment === PremiumLandingPageFragment.Offers) this.premiumLandingPageOffers.scrollToOffers();

        this.orderReason = OrderReason.fromActivatedRoute(this.activatedRoute);
    }

    private scrollToOffers() {
        this.trackingService.hit('Premium','KaufenSeite', 'AbosAnzeigen');
        this.premiumLandingPageOffers.scrollToOffers();
    }
}
