import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'settings-account-delete-step-recommendation',
               template:  `

                              <div class="settings-account-delete-step-recommendation__headline">{{'ACCOUNT.DELETE.STEP.RECOMMENDATION.HEADLINE' | translate}}</div>

                              <div class="settings-account-delete-step-recommendation__radio"
                                   [class.settings-account-delete-step-recommendation__radio--margin]="recommended === undefined"
                              >
                                  <radio [value]="true"
                                         [label]="'GLOBAL.YES'"
                                         [small]="true"
                                         [checked]="recommended"
                                         (select)="recommended = $event"
                                  ></radio>

                                  <radio [value]="false"
                                         [label]="'GLOBAL.NO'"
                                         [small]="true"
                                         [checked]="!recommended && recommended != undefined"
                                         (select)="recommended = $event"
                                  ></radio>
                              </div>

                              <text-area [placeholder]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.REASON' | translate"
                                         [rows]="3"
                                         *ngIf="recommended != undefined"
                                         [(value)]="recommendedText"
                                         class="settings-account-delete-step-recommendation__text-area"
                                         [disabled]="apiCallRun"
                              ></text-area>

                              <div class="settings-account-delete-step-recommendation__headline">{{'ACCOUNT.DELETE.STEP.RECOMMENDATION.PASSWORD.HEADLINE' | translate}}</div>

                              <div class="settings-account-delete-step-recommendation__password">
                                  <text-field class="settings-account-delete-step-recommendation__password__input"
                                              [placeholder]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.PASSWORD.LABEL' | translate"
                                              [value]="password"
                                              (valueChange)="password = $event; errorChange.emit(false)"
                                              type="password"
                                              [valid]="!error"
                                              [errorText]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.PASSWORD.ERROR' | translate"
                                              [disabled]="apiCallRun"
                                  ></text-field>
                                  <div class="settings-account-delete-step-recommendation__password__forgotten">
                                      <a (click)="toForgotPassword()">{{'ACCOUNT.DELETE.STEP.RECOMMENDATION.PASSWORD.FORGOT_PASSWORD' | translate}}</a>
                                  </div>
                              </div>

                              <settings-account-delete-footer [nextText]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.NEXT' | translate"
                                                              (next)="deleteProfile()"
                                                              [cancelText]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.CANCEL' | translate"
                                                              (cancel)="cancel.emit($event);trackingService.hit('AccountLoeschen','LetzteSeite','Abbrechen')"
                                                              [disabledNext]="isDisabeled"
                              >
                                  <div class="settings-account-delete-step-recommendation__disclaimer" [innerHTML]="'ACCOUNT.DELETE.STEP.RECOMMENDATION.DISCLAIMER' | translate | markdown">

                                  </div>
                              </settings-account-delete-footer>

                          `,
               styles: [require('./settings-account-delete-step-recommendation.scss')],
           })

export class SettingsAccountDeleteStepRecommendation {
    @Output() private cancel = new EventEmitter();
    @Output('deleteProfile') private _deleteProfile = new EventEmitter();
    @Input() private error: boolean = false;
    @Input() private apiCallRun: boolean = false;
    @Output() private errorChange = new EventEmitter();

    private recommended: boolean;
    private recommendedText: string = '';
    private password: string = '';


    public constructor(private navigationService: NavigationService, private trackingService: TrackingService) {
    }


    private get forgotPasswordRoute(): string[] {
        return this.navigationService.getRoute(Routes.SendRecoveryEmail);
    }

    private toForgotPassword() {
        this.navigationService.navigateTo(Routes.SendRecoveryEmail);
        this.trackingService.hit('AccountLoeschen', 'Passwort', 'PasswortVergessen');
    }

    private get isDisabeled(): boolean {
        return this.password.isEmpty() || this.recommended === undefined || this.apiCallRun;
    }


    private deleteProfile() {
        this.trackingService.hit('AccountLoeschen', 'BKWeiterempfehlen', this.recommended ? 'Ja' : 'Nein');
        this.trackingService.hit('AccountLoeschen', 'Bestaetigung', 'ProfilLoeschen');

        this._deleteProfile.emit({
                                     recommended:     this.recommended,
                                     recommendedText: this.recommendedText,
                                     password:        this.password,
                                 });
    }
}
