import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Modal } from '../../../../shared/mat-design/modal/modal';

@Component({
               selector:  'search-modal',
               template:  `
                              <modal #modal no-border no-padding top-bond>
                                  <div class="search-modal">
                                      <div class="search-modal__title">
                                          {{title}}
                                      </div>

                                      <ng-content></ng-content>

                                      <footer class="mdc-dialog__actions search-modal__actions">
                                          <flat-button class="search-modal__button"
                                                       (click)="hide(); onCancel.emit()"
                                          >
                                              {{"SEARCH.EXTENDED.CANCEL" | translate}}
                                          </flat-button>
                                          <raised-button class="search-modal__button"
                                                         [disabled]="disable"
                                                         (click)="hide(); onSave.emit()"
                                          >
                                              {{"SEARCH.EXTENDED.SEARCH" | translate}}
                                          </raised-button>
                                      </footer>
                                  </div>
                              </modal>
                          `,
               styles: [require('./search-modal.scss')],
           })

export class SearchModal {
    @Input() private title = '';
    @ViewChild('modal', { static: true }) private modal: Modal;

    @Output('cancel') private onCancel = new EventEmitter();
    @Output('save') private onSave = new EventEmitter();
    @Input()private disable: boolean = false;

    public show() {
        this.modal.open();
    }

    public hide() {
        this.modal.close();
    }

}
