import {
    Component,
    HostListener,
    Input,
} from '@angular/core';
import { HttpDebugService } from './http-debug.service';
import { LogEntry } from './log-entry';

@Component({
               selector: 'http-debug-item',
               template: `
                             <div class="http-debug-item"
                                  [class.http-debug-item--error]="item.error"
                                  (click)="click()"
                             >
                                 {{item.getSendTime()}} - {{item.getResponseTime()}} - {{item.urlTrim()}}
                             </div>`,
               styles:   [
                   `
                       .http-debug-item {
                           border-bottom: 1px solid lightgray;
                           cursor: pointer;
                           color: #1E88E5;
                       }

                       .http-debug-item:hover {
                           color: #42A5F5;
                       }

                       .http-debug-item--error {
                           color: #D32F2F;
                       }

                       .http-debug-item--error:hover {
                           color: #F44336;
                       }
                       `,
               ],
           })

export class HttpDebugItemComponent {
    @Input() private item: LogEntry;

    public constructor(private _httpDebug: HttpDebugService) {
    }

    private click() {
        this._httpDebug.setClicked(this.item.id);
        const win = window.open(this.item.debugTokenLink, '_blank');
        win.focus();
    }

    @HostListener('mousedown', ['$event'])
    private onClick($event: MouseEvent) {
        if ($event.button === 1) {
            this.click();
        }
    }
}
