import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Settings,
} from 'BKModels';
import {
    CurrentUserService,
    SettingsService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderService } from '../../../service/header.service';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { SessionService } from '../../../service/session.service';

@Component({
               selector:  'intern-header-right',
               template:  `
                              <div class="intern-header-right">
                                  <div class="intern-header-right__premium-icon"
                                       [class.intern-header-right__premium-icon--active]="currentUser.premium"
                                       (click)="toPremiumOverview()"
                                  >
                                      <icon icon="crown-outline"></icon>
                                  </div>
                                  <account-menu (logout)="sessionService.logout()"
                                                [settings]="settings"
                                  ></account-menu>
                                  <ng-content></ng-content>
                              </div>
                          `,
               styles: [require('./intern-header-right.scss')],
           })

export class InternHeaderRight {
    @Input() private currentUser = new CurrentUser();
    @Input() private settings: Settings = new Settings();

    public constructor(
        private headerService: HeaderService,
        private sessionService: SessionService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private toPremiumOverview() {
        this.trackingService.hit('RechteNavi', "HeaderGeklickt", 'Krone')
        this.navigationService.navigateTo(Routes.PremiumOverview);
    }
}
