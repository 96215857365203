import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUser,
    Image,
    ImageStatus,
} from 'BKModels';
import {
    ApiService,
    CommunicationService,
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { getScrollContainer } from 'BKUtils';
import {
    Subject,
    Subscription,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ImageService } from '../../../../../service/image.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import { dataUriToFile } from '../../../../../utils/file-converter';
import { ImageUploadModal } from '../../../../../shared/mat-design/image-upload-modal/image-upload-modal';


@Component({
               selector:   'edit-images-layout',
               'template': `
                               <div class="edit-images__description">
                                   <div class="edit-images__description__description markdown first-last-nomargin" [innerHTML]="'EDIT-IMAGES.DESCRIPTION' | translate | markdown"></div>
                               </div>

                               <div class="edit-images">
                                   <edit-image-item
                                           *ngFor="let image of userImages"
                                           class="edit-images__list-item"
                                           [image]="image"
                                           (showStatus)="openImageStatusModal($event)"
                                   >
                                       <menu class="edit-images__list-item__menu" small>
                                           <menu-item [disabled]="!image.canChangeToMain()"
                                                      (click)="useAsMain(image)"
                                                      iconName="profile-outline"
                                           >{{ 'EDIT-IMAGES.MENU.USE_AS_MAIN' | translate }}</menu-item>
                                           <menu-item [disabled]="!image.canChangeToDeactivated()"
                                                      (click)="deactivate(image)"
                                                      iconName="visibility-off"
                                           >{{ 'EDIT-IMAGES.MENU.CHANGE_TO_DEACTIVATED' | translate }}</menu-item>
                                           <menu-item [disabled]="!image.canChangeToActivated()"
                                                      (click)="activate(image)"
                                                      iconName="visibility"
                                           >{{ 'EDIT-IMAGES.MENU.CHANGE_TO_ACTIVATED' | translate }}</menu-item>
                                           <menu-item [disabled]="!image.canChangeToDeleted()"
                                                      (click)="openDeleteImageDialog(image)"
                                                      iconName="delete"
                                           >{{ 'EDIT-IMAGES.MENU.DELETE' | translate }}
                                           </menu-item>
                                       </menu>

                                   </edit-image-item>

                                   <images-drag-drop class="edit-images__list-item" (onDrop)="selectImage($event)" [disabled]="uploadDisabled"></images-drag-drop>
                               </div>

                               <div class="edit-images__button-holder">
                                   <image-upload #imageUpload (selectImg)="selectImage($event)"
                                                 [id]="'profile-image-upload-input'"
                                                 [disabled]="uploadDisabled"
                                                 class="edit-images__button-holder__button"
                                   >
                                       {{'EDIT-IMAGES.ADD-IMAGES-BUTTON'|translate}}
                                   </image-upload>

                               </div>
                               <hint-box [title]="'EDIT-IMAGES.HINT-BOX.TITLE'|translate">
                                   <list-checked role="content"
                                                 [list]="['EDIT-IMAGES.HINT-BOX.HINT1'|translate,'EDIT-IMAGES.MAX-FILE-SIZE.TYPES'|translate,'EDIT-IMAGES.MAX-FILE-SIZE.SIZE'|translate]"
                                   ></list-checked>

                               </hint-box>

                               <dialog-box #dialogDeleteImage
                                           type="warning"
                                           [title]="'EDIT-IMAGES.DIALOG.DELETE.IMAGE.TITLE' | translate"
                                           [acceptText]="'EDIT-IMAGES.DIALOG.DELETE.IMAGE.ACCEPT.TEXT' | translate"
                                           [rejectText]="'EDIT-IMAGES.DIALOG.DELETE.IMAGE.REJECT.TEXT' | translate"
                                           (onAccept)="deleteUserImage(deleteImage)"
                               >
                                   <div class="delete-image-dialog">
                                       <div class="delete-image-dialog__text">{{'EDIT-IMAGES.DIALOG.DELETE.IMAGE.DESCRIPTION' | translate}}</div>
                                       <img class="delete-image-dialog__image" src="{{deleteImage.getUrl('285x285')}}">
                                   </div>

                               </dialog-box>

                               <dialog-box #dialogVerified type="success" [title]="'EDIT-IMAGES.DIALOG.VERIFIED.TITLE'|translate" [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate">
                                   {{ 'EDIT-IMAGES.DIALOG.VERIFIED.CONTENT'|translate }}
                               </dialog-box>
                               <dialog-box #dialogRejected type="error"
                                           [title]="'EDIT-IMAGES.DIALOG.REJECTED.TITLE'|translate"
                                           [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate"
                                           [acceptText]="(userImages.length < maxImageLength) ? ('EDIT-IMAGES.ADD-IMAGES-BUTTON'|translate) : ''"
                                           (onAccept)="imageUpload.openFileUpload()"
                               >
                                   {{ 'EDIT-IMAGES.DIALOG.REJECTED.CONTENT'|translate }}
                               </dialog-box>

                               <dialog-box #dialogReview type="info"
                                           [title]="'EDIT-IMAGES.DIALOG.REVIEW.TITLE'|translate"
                                           [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate"
                               >
                                   {{ 'EDIT-IMAGES.DIALOG.REVIEW.CONTENT'|translate }}
                               </dialog-box>

                               <dialog-box #dialogUnsuitable type="warning"
                                           [title]="'EDIT-IMAGES.DIALOG.UNSUITABLE.TITLE'|translate"
                                           [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate"
                                           [acceptText]="(userImages.length < maxImageLength) ? ('EDIT-IMAGES.ADD-IMAGES-BUTTON'|translate) : ''"
                                           (onAccept)="imageUpload.openFileUpload()"
                               >
                                   {{ 'EDIT-IMAGES.DIALOG.UNSUITABLE.CONTENT'|translate }}
                               </dialog-box>

                               <dialog-box #dialogLimitedVerified type="warning"
                                           [title]="'EDIT-IMAGES.DIALOG.LIMITED_VERIFIED.TITLE'|translate"
                                           [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate"
                               >
                                   {{ 'EDIT-IMAGES.DIALOG.LIMITED_VERIFIED.CONTENT'|translate }}
                               </dialog-box>


                               <image-upload-modal [imageUrl]="selectedImage"
                                                   (uploadComplete)="uploadSuccessful()"
                                                   (onError)="uploadFailed()"
                               ></image-upload-modal>
                           `,
               styles:  [require('./edit-images-layout.scss')],
           })

export class EditImagesLayout implements OnInit, OnDestroy {
    private curUser: CurrentUser = new CurrentUser();
    private deleteImage: Image = new Image();
    @ViewChild('dialogDeleteImage', { static: true }) private dialogDeleteImage: DialogBox;
    @ViewChild('dialogLimitedVerified', { static: true }) private dialogLimitedVerified: DialogBox;
    @ViewChild('dialogRejected', { static: true }) private dialogRejected: DialogBox;
    @ViewChild('dialogReview', { static: true }) private dialogReview: DialogBox;
    @ViewChild('dialogUnsuitable', { static: true }) private dialogUnsuitable: DialogBox;
    @ViewChild('dialogVerified', { static: true }) private dialogVerified: DialogBox;
    @ViewChild('imageUpload', { static: true }) private imageUpload: DialogBox;
    @ViewChild(ImageUploadModal, { static: true }) private imageUploadModal: ImageUploadModal;
    private maxImageLength: number = 8;
    private selectedImage = null;
    private currentUserSubscription = new Subscription();
    private gallerySubscription = new Subscription();
    private userImages: Image[] = [];

    private get uploadDisabled(): boolean {
        return this.userImages.length >= this.maxImageLength;
    }

    public constructor(
        private currentUserService: CurrentUserService,
        private apiService: ApiService,
        private communicationService: CommunicationService,
        private imageService: ImageService,
        private translate: TranslateService,
        private trackingService: TrackingService
    ) {
    }

    public ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
        this.gallerySubscription.unsubscribe();
    }

    public ngOnInit() {
        this.currentUserSubscription = this.currentUserService
            .currentUserObservable
            .subscribe((data) => {
                this.curUser = data;
                this.getUserImages();
            });
        this.gallerySubscription = this.currentUserService.gallery$.subscribe(images => {
            this.userImages = images;
        });
    }

    private activate(image: Image) {
        this.imageService.activate(image)
            .then(() => this.getUserImages());
    }

    private deactivate(image: Image) {
        this.imageService.deactivate(image)
            .then(() => this.getUserImages());
    }

    private deleteUserImage(image: Image): void {
        this.apiService.image.delete(image.image)
            .then((res) => {
                if (res.error) {
                    this.communicationService.growl.negative(this.translate.instant('EDIT-IMAGE.DELETE_IMAGE.ERROR'));
                } else {
                    this.communicationService.growl.positive(this.translate.instant('EDIT-IMAGE.DELETE_IMAGE.SUCCESS'));
                    this.getUserImages();
                }
            });
    }

    private uploadSuccessful(): void {
        this.trackingService.hit('ProfilBearbeiten', 'Onboarding', 'BildHochgeladen');
        this.getUserImages();
    }

    private uploadFailed(): void {
        this.trackingService.hit('ProfilBearbeiten', 'Onboarding', 'BildHochladenFehlgeschlagen');
        this.dialogUnsuitable.open();
    }

    private getUserImages(): void {
        this.currentUserService.refreshGallery();
    }

    private openDeleteImageDialog(image: Image): void {
        this.deleteImage = image;
        this.dialogDeleteImage.open();
    }

    private openImageStatusModal(imageStatus: ImageStatus): void {
        switch (imageStatus) {
            case ImageStatus.Ok:
            case ImageStatus.OkButNoRating:
            case ImageStatus.OkButDeactivate:
            case ImageStatus.OkMainPic:
                this.dialogVerified.open();
                break;
            case ImageStatus.OkNotMainPic:
            case ImageStatus.OkNotMainPicButDeactivate:
                this.dialogLimitedVerified.open();
                break;
            case ImageStatus.NotApproved:
                this.dialogReview.open();
                break;
            case ImageStatus.Reject:
            case ImageStatus.RejectOld:
                this.dialogRejected.open();
                break;
        }
    }

    private selectImage(event) {

        const imageData = dataUriToFile(event);
        const maxFileSize = 12582912;//Server allow maximum 12MB files

        if (imageData.content.size > maxFileSize || imageData.content.type.substring(0, 5) !== 'image') {
            this.dialogUnsuitable.open();
            return;
        }

        this.selectedImage = event;
        this.imageUploadModal.open();
    }

    private useAsMain(image: Image) {
        this.imageService.useAsMain(image)
            .then(() => this.getUserImages());
    }
}

