/**
 * Created by olifra on 12.06.2017.
 */
import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/**
 * Pipe zum truncaten
 */
@Pipe({
          name: 'truncate',
      })
export class TruncatePipe implements PipeTransform {
    public transform(value: string, limit: number = 10, trail: string = '...'): string {
        return value.length > limit ? value.substring(0, limit - 3) + trail : value;
    }
}
