import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { SingleEvent } from '../../../../models/single-event';
import {
    SingleEventService,
} from '../../../../service/single-event.service';

@Component({
               selector:  'logout-singleevents',
               template:  `
                              <div class="logout-singleevents" *ngIf="events.length">
                                  <div class="logout-singleevents__content">
                                      <div class="logout-singleevents__title">
                                          {{'LOGOUT.SINGLEEVENTS.TITLE' | translate}}
                                      </div>

                                      <div class="logout-singleevents__list">
                                          <logout-singleevents-item *ngFor="let event of events" [singleEvent]="event"></logout-singleevents-item>
                                      </div>

                                      <div class="logout-singleevents__button">
                                          <raised-button>{{'LOGOUT.SINGLEEVENTS.BUTTON'| translate }}</raised-button>
                                      </div>

                                  </div>
                              </div>
                          `,
               styles: [require('./logout-singleevents.scss')],
           })

export class LogoutSingleevents implements OnInit {
    @Input() private singleEventLimit = 4;
    private events: SingleEvent[] = [];

    public constructor(private singleEventService: SingleEventService) {
    }

    public ngOnInit(): void {
        this.singleEventService.list(this.singleEventLimit).then(list => this.events = list);

    }
}
