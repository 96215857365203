/**
 * Created by olifra on 19.05.2017.
 */
import {
    AfterViewInit,
    Component,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Lovestory } from 'BKModels';
import { ModalService } from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LovestoryService } from '../../../../../service/lovestory.service';
import { Modal } from '../../../../../shared/mat-design/modal/modal';

@Component({
               selector:  'lovestory-modal',
               template: `
                             <modal (onClose)="close()" [closeIcon]="true">
                                 <loading-spinner *ngIf="isLoading"></loading-spinner>

                                 <ng-container *ngIf="!isLoading">
                                     <div class="lovestory-modal">
                                         <div class="lovestory-modal__title">
                                             {{lovestory.nameMale}} & {{lovestory.nameFemale}}
                                         </div>
                                         <div class="lovestory-modal__content">
                                             <div class="lovestory-modal__content__img">
                                                 <div class="lovestory-modal__wrapper">
                                                     <div *ngFor="let item of lovestory.getImages()">
                                                         <img class="lovestory-modal__wrapper__image"
                                                              [src]="item"
                                                         >
                                                     </div>
                                                 </div>
                                             </div>
                                             <div class="lovestory-modal__content__answer">

                                                 <lovestory-modal-item [title]="'LOVESTORY.QUESTION.WIE-AUF-BK' | translate"
                                                                       [answer]="lovestory.answers.becomeAware"
                                                                       [nameMale]="lovestory.nameMale"
                                                                       [nameFemale]="lovestory.nameFemale"
                                                 ></lovestory-modal-item>
                                                 <separator class="lovestory-modal__content__seperator"></separator>
                                                 <lovestory-modal-item [title]="'LOVESTORY.QUESTION.ERST-KONTAKT' | translate"
                                                                       [answer]="lovestory.answers.firstContact"
                                                                       [nameMale]="lovestory.nameMale"
                                                                       [nameFemale]="lovestory.nameFemale"
                                                 ></lovestory-modal-item>
                                                 <separator class="lovestory-modal__content__seperator"></separator>
                                                 <lovestory-modal-item [title]="'LOVESTORY.QUESTION.ERSTES-DATE' | translate"
                                                                       [answer]="lovestory.answers.firstDate"
                                                                       [nameMale]="lovestory.nameMale"
                                                                       [nameFemale]="lovestory.nameFemale"
                                                 ></lovestory-modal-item>
                                                 <separator class="lovestory-modal__content__seperator"></separator>
                                                 <lovestory-modal-item [title]="'LOVESTORY.QUESTION.WIE-WEITER' | translate"
                                                                       [answer]="lovestory.answers.continuation"
                                                                       [nameMale]="lovestory.nameMale"
                                                                       [nameFemale]="lovestory.nameFemale"
                                                 ></lovestory-modal-item>
                                                 <separator class="lovestory-modal__content__seperator"></separator>
                                                 <lovestory-modal-item [title]="'LOVESTORY.QUESTION.BK-WEITER-EMP' | translate"
                                                                       [answer]="lovestory.answers.recommendation"
                                                                       [nameMale]="lovestory.nameMale"
                                                                       [nameFemale]="lovestory.nameFemale"
                                                 ></lovestory-modal-item>
                                             </div>

                                             <div class="lovestory-modal__content__greetings">
                                                 {{'LOVESTORY.MODAL.GREETINGS' | translate}}

                                             </div>


                                             <div class="lovestory-modal__content__footer">
                                                 <raised-button (click)="close()">{{'GLOBAL.CLOSE' | translate}}</raised-button>
                                             </div>
                                         </div>
                                     </div>
                                 </ng-container>
                             </modal>
                         `,
               styles: [require('./lovestory-modal.scss')],
           })
export class LovestoryModal implements AfterViewInit, OnDestroy {
    private unsubscribe$ = new Subject();
    private isLoading: boolean = true;

    private lovestory: Lovestory = new Lovestory();

    @ViewChild(Modal, { static: true }) private modal: Modal;

    public constructor(private modalService: ModalService, private lovestoryService: LovestoryService) {

    }


    public ngAfterViewInit(): void {
        this.modalService
            .lovestory
            .get
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((cur) => {
                if (cur) {
                    this.modal.open();
                    this.loadLoveStory();
                } else {
                    this.modal.close();
                }
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private close() {
        this.modalService.lovestory.close();
    }

    private loadLoveStory() {
        this.isLoading = true;
        this.lovestoryService
            .get(this.modalService.lovestory.id)
            .then(story => {
                this.lovestory = story;
                this.isLoading = false;
            });
    }
}
