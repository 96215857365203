import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

@Component({
               selector: 'back-arrow',
               template: `
        <div class="back-arrow">
            <icon-button color
                  icon="arrow-back"
                  class="back-arrow__icon"
                  (click)="back($event)"
            ></icon-button>
        </div>
    `,
           })
export class BackArrow {
    @Output('back') private _back: EventEmitter<any> = new EventEmitter();

    private back($event) {
        this._back.emit($event);
    }
}
