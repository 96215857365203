import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'badge',
               template:  `                          
                                  <div *ngIf="count > 0" class="badge">{{ count }}</div>
                          `,
               styles: [require('./badge.scss')],
           })
export class Badge  {
    @Input() public count = 0;
}
