import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Message,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
               selector: 'system-msg',
               template: `
        <loading-spinner *ngIf="isLoading"></loading-spinner>

        <card class="system-message-box" *ngIf="!isLoading" [ngSwitch]="messageType">
            <system-msg-welcome *ngSwitchCase="'willkommen'"></system-msg-welcome>
            <system-msg-fav-birthday *ngSwitchCase="'favHatGeburtstag'" [message]="systemMessage"></system-msg-fav-birthday>
            <system-msg-fav-birthday *ngSwitchCase="'freundHatGeburtstag'" [message]="systemMessage" friend="true"></system-msg-fav-birthday>
            <system-msg-birthday *ngSwitchCase="'geburtstag'"></system-msg-birthday>

            <system-msg-html *ngSwitchDefault [message]="systemMessage"></system-msg-html>
        </card>
    `,
               styles: [require('./system-msg.scss')],
           })

export class SystemMsg {
    @Input() private isLoading: boolean = false;

    @Input() private systemMessage: Message;

    private get messageType(): string {
        if (!!this.systemMessage && !!this.systemMessage.text && this.systemMessage.text.indexOf('$template') !== -1) {
            return this.systemMessage.text.split('=')[1];
        }
        return 'html';
    }
}
