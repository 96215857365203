import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';

import {
    ActivatedRoute,
    Params,
} from '@angular/router';
import {
    LoginDisabledReasons,
    LoginUser,
} from 'BKModels';
import {
    ApiService,
    CurrentUserService,
    StoreService,
    TrackingService,
} from 'BKService';
import {
    PasswordValidator,
    UsernameValidator,
} from 'BKShared/Validator';
import { openRegistration } from 'BKUtils';
import { throwError as observableThrowError } from 'rxjs';
import {
    NavigationService,
    Routes,
} from '../../service/navigation.service';
import { FailedChargebackPaymentInfo } from '../../service/premium.service';
import {
    AccountDisabledError,
    SessionService,
} from '../../service/session.service';
import { BkModalAccountDisabled } from '../../shared/mat-design/bk-modals/bk-modal-account-disabled';
import { BkModalChargebackPaymentFailed } from '../../shared/mat-design/bk-modals/bk-modal-chargeback-payment-failed';
import { BkModalIdInProgress } from '../../shared/mat-design/bk-modals/bk-modal-id-in-progress';
import { BkModalIdRequested } from '../../shared/mat-design/bk-modals/bk-modal-id-requested';
import { Cache } from '../../utils/cache';

/**
 * Komponente für den Login
 */
@Component({
               selector:  'bk-login',
               template:  `
                              <bk-modal-chargeback-payment-failed #modalChargebackPaymentFailed [account-disabled]="true"></bk-modal-chargeback-payment-failed>
                              <bk-modal-account-disabled #modalAccountDisabled></bk-modal-account-disabled>
                              <bk-modal-id-requested #modalIdUpload></bk-modal-id-requested>
                              <bk-modal-id-in-progress #modalIdInProgress></bk-modal-id-in-progress>
                              <login-header></login-header>
                              <bk-section-out>
                                  <div class="login"
                                       [class.shake]="shake"
                                  >
                                      <bk-form [first]="true">
                                          <h4 class="login__title">{{'LOGIN.LOGIN' | translate}}</h4>
                                          <div *ngIf="isError" class="login__error">
                                              {{getErrorMsg() | translate }}
                                          </div>
                                          <form [formGroup]=" loginForm">
                                              <text-field class="login__input no-check"
                                                          trimInput
                                                          [id]="'username'"
                                                          [placeholder]="'LOGIN.USERNAME' | translate"
                                                          formControlName="_username"
                                                          [valid]="!invalid('_username')"

                                              ></text-field>
                                              <text-field class="login__input no-check"
                                                          [id]="'password'"
                                                          type="password"
                                                          [placeholder]="'LOGIN.PASSWORD' | translate"
                                                          formControlName="_password"
                                                          [valid]="!invalid('_password')"
                                                          (keyup)="keyup($event)"
                                                          (keyup.enter)="onLogin()"
                                                          [helperText]="helperText"
                                              ></text-field>
                                          </form>

                                          <checkbox [(ngModel)]="rememberMe" class="login__rememberme">{{'LOGIN.REMEMBER_ME' | translate }}</checkbox>

                                          <raised-button class="login__button"
                                                         [disabled]="loading"
                                                         (click)="onLogin()"
                                          >
                                              <loading-indicator *ngIf="loading" class="loading-indicator-button"></loading-indicator>
                                              {{'LOGIN.LOGIN' | translate}}
                                          </raised-button>
                                          <a bk-link class="login__link"
                                             (click)="trackPasswordRecovery()"
                                             [routerLink]="sendRecoveryEmailLink"
                                          >
                                              {{'LOGIN.FORGOT_PASSWORD' | translate }}
                                          </a>
                                          <div class="login__registration">
                                              <p class="login__link">{{'GLOBAL.NEW' | translate }}</p>
                                              <a bk-link
                                                 class="login__link"
                                                 (click)="registrationClick()"
                                              >
                                                  {{'GLOBAL.REGISTER_NOW' | translate }}
                                              </a>
                                          </div>
                                      </bk-form>
                                  </div>
                              </bk-section-out>
                              <bk-footer-out></bk-footer-out>
                          `,
               styles: [
                   require('./login.scss'),
               ],
           })
export class Login implements OnInit {
    @ViewChild('modalChargebackPaymentFailed', { static: true }) private chargebackPaymentFailed: BkModalChargebackPaymentFailed;
    @ViewChild('modalAccountDisabled', { static: true }) private modalAccountDisabled: BkModalAccountDisabled;
    @ViewChild('modalIdUpload', { static: true }) private modalIdUpload: BkModalIdRequested;
    @ViewChild('modalIdInProgress', { static: true }) private modalIdInProgress: BkModalIdInProgress;

    helperText = '';

    private get isError(): boolean {
        return this.errorDescription.length > 0;
    }

    private loading: boolean = false;
    private loginForm: FormGroup;
    private returnParams: Params = {};
    private returnUrl: string;
    private shake: boolean = false;
    private user: LoginUser;
    private rememberMe: boolean = true;

    private _errorDescription: string = '';

    private get errorDescription(): string {
        return this._errorDescription;
    }

    private set errorDescription(val: string) {
        this._errorDescription = val.trim();
    }

    public constructor(
        private fb: FormBuilder,
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private curUserService: CurrentUserService,
        private trackingService: TrackingService,
        private storeService: StoreService,
        private sessionService: SessionService,
    ) {
        this.createForm();
    }

    keyup(event: KeyboardEvent) {
        if (event instanceof KeyboardEvent && event.getModifierState('CapsLock')) {
            this.helperText = 'Caps lock is ON.';
        } else {
            this.helperText = '';
        }
    }

    private registrationClick() {
        openRegistration();
    }

    private get sendRecoveryEmailLink() {
        return this.navigationService.getRoute(Routes.SendRecoveryEmail);
    }

    public ngOnInit(): void {
        this.returnUrl = this.navigationService.getSerializedRoute(Routes.Startpage);
        this.user = new LoginUser();
        let loginToken = null;
        if (this.activatedRoute.snapshot.queryParams.returnUrl) {
            // @todo use Url component
            const split = this.activatedRoute.snapshot.queryParams.returnUrl.split('?');
            this.returnUrl = split[0];
            this.returnParams = this.createParams(split[1]);

            if (this.returnParams.hasOwnProperty('loginToken')) {
                loginToken = decodeURIComponent(this.returnParams.loginToken);
                this.returnParams.loginToken = null;
            }
        }
        if (this.activatedRoute.snapshot.queryParams.hasOwnProperty('loginToken')) {
            loginToken = decodeURIComponent(this.activatedRoute.snapshot.queryParams.loginToken);
        }
        if (loginToken) {
            Cache.getInstance().clear();
            this.handleLogin(this.sessionService.loginWithToken(loginToken));
        }
    }

    private createForm() {
        this.loginForm = this.fb
                             .group({
                                        _username:  ['', [UsernameValidator.required]],
                                        _password:  ['', [PasswordValidator.required]],
                                        _stayLogin: [false],
                                    });
    }

    private createParams(val: string = ''): Params {
        return val.split('&')
                  .reduce((res, cur) => {
                      const tmp = cur.split('=');
                      res[tmp[0]] = tmp[1];
                      return res;
                  }, {});
    }

    private getErrorMsg(): string {
        if (this.errorDescription.length !== 0) {
            return this.errorDescription;
        }
        return '';
    }

    private invalid(controlName: string): boolean {
        if (!this.loginForm.controls.hasOwnProperty(controlName)) {
            throw new Error(`${controlName} is not a valid control`);
        }
        return this.loginForm.controls[controlName].errors && !this.loginForm.controls[controlName].pristine;
    }

    private onLogin(): void {
        this.trackingService.login.clickLogin();
        Cache.getInstance().clear();

        if (this.loginForm.status === 'INVALID') {
            this.toggleShake();
            this.errorDescription = 'LOGIN.ERROR.NO_USERDATA';
        } else {
            const raw = this.loginForm.getRawValue();
            const user = {
                _username: raw._username,
                _password: raw._password,
            };
            this.loading = true;

            this.handleLogin(this.sessionService.login(user, this.rememberMe));
        }
    }

    private toggleShake() {
        this.shake = true;
        setTimeout(() => this.shake = false, 400);
    }

    private trackPasswordRecovery() {
        this.trackingService.hit('LoginAlt', 'Login', 'PasswortVergessen');
    }

    private handleLogin(loginPromise: Promise<any>) {
        return loginPromise.then((foo) => {
            this.trackingService.hit('LoginAlt', 'Login', 'AnmeldenErfolgreich');
            this.navigationService.navigate(this.returnUrl, {
                queryParams: this.returnParams,
            });
        }).catch((res) => {
            this.loading = false;
            if (res.error?.error === 'invalid_grant') {
                this.errorDescription = 'LOGIN.ERROR.BAD_CREDENTIALS';
                this.trackingService.hit('LoginAlt', 'Login', 'FehlerBenutzerdaten');
                this.trackingService
                    .login
                    .badCredentials();
            } else if (res.status === 0 && res.statusText === 'Unknown Error') {
                this.errorDescription = 'ERROR.NETWORK.UNKNOWN';
            } else if (res instanceof AccountDisabledError) {
                this.handleDisabledError(res);
            }
            this.toggleShake();
            return observableThrowError(res);
        });
    }

    private handleDisabledError(error: AccountDisabledError) {
        switch (error.reason) {
            case LoginDisabledReasons.Locked:
                return this.accountDisabled(error.payload);
            case LoginDisabledReasons.PassportRequested:
                return this.accountIdRequested(error.payload);
            case LoginDisabledReasons.PassportInProgress:
                return this.accountIdValidationInProgress();
            case LoginDisabledReasons.BillingIssue:
                return this.accountBillingIssue(error.payload);
        }
    }

    private accountDisabled(payload: { email: string }) {
        this.modalAccountDisabled.open(payload.email);
    }

    private accountIdRequested(payload: { token: string }) {
        this.modalIdUpload.open(payload.token);
    }

    private accountBillingIssue(payload: { reference: string, paymentDue: number, total: number }) {
        const info = new FailedChargebackPaymentInfo(payload.reference, payload.total, new Date(payload.paymentDue).getTime());
        this.chargebackPaymentFailed.open(info);
    }

    private accountIdValidationInProgress() {
        this.modalIdInProgress.open({});
    }
}
