import {
    ListVariant,
    MatchingService,
} from 'BKService';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';
import { AdsService } from '../module/intern/ads/ads.service';
import { RadioService } from '../module/intern/schlagerparadies/radio.service';
import { SearchService } from '../module/intern/search/search.service';
import { ConversationService } from '../service/conversation.service';
import { FavoriteService } from '../service/favorite.service';
import { IgnoreService } from '../service/ignore.service';
import { ListService } from '../service/list.service';
import { LovestoryService } from '../service/lovestory.service';
import {
    ListItem,
    ListItemType,
} from '../shared/mat-design/list/list-item-selector';
import { FavWithNotice } from './favorite';
import { IgnoredUser } from './ignore';
import { LovestoryListItem } from './lovestory';
import { ConversationFilter } from './messages';
import {
    ProfileInfo,
    UserBaseView,
} from './user';

export interface ListDataSource<T> {
    listObservable: Observable<any[]>;

    isLoading: boolean;

    loadMore(): Promise<void>;

    isEmpty: boolean;

    length: number;

    value: T[];
}

export class ApiListDataSource<T> implements ListDataSource<T> {
    static readonly defaultListLoadingLimit = 40;

    protected listSubject = new BehaviorSubject<ListItem<T>[]>([]);
    private loadingState = false;

    private reachedEnd = false;

    get length(): number {
        return this.value.length;
    }

    constructor(public readonly itemType: ListItemType, protected source: (limit: number, offset ?: number) => Promise<T[]>) {
    }

    protected appendEntries(entries: ListItem<T>[]) {
        this.listSubject.next([...this.listSubject.getValue(), ...entries]);
    }

    protected insertEntries(index: number, entries: ListItem<T>[]) {
        const list = this.listSubject.getValue();
        list.splice(index, 0, ...entries);
        this.listSubject.next(list);
    }

    protected startLoading() {
        this.loadingState = true;
    }

    protected finishLoading() {
        this.loadingState = false;
    }

    get listObservable(): Observable<ListItem<T>[]> {
        return this.listSubject.asObservable();
    }

    get isLoading(): boolean {
        return this.loadingState;
    }

    get isEmpty(): boolean {
        return this.listSubject.getValue().isEmpty();
    }

    get value(): T[] {
        return this.listSubject.value.map(v => v.item);
    }

    protected get listItems(): ListItem<T>[] {
        return this.listSubject.value;
    }

    loadMore(): Promise<void> {
        if (this.reachedEnd) return new Promise<void>(_ => {/* nothing to do */});
        this.startLoading();
        return this.source(ApiListDataSource.defaultListLoadingLimit, this.length)
                   .then(val => this.defaultLoadHandler(val))
                   .finally(() => this.finishLoading());
    }

    protected defaultLoadHandler(val: T[]) {
        if (val.length === 0) this.reachedEnd = true;
        else this.appendEntries(val.map(it => new ListItem(this.itemType, it)));
    }

    // TODO Bessere Lösung finden um einzelne Items zu aktualisieren
    reload(): Promise<void> {
        this.startLoading();
        return this.source(this.length, 0)
                   .then(val => {
                       this.clear();
                       this.appendEntries(val.map(it => new ListItem(this.itemType, it)));
                   })
                   .finally(() => this.finishLoading());
    }

    clear() {
        this.listSubject.next([]);
        this.reachedEnd = false;
    }

    remove(predicate: (value: T) => boolean) {
        this.listSubject.next(this.listSubject.value.filter(value => !predicate(value.item)));
    }
}

export class PreviewDataSource<T> implements ListDataSource<T> {
    private listSubject = new BehaviorSubject<ListItem<T>[]>([]);

    listObservable = this.listSubject.asObservable();

    isLoading: boolean;

    get value(): T[] {
        return this.listSubject.value.map(v => v.item);
    }

    loadMore(): Promise<void> {
        return Promise.resolve();
    }

    get isEmpty(): boolean {
        return this.length === 0;
    }

    get length(): number {
        return this.value.length;
    }

    public refresh() {
        if (this.isLoading) return;

        this.isLoading = true;

        this.fetchFunction()
            .then(result => {
                this.listSubject.next(result.map(value => new ListItem<T>(ListItemType.PreviewList, value)));
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    constructor(private fetchFunction: () => Promise<T[]>) {}
}

export class MatchingPartnerDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(matchingService: MatchingService, private showAds: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => matchingService.matchPartner(limit, offset));
    }

    private adCount = 0;
    private adFrequency = 7;

    public loadMore(): Promise<void> {
        const resultCount = this.length - this.adCount;
        this.startLoading();
        return this.source(ApiListDataSource.defaultListLoadingLimit, resultCount).then(val => {
            if (val.isEmpty()) return;

            let insertOffset = this.adFrequency - resultCount % this.adFrequency;
            const newEntries = val.map(it => new ListItem(this.itemType, it));

            if (this.showAds) {
                while (insertOffset < val.length) {
                    newEntries.splice(insertOffset, 0, new ListItem(ListItemType.Advertisement, null));
                    this.adCount++;
                    insertOffset += this.adFrequency + 1;
                }
            }
            this.appendEntries(newEntries);
        }).finally(() => this.finishLoading());
    }

    public loadNewest(count: number) {
        this.source(count, 0).then(val => {
            if (val.isEmpty()) return;
            const list = this.listItems;
            const newEntries = val.filter(newEntry => !list.find(it => it.type === ListItemType.DefaultUser && it.item.id === newEntry.id))
                                  .map(it => new ListItem(this.itemType, it));
            this.insertEntries(0, newEntries);
        });
    }
}

export class MatchingWishPartnerDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(matchingService: MatchingService, private showAds: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => matchingService.youWant(limit, offset));
    }
}

export class MatchingWantYouDataSource extends ApiListDataSource<UserBaseView> {
    private _totalCount = 0;
    get totalCount(): number {
        return this._totalCount;
    }

    public constructor(matchingService: MatchingService, private showAds: boolean) {
        super(ListItemType.Matching, async (limit, offset) => {
            const wantYou = await matchingService.wantYouWithCount(limit, offset);
            this._totalCount = wantYou.count;
            return wantYou.wantToMeetMe;
        });
    }
}

export class MatchingSecondChanceDataSource extends ApiListDataSource<ProfileInfo> {
    public constructor(matchingService: MatchingService, private showAds: boolean) {
        super(ListItemType.MatchingSecondChance, (limit, offset) => matchingService.secondChance(limit, offset));
    }
}

export class ProfileVisitorDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(listService: ListService, private showAds: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => listService.profileVisitors(true, limit, offset));
    }
}

export class ProfilesVisitedDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(listService: ListService) {
        super(ListItemType.DefaultUser, (limit, offset) => listService.profilesVisited(limit, offset));
    }
}

export class SearchDataSource extends ApiListDataSource<UserBaseView|string> {

    static slotName = 'BkRL_SucheProfilkarte';

    public constructor(searchService: SearchService, private adsService: AdsService) {
        super(ListItemType.DefaultUser, (limit, offset) => searchService.search(limit, offset));
    }

    protected appendEntries(entries: ListItem<UserBaseView>[]) {
        this.adsService.isSlotActive(SearchDataSource.slotName)
            .then(isActive => {
                if (!isActive) {
                    super.appendEntries(entries);
                } else {
                    let expandedEntries: ListItem<UserBaseView|string>[] = [];

                    let i = 0;
                    for (let entry of entries ) {
                        if ( i > 0 && i % 10 === 0) {
                            expandedEntries.push(new ListItem<string>(ListItemType.Advertisement, SearchDataSource.slotName));
                        }
                        expandedEntries.push(entry);
                        i++;
                    }

                    this.listSubject.next([...this.listSubject.getValue(), ...expandedEntries]);
                }
            });
    }
}

export class RadioUsersDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(radioService: RadioService, upcoming: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => upcoming ? radioService.upcomingUsers() : radioService.previousUsers());
    }
}

export class SearchUsernameDataSource extends ApiListDataSource<UserBaseView> {
    public constructor(searchService: SearchService, private showAds: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => searchService.usernameSearch(limit, offset));
    }
}

export class ConversationListDataSource extends ApiListDataSource<any> {
    public constructor(conversationService: ConversationService, filter: ConversationFilter, private showAds: boolean) {
        super(ListItemType.Conversation, (limit, offset) => conversationService.getAll(filter, limit, offset));
    }
}

export class LovestoryDataSource extends ApiListDataSource<LovestoryListItem> {
    public constructor(lovestoryService: LovestoryService, private showAds: boolean) {
        super(ListItemType.Lovestory, (limit, offset) => lovestoryService.getList(limit, offset));
    }
}

export class IgnoreDataSource extends ApiListDataSource<IgnoredUser> {
    public constructor(ignoreService: IgnoreService) {
        super(ListItemType.Ignore, (limit, offset) => ignoreService.list(limit, offset));
    }
}

export class FavoriteDataSource extends ApiListDataSource<FavWithNotice> {
    public constructor(favoriteService: FavoriteService) {
        super(ListItemType.Favorite, (limit, offset) => favoriteService.list(limit, offset));
    }
}

export class FavoriteDeletedDataSource extends ApiListDataSource<FavWithNotice> {
    public constructor(favoriteService: FavoriteService) {
        super(ListItemType.Favorite, (limit, offset) => favoriteService.listDeleted(limit, offset));
    }
}

export class ActiveUserDataSource extends ApiListDataSource<UserBaseView> {
    private static BasicMax = 18;

    public constructor(listService: ListService, private isPremium: boolean) {
        super(ListItemType.DefaultUser, (limit, offset) => listService.activeUsers(limit, offset));
    }

    public loadMore(): Promise<void> {
        let count = ApiListDataSource.defaultListLoadingLimit;
        if (!this.isPremium) {
            count = ActiveUserDataSource.BasicMax - this.length;
            if (count <= 0) {
                return new Promise(resolve => this.defaultLoadHandler([]));
            }
        }

        this.startLoading();
        return this.source(count, this.length)
                   .then(val => this.defaultLoadHandler(val))
                   .finally(() => this.finishLoading());
    }
}

export class ActiveUserPreviewDataSource extends PreviewDataSource<ProfileInfo>{
    constructor(private listService: ListService) {
        super(() => this.listService.activeUsers(18));
    }
}

export class ProfileVisitorPreviewDataSource extends PreviewDataSource<ProfileInfo>{
    constructor(private listService: ListService) {
        super(() => this.listService.profileVisitors(false, 3,0, ListVariant.FirstPictureNotBlur, true));
    }
}

export class ProfilesVisitedPreviewDataSource extends PreviewDataSource<ProfileInfo>{
    constructor(private listService: ListService) {
        super(() => this.listService.profilesVisited( 3,0, true));
    }
}

