import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { LovestoryModalModule } from '../lovestory/lovestory-modal/lovestory-modal.module';
import { ProfileViewModule } from '../profile-view';
import { Communication,GrowlElement } from './communication';
import { Header } from './header/header';
import { Layout } from './layout';
import { QuickNav } from './quick-nav/quick-nav';

import sideNav from './side-nav';

@NgModule({
              imports:         [
                  ShareModule,
                  ProfileViewModule,
                  LovestoryModalModule,
              ],
              declarations:    [
                  ...sideNav,
                  Communication,
                  GrowlElement,
                  QuickNav,
                  Header,
                  Layout,

              ],
              entryComponents: [
                  Layout,
              ],
              providers:       [],
              exports:         [Layout,Communication,GrowlElement],
          })
export class LayoutModule {
}
