import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TrackingService } from 'BKService';

@Component({
               selector: 'awards',
               template: `
                             <div class="awards">
                                 <div class="awards__content_wrap">
                                     <div *ngIf="full" class="awards__title">{{'PREMIUM_LANDINGPAGE.AWARDS.TITLE' | translate}}</div>
                                     <div class="awards__content">
                                         <div class="awards__container awards__sign_container">
                                             <a class="awards__award"
                                                href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html"
                                                (click)="trackEvent('click','Siegel1')"
                                                target="_blank"
                                             >
                                                 <span class="awards__award_privacy"></span>
                                             </a>
                                             <a *ngIf="full"
                                                class="awards__award"
                                                href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html"
                                                (click)="trackEvent('click','Siegel2')"
                                                target="_blank"
                                             >
                                                 <span class="awards__award_customerFavorite"></span>
                                             </a>
                                         </div>

                                         <div *ngIf="full" class="awards__subtitle">{{'PREMIUM_LANDINGPAGE.AWARDS.MENTIONS.TITLE' | translate}}</div>
                                         <div *ngIf="full" class="awards__container">

                                             <div class="awards__mention" *ngFor="let mention of mentions">
                                                 <a class="awards__mention__title"
                                                    href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html"
                                                    target="_blank"
                                                    (click)="trackEvent('click','textAward')"
                                                 >
                                                     {{mention.title | translate}}
                                                 </a>
                                                 {{mention.text | translate}}
                                             </div>

                                             <div class="awards__mention">
                                                 <a href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html"
                                                    target="_blank"
                                                    (click)="trackEvent('click','furtherAwards')"
                                                 >
                                                     {{'PREMIUM_LANDINGPAGE.AWARDS.MENTIONS.MORE_LINK' | translate}}
                                                 </a>
                                             </div>

                                         </div>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./awards.scss')],
           })

export class Awards {
    @Input() full = true;
    @Output() trackRequest = new EventEmitter< { action: string, label: string }>();

    private mentions =
        [
            new Mention(
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_1.TITLE',
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_1.TEXT',
            ),
            new Mention(
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_2.TITLE',
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_2.TEXT',
            ),
            new Mention(
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_3.TITLE',
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_3.TEXT',
            ),
            new Mention(
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_4.TITLE',
                'PREMIUM_LANDINGPAGE.AWARDS.MENTION_4.TEXT',
            ),
        ];

    public trackEvent(action: string, label: string ) {
        this.trackRequest.emit({action, label});
        return true;
    }
}

class Mention {
    constructor(public title: String, public text: String) {
    }
}
