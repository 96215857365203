/**
 * Created by olifra on 12.06.2017.
 */
import {
    Component,
    Input,
} from '@angular/core';
import {
    LovestoryBase,
    LovestoryListItem,
} from 'BKModels';

@Component({
               selector:  'lovestory-item',
               template: `
                         <div>
                             <div class="lovestory-item">
                                 <img [src]="story.mainImageUrl" class="lovestory-item__img" /> 

                                 <div class="lovestory-item__info">
                                     <div class="lovestory-item__summary">
                                         {{summary}}
                                         <a *ngIf="hasLongSummary">{{ 'BK-HOME.LOVESTORY.MORE_TEXT'|translate }}</a>
                                     </div>

                                     <div class="lovestory-item__author">
                                         {{story.nameMale}} & {{story.nameFemale}}
                                     </div>
                                 </div>

                             </div>
                         </div>
                         `,
               styles: [require('./lovestory-item.scss')],
           })
export class LovestoryItem {
    private SummaryLengthMax = 170;

    @Input() private story: LovestoryBase;

    private get hasLongSummary(): boolean {
        return this.story.summary.length > this.SummaryLengthMax;
    }

    private get summary(): string {
        let summary = this.story.summary;

        if (this.hasLongSummary) {
            summary = summary.substr(0, summary.lastIndexOf(" ", this.SummaryLengthMax)) + "...";
        }

        return summary;
    }
}
