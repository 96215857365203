/**
 * Created by denjas on 07.04.2017.
 */
import {
    AbstractControl,
    FormControl,
} from '@angular/forms';
import { isEmpty } from 'BKShared/Validator';

const PasswordLengthMin = 6;
const PasswordLengthMax = 15;

// TODO
/**
 * Validator für das Password
 */
export class PasswordValidator {
    /**
     * Validator der Checked ob Username und Password gleich sind
     {FormControl} notEqual
     {(c:FormControl)=>{passwordMatch: string}}
     */
    public static notEqual(notEqual: FormControl): any {
        return (c: FormControl) => (
            (c.value !== notEqual.value) ? null : { 'ERROR.VALIDATOR.PASSWORD.MATCH.EQUAL_USERNAME': true }
        );
    }

    /**
     * Validator that performs password repetition.
     *
     equalTo
     {(c:FormControl)=>{passwordMatch: string}}
     */
    public static repetition(equalTo: FormControl): any {
        return (c: FormControl) => (
            (c.value === equalTo.value) ? null : { 'ERROR.VALIDATOR.PASSWORD.MATCH': true }
        );
    }

    public static required(control: AbstractControl): KeyValue {
        if (isEmpty(control.value)) {
            return {
                'ERROR.VALIDATOR.PASSWORD.REQUIRED': true,
            };
        }

        return {};
    }

    /**
     * Validator that performs password validation.
     control
     {KeyValue | null}
     */
    public static validate(control: AbstractControl): KeyValue {
        if (isEmpty(control.value)) {
            return { 'ERROR.VALIDATOR.PASSWORD.REQUIRED': true };
        }
        if (control.value.length < PasswordLengthMin) {
            return { 'ERROR.VALIDATOR.PASSWORD.TO_SHORT': true };
        }
        if (control.value.length > PasswordLengthMax) {
            return { 'ERROR.VALIDATOR.PASSWORD.TO_LONG': true };
        }
        return null;
    }
}
