/**
 * Component for the AccordionTabs
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';


@Component({
               selector: 'accordion-tab-header',
               template: `
                             <div class="accordion-tab-header"
                                  (click)="toggle.emit($event)"
                                  ripple
                             >
                                 <div class="accordion-tab-header__title">{{title}}</div>
                                 <div class="accordion-tab-header__subtitle"
                                      *ngIf="showSubtitle"
                                 >
                                     {{subtitle}}
                                 </div>
                                 <icon *ngIf="selected" icon="arrow-down"></icon>
                                 <icon *ngIf="!selected" icon="arrow-up"></icon>
                             </div>
                         `,
               styles: [require('./accordion-tab-header.scss')],
           })

export class AccordionTabHeader {
    @Input() private selected: boolean;
    @Input() private showSubtitle: boolean;
    @Input() private subtitle: string = '';
    @Input() private title: string = '';
    @Output() private toggle = new EventEmitter<any>();
}
