import {
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { MDCLinearProgress } from "@material/linear-progress";

@Component({
               selector:  'linear-progress',
               template:  `
                              <div #progess role="progressbar" class="mdc-linear-progress mdc-linear-progress--indeterminate">
                                  <div class="mdc-linear-progress__buffering-dots"></div>
                                  <div class="mdc-linear-progress__buffer"></div>
                                  <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                                      <span class="mdc-linear-progress__bar-inner"></span>
                                  </div>
                                  <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                                      <span class="mdc-linear-progress__bar-inner"></span>
                                  </div>
                              </div>
                          `,
               styles: [require('./linear-progress.scss')],
           })

export class LinearProgress {
    private progressBar: MDCLinearProgress;
    @ViewChild('progess', {static: true}) private progressBarRef : ElementRef;

    public ngAfterViewInit() {
        this.progressBar = new MDCLinearProgress(this.progressBarRef.nativeElement);
        this.hide();
    }

    public show() {
        this.progressBar.open();
    }

    public hide() {
        this.progressBar.close();
    }
}
