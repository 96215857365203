/**
 * Created by olifra on 05.05.2017.
 */
import {
    Component,
    Input,
} from '@angular/core';


/**
 * Section Out komponete
 */
@Component({
               selector: 'bk-section-out',
               template: `
                             <section [id]="id" class="bk-section-out">
                                 <ng-content></ng-content>
                             </section>
                         `,
               styles: [require('./section-out.scss')],
           })
export class SectionOut {
    @Input() private id: string = '';
}
