import {
    animate,
    AnimationBuilder,
    AnimationPlayer,
    style,
} from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    HostListener,
    Input,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { CarouselItemDirective } from './carousel-item.directive';

@Directive({
               selector: '.carousel-item',
           })
export class CarouselItemElement {
}


// TODO other sturcture for controls
@Component({
               selector:  'carousel',
               exportAs:  'carousel',
               template:  `
                              <div class="carousel">
                                  <div class="carousel__controls carousel__controls__left is-hidden-tablet" *ngIf="!hideControls" (click)="previous()">
                                      <icon icon="arrow-back-ios" class="carousel__controls__icon"></icon>
                                  </div>

                                  <section class="carousel-wrapper" #carouselWrapper [ngStyle]="carouselWrapperStyle">
                                      <ul class="carousel-inner" #carousel>
                                          <li *ngFor="let item of items;" class="carousel-item" [ngStyle]="carouselWrapperStyle">
                                              <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
                                          </li>
                                      </ul>
                                  </section>
                                  <div class="carousel__controls carousel__controls__right is-hidden-tablet" *ngIf="!hideControls" (click)="next()">
                                      <icon icon="arrow-forward-ios" class="carousel__controls__icon"></icon>
                                  </div>
                              </div>

                              <div class="carousel__footer">
                                  <div class="carousel__controls is-hidden-desktop" *ngIf="!hideControls" (click)="previous()">
                                      <icon icon="arrow-back-ios" class="carousel__controls__icon"></icon>
                                  </div>
                                  <page-indicator class="carousel__page-indicator"
                                                  *ngIf="!hidePageIndicator"
                                                  [count]="items.length"
                                                  [curId]="currentSlide"
                                                  (change)="toSlide($event)"
                                  ></page-indicator>
                                  <div class="carousel__controls is-hidden-desktop" *ngIf="!hideControls" (click)="next()">
                                      <icon icon="arrow-forward-ios" class="carousel__controls__icon"></icon>
                                  </div>
                              </div>
                          `,
               styles: [require('./carousel.scss')],
           })
export class Carousel implements AfterViewInit {
    @ContentChildren(CarouselItemDirective) private items: QueryList<CarouselItemDirective>;
    @ViewChildren(CarouselItemElement, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
    @ViewChild('carousel', { static: false }) private carousel: ElementRef;
    @ViewChild('carouselWrapper', { static: false }) private carouselWrapper: ElementRef;
    @Input() private timing = '250ms ease-out';
    @Input() private hidePageIndicator: boolean = false;
    @Input() private hideControls: boolean = false;
    private player: AnimationPlayer;
    private currentSlide = 0;

    private itemWidth = 0;

    public constructor(private builder: AnimationBuilder) {
    }

    public ngAfterViewInit() {
        this.setItemWidth();
    }

    @HostListener('window:resize', ['$event'])
    private setItemWidth() {
        const itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
        const parentWidth = this.carouselWrapper.nativeElement.parentElement.getBoundingClientRect().width;


        this.itemWidth = parentWidth < itemWidth ? parentWidth : itemWidth;
    }

    private get carouselWrapperStyle() {
        if (this.itemWidth === 0) return {};
        return {
            width: `${this.itemWidth}px`,
        };
    }

    public next() {
        if (this.currentSlide + 1 === this.items.length) {
            this.currentSlide = 0;
        } else {
            this.currentSlide = (this.currentSlide + 1) % this.items.length;
        }
        this.playAnimation();
    }

    public previous() {
        if (this.currentSlide === 0) {
            this.currentSlide = this.items.length - 1;
        } else {
            this.currentSlide = ((this.currentSlide - 1) + this.items.length) % this.items.length;
        }
        this.playAnimation();
    }

    public toSlide(slideNumber: number) {
        this.currentSlide = slideNumber;
        this.playAnimation();
    }


    private playAnimation() {
        const offset = this.currentSlide * this.itemWidth;
        const myAnimation = this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
    }
}
