import { Component } from '@angular/core';

@Component({
               selector:  'app-link-list',
               template:  `
    <ul class="app-links">
      <li class="app-links__list">
        <span class="app-links__list__label">{{'FOOTER_EXTERN.APP_LINKS.BK_FOR_IPHONE' | translate}}</span>
        <a bk-link href="#" class="app-links__list__link">{{'FOOTER_EXTERN.APP_LINKS.DOWNLOAD_APP' | translate}}</a>
      </li>
      <li>
        <span class="app-links__list__label">{{'FOOTER_EXTERN.APP_LINKS.BK_FOR_IPAD' | translate}}</span>
        <a bk-link href="#" class="app-links__list__link">{{'FOOTER_EXTERN.APP_LINKS.DOWNLOAD_APP' | translate}}</a>
      </li>
      <li class="android-app">
        <span class="app-links__list__label">{{'FOOTER_EXTERN.APP_LINKS.BK_FOR_ANDROID' | translate}}</span>
        <a bk-link href="#" class="app-links__list__link">{{'FOOTER_EXTERN.APP_LINKS.DOWNLOAD_APP' | translate}}</a>
      </li>
    </ul>`,
               styles: [require('./app-link-list.scss')],
           })

export class AppLinkItem {

}
