import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Image as BKImage, ImageStatus } from 'BKModels';

@Component({
               selector:  'edit-image-item',
               template:  `
                              <div class="edit-image-item"
                                   (click)="openPreview($event)"
                                   [async-src]="imageUrl"
                                   [class.main]="image.main"
                              >
                                  <div class="edit-image-item__top-icon-bar">
                                      <ng-content></ng-content>

                                      <div class="edit-image-item__icon edit-image-item__visibility-icon">
                                          <icon [icon]="getImageVisibilityIcon(image)"></icon>
                                      </div>
                                  </div>

                                  <div class="edit-image-item__status" 
                                       [ngClass]="getImageStatusClass(image)"
                                       (click)="this.showStatus.emit(this.image.status)">
                                      
                                      <span class="edit-image-item__status__text">{{getImageStatusTranslation(image)|translate}}</span>                                      
                                      <span class="edit-image-item__icon">
                                              <icon icon="info"></icon>
                                          </span>
                                  </div>


                              </div>
                              
                              <edit-images-preview
                                      [open]="previewOpen"
                                      [userImage]="image"
                                      (close)="previewOpen=false"
                              ></edit-images-preview>
                          `,
               styles: [require('./edit-image-item.scss')],
           })

export class EditImageItem {
    @ViewChild("item", {static: true}) private item: ElementRef;
    @Input() private image: BKImage;

    @Output() private showStatus = new EventEmitter();

    private previewOpen: boolean = false;

    private get imageUrl(): string {
        return this.image.getUrl('285x285');
    }


    private getImageStatusClass(image: BKImage): string {
        switch (image.status) {
            case ImageStatus.Ok:
            case ImageStatus.OkButNoRating:
            case ImageStatus.OkButDeactivate:
            case ImageStatus.OkMainPic:
                return 'okay';
            case ImageStatus.OkNotMainPic:
            case ImageStatus.OkNotMainPicButDeactivate:
                return 'warn';
            case ImageStatus.NotApproved:
                return 'pending';
            default:
                return 'error';
        }
    }

    private getImageStatusTranslation(image: BKImage): string {

        if(image.main){
            return 'EDIT-IMAGES.STATUS.TYPE.PROFILE.IMAGE';
        }
        switch (image.status) {
            case ImageStatus.Ok:
            case ImageStatus.OkButNoRating:
            case ImageStatus.OkButDeactivate:
            case ImageStatus.OkMainPic:
                return 'EDIT-IMAGES.STATUS.TYPE.VERIFIED';
            case ImageStatus.OkNotMainPic:
            case ImageStatus.OkNotMainPicButDeactivate:
                return 'EDIT-IMAGES.STATUS.TYPE.LIMITED.VERIFIED';
            case ImageStatus.NotApproved:
                return 'EDIT-IMAGES.STATUS.TYPE.PENDING';
            case ImageStatus.Reject:
            case ImageStatus.RejectOld:
                return 'EDIT-IMAGES.STATUS.TYPE.REJECTED';
            default:
                return 'EDIT-IMAGES.STATUS.TYPE.UNKNOWN';
        }
    }

    private getImageVisibilityIcon(image: BKImage): string {
        if (image.main) {
            return 'profile-outline';
        }

        switch (image.status) {
            case ImageStatus.OkNotMainPic:
                return 'profile-outline-inactive';
            case ImageStatus.NotApproved:
            case ImageStatus.OkButDeactivate:
            case ImageStatus.OkNotMainPicButDeactivate:
            case ImageStatus.Reject:
            case ImageStatus.RejectOld:
                return 'visibility-off';
            default:
                return '';
        }
    }

    private openPreview(event){
        const eventTargetClass = event.target.classList;
        if (eventTargetClass.contains('edit-image-item')) {
            this.previewOpen = true;
        }
    }

}
