import {
    Component,
    Input,
} from '@angular/core';

/**
 * Item das im Matching Explain angezeigt wird
 */
@Component({
               selector: 'matching-explain-item',
               template: `
                              <div class="bk-matching-explain-item">
                                  <div class="bk-matching-explain-item__title">{{title}}:</div>
                                  <div class="bk-matching-explain-item__content">
                                      <div class="bk-matching-explain-item__content__pic">
                                          <img class="bk-matching-explain-item__content__pic__img"
                                               [src]="img"
                                          >
                                      </div>
                                      <div class="bk-matching-explain-item__content__text">{{text}}</div>
                                  </div>
                              </div>
                          `,
               styles: [
                   require('./matching-explain-item.scss'),
               ],
           })
export class MatchingExplainItem {
    @Input() private img: string = '';

    @Input() private text: string = '';

    @Input() private title: string = '';
}
