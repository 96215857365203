import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { ActiveUsersList } from './active-users';
import { ActiveUsersHeader } from './active-users-header';
import { ActiveUsersRouting } from './active-users.routing';


@NgModule({
              imports:      [
                  ShareModule,
                  ActiveUsersRouting,
              ],
              exports:      [],
              declarations: [ActiveUsersList, ActiveUsersHeader],
              providers:    [],
          })
export class ActiveUsersModule {
}
