import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'settings-account-delete-title',
               template: `
                             <div class="settings-account-delete-title__title">{{title}}</div>
                             <div class="settings-account-delete-title__subtitle" *ngIf="!subtitle.isEmpty()"> {{subtitle}}</div>
                             <div class="settings-account-delete-title__text"> {{text}} </div>`,
               styles: [require('./settings-account-delete-title.scss')],
           })

export class SettingsAccountDeleteTitle {
    @Input() private title: string;
    @Input() private subtitle: string = '';
    @Input() private text: string;
}
