import { Component } from '@angular/core';
import { CommunicationService } from 'BKService';

@Component({
               selector:  'communication',
               template:  `
                              <growl *ngFor="let growl of (comService.growl.get|async); let i = index" [growl]="growl" [index]="i"></growl>
                          `,
               styles: [require('./communication.scss')],
           })

export class Communication {
    public constructor(private comService: CommunicationService) {}
}
