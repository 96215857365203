/**
 * Created by olifra on 13.07.2017.
 */
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const leftInRightOut = trigger('leftInRightOut', [
    state('in', style({ transform: 'translateX(0)' })),
    transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate(200),
    ]),
    transition('* => void', [
        animate(200, style({ transform: 'translateX(100%)' })),
    ]),
]);

const growShrinkTimings = 100;
export const growShrink = trigger('growShrink', [
    transition('void => *', [
        style({ height: 0, opacity: 0 }),
        animate(growShrinkTimings, style({ height: '*', opacity: 1 })),
    ]),
    transition('* => void', [
        style({ height: '*', opacity: 1 }),
        animate(growShrinkTimings, style({ height: 0, opacity: 0 })),
    ]),
]);

export const plop = trigger('plop', [
    transition('inactive=> active', [
        style({
                  // backgroundColor: '#cfd8dc',
                  transform: 'scale(1.3)',
              }),
        animate('80ms ease-in', style({
                                          // backgroundColor: '#eee',
                                          transform: 'scale(1)',
                                      })),
    ]),
]);

export const openCloseSideNav = trigger('openCloseSideNav', [
    state('open', style({
                            transform: 'translateX(0)',
                            display:   'box',
                        })),
    state('close', style({
                             transform: 'translateX(100%)',
                             display:   'none',
                         })),
    transition('open => close', animate('100ms ease-out')),
    transition('close => open', animate('100ms ease-in')),
]);


export const tabContent = trigger('tabContent', [
    state('hidden', style({
                              height: '0',
                          })),
    state('visible', style({
                               height: '*',
                           })),
    transition('visible <=> hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    transition('* => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
]);
