import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    EditBoxOpen,
    ExtendedSearchCriteria,
    QuickSearchCriteria,
} from 'BKModels';
import { SearchQuick } from '../quick/search-quick';
import { SearchService } from '../search.service';

@Component({
               selector:  'search-criteria',
               template: `
                             <div class="search-criteria">
                                 <div class="search-criteria__list">
                                     <search-quick-criteria [quickCriteria]="searchService.quickCriteria | async"
                                                            (showQuickSearch)="showQuickSearch($event)"
                                     ></search-quick-criteria>

                                     <search-extended-criteria [extendedCriteria]="searchService.extendedCriteria | async"
                                                               (extendedCriteriaChange)="searchService.extendedCriteria .next($event)"
                                                               (showExtendedSearch)="showExtendedSearch($event)"
                                     ></search-extended-criteria>
                                 </div>
                             </div>
                         `,
               styles: [require('./search-criteria.scss')],
           })
export class SearchCriteria {
    public constructor(private searchService: SearchService) {
    }

    private showQuickSearch(key: string) {
        SearchService.showQuickSearch(key);
    }

    private showExtendedSearch(key: string) {
        SearchService.showExtendedSearch(key);
    }
}
