import { SliderFormatterBase } from 'BKModels';

export class SliderFormatter implements SliderFormatterBase {
    public from(value: string): number {
        return parseFloat(value);
    }

    public to(value: number): string {
        // formatting with http://stackoverflow.com/a/26463364/478584
        return String(parseFloat(parseFloat(String(value))
                                     .toFixed(2)));
    }
}
