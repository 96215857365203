import {
    Component,
    OnInit,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'settings-change-sepa-header',
               template:  `
                              <intern-default-header [showMenuContent]="true">
                                  <settings-change-sepa-header-bar menu></settings-change-sepa-header-bar> {{'GLOBAL.BACK' | translate}}
                              </intern-default-header>
                          `,

           })

export class SettingsChangeSepaHeader {
}
