/**
 * Created by olifra on 27.06.2017.
 */

import { Injectable } from '@angular/core';
import {
    ApiResponseFrame,
    DecisionBatch,
    MatchingDecision,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { Observable } from 'rxjs';
import { MatchingDecisionInfo } from '../../../models/matching-decision-info';
import { ApiRequestService } from '../api-request.service';
import { ListVariant } from '../api.service';

/**
 * Apiservice für das Matching
 */
export class MatchingApi {
    /**
     * constructor
     * {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    /**
     * Sended mehrere Endscheidungen aufeinmal
     *
     {DecisionBatch[]} decisions
     {OldObservable<ApiResponseFrame<any>>}
     */
    public decisionBatch(decisions: DecisionBatch[]): Promise<ApiResponseFrame<any>> {
        const body = decisions.reduce((res: any, cur: DecisionBatch, i: number): any => {
            res[`d[batch][${i}][subjectId]`] = cur.id;
            res[`d[batch][${i}][decision]`] = cur.decision;
            return res;
        }, {});

        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.decideBatch, { body });
    }

    /**
     * Löscht eine Matching entscheidung
     {number} subjectId
     */
    public deleteDecision(subjectId: number): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.deleteMatch, {
                       body: {
                           subjectId,
                       },
                   });
    }

    /**
     * Gibt eine Liste an Matchpartnern zurück
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     */
    public getMatches(limit: number, offset: number = 0,  variant: ListVariant = ListVariant.FirstPictureNotBlur): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.getMatches, {
                       body:  {
                           limit,
                           offset,
                           variant,
                       },
                   });
    }

    /**
     * Gibt die Liste der Leute die der Eingelogte Benutzer treffen möchte zurück
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     */
    public getWantToMeet(limit: number, offset: number = 0, variant: ListVariant = ListVariant.FirstPictureNotBlur): Promise<any> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.wantToMeet, {
                       body:  {
                           limit,
                           offset,
                           variant,
                       },
                   })
            .then(frame => frame.data['wantToMeet'].map(res => ProfileInfo.create(res.subject)));
    }

    /**
     * Gibt die Liste der Leute die den Eingelogte Benutzer treffen möchte zurück
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     */
    public getWantToMeetYou(limit: number, offset: number = 0, variant: ListVariant = ListVariant.FirstPictureNotBlur): Promise<{ count: number, wantToMeetMe: ProfileInfo[] }> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.matching.wantToMeetMe, {
                       query:  {
                           limit,
                           offset,
                           variant
                       },
                   })
                   .then(frame => ({
                       count: frame.data['count'],
                       wantToMeetMe: frame.data['wantToMeetMe'].map(res => ProfileInfo.create(res))
                   }));
    }

    public getSecondChance(limit: number, offset: number = 0): Promise<ProfileInfo[]> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.matching.secondChance, {
                       query:  {
                           limit,
                           offset,
                       },
                   })
                   .then(frame => frame.data['secondChance'].map(res => ProfileInfo.create(res)));
    }

    /**
     * Lad die Matchingqueue sprich die nächsten user die im Matching auftauchen
     *
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     {OldObservable<ApiResponseFrame<any>>}
     */
    public loadQueue(limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.getPartner, {
                       body: {
                           limit,
                       },
                   });
    }

    /**
     * Sendet die Endscheidung für des Eingelogten Benutzers für die user Id
     {number} subjectId
     {MatchingDecision} decision
     {OldObservable<ApiResponseFrame<any>>}
     */
    public setDecision(subjectId: number, decision: MatchingDecision): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.matching.decide, {
                       body: {
                           'decision[subjectId]': subjectId,
                           'decision[decision]':  decision,

                       },
                   });
    }

}
