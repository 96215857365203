import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { SearchHeader } from './search-header/search-header';
import { Search } from './search/search';


const SearchRoutes: Routes = [
    {
        path:     '',
        children: [{ path: '', component: Search },
                   { path: '', component: SearchHeader, outlet: 'header'},
        ],

    },
];


export const SearchRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(SearchRoutes);
