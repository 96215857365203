import { Injectable } from '@angular/core';
import { State } from '../models/state';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
    public constructor(private apiService: ApiService) {}

    public returnToLegacyFeedback(text: string): Promise<State> {
        return this.sendFeedback('Zurück zum alten BK', text);
    }

    private sendFeedback(category: string, text: string): Promise<State> {
        if (!text || text.isEmpty()) return;

        let message = `${category}:\n\n${text}`;

        return this.apiService
            .feedback
            .feedback(message);
    }
}
