import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { SurveyService } from '../../../service/survey.service';
import { Modal } from '../modal/modal';

enum NpsSteps {
    Score,
    Text,
    Trustpilot,
    Done
}

@Component({
            selector: 'bk-nps',
            template: `
                          <ng-container>
                              <modal #modal
                                     no-border
                                     [closeIcon]="false"
                                     [backgroundClose]="true"
                              >
                                  <div class="content">
                                      <ng-container *ngIf="step === NpsSteps.Score">
                                          <div class="title">{{ 'NPS.DIALOG.SCORE.TITLE'|translate }}</div>
                                          <div class="text">{{ 'NPS.DIALOG.SCORE.TEXT'|translate }}</div>

                                          <div class="scores-container-large">
                                              <div class="scores">
                                                  <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
                                                      <raised-button slim [attr.fake-disable]="score !== i || null" (click)="score = i">{{ i }}</raised-button>
                                                  </ng-container>
                                              </div>
                                              <div class="score-description">
                                                  <div>{{ 'NPS.DIALOG.SCORE.DESCRIPTION.LOW'|translate }}</div>
                                                  <div>{{ 'NPS.DIALOG.SCORE.DESCRIPTION.HIGH'|translate }}</div>
                                              </div>
                                          </div>
                                          <div class="scores-container-small">
                                              <div class="scores">
                                                  <ng-container *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">
                                                      <div class="fake-button" [attr.selected]="score === i || null" (click)="score = i">{{ i }}</div>
                                                  </ng-container>
                                              </div>
                                              <div class="score-description">
                                                  <img src="./assets/img/nps/iconFaceSad.svg" [alt]="'NPS.DIALOG.SCORE.DESCRIPTION.LOW'|translate">
                                                  <img src="./assets/img/nps/iconFaceHappy.svg" [alt]="'NPS.DIALOG.SCORE.DESCRIPTION.HIGH'|translate">
                                              </div>
                                          </div>

                                          <div class="action-bar">
                                              <flat-button (click)="close()">{{ 'NPS.DIALOG.ACTION.CLOSE'|translate }}</flat-button>
                                              <flat-button (click)="step = NpsSteps.Text" [disabled]="score === 0">{{ 'NPS.DIALOG.ACTION.CONTINUE'|translate }}</flat-button>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="step === NpsSteps.Text">
                                          <div class="title">{{ 'NPS.DIALOG.TEXT.TITLE'|translate }}</div>
                                          <div class="text">{{ 'NPS.DIALOG.TEXT.TEXT'|translate }}</div>

                                          <div class="message">
                                              <text-area rows="4" [placeholder]="'NPS.DIALOG.TEXT.PLACEHOLDER'|translate" [(value)]="text">
                                              </text-area>
                                          </div>

                                          <div class="action-bar">
                                              <flat-button (click)="close()">{{ 'NPS.DIALOG.ACTION.CLOSE'|translate }}</flat-button>
                                              <flat-button (click)="finish()">{{ 'NPS.DIALOG.ACTION.CONTINUE'|translate }}</flat-button>
                                          </div>

                                      </ng-container>
                                      <ng-container *ngIf="step === NpsSteps.Trustpilot">
                                          <div class="title">{{ 'NPS.DIALOG.TRUSTPILOT.TITLE'|translate }}</div>
                                          <div class="text">{{ 'NPS.DIALOG.TRUSTPILOT.TEXT'|translate }}</div>

                                          <div class="action-bar">
                                              <flat-button (click)="close()">{{ 'NPS.DIALOG.ACTION.CLOSE'|translate }}</flat-button>
                                              <raised-button (click)="openTrustpilot()">{{ 'NPS.DIALOG.ACTION.TRUSTPILOT'|translate }}</raised-button>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="step === NpsSteps.Done">
                                          <div class="title">{{ 'NPS.DIALOG.DONE.TITLE'|translate }}</div>
                                          <div class="text">{{ 'NPS.DIALOG.DONE.TEXT'|translate }}</div>

                                          <div class="action-bar">
                                              <flat-button (click)="close()">{{ 'NPS.DIALOG.ACTION.CLOSE'|translate }}</flat-button>
                                          </div>

                                      </ng-container>
                                  </div>
                              </modal>
                          </ng-container>
                      `,
            styles: [require('./bk-nps.scss')]
           })
export class BkNps implements ModalDialog {
    NpsSteps = NpsSteps;

    @ViewChild('modal', { static: true }) private modal: Modal;

    step = NpsSteps.Score;

    score = 0;

    text = "";

    static readonly typeId = 'BkNps';

    public open(config) {
        this.score = 0;
        this.text = "";
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }

    openTrustpilot() {
        window.open('https://de.trustpilot.com/evaluate/www.bildkontakte.de', '_blank');
        this.close();
    }

    finish() {
        this.surveyService
            .sendNpsSurvey(this.score, this.text)
            .then(() => {
                if (this.score >= 7) this.step = NpsSteps.Trustpilot;
                else this.step = NpsSteps.Done;
            });
    }

    public get typeId(): string {
        return BkNps.typeId;
    }

    constructor(
        private surveyService: SurveyService
    ) {}
}
