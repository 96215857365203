import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { Favorite } from './favorite/favorite';
import { FavoriteHeader } from './favorite-header/favorite-header';
import { FavoriteDelete } from './tabs/favorite-delete';
import { FavoriteExist } from './tabs/favorite-exist';


const favoriteRoutes: NgRoutes = [
    {
        path:     '',
        children: [
            { path: '',
                component: Favorite,
                children: [{
                    path:      routeName(Routes.FavoriteExist),
                    component: FavoriteExist,
                },{
                    path: routeName(Routes.FavoriteDeleted),
                    component: FavoriteDelete,
                }]
            },
            { path: '', component: FavoriteHeader, outlet: 'header' },
        ],
    },
];

export const FavoriteRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(favoriteRoutes);
