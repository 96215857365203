import { Component } from '@angular/core';
import { CurrentUserService } from 'BKService';
import {
    Subscription,
} from 'rxjs';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'po-box-list-empty',
               template:  `
                              <list-empty [title]="'POST_BOX.EMPTY_LIST.TITLE' | translate"
                                          [text]="descriptionText | translate | markdown"
                                          [img]="'./assets/img/no-unread-mails-pic.svg'"
                              >
                                  <raised-button class="list-empty__button"
                                                 trailingIcon="chevron_right"
                                                 [routerLink]="navigationService.getRoute(Routes.Search)"
                                  >
                                      {{'POST_BOX.EMPTY_LIST.BUTTON' | translate}}
                                  </raised-button>
                              </list-empty>
                          `,
           })

export class PoBoxListEmpty {
    private currentUser = this.currentUserService.cachedCurrentUser;
    private currentUserSubscription: Subscription;

    public constructor(private navigationService: NavigationService, private currentUserService: CurrentUserService) {
        this.currentUserSubscription = this.currentUserService.currentUserObservable
                                                .subscribe(val => this.currentUser = val);
    }

    private get descriptionText()
    {
        return this.currentUser.gender === 'm' ? 'POST_BOX.EMPTY_LIST.TEXT.MALE' : 'POST_BOX.EMPTY_LIST.TEXT.FEMALE';
    }

    private Routes = Routes;

}
