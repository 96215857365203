import {
    Component,
    Input,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';

@Component({
               selector: 'match-criteria',
               template: `
                             <div class="match-criteria" *ngIf="user.matchingCriteria">
                                 <icon class="match-criteria-icon" icon="loyalty"></icon>
                                 <span>{{ 'PROFILE_VIEW.HEADER.MATCHING_CRITERIA'|translate:{ username: user.nickname } }}</span>
                             </div>`,
               styles: [require('./match-criteria.scss')],
           })

export class MatchCriteria {
    @Input() public user: ProfileInfo;
}
