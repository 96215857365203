import { Routes } from '../service/navigation.service';
import { Duration } from '../utils/duration';
import { Tariff } from './tariff';


export class Product extends Tariff {
    public active: boolean = true;
    public favorite: boolean = false;
    public preselected: boolean = false;

    public static create(apiObject: any): Product {
        const product = new Product();

        product.active = apiObject.active;
        product.favorite = apiObject.favorite;
        product.price = apiObject.price;
        product.period = Duration.parsePeriod(apiObject.period);
        product.preselected = apiObject.preselected;
        product.subscriptionPrice = apiObject.subscriptionPrice;
        product.subscriptionPeriod = Duration.parsePeriod(apiObject.subscriptionPeriod);
        product.subscriptionPrice = apiObject.subscriptionPrice;
        product.productId = apiObject.productId;
        product.tariffId = apiObject.tariffId;

        return product;
    }
}


export enum PaymentType {
    Bank,
    Paypal,
    Credit,
    Cash
}
