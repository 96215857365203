import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    Image,
    MatchingDecision,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import {
    ModalService,
    StoreService,
    TrackingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import { NavigationService } from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';
import { FeatureExplainDialog } from '../../../../../shared/mat-design/dialog/feature-explain-dialog';
import { MatchingImageIndicator } from '../matching-image-indicator/matching-image-indicator';

@Component({
               selector:  'matching-user-card',
               template:  `
                              <div class="mdc-card card">

                                  <div class="mdc-card__media card__media" (click)="clickImage()">
                                      <img-with-loading-indicator class="card__media__image" [src]="img"></img-with-loading-indicator>
                                      <div *ngIf="showTutorial" (click)="closeTutorial($event)" class="tutorial">
                                          <div class="multiple-images">
                                              <div>
                                                  {{ 'TUTORIAL.MATCHING.1'|translate }}
                                              </div>
                                              <img src="./assets/img/tutorial/iconArrow.svg">
                                          </div>
                                          <div class="click-next">
                                              <img src="./assets/img/tutorial/iconTouch.svg">
                                          </div>
                                          <div class="click-next-description">
                                              {{ 'TUTORIAL.MATCHING.2'|translate }}
                                          </div>
                                      </div>
                                      <matching-image-indicator #imageIndicator
                                                                class="matching-image-selector"
                                                                *ngIf="partner.fullGallery.length > 1"
                                                                [length]="partner.fullGallery.length"
                                                                [(index)]="currentImageIndex"
                                      ></matching-image-indicator>
                                  </div>
                                  <div class="mdc-card__primary-action" tabindex="0" (click)="openProfile($event)">
                                      <div class="card__primary">
                                          <h2 class="card__title">
                                              <online-state [state]="partner.online" type="icon" *ngIf="!partner.isOffline" class="card__title__online-state"></online-state>
                                              <span class="card__title__name">{{partner.nickname}},</span>
                                              {{partner.age}}
                                          </h2>
                                          <h3 class="card__subtitle" *ngIf="currentUser.isPremium()">{{partner.city}}</h3>
                                      </div>
                                      <div class="card__secondary"
                                           [class.card__secondary--basic-user]="!currentUser.isPremium()"
                                      >
                                          <div>
                                              <div *ngIf="partner.isVerified && currentUser.isPremium()" class="card__action">
                                                  <icon class="card__verified-icon" icon="verified-user"></icon>
                                                  {{ 'PROFILE_VIEW.HEADER.VERIFIED'|translate }}
                                              </div>
                                          </div>
                                          <div class="card__action card__action__icon" [class.card__action__icon-active]="currentUser.isPremium()"
                                               (click)="heartBasicClick($event)"
                                          >
                                              <icon-button *ngIf="!currentUser.isPremium()"
                                                           class="card__like-icon"
                                                           icon="match-outline"
                                                           medium
                                              ></icon-button>
                                              <icon-button *ngIf="currentUser.isPremium()"
                                                           class="card__like-icon card__like-icon--active"
                                                           [icon]="partnerDecisionYesOrMaybe ? 'match' : 'match-outline'"
                                                           medium
                                              ></icon-button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <feature-explain-dialog #basicDialog
                                                      [title]="'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.BASIC.TITLE' | translate"
                                                      [text]="'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.BASIC.TEXT' | translate | markdown"
                                                      [buttonText]="'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.BASIC.BUTTON' | translate"
                                                      (buttonAction)="basicDialogButtonAction()"
                                                      [img]="'./assets/img/matching/matching-tutorial-2-basis.svg'"
                              ></feature-explain-dialog>

                              <feature-explain-dialog #premiumDialog
                                                      [title]="'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.PREMIUM.TITLE' | translate"
                                                      [text]="premiumDialogText | translate | markdown"
                                                      [img]="'./assets/img/matching/matching-tutorial-2-premium.svg'"
                              ></feature-explain-dialog>
                          `,
               styles: [require('./matching-user-card.scss')],
           })
export class MatchingUserCard implements OnDestroy {
    private _partner: ProfileInfo;

    @Input()
    private set partner(val: ProfileInfo) {
        this._partner = val;
        this.onUpdatePartner();
    }

    private get partner(): ProfileInfo {
        return this._partner;
    }

    @Input() private currentUser: CurrentUser;
    @Input() private verified: boolean = false;

    @ViewChild('imageIndicator', { static: false }) private imageIndicator: MatchingImageIndicator;
    @ViewChild('basicDialog', { static: true }) private basicDialog: FeatureExplainDialog;
    @ViewChild('premiumDialog', { static: true }) private premiumDialog: FeatureExplainDialog;

    @Output() private didShowDecisionDialogChange = new EventEmitter<boolean>();
    @Input('didShowDecisionDialog') private _didShowDecisionDialog = false;
    private set didShowDecisionDialog(value: boolean) {
        this._didShowDecisionDialog = value;
        this.didShowDecisionDialogChange.emit(value);
    }

    private get didShowDecisionDialog(): boolean {
        return this._didShowDecisionDialog;
    }

    private currentImageIndex = 0;

    private get currentImage(): string {
        return this.partner.fullGallery[this.currentImageIndex].image;
    }

    private get img(): string {
        if (this.partner.fullGallery.length > 0) {
            if (this.currentImage.isEmpty()) {
                return this.partner.fullGallery[0].getUrl('530x530');
            }
            return Image.getUrlFromString('530x530', this.currentImage);
        }
        return '';
    }

    private get partnerDecisionYesOrMaybe() {
        return this.partner.decisionPartner === MatchingDecision.Yes || this.partner.decisionPartner === MatchingDecision.Maybe;
    }

    showTutorial = false;
    checkedTutorial = false;

    tutorialShownSubscription = new Subscription();

    public ngOnDestroy(): void {
        this.tutorialShownSubscription.unsubscribe();
    }

    onUpdatePartner() {
        this.currentImageIndex = 0;

        if (this.partner.fullGallery.length > 1) {
            this.tutorialShownSubscription = this.storeService.matchingTutorialShown.get()
                                                 .subscribe((value: boolean) => this.checkTutorial(value));
        }
    }

    checkTutorial(shownBefore: boolean) {
        if (this.checkedTutorial) return;
        this.checkedTutorial = true;

        if (shownBefore) {
            this.trackingService.hit('Matching', 'OnboardingProfilkarte', 'TutorialLayerBereitsGesehen');
        } else {
            this.trackingService.hit('Matching', 'OnboardingProfilkarte', 'TutorialLayerAnzeigen');
            this.showTutorial = true;
        }
    }

    closeTutorial($event: Event) {
        $event.preventDefault();
        $event.stopImmediatePropagation();

        this.showTutorial = false;
        this.storeService.matchingTutorialShown.next(true);
    }

    private heartBasicClick(event) {
        event.stopPropagation();
        if (!this.didShowDecisionDialog) {
            this.trackingService.hit('Matching', 'MatchingSpielen', 'GraueHerzen');
            this.didShowDecisionDialog = true;
        }

        if (this.currentUser.isPremium()) {
            this.premiumDialog.open();
        } else {
            this.basicDialog.open();
        }
    }

    private toPremium() {
        this.premiumService.navigateToPremium(OrderReason.MatchingSeeMatchpartner);
    }

    private openProfile(event) {
        event.stopPropagation();
        if (this.currentUser.isPremium()) {
            this.modalService.profileView.open(this.partner.id);
        } else {
            this.premiumService.navigateToPremium(OrderReason.MatchingOpenProfile);
        }
    }

    private get premiumDialogText(): string {
        switch (this.partner.gender) {
            case Gender.Male:
                return 'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.PREMIUM.TEXT.MALE';
            case Gender.Female:
                return 'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.PREMIUM.TEXT.FEMALE';
            default:
                return 'MATCHING.FEATURE.EXPLAIN.SEE_DECISION.PREMIUM.TEXT.PAIR';

        }
    }

    private clickImage() {
        if (this.partner.gallery.length > 0) this.imageIndicator.nextImage();
    }

    private basicDialogButtonAction() {
        this.toPremium();
        this.trackingService.hit('Matching', 'MatchingSpielen', 'PremiumWerdenJaGesagt');
    }

    public constructor(
        private modalService: ModalService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private trackingService: TrackingService,
        private storeService: StoreService
    ) {
    }
}
