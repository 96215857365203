import {
    Component,
    OnInit,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'settings-header',
               template:  `
                              <intern-default-header [title]="'LAYOUT.HEADER.SETTINGS' | translate" [closeable]="true">
                                  <settings-tabbar></settings-tabbar>
                              </intern-default-header>
                          `,
               styles: [require('./settings-header.scss')],
           })

export class SettingsHeader implements OnInit{
    public constructor(private navigationService: NavigationService) {
    }

    public ngOnInit(): void {
        const b = this.navigationService.isRouteActive(Routes.SettingChangeSepa);

    }
}
