import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import { urlB64ToUint8Array } from 'BKUtils';
import {
    BehaviorSubject,
    ReplaySubject,
} from 'rxjs';
import { ApiService } from './api';
import { ServiceWorkerConnectorService } from './service-worker-connector.service';

export class PushPermissionDeniedException {}
export class PushSubscribeErrorException {}
export class PushUnsubscribeErrorException {}
export class PushPermissionUnknownErrorException {
    constructor(public readonly result: any) {
    }
}

export class PushAlreadyEnabledException {}
export class PushNotEnabledException {}

@Injectable({ providedIn: 'root'})
export class PushNotificationService {
    private vapidPubKey = urlB64ToUint8Array(BkConfig.vapidPubKeyBase64);

    private pushesSubject = new ReplaySubject<any>(1);
    pushes$ = this.pushesSubject.asObservable();

    private enabledStateSubject = new ReplaySubject<boolean>(1);
    enabledState$ = this.enabledStateSubject.asObservable();

    private pushesAvailableSubject = new ReplaySubject<boolean>(1);
    pushesAvailable$ = this.pushesAvailableSubject.asObservable();

    constructor(
        private serviceWorkerConnectorService: ServiceWorkerConnectorService,
        private apiService: ApiService
    ) {
        this.serviceWorkerConnectorService.message$.subscribe(message => {
            if (message.name !== 'push') return;
            const push = message.payload;

            this.pushesSubject.next(push);
        });

        // Force loading subscription
        this.subscription()
            .then(subscription => {
                this.enabledStateSubject.next(!!subscription);
                this.pushesAvailableSubject.next(true);
            })
            .catch(_ => {
                this.pushesAvailableSubject.next(false);
            });
    }

    private set isEnabled(v: boolean) {
        this.enabledStateSubject.next(v);
    }

    get permissionGranted(): boolean {
        return Notification.permission === "granted";
    }

    async enable(): Promise<void> {
        const permissionResult = await Notification.requestPermission();
        if (permissionResult === "denied") throw new PushPermissionDeniedException();
        if (permissionResult !== "granted") throw new PushPermissionUnknownErrorException(permissionResult);

        let subscription = await this.subscription();
        if (subscription) throw new PushAlreadyEnabledException();

        const registration = await this.serviceWorkerConnectorService.ready();
        subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,  // required as of 2023-03-16 as browsers do not allow hidden notifications for privacy concerns
            applicationServerKey: this.vapidPubKey
        });
        if (!subscription) throw new PushSubscribeErrorException();

        await this.sendSubscriptionToBackend(subscription);

        this.isEnabled = true;
    }

    async disable(): Promise<void> {
        const subscription = await this.subscription();
        if (!subscription) throw new PushNotEnabledException();

        await this.removeSubscriptionOnBackend(subscription);

        const unsubscribed = await subscription.unsubscribe();
        if (!unsubscribed) throw new PushUnsubscribeErrorException();
        this.isEnabled = false;
    }

    async hasSubscription(): Promise<boolean> {
        return !!(await this.subscription());
    }

    private async subscription(): Promise<PushSubscription|null> {
        const registration = await this.serviceWorkerConnectorService.ready();
        return registration.pushManager.getSubscription();
    }

    public async updateSubscriptionOnBackend() {
        const subscription = await this.subscription();
        if (subscription) await this.sendSubscriptionToBackend(subscription);
    }

    private async sendSubscriptionToBackend(subscription: PushSubscription) {
        return this.apiService.push.subscribe(subscription);
    }

    private async removeSubscriptionOnBackend(subscription: PushSubscription) {
        return this.apiService.push.unsubscribe(subscription.endpoint);
    }
}
