import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    IgnoredUser,
    ImageSize,
    UserBaseView,
} from 'BKModels';

@Component({
               selector: 'list-ignore-item',
               template: `
                              <div
                                      class="mdc-card list-item"
                                      [class.list-item--basis]="item.ignoredUser.isBasisView()"
                              >
                                  <div class="mdc-card__primary-action" tabindex="0">
                                      <div class="mdc-card__media list-item__media">
                                          <img-with-loading-indicator class="list-item__media__image" [src]="imageUrl"></img-with-loading-indicator>
                                      </div>

                                      <div class="list-item__primary" *ngIf="!item.ignoredUser.isBasisView()">
                                          <div class="list-item__title">
                                              <span class="list-item__title__name">{{ item.ignoredUser.nickname }}</span>
                                              <icon *ngIf="hasVerifiedState && isVerified" class="list-item__verified-icon" icon="verified-user"></icon>
                                          </div>
                                          <div class="list-item__subtitle">{{item.ignoredUser.city}}</div>
                                      </div>

                                      <div class="list-item__primary" *ngIf="item.ignoredUser.isBasisView()">
                                          <div class="list-item__title">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                          <div class="list-item__subtitle">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="mdc-card__actions list-item__action">
                                      <div class="mdc-card__action-buttons">
                                          <flat-button (click)="remove.emit(item.ignoredUser)">{{ 'IGNORE.REMOVE'|translate }}</flat-button>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./list-ignore-item.scss')],
           })

export class ListIgnoreItem {
    @Output() private remove: EventEmitter<UserBaseView> = new EventEmitter();
    @Input() private item: IgnoredUser;

    private get isVerified(): boolean {
        return this.item.ignoredUser.isVerified;
    }

    private get hasVerifiedState(): boolean {
        return !this.item.ignoredUser.isBasisView();
    }

    private get imageUrl(): string {
        return this.item.ignoredUser.getProfilePictureUrl(ImageSize['530x530']);
    }
}
