import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import { growShrink } from 'BKUtils';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import { geodesicDistance } from '../../../../../utils/geo-helpers';
import { ProfileMenu } from '../profile-menu/profile-menu';

@Component({
               selector:   'profile-header',
               template:   `
                               <div class="profile-header">
                                   <div class="profile-header__text">
                                       <div class="profile-header__text--title">
                                           <span>{{user.nickname}}</span>
                                           <span *ngIf="!user.isOffline" class="title-separator">|</span><online-state *ngIf="!user.isOffline" [state]="user.online" type="text" class="profile-header__online"></online-state>
                                       </div>
                                       <div class="profile-header__text__line">{{user.age}} {{'PROFILE_VIEW.HEADER.YEARS' | translate}}
                                           | {{user.city}}&nbsp;{{geodesicDistance}}</div>
                                       <div class="profile-header__text__line--verified" *ngIf="user.isVerified" (click)="this.showVerifiedHint = !this.showVerifiedHint">
                                           <icon class="profile-header__verified" icon="verified-user"></icon>
                                           {{ 'PROFILE_VIEW.HEADER.VERIFIED'|translate }}
                                           <icon [icon]="showVerifiedHint ? 'arrow-drop-up': 'arrow-drop-down'"></icon>
                                       </div>
                                       <div *ngIf="showVerifiedHint" [@growShrink]="false" class="profile-header__verified-hint">
                                           <div [innerHTML]="'PROFILE_VIEW.HEADER.VERIFIED.HINT.TEXT'|translate|markdown"></div>
                                           <a [routerLink]="myProfileLink" [fragment]="ProfileEditRouteFragment.Authenticity">{{ 'PROFILE_VIEW.HEADER.VERIFIED.HINT.ACCEPT'|translate }}</a>
                                       </div>
                                       <div class="profile-header__text__line--team" *ngIf="user.isTeam">
                                           <img class="profile-header__team" src="./assets/img/iconVipDef.svg">
                                           {{ 'PROFILE_VIEW.HEADER.TEAM'|translate }}
                                       </div>

                                   </div>


                                   <div class="profile-header__action">
                                       <profile-menu [user]="user"
                                                     (addFavorite)="addFavorite.emit()"
                                                     (removeFavorite)="removeFavorite.emit()"
                                                     (addIgnore)="addIgnore.emit()"
                                                     (report)="report.emit()"
                                                     [style.top]="topString"
                                                     [style.display]="top <= 30? 'none': 'block'"
                                                     class="profile-header__menu"
                                       ></profile-menu>

                                       <icon-button class="profile-header__close"
                                                    icon="close"
                                                    (click)="close.emit()"
                                       ></icon-button>
                                   </div>
                               </div>
                           `,
               styles:  [require('./profile-header.scss')],
               animations: [growShrink],
           })

export class ProfileHeader {
    @ViewChild(ProfileMenu, { static: true }) public profileMenu: ProfileMenu;
    @Output() private close = new EventEmitter();
    @Input() private user: ProfileInfo;
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @Output() private addFavorite = new EventEmitter();
    @Output() private removeFavorite = new EventEmitter();
    @Output() private addIgnore = new EventEmitter();
    @Output() private report = new EventEmitter();
    @ViewChild('verifiedHint', { static: true }) private verifiedHint: DialogBox;
    private ProfileEditRouteFragment = ProfileEditRouteFragment;

    private showVerifiedHint: Boolean = false;

    constructor(private navigationService: NavigationService, private element: ElementRef) {
    }

    private get myProfileLink() {
        return this.navigationService.getRoute(Routes.MyProfile);
    }

    private get onlineStatusText() {
        return this.user.isOnline ? 'PROFILE_VIEW.HEADER.ONLINE' : 'PROFILE_VIEW.HEADER.OFFLINE';
    }

    private get topString() {
        return `${this.top}px`;
    }

    private get top() {
        return (this.element.nativeElement.parentNode.parentNode.scrollTop * -1) + 35;
    }
    private get geodesicDistance(): string {
        if (!this.currentUser.hasCoordinates || !this.user.hasCoordinates) return '';

        const geodesicDistanceInKm = Math.round(geodesicDistance(this.currentUser, this.user));
        return `(${geodesicDistanceInKm} km)`;
    }
}
