import { UserBaseView } from './user/user-base-view';

export class IgnoredUser {
    public entryAt: number;

    public ignoredUser: UserBaseView = new UserBaseView();

    public static create(data: IgnoredUser): IgnoredUser {
        const res = new IgnoredUser();
        res.entryAt = data.entryAt;
        res.ignoredUser = new UserBaseView();
        res.ignoredUser.basicView = data.ignoredUser.basicView;
        res.ignoredUser.deleted = data.ignoredUser.deleted;
        res.ignoredUser.echeck = data.ignoredUser.echeck;
        res.ignoredUser.id = data.ignoredUser.id;
        res.ignoredUser.image = data.ignoredUser.image;
        res.ignoredUser.nickname = data.ignoredUser.nickname;
        return res;
    }
}

export interface IgnoreResponse {
    ignores: IgnoredUser[],
}
