import {
    AfterViewInit,
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { ActiveUserDataSource } from '../../../models/list-data-sources';
import { DataSourceFactory } from '../../../service/data-source-factory';
import { ListService } from '../../../service/list.service';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';
import { PremiumService } from '../../../service/premium.service';

@Component({
               selector:  'active-users',
               template: `
                             <div class="active-users">
                                 <subheader class="is-hidden-tablet">
                                     <div class="title-bar">
                                         <div class="title">{{ 'ACTIVITY_LIST.TITLE'|translate }}</div>
                                         <div class="settings-box">
                                             <chip icon="wc" [closeable]="false" (click)="onOpenSettings()">{{ genderTextKey|translate }}</chip>
                                             <chip icon="event" [closeable]="false" (click)="onOpenSettings()">{{ 'ACTIVE_USERS_PREVIEW.SEARCH_AGE'|translate:ageParams }}</chip>
                                         </div>
                                     </div>
                                     
                                 </subheader>
                                 <list-container [dataSource]="listDataSource" *ngIf="listDataSource">
                                     <ng-template let-item>
                                         <list-item-selector two-columns-max
                                                             [listItem]="item"
                                                             (onOpenProfile)="onOpenProfile()"
                                                             (onAddFavorite)="onAddFavorite()"
                                                             (onOpenConversation)="onOpenConversation()"
                                                             (onDeleteFavorite)="onDeleteFavorite()"
                                                             (gotoPremium)="gotoPremium()"
                                         ></list-item-selector>
                                     </ng-template>
                                 </list-container>
                                 
                                 <div class="active-users__basic-button" *ngIf="!currentUser.isPremium() && listDataSource && listDataSource.length>=20">
                                     <raised-button (click)="gotoPremium()" premium>{{'ACTIVE_USERS_PREVIEW.VIEW_FULL'|translate}}</raised-button>
                                 </div>
                             </div>
                         `,
               styles: [require('./active-users.style.scss')],
           })

export class ActiveUsersList implements OnInit, AfterViewInit {
    currentUser: CurrentUser = new CurrentUser();
    listDataSource: ActiveUserDataSource;

    constructor(
        private listService: ListService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private dataSourceFactory: DataSourceFactory,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
    ) {
    }

    get genderTextKey(): string {
        if (!this.currentUser) return '-';

        switch (this.currentUser.searchGender) {
            case Gender.Male:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_MALE';
            case Gender.Female:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_FEMALE';
            case Gender.Both:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_BOTH';
            case Gender.None:
            default:
                return '-';
        }
    }

    get ageParams(): { from: string, to: string } {
        if (!this.currentUser) return { from: '0', to: '0' };

        return {
            'from': this.currentUser.ageFrom.toString(),
            'to': this.currentUser.ageTill.toString(),
        };
    }

    onOpenSettings() {
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }

    ngOnInit() {
        this.currentUserService
            .currentUser
            .then(currentUser => this.currentUser = currentUser);

        this.dataSourceFactory.createActiveUserDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });
    }

    ngAfterViewInit(): void {
        this.currentUserService.refreshBadgeCount();
    }

    get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    onOpenProfile() {
        this.trackingService.hit('AktiveMitglieder', 'AktiveMitgliederKarte', 'ProfilAufgerufen');
    }

    onOpenConversation() {
        this.trackingService.hit('AktiveMitglieder', 'AktiveMitgliederKarte', 'Konversation');
    }

    onAddFavorite() {
        this.trackingService.hit('AktiveMitglieder', 'AktiveMitgliederKarte', 'FavoritHinzugefuegt');
    }

    onDeleteFavorite() {
        this.trackingService.hit('AktiveMitglieder', 'AktiveMitgliederKarte', 'FavoritGeloescht');
    }

    gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.ActivityList);
    }
}
