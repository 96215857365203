import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';

@Component({
               selector: 'chat-report',
               template: `
        <div class="chat-report">
            <a (click)="report.emit()">{{'CHAT.HEADER.MENU.REPORT_CONVERSATION'|translate}}</a>
            <span>|</span>
            <a *ngIf="!partner.ignore" (click)="ignoreAdd.emit()">{{'CHAT.HEADER.MENU.IGNORE' | translate}}</a>
            <a *ngIf="partner.ignore" (click)="ignoreRemove.emit()">{{'CHAT.HEADER.MENU.IGNORE_REMOVE' | translate}}</a>
        </div>`,
               styles: [require('./chat-report.scss')],
           })

export class ChatReport {
    @Input() partner: ProfileInfo = new ProfileInfo();
    @Output() report = new EventEmitter();
    @Output() ignoreAdd = new EventEmitter();
    @Output() ignoreRemove = new EventEmitter();

    public constructor() {}
}
