import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { Offer } from '../../../models/offer';

enum BannerVariant {
    Low, Medium, High
}

@Component({
               selector:  'recurring-offer-banner',
               template:  `
                              <ng-container>
                                  <img [src]="imageSrc" 
                                       [alt]="'RECURRING_OFFER_BANNER.ALT'|translate" 
                                       (click)="purchaseOffer.emit()" 
                                       [class.show-cursor]="!small" 
                                       class="banner">
                              </ng-container>
                          `,
               styles: [require('./recurring-offer-banner.scss')],
           })

export class RecurringOfferBanner {
    @Input() small: boolean = false;
    @Input() offer: Offer;
    @Output() purchaseOffer = new EventEmitter();

    private get variant(): BannerVariant {
        return {
            [85]: BannerVariant.Low,
            [86]: BannerVariant.Medium,
            [87]: BannerVariant.High,
        }[this.offer.tariffId];
    }

    get imageSrc(): string {
        const variantPart = {
            [BannerVariant.Low]: 'a',
            [BannerVariant.Medium]: 'b',
            [BannerVariant.High]: 'c',
        }[this.variant];
        const sizePart = this.small ? '-small' : '';
        return `./assets/img/banner/recurring-offer-${variantPart}${sizePart}.png`;
    }

}
