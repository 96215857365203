import { Injectable } from '@angular/core';
import { Events } from './events';
import { TrackingService } from './tracking.service';

export class LoginEvents extends Events{
    private category = 'login';

    public badCredentials() {
        this.track({
                                action:   'unauthorized',
                                category: this.category,
                                label:    'bad credentials',
                                event:    'interaction',
                            });
    }

    public clickLogin() {
        this.track({
                                action:   'clickLoginButton',
                                category: this.category,
                                event:    'click',
                                label:    'login',
                            });
    }

    public fbLogin() {
        this.track({
                                action:   'clickFbLoginButton',
                                category: this.category,
                                event:    'click',
                                label:    'fb login',
                            });
    }
}
