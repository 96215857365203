import { Component } from '@angular/core';

@Component({
               selector:    'chat-unread-separator',
               template: `
                           <div class="chat-unread-separator__line"></div> 
                           <div class="chat-unread-separator__text">
                               <icon icon="chat-outline" class="chat-unread-separator__text__icon"></icon>
                               {{'CHAT.NEW_MESSAGES' | translate}}
                           </div>
                           <div class="chat-unread-separator__line"></div>
                         `,
               styles:   [require('./chat-unread-separator.scss')],
           })

export class ChatUnreadSeparator {
    public constructor() {
    }




}
