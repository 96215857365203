import {
    Component,
    Input,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import { TrackingService } from 'BKService';
import { UserCounts } from '../../../../../models/user-counts';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'premium-cancel-lists',
               template:  `                          
                              <div class="premium-cancel-lists__title">{{ 'PREMIUM.CANCEL.LISTS.TITLE'|translate }}</div>
                          
                              <cancel-user-list *ngIf="interestedInMeUserList.length >= 5"
                                                        [userList]="interestedInMeUserList" 
                                                        (action)="openInterestedInMe()" 
                                                        [titleText]="'PREMIUM.CANCEL.LISTS.INTERESTED_IN_ME.TITLE'|translate:{count:this.counts.openMatches.toString()}" 
                                                        [buttonText]="'PREMIUM.CANCEL.LISTS.INTERESTED_IN_ME.ACTION'|translate"></cancel-user-list>
                              <cancel-user-list [userList]="matchPartnerUserList" 
                                                        (action)="openMatchPartners()" 
                                                        [titleText]="'PREMIUM.CANCEL.LISTS.MATCH_PARTNERS.TITLE'|translate:{count:this.counts.matches.toString()}" 
                                                        [buttonText]="'PREMIUM.CANCEL.LISTS.MATCH_PARTNERS.ACTION'|translate"></cancel-user-list>
                              <cancel-user-list [userList]="profileVisitorList" 
                                                        (action)="openProfileVisitors()" 
                                                        [titleText]="'PREMIUM.CANCEL.LISTS.PROFILE_VISITORS.TITLE'|translate:{count:this.counts.profileVisitors.toString()}" 
                                                        [buttonText]="'PREMIUM.CANCEL.LISTS.PROFILE_VISITORS.ACTION'|translate"></cancel-user-list>
                          `,
               styles: [require('./premium-cancel-lists.scss')],
           })

export class PremiumCancelLists {
    @Input() private listSize = 5;
    @Input() private interestedInMeUserList: UserBaseView[] = [];
    @Input() private matchPartnerUserList: UserBaseView[] = [];
    @Input() private profileVisitorList: UserBaseView[] = [];

    @Input() private counts: UserCounts;

    constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService) {
    }

    private openInterestedInMe() {
        this.trackingService.hit('PremiumKuendigen', 'ProzessVerlassen', 'VerlusteSinglesMoechtenDich');
        this.navigationService.navigateTo(Routes.MatchingLikeYou);
    }

    private openMatchPartners() {
        this.trackingService.hit('PremiumKuendigen', 'ProzessVerlassen', 'VerlusteMatchpartner');
        this.navigationService.navigateTo(Routes.MatchingPartner);
    }

    private openProfileVisitors() {
        this.trackingService.hit('PremiumKuendigen', 'ProzessVerlassen', 'VerlusteProfilbesucher');
        this.navigationService.navigateTo(Routes.ProfileVisitors);
    }
}
