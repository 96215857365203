import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'BKService';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';

@Component({
               selector: 'img-upload',
               template: `
    <dialog-box [title]="label"
            (onReject)="reject.emit()"
            [rejectText]="'IMAGE_UPLOAD.SKIP' | translate"
            (onAccept)="uploadImg()"
            [acceptText]="imageDialogAcceptText"
            #imageDialog
    >
      <div class="img-upload">
        <div class="img-upload__wrapper">
          <img *ngIf="!file.isEmpty()" [src]="file" class="img-upload__image">
        </div>

        <div class="img-upload__text">{{text}}</div>
        <input type="file" #fileInput [hidden]="true" (change)="selectImage($event)">
        <raised-button (click)="fileInputRef.nativeElement.click()">Foto auswählen</raised-button>

      </div>

    </dialog-box>
  `,
               styles: [require('./img-upload.scss')],
           })
export class ImgUpload {
    private file = '';
    @ViewChild('fileInput', { static: true }) private fileInputRef: ElementRef<HTMLInputElement>;
    @ViewChild('image', { static: true }) private image: ElementRef;
    @ViewChild('imageDialog', { static: true }) private imageDialog: DialogBox;
    @Input() private label: string = '';
    @Output() private reject = new EventEmitter();
    @Input() private text: string = '';
    @Output() private upload = new EventEmitter();

    private get imageDialogAcceptText(): string {
        if (this.file.isEmpty()) return '';
        return this.translate.instant('IMAGE_UPLOADE.SEND_IMAGE');
    }

    public constructor(private api: ApiService, private translate: TranslateService) {
    }

    public close() {
        this.imageDialog.close();
    }

    public get isOpen(): boolean {
        return this.imageDialog.isOpen;
    }

    public open() {
        this.imageDialog.open();
    }

    private selectImage(input) {
        const reader: FileReader = new FileReader();
        if (input.target.files && input.target.files[0]) {
            reader.onload = (event: any) => this.file = event.target.result;
        }
        reader.readAsDataURL(input.target.files[0]);
    }

    private uploadImg() {
        this.api.image.upload(this.file)
        this.upload.emit();
    }

}
