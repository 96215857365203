import {
    Component,
    Input,
} from '@angular/core';
import { tabContent } from 'BKUtils';

@Component({
               selector:   'card-openable',
               template:   `
        <div class="mdc-card card">
            <div class="card__header" (click)="toggle()">
                <div *ngIf="!title.isEmpty()" class="card__header__text">{{title}}</div>
                <div class="card__header__icon">
                    <icon *ngIf="!contentVisible" icon="arrow-drop-down"></icon>
                    <icon *ngIf="contentVisible" icon="arrow-drop-up"></icon>
                </div>
            </div>
            <div class="card__content"
                 [@tabContent]="state"
                 (@tabContent.done)="toggleDone()"
            >
                <div class="card__content__padding">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    `,
               styles:  [require('./card-openable.scss')],
               animations: [tabContent],
           })
export class CardOpenable {
    private animating: boolean = false;
    @Input() private state: 'visible' | 'hidden' = 'hidden';
    @Input() private title: string = '';

    private get contentVisible(): boolean {
        return this.state === 'visible';
    }

    private toggle() {
        if (!this.animating) {
            this.animating = true;
            this.state = this.state === 'visible' ? 'hidden' : 'visible';
        }
    }

    private toggleDone() {
        this.animating = false;
    }
}
