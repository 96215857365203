import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'BKUtils';


enum Plural {
    One,
    Few,
    Many,
    Other,
}

@Pipe({
          name: 'plural',
      })

export class PluralPipe implements PipeTransform {
    public constructor(private translate: TranslateService) {

    }

    private static english(count: number): Plural {
        return count === 1 ? Plural.One : Plural.Few;
    }

    private static german(count: number): Plural {
        return count === 1 ? Plural.One : Plural.Few;
    }

    private static getPluralString(plural: Plural): string {
        switch (plural) {
            case Plural.One:
                return 'ONE';
            case Plural.Few:
                return 'FEW';
            case Plural.Many:
                return 'MANY';
            default:
                return 'OTHER';
        }
    }

    private static polnish(count: number): Plural {
        if (count === 1) {
            return Plural.One;
        }
        if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) {
            return Plural.Few;
        }
        return Plural.Many;
    }

    public transform(key: string, count: number): string {
        let plural = Plural.Other;

        switch (this.translate.currentLang) {
            case 'de-DE':
                plural = PluralPipe.german(count);
                break;
            case 'en-GB':
                plural = PluralPipe.english(count);
                break;
            case 'pl-PL':
                plural = PluralPipe.polnish(count);
                break;
            default:
                Logger.error(`Language ${this.translate.currentLang} is not supported`);
        }

        return `${key}.${PluralPipe.getPluralString(plural)}`;
    }
}
