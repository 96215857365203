import {
    Component,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import { UserBaseView } from 'BKModels';

@Component({
               selector:  'cancel-user-list-big',
               template:  `
                              <div class="cancel-user-list-big">
                                  <div *ngFor="let user of list" class="cancel-user-list-big__entry">
                                      <div class="cancel-user-list-big__entry__name">{{ user.nickname }}</div>
                                      <img [src]="user.userImage.getUrlNextAvailableSize(154, 154)"/>
                                      <raised-button (click)="action.emit(user)" class="cancel-user-list-big__entry__action">
                                          {{'LIST.TO_PROFILE' | translate }}
                                      </raised-button>
                                  </div>
                              </div>
                          `,
               styles: [require('./cancel-user-list-big.scss')],
           })

export class CancelUserListBig {
    @Input() private list: UserBaseView[];
    @Output() private action = new EventEmitter<UserBaseView>();
}
