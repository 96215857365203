import {
    Component,
    Input,
} from '@angular/core';
import {
    SingleEvent,
    SingleEventType,
} from '../../../../models/single-event';


@Component({
               selector:  'logout-singleevents-item',
               template: `
                             <div class="logout-singleevents-item">
                                 <div class="logout-singleevents-item__icon">
                                     <icon [icon]="categoryIcon[singleEvent.type] || ''"></icon>
                                 </div>
                                 <div class="logout-singleevents-item__content">
                                     <div class="logout-singleevents-item__col">
                                         <a>{{singleEvent.name}}</a>
                                         <span class="logout-singleevents-item__col__currency">{{'GLOBAL.CURRENCY' | translate:singleEvent}}</span>
                                     </div>
                                     <div class="logout-singleevents-item__col">
                                         <span>{{singleEvent.city}}, {{singleEvent.date}}</span>
                                         <span>{{getAgeRange}} {{'LOGOUT.SINGLEEVENT.YEARS' | translate}}</span>
                                     </div>
                                 </div>
                             </div>

                         `,
               styles: [require('./logout-singleevents-item.scss')],
           })
export class LogoutSingleeventsItem {
    @Input() private singleEvent: SingleEvent;

    private categoryIcon: { [key in SingleEventType]: string } = {
        [SingleEventType.Sport]:    'directions-walk',
        [SingleEventType.Culture]:  'smiley',
        [SingleEventType.Dinning]:  'local-dining',
        [SingleEventType.Discover]: 'smiley',
        [SingleEventType.Dating]:   'match-outline',
        [SingleEventType.NONE]:     '',
    };

    public constructor() {
    }

    private get getAgeRange() {
        if(this.singleEvent.minAge === this.singleEvent.maxAge){return this.singleEvent.minAge;}
        return this.singleEvent.minAge+' - '+this.singleEvent.maxAge
    }
}
