import {
    AfterViewInit,
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { MDCChipSet } from '@material/chips';

@Component({
               selector: 'chip-set',
               template: `
        <div #chipSet class="mdc-chip-set">
            <ng-content></ng-content>
        </div>
    `,
               styles: [require('./chip.scss')],
           })

export class ChipSet implements AfterViewInit {
    @ViewChild('chipSet', { static: true }) private el: ElementRef;

    public ngAfterViewInit() {
        const chipSet = new MDCChipSet(this.el.nativeElement);
    }
}
