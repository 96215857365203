import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Offer } from '../../../../../models/offer';
import { Tariff } from '../../../../../models/tariff';
import {
    Duration,
    DurationUnit,
} from '../../../../../utils/duration';

enum Variant {
    Default,
    Week,
    Offer
}

enum UspVariant {
    Default,
    Offer,
}

@Component({
               selector:  'premium-booking-page-offer',
               template:  `
                              <div class="premium-booking-page-tariff"
                                   [class.premium-booking-page-tariff--flag]="savingsRounded > 0"
                              >
                                  <ng-container *ngIf="savingsRounded > 0 ">
                                      <premium-flag class="premium-booking-page-tariff__flag" *ngIf="!specialOffer" [discount]="savingsRounded"></premium-flag>

                                      <premium-star class="premium-booking-page-tariff__star" *ngIf="!!specialOffer" [discount]="savingsRounded">
                                      </premium-star>
                                  </ng-container>

                                  <div class="premium-booking-page-tariff__name" [class.loading-text-placeholder-container]="tariffLoading">
                                      <span>{{ nameTranslationKey | translate:{ 'amount': duration.amount.toString() } }}</span>
                                  </div>

                                  <ng-container *ngIf="variant == Variant.Default">
                                      <div class="premium-booking-page-tariff__price" [class.loading-text-placeholder-container]="tariffLoading">
                                          <span>{{pricePerMonth}}</span>
                                          <span class="premium-booking-page-tariff__price__time">{{'PREMIUM_LANDINGPAGE.OFFER_BOX.MONTHLY_PRICE' | translate}}</span>
                                      </div>
    
                                      <div class="premium-booking-page-tariff__first-invoice" [class.loading-text-placeholder-container]="tariffLoading">
                                          <span *ngIf="duration.unit == DurationUnit.Month && duration.amount == 1">
                                              {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_MONTH' | translate:{ 'amount': price } }}
                                          </span>
                                          <span *ngIf="!(duration.unit == DurationUnit.Month && duration.amount == 1)">
                                              {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_MONTHS' | translate:{ 'amount': price } }}
                                          </span>
                                      </div>
                                  </ng-container>
                                  <ng-container *ngIf="variant == Variant.Week">
                                      <div class="premium-booking-page-tariff__price">
                                          {{price}}
                                      </div>

                                      <div class="premium-booking-page-tariff__first-invoice">
                                          <ng-container *ngIf="tariff.subscriptionPeriod.unit == DurationUnit.Month">
                                              <span *ngIf="tariff.subscriptionPeriod.amount == 1">
                                                 {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_RENEWAL_MONTH' | translate:{ 'amount': tariff.formattedSubscriptionPrice } }}
                                              </span>
                                              <span *ngIf="tariff.subscriptionPeriod.amount > 1">
                                                  {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_RENEWAL_MULTI_MONTH' | translate:{ 
                                                        'amount': tariff.formattedSubscriptionPrice, 
                                                        'durationAmount': tariff.subscriptionPeriod.amount 
                                                    }
                                                  }}
                                              </span>
                                          </ng-container>
                                      </div>
                                  </ng-container>
                                  <ng-container *ngIf="variant == Variant.Offer">
                                      <div class="premium-booking-page-tariff__price">
                                          {{price}}
                                      </div>

                                      <div class="premium-booking-page-tariff__first-invoice">
                                          <ng-container *ngIf="tariff.subscriptionPeriod.unit == DurationUnit.Month">
                                              <span *ngIf="tariff.subscriptionPeriod.amount == 1">
                                                 {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_RENEWAL_MONTH' | translate:{ 'amount': tariff.formattedSubscriptionPrice } }}
                                              </span>
                                              <span *ngIf="tariff.subscriptionPeriod.amount > 1">
                                                  {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_RENEWAL_MULTI_MONTH' | translate:{
                                                  'amount': tariff.formattedSubscriptionPrice,
                                                  'durationAmount': tariff.subscriptionPeriod.amount
                                              }
                                                  }}
                                              </span>
                                          </ng-container>
                                      </div>
                                  </ng-container>

                                  <div class="premium-booking-page-tariff__usp">
                                      <div class="premium-booking-page-tariff__usp__seperator"></div>
                                      <ng-container [ngSwitch]="uspVariant">
                                          <div *ngSwitchCase="UspVariant.Offer" 
                                               class="premium-booking-page-tariff__usp__text" 
                                               [innerHTML]="offerUsp|translate|inlineMarkdown"></div>
                                          <list-checked *ngSwitchDefault class="premium-booking-page-tariff__usp"
                                                        [list]="['PREMIUM.BOOKING.PAYMENT.USP_1' | translate, 'PREMIUM.BOOKING.PAYMENT.USP_2' | translate, 'PREMIUM.BOOKING.PAYMENT.USP_3' | translate]"
                                          ></list-checked>
                                      </ng-container>
                                  </div>


                                  <div *ngIf="!specialOffer" class="premium-booking-page-tariff__change">
                                      <a (click)="onClick.emit()">{{'PREMIUM.BOOKING.CHANGE_TARIFF' | translate}}</a>
                                  </div>

                              </div>
                          `,
               styles: [require('./premium-booking-page-offer.scss')],
           })

export class PremiumBookingPageOffer {
    @Input() private percentSaved: number;
    @Input() private specialOffer: Offer;
    @Input() private tariff: Tariff;
    @Output() private onClick = new EventEmitter();

    UspVariant = UspVariant;

    get tariffLoading(): boolean {
        return this.tariff.isEmpty();
    }

    get uspVariant(): UspVariant {
        if (!!this.specialOffer) return UspVariant.Offer;
        return UspVariant.Default;
    }

    get offerUsp(): string {
        if (!this.specialOffer) return "";

        return `PREMIUM.BOOKING.BOX_INFO.${this.specialOffer.offerType.toUpperCase()}`;
    }

    private get duration(): Duration {
        return this.tariff.period;
    }

    private get price(): string {
        return this.tariff.formattedPrice;
    }

    private get pricePerMonth(): string {
        return this.tariff.formattedPricePerMonth;
    }

    private DurationUnit = DurationUnit;

    private Variant = Variant;

    private get variant(): Variant {

        if( this.specialOffer && this.duration.unit !== DurationUnit.Week){
            return Variant.Offer;
        }

        return this.duration.unit === DurationUnit.Week ? Variant.Week : Variant.Default;
    }

    private get savingsRounded(): number {
        return Math.floor(this.percentSaved);
    }

    private get nameTranslationKey(): string {
        const map = {
            [DurationUnit.Week]: {
                singular: 'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_WEEK',
                plural: 'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_WEEKS'
            },
            [DurationUnit.Month]: {
                singular: 'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_MONTH',
                plural: 'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_MONTHS'
            }
        };

        if (!map[this.duration.unit]) return '';
        return this.duration.amount === 1 ? map[this.duration.unit].singular : map[this.duration.unit].plural;
    }
}
