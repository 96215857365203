import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import * as moment from 'moment';
import { PremiumDetails } from '../../../../../models/premium-details';
import { UserCounts } from '../../../../../models/user-counts';
import { ListService } from '../../../../../service/list.service';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

@Component({
               selector:  'premium-cancel-step-list',
               template: `
                             <div>
                                 <div class="premium-cancel-step-list__headline">{{ 'PREMIUM.CANCEL.LISTS.HEADLINE'|translate }}</div>
                                 <div class="premium-cancel-step-list__subheadline">{{ 'PREMIUM.CANCEL.LISTS.SUBHEADLINE'|translate }}</div>
                                 <premium-cancel-single-list *ngIf="!showLists; else lists"
                                                             [list]="selectedUserList"
                                 ></premium-cancel-single-list>

                                 <ng-template #lists>
                                     <premium-cancel-lists [interestedInMeUserList]="interestedInMeUserList"
                                                           [matchPartnerUserList]="matchPartnerUserList"
                                                           [profileVisitorList]="profileVisitorList"
                                                           [listSize]="listSize"
                                                           [counts]="userCounts"
                                     ></premium-cancel-lists>
                                 </ng-template>
                                 
                                 <premium-day-box [text]="translation | translate:translationParams"></premium-day-box>

                                 <div class="premium-cancel-step-list__footer">
                                     <raised-button (click)="onCancel($event)">{{'GLOBAL.CANCEL' | translate}}</raised-button>
                                     <raised-button fake-disable (click)="onNext($event)">{{'PREMIUM.CANCEL.REASON-PAGE.CONTINUE' | translate}}</raised-button>
                                 </div>
                             </div>
                         `,
               styles: [require('./premium-cancel-step-list.scss')],
           })

export class PremiumCancelStepList implements OnInit {
    @Output() private cancel = new EventEmitter();
    @Output() private next = new EventEmitter();

    @Input()
    private set premiumDetails(premiumDetails: PremiumDetails) {
        if (premiumDetails) {
            // Ein Tag wird drauf gerechnet da expireAt der letzte Tag ist an dem ein Benutzer Premium hat.
            const expireIn = premiumDetails.active.remainingDays;


            this.translationParams.expireIn = expireIn.toString();
            if (expireIn !== 1) this.translation = this.translationPlural;
            else this.translation = this.translationSingular;
        }
    }

    private translationSingular: string = 'PREMIUM.CANCEL.SELECTED_SINGLES.BOX';
    private translationPlural: string = 'PREMIUM.CANCEL.SELECTED_SINGLES.BOX.MULTIPLE';
    private translationParams = {
        expireIn: '',
        link:     this.navigationService.getRoute(Routes.Startpage),
    };
    private translation: string = this.translationSingular;

    private listSize = 5;
    private selectedUserListSize = 4;

    private userCounts = new UserCounts();
    private interestedInMeUserList: UserBaseView[] = [];
    private matchPartnerUserList: UserBaseView[] = [];
    private profileVisitorList: UserBaseView[] = [];
    private selectedUserList: UserBaseView[] = [];

    public constructor(
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private listService: ListService,
        private matchingService: MatchingService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        this.premiumService.getDetails().then(premiumDetails => this.premiumDetails = premiumDetails);
        this.currentUserService.getCounts().then(value => this.userCounts = value);
        this.listService.profileVisitors(false, this.listSize).then(value => this.profileVisitorList = value);
        this.matchingService.matchPartner(this.listSize).then(value => this.matchPartnerUserList = value);
        this.matchingService.wantYou(this.listSize).then(value => this.interestedInMeUserList = value);
        this.listService.selectedUser(this.selectedUserListSize).then(value => this.selectedUserList = value.result);
    }

    private get showLists(): boolean {
        return /*this.interestedInMeUserList.length >= this.listSize &&*/ this.matchPartnerUserList.length >= this.listSize
            && this.profileVisitorList.length >= this.listSize;
    }

    private onCancel(e) {
        this.trackingService.hit('PremiumKuendigen', 'ProzessVerlassen', 'Abbrechen');
        this.cancel.emit(e);
    }

    private onNext(e) {
        this.trackingService.hit('PremiumKuendigen', 'WeiterVerluste', 'Fortfahren');
        this.next.emit(e);
    }
}
