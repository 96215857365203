import {
    ComponentFactoryResolver,
    ViewContainerRef,
} from '@angular/core';

export enum NotificationType {
    Hidden = 0,
    StartPage = (1 << 0),
    Growl = (1 << 1),
}

export enum NotificationStyle {
    Information,
    Success,
    Warning,
    Error,
}

export abstract class Notification {
    /**
     * Notification id is unique in the notification list.
     * Use a static value to make this notification type unique.
     * Use a e.g. randomly generated value to allow multiple instances in the notification list.
     */
    abstract notificationId: string;

    types: NotificationType = NotificationType.Hidden;
    style: NotificationStyle = NotificationStyle.Information;
    importance: number = 0;

    abstract titleKey: string;
    titleParams: { [key: string]: string } = {}
    abstract contentKey: string;
    contentParams: { [key: string]: string } = {}
    actionKey: string = '';
    // eslint-disable-next-line no-empty-function
    action: () => void = () => {};
    // eslint-disable-next-line no-empty-function
    addExtraContent: (container: ViewContainerRef, componentFactoryResolver: ComponentFactoryResolver) => void = () => {};
    // eslint-disable-next-line no-empty-function
    removeExtraContent: () => void = () => {};

    foldable: boolean = false;
    premiumAction: boolean = false;

    hasType(type: NotificationType): boolean {
        return (this.types & type) !== 0;
    }
}
