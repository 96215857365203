import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Tariff } from '../../../models/tariff';

@Component({
               selector:  'loyalty-offer-banner',
               template:  `<div class="banner" [class.banner--small]="small">
                   <div class="banner__border">
                       <div class="banner__box1">
                           <div class="banner__box1__title">{{(small? 'OFFER.LOYALTY_OFFER.BANNER.SMALL.TITLE' : 'OFFER.LOYALTY_OFFER.BANNER.TITLE')|translate}}</div>
                       </div>
                       <div class="banner__button__panel" *ngIf="!small">
                           <raised-button premium (click)="purchaseOffer.emit()">{{'OFFER.LOYALTY_OFFER.CTA.TEXT'|translate}}</raised-button>
                       </div>
                       <div class="banner__box2">
                           <h3 class="banner__box2__title">{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.TITLE'|translate }}</h3>
                           <h6 class="banner__box2__price">{{ offerTariff.formattedPrice }}</h6>
                           <div class="banner__box2__subtitle">{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.SUBTITLE'|translate:{'percentDiscount': discount} }}</div>
                           
                       </div>
                       <deko-star class="star">
                           <h3 class="star-text">{{discount}}%</h3>
                           <span>gespart</span>
                       </deko-star>
                   </div>
               </div>`,
               styles: [require('./loyalty-offer-banner.scss')],
           })
export class LoyaltyOfferBanner {
    @Input() offerTariff: Tariff;
    @Input() discount: string = '0';
    @Input() small: boolean = false;
    @Output() purchaseOffer = new EventEmitter();
}