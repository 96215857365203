import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { openRegistration } from 'BKUtils';
import {
    NavigationService,
    Routes,
} from '../../service/navigation.service';

@Component({
               selector:  'error',
               template:  `
        <bk-header-out>
            <raised-button>Login <span id="for_members" (click)="toLogin()">für Mitglieder</span></raised-button>
        </bk-header-out>


        <section class="header">
            <h1>{{'ERROR_EXTERN.HEROSHOT.HEADLINE' | translate}}</h1>
            <p class="header-text">{{'ERROR_EXTERN.HEROSHOT.TEXT'|translate}}</p>

            <raised-button
                class="header-button-big"
                (click)="registrationClick()"
            >{{'ERROR_EXTERN.HEROSHOT.BUTTON_TEXT'|translate}}
            </raised-button>

            <div class="app-buttons">
                <a href="https://itunes.apple.com/de/app/bildkontakte-deine-flirt-dating/id999364287"
                   class="app-button"
                   id="app_store"
                >
                    <img class="apple-badge"
                         alt="Button der zum Appstore führt und Ihnen erlaubt die App runter zu laden"
                    >
                </a>
                <a href="https://play.google.com/store/apps/details?id=de.entrex.bildkontakte"
                   class="app-button"
                   id="play_store"
                >
                    <img class="android-badge"
                         alt="Button der zum Playstore führt und Ihnen erlaubt die App runter zu laden"
                    >
                </a>
            </div>

            <raised-button class="header-button">Button Text</raised-button>

        </section>


        <section class="advantages">
            <ul class="advantages-list">
                <li>{{'ERROR_EXTERN.ADVANTAGES.ADVANTAGE_1'|translate}}</li>
                <li>{{'ERROR_EXTERN.ADVANTAGES.ADVANTAGE_2'|translate}}</li>
                <li>{{'ERROR_EXTERN.ADVANTAGES.ADVANTAGE_3'|translate}}</li>
            </ul>

            <div class="siegel">
                <a href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html#wdj2016"
                   target="_blank"
                >
                    <img class="wdj"
                         alt="Bild von unserem Website des Jahres Siegel"
                         id="siegel_1"
                    ></a>
                <a href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html#disqSicherheit"
                   target="_blank"
                >
                    <img class="disq"
                         alt="Bild von unserem n-tv Siegel"
                         id="siegel_2"
                    ></a>
                <a href="https://www.bildkontakte.de/w/glossar/de_DE/dating/mit-bildkontakte-bist-du-auf-der-sicheren-web-seite.html#focusmoneyKundenliebling"
                   target="_blank"
                >
                    <img class="focus_money"
                         alt="Bild von unserem Focus Money Siegel"
                         id="siegel_3"
                    ></a>
            </div>
        </section>


        <bk-footer-out></bk-footer-out>
    `,
               styles: [
                   require('./error.scss'),
               ],
           })
export class Error {
    public constructor(private navigationService: NavigationService, private router: Router) {
    }

    private toLogin() {
        this.navigationService.navigateTo(Routes.Login)
    }

    private registrationClick() {
        openRegistration();
    }
}
