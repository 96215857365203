import { MatchingPartner } from './matching-partner/matching-partner';
import { MatchingSecondChance } from './matching-second-chance/matching-second-chance';
import { MatchingStart } from './matching-start/matching-start';
import { MatchingWantToMeet } from './matching-want-to-meet/matching-want-to-meet';
import { MatchingWantYou } from './matching-want-you/matching-want-you';

export * from './matching-start/matching-start';
export * from './matching-partner/matching-partner';
export * from './matching-want-to-meet/matching-want-to-meet';
export * from './matching-want-you/matching-want-you';
export * from './matching-second-chance/matching-second-chance';


export default [
    MatchingStart,
    MatchingPartner,
    MatchingWantToMeet,
    MatchingWantYou,
    MatchingSecondChance
];
