import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import {
    NavigationService,
    SearchRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';
import { Modal } from '../../../../../shared/mat-design/modal/modal';

@Component({
               selector:  'premium-features-full-modal',
               template:  `
                              <modal #modal>
                                  <div class="premium-features-full-modal__title">{{'PREMIUM.FULL_FEATURE_MODAL.TITLE' | translate}}</div>

                                  <div class="premium-features-full-modal__block-header premium-features-full-modal__first-block-header">
                                       <span class="premium-features-full-modal__first-block-header__title">
                                           {{'PREMIUM.FULL_FEATURE_MODAL.FAVOR_FUNCTION' | translate}}
                                       </span>
                                      <premium-column-badge [active]="!isPremium" [premiumVariant]="false"></premium-column-badge>
                                      <premium-column-badge [active]="isPremium" [premiumVariant]="true"></premium-column-badge>
                                  </div>
                                  <div class="premium-features-full-modal__block premium-features-full-modal__first-block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">
                                          <a [routerLink]="navigationService.getRoute(Routes.ProfileVisitors)">{{'PREMIUM.USP.SEE_PROFILE_VISITOR' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">
                                          <a [routerLink]="navigationService.getRoute(Routes.MatchingPartner)">{{'PREMIUM.USP.SEE_MATCHPARTNER' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.SEE_ALL_PICS_BIG' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false"><a [routerLink]="navigationService.getRoute(Routes.Search)"
                                                                                                     [fragment]="SearchRouteFragment.OpenExtendedSearch"
                                      >{{'PREMIUM.USP.ADVANCED_SEARCH_FUNCTIONS' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.FLIRT_WITHOUT_ADS' | translate}}</premium-table-row>
                                  </div>

                                  <div class="premium-features-full-modal__block-header">
                                      {{'PREMIUM.FULL_FEATURE_MODAL.MESSAGES' | translate}}
                                  </div>
                                  <div class="premium-features-full-modal__block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.WRITE_TO_MEMBERS' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.RECEIVE_MESSAGES' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.REPLY_TO_MESSAGES' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.READ_STATUS' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.NOTIFICATION_FAV_BIRTHDAY' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.OLD_MESSAGES' | translate}}</premium-table-row>
                                  </div>

                                  <div class="premium-features-full-modal__block-header">
                                      {{'PREMIUM.FULL_FEATURE_MODAL.PICTURES' | translate}}
                                  </div>
                                  <div class="premium-features-full-modal__block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.SEE_PROFILE_IMAGE' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.SEE_ALL_PICTURE' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.SEE_PICTURE_BIG' | translate}}</premium-table-row>
                                  </div>

                                  <div class="premium-features-full-modal__block-header">
                                      {{'PREMIUM.FULL_FEATURE_MODAL.MATCHING' | translate}}
                                  </div>
                                  <div class="premium-features-full-modal__block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.MATCH_MEMBER' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false"><a [routerLink]="navigationService.getRoute(Routes.MatchingPartner)">{{'PREMIUM.USP.SEE_MATCHPARTNER' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">
                                          <a [routerLink]="navigationService.getRoute(Routes.MatchingYouWantMeet)">{{'PREMIUM.USP.SEE_YOUR_WISH_PARTNER' | translate}}</a>
                                      </premium-table-row>
                                  </div>

                                  <div class="premium-features-full-modal__block-header">
                                      {{'PREMIUM.FULL_FEATURE_MODAL.PROFILE' | translate}}
                                  </div>
                                  <div class="premium-features-full-modal__block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.CREATE_OWN_PROFILE' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.SEE_OTHER_PROFILES' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.SEE_PROFILES_INVISIBLE' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false"><a [routerLink]="navigationService.getRoute(Routes.ProfileVisitors)">{{'PREMIUM.USP.SEE_PROFILE_VISITOR' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false">{{'PREMIUM.USP.SEE_DISTANCE' | translate}}</premium-table-row>
                                  </div>

                                  <div class="premium-features-full-modal__block-header">
                                      {{'PREMIUM.FULL_FEATURE_MODAL.SEARCH' | translate}}
                                  </div>
                                  <div class="premium-features-full-modal__block">
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.QUICK_SEARCH' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="true">{{'PREMIUM.USP.NICKNAME_SEARCH' | translate}}</premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false"><a [routerLink]="navigationService.getRoute(Routes.Search)">{{'PREMIUM.USP.ADVANCED_SEARCH_FUNCTIONS' | translate}}</a>
                                      </premium-table-row>
                                      <premium-table-row [premium]="isPremium" [forBasic]="false" [infoText]="'PREMIUM.USP.PREFERRED_IN_SEARCH.INFO_TEXT' | translate">
                                          {{'PREMIUM.USP.PREFERRED_IN_SEARCH' | translate}}</premium-table-row>
                                  </div>
                                  
                                  <div class="premium-features-full-modal__actions"
                                       [class.premium-features-full-modal__actions--center]="hasSubscription"
                                  >
                                      <div class="premium-features-full-modal__actions__close">
                                          <flat-button (click)="close()">{{'PREMIUM.FULL_FEATURE_MODAL.CLOSE' | translate}}</flat-button>
                                      </div>
                                      <div class="premium-features-full-modal__actions__offer" *ngIf="!hasSubscription">
                                          <raised-button premium (click)="toPremium()">{{premiumButtonText | translate}}</raised-button>
                                          <p>{{'PREMIUM.OVERVIEW.PRICE'| translate:{ price: monthPrice } }}</p>
                                      </div>
                                  </div>
                                  
                              </modal>
                          `,
               styles: [require('./premium-features-full-modal.scss')],
           })

export class PremiumFeaturesFullModal {
    @ViewChild('modal', { static: true }) private modal: Modal;
    @Input('premium') private isPremium: boolean;
    @Input('subscription') private hasSubscription: boolean;
    @Input() private monthPrice: string = '';

    private Routes = Routes;
    private SearchRouteFragment = SearchRouteFragment;

    constructor(
        private navigationService: NavigationService,
        private premiumService: PremiumService
    ) {}

    public show() {
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }

    private toPremium() {
        this.premiumService.navigateToPremium(OrderReason.PremiumServiceOverview)
    }

    private get premiumButtonText(): string {
        return this.isPremium ? 'PREMIUM_OVERVIEW.EXTEND_PREMIUM' : 'PREMIUM.OVERVIEW.TO_OFFERS';
    }
}
