import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../../service/navigation.service';
import { InternDefaultHeader } from '../../../../shared/mat-design/header/intern-default-header';
import { LovestoryFormular } from './formular';
import { Lovestory } from './overview';
import { LovestoryHeader } from './overview/lovestory-header/lovestory-header';


const lovestoryRoutes: NgRoutes = [{
    path:     '',
    children: [{
        path:      '',
        component: Lovestory,
    }, {
        path:      '',
        component: LovestoryHeader,
        outlet:    'header',
    },
    /*{
        path:      routeName(Routes.LovestoryFormular),
        component: LovestoryFormular,
    },*/
    ],
},
];


export const LovestoryRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(lovestoryRoutes);
