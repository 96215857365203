import { Component } from '@angular/core';

@Component({
               selector:  'favorite-header',
               template:  ` 
                              <intern-default-header [title]="'LAYOUT.SIDE_NAV.FAVORITE' | translate" [closeable]="true">
                                  <favorite-tabbar class="is-hidden-desktop"></favorite-tabbar>
                              </intern-default-header>
                          `,
           })

export class FavoriteHeader {
}
