import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({
          name: 'badge',
      })
class BadgePipe implements PipeTransform {
    public transform(value: string | number): string {
        const num = value;
        if (num === 0) {
            return '';
        }
        if (num > 99) {
            return '99+';
        }

        return value.toString();
    }
}


export default [BadgePipe];
