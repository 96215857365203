import {
    Component,
    OnInit,
} from '@angular/core';
import { CurrentUserService } from 'BKService';
import { FavoriteDeletedDataSource } from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';

@Component({
               selector:  'favorite-delete',
               template:  `
                              <favorite-list [listDataSource]="dataSource"></favorite-list>
                          `,
           })

export class FavoriteDelete implements OnInit {
    private dataSource: FavoriteDeletedDataSource;

    public constructor(private dataSourceFactory: DataSourceFactory) {
    }

    public ngOnInit() {
        this.dataSourceFactory.createFavoriteDeletedDataSource().then(dataSource => {
            this.dataSource = dataSource;
            this.dataSource.loadMore();
        });
    }

}
