import { Component } from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';

@Component({
               selector: 'lovestory-form-image',
               template: `
        <div class="ls-form-img">
            <div (click)="clickInput()"
                 class="ls-form-img__placeholder"
                 *ngIf="value.length == 0"
            >
                <div class="ls-form-img__placeholder__icon">
                    <!-- Prüfen wenn wir hier dran sind-->
                    <!--<icon icon="icon-plus"></icon>-->
                </div>
            </div>

            <img [src]="value"
                 *ngIf="value.length > 0"
                 class="ls-form-img__image"
                 (click)="clickInput()"
            >

        </div>


        <input type="file"
               accept="image/*"
               style="display: none"
               [attr.id]="id"
               (change)="change($event)"
        >
    `,
               styles: [require('./lovestory-formular-image.scss')],
               providers: [
                   getValueAccessorProvider(LovestoryFormularImage),
               ],
           })
export class LovestoryFormularImage extends ValueAccessorBase<string> {
    private id = uuidv4();

    private change($event) {
        const reader: FileReader = new FileReader();
        if ($event.target.files && $event.target.files[0]) {
            reader.onload = (input: any) => {
                this.value = input.target.result;
            };
        }
        reader.readAsDataURL($event.target.files[0]);
    }

    private clickInput() {
        document.getElementById(this.id)
                .click();
    }
}
