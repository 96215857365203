import { Injectable } from '@angular/core';
import {
    BillingAddress,
    CashReference,
    DebitRequest,
    OrderReason,
    SepaAccount,
} from 'BKModels';
import { addLogoutEventListener } from 'BKUtils';
import { OpenInvoice } from '../models/open-invoice';
import { Product } from '../models/product';
import { ProductList } from '../models/productList';
import { Tariff } from '../models/tariff';
import {
    Cache,
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';
import { PremiumDebitResult } from './api/calls/payment.api';
import { CommunicationService } from './communication/communication.service';
import { GooglePlayPaymentsService } from './google-play-payments.service';

export enum StoreType {
    Unknown, Website, GooglePlay
}

@Injectable({ providedIn: 'root' })
export class PaymentService {
    @Cacheable(CacheKey.PremiumProductList)
    public get productList(): Promise<ProductList> {
        return this.apiService.payment.produtcs();
    }

    private _billingAddress: BillingAddress = null;

    public billingAddress(): Promise<BillingAddress> {
        if (this._billingAddress) return Promise.resolve(this._billingAddress);

        return this.apiService.payment
                   .billingAddress()
                   .then(billingAdress => {
                       this._billingAddress = billingAdress;
                       return billingAdress;
                   });

    }

    public setBillingAddress(billingAdress: BillingAddress) {
        this._billingAddress = BillingAddress.create(billingAdress);
    }

    private _sepaAccount: SepaAccount = null;

    public sepaAccount(): Promise<SepaAccount> {
        if (this._sepaAccount) return Promise.resolve(this._sepaAccount);

        return this.apiService.payment
                   .sepaAccount()
                   .then(sepaAccount => {
                       this._sepaAccount = sepaAccount;
                       return sepaAccount;
                   });
    }

    public setSepaAccount(val: SepaAccount) {
        this._sepaAccount = SepaAccount.create(val);
    }

    public constructor(
        private apiService: ApiService,
        private communicationService: CommunicationService,
        private googlePlayPaymentsService: GooglePlayPaymentsService) {
        addLogoutEventListener(() => {
            this._billingAddress = null;
            this._sepaAccount = null;
        });
    }

    @Cacheable(CacheKey.Tariff)
    public tariff(tariffId: number): Promise<Tariff> {
        return this.apiService.payment.tariff(tariffId);
    }

    public orderWithDebit(tariff: Tariff, orderReason?: OrderReason, offerId?: string): Promise<PremiumDebitResult> {
        const debitRequest = new DebitRequest(tariff.tariffId, this._billingAddress, this._sepaAccount, orderReason, offerId);
        return this.apiService.payment
                   .directDebit(debitRequest)
                   .then(res => {
                       Cache.getInstance().reload(CacheKey.PremiumDetails);
                       return res;
                   });
    }

    public get mostExpensivePremiumProduct(): Promise<Product> {
        return this.productList
                   .then(list => list.premium.reduce((mostExpensive, cur) => {
                       if (!mostExpensive || cur.pricePerDay > mostExpensive.pricePerDay) return cur;
                       return mostExpensive;
                   }));
    }

    public get cheapestPremiumProduct(): Promise<Product> {
        return this.productList.then(list => list.premium.reduce((cheapest, cur) => {
            if (!cheapest || cur.pricePerDay < cheapest.pricePerDay) return cur;
            return cheapest;
        }));
    }

    public changePaymentData(billingAddress: BillingAddress, sepaAccount: SepaAccount): Promise<any> {
        if (sepaAccount.account_holder.isEmpty()) sepaAccount.account_holder = `${billingAddress.forename} ${billingAddress.surname}`;

        this.setBillingAddress(billingAddress);
        this.setSepaAccount(sepaAccount);


        return this.apiService.payment.changePaymentData(billingAddress, sepaAccount)
                   .then(state => {
                       if (state) this.communicationService.growl.payment.changeDebit.handleSuccess();
                       else this.communicationService.growl.payment.changeDebit.handleError();
                   }).catch(() => this.communicationService.growl.payment.changeDebit.handleError());
    }

    @Cacheable(CacheKey.CashReference)
    public cashReference(userId, tarifId): Promise<CashReference> {
        return this.apiService.payment.getCashReference(userId, tarifId);
    }

    public payCash(tariff: Tariff): Promise<boolean> {
        return this.apiService.payment.payCash(tariff);
    }

    @Cacheable(CacheKey.OpenInvoice)
    public openInvoices(): Promise<OpenInvoice> {
        return this.apiService.payment.openInvoices();
    }

    public debitAgain(billingAddress: BillingAddress, sepaAccount: SepaAccount): Promise<boolean> {
        this._billingAddress = billingAddress;
        this._sepaAccount = sepaAccount;

        return this.apiService.payment.debitAgain(billingAddress, sepaAccount).then(res => {
            this.communicationService.growl.payment.repay.handleSuccess();
            Cache.getInstance().delete(CacheKey.OpenInvoice);
            return res;
        }).catch(res => {
            this.communicationService.growl.payment.repay.handleError();
            return res;
        });
    }

    public clear() {
        this._sepaAccount = null;
        this._billingAddress = null;
        Cache.getInstance().delete(CacheKey.CashReference, CacheKey.PremiumProductList);
    }

    private isAndroidTWA: boolean|null = null;
    private async detectAndroidTWA(): Promise<boolean> {
        if (this.isAndroidTWA !== null) return this.isAndroidTWA;
        this.isAndroidTWA = document.referrer.includes('android-app://de.entrex.bildkontakte');
        return this.isAndroidTWA;
    }

    private detectedGooglePlay: boolean|null = null;
    private async detectGooglePlay(): Promise<boolean> {
        if (this.detectedGooglePlay !== null) return this.detectedGooglePlay;
        try {
            await this.googlePlayPaymentsService.availableItems();
            this.detectedGooglePlay = true;
        } catch (e) {
            this.detectedGooglePlay = false;
        }

        return this.detectedGooglePlay;
    }

    public async detectStoreType(): Promise<StoreType> {
        if (await this.detectAndroidTWA()) {
            if (await this.detectGooglePlay()) return StoreType.GooglePlay;
        }

        return StoreType.Website;
    }
}
