import { Injectable } from '@angular/core';
import { LoginDisabledReasons } from 'BKModels';
import {
    addForcedLogoutEventListener,
    dispatchLoginEvent,
    dispatchLogoutEvent,
} from 'BKUtils';
import { Cache } from '../utils/cache';
import { ApiService } from './api/api.service';
import { StoreService } from './storage/store.service';
import { TrackingService } from './tracking/tracking.service';
import { CurrentUserService } from './user.service';

export class AccountDisabledError {
    constructor(
        public readonly reason: LoginDisabledReasons,
        public readonly payload: any) {}
}

@Injectable({ providedIn: 'root' })
export class SessionService {

    public constructor(
        private apiService: ApiService,
        private storeService: StoreService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService
    ) {
        addForcedLogoutEventListener(() => this.onForcedLogout());
    }

    public login(payload: any, rememberMe: boolean = false): Promise<any> {
        return this.apiService.login(payload)
                   .then(res => {
                       if (!!res.disabled) throw new AccountDisabledError(res.disabled, res.payload);
                       this.storeService.token.next(res.access_token);
                       if (rememberMe) this.storeService.persistedToken.next(res.access_token);

                       this.apiService.sendDeviceInfos();
                       dispatchLoginEvent();
                       this.trackingService.hit('Login', 'Login', 'Erfolgreich');
                       return this.currentUserService.loadCurrentUser();
                   });
    }

    public loginWithToken(nonce: string): Promise<any> {
        return this.apiService.loginWithNonce(nonce)
        .then(res => {
            this.storeService.token.next(res.access_token);
            this.apiService.sendDeviceInfos();
            dispatchLoginEvent();

            return this.currentUserService.loadCurrentUser();
    });
    }

    public logout() {
        this.apiService.logout().finally(() => this.onForcedLogout());
    }

    public isLoggedIn(): boolean {
        const token = this.storeService.token;
        return !token.isEmpty() && !token.getValues().isEmpty();
    }

    private onForcedLogout() {
        Cache.getInstance().clear();
        dispatchLogoutEvent();
    }

}
