// TODO auslagern zusammen mit den login header/footer

import {
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

/**
 * ProfileHeader für den nicht eingeloggten Bereich
 */
@Component({
               selector:  'bk-header-out',
               template:  `
                              <div class="header-out-container"
                                   [style.paddingTop.px]="height"
                              >

                                  <header class="header-out" #header>
                                      <div class="header-out__top">
                                          <div class="header-out__logo-holder"
                                               (click)="goToLogin()"
                                          >
                                              <span class="header-out__logo header-out__logo--full"></span>
                                              <p class="header-out__logo-caption">{{ 'GLOBAL.COMPANY_SLOGAN' | translate }}</p>
                                          </div>
                                          <show-translation-keys></show-translation-keys>
                                          <div class="header-section-right">
                                              <ng-content></ng-content>
                                          </div>
                                      </div>
                                      <div class="header-out__extra">
                                          <ng-content select="[extra]"></ng-content>
                                      </div>
                                  </header>
                              </div>
                          `,
               styles: [require('./header-out.scss')],
           })
export class HeaderOut {
    @ViewChild('header', { static: true }) private header: ElementRef;

    public constructor(private navigationService: NavigationService) {

    }

    public goToLogin() {
        this.navigationService.navigateTo(Routes.Login);
    }

    public get height() {
        const computedHeight = window.getComputedStyle(this.header.nativeElement).height;
        const heightNumber = parseInt(computedHeight.replace('px', ''));
        return heightNumber;
    }
}
