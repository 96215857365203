import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    CurrentUser,
    Gender,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import {
    Observable,
    Subscription,
} from 'rxjs';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../service/navigation.service';
import { InternDefaultHeader } from '../../../../shared/mat-design/header/intern-default-header';
import { BKStore } from '../../../../store/store';
import {
    SearchService,
    SearchVisiblePage,
} from '../search.service';

@Component({
               selector:  'search-header',
               template:  `
                              <intern-default-header #header [title]="titleKey | translate"
                                                     [closeable]="searchService.visiblePage !== SearchVisiblePage.ActiveUsers"
                              >
                                  <search-header-menu menu class="is-hidden-desktop" (changeMode)="headerRef.openContent()"></search-header-menu>
                                  <div class="search-header__content is-hidden-desktop" [class.search-header__content--visible]="isHeaderExtended$|async">
                                      <search-criteria *ngIf="searchService.visiblePage === SearchVisiblePage.Search"></search-criteria>
                                      <search-nickname *ngIf="searchService.visiblePage === SearchVisiblePage.NicknameSearch"
                                                       [value]="searchService.nicknameSearch | async"
                                                       (valueChange)="searchService.nicknameSearch.next($event)"
                                                       (close)="searchService.visiblePage === SearchVisiblePage.Search"
                                      ></search-nickname>
                                      <div class="search-header__content__active-users__settings-box" *ngIf="searchService.visiblePage === SearchVisiblePage.ActiveUsers">
                                          <chip icon="wc" [closeable]="false" (click)="onOpenSettings()">{{ genderTextKey|translate }}</chip>
                                          <chip icon="event" [closeable]="false" (click)="onOpenSettings()">{{ 'ACTIVE_USERS_PREVIEW.SEARCH_AGE'|translate:ageParams }}</chip>
                                      </div>
                                  </div>
                                  
                              </intern-default-header>
                          `,
               styles: [require('./search-header.scss')],
           })

export class SearchHeader implements OnInit {
    private headerExtendedSubscription = new Subscription();
    @ViewChild("header",{static: true} ) headerRef: InternDefaultHeader;

    headerExtendedState: boolean = true;

    SearchVisiblePage = SearchVisiblePage;

    currentUser: CurrentUser;
    isHeaderExtended$: Observable<boolean>;

    ngAfterViewInit()
    {
        this.isHeaderExtended$ = this.store.select('headerExtended');
        this.headerExtendedSubscription = this.isHeaderExtended$.subscribe(
            {
                next: (headerState: boolean) =>
                      {
                          this.headerExtendedState = headerState;
                      },
            });
    }

    public ngOnDestroy(): void {

        this.headerExtendedSubscription.unsubscribe();
    }

    get titleKey(): string {
        const map = {
            [SearchVisiblePage.Search]: 'SEARCH.NORMAL',
            [SearchVisiblePage.NicknameSearch]: 'SEARCH.NICKNAME',
            [SearchVisiblePage.ActiveUsers]: 'SEARCH.ACTIVE_USERS',
        };

        return map[this.searchService.visiblePage];
    }

    public constructor(
        public searchService: SearchService,
        private navigationService: NavigationService,
        private currentUserService: CurrentUserService,
        private store: Store<BKStore>
    ) {
    }

    public ngOnInit(): void {
        this.currentUserService.currentUser
            .then(value => this.currentUser = value);
    }

    get genderTextKey(): string {
        if (!this.currentUser) return '-';

        switch (this.currentUser.searchGender) {
            case Gender.Male:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_MALE';
            case Gender.Female:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_FEMALE';
            case Gender.Both:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_BOTH';
            case Gender.None:
            default:
                return '-';
        }
    }

    get ageParams(): { from: string, to: string } {
        if (!this.currentUser) return { from: '0', to: '0' };

        return {
            'from': this.currentUser.ageFrom.toString(),
            'to': this.currentUser.ageTill.toString(),
        };
    }

    onOpenSettings() {
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }
}
