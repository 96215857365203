import {
    Component,
    ContentChild,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
} from '@angular/core';
import { getScrollContainer } from 'BKUtils';
import { Subscription } from 'rxjs';
import { ListDataSource } from '../../../models/list-data-sources';

@Component({
               selector: 'list-container',
               template: `
                              <div class="list-container"
                                   [class.list-container--list-view]="listView"
                                   *ngIf="dataSource"
                              >
                                  <ng-container #list
                                                *ngFor="let item of dataSource.listObservable | async"
                                  >
                                      <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: (item)}"></ng-template>
                                  </ng-container>
                              </div>
                              <loading-spinner *ngIf="isLoading" class="list-base__loading"></loading-spinner>
                          `,
               styles: [require('./list-container.scss')],
           })
export class ListContainer implements OnInit, OnDestroy {
    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
    @Input() private dataSource: ListDataSource<any>;
    @Input() private listView: boolean = false;
    @Input() private singleFetch: boolean = false;
    @Output() private scroll: EventEmitter<number> = new EventEmitter<number>();
    private lastScrollTop = 0;

    public ngOnInit() {
        if (this.singleFetch) this.dataSource.loadMore();
        else getScrollContainer().addEventListener('scroll', this.onScrollEvent);
    }

    public ngOnDestroy(): void {
        if (!this.singleFetch) getScrollContainer().removeEventListener('scroll', this.onScrollEvent);
    }

    private get isLoading(): boolean {
        return !!this.dataSource && this.dataSource.isLoading;
    }

    private onScrollEvent = () => {
        const container = getScrollContainer();
        const containerHeight = container.containerHeight;
        const scrollerHeight = container.scrollHeight;

        const pageOffset = container.scrollTop;
        const scrollOffsetForReload = 1950;
        if (pageOffset > this.lastScrollTop && containerHeight + pageOffset > scrollerHeight - scrollOffsetForReload) {
            if (this.isLoading) return;
            this.dataSource.loadMore();
        }
        this.lastScrollTop = pageOffset;
        this.scroll.emit(pageOffset);
    };
}
