import {
    Component,
    Input,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               template: `
        <a (click)="navigate()">
            <ng-content></ng-content>
        </a>`
           })

export class BkLink {
    @Input() private route: string = '';
    @Input() private target: string;

    constructor(private navigationService: NavigationService) {
    }

    navigate() {
        if (this.route && !this.route.isEmpty() && this.route in Routes) {
            this.navigationService.navigateTo(Routes[this.route]);
        }
    }
}
