import {
    Component,
    Input,
} from '@angular/core';
import { formatAmount } from 'BKUtils';
import { PremiumSubscription } from '../../../../models/premium-details';
import { DurationUnit } from '../../../../utils/duration';

@Component({
               selector:  'settings-account-tariff',
               template:  `
                              <div class="settings-account-tariff">
                                  <div class="settings-account-tariff__name">
                                      {{subscription.period.amount}} {{subscription.period.durationTranslationKey | translate }} {{'GLOBAL.PREMIUM' | translate}}
                                  </div>
                                  <div class="settings-account-tariff__price">
                                      {{price}}
                                      <span *ngIf="showTextPerMonth">{{'SETTINGS.ACCOUNT.PREMIUM.PER_MONTH' | translate}}</span>
                                  </div>
                              </div>
                              <div>{{'SETTINGS.ACCOUNT.PREMIUM.TEXT' | translate}}</div>
                          `,
               styles: [require('./settings-account-tariff.scss')],
           })

export class SettingsAccountTariff {
    @Input() private subscription: PremiumSubscription;

    private get price(): string {
        return formatAmount(this.subscription.price);
    }

    private get showTextPerMonth(): boolean
    {
        return 1 === this.subscription.period.amount && this.subscription.period.unit === DurationUnit.Month;
    }
}
