import {
    Component,
    Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
               selector: 'chat-date',
               template: `
        <div class="chat-date">
            <div class="chat-date__content">
                {{date}}
            </div>
        </div>`,
               styles: [require('./chat-date.scss')],
           })

export class ChatDate {
    @Input() private sendtime: number;

    private get date(): string {
        const msgDate = moment(this.sendtime * 1000);
        const curDate = moment();

        if (msgDate.isSame(curDate, 'day')) {
            return this._translate.instant('CALENDER.TODAY');
        }
        if (msgDate.isSame(curDate.subtract(1, 'days'), 'day')) {
            return this._translate.instant('CALENDER.YESTERDAY');
        }
        if (msgDate.isSameOrAfter(curDate.subtract(2, 'days')) && msgDate.isSameOrAfter(curDate.subtract(7, 'days'))) {
            return msgDate.format('dddd');
        }
        return msgDate.format('DD.MM.YYYY');
    }

    public constructor(private _translate: TranslateService) {
    }
}
