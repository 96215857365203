import { HttpResponse } from '@angular/common/http';
import { BkConfig } from 'BKConfig';

export interface Time {
    response: Date;
    send: Date;
}

export class LogEntry {
    public clicked: boolean = false;

    public readonly debugToken: string = '';

    public readonly debugTokenLink: string = '';

    public readonly error: boolean = false;

    public readonly id: number = 0;

    public time: Time = {
        send:     new Date(),
        response: new Date(),
    };

    public readonly url: string = '';

    public constructor(
        event: HttpResponse<any>,
        id: number,
        time: Time,
        error: boolean = false,
    ) {
        this.id = id;
        this.url = event.url;
        this.debugToken = event.headers.get('X-Debug-Token') ?? '';
        this.debugTokenLink = event.headers.get('X-Debug-Token-Link')
                                   ?.replace(/.*\/\/.*\/app_dev\.php/, BkConfig.apiPath)
                                   ?? '';
        this.time = time;

        if (error) {
            this.error = true;
        } else if (event.body) {
            this.error = event.body.error;
        }
    }

    public getResponseTime(): string {
        return this.getTime(this.time.response);
    }

    public getSendTime(): string {
        return this.getTime(this.time.send);
    }

    public urlTrim(): string {
        return this.url
                   .replace(`${BkConfig.apiPath}/`, '')
                   .replace(/\?.*/g, '');
    }

    private formate(num: number): string {
        return `0${num}`.slice(-2);
    }

    private getTime(time: Date): string {
        return `${time.getHours()}:${this.formate(time.getMinutes())}:${this.formate(time.getSeconds())}`;
    }
}
