import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UserBaseView } from 'BKModels';

@Component({
               selector:  'cancel-user-list',
               template:  `
                              <cancel-list-header [titleText]="titleText" (action)="action.emit($event)">
                                  <div class="cancel-user-list__container">
                                      <img *ngFor="let user of userList"
                                           class="cancel-user-list__entry"
                                           [src]="user.userImage.getUrlNextAvailableSize(80, 80)"
                                           (click)="action.emit($event)"
                                      />
                                      <a (click)="action.emit($event)"
                                         class="cancel-user-list__entry"
                                      >{{ 'PREMIUM.CANCEL.USERLIST.MORE'|translate }}</a>
                                  </div>
                                  <raised-button (click)="action.emit($event)" class="cancel-user-list__action">
                                      {{ buttonText }}
                                  </raised-button>
                              </cancel-list-header>
                          `,
               styles: [require('./cancel-user-list.scss')],
           })

export class CancelUserList {
    @Output() private action = new EventEmitter();
    @Input() private titleText: string;
    @Input() private buttonText: string;
    @Input() private userList: UserBaseView[];
}
