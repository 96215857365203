import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'premium-star',
               template:  `              
                          <span class="percent">{{discount}}%</span>
                          {{'PREMIUM_LANDINGPAGE.OFFER_BOX.SAVINGS' | translate}}
                          `,
               styles: [require('./premium-star.scss')],
           })

export class PremiumStar {
    @Input() private discount: number;
}
