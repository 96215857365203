export const dataUriToFile = (dataUri: string)  => {
    let byteString;
    const split = dataUri.split(',');
    if (split[0].indexOf('base64') >= 0) {
        byteString = atob(split[1]);
    } else {
        byteString = unescape(split[1]);
    }

    const mimeString = split[0]
        .split(':')[1]
        .split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return {
        content:  dataUriToBlob(dataUri),
        type:     mimeString,
        filename: `${makeid(6)}.jpg`
    };
};

const dataUriToBlob = (dataUri: string) => {
    let byteString;
    const split = dataUri.split(',');
    if (split[0].indexOf('base64') >= 0) {
        byteString = atob(split[1]);
    } else {
        byteString = unescape(split[1]);
    }

    const mimeString = split[0]
        .split(':')[1]
        .split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
};


const makeid = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
