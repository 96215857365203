import * as _debounce from 'lodash.debounce';

// for possibel options look here: https://lodash.com/docs/4.17.15#debounce
export function debounce(ms: number = 0, options: any = {}): any {
    return function debounceMethode(target, propertyKey, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        const map = new WeakMap();
        descriptor.value = function (...params) {
            let debounced = map.get(this);
            if (!debounced) {
                debounced = _debounce(originalMethod, ms, options).bind(this);
                map.set(this, debounced);
            }
            return debounced(...params)
        };
    }
}



