import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';
import { debounce } from '../../../utils/decorator';
import { TextField } from '../text-field/text-field';


export interface AutocompleteSelectItem {
    displayValue: string;
    value: string;
}


@Component({
               selector:  'autocomplete',
               template:  `
                              <div (clickOutside)="clickOutside()">
                                  <text-field #input
                                              [placeholder]="placeholder"
                                              [(value)]="textValue"
                                              (focus)="menuOpen = true; focus.emit($event)"
                                              (keydown)="keyChange = true"
                                              (keyup.arrowDown)="pressArrowDown()"
                                              (keyup.arrowUp)="pressArrowUp()"
                                              (keyup)="keyup($event)"
                                              (keyup.enter)="selectItem()"
                                              (keyup.escape)="menuOpen = false"
                                              [trailingIcon]="trailingIcon"
                                              [showPremiumIcon]="showPremiumIcon"
                                  ></text-field>
                                  <div class="mdc-menu-surface--anchor">
                                      <div class="mdc-menu mdc-menu-surface autocomplete__menu"
                                           [class.mdc-menu-surface--open]="menuOpen"
                                           #menu
                                      >
                                          <ul #list class="mdc-list autocomplete__list"
                                              (mousemove)="this.mouseMoved = true"
                                          >
                                              <dropdown-option *ngFor="let option of options; index as index"
                                                               no-hover
                                                               [value]="option.value"
                                                               (click)="selectItem()"
                                                               [class.autocomplete__list__item--select]="selectedItem == index"
                                                               [id]="index"
                                                               (mousemove)="mouseOver(index)"
                                              >
                                                  {{option.displayValue}}
                                              </dropdown-option>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./autocomplete.scss')],
               providers: [
                   getValueAccessorProvider(TextField),
               ],
           })
export class Autocomplete extends ValueAccessorBase<any> implements AfterViewInit {
    @Output() private completeMethod = new EventEmitter();
    @Output() private focus = new EventEmitter();
    @ViewChild('input', { static: false }) private inputRef: TextField;
    private keyChange = false;
    @ViewChild('list', { static: true }) private listRef: ElementRef<HTMLElement>;
    @ViewChild('menu', { static: true }) private menuRef: ElementRef<HTMLElement>;
    private mouseMoved: boolean = false;
    @Input() private name: string = uuidv4();
    @Input() private options: AutocompleteSelectItem[];
    @Input() private placeholder: string = '';
    @Input() private trailingIcon: string = '';
    @Input() private showPremiumIcon: boolean = false;
    private selectedItem = 0;

    private _menuOpen: boolean = false;

    public get displayValue(): string {
        return this.inputRef.value as string;
    }

    public set displayValue(value: string) {
        this.inputRef.value = value;
    }

    private get menuOpen(): boolean {
        return this._menuOpen;
    }

    private set menuOpen(val: boolean) {
        setTimeout(() => this._menuOpen = val);
    }

    private get heightOfSelectedItem(): number {
        const ele = document.getElementById(this.selectedItem.toString());
        return ele ? ele.childNodes.first().offsetHeight : 0;
    }

    private get textValue(): string {
        if (this.value && this.value.hasOwnProperty('displayValue')) return this.value.displayValue;
        return this.value;
    }

    private set textValue(val) {
        if (this.keyChange) this.value = val;
    }


    public ngAfterViewInit(): void {
        this.layout();
    }

    @debounce(250)
    private complete() {
        const res = this.value.value ? this.value.value : this.value;
        this.keyChange = false;
        this.completeMethod.emit(res)
    }

    private keyup(event) {
        this.keyChange = false;
        if (
            event.key !== 'ArrowLeft' &&
            event.key !== 'ArrowRight' &&
            event.key !== 'ArrowUp' &&
            event.key !== 'ArrowDown' &&
            event.key !== 'Enter'
        ) {
            this.complete();
        }
    }

    private mouseOver(index) {
        if (this.mouseMoved) this.selectedItem = index;
    }

    private pressArrowDown() {
        this.mouseMoved = false;
        this.selectedItem = (this.selectedItem + 1) % this.options.length;
        this.scroll();
        return false;
    }

    private pressArrowUp() {
        this.mouseMoved = false;
        this.selectedItem = (this.selectedItem - 1);
        if (this.selectedItem < 0) this.selectedItem = this.options.length - 1;
        this.scroll();
        return false;
    }

    private updateSelectedValue() {
        this.value = this.options[this.selectedItem];
    }

    private scroll() {
        const itemHeight = this.heightOfSelectedItem;
        const topOfSelectedItem = this.selectedItem * itemHeight;
        const menuEle = this.menuRef.nativeElement;

        if (topOfSelectedItem >= menuEle.offsetHeight) {
            menuEle.scrollTop = topOfSelectedItem - menuEle.offsetHeight + itemHeight;
        } else if (topOfSelectedItem < menuEle.scrollTop) {
            menuEle.scrollTop = 0;
        }
    }

    private selectItem() {
        if (!this.options.length) {
            return;
        }

        this.updateSelectedValue();
        this.menuOpen = false;
    }

    public layout(): Promise<any> {
        return this.inputRef.layout()
                   .then(() => {
                       this.listRef.nativeElement.style.width = `${this.inputRef.width}px`;
                       return null;
                   });
    }


    private clickOutside() {
        this.menuOpen = false;
    }

}
