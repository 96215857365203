import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    ImageSize,
    ProfileInfo,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { ModalDialog } from '../../../service/global-modal.service';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../service/navigation.service';
import { DialogBox } from '../dialog/dialog-box';
import { Modal } from '../modal/modal';

@Component({
               selector:  'bk-modal-match',
               template:  `
                              <modal #modal>
                                  <div class="modal-content">
                                      <div class="images">
                                          <img-with-loading-indicator class="image left" [src]="leftImage"></img-with-loading-indicator>
                                          <img-with-loading-indicator class="image right" [src]="rightImage"></img-with-loading-indicator>
                                      </div>
                                      <div class="title markdown p-nomargin" [innerHTML]="'MODAL.MATCH.TITLE'|translate|markdown"></div>
                                      <div class="text markdown p-nomargin" [innerHTML]="'MODAL.MATCH.TEXT'|translate:translationVars|markdown"></div>
                                      <div class="actions">
                                          <raised-button (click)="writeMessage()">{{ 'MODAL.MATCH.ACTION.MESSAGE'|translate }}</raised-button>
                                          <flat-button (click)="close()">{{ 'MODAL.MATCH.ACTION.CLOSE'|translate }}</flat-button>
                                      </div>
                                  </div>
                              </modal>
                          `,
               styles: [require('./bk-modal-match.scss')],
           })
export class BkModalMatch implements ModalDialog {
    @ViewChild('modal', { 'static': true }) private modal: Modal;

    private user: ProfileInfo;
    private currentUser: CurrentUser;

    get translationVars() {
        return {
            username: this.user?.nickname ?? '',
        };
    }

    static readonly typeId = 'BkModalMatch';

    open(config) {
        if (!config?.user) throw 'Missing user config for BkModalMatch';

        this.currentUserService.currentUser
            .then((user) => {
                this.currentUser = user;
            });

        this.user = config?.user;

        this.modal.open();
    }

    close() {
        this.trackingService.hit('Matching', 'MatchErfolgreich', 'Schließen');

        this.modal.close();
    }

    writeMessage() {
        this.trackingService.hit('Matching', 'MatchErfolgreich', 'NachrichtSchreiben');

        const conversationId = `${this.currentUser.id}-${this.user.id}`;
        this.navigationService.navigateTo(Routes.MessagesConversation, {
            urlParam: { [RouteParameter.ConversationId]: conversationId },
        });

        this.modal.close();
    }

    get typeId(): string {
        return BkModalMatch.typeId;
    }

    get leftImage(): string {
        if (!this.currentUser) return "";

        return this.currentUser.getProfilePictureUrl(ImageSize['285x285']);
    }

    get rightImage(): string {
        if (!this.user) return "";

        return this.user.getProfilePictureUrl(ImageSize['285x285']);
    }

    constructor(
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private navigationService: NavigationService,
    ) {
    }
}
