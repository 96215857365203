import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'premium-column-badge',
               template: `                         
                                 <div class="premium-column-badge"
                                      [class.premium-column-badge--active]="active">
                                     <icon [class.premium-column-badge__icon--premium]="premiumVariant" icon="crown-outline"></icon>
                                     <span class="premium-column-badge__text">{{ textKey|translate }}</span>
                                     <div class="arrow"></div>
                                 </div>
`,
               styles: [require('./premium-column-badge.scss')],
           })

export class PremiumColumnBadge {
    @Input('active') private active: boolean= false;

    @Input('premiumVariant') private premiumVariant: boolean = false;

    private get textKey(): String {
        return this.premiumVariant ? 'PREMIUM.COLOUM_BADGE.PREMIUM' : 'PREMIUM.COLOUM_BADGE.BASIC';
    }
}
