import {
    AfterViewInit,
    Component,
    ViewChild,
} from '@angular/core';
import {
    ApiService,
    CancelType,
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { EmailValidator } from 'BKShared/Validator';
import { SessionService } from '../../service/session.service';
import { Dropdown } from '../../shared/mat-design/dropdown/dropdown';

@Component({
               selector:  'cancel-contracts',
               template:  `
                              <div class="page">
                                  <single-page-header trackingCategoryName="VerträgeKündigen" trackingPageName="VerträgeKündigen" [canLeave]="false" class="header"></single-page-header>
                                  <loading-spinner *ngIf="loading"></loading-spinner>
                                  <div class="page-content" *ngIf="!loading">
                                      <card class="card">
                                          <div class="card-content">
                                              <ng-container *ngIf="!sent">
                                                  <div class="title">{{ 'CANCEL_CONTRACTS.TITLE'|translate }}</div>
                                                  <div class="text markdown first-last-nomargin" [innerHtml]="'CANCEL_CONTRACTS.TEXT'|translate|markdown"></div>
                                                  <div class="form">
                                                      <text-field
                                                              [(value)]="email"
                                                              [placeholder]="'CANCEL_CONTRACTS.INPUT.EMAIL'|translate"
                                                              [valid]="emailValid"
                                                              [errorText]="emailError|translate"
                                                              helperText=""
                                                      ></text-field>
                                                      <text-field [(value)]="password" type="password" [placeholder]="'CANCEL_CONTRACTS.INPUT.PASSWORD'|translate"></text-field>
                                                      <text-field [(value)]="id" [placeholder]="'CANCEL_CONTRACTS.INPUT.ID'|translate"></text-field>
                                                      <div>{{ 'CANCEL_CONTRACTS.BILLING_ADDRESS'|translate }}</div>
                                                      <text-field [(value)]="firstName" [placeholder]="'CANCEL_CONTRACTS.INPUT.FIRST_NAME'|translate"></text-field>
                                                      <text-field [(value)]="lastName" [placeholder]="'CANCEL_CONTRACTS.INPUT.LAST_NAME'|translate"></text-field>
                                                      <text-field [(value)]="street" [placeholder]="'CANCEL_CONTRACTS.INPUT.STREET'|translate"></text-field>
                                                      <text-field [(value)]="streetNumber" [placeholder]="'CANCEL_CONTRACTS.INPUT.STREET_NUMBER'|translate"></text-field>
                                                      <text-field [(value)]="postCode" [placeholder]="'CANCEL_CONTRACTS.INPUT.POST_CODE'|translate"></text-field>
                                                      <text-field [(value)]="city" [placeholder]="'CANCEL_CONTRACTS.INPUT.CITY'|translate"></text-field>
                                                      <div>{{ 'CANCEL_CONTRACTS.TERMINATION_DETAILS'|translate }}</div>
                                                      <text-field [(value)]="cancelDate" [placeholder]="'CANCEL_CONTRACTS.INPUT.CANCEL_DATE'|translate"></text-field>
                                                      <dropdown [(value)]="cancelType" [placeholder]="'CANCEL_CONTRACTS.INPUT.CANCEL_TYPE'|translate" #cancelTypeDropdown>
                                                          <dropdown-option [value]="CancelType.Normal"
                                                                           [selected]="cancelType === CancelType.Normal"
                                                          >{{'CANCEL_CONTRACTS.TYPE.NORMAL' | translate}}</dropdown-option>                        
                                                          <dropdown-option [value]="CancelType.WithoutNotice"
                                                                           [selected]="cancelType === CancelType.WithoutNotice"
                                                          >{{'CANCEL_CONTRACTS.TYPE.WITHOUT_NOTICE' | translate}}</dropdown-option>
                                                      </dropdown>
                                                      <div class="markdown first-last-nomargin info" [innerHtml]="'CANCEL_CONTRACTS.INFO.CANCEL_REASON'|translate|markdown"></div>
                                                      <text-area [(value)]="cancelReason" 
                                                                 [placeholder]="'CANCEL_CONTRACTS.INPUT.CANCEL_REASON'|translate" 
                                                                 rows="4" 
                                                                 [valid]="cancelReasonValid"
                                                      ></text-area>
                                                  </div>
                                                  <div class="button-bar actions">
                                                      <raised-button [disabled]="!inputsValid || submitting" (click)="terminateContracts()">
                                                          <loading-indicator *ngIf="submitting"></loading-indicator>
                                                          {{ 'CANCEL_CONTRACTS.TERMINATE_NOW'|translate }}
                                                      </raised-button>
                                                  </div>
                                              </ng-container>
                                              <ng-container *ngIf="sent">
                                                  <div class="title">{{ 'CANCEL_CONTRACTS.SENT.TITLE'|translate }}</div>
                                                  <div class="text markdown first-last-nomargin" [innerHtml]="'CANCEL_CONTRACTS.SENT.TEXT'|translate|markdown"></div>
                                              </ng-container>
                                          </div>
                                      </card>
                                  </div>
                                  <bk-footer-out class="footer"></bk-footer-out>
                              </div>
                          `,
               styles: [require('./cancel-contracts.scss')],
           })

export class CancelContracts implements AfterViewInit {
    @ViewChild('cancelTypeDropdown', {static: false}) cancelTypeDropdown: Dropdown;

    CancelType = CancelType;

    loading = false;
    submitting = false;
    sent = false;

    email = '';
    id = '';
    password = '';
    firstName = '';
    lastName = '';
    street = '';
    streetNumber = '';
    postCode = '';
    city = '';
    cancelDate = '';
    cancelType = CancelType.Undefined;
    cancelReason = '';

    get emailError(): string {
        if (this.email.isEmpty()) return null;

        const error = EmailValidator.emailString(this.email);

        if (error) {
            const keys = Object.keys(error);
            for (let k of keys) {
                if (error[k]) return k;
            }
        }

        return null;
    }

    get emailValid(): boolean {
        return !this.emailError;
    }

    get cancelTypeValid(): boolean {
        return this.cancelType !== CancelType.Undefined;
    }

    get cancelReasonValid(): boolean {
        return !(this.cancelType === CancelType.WithoutNotice && this.cancelReason.isEmpty());
    }

    get inputsValid(): boolean {
        return (!this.email.isEmpty() && this.emailValid) &&
            this.cancelTypeValid &&
            this.cancelReasonValid;
    }

    terminateContracts() {
        this.submitting = true;
        this.apiService.terminateContracts(
                this.email,
                this.password,
                this.id,
                this.firstName,
                this.lastName,
                this.street,
                this.streetNumber,
                this.postCode,
                this.city,
                this.cancelDate,
                this.cancelType,
                this.cancelReason,
            )
            .then(() => {
                this.sent = true;
            })
            .finally(() => {
                this.submitting = false;
            });
    }


    public ngAfterViewInit(): void {
        if (this.sessionService.isLoggedIn()) {
            this.loading = true;
            this.currentUserService.currentUser
                .then(currentUser => {
                    this.id = currentUser.id.toString();
                })
                .finally(() => {
                    this.loading = false;
                });

            const profileSubscription = this.currentUserService.currentUserProfileInfo
                                            .subscribe(profile => {
                                                this.email = profile.email;
                                                setTimeout(() => profileSubscription.unsubscribe(), 0);
                                            });
        } else {
            // TODO Hack for correctly initializing the cancel type dropdown which would otherwise be empty and show no placeholder text.
            setTimeout(() => this.cancelTypeDropdown.layout(), 5000);
        }
    }

    public constructor(
        private trackingService: TrackingService,
        private sessionService: SessionService,
        private currentUserService: CurrentUserService,
        private apiService: ApiService,
    ) {
    }
}
