import {
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    DomSanitizer,
    SafeStyle,
} from '@angular/platform-browser';

@Component({
               selector:  'offer-banner',
               template:  `
                              <div class="banner" [class.banner--small]="small">
                                  <div class="banner__title">
                                      <div class="banner__title__content">
                                          <div class="banner__title__content__title">{{title}}</div>
                                          <div class="banner__title__content__discount"> {{subtitle}}</div>
                                      </div>
                                      <div #arrowDown class="arrow-bottom" [style]="arrowDownStyle"></div>
                                      <div #arrowRight class="arrow-right" [style]="arrowRightStyle"></div>
                                  </div>
                                  <div class="banner__content">

                                      <div class="banner__content__text">
                                          <ng-content></ng-content>
                                      </div>

                                      <div class="banner__content__button" *ngIf="!small">
                                          <raised-button premium (click)="purchaseOffer.emit()">Jetzt angebot sichern</raised-button>
                                      </div>
                                  </div>

                                  <img class="banner__img" src="./assets/img/banner/abstract-banner.svg">
                              </div>
                          `,
               styles: [require('./offer-banner.scss')],
           })

export class OfferBanner {
    @ViewChild('arrowDown', {static: true}) private arrowDown;
    @ViewChild('arrowRight', {static: true}) private arrowRight;
    @Input() title: string = '';
    @Input() subtitle: string = '';
    @Input() small: boolean = false;
    @Output() purchaseOffer = new EventEmitter();

    public constructor(private sanitizer: DomSanitizer) {
    }

    get arrowDownStyle(): SafeStyle {
        let style = '';
        if(!!this.arrowDown) {
            const width = this.arrowDown.nativeElement.parentElement.clientWidth;
            style = `border-right-width: ${width / 2}px;` +
                `border-left-width: ${width / 2}px;` +
                `border-top-width: ${width / 20}px;`;
        }
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }


    get arrowRightStyle(): SafeStyle {
        let style = '';
        if(!!this.arrowRight) {
            const height = this.arrowRight.nativeElement.parentElement.clientHeight;
            style = `border-top-width: ${height / 2}px;` +
                    `border-bottom-width: ${height / 2}px;` +
                    `border-left-width: ${height / 4}px;`;
        }
        return this.sanitizer.bypassSecurityTrustStyle(style);
    }
}