/**
 * Created by olifra on 22.06.2017.
 */
import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { Gender } from 'BKModels';

type ValidatorResult = ValidationErrors | null;
export const isEmpty = (value: any): boolean => value === null || value.length === 0;

export const required = (control: AbstractControl): ValidatorResult => (
    isEmpty(control.value) ? { 'ERROR.VALIDATOR.REQUIRED': true } : null
);

export const requiredIf = flagFunc => (control: AbstractControl): ValidatorResult => {
    if (flagFunc()) {
        return isEmpty(control.value) ? { 'ERROR.VALIDATOR.REQUIRED': true } : null;
    }
    return null;
};


export const minLength = (_minLength: number): ValidatorFn => (control: AbstractControl): ValidatorResult => {
    const length: number = control.value ? control.value.length : 0;
    if (!isEmpty(control.value) && length < _minLength) {
        return {
            'ERROR.VALIDATOR.MIN_LENGTH': {
                requiredLength: _minLength,
                actualLength:   length,
            },
        };
    }
    return null;
};

export const maxLength = (_maxLength: number): ValidatorFn => (control: AbstractControl): ValidatorResult => {
    const length: number = control.value ? control.value.length : 0;
    return length > _maxLength
        ? {
            'ERROR.VALIDATOR.MAX_LENGTH': {
                requiredLength: _maxLength,
                actualLength:   length,
            },
        }
        : null;
};

export function genderValidator(control: AbstractControl): ValidatorResult {
    const gender = [Gender.Male, Gender.Female];
    const { value } = control;
    return gender.includes(value) ? null : { 'ERROR.VALIDATOR.GENDER': true };
}
