import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GrowlCommunication } from './growl.communication';

@Injectable({providedIn: 'root'})
export class CommunicationService {
    public readonly growl: GrowlCommunication = new GrowlCommunication(this.translateService);

    constructor(private translateService: TranslateService) {}

}
