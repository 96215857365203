import { ApiResponseFrame } from 'BKModels';
import { Offer } from '../../../models/offer';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export class OfferApi {
    /**
     * constructor
     *
     {ApiRequestService} apiRequestService
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public offers(): Promise<Offer[]> {
        return this.apiRequestService
            .createGet(Endpoints.payment.offers)
            .then(res => res['data']['offers'].map(it => Offer.create(it)));
    }

    public triggerCanceledPurchaseOffer(): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService.createPost(Endpoints.trigger.canceledPurchase);
    }

}
