import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import { growShrink } from 'BKUtils';
import { MatchingRouteFragment } from '../../../../../service/navigation.service';

/**
 * Erklärung zum Matching und wie es functioniert
 */
@Component({
               selector:   'matching-explain',
               template:   `
                               <div class="bk-matching-explain">
                                   <div class="bk-matching-explain__title" (click)="toggleCard()">
                                       {{'MATCHING.EXPLAIN.TITLE' | translate}}
                                       <icon *ngIf="!open" class="bk-matching-explain__title__icon" icon="arrow-down"></icon>
                                       <icon *ngIf="open" class="bk-matching-explain__title__icon" icon="arrow-up"></icon>
                                   </div>

                                   <div *ngIf="open" [@growShrink]="false" class="bk-matching-explain__card">
                                       <div class="bk-matching-explain__card__title"> Der Weg zu deinem Match:</div>
                                       <matching-explain-item [title]="'MATCHING.EXPLAIN.0.TITLE' | translate"
                                                              img="./assets/img/matching/matching_explain_step1.png"
                                                              [text]="'MATCHING.EXPLAIN.0.TEXT' | translate"
                                       ></matching-explain-item>
                                       <matching-explain-item [title]="'MATCHING.EXPLAIN.1.TITLE' | translate"
                                                              img="./assets/img/matching/matching_explain_step2.png"
                                                              [text]="'MATCHING.EXPLAIN.1.TEXT' | translate"
                                       ></matching-explain-item>
                                       <matching-explain-item [title]="'MATCHING.EXPLAIN.2.TITLE' | translate"
                                                              img="./assets/img/matching/matching_explain_step3.png"
                                                              [text]="'MATCHING.EXPLAIN.2.TEXT' | translate"
                                       ></matching-explain-item>


                                       <raised-button (click)="startMatching()"
                                                      class="bk-matching-explain__card__button"
                                       >
                                           {{'MATCHING.EXPLAIN.START' | translate }}
                                       </raised-button>
                                   </div>
                               </div>
                               <div [id]="scrollId"></div>
                           `,
               styles:  [
                   require('./matching-explain.scss'),
               ],
               animations: [growShrink],
           })
export class MatchingExplain {
    private scrollId = MatchingRouteFragment.Explain

    /**
     * Open/Close Event
     {EventEmitter<any>}
     */
    @Output() private openChange = new EventEmitter();

    private _open: boolean;
    /**
     * gibt an ob die Erklärung offen ist
     {boolean}
     */
    @Input()
    private get open() {
        return this._open;
    }

    private set open(val: boolean) {
        this._open = val;
        this.openChange.emit(this._open);
        if (val) {
            this.scroll();
        }
    }

    public constructor(private trackingService: TrackingService) {
    }

    private scroll() {
        setTimeout(() => {
            document.getElementById(this.scrollId)
                    .scrollIntoView({
                                        behavior: 'smooth',
                                        block:    'start',
                                    });
        }, 550);
    }

    private toggleCard() {
        if (!this.open) this.trackingService.hit('Matching', 'MatchingSpielen', 'WasMatchingIst');
        this.open = !this.open;
    }

    private startMatching() {
        this.trackingService.hit('Matching', 'MatchingSpielen', 'JetztMatchingSpielen');
        this.open = false;
    }
}
