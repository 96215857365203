import {
    ApiResponseFrame,
    PushSettings,
    SettingsApiBody,
    SettingsApiBodyPush,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { Settings } from '../../../module/intern/settings/settings/settings';

import { ApiRequestService } from '../api-request.service';

export enum MailSettingsInterval {
    Never= 'NEVER',
    Always = 'ALWAYS',
    LimitedPerDay = 'RESTRICTED'
}

export enum MailSettingsType {
    WeeklyActivity = 'weeklyActivitySummary',
    UserSuggestion = 'userSuggestion',
    Match = 'match',
    NewOpenMatch = 'almostMatch',
    NewMessage = 'newMessage',
    ProfileVisitor = 'profileVisitor',
    NearbyUsers = 'nearbyUsers',
    Ads = 'ads',
    Offers = 'offers',
    Events = 'events',
    Magazine = 'magazine',
    Travel = 'travel'
}

export interface MailSetting {
    type: MailSettingsType;
    interval: MailSettingsInterval;
}

class SettingsPush {
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public get(): Promise<SettingsApiBodyPush> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.settings.push.get)
                   .then((res) => SettingsApiBodyPush.create(res.data));
    }

    public post(pushSettings: PushSettings): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.push.change, { body: pushSettings });
    }
}

export class SettingsApi {
    public push = new SettingsPush(this._apiRequestService);

    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public get(): Promise<SettingsApiBody> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.settings.get)
                   .then((res) => SettingsApiBody.create(res.data.settings));
    }

    public patch(settings: SettingsApiBody): Promise<Settings> {
        const body = {};
        for (let [key, value] of Object.entries(settings)) {
            body[`settings[${key}]`] = value;
        }

        return this._apiRequestService.createPost(Endpoints.settings.get, { body });
    }

    public allIntervals(): Promise<MailSetting[]> {
        return this._apiRequestService
            .createGet<ApiResponseFrame<MailSetting[]>>(Endpoints.settings.email.intervalAll)
            .then(result => result.data);
    }

    public interval(emailType: MailSettingsType, interval: MailSettingsInterval): Promise<boolean> {
        const body = { type: emailType, interval };
        return this._apiRequestService
            .createPost<ApiResponseFrame<any>>(Endpoints.settings.email.interval, { body })
            .then(result => result.data.state);
    }

    public multipleIntervals(intervals: MailSetting[]): Promise<boolean> {
        const body = { settings: intervals };

        return this._apiRequestService
            .createPost<ApiResponseFrame<any>>(Endpoints.settings.email.intervalMultiple, { body })
            .then(result => result.data.state);
    }
}
