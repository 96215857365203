import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';
import { SchlagerparadiesOverview } from './overview/schlagerparadies-overview';

const startpageRoutes: Routes = [
    {
        path:     '',
        children: [
            { path: '', component: SchlagerparadiesOverview },
            { path: '', component: InternDefaultHeader, outlet: 'header', data: { translateTitle: 'LAYOUT.SIDE_NAV.SCHLAGERPARADIES' } },
        ],
    },
];

export const SchlagerparadiesRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(startpageRoutes);
