import { HttpClient } from '@angular/common/http';

export class Article {
    public image: string;
    public link: string;
    public text: string;
    public title: string;
    public category: string;
    public rating: number;
    public readtime: string
}

export abstract class RssFeed {
    constructor(protected httpClient: HttpClient) {
    }

    abstract getEntrys(limt: number, tag?: string ): Promise<Article[]>;
}
