import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../outer-component';
import { VerifyEmailChangeComponent } from './verify-email-change.component';

@NgModule({
              exports:      [VerifyEmailChangeComponent],
              declarations: [VerifyEmailChangeComponent],
              providers:    [],
              imports: [
                  ShareModule,
                  OuterComponentModule
              ],
          })
export class VerifyEmailChangeModule {
}
