import { Injectable } from '@angular/core';
import {
    Conversation,
    ConversationFilter,
} from 'BKModels';
import { SpamReportReason } from '../models/api-enums';
import {
    Cache,
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class ConversationService {

    public constructor(private apiService: ApiService) {
    }


    public getAll(filter: ConversationFilter, limit: number, offset: number = 0): Promise<Conversation[]> {
        return this.apiService.conversation.getAll(limit, offset, filter);
    }

    public unreadWithoutSystem(limit: number, offset: number = 0): Promise<Conversation[]> {
        return this.apiService.conversation.getAll(limit, offset, ConversationFilter.UnreadWithoutSystem);
    }


    public delete(conversationIds: string[]): Promise<boolean> {
        return this.apiService.conversation.deleteMultiple(conversationIds);
    }

    public markAsUnreadMultiple(conversationIds: string[]): Promise<boolean> {
        return this.apiService.conversation.markAsUnreadMultiple(conversationIds);
    }


    public pinConversations(conversationIds: string[]): Promise<boolean> {
        return this.apiService.conversation.pinConversations(conversationIds).then(res => {
            Cache.getInstance().delete(CacheKey.AllConversation);
            return res;
        });
    }

    public unpinConversations(conversationIds: string[]): Promise<boolean> {
        return this.apiService.conversation.unpinConversations(conversationIds).then(res => {
            Cache.getInstance().delete(CacheKey.AllConversation);
            return res;
        });
    }

    public report(convId: string, reason: SpamReportReason, description: string = ''): Promise<boolean> {
        return this.apiService.conversation.report(convId, reason, description);
    }

}
