import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { FavoriteDelete } from '../favorite/tabs/favorite-delete';
import { FavoriteExist } from '../favorite/tabs/favorite-exist';
import { ProfileVisitorsMyVisits } from '../profile-visitors/profile-visitors-my-visits/profile-visitors-my-visits.component';
import { ProfileVisitorsOthers } from '../profile-visitors/profile-visitors-others/profile-visitors-others';
import { Contacts } from './contacts/contacts';
import { ContactsHeader } from './contacts-header/contacts-header';

const contactsRoutes: NgRoutes = [
    {
        path:     '',
        children: [
            { path:        '',
                component: Contacts,
                children:  [{
                    path:      routeName(Routes.ContactsVisitors),
                    component: ProfileVisitorsOthers,
                },{
                    path:      routeName(Routes.ContactsVisitorsMyVisits),
                    component: ProfileVisitorsMyVisits,
                },{
                    path:      routeName(Routes.ContactsFavorites),
                    component: FavoriteExist,
                },{
                    path:      routeName(Routes.ContactsFavoritesDeleted),
                    component: FavoriteDelete,
                }]
            },
            { path: '', component: ContactsHeader, outlet: 'header' },
        ],
    },
];

export const ContactsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(contactsRoutes);
