import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatchingDecision } from 'BKModels';

@Component({
               selector: 'profile-matching-decision',
               template: `
    <div class="profile-matching-decision"
         [class.profile-matching-decision--yes]="type == matchingDecision.Yes"
         [class.profile-matching-decision--no]="type == matchingDecision.No"
         [class.profile-matching-decision--maybe]="type == matchingDecision.Maybe"
         [class.disabled]="disabled"
         [class.toned-down]="tonedDown"
         (click)="decide()"
    >
      <div class="profile-matching-decision__icon">
          
        <ng-container [ngSwitch]="type">
          <icon *ngSwitchCase="matchingDecision.Yes"
                icon="match"
          ></icon>
          <icon *ngSwitchCase="matchingDecision.No"
                icon="close"
          ></icon>
          <icon *ngSwitchCase="matchingDecision.Maybe"
                icon="help"
          ></icon>
        </ng-container>


      </div>
      <div class="profile-matching-decision__text">
        {{text | translate}}
      </div>
    </div>`,
               styles: [require('./profile-matching-decision.scss')],
           })

export class ProfileMatchingDecision {
    @Output("decide") _decide = new EventEmitter();
    matchingDecision = MatchingDecision;
    @Input() disabled: boolean = false;
    @Input() tonedDown: boolean = false;
    @Input() type: MatchingDecision;

    get text() {
        switch (this.type) {
            case MatchingDecision.Maybe:
                return 'MATCHING.MAYBE';
            case MatchingDecision.No:
                return 'MATCHING.NO';
            case MatchingDecision.Yes:
                return 'MATCHING.YES';
            default:
                return '';
        }
    }

    decide() {
        if(!this.disabled) this._decide.emit(this.type);
    }
}
