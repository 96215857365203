import {
    AfterViewInit,
    Component,
    Input,
} from '@angular/core';
import {
    Message,
} from 'BKModels';
import { MessageService } from '../../../../../service/message.service';

@Component({
               selector: 'system-msg-html',
               template: `
        <system-msg-layout>
            <loading-spinner *ngIf="!messageValid"></loading-spinner>
            <div *ngIf="messageValid" [innerHTML]="messageHtml"></div>
        </system-msg-layout>
    `,
           })

export class SystemMsgHtml implements AfterViewInit {
    @Input() private message: Message;

    private messageHtml = '';

    private get messageValid(): boolean {
        return !!this.message && !!this.message.conversationId;
    }

    constructor(
        private messageService: MessageService
    ) {}

    public ngAfterViewInit(): void {
        this.messageService.get(this.message.conversationId)
            .then(value => {
                this.messageHtml = value.messages[0].text;
            });
    }

}
