import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import * as twemoji from 'twemoji'

@Pipe({ name: 'emoji' })
export class EmojiPipe implements PipeTransform {
    public transform(value: string): string {
        return this.parser(twemoji.default.parse(value));
    }

    private getImg(emoji: string): string {
        const num = emoji.match(/[0-9]+/g)[0];
        return ` <img class='emoji' alt=':t${emoji.trim()}:' src='/assets/bk-emojis/ic_bkemojis_${num}.png'>`;
    }

    private parser(val: string): string {
        return val.replace(/:t([1-9]?[0-9]):/g, (match, code) => { return this.getImg(code) })
                  .replace(/ +/g, ' ');
    }
}
