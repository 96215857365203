import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import {
    ConversationDisabled,
    MessageService,
} from '../../../../../service/message.service';


@Component({
               selector:  'chat-input',
               template:  `
                              <div class="chat-input" id="chat-input"
                                   [ngSwitch]="messagesService.conversationPossible(partner)"
                              >

                                  <chat-input-field *ngSwitchCase="conversationDisabled.No"
                                                    [disabled]="disabled"
                                                    [conversationId]="conversationId"
                                                    (change)="change.emit($event)"
                                                    (send)="send.emit($event)"
                                  ></chat-input-field>

                                  <chat-input-error-box *ngSwitchCase="conversationDisabled.Ignore"
                                                        [content]="'CHAT.INPUT.INGORE_YOU.TEXT' | translate:partner | markdown"
                                  >
                                      <flat-button (click)="removeIgnore.emit()">{{'CHAT.INPUT.INGORE_YOU.LINK' | translate:partner }}</flat-button>
                                  </chat-input-error-box>

                                  <chat-input-error-box *ngSwitchCase="conversationDisabled.IgnoresMe"
                                                        [content]="'CHAT.INPUT.INGORES_ME' | translate:partner | markdown"
                                  >
                                  </chat-input-error-box>
                                  
                                  <chat-input-error-box *ngSwitchDefault
                                    [content]="'CHAT.INPUT.ERROR' | translate:partner | markdown"
                                  ></chat-input-error-box>

                              </div>
                          `,
               styles: [require('./chat-input.scss')],

           })
export class ChatInput {
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @Input() private partner: ProfileInfo = new ProfileInfo();
    @Input() private disabled: boolean = false;
    @Input() private conversationId: string;
    @Output() private send: EventEmitter<string> = new EventEmitter();
    @Output() private change = new EventEmitter();

    private conversationDisabled = ConversationDisabled;

    @Output() private removeIgnore = new EventEmitter();

    public constructor(
        private messagesService: MessageService
    ) {}
}
