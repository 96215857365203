import { Component } from '@angular/core';

@Component({
               selector:  'settings-title',
               template:  `
                            <div class="settings-title">
                                <ng-content></ng-content>
                            </div>
                         `,
               styles: [require('./settings-title.scss')],
           })

export class SettingsTitle {
}
