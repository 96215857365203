import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import { TrackingService } from 'BKService';
import {
    PaymentType,
    Product,
} from '../../../../../models/product';
import { Tariff } from '../../../../../models/tariff';


@Component({
               selector:  'premium-booking-page-payment',
               template:  `
                              <div class="premium-booking-page-payment__list" *ngIf="!offerId">
                                  <payment-methode-tab class="premium-booking-page-payment__list__tab"
                                                       (click)="tabClick(PaymentType.Bank)"
                                                       [active]="currentPaymentType == PaymentType.Bank"
                                                       img="./assets/img/payment/badgeEc.png"
                                  >
                                      {{'PREMIUM.BOOKING.PAYMENT.BANK' | translate}}
                                  </payment-methode-tab>

                                  <payment-methode-tab class="premium-booking-page-payment__list__tab"
                                                       (click)="tabClick(PaymentType.Paypal)"
                                                       [active]="currentPaymentType == PaymentType.Paypal"
                                                       img="./assets/img/payment/badgePaypal.svg"
                                  >
                                  </payment-methode-tab>

                                  <payment-methode-tab class="premium-booking-page-payment__list__tab"
                                                       (click)="tabClick(PaymentType.Credit)"
                                                       [active]="currentPaymentType == PaymentType.Credit"
                                                       img="./assets/img/payment/badgeCredit.svg"
                                  >
                                      {{'PREMIUM.BOOKING.PAYMENT.CREDIT' | translate}}
                                  </payment-methode-tab>

                                  <payment-methode-tab class="premium-booking-page-payment__list__tab"
                                                       (click)="tabClick(PaymentType.Cash)"
                                                       [active]="currentPaymentType == PaymentType.Cash"
                                                       img="./assets/img/payment/badgeBar.png"
                                  >
                                      {{'PREMIUM.BOOKING.PAYMENT.CASH' | translate}}
                                  </payment-methode-tab>
                              </div>

                              <div class="premium-booking-page-payment__content" [ngSwitch]="currentPaymentType">
                                  <div *ngSwitchCase="PaymentType.Bank">
                                      <premium-booking-page-payment-debit [tariff]="tariff" [orderReason]="orderReason" [offerId]="offerId"></premium-booking-page-payment-debit>
                                  </div>
                                  <div *ngSwitchCase="PaymentType.Paypal">
                                      <premium-booking-page-payment-paypal [tariff]="tariff" [currentUser]="currentUser"></premium-booking-page-payment-paypal>
                                  </div>
                                  <div *ngSwitchCase="PaymentType.Credit">
                                      <premium-booking-page-payment-credit [tariff]="tariff" [currentUser]="currentUser"></premium-booking-page-payment-credit>
                                  </div>
                                  <div *ngSwitchCase="PaymentType.Cash">
                                      <premium-booking-page-payment-cash [tariff]="tariff" [currenUser]="currentUser"></premium-booking-page-payment-cash>
                                  </div>
                              </div>

                          `,
               styles: [require('./premium-booking-page-payment.scss')],
           })

export class PremiumBookingPagePayment {
    @Input() private tariff: Tariff;
    @Input() private currentUser = new CurrentUser();
    @Input('currentPaymentType') private _currentPaymentType: PaymentType;
    @Input() private orderReason: OrderReason = OrderReason.None;
    @Input() private offerId: string = null;
    @Output() private currentPaymentTypeChange = new EventEmitter();

    public constructor(private trackingService: TrackingService) {
    }


    private get currentPaymentType() {
        return this._currentPaymentType;
    }

    private set currentPaymentType(val) {
        this.currentPaymentTypeChange.emit(val);
        this._currentPaymentType = val;
    }

    private PaymentType = PaymentType;

    private trackingPaymentTypeMapping = {
        [PaymentType.Bank]:   'Bankeinzug',
        [PaymentType.Paypal]: 'PayPal',
        [PaymentType.Credit]: 'Kreditkarte',
        [PaymentType.Cash]:   'Barzahlung',
    };

    private tabClick(type: PaymentType) {
        this.trackingService.hit('Premium', 'ZahlungsartSeite', this.trackingPaymentTypeMapping[type]);

        this.currentPaymentType = type;
    }
}
