import { formatAmount } from 'BKUtils';
import {
    Duration,
    DurationUnit,
} from '../utils/duration';

export class Tariff {
    static readonly empty = new Tariff();

    public productId: string = '';
    public period: Duration = new Duration(0, DurationUnit.Day);
    public price: number = 0;
    public subscriptionPrice: number = 0;
    public subscriptionPeriod: Duration = new Duration(1, DurationUnit.Month);
    public tariffId: number = 0;

    public static create(apiObject: any): Tariff {
        const tariff = new Tariff();

        tariff.price = apiObject.price;
        tariff.period = Duration.parsePeriod(apiObject.period);
        tariff.subscriptionPrice = apiObject.subscriptionPrice;
        tariff.subscriptionPeriod = Duration.parsePeriod(apiObject.subscriptionPeriod);
        tariff.subscriptionPrice = apiObject.subscriptionPrice;
        tariff.tariffId = apiObject.tariffId;
        tariff.productId = apiObject.productId;

        return tariff;
    }

    public get pricePerDay() {
        return this.price / this.period.days;
    }

    public get pricePerMonth(): number {
        if (this.period.unit === DurationUnit.Month && this.period.amount === 1) return this.price;
        return Math.floor(this.price / this.period.days * 3000) / 100;
    }

    public get pricePerPeriod() : number {
        return this.period.days * this.pricePerDay;
    }

    public get formattedPricePerMonth() {
        if (this.isEmpty()) return this.emptyFormattedString;
        return formatAmount(this.pricePerMonth);
    }

    public get formattedPrice(): string {
        if (this.isEmpty()) return this.emptyFormattedString;
        return formatAmount(this.price);
    }

    public get formattedSubscriptionPrice(): string {
        if (this.isEmpty()) return this.emptyFormattedString;
        return formatAmount(this.subscriptionPrice);
    }

    public get formattedPeriodPrice(): string {
        if (this.isEmpty()) return this.emptyFormattedString;
        return formatAmount(this.pricePerPeriod);
    }

    public get nameTranslationKey(): string {
        if (this.period.unit === DurationUnit.Month) {
            if (this.period.amount === 1) return 'PREMIUM.TARIFF.NAME.ONE_MONTH';
            if (this.period.amount === 3) return 'PREMIUM.TARIFF.NAME.THREE_MONTHS';
            if (this.period.amount === 6) return 'PREMIUM.TARIFF.NAME.SIX_MONTHS';
        }
        return 'PREMIUM.TARIFF.NAME.DEFAULT';
    }

    public getDiscount(other: Tariff): number {
        if (this.isEmpty() || other.isEmpty()) return NaN;
        return (1 - (other.pricePerDay / this.pricePerDay)) * 100;
    }

    public isEmpty(): boolean {
        return this.tariffId === 0 || this.productId.isEmpty();
    }

    private get emptyFormattedString(): string {
        return formatAmount(0).replace(/0/g, '-');
    }
}
