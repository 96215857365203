import { Component } from '@angular/core';

@Component({
               selector:  'settings-account-delete-header',
               template:  `
                              <intern-default-header [title]="'ACCOUNT.DELETE.HEADER.TITLE' | translate" >
                              </intern-default-header>
                          `,
           })

export class SettingsAccountDeleteHeader {
    public constructor() {
    }
}
