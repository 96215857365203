import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { growShrink } from 'BKUtils';
import { GenericModal } from '../generic-modal/generic-modal';

@Component({
               selector:   'bk-modal-become-vip',
               template:   `
                               <ng-container>
                                   <generic-modal #genericModal
                                                  [title]="'MODAL.BECOME_VIP.TITLE'|translate"
                                                  [closeIcon]="true"
                                   >
                                       <div class="modal-container">
                                           <div [innerHTML]="'MODAL.BECOME_VIP.TEXT.1'|translate:{username: username}|markdown" class="markdown text"></div>
                                           <div [innerHTML]="'MODAL.BECOME_VIP.TEXT.2'|translate|markdown" class="markdown p-nomargin checkbox-list task-list"></div>
                                           <div [innerHTML]="'MODAL.BECOME_VIP.TEXT.3'|translate|markdown" class="markdown text"></div>
                                           <raised-button (click)="becomeVip()">{{ 'MODAL.BECOME_VIP.ACTION'|translate }}</raised-button>
                                       </div>
                                   </generic-modal>
                               </ng-container>
                           `,
               styles:  [require('./bk-modal-become-vip.scss')],
               animations: [growShrink],
           })
export class BkModalBecomeVip implements ModalDialog {
    @ViewChild(GenericModal, { static: true }) private genericModal: GenericModal;

    static readonly typeId = 'BkModalBecomeVip';

    username: string = '';

    public open(config) {
        this.username = config;
        this.genericModal.open();
    }

    public close() {
        this.genericModal.close();
    }

    public get typeId(): string {
        return BkModalBecomeVip.typeId;
    }

    becomeVip() {
        window.open('mailto:werdeVIP@bildkontakte.de');
    }
}
