import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    ErrorNumber,
    ProfileInfo,
} from 'BKModels';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';
import {
    ChatListEntryType,
    ChatListSentMessageEntry,
} from './chat/chat';

@Component({
               selector: 'chat-error-dialogs',
               template: `
                             <dialog-box #youIgnoreErrorDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.YOU_IGNORE.TITLE' | translate"
                                         [acceptText]="'CHAT.ERROR_DIALOG.YOU_IGNORE.ACCEPT_TEXT' | translate"
                                         (onAccept)="removeIgnore.emit()"
                                         [rejectText]="'CHAT.ERROR_DIALOG.YOU_IGNORE.REJECT_TEXT' | translate"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.YOU_IGNORE.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>
                             
                             <dialog-box #receivingDisabledErrorDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.RECEIVING_DISABLED.TITLE' | translate"
                                         [rejectText]="'CHAT.ERROR_DIALOG.RECEIVING_DISABLED.REJECT_TEXT' | translate"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.RECEIVING_DISABLED.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>
                             
                             <dialog-box #partnerLockedDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.PARTNER_LOCKED.TITLE' | translate"
                                         [rejectText]="'CHAT.ERROR_DIALOG.PARTNER_LOCKED.REJECT_TEXT' | translate"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.PARTNER_LOCKED.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>

                             <dialog-box #ignorErrorDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.IGNORE.TITLE' | translate"
                                         [rejectText]="'CHAT.ERROR_DIALOG.IGNORE.REJECT_TEXT' | translate"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.IGNORE.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>

                             <dialog-box #premNeedDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.PREM_NEED.TITLE' | translate"
                                         [rejectText]="'CHAT.ERROR_DIALOG.PREM_NEED.REJECT_TEXT' | translate"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.PREM_NEED.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>

                             <dialog-box #defaultDialog
                                         [type]="'info'"
                                         [title]="'CHAT.ERROR_DIALOG.DEFAULT.TITLE' | translate"
                                         [rejectText]="'CHAT.ERROR_DIALOG.DEFAULT.REJECT_TEXT' | translate"
                                         [acceptText]="'CHAT.ERROR_DIALOG.DEFAULT.ACCEPT_TEXT' | translate"
                                         (onAccept)="resend.emit()"
                             >
                                 <span [innerHTML]="'CHAT.ERROR_DIALOG.DEFAULT.CONTENT' | translate:curPartner | markdown"></span>
                             </dialog-box>
                         `,
           })
export class ChatErrorDialogs {
    @Input() private curPartner: ProfileInfo;
    @Input() private curUser: CurrentUser;
    @Output() private removeIgnore = new EventEmitter();
   @Output() private resend = new EventEmitter();

    @ViewChild('youIgnoreErrorDialog', { static: true }) private youIgnoreErrorDialog: DialogBox;
    @ViewChild('receivingDisabledErrorDialog', { static: true }) private receivingDisabledErrorDialog: DialogBox;
    @ViewChild('partnerLockedDialog', { static: true }) private partnerLockedDialog: DialogBox;
    @ViewChild('ignorErrorDialog', { static: true }) private ignorErrorDialog: DialogBox;
    @ViewChild('premNeedDialog', { static: true }) private premNeedDialog: DialogBox;
    @ViewChild('defaultDialog', {static : true}) private defaultDialog: DialogBox;


    public open(entry) {
        if(entry.type === ChatListEntryType.SentMessage){
            const sentMessage =  entry as ChatListSentMessageEntry;

            if (sentMessage.error) {
                switch (sentMessage.errorNumber) {
                    case ErrorNumber.IGNORE_YOU_IGNORE_USER:
                        this.youIgnoreErrorDialog.open();
                        break;
                    case ErrorNumber.MESSAGE_RECEIVING_DISABLED:
                        this.receivingDisabledErrorDialog.open();
                        break;
                    case ErrorNumber.GENERAL_USER_LOCKED:
                        this.partnerLockedDialog.open();
                        break;
                    case ErrorNumber.IGNORE_USER_IGNORES_YOU:
                        this.ignorErrorDialog.open();
                        break;
                    case ErrorNumber.GENERAL_PREM_NEED:
                        this.premNeedDialog.open();
                        break;
                    default:
                        this.defaultDialog.open();
                }
            }
        }
    }

}
