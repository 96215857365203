/**
 * Created by olifra on 10.07.2017.
 */


import { Component } from '@angular/core';

/**
 * Loading Spinner Komponente
 */
@Component({
               selector:  'loading-spinner',
               template:  `
                   <div class="loading-spinner">
                       <img class="loading-spinner__img" src="./assets/img/bk-face.svg">
                       <img class="loading-spinner__img" src="./assets/img/bk-face.svg">
                       <img class="loading-spinner__img" src="./assets/img/bk-face.svg">
                   </div>`,
               styles: [require('./spinner.scss')],
           })
export class Spinner {
}
