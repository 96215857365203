import {
    Component,
    Input,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import {
    Offer,
    OfferType,
} from '../../../models/offer';
import { Tariff } from '../../../models/tariff';
import { NavigationService } from '../../../service/navigation.service';
import { PaymentService } from '../../../service/payment.service';

@Component({
               selector:  'banner-selector',
               template: `
                             <div *ngIf="!!offerTariff && !!expensivePremiumProduct" [ngSwitch]="offer.offerType">
                                 <offer-banner *ngSwitchCase="OfferType.LegacyWelcomeOffer"
                                               [title]="'OFFER.WELLCOME_OFFER.TITLE' | translate"
                                               [subtitle]="'OFFER.WELLCOME_OFFER.SUBTITLE' | translate:{'discount': discount}"
                                               [small]="small"
                                               (purchaseOffer)="purchaseOffer()"
                                 >
                                     <span [innerHTML]="'OFFER.WELLCOME_OFFER.OFFER' | translate:{'newPrice':newPrice,'oldPriceMonth': oldPricePerMonth} "></span>
                                     <div class="basic-month-price">{{'OFFER_BANNER.BASIC_MONTH_PRICE' | translate}}</div>
                                 </offer-banner>
                                 <offer-banner *ngSwitchCase="OfferType.LegacyWelcomeDelayedOffer"
                                               [title]="'OFFER.WELLCOME_OFFER.TITLE' | translate"
                                               [subtitle]="'OFFER.WELLCOME_OFFER.SUBTITLE' | translate:{'discount': discount}"
                                               [small]="small"
                                               (purchaseOffer)="purchaseOffer()"
                                 >
                                     <span [innerHTML]="'OFFER.WELLCOME_OFFER.OFFER' | translate:{'newPrice':newPrice,'oldPriceMonth': oldPricePerMonth} "></span>
                                     <div class="basic-month-price">{{'OFFER_BANNER.BASIC_MONTH_PRICE' | translate}}</div>
                                 </offer-banner>
                                 <birthday-offer-banner *ngSwitchCase="OfferType.BirthdayOffer" 
                                                        [offerTariff]="offerTariff"
                                                        [small]="small"
                                                        [discount]="discount"
                                                        (purchaseOffer)="purchaseOffer()">                                     
                                 </birthday-offer-banner>
                                 <loyalty-offer-banner *ngSwitchCase="OfferType.LoyaltyOffer"
                                                        [offerTariff]="offerTariff"
                                                        [small]="small"
                                                        [discount]="discount"
                                                        (purchaseOffer)="purchaseOffer()">
                                 </loyalty-offer-banner>
                                 <recurring-offer-banner *ngSwitchCase="OfferType.RecurringOffer" 
                                                         [small]="small"
                                                         [offer]="offer"
                                                         (purchaseOffer)="purchaseOffer()"
                                 ></recurring-offer-banner>
                             </div>
                         `,
               styles: [require('./banner-selector.scss')],
           })

export class BannerSelector {
    @Input() offer: Offer;
    @Input() small: boolean = false;
    OfferType = OfferType;
    offerTariff: Tariff;
    expensivePremiumProduct: Tariff;

    private trackingData: { [key in OfferType]: { category: string, label: string } } = {
        [OfferType.LegacyWelcomeOffer]: { category: 'WillkommensangebotBannerStartseite', label: 'WillkoLogikAlt' },
        [OfferType.LegacyWelcomeDelayedOffer]: { category: 'WillkommensangebotBannerStartseite', label: 'WillkoLogik10TageNach' },
        [OfferType.RecurringOffer]: {category: 'WiederkehrendesAngebotStartseite', label: 'WiederkehrendesAngebot'},
        [OfferType.BirthdayOffer]: {category: 'GeburtstagStartseite', label: 'GeburtstagsAngebot'},
        [OfferType.LoyaltyOffer]: {category: 'TreueAngebotBannerStartseite', label: 'TreueAngebot'},
    }

    public constructor(private navigationService: NavigationService,
                       private paymentService: PaymentService,
                       private trackingService: TrackingService,
                       ) {
    }

    ngOnInit() {
        //TODO Wie bekomme ich raus welches anbegot angezeigt werden soll
        this.paymentService.tariff(this.offer.tariffId).then(res => this.offerTariff = res);
        this.paymentService.mostExpensivePremiumProduct.then(res => this.expensivePremiumProduct = res);
    }

    get discount(): string {
        return Math.floor(this.expensivePremiumProduct.getDiscount(this.offerTariff)).toString();
    }

    get newPrice(): string {
        return this.offerTariff.formattedPeriodPrice;
    }

    get oldPricePerMonth(): string {
        return this.expensivePremiumProduct.formattedPricePerMonth;
    }

    purchaseOffer() {
        this.trackingService.hit(this.trackingData[this.offer.offerType].category,'AngebotSichernGeklickt', this.trackingData[this.offer.offerType].label);

        this.navigationService.navigateToPurchasePage(this.offer.tariffId, this.offer.orderReason, this.offer.id);
    }
}
