/**
 * Created by olifra on 03.07.2017.
 */


import {
    ProfileInfo,
    UserBaseView,
} from './user';

type State = 'read' | 'unread' | 'transmitted';
type Unseen = 'seen' | 'unseen';

export interface MessageInterface {
    conversationId: string;

    deleted: boolean;

    deletedAt: number;

    extraInformation: any;

    firstUnread: boolean;

    id: number;

    lastDayMsg: boolean;

    lastSenderMsg: boolean;

    newDate: boolean;

    read: boolean;

    receiverId: number;

    refNumber: number;

    senderId: number;

    sendtime: number;

    sendtimeMilliseconds: number;

    text: string;

    triggeringUser: number;

    type: string;

    isSender(): boolean;

    readonly fullSendtime: number;

    readonly timeId: string;

    readonly fullConversationId: string
}

/**
 * Nachricht
 */
export class Message implements MessageInterface{
    /**
     * ConversationId
     * @example 111111-222222
     {string}
     */
    public conversationId: string;

    /**
     * True wenn die Nachricht gelöscht worden ist
     {boolean}
     */
    public deleted: boolean;

    /**
     * Wenn deleted true ist, dann steht hier das Datum
     *
     {date}
     */
    public deletedAt: number;

    public extraInformation: any;

    public firstUnread: boolean = false;

    /**
     * Id der Nachricht
     {number}
     */
    public id: number;

    public lastDayMsg: boolean = false;

    public lastSenderMsg: boolean = false;

    public newDate: boolean = false;

    /**
     * True wenn die Nachricht schon mal dem eingeloggten user angezeigt wurde
     {boolean}
     */
    public read: boolean = true;

    /**
     * Id des Empfängers der Nachricht
     {number}
     */
    public receiverId: number;

    /**
     * Bei Systemnachrichten ist hier eine eindeutige Nummer hinterlegt, ansonsten null
     {number}
     */
    public refNumber: number;

    /**
     * Id des Senders der Nachricht
     {number}
     */
    public senderId: number;

    public sendtime: number;

    public sendtimeMilliseconds: number;

    public text: string;

    /**
     * Id des Benutzers der die Nachricht ausgelöst hat.
     *
     * Es gibt meldungen die vom System kommen aber einen expliziten Bezug haben.
     * Zum Beispiel das annehmen einer freundschaft anfrage.
     *
     {number}
     */
    public triggeringUser: number;

    /**
     * Gibt den Typ der Nachricht an.
     *
     * - normal
     * - system
     *
     {string}
     */
    public type: string;

    public static create(data: any): Message {
        const res = new Message();

        res.id = data.id;
        res.conversationId = data.conversationId || data._conversationId;
        res.senderId = data.senderId;
        res.receiverId = data.receiverId;
        res.text = data.text;
        res.sendtime = data.sendtime;
        res.sendtimeMilliseconds = data.sendtimeMilliseconds || data.sendtimeMillisecnds;
        res.refNumber = data.refNumber;
        res.triggeringUser = data.triggeringUser;
        res.read = data.read;
        res.deleted = data.deleted;
        res.deletedAt = data.deletedAt;
        res.type = data.type;
        res.extraInformation = data.extraInformation;
        return res;
    }

    public static createDirty(id: number, conversationId: string, text: string): Message {
        const res = new Message();
        res.id = id;
        res.conversationId = conversationId;
        const tmp = conversationId.split('-');
        res.senderId = parseInt(tmp[0]);
        res.receiverId = parseInt(tmp[1]);
        res.text = text;
        const now = Date.now();
        res.sendtime = Math.floor(now / 1000);
        res.sendtimeMilliseconds = now % 1000;
        return res;
    }

    public static createMultiple(data: any[] = []): Message[] {
        return data.map(cur => Message.create(cur));
    }

    public isSender(): boolean {
        return this.conversationId.split('-')[0] === `${this.senderId}`;
    }

    public get fullSendtime(): number {
        return this.sendtime * 1000 + this.sendtimeMilliseconds;
    }

    public get timeId(): string {
        return `${this.id}-${this.fullSendtime}`;
    }

    public get fullConversationId(): string {
        if (this.senderId === UserBaseView.SystemUserId) return `${this.conversationId}-${this.refNumber}`;
        else return this.conversationId;
    }
}

/**
 * Konversation
 */
export class Conversation {
    /**
     * Wenn true dann ist die Conversation empty
     *
     {boolean}
     */
    public empty: boolean;

    /**
     * ConversationId
     {string}
     */
    public id: string;

    /**
     * Ausschnitt der letzten Nachricht in dieser Unterhaltung. Kann ein Template enthalten. Maximal 255 Zeichen.
     {string}
     */
    public lastMessage: string;

    /**
     * Wann wurde die letzte Nachricht gesendet
     *
     {number}
     */
    public lastMessageOn: number;

    /**
     * UserId des eingeloggten Users
     {number}
     */
    public ownerId: number;

    /**
     * ConversationsPartner
     {ProfileInfo}
     */
    public partner: ProfileInfo;

    /**
     * partnerConversationState
     *
     {any}
     */
    public partnerConversationState: any;

    /**
     * Ist bis auf bei Systemnachrichten immer null
     *
     */
    public refNumber: number;

    /**
     * Status der Unterhaltung
     * - read : Gelesen
     * - unread : Ungelesen
     * - transmitted : Beantwortet/Gesendet
     *
     {string}
     */
    public state: string;

    /**
     * Anzahl ungelesener Nachrichten in der Unterhaltung
     *
     {number}
     */
    public unreadMessages: number;

    /**
     *Ob der user die Konversation gesehen hat. Nicht verwechseln mit gelesen. Mögliche Werte:
     * - seen
     * - unseen
     *
     {string}
     */
    public unseen: Unseen;

    public pinned: boolean;


    public static create(conversation ?: any): Conversation {
        const res = new Conversation();

        if (conversation) {
            res.id = conversation.id;
            res.ownerId = conversation.ownerId;
            res.refNumber = conversation.refNumber;
            res.lastMessageOn = conversation.lastMessageOn;
            res.lastMessage = conversation.lastMessage;
            res.state = conversation.state;
            res.unseen = conversation.unseen;
            res.unreadMessages = conversation.unreadMessages;
            res.partnerConversationState = conversation.partnerConversationState;
            res.partner = ProfileInfo.create(conversation.partner);
            res.empty = false;
            res.pinned = conversation.pinned;
        } else {
            res.empty = true;
            res.unreadMessages = 0;
        }

        return res;
    }

    public static createMultiple(conversations: any[] = []): Conversation[] {
        return conversations.map(cur => Conversation.create(cur));
    }

    /**
     * Gibt true zurück wenn es ungelesenen Nachrichten gibt
     {boolean}
     */
    public areMessagesUnread(): boolean {
        return this.unreadMessages > 0;
    }

    public isRead(): boolean {
        return this.state === 'read';
    }

    public isSeen(): boolean {
        return this.unseen === 'seen';
    }

    public isTransmitted(): boolean {
        return this.state === 'transmitted';
    }

    public isUnread(): boolean {
        return this.state === 'unread';
    }
}

export enum ConversationFilter {
    All= 'all',
    Unread = 'unread',
    Read = 'read',
    Transmitted = 'transmitted',
    Received = 'received',
    SystemOnly = 'only_system',
    AllWithoutSystem = 'without_system',
    UnreadWithoutSystem = 'unread_without_system'
}

export interface Conversations {
    conversations: Conversation[]
}

export class DetailedConversationUnreadCounts{
    public total: number;
    public nonSystem: number;
    public system: number;
}

export abstract class MessageDecorator implements MessageInterface {
    public get type(): string {
        return this.message.type;
    }

    public set type(value: string) {
        this.message.type = value;
    }
    public get triggeringUser(): number {
        return this.message.triggeringUser;
    }

    public set triggeringUser(value: number) {
        this.message.triggeringUser = value;
    }
    public get text(): string {
        return this.message.text;
    }

    public set text(value: string) {
        this.message.text = value;
    }
    public get sendtimeMilliseconds(): number {
        return this.message.sendtimeMilliseconds;
    }

    public set sendtimeMilliseconds(value: number) {
        this.message.sendtimeMilliseconds = value;
    }
    public get sendtime(): number {
        return this.message.sendtime;
    }

    public set sendtime(value: number) {
        this.message.sendtime = value;
    }
    public get senderId(): number {
        return this.message.senderId;
    }

    public set senderId(value: number) {
        this.message.senderId = value;
    }
    public get refNumber(): number {
        return this.message.refNumber;
    }

    public set refNumber(value: number) {
        this.message.refNumber = value;
    }
    public get receiverId(): number {
        return this.message.receiverId;
    }

    public set receiverId(value: number) {
        this.message.receiverId = value;
    }
    public get read(): boolean {
        return this.message.read;
    }

    public set read(value: boolean) {
        this.message.read = value;
    }
    public get newDate(): boolean {
        return this.message.newDate;
    }

    public set newDate(value: boolean) {
        this.message.newDate = value;
    }
    public get lastSenderMsg(): boolean {
        return this.message.lastSenderMsg;
    }

    public set lastSenderMsg(value: boolean) {
        this.message.lastSenderMsg = value;
    }
    public get lastDayMsg(): boolean {
        return this.message.lastDayMsg;
    }

    public set lastDayMsg(value: boolean) {
        this.message.lastDayMsg = value;
    }
    public get id(): number {
        return this.message.id;
    }

    public set id(value: number) {
        this.message.id = value;
    }
    public get firstUnread(): boolean {
        return this.message.firstUnread;
    }

    public set firstUnread(value: boolean) {
        this.message.firstUnread = value;
    }
    public get extraInformation(): any {
        return this.message.extraInformation;
    }

    public set extraInformation(value: any) {
        this.message.extraInformation = value;
    }

    public get deletedAt(): number {
        return this.message.deletedAt;
    }

    public set deletedAt(value: number) {
        this.message.deletedAt = value;
    }
    public get deleted(): boolean {
        return this.message.deleted;
    }

    public set deleted(value: boolean) {
        this.message.deleted = value;
    }
    public get conversationId(): string {
        return this.message.conversationId;
    }

    public set conversationId(value: string) {
        this.message.conversationId = value;
    }

    public get fullSendtime(): number {
        return this.message.fullSendtime;
    }

    public get timeId(): string {
        return this.message.timeId;
    }

    public isSender(): boolean {
        return this.message.isSender();
    }

    constructor(private message: MessageInterface) {
    }

    public get fullConversationId(): string {
        return this.message.fullConversationId;
    }
}

export class MessageSendStateDecorator extends MessageDecorator {
    public sendError = false;

    constructor(message: MessageInterface) {
        super(message);
    }
}

export class MessageResults {
    public conversationRead = false;
    public moreMessages = false;
    public messages: Message[];
}