export const objectToFormData = (obj, form = [], namespace = '') => {
    // let fd = form || new FormData();
    const formdata = form || [];
    let formKey;

    // eslint-disable-next-line no-restricted-syntax
    for (const property in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, property)) {
            if (namespace) {
                formKey = `${namespace}[${property}]`;
            } else {
                formKey = property;
            }

            if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], formdata, property);
            } else {
                formdata.push(`${formKey}=${obj[property]}`);
            }
        }
    }
    return formdata.join('&');
};
