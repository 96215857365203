import {
    Injectable,
    OnDestroy,
} from '@angular/core';
import {
    NavigationEnd,
    Router,
} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
                providedIn: 'root',
            })
export class PreviousRouteService implements OnDestroy {
    private _previousUrl: string;
    public currentUrl: string;
    private unsubscribe$ = new Subject();

    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        router.events
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            }
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public get previousUrl() {
        return this._previousUrl;
    }
}
