import {
    Injectable,
    OnDestroy,
} from '@angular/core';
import {
    AccountState,
    CurrentUser,
    MessageResults,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from './api';
import { StoreService } from './storage/store.service';
import { CurrentUserService } from './user.service';

export enum ConversationDisabled {
    No,
    PermNeed,
    Ignore,
    IgnoresMe,
    Loocked,
}

@Injectable({ providedIn: 'root' })
export class MessageService implements OnDestroy {
    private currentUser: CurrentUser = new CurrentUser();
    private unsubscribe$ = new Subject();

    sendMessageWithEnter$ = this.storeService.sendMessageWithEnter.get()

    set sendMessageWithEnter(v: boolean) {
        this.storeService.sendMessageWithEnter.next(v);
    }

    get sendMessageWithEnter(): boolean {
        return this.storeService.sendMessageWithEnter.getValues();
    }

    constructor(
        private currentUserService: CurrentUserService,
        private api: ApiService,
        private storeService: StoreService
    ) {
        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(val => {
                this.currentUser = val;
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    send(conversationId: string, msg: string): Promise<any> {
        return this.api.message.write(conversationId, msg.replace('&nbsp;', '')).then(val => {
            if (val.error) throw new Error(val.error_description[0]);
            return val;
        });
    }

    sendMessage(to: UserBaseView, msg: string): Promise<any> {
        const conversationId = `${this.currentUser.id}-${to.id}`;
        return this.send(conversationId, msg);
    }

    get(conversationId: string, startingId: number = null): Promise<MessageResults> {
        // TODO: check why the app hangs at 2+ messages in a normal conversation. Probably missing exception handling.
        //if (!conversationId) return new Promise((resolve, reject) => { reject("undefined conversation id"); });

        return (!startingId ? this.api.message.get(conversationId) : this.api.message.messages(conversationId, startingId))
            .then(value => value.data as MessageResults);
    }

    loadOlder(conversationId: string, startId: number): Promise<any> {
        return this.api.message.getOlder(conversationId, startId, 10);
    }

    conversationPossible(profileInfo: ProfileInfo): ConversationDisabled {
        if (profileInfo.isTeam) return ConversationDisabled.No;
        if (this.currentUser.premiumNeeded && !this.currentUser.premium) return ConversationDisabled.PermNeed;
        if (profileInfo.ignore) return ConversationDisabled.Ignore;
        if (profileInfo.ignoresMe) return ConversationDisabled.IgnoresMe;
        if (this.currentUser.accountState === AccountState.Deactivated) return ConversationDisabled.Loocked;

        return ConversationDisabled.No;
    }

    delete(conversationId, ids): Promise<any> {
        return this.api.message.delete(conversationId, ids).then(val => {
            if (val.error) throw new Error(val.error_description[0]);
            return val;
        });
    }

    clearUnsentMessage(conversationId: string) {
        const messages = this.storeService.unsentMessages.getValues();
        delete messages[conversationId];
        this.storeService.unsentMessages.next(messages);
    }

    updateUnsentMessage(conversationId: string, message: string) {
        const messages = this.storeService.unsentMessages.getValues();
        messages[conversationId] = message;
        this.storeService.unsentMessages.next(messages);
    }

    getUnsentMessage(conversationId: string): string {
        const messages = this.storeService.unsentMessages.getValues();
        if (conversationId in messages) return messages[conversationId];
        else return '';
    }

    hasUnsentMessage(conversationId: string): boolean {
        const messages = this.storeService.unsentMessages.getValues();
        return conversationId in messages;
    }

}
