import {
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
} from 'BKModels';
import {
    CurrentUserService,
} from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector: 'active-users-header',
               template: `
                             <intern-default-header [title]="'LAYOUT.SIDE_NAV.ACTIVE_USERS' | translate" class="header">
                                 <div class="settings-box">
                                     <chip icon="wc" [closeable]="false" (click)="onOpenSettings()">{{ genderTextKey|translate }}</chip>
                                     <chip icon="event" [closeable]="false" (click)="onOpenSettings()">{{ 'ACTIVE_USERS_PREVIEW.SEARCH_AGE'|translate:ageParams }}</chip>
                                 </div>
                             </intern-default-header>`,
               styles: [require('./active-users.style.scss')],
           })
export class ActiveUsersHeader implements OnInit {
    currentUser: CurrentUser = new CurrentUser();

    get genderTextKey(): string {
        if (!this.currentUser) return '-';

        switch (this.currentUser.searchGender) {
            case Gender.Male:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_MALE';
            case Gender.Female:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_FEMALE';
            case Gender.Both:
                return 'ACTIVE_USERS_PREVIEW.SEARCH_BOTH';
            case Gender.None:
            default:
                return '-';
        }
    }

    get ageParams(): { from: string, to: string } {
        if (!this.currentUser) return { from: '0', to: '0' };

        return {
            'from': this.currentUser.ageFrom.toString(),
            'to':   this.currentUser.ageTill.toString(),
        };
    }

    onOpenSettings() {
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }

    ngOnInit() {
        this.currentUserService
            .currentUser
            .then(currentUser => this.currentUser = currentUser);
    }

    constructor(
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
    ) {
    }
}
