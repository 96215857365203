import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import { FormatAmount } from 'BKUtils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    PaymentType,
    Product,
} from '../../../../../models/product';
import { PaymentService } from '../../../../../service/payment.service';
import { DurationUnit } from '../../../../../utils/duration';

@Component({
               selector: 'premium-purchase-after-sale-summary',
               template: `
                             <div class="premium-purchase-after-sale-summary" *ngIf="shown">
                                 <premium-purchase-after-sale-delaybox id="delaybox" *ngIf="!currentUser.isPremium()" data-html2canvas-ignore></premium-purchase-after-sale-delaybox>

                                 <div class="premium-purchase-after-sale-summary__header">
                                     <div class="premium-purchase-after-sale-summary__logo"></div>
                                     <div class="premium-purchase-after-sale-summary__support">
                                         <premium-support 
                                                 [showTitle]="false"
                                                 (clickPhoneNumber)="trackPhonenumberClick()" 
                                                 (clickMail)="trackMailClick()"
                                         ></premium-support>
                                     </div>
                                 </div>

                                 <div class="premium-purchase-after-sale-summary__info">
                                     <div>
                                         <span>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.DATE' | translate}}</span>
                                         <span>{{currentDate}}</span>
                                     </div>
                                     <div>
                                         <span>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.PAYMENT-METHODE' | translate}}</span>
                                         <span>{{paymentMethode | translate}}</span>
                                     </div>
                                     <div>
                                         <span>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.NEXT-RENEW' | translate}}</span>
                                         <span>{{endDate}}</span>
                                     </div>
                                     <div>
                                         <span>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.NEXT-TERMINATION-DATE' | translate}}</span>
                                         <span>{{lastCancelationDate}}</span>
                                     </div>
                                 </div>
                                 <table>
                                     <thead>
                                     <tr>
                                         <th [attr.colspan]="atLeastOneMonth ? 1 : 2">{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.DESCRIPTION' | translate}}</th>
                                         <th *ngIf="atLeastOneMonth">{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.MONTH-PRICE' | translate}}</th>
                                         <th>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.TOTAL-PRICE'| translate }}</th>
                                     </tr>
                                     </thead>
                                     <tbody>
                                     <tr>
                                         <td [attr.colspan]="atLeastOneMonth ? 1 : 2">{{product.nameTranslationKey | translate}}{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.DESCRIPTION.TEXT' | translate:descriptionObj}}</td>
                                         <td *ngIf="atLeastOneMonth">{{getCurrency(expensiveProduct.formattedPricePerMonth)}}</td>
                                         <td>{{getCurrency(priceBeforeDiscount)}}</td>
                                     </tr>
                                     <tr *ngIf="discountPercent > 0 && atLeastOneMonth">
                                         <td>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.DISCOUNT' | translate | replace:'discount':discountPercent:true}}
                                         <td>{{getCurrency(discountMonthPrice)}}</td>
                                         <td>{{getCurrency(discountPrice)}}</td>
                                     </tr>
                                     </tbody>
                                     <tfoot>
                                     <tr>
                                         <th>{{renewalInfoKey | translate:renewalInfoReplace}}</th>
                                         <th>{{'PREMIUM.PURCHASE.AFTER-SALE.SUMMARY.BALANCE_DUE' | translate}}</th>
                                         <th>{{getCurrency(product.formattedPrice)}}</th>
                                     </tr>
                                     </tfoot>
                                 </table>
                                 <div class="premium-purchase-after-sale-summary__renewal-info">
                                     {{renewalInfoKey | translate:renewalInfoReplace}}
                                 </div>
                                 <div class="button" data-html2canvas-ignore>
                                     <flat-button (click)="download.emit()"> {{'PREMIUM.PURCHASE.AFTER-SALE.DOWNLOAD' | translate}}</flat-button>
                                 </div>
                             </div>
                         `,
               styles: [require('./premium-purchase-after-sale-summary.scss')],
           })

export class PremiumPurchaseAfterSaleSummary implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    @Input() private product: Product = null;
    @Input('startDate') private _startDate: Date = null;
    @Input() private purchaseDate: Date = null;
    @Input() private paymentType: PaymentType = null;

    @Input() private currentUser: CurrentUser;
    @Input() private expensiveProduct: Product;
    private currencyString = '';
    private loaded = false;
    @Output() private download = new EventEmitter();

    private get atLeastOneMonth(): boolean {
        return this.product.period.unit >= DurationUnit.Month;
    }

    private get shown(): boolean {
        return this.loaded && this.expensiveProduct !== undefined && this.expensiveProduct !== null && this.product !== null;
    }

    public constructor(private translate: TranslateService,
                       private paymentService: PaymentService,
                       private trackingService: TrackingService,
    ) {
    }

    public ngOnInit() {
        const key = 'GLOBAL.CURRENCY';
        this.translate.stream(key)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                if (res != key) {
                    this.loaded = true;
                }
                this.currencyString = res;
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private get currentDate() {
        return this.getFormatedDate(Date.now());
    }

    private get startDate() {
        return this.getFormatedDate(this._startDate);
    }

    private get endAt() {
        // @ts-ignore
        return moment(this._startDate).add(this.product.period.amount, this.product.period.momentDurationUnit);
    }

    private get endDate() {
        return this.getFormatedDate(this.endAt);
    }

    private getFormatedDate(date): string {
        return moment(date).format('DD.MM.YYYY');
    }

    private get lastCancelationDate() {
        const lastCancelationDate = moment(this.endAt).subtract(1, 'd');
        return this.getFormatedDate(lastCancelationDate);
    }

    private paymentTypeTranslationKeys: { [key in PaymentType]: string } = {
        [PaymentType.Bank]:   'PREMIUM.BOOKING.PAYMENT.BANK',
        [PaymentType.Paypal]: 'PREMIUM.BOOKING.PAYMENT.PAYPAL',
        [PaymentType.Cash]:   'PREMIUM.BOOKING.PAYMENT.CASH',
        [PaymentType.Credit]: 'PREMIUM.BOOKING.PAYMENT.PAYPAL',
    };


    private get paymentMethode(): string {
        return this.paymentTypeTranslationKeys[this.paymentType];
    }

    private get descriptionObj() {
        return {
            startDate: this.startDate,
            endDate:   this.endDate,
        };
    }

    private getCurrency(price) {
        return price;
    }

    private get renewalInfoKey(): string {
        if (this.product.period.unit === DurationUnit.Month) {
            if (this.product.period.amount == 1) {
                return 'PREMIUM.PURCHASE.RENEWAL-INFO.MONTH';
            } else {
                return 'PREMIUM.PURCHASE.RENEWAL-INFO.MONTHS';
            }
        }

        return 'PREMIUM.PURCHASE.RENEWAL-INFO.DEFAULT';
    }

    private get renewalInfoReplace() {
        return {
            endDate: this.endDate,
            price:   this.getCurrency(this.product.formattedSubscriptionPrice),
            amount:  this.product.period.amount.toString(),
        };
    }

    @FormatAmount()
    private get priceBeforeDiscount() {
        // TODO find a better solution to properly support non month-based durations
        if (this.atLeastOneMonth) {
            return this.expensiveProduct.pricePerMonth * this.product.period.amount;
        }
        return this.product.price;
    }

    @FormatAmount()
    private get discountMonthPrice() {
        return this.product.pricePerMonth - this.expensiveProduct.pricePerMonth;
    }

    @FormatAmount()
    private get discountPrice() {
        // TODO this breaks for any non month-based duration
        return this.product.price - this.expensiveProduct.pricePerMonth * this.product.period.amount;
    }

    private get discountPercent() {
        return Math.floor((1 - (this.product.pricePerDay / this.expensiveProduct.pricePerDay)) * 100);
    }

    private trackPhonenumberClick() {
        this.trackingService.hit('Premium', 'AfterSales', 'Supportnummer');
    }

    private trackMailClick() {
        this.trackingService.hit('Premium', 'AfterSales', 'Supportmail');
    }
}
