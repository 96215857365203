import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { AccordionComponent } from './accordion.component';

/**
 * Component for the AccordionTabs
 */
@Component({
               selector: 'accordion-tab',
               template: `
                   <div class="accordion-tab">
                       <accordion-tab-header [title]="title"
                                             [showSubtitle]="showSubtitle()"
                                             [subtitle]="subtitle"
                                             (toggle)="toggle($event)"
                                             [selected]="selected"
                       ></accordion-tab-header>

                       <accordion-tab-content [state]="state"
                                              (onToggleDone)="onToggleDone($event)"
                       >
                           <ng-content></ng-content>
                       </accordion-tab-content>
                   </div>
               `,
               styles: [require('./accordion-tab.scss')],
           })

export class AccordionTab implements OnInit {
    private animating: boolean;

    @Input() private disabled: boolean;

    @Input() private selected: boolean = false;

    @Output() private selectedChange: EventEmitter<any> = new EventEmitter();

    private state: 'visible' | 'hidden' = 'hidden';

    @Input() private subtitle: string = '';

    @Input() private title: string = '';

    public constructor(public accordion: AccordionComponent) {
        this.accordion.addTab(this);
    }

    public ngOnInit() {
        setTimeout(() => this.setState());
    }

    public setSelect(bool: boolean) {
        if (this.selected !== bool) {
            this.selected = bool;
            this.selectedChange.emit(bool);
            this.setState();
        }
    }

    private onToggleDone(event: Event) {
        this.animating = false;
    }

    private setState() {
        this.state = this.selected ? 'visible' : 'hidden';
    }

    private showSubtitle(): boolean {
        return !this.selected;
    }

    private toggle($event) {
        if (!this.disabled && !this.animating) {
            this.animating = true;
            const index = this.accordion.findTabIndex(this);

            if (this.selected) {
                this.setSelect(false);
                this.accordion.onClose.emit({
                                                originalEvent: $event,
                                                index,
                                            });
            } else {
                if (!this.accordion.multiple) {
                    this.accordion.closeAll();
                }
                this.setSelect(true);
                this.accordion.onOpen.emit({
                                               originalEvent: event,
                                               index,
                                           });
            }
            $event.preventDefault();
        }
    }
}
