import { Component } from '@angular/core';
import { openRegistration } from 'BKUtils';
import {
    NavigationService,
    Routes,
} from '../../service/navigation.service';

@Component({
               selector:  'login-header',
               template:  `
    <bk-header-out>
      <div class="header-right">
        {{'GLOBAL.NEW' | translate }}
        <flat-button (click)="registrationClick()">{{'GLOBAL.REGISTER_NOW' | translate }}</flat-button>
      </div>
    </bk-header-out>`,
               styles: [require('./login-header.scss')],
           })

export class LoginHeaderComponent {
    public constructor() {
    }

    private registrationClick() {
        openRegistration();
    }
}
