import {
    AfterViewInit,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCFloatingLabel } from '@material/floating-label/component';
import { MDCTextField } from '@material/textfield/component';
import {
    MDCTextFieldCharacterCounter,
    MDCTextFieldHelperText,
} from '@material/textfield/index';
import { TranslateService } from '@ngx-translate/core';
import { ValueAccessorBase } from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';

export class TextFieldBase extends ValueAccessorBase<string | number> implements AfterViewInit {
    @Input() protected id: string = uuidv4();
    @Input() protected placeholder: string = '';
    @Input() protected maxLength?: number = null;

    @Output() private keydown = new EventEmitter();
    @Output() private keyup = new EventEmitter();
    @Output() private blur = new EventEmitter();
    @Output() private focus = new EventEmitter();

    private label: MDCFloatingLabel = null;
    @ViewChild('label', { static: false }) private labelRef: ElementRef<HTMLElement>;

    @ViewChild('input', { static: true }) private inputRef: ElementRef;

    protected get inputElement() {
        return this.inputRef.nativeElement;
    }

    private textField: MDCTextField;
    @ViewChild('textField', { static: true }) private textFieldRef: ElementRef;

    private counter: MDCTextFieldCharacterCounter;
    @ViewChild('counter', { static: false }) private counterRef: ElementRef;

    @Input() protected pasteError: boolean = false;

    @Input('valid') private _valid: boolean = true;
    protected get valid(): boolean {
        return this._valid;
    }

    protected set valid(value) {
        this._valid = value;
        if (this.textField) this.textField.valid = this.valid;
    }

    protected get currentLength(): number {
        if (!this.value) return 0;
        // HACK: Change length calculation as Safari encodes new lines as CRLF when sending to the API endpoint
        if (navigator.isSafari()) return this.value.toString().replace(/\n/g, '\r\n').utf8Length();
        else return this.value.toString().utf8Length();
    }

    protected get isNotched(): boolean {
        return (this.value && this.value.toString().length > 0) || this.inputElement == document.activeElement;
    }

    public get width() {
        return this.textFieldRef.nativeElement.offsetWidth;
    }

    protected filterKeyPress(event): any {
        let value = String.fromCharCode(event.keyCode);
        // HACK: Change length calculation as Safari encodes new lines as CRLF when sending to the API endpoint
        if (value.match(/[\r\n]/) && navigator.isSafari()) value = '\r\n';

        let element = this.inputElement;
        let selectedText = element.value.substring(element.selectionStart, element.selectionEnd);

        if (this.maxLength && this.currentLength - selectedText.utf8Length() + value.utf8Length() > this.maxLength) {
            event.preventDefault();
            return;
        }

        this.pasteError = false;
    }

    protected filterPaste(event): any {
        let value = event.clipboardData.getData('text/plain').toString();
        // HACK: Change length calculation as Safari encodes new lines as CRLF when sending to the API endpoint
        if (navigator.isSafari()) value = value.replace(/\n/g, '\r\n');

        let element = this.inputElement;
        let selectedText = element.value.substring(element.selectionStart, element.selectionEnd);

        if (this.maxLength && this.currentLength - selectedText.utf8Length() + value.utf8Length() > this.maxLength) {
            this.pasteError = true;
            event.preventDefault();
            return;
        }
        this.pasteError = false;
    }

    public ngAfterViewInit(): void {
        this.textField = new MDCTextField(this.textFieldRef.nativeElement);
        if(!this.placeholder.isEmpty()) this.label = new MDCFloatingLabel(this.labelRef.nativeElement);

        this.valid = this._valid;
        this.textField.disabled = this.disabled;

        setTimeout(() => {
            this.layout();
            // HACK: fix label for chrome/webkit browser when values automatically filled in
            try {
                if (this.textFieldRef.nativeElement.querySelector('.mdc-text-field__input:-webkit-autofill')) {
                    this.labelRef.nativeElement.classList.add('mdc-floating-label--float-above');
                    this.textField.getDefaultFoundation().notchOutline(true);
                }
            } catch (e) {
                // Do nothing
            }
        }, 100);

    }

    @Input() protected errorText: String = '';
    @Input() protected helperText: string = '';

    private get helperTextEmpty() {
        return this.helperText.isEmpty() && this.errorText.isEmpty();
    }

    private get hintText() {
        if (this.pasteError) return this.translateService.instant('TEXT_FIELD.PASTE_ERROR_OVERFLOW');
        if (!this.valid) return this.errorText;
        return this.helperText;
    }

    private get hasErrors() {
        return this.pasteError || !this.valid;
    }

    private helperTextElement: MDCTextFieldHelperText;

    @ViewChild('helperText', { static: true })
    private set helper(helperRef: ElementRef) {
        if (!helperRef) return;
        this.helperTextElement = new MDCTextFieldHelperText(helperRef.nativeElement);
    }

    constructor(private translateService: TranslateService) {
        super();
    }

    public writeValue(value:string | number) {
        this.pasteError = false;
        super.writeValue(value);
    }

    public layout(): Promise<void> {
        return new Promise<void>(resolve => {
            if(!this.placeholder.isEmpty()) {
                this.textField.layout();
                this.textField.getDefaultFoundation().activateFocus();
                this.textField.getDefaultFoundation().deactivateFocus();
            }
            resolve();
        });
    }

    public get caretPosition() {
        let caretPosition = 0;

        if (typeof this.inputElement.selectionStart == 'number' && typeof this.inputElement.selectionEnd == 'number') {
            caretPosition = this.inputElement.selectionStart;
        } else {
            const range = document['selection'].createRange();
            if (range && range.parentElement() == this.inputElement) {
                const length = this.inputElement.value.length;
                const normalizedValue = this.inputElement.value.replace(/\r\n/g, '\n');

                // Create a working TextRange that lives only in the input
                const textInputRange = this.inputElement.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                const endRange = this.inputElement.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
                    caretPosition = length;
                } else {
                    caretPosition = -textInputRange.moveStart('character', -length);
                    caretPosition += normalizedValue.slice(0, caretPosition).split('\n').length - 1;
                }
            }
        }

        return caretPosition;
    }


    public set caretPosition(pos) {
        setTimeout(() => this.inputElement.setSelectionRange(pos, pos));
    }
}

