import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import { OrderReasonVariant } from './premium-landing-page-usp-variant';

/*
 * Um eine neue Variate zu machen müssen folgene Schlüssel angelegt werden
 * - PREMIUM_LANDINGPAGE.USP.{variant}.TITLE
 * - PREMIUM_LANDINGPAGE.USP.{variant}.TEXT
 * - PREMIUM_LANDINGPAGE.USP.{variant}.USP_1
 * - PREMIUM_LANDINGPAGE.USP.{variant}.USP_2
 * - PREMIUM_LANDINGPAGE.USP.{variant}.TITLE
 * - PREMIUM_LANDINGPAGE.USP.{variant}.USP_3
 *
 * Die Bilder müssen alle den gleichen Namen haben und werden in die Ordner
 *
 * -  /assets/img/premium-landingpage/female-old
 * -  /assets/img/premium-landingpage/female-young
 * -  /assets/img/premium-landingpage/male-old
 * -  /assets/img/premium-landingpage/male-young
 *
 * Bei dem Object müssen nur noch die Variante und das Name des Bildes
 */

const orderReasonVariant: { [key in OrderReason]: OrderReasonVariant } = {
    [OrderReason.None]:                         new OrderReasonVariant(),
    [OrderReason.ProfileVisitor]:               new OrderReasonVariant('PROFILE_VISITORS', 'Profilbesucher.jpg'),
    [OrderReason.NewUser]:                      new OrderReasonVariant('NEW_USER'),
    [OrderReason.NearByUser]:                   new OrderReasonVariant('NEAR_BY_USER'),
    [OrderReason.NoAdvertising]:                new OrderReasonVariant('NO_ADVERTISING'),
    [OrderReason.FavoriteBirthday]:             new OrderReasonVariant(),
    [OrderReason.MatchPartner]:                 new OrderReasonVariant('MATCH_PARTNER', 'Matchpartner.jpg'),
    [OrderReason.WishPartner]:                  new OrderReasonVariant('WISH_PARTNER', 'Wunschpartner.jpg'),
    [OrderReason.WantToKnowYou]:                new OrderReasonVariant('WANT_YOU', 'Wer-hat-ja-gesagt.jpg'),
    [OrderReason.AllImages]:                    new OrderReasonVariant('ALL_IMAGES'),
    [OrderReason.ExtendedSearch]:               new OrderReasonVariant('EXTENDED_SEARCH', 'Erweiterte-Suche.jpg'),
    [OrderReason.SearchRadius]:                 new OrderReasonVariant('SEARCH_RADIUS', 'Suchradius.jpg'),
    [OrderReason.ReadingState]:                 new OrderReasonVariant('READING_STATE', 'Nachricht-gelesen.jpg'),
    [OrderReason.IncognitoMode]:                new OrderReasonVariant('INCOGNITO_MODE'),
    [OrderReason.HideOnlineState]:              new OrderReasonVariant('HIDE_ONLINE_STATE', 'Onlinestatus-verbergen.jpg'),
    [OrderReason.UnlimitedMatching]:            new OrderReasonVariant('UNLIMTED_MATCH', 'Matchlimit.jpg'),
    [OrderReason.MatchingSeeMatchpartner]:      new OrderReasonVariant('WANT_YOU', 'Wer-hat-ja-gesagt.jpg'),
    [OrderReason.OlderMessages]:                new OrderReasonVariant('OLDER_MESSAGES', 'Nachrichtenverlauf.jpg'),
    [OrderReason.MatchingOpenProfile]:          new OrderReasonVariant('MATCHING_OPEN_PROFILE'),
    [OrderReason.PremiumServiceOverview]:       new OrderReasonVariant(),
    [OrderReason.SelectedUser]:                 new OrderReasonVariant('SELECTED_USER', 'Ausgewaehlte-Singles.jpg'),
    [OrderReason.SelectedUserAccountDelete]:    new OrderReasonVariant('SELECTED_USER', 'Ausgewaehlte-Singles.jpg'),
    [OrderReason.RealUser]:                     new OrderReasonVariant(),
    [OrderReason.PremiumExpiration]:            new OrderReasonVariant(),
    [OrderReason.LegacyWelcomeOffer]:           new OrderReasonVariant(),
    [OrderReason.LegacyWelcomeDelayedOffer]:    new OrderReasonVariant(),
    [OrderReason.ExtendPremium]:                new OrderReasonVariant(),
    [OrderReason.RecurringOffer]:               new OrderReasonVariant(),
    [OrderReason.ActivityList]:                 new OrderReasonVariant('ACTIVITY_LIST','activity-list.jpg'),
    [OrderReason.ActivityListPreview]:          new OrderReasonVariant('ACTIVITY_LIST','activity-list.jpg'),
    [OrderReason.ChangeNegativeMatchDecision]:  new OrderReasonVariant(),
    [OrderReason.BirthdayOffer]:                new OrderReasonVariant(),
    [OrderReason.LoyaltyOffer]:                 new OrderReasonVariant(),
    [OrderReason.WantToKnowYouProfile]:         new OrderReasonVariant('WANT_YOU', 'Wer-hat-ja-gesagt.jpg'),
    [OrderReason.ChangeDecisionToYesProfile]:   new OrderReasonVariant('CHANGE_DECISION_TO_YES', 'entscheidung-aendern.jpg'),
};

@Component({
               selector: 'premium-landing-page-usp',
               template: `
                             <div class="premium-landing-page-usp"
                                  [style.background-image]="image"
                             >
                                 <div class="premium-landing-page-usp__content_wrap">
                                     <div class="premium-landing-page-usp__title">{{variant.title | translate}}</div>
                                     <div class="premium-landing-page-usp__text">{{variant.text | translate}}</div>

                                     <div class="premium-landing-page-usp__box">
                                         <div class="premium-landing-page-usp__box__title">{{'PREMIUM_LANDINGPAGE.USP.USP.TITLE' | translate}}</div>
                                         <div class="premium-landing-page-usp__box__container">
                                             <div class="premium-landing-page-usp__box__entry" *ngFor="let usp of variant.usp">
                                                 <icon class="premium-landing-page-usp__box__icon" icon="check"></icon>
                                                 <span>{{usp | translate}}</span>
                                             </div>
                                         </div>
                                     </div>
                                     <div class="premium-landing-page-usp__botton">
                                         <raised-button premium (click)="buttonClick.emit($event)">{{'PREMIUM_LANDINGPAGE.USP.BUTTON' | translate}}</raised-button>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-usp.scss')],
           })
export class PremiumLandingPageUsp {
    @Input() private orderReason: OrderReason = OrderReason.None;
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @Output() public buttonClick = new EventEmitter();

    private get variant() {
        return orderReasonVariant[this.orderReason];
    }

    private get image(): string {
        return this.variant.image(this.currentUser.gender, this.currentUser.age);
    }
}
