import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUser,
    Settings,
} from 'BKModels';
import {
    CurrentUserService,
    SettingsService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderService } from '../../../service/header.service';

@Component({
               selector: 'intern-default-header',
               template: `
                             <intern-header [showMenuContent]="showMenuContent" [class.incognito]="settings.anonymous" (click)="headerService.setSideNavOpen(false)">
                                 <intern-header-left left></intern-header-left>
                                 
                                 <intern-header-title center [closeable]="closeable" [showContent]="showContent" (click)="showContent = !showContent">
                                     {{title | translate}}
                                 </intern-header-title>
                                 <intern-header-right right [currentUser]="currentUser" [settings]="settings"></intern-header-right>
                                 <ng-content menu select="[menu]"></ng-content>
                                 <div extra *ngIf="showContent">
                                     <ng-content></ng-content>
                                 </div>
                             </intern-header>`,
               styles: [require('./intern-default-header.scss')],
           })
export class InternDefaultHeader implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    @Input('title') private _title: string = null;
    @Input() private closeable: boolean = false;
    private currentUser = new CurrentUser();
    private settings = new Settings();

    //@Todo find a better name
    showContent = true;
    @Input() private showMenuContent: boolean = false;


    constructor(private activatedRoute: ActivatedRoute,
                private settingsService: SettingsService,
                private currentUserService: CurrentUserService,
                private headerService: HeaderService,
                private translate: TranslateService
                ) {
    }

    public ngOnInit(): void {
        this.settingsService.settings
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(settings => this.settings = settings);

        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(user => this.currentUser = user);

        this.headerService.extraContentVisibilitySubject
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(it => this.showContent = it);
    }

  public ngOnDestroy() {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }
    private get title() {
        if (this._title) {
            return this._title;
        }
        else {
            const data = this.activatedRoute.snapshot.data;
            if (data.translateTitle) return this.translate.instant(data.translateTitle);
            else return data.title;
        }
    }

    public openContent() {
        this.showContent = true;
    }
}
