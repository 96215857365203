import { Component } from '@angular/core';

@Component({
               selector:  'footer-flag-list',
               template:  `
                             <ul class="flag-list">
                                 <footer-flag-item img="/assets/img/flags/flag_deutschland.png"
                                                   link="https://www.bildkontakte.de"
                                 ></footer-flag-item>
                                 <footer-flag-item img="/assets/img/flags/flag_schweiz.png"
                                                   link="https://www.bildkontakte.ch"
                                 ></footer-flag-item>
                                 <footer-flag-item img="/assets/img/flags/flag_oesterreich.png"
                                                   link="https://www.bildkontakte.at"
                                 ></footer-flag-item>
                                 <footer-flag-item img="/assets/img/flags/flag_polen.png"
                                                   link="https://www.fotoflirt.pl"
                                 ></footer-flag-item>
                                 <footer-flag-item img="/assets/img/flags/flag_gb.png"
                                                   link="https://www.single.am"
                                 ></footer-flag-item>
                             </ul>`,
               styles: [require('./flag-list.scss')],
           })

export class FooterFlagList {
}
