import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CurrentUser } from 'BKModels';
import {
    ApiService,
    CommunicationService,
} from 'BKService';
import {
    startLoadingBar,
    stopLoadingBar,
} from 'BKUtils';
import { AuthenticityCheckModal } from '../../authenticity-check-modal';

@Component({
               selector:  'authenticity-check-photo',
               template:  `
                              <authenticity-check-card-layout
                                      icon="photo-upload"
                                      [title]="'ECHECK.PHOTO.PHOTOCHECK'|translate"
                                      [description]="'ECHECK.PHOTO.DESCRIPTION'|translate"
                                      [pendingDescription]="'ECHECK.STATE.PENDING.DESCRIPTION' | translate"
                                      [verifiedDescription]="'ECHECK.STATE.VERIFIED.DESCRIPTION' | translate"
                                      (clickButton)="modal.open()"
                                      [verifyText]="'ECHECK.PHOTO.VERIFY_TEXT' |translate"
                                      [hasCheckDone]="currentUser.getEcheckMethode().picture"
                                      [pending]="currentUser.photoCheckValidationPending"
                              ></authenticity-check-card-layout>


                              <authenticity-check-modal #modal
                                                        [title]="'ECHECK.PHOTO.MODAL.TITLE' | translate"
                                                        [description]="'ECHECK.PHOTO.MODAL.DESCRIPTION' | translate | markdown"
                              >

                                  <image-upload
                                          class="photo-checks__upload-button"
                                          [id]="'authenticity-check-photo-input'"
                                          (selectImg)="upload($event)"
                                  >
                                      {{'ECHECK.PHOTO.MODAL.UPLOAD'|translate}}
                                  </image-upload>

                                  <div class="photo-checks__image-size-info">{{'ECHECK.IDCARD.MODAL.IMAGE_SIZE_INFO'|translate}}</div>
                              </authenticity-check-modal>
                          `,
               styles: [require('./authenticity-check-photo.scss')],
           })

export class AuthenticityCheckPhoto {
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @Output() private uploadComplete = new EventEmitter();
    @ViewChild('input', { static: true }) private inputRef: ElementRef<HTMLInputElement>;
    @ViewChild('modal', { static: true }) private modal: AuthenticityCheckModal;

    public constructor(
        private apiService: ApiService,
        private communicationService: CommunicationService,
        private translate: TranslateService,
    ) {

    }

    private upload(data) {
        startLoadingBar();
        this.apiService
            .validation
            .photocheck(data)
            .then((res) => {
                stopLoadingBar();
                if (res.error) throw new Error('File can not uploaded');

                this.communicationService.growl.positive(this.translate.instant('ECHECK.PHOTO.UPLOAD.SUCCESS'));
                this.uploadComplete.emit();
            })
            .catch(() => {
                this.communicationService.growl.negative(this.translate.instant('ECHECK.PHOTO.UPLOAD.ERROR'));
            });

        this.modal.close();
    }
}
