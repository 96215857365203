/**
 * Interface for MenuItems
 */
export interface MenuItem {
    /**
     * Click event
     * @param {($event: Event) => void)
     */
    click: ($event: Event) => void;
    /**
     * title of the item
     * @type {string}
     */
    title: string;
}
