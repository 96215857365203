/**
 * Created by olifra on 10.04.2017.
 */
import { NgModule } from '@angular/core';

import { ShareModule } from 'BKShared';
import { InternComponentsModule } from '../intern-components';
import components from './components';

import { Matching } from './container/matching';
import { MatchingHeader } from './container/matching-header';
import { MatchingTabbar } from './container/matching-tabbar';
import explain from './explain';
import { MatchingRouting } from './matching.routing';
import pages from './pages';
import placeholder from './placeholder';

@NgModule({
              imports:      [
                  ShareModule,
                  MatchingRouting,
                  InternComponentsModule,
              ],
              declarations: [
                  ...components,
                  ...explain,
                  ...pages,
                  ...placeholder,
                  Matching,
                  MatchingTabbar,
                  MatchingHeader,
              ],
          })
export class MatchingModule {
}
