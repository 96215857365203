import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { LovestoryModalModule } from '../lovestory-modal';
import { LovestoryCard } from './lovestory-card/lovestory-card';
import { LovestoryItem } from './lovestory-item/lovestory-item';

@NgModule({
              imports:      [ShareModule, LovestoryModalModule],
              exports:      [
                  LovestoryCard,
                  LovestoryItem,
              ],
              declarations: [
                  LovestoryCard,
                  LovestoryItem,
              ],
              providers:    [],
          })
export class LovestoryBkHomeModule {
}
