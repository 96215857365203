/**
 * Created by denjas on 07.04.2017.
 */
import { AbstractControl } from '@angular/forms';
import { EmailValidator } from './email.validator';
// noinspection TypeScriptPreferShortImport
import { UsernameValidator } from './username.validator';

export function loginUserValidator(control: AbstractControl): KeyValue {
    const user = UsernameValidator.username(control);
    const email = EmailValidator.email(control);

    if (user === null || email === null) return null;
    if (user !== null) return user;
    return email;
}
