import { getScrollContainer } from 'BKUtils';
import { ProfileEditRouteFragment } from '../../../../../service/navigation.service';

export class NavigationElement {
    public selected = false;

    constructor(
        public readonly id: ProfileEditRouteFragment,
        public readonly titleKey: string,
    ) {
    }

    public get chipId() {
        return `${this.id}-chip`;
    }

    public get chipElement() {
        return document.getElementById(this.chipId);
    }

    public set select(val: boolean) {
        const element = this.chipElement;
        this.selected = val;

        if(element) {
            if (val) element.classList.add('selected');
            else element.classList.remove('selected');
        }
    }

    public get element() {
        return document.getElementById(this.id);
    }

    public get isLastChild() {
        const element = this.element;
        if (element === null) return false;
        return element.nextSibling === null;
    }

    public fitInView(headerHeight): boolean {
        const boxHeight = this.getHeight(this.element);
        const sectionHeight = getScrollContainer().offsetHeight;
        return sectionHeight - headerHeight >= boxHeight;
    }

    public isScrolledInBox(scrollPos, headerHeight) {
        const element = this.element;
        if (!element) return false;
        return element.offsetTop - headerHeight <= scrollPos &&
            element.offsetTop + this.getHeight(element) - headerHeight > scrollPos;
    }

    private getHeight(element) {
        const computedHeight = window.getComputedStyle(element.firstElementChild).height;
        const heightNumber = parseInt(computedHeight.replace('px', ''));

        if (isNaN(heightNumber)) return this.getHeight(element.firstElementChild);
        return heightNumber;
    }
}
