import { addLogoutEventListener } from 'BKUtils';
import { StoreBase } from './storage-base';

export class LocalStorage<T> extends StoreBase<T> {
    public constructor(creator, storageName) {
        super(creator, storageName);
    }

    protected saveToStore(val: T) {
        window.localStorage.setItem(this.storageName, JSON.stringify(val));
    }

    public remove() {
        window.localStorage.removeItem(this.storageName);
    }

    protected loadFromStore(): T {
        return JSON.parse(window.localStorage.getItem(this.storageName));
    }

    public isPersisted(): boolean {
        return true;
    }

}

export class SessionStorage<T> extends StoreBase<T> {
    public constructor(creator, storageName) {
        super(creator, storageName);
    }

    protected saveToStore(val: T) {
        window.sessionStorage.setItem(this.storageName, JSON.stringify(val));
    }

    public remove() {
        window.sessionStorage.removeItem(this.storageName);
    }

    protected loadFromStore(): T {
        return JSON.parse(window.sessionStorage.getItem(this.storageName));
    }

    public isPersisted(): boolean {
        return false;
    }
}

export class CurrentLoginStorage<T> extends StoreBase<T> {
    public constructor(creator) {
        super(creator, null);
        addLogoutEventListener(() => this.next(creator()));
    }

    public isPersisted(): boolean {
        return false;
    }

    protected storeIsEmpty(): boolean {
        return true;
    }

    protected loadFromStore(): T {
        // TODO handled in subject in StorageBase
        return undefined;
    }

    //eslint-disable-next-line no-empty-function
    public remove() {
        // TODO handled in subject in StorageBase
    }

    //eslint-disable-next-line no-empty-function
    protected saveToStore(val: T) {
        // TODO handled in subject in StorageBase
    }
}

export class CookieStorage extends StoreBase<string> {
    public static readonly MaxAgePracticallyNeverExpiring = 100 * 365 * 86400;

    public constructor(creator, storageName, private maxAge: number, isProtected = false) {
        super(creator, storageName, isProtected);
    }

    public isPersisted(): boolean {
        return true;
    }

    protected saveToStore(val: string) {
        if (!val.isEmpty()) {
            this.setCookie(val, this.maxAge);
        }
    }

    public remove() {
        this.setCookie('', -1);
    }

    protected loadFromStore(touch: boolean = false): string {
        if (!this.cookieExists()) return this.creator();

        const regex = this.getCookieRegExp(this.storageName);
        const result = regex.exec(document.cookie);
        return decodeURIComponent(result[1]);
    }

    private setCookie(val: string | string, maxAge: number) {
        let hostname = window.location.hostname
                             .split('.')
                             .reverse()
                             .slice(0, 2)
                             .reverse()
                             .join('.');
        let cookieString = `${encodeURIComponent(this.storageName)}=${encodeURIComponent(val)};`;
        if (maxAge) cookieString = `${cookieString}Max-Age=${maxAge};`;
        cookieString = `${cookieString}domain=${hostname};path=/;`;
        document.cookie = cookieString;
    }

    private getCookieRegExp(name: string): RegExp {
        // eslint-disable-next-line no-useless-escape
        const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1');

        return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
    }

    private cookieExists(): boolean {
        const encodeName = encodeURIComponent(this.storageName);
        const regExp = this.getCookieRegExp(encodeName);
        return regExp.test(document.cookie);
    }

}
