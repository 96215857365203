import { Injectable } from '@angular/core';
import { addLogoutEventListener } from 'BKUtils';

export interface ModalDialog {
    open(config): void;
    close(): void;
    readonly typeId: string;
}

@Injectable({ providedIn: 'root' })
export class GlobalModalService {
    private modals = new Array<ModalDialog>();

    constructor() {
        addLogoutEventListener(() => this.modals = new Array<ModalDialog>());
    }

    public add(modal: ModalDialog) {
        if (this.modals.contains(element => modal.typeId === element.typeId)) return;
        this.modals.push(modal);
    }

    public remove(modal: ModalDialog) {
        this.modals = this.modals.filter((element) => element.typeId !== modal.typeId);
    }

    public open(typeId: string, config = {}) {
        const modal = this.modals.find(element => element.typeId === typeId);
        if (modal) modal.open(config);
    }
}
