import {
    Directive,
    Output,
    EventEmitter,
    HostListener,
    ElementRef,
    Input,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[trimInput]',
    providers: [NgModel]
           })
export class TrimInputDirective
{
    @Output() valueInput: EventEmitter<string> = new EventEmitter();

    constructor(private model: NgModel, private element: ElementRef) {
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange($event: string): void
    {
        let trimmedValued = $event.trim();
        this.model.valueAccessor.writeValue(trimmedValued);
        this.element.nativeElement.value = trimmedValued;
        this.valueInput.emit(trimmedValued);
    }
}