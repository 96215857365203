import { CurrentUser } from './user';

export class BadgeCounts {
    constructor(
        public unreadMessages = 0,
        public openMatches = 0,
        public matches = 0,
        public profileVisitors = 0,
        public totalMatches = 0
    ) {
    }

    public get all(): number {
        return this.unreadMessages + this.openMatches + this.matches + this.profileVisitors;
    }

    public static create(): BadgeCounts {
        return new BadgeCounts();
    }

    public static createFromCurrentUser(data: CurrentUser): BadgeCounts {
        const badgeCounts = new BadgeCounts();
        badgeCounts.unreadMessages = data.unreadedMessages ? data.unreadedMessages : 0;
        badgeCounts.openMatches = data.openMatches ? data.openMatches : 0;
        badgeCounts.matches = data.matches ? data.matches : 0;
        badgeCounts.profileVisitors = data.profileVisitors ? data.profileVisitors : 0;
        badgeCounts.totalMatches = data.totalMatches ? data.totalMatches : 0;
        return badgeCounts;
    }

    public static createFromApi(data): BadgeCounts {
        const badgeCounts = new BadgeCounts();
        badgeCounts.unreadMessages = data.unreadedMessages ? data.unreadedMessages : 0;
        badgeCounts.openMatches = data.openMatches ? data.openMatches : 0;
        badgeCounts.matches = data.matches ? data.matches : 0;
        badgeCounts.profileVisitors = data.profileVisitors ? data.profileVisitors : 0;
        badgeCounts.totalMatches = data.totalMatches ? data.totalMatches : 0;
        return badgeCounts;
    }
}
