import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    ErrorNumber,
    ImageSize,
    MessageInterface,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import { ModalService } from 'BKService';
import * as moment from 'moment';
import { NavigationService } from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

@Component({
               selector:  'chat-msg-box',
               template:  `
                              <div class="chat-msg-box"
                                   [class.chat-msg-box--me]="isFromCurrentUser()"
                                   (click)="selectMsg()"
                              >
                                  <checkbox *ngIf="edit" [value]="selected"></checkbox>

                                  <img *ngIf="!isFromCurrentUser() && !edit"
                                       (click)="openProfileView()"
                                       class="chat-msg-box__img"
                                       [class.chat-msg-box__img--hide]="showImg"
                                       [src]="img"
                                  >
                                  <div class="chat-msg-box__bubble">
                                      <chat-bubble [text]="msg.text"
                                                   [time]="time"
                                                   [sender]="isFromCurrentUser()"
                                                   [selected]="selected"
                                                   [lasSenderMsg]="msg.lastSenderMsg"
                                                   [lastDayMsg]="msg.lastDayMsg"
                                      ></chat-bubble>
                                  </div>

                                  <icon *ngIf="isFromCurrentUser() && error"
                                        (click)="infoclick.emit($event)"
                                        class="chat-msg-box__error-icon"
                                        icon="info-outline"
                                  ></icon>

                                  <chat-reading-status *ngIf="isFromCurrentUser() && last"
                                                       [isPremium]="curUser.isPremium()"
                                                       [convRead]="convRead"
                                                       [error]="error"
                                                       (openNpLayer)="openNp()"
                                  ></chat-reading-status>
                              </div>
                          `,
               styles: [require('./chat-msg-box.scss')],
           })

export class ChatMsgBoxComponent {
    @Input() private convRead: boolean = false;
    @Input() private curUser: CurrentUser;
    @Input() private edit: boolean = false;
    @Input() private last: boolean = false;
    @Input() private msg: MessageInterface;
    @Input() private partner: ProfileInfo;
    @Input('selected') private _selected: boolean = false;
    @Input() private error: boolean;
    @Input() private errorNumebr: ErrorNumber;
    @Output() private infoclick = new EventEmitter();
    @Output() private selectedChange = new EventEmitter();

    private get selected(): boolean {
        return this._selected;
    }

    private set selected(val: boolean) {
        this._selected = val;
        this.selectedChange.emit(val);
    }

    private get img(): string {
        if (this.isFromCurrentUser()) {
            return '';
        }
        return this.partner.getProfilePictureUrl(ImageSize['70x70']);
    }

    private get showImg(): boolean {
        return !this.msg.lastSenderMsg;
    }

    private get time(): string {
        return moment
            .unix(this.msg.sendtime)
            .format('HH:mm');
    }

    public constructor(
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private modalService: ModalService) {}

    private isFromCurrentUser(): boolean {
        const sender = this.msg.senderId.toString();
        return this.msg
                   .conversationId
                   .indexOf(sender) === 0;
    }

    private openNp() {
        this.premiumService.navigateToPremium(OrderReason.ReadingState);
    }

    private openProfileView() {
        if (!this.edit) {
            this.modalService.profileView.open(this.partner.id);
        }
    }

    private selectMsg() {
        if (this.edit) {
            this.selected = !this.selected;
        }
    }
}
