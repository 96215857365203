/**
 * Created by denjas on 29.03.2017.
 */
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    Input,
} from '@angular/core';

/**
 * Komponete für die Forms im Login, Registrierung und password vergessen
 */
@Component({
               selector:   'bk-form',
               template:   `
        <div class="bk-form"
             *ngIf="active"
             [@formAnimation]="getState()"
             (@formAnimation.done)="animationDone()"
        >
            <ci-bar></ci-bar>
            <div class="bk-form__content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
               styles:  [require('./form.scss')],
               animations: [
                   trigger('formAnimation', [
                       state('first', style({})),
                       transition('void => first', style({})),
                       transition('void => next', [
                           style({
                                     transform: 'translateX(-300%)',
                                     opacity:   0,
                                 }),
                           animate('.4s .4s ease-in', style({
                                                                transform: 'translateX(0%)',
                                                                opacity:   1,
                                                            })),
                       ]),
                       transition('* => void', [
                           style({
                                     transform: 'translateX(0)',
                                     opacity:   1,
                                 }),
                           animate('.4s ease-in-out', style({
                                                                transform: 'translateX(-300%)',
                                                                opacity:   0,
                                                            })),
                       ]),
                   ]),
               ],
           })
export class Form {
    @Input() private active: boolean = true;

    @Input() private first: boolean = false;

    private animationDone() {
        this.first = false;
    }

    private getState(): string {
        return this.first ? 'first' : 'next';
    }
}
