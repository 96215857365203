import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';
import { BkModal } from './bk-modal';

@Component({
            selector: 'bk-modal-profile-picture-message',
            template: `
                      <ng-container>
                          <bk-modal #bkModal
                                    imageSource="/assets/img/bild-upload-pic.svg"
                                    [titleKey]="config.titleKey"
                                    [textKey]="config.textKey" 
                                    [actionTextKey]="config.actionTextKey"
                                    (action)="action()"
                          ></bk-modal>
                      </ng-container>
                      `,
           })
export class BkModalProfilePictureMessage implements ModalDialog {
    @ViewChild('bkModal', { static: true }) private bkModal: BkModal;

    static readonly typeId = 'BkModalProfilePictureMessage';

    static defaultConfig = {
        titleKey: "MODAL.MISSING_PROFILE_PICTURE.MESSAGE.TITLE",
        textKey: "MODAL.MISSING_PROFILE_PICTURE.MESSAGE.TEXT",
        actionTextKey: "MODAL.MISSING_PROFILE_PICTURE.MESSAGE.ACTION"
    };

    private _config = Object.assign({}, BkModalProfilePictureMessage.defaultConfig);
    public get config() {
        return this._config;
    }

    constructor(
        private navigationService: NavigationService
    ) {}

    public action() {
        this.close();
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Images });
    }

    public open(config) {
        let defaultConfigCopy = Object.assign({}, BkModalProfilePictureMessage.defaultConfig);
        this._config = Object.assign(defaultConfigCopy, config);

        this.bkModal.open();
    }

    public close() {
        this.bkModal.close();
    }

    public get typeId(): string {
        return BkModalProfilePictureMessage.typeId;
    }
}