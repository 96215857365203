import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';
import {
    CurrentUserService,
    StoreService,
} from 'BKService';
import { parseLang } from 'BKUtils';
import {
    NavigationService,
    Routes,
} from './service/navigation.service';
import { PremiumService } from './service/premium.service';
import { SessionService } from './service/session.service';
import { StayOnBetaService } from './service/stay-on-beta.service';

/**
 * Klassen zum überprüfen ob der user eingeloggt ist.
 */
@Injectable()
export class AuthenticationInternCheck implements CanActivate, CanActivateChild {
    public constructor(
        private navigationService: NavigationService,
        private sessionService: SessionService,
        private stayOnBetaService: StayOnBetaService) {
    }

    /**
     * Checked ob der Benutzer eingeloggt ist für eine Route
     {ActivatedRouteSnapshot} _route
     {RouterStateSnapshot} _state
     {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkCanActivate(route, state);
    }

    /**
     * Checked ob der Benutzer eingeloggt ist für alle ChildRoutes
     {ActivatedRouteSnapshot} _childRoute
     {RouterStateSnapshot} _state
     {boolean}
     */
    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkCanActivate(childRoute, state);
    }

    private checkCanActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const returnUrl = state.url;

        if (route.queryParams.stay) {
            const stay = route.queryParams.stay === "true";

            if (stay) {
                this.stayOnBetaService.forceStay();
            }
        }

        if (this.sessionService.isLoggedIn()) {
            return true;
        }

        const queryParams: KeyValue = {};
        if (returnUrl.length > 0) {
            queryParams.returnUrl = returnUrl;
        }
        this.navigationService.navigateTo(Routes.Login, { queryParams });
        return false;
    }
}

@Injectable()
export class AuthenticationPremiumCancel implements CanActivate {
    public constructor(private premiumService: PremiumService, private navigationService: NavigationService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.premiumService.hasSubscription()
                   .then(hasSubscription => {
                       if (!hasSubscription) this.navigationService.navigateTo(Routes.PremiumOverview);
                       return hasSubscription;
                   });
    }
}

/**
 * Checks for the LoginRoute;
 */
@Injectable()
export class AuthenticationLoginCheck implements CanActivate {
    public constructor(
        private navigationService: NavigationService,
        private storeService: StoreService,
        private currentUserService: CurrentUserService,
    ) {
    }

    /**
     * Checked ob der Benutzer eingeloggt ist für eine Route
     {ActivatedRouteSnapshot} _route
     {RouterStateSnapshot} _state
     {boolean}
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if (this.storeService.token.getValues().isEmpty()) return Promise.resolve(true);

        return this.currentUserService.loadCurrentUser()
                   .then(() => {
                       this.navigationService.navigateTo(Routes.Intern);
                       return false;
                   })
                   .catch(() => {
                       this.storeService.cleanAll();
                       return true;
                   });
    }
}

/**
 * Guard for checking if the selected Language is Supported
 */
@Injectable()
export class AuthenticationLanguageCheck implements CanActivate {
    public constructor(private translate: TranslateService, private router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const { lang } = route.params;
        if (lang) {
            const parsedLang = parseLang(lang);
            if (this.translate.getLangs().indexOf(parsedLang) === -1) {
                const newRoute = state.url.replace(`/${lang}`, `/${BkConfig.defaultLanguage}`);
                this.router.navigate([newRoute], { queryParamsHandling: "preserve" });
            }
        }
        return true;
    }
}

export const APP_RESOLVER_PROVIDERS = [
    AuthenticationInternCheck,
    AuthenticationLoginCheck,
    AuthenticationLanguageCheck,
    AuthenticationPremiumCancel,
];
