import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    ImageSize,
    ProfileInfo,
} from 'BKModels';
import {
    ModalService,
    TrackingService,
} from 'BKService';
import { growShrink } from 'BKUtils';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { SubModal } from '../../../../../shared/mat-design/modal/sub-modal';
import { ProfileMenu } from '../profile-menu/profile-menu';

enum ActionPanel {
    None,
    WriteMessage,
    NoImage,
    ImageInAudit,
    MessagingDisabled,
    GalleryNoImage,
    SearchMismatch
}

@Component({
               selector:   'profile-panel',
               template: `
                             <div class="profile-panel">
                                 <div class="profile-panel__picture">
                                     <img-with-loading-indicator [src]="profileImage"
                                                                 class="profile-panel__picture__img"
                                                                 indicatorHeight="48px"
                                                                 indicatorWidth="48px"
                                                                 (click)="galleryClick()"
                                     ></img-with-loading-indicator>
                                     <div *ngIf="currentUser.isInRegistration()" class="profile-panel__picture__overlay">
                                         <div class="profile-panel__picture__overlay__title">
                                             {{ 'PROFILE_VIEW.OVERLAY.NO_IMAGE.TITLE'|translate }}
                                         </div>
                                         <div class="profile-panel__picture__overlay__description">
                                             {{ 'PROFILE_VIEW.OVERLAY.NO_IMAGE.TEXT'|translate:{ 'username': user.nickname } }}
                                         </div>
                                         <raised-button (click)="noImageAction(trackNoImageClickProfilePicture)">
                                             {{ 'PROFILE_VIEW.OVERLAY.NO_IMAGE.ACTION'|translate }}
                                         </raised-button>
                                     </div>
                                 </div>
                                 <div class="profile-panel__wrapper">
                                     <div class="profile-panel__content">
                                         <profile-matching [user]="user" [currentUser]="currentUser" (decide)="decide.emit($event)"></profile-matching>
                                         <div class="profile-panel__content__edit" *ngIf="ownProfile">
                                             <raised-button (click)="editProfile()" class="profile-panel__content__edit__button">{{ 'PROFILE_VIEW.PANEL.EDIT_PROFILE'|translate }}</raised-button>
                                         </div>
                                     </div>
                                 </div>
                             </div>

                             <profile-panel-buttons class="profile-panel__buttons"
                                                    [user]="user"
                                                    (openGallery)="galleryClick()"
                                                    (addFavorite)="addFavorite.emit()"
                                                    (removeFavorite)="removeFavorite.emit()"
                                                    (writeMsg)="toggleMessageInput()"
                                                    [msgDisabled]="msgDisabled"
                             ></profile-panel-buttons>

                             <div *ngIf="visibleActionPanel !== ActionPanel.None" [@growShrink]="false" class="profile-panel__messages">
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.WriteMessage">
                                     <match-criteria [user]="user"></match-criteria>
                                     <text-area [placeholder]="'PROFILE_VIEW.SEND_MESSAGE.TEXT_AREA.PLACEHOLDER'| translate:user"
                                                [(value)]="message"
                                                rows="4"
                                                maxLength="2500"
                                                [disabled]="disable"
                                     ></text-area>
                                     <div class="profile-panel__messages__button">
                                         <raised-button tabindex="0" (click)="sendMessages()" [disabled]="disable">
                                             {{'PROFILE_VIEW.SEND_MESSAGE.DIALOG.ACCEPT' | translate}}
                                         </raised-button>
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.NoImage">
                                     <div class="profile-panel__no-image">
                                         <div class="profile-panel__no-image__text">
                                             {{ 'PROFILE_VIEW.SEND_MESSAGE.NO_IMAGE'|translate }}
                                         </div>
                                         <raised-button (click)="noImageAction()">
                                             {{ 'PROFILE_VIEW.SEND_MESSAGE.NO_IMAGE.ACTION'|translate }}
                                         </raised-button>
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.ImageInAudit">
                                     <div class="profile-panel__image-audit">
                                         <div class="profile-panel__image-audit__text"
                                              [innerHTML]="'PROFILE_VIEW.SEND_MESSAGE.IMAGE_AUDIT.BOX'|translate|markdown"
                                         >
                                         </div>
                                         <div class="profile-panel__image-audit__box">
                                             <icon icon="crown-outline" class="profile-panel__image-audit__box__icon"></icon>
                                             <div class="profile-panel__image-audit__box__text-content">
                                                 <div class="profile-panel__image-audit__box__text-content__title">
                                                     {{ 'PROFILE_VIEW.SEND_MESSAGE.IMAGE_AUDIT.BOX.TITLE'|translate }}
                                                 </div>
                                                 <div class="profile-panel__image-audit__box__text-content__text markdown p-nomargin markdown-a-premium"
                                                      [innerHTML]="'PROFILE_VIEW.SEND_MESSAGE.IMAGE_AUDIT.BOX.TEXT'|translate|markdown"
                                                 >
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.MessagingDisabled">
                                     <div class="profile-panel__messaging-disabled">
                                         {{ 'PROFILE_VIEW.SEND_MESSAGE.MESSAGING_DISABLED'|translate:{ 'username': user.nickname } }}
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.GalleryNoImage">
                                     <div class="profile-panel__no-image">
                                         <div class="profile-panel__no-image__text">
                                             {{ 'PROFILE_VIEW.GALLERY.NO_IMAGE'|translate:{ 'username': user.nickname } }}
                                         </div>
                                         <raised-button (click)="noImageAction(trackNoImageClickActionPanelGallery)">
                                             {{ 'PROFILE_VIEW.GALLERY.NO_IMAGE.ACTION'|translate }}
                                         </raised-button>
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="visibleActionPanel === ActionPanel.SearchMismatch">
                                     <div class="profile-panel__no-image">
                                         <div class="markdown first-last-nomargin"
                                              [innerHTML]="searchMismatchTextKey|translate:searchMismatchTranslationVars|markdown"
                                         >
                                         </div>
                                         <div class="profile-panel__no-image__actions button-bar">
                                             <flat-button (click)="ignoreAgeMismatch()">
                                                 {{ 'MODAL.WRITE_MESSAGE.ACTION.WRITE_ANYWAY'|translate }}
                                             </flat-button>
                                             <raised-button (click)="closeAgeMismatch()">
                                                 {{ 'GLOBAL.CLOSE'|translate }}
                                             </raised-button>
                                         </div>
                                     </div>
                                 </ng-container>
                             </div>
                         `,
               styles:  [require('./profile-panel.scss')],
               animations: [growShrink],
           })

export class ProfilePanel implements AfterViewInit {
    @ViewChild(ProfileMenu, { static: true }) profileMenu: ProfileMenu;
    @ViewChild('writeMsg', { static: true }) writeMsgModal: SubModal;
    @Output() decide = new EventEmitter();
    @Input() private modal: boolean;
    message: String = '';
    @Output() private openGallery = new EventEmitter();
    @Input() user = new ProfileInfo();
    @Output() private writeMsg = new EventEmitter();
    @Input() currentUser = new CurrentUser();
    @Input() msgDisabled: boolean = false;
    @Output() addFavorite = new EventEmitter();
    @Output() removeFavorite = new EventEmitter();
    @Output() private close = new EventEmitter();

    ProfileEditRouteFragment = ProfileEditRouteFragment;
    ActionPanel = ActionPanel;
    visibleActionPanel = ActionPanel.None;

    disable: boolean = false;
    private isMessageOpen: boolean = false;

    get ownProfile(): boolean {
        if (this.currentUser && this.user) return this.currentUser.id === this.user.id;
        else return false;
    }

    trackNoImageClickProfilePicture = () => {
        this.trackingService.hit('ProfilAnsehen', 'Profilbild - Onboarding MitgliedHatKeinBild', 'Klick: Btn: BildHochladen');
    };

    trackNoImageClickActionPanelGallery = () => {
        this.trackingService.hit('ProfilAnsehen', 'Profilbild - Onboarding MitgliedHatKeinBild', 'Klick: Btn: BildHochladenImNachrichtenfeld');
    };

    get profileImage(): string {
        if (this.currentUser.isInRegistration()) return this.user.getProfilePictureUrl(ImageSize['750x1000'], true);
        else return this.user.getProfilePictureUrl(ImageSize['750x1000']);
    }

    get showSearchMismatchWarning(): boolean {
        if (this.user.isMatch) return false;

        return this.currentUser.age < this.user.ageFrom
            || this.currentUser.age > this.user.ageTill
            || !(this.currentUser.gender === this.user.searchGender || this.user.searchGender === Gender.Both);
    }

    get searchMismatchTextKey(): string {
        if (this.user.searchGender === Gender.Male) return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT.MALE';
        else if (this.user.searchGender === Gender.Female) return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT.FEMALE';
        else return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT';
    }

    get searchMismatchTranslationVars() {
        return {
            username: this.user?.nickname ?? '',
            from:     (this.user?.ageFrom ?? 0).toString(),
            to:       (this.user?.ageTill ?? 0).toString(),
        };
    }

    constructor(
        private navigationService: NavigationService,
        private modalService: ModalService,
        private trackingService: TrackingService,
    ) {}

    public ngAfterViewInit(): void {
        if (this.currentUser.isInRegistration()) {
            this.trackingService.hit('ProfilAnsehen', 'Profilbild - Onboarding MitgliedHatKeinBild', 'View: Bildhochladenhinweis anstelle Profilbild');
            return;
        }
    }

    sendMessages() {
        this.writeMsg.emit(this.message);
    }

    editProfile(fragment: ProfileEditRouteFragment|null = null) {
        this.close.emit();
        if (fragment) {
            this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Images });
        } else {
            this.navigationService.navigateTo(Routes.MyProfile);
        }
    }

    toggleMessageInput() {
        let messageActionPanels = [ActionPanel.MessagingDisabled, ActionPanel.ImageInAudit, ActionPanel.NoImage, ActionPanel.WriteMessage];
        if (messageActionPanels.contains(element => element === this.visibleActionPanel)) {
            this.visibleActionPanel = ActionPanel.None;
        } else if (this.currentUser.isInRegistrationWithImage()) {
            this.visibleActionPanel = ActionPanel.ImageInAudit;
            this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben - Onboarding BildWirdNochGeprueft', 'View: Bildwirdgepruefthinweis anstelle Nachrichtenfeld');
        } else if (!this.currentUser.profileImgExist()) {
            this.visibleActionPanel = ActionPanel.NoImage;
            this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben - Onboarding MitgliedHatKeinBild', 'View: Bildhochladenhinweis anstelle Nachrichtenfeld');
        } else if (!this.user.messagingEnabled) {
            this.visibleActionPanel = ActionPanel.MessagingDisabled;
            this.trackingService.hit('ProfilAnsehen', 'NachrichtenIconGeklickt', 'View: MitgliedWillKeineNachrichten');
        } else if (this.showSearchMismatchWarning) {
            this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben', 'ageDifLayerOpen');
            this.visibleActionPanel = ActionPanel.SearchMismatch;
        } else {
            this.trackingService.hit('ProfilAnsehen', 'NachrichtenIcon', 'Klick');
            this.visibleActionPanel = ActionPanel.WriteMessage;
        }
    }

    closeAgeMismatch() {
        this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben', ' ageDifLayerCancel');
        this.visibleActionPanel = ActionPanel.None;
    }

    ignoreAgeMismatch() {
        this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben', 'ageDifLayerAccept');
        this.trackingService.hit('ProfilAnsehen', 'NachrichtenIcon', 'Klick');
        this.visibleActionPanel = ActionPanel.WriteMessage;
    }

    galleryClick() {
        if (this.visibleActionPanel === ActionPanel.GalleryNoImage) {
            this.visibleActionPanel = ActionPanel.None;
            return;
        } else if (this.currentUser.isInRegistration()) {
            this.visibleActionPanel = ActionPanel.GalleryNoImage;
            this.trackingService.hit('ProfilAnsehen', 'Profilbild - Onboarding MitgliedHatKeinBild', 'Klick: Bilder');
            return;
        } else {
            this.openGallery.emit();
        }
    }

    public closeMessageInput() {
        this.isMessageOpen = false;
    }

    public clearMessage() {
        this.message = '';
    }

    public disableMessages() {
        this.disable = true;
    }

    public enableMessages() {
        this.disable = false;
    }

    // eslint-disable-next-line no-empty-function
    public noImageAction(extra: () => void = () => {}) {
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Images });
        extra();
    }

}

