/**
 * Created by olifra on 10.04.2017.
 */

import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { AdsModule } from '../ads/ads.module';
import { InternComponentsModule } from '../intern-components';
import { LovestoryBkHomeModule } from '../lovestory';
import { Asll } from './asll/asll';
import { AsllItem } from './asll/asll-item';
import { OnBoarding } from './on-boarding/on-boarding';
import { OnBoardingItem } from './on-boarding/on-boarding-item';
import { SelectedPartner } from './selected-partner/selected-partner';
import { SingleeventsCardComponent } from './singleevents/singleevents';
import { StartpageHeader } from './startpage-header/startpage-header';
import { StartpageRouting } from './startpage.routing';

import { Startpage } from './startpage/startpage';
import { UserDailySuggestion } from './user-daily-suggestion/user-daily-suggestion.component';
import { PicturePreViwComponent } from './user-preview/picture-preview';
import { UserPreview } from './user-preview/user-preview';

@NgModule({
              imports: [
                  ShareModule,
                  LovestoryBkHomeModule,
                  InternComponentsModule,
                  StartpageRouting,
                  AdsModule,
              ],
              declarations:    [
                  Startpage,
                  Asll,
                  AsllItem,
                  OnBoarding,
                  OnBoardingItem,
                  PicturePreViwComponent,
                  SingleeventsCardComponent,
                  UserPreview,
                  UserDailySuggestion,
                  SelectedPartner,
                  StartpageHeader,
              ],
              entryComponents: [
                  Startpage,
              ],
              exports: [Startpage, UserPreview],
              providers:       [],
          })
export class StartpageModule {
}
