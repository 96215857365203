import { Component } from '@angular/core';
import { TrackingService } from 'BKService';

@Component({
               selector:  'logout-awards',
               template:  `
                              <awards (trackRequest)="trackEvent($event)"></awards>
                          `,
           })
export class LogoutAwards {

    public constructor(private trackingService: TrackingService) {
    }


    public trackEvent(event: {action: string, label: string}) {
        this.trackingService.hit('Logout', event.action, event.label);
    }
}
