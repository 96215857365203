import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { RoundProgressEase } from './round-progress.ease';

import { RoundProgressService } from './round-progress.service';


@Component({
               selector: 'round-progress',
               template: `
                             <svg xmlns="http://www.w3.org/2000/svg"
                                  [attr.viewBox]="_viewBox"
                             >
                                 <circle
                                         fill="none"
                                         [attr.cx]="radius"
                                         [attr.cy]="radius"
                                         [attr.r]="radius - stroke / 2"
                                         [style.stroke]="resolveColor(background)"
                                         [style.stroke-width]="stroke"
                                 />
                                 <path
                                         #path
                                         fill="none"
                                         [style.stroke-width]="stroke"
                                         [style.stroke]="resolveColor(color)"
                                         [style.stroke-linecap]="rounded ? 'round' : ''"
                                         [attr.transform]="getPathTransform()"
                                 />
                             </svg>
                         `,
               host:     {
                   role:                     'progressbar',
                   '[attr.aria-valuemin]':   'current',
                   '[attr.aria-valuemax]':   'max',
                   '[style.width]':          'responsive ? \'\' : _diameter + \'px\'',
                   '[style.height]':         '_elementHeight',
                   '[style.padding-bottom]': '_paddingBottom',
                   '[class.responsive]':     'responsive',
               },
               styles:   [
                       `:host {
                       display: block;
                       position: relative;
                       overflow: hidden;
                   }`,
                       `:host.responsive {
                           width: 100%;
                           padding-bottom: 100%;
                       }`,
                       `:host.responsive > svg {
                           position: absolute;
                           width: 100%;
                           height: 100%;
                           top: 0;
                           left: 0;
                       }`,
               ],
           })
export class RoundProgressComponent implements OnChanges {
    private _lastAnimationId: number = 0;

    @ViewChild('path', { static: true }) private _path;

    @Input() private animation: string = 'easeOutCubic';

    @Input() private animationDelay: number = null;

    @Input() private background: string = '#EAEAEA';

    @Input() private clockwise: boolean = true;

    @Input() private color: string = '#45CCCE';

    @Input() private current: number;

    @Input() private duration: number = 500;

    @Input() private max: number;

    @Output() private onRender: EventEmitter<number> = new EventEmitter();

    @Input() private radius: number = 125;

    @Input() private responsive: boolean = false;

    @Input() private rounded: boolean = false;

    @Input() private semicircle: boolean = false;

    @Input() private stroke: number = 15;

    /** Diameter of the circle. */
    private get _diameter(): number {
        return this.radius * 2;
    }

    /** The CSS height of the wrapper elementRef. */
    private get _elementHeight(): string {
        if (!this.responsive) {
            return `${(this.semicircle ? this.radius : this._diameter)}px`;
        }
        return '';
    }

    /** Bottom padding for the wrapper elementRef. */
    private get _paddingBottom(): string {
        if (this.responsive) {
            return this.semicircle ? '50%' : '100%';
        }
        return '';
    }

    /** Viewbox for the SVG elementRef. */
    private get _viewBox(): string {
        const diameter = this._diameter;
        return `0 0 ${diameter} ${this.semicircle ? this.radius : diameter}`;
    }

    public constructor(
        private _service: RoundProgressService,
        private _easing: RoundProgressEase,
        private _ngZone: NgZone,
        private _renderer: Renderer2,
    ) {
    }

    /** Change detection callback. */
    public ngOnChanges(changes): void {
        if (changes.current) {
            this.animateChange(changes.current.previousValue, changes.current.currentValue);
        } else {
            this._setPath(this.current);
        }
    }

    /** Sets the path dimensions. */
    private _setPath(value: number): void {
        if (this._path) {
            const arc = this._service
                            .getArc(
                                value,
                                this.max, this.radius - (this.stroke / 2),
                                this.radius, this.semicircle,
                            );

            this._renderer.setAttribute(this._path.nativeElement, 'd', arc);
        }
    }

    /** Animates a change in the current value. */
    private animateChange(inputFrom: number, inputTo: number): void {
        let from = inputFrom;
        let to = inputTo;
        if (typeof from !== 'number') {
            from = 0;
        }

        to = this.clamp(to);
        from = this.clamp(from);

        const self = this;
        const changeInValue = to - from;
        const { duration } = self;

        // Avoid firing change detection for each of the animation frames.
        self._ngZone.runOutsideAngular(() => {
            const start = () => {
                const startTime = self._service.getTimestamp();
                const id = ++self._lastAnimationId;

                requestAnimationFrame(function animation() {
                    const currentTime = Math.min(self._service.getTimestamp() - startTime, duration);
                    const value = self._easing[self.animation](currentTime, from, changeInValue, duration);

                    self._setPath(value);
                    self.onRender.emit(value);

                    if (id === self._lastAnimationId && currentTime < duration) {
                        requestAnimationFrame(animation);
                    }
                });
            };

            if (this.animationDelay > 0) {
                setTimeout(start, this.animationDelay);
            } else {
                start();
            }
        });
    }

    /** Clamps a value between the maximum and 0. */
    private clamp(value: number): number {
        return Math.max(0, Math.min(value || 0, this.max));
    }

    /** Determines the SVG transforms for the <path> node. */
    private getPathTransform(): string {
        const diameter = this._diameter;

        if (this.semicircle) {
            return this.clockwise
                ? `translate(0, ${diameter}) rotate(-90)`
                : `translate(${[diameter, diameter].join(',')}) rotate(90) scale(-1, 1)`;
        }
        if (!this.clockwise) {
            return `scale(-1, 1) translate(-${diameter} 0)`;
        }
        return '';
    }

    /** Resolves a color through the service. */
    private resolveColor(color: string): string {
        return this._service.resolveColor(color);
    }
}
