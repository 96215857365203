import { AuthenticityCheckCardLayout } from './authenticity-check-card-layout/authenticity-check-card-layout';
import { AuthenticityCheckIdcard } from './authenticity-check-idcard/authenticity-check-idcard';
import { AuthenticityCheckPhone } from './authenticity-check-phone/authenticity-check-phone';
import { AuthenticityCheckPhoto } from './authenticity-check-photo/authenticity-check-photo';

export default [
    AuthenticityCheckCardLayout,
    AuthenticityCheckIdcard,
    AuthenticityCheckPhone,
    AuthenticityCheckPhoto,
];
