import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import {
    CommunicationService,
    CurrentUserService,
    StoreService,
    TrackingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import { BadgeCounts } from '../../../../../models/badge-counts';
import { GlobalModalService } from '../../../../../service/global-modal.service';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { StayOnBetaService } from '../../../../../service/stay-on-beta.service';

/**
 * Items der Seitennavigation
 */
@Component({
               selector:  'side-nav-navigation',
               template: `
                             <nav #nav class="side-nav-navigation">
                                 <bk-side-nav-switch-item
                                         [class.stay-on-beta-hidden]="hideStayOnBeta"
                                         [(state)]="stayOnBetaService.stayOnBeta"
                                         [showLink]="!stayOnBetaService.stayOnBeta"
                                         (linkClick)="returnToLegacyLinkClick($event)">
                                     <icon glyph icon="ssl"></icon>
                                     {{'LAYOUT.SIDE_NAV.STAY_ON_BETA' | translate}}
                                     <div caption>{{'LAYOUT.SIDE_NAV.STAY_ON_BETA.DESCRIPTION' | translate}}</div>
                                     <ng-container linkContent>{{ 'LAYOUT.SIDE_NAV.STAY_ON_BETA.RETURN_TO_LEGACY'|translate }}</ng-container>
                                 </bk-side-nav-switch-item>
                                 <hr class="side-nav-navigation__seperator" [class.stay-on-beta-hidden]="hideStayOnBeta">
                                 <bk-side-nav-item [route]="Routes.Startpage"
                                                   trackingLabel="Startseite"
                                 >
                                     <icon glyph icon="home-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.STARTPAGE' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Search"
                                                   trackingLabel="Suche"
                                 >
                                     <icon glyph icon="search-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.SEARCH' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Conversation"
                                                   [badgeCount]="badgeCounts.unreadMessages"
                                                   trackingLabel="Postfach"
                                 >
                                     <icon glyph icon="chat-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.MESSAGES' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.MatchingStart"
                                                   [badgeCount]="badgeCounts.matches+badgeCounts.openMatches"
                                                   trackingLabel="Matching"
                                 >
                                     <icon glyph icon="match-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.MATCHING' | translate}}
                                 </bk-side-nav-item>
                                 <hr class="side-nav-navigation__seperator">
                                 <bk-side-nav-item [route]="Routes.ProfileVisitors"
                                                   [badgeCount]="badgeCounts.profileVisitors"
                                                   trackingLabel="Profilbesucher"
                                 >
                                     <icon glyph icon="visitor-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.VISITOR' | translate}}
                                 </bk-side-nav-item>
                                 <bk-side-nav-item [route]="Routes.Favorite"
                                                   trackingLabel="Favoriten"
                                 >
                                     <icon glyph icon="favorite-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.FAVORITE' | translate}}
                                 </bk-side-nav-item>
                                 <hr class="side-nav-navigation__seperator">
                                 <bk-side-nav-item [route]="Routes.PremiumOverview"
                                                   trackingLabel="Premium"
                                 >
                                     <icon glyph icon="crown-outline"></icon>
                                     {{'LAYOUT.SIDE_NAV.PREMIUM' | translate}}
                                 </bk-side-nav-item>
                             </nav>
                             <ad-slot slotName="BkRl_side-nav-navigation"></ad-slot>
                         `,
               styles: [require('./side-nav-navigation.scss')],
           })
export class SideNavNavigation implements OnInit {
    @Input() badgeCounts: BadgeCounts = new BadgeCounts();
    @Output() private selectRoute = new EventEmitter<Routes>();
    @ViewChild('nav', { static: true }) private navRef: ElementRef;

    get height(): string {
        return window.getComputedStyle(this.navRef.nativeElement).height;
    }

    get hideStayOnBeta(): boolean {
        return this.currentUser !== null && !!this.currentUser.testGroups.find(value => value.name.startsWith("newRegistrationBetaB"));
    }

    Routes = Routes;

    private currentUserSubscription = new Subscription();
    private currentUser: CurrentUser = null;

    public constructor(
        private globalModalService: GlobalModalService,
        private navigationService: NavigationService,
        private storeService: StoreService,
        private currentUserService: CurrentUserService,
        private communicationService: CommunicationService,
        private trackingService: TrackingService,
        public stayOnBetaService: StayOnBetaService) {
    }

    public ngOnInit(): void {
        this.currentUserSubscription = this.currentUserService
            .currentUserObservable.subscribe(value => this.currentUser = value);
    }

    returnToLegacyLinkClick(event: Event) {
        event.stopPropagation();
        this.trackingService.hit('BetaOnboarding', 'ZuruekZuAltBK', 'Klick: LinkeNavi ZuruekZumAltenBK');
        this.stayOnBetaService.returnToLegacy();
    }
}
