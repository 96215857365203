import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import { TrackingService } from 'BKService';
import * as moment from 'moment';
import {
    Subject,
    timer,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

/**
 * Platzhalter für den Fall das das Matchingkontingent aufgebraucht ist
 */
@Component({
               selector:  'bk-matching-placeholder-contingent',
               template: `
                             <card>
                                 <div class="wrapper">
                                     <div class="matching-contingent">
                                         <img src="assets/img/matching/waiting-for-new-matches.svg" class="matching-contingent__image">

                                         <div class="matching-contingent__title">
                                             {{'MATCHING.CONTINGENT.PLACEHOLDER.TITLE' | translate}}
                                         </div>
                                         <div class="matching-contingent__text">
                                             {{'MATCHING.CONTINGENT.PLACEHOLDER.SUBTITLE' | translate}}
                                         </div>
                                         <div class="matching-contingent__timer">
                                             {{tick}}
                                         </div>
                                         <separator class="matching-contingent__seperator"></separator>
                                         <div class="matching-contingent__text">
                                             {{'MATCHING.CONTINGENT.PLACEHOLDER.PREMIUM_AD' | translate}}
                                         </div>

                                         <raised-button fit-content
                                                        premium
                                                        class="matching-contingent__button"
                                                        (click)="openNpLayer()"
                                         > {{'MATCHING.CONTINGENT.PLACEHOLDER.BUTTON' | translate}}</raised-button>

                                     </div>
                                 </div>
                             </card>
                         `,
               styles: [
                   require('./matching-placeholder-contingent.scss'),
               ],
           })
export class MatchingPlaceholderContingent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject();
    private _tick: any;

    private get tick(): string {
        return this._tick.format('HH:mm:ss');
    }

    public constructor(
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private trackingService: TrackingService) {}

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public ngOnInit() {
        this.loadTimer();
    }

    private loadTimer() {
        const now = moment();
        const midnight = moment([now.get('year'), now.get('month'), now.get('date') + 1, 0, 0, 0, 0]);
        const diff = midnight.diff(now, 'seconds');
        this._tick = moment.unix(diff);

        timer(0, 1000)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this._tick.subtract(1, 'seconds');
            });

    }

    private openNpLayer() {
        this.trackingService.hit('Matching', 'Upselling', 'UnbegrenztMatchen');
        this.premiumService.navigateToPremium(OrderReason.UnlimitedMatching);
    }
}
