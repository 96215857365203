import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    HostListener,
} from '@angular/core';
import { HttpDebugService } from './http-debug.service';

@Component({
               selector: 'http-debug',
               template: `
                               <div class="http-debug"
                                    [@slideInOut]="state"
                               >
                                   <div *ngFor="let item of _httpDebug.logs| async">
                                       <http-debug-item [item]="item"></http-debug-item>
                                   </div>

                               </div>
                           `,
               styles: [
                   `
                   .http-debug {
                       border: 1px solid gray;
                       background-color: #FFF;
                       height: 100vh;
                       overflow: auto;
                       position: fixed;
                       top: 0;
                       width: 300px;
                       z-index: 10000;
                       transition: all 2s;
                   }
               `,
               ],
               animations: [
                   trigger('slideInOut', [
                       state('in', style({
                                             transform: 'translate3d(0, 0, 0)',
                                         })),
                       state('out', style({
                                              transform: 'translate3d(-100%, 0, 0)',
                                          })),
                       transition('* => *', animate('400ms ease-in-out')),
                   ]),
               ],
           })

export class HttpDebugComponent {
    private state: string = 'out';

    public constructor(private _httpDebug: HttpDebugService) {
    }

    @HostListener('document:keyup', ['$event'])
    private keyPress($event: KeyboardEvent) {
        if ($event.altKey && $event.shiftKey && $event.code === 'KeyH') {
            this.toggleState();
        }
    }

    private toggleState() {
        this.state = this.state === 'out' ? 'in' : 'out';
    }
}
