import { BkConfig } from 'BKConfig';
import {
    ApiResponseFrame,
    RegcodeValidation,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { dataUriToFile } from '../../../utils/file-converter';
import { ApiRequestService } from '../api-request.service';

/**
 * Class for the Validation apicalls
 */
export class ValidationApi {
    /**
     * constructor
     *
     {ApiRequestService} apiRequestService
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public email(email: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(
                       Endpoints.validate.email,
                       {
                           query: {
                               'email[email]': email,
                               client_id:      BkConfig.client.id,
                           },
                       },
                   );
    }

    public emailExists(email: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(
                       Endpoints.validate.emailExists,
                       {
                           query: {
                               'email':   email,
                               client_id: BkConfig.client.id,
                           },
                       },
                   );
    }

    public idcard(image: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.validate.user.idcard, {
                       body: {
                           image: dataUriToFile(image),
                       },
                   });
    }

    public idWithNonce(image: File, nonce: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.validate.user.idWithNonce, {
                       body: {
                           token: nonce,
                           checksum: '0123456789abcdef',
                           client_id: BkConfig.client.id,
                           image: image,
                       },
                   });
    }

    public photocheck(image: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.validate.user.photocheck, {
                       body: {
                           image: dataUriToFile(image),
                       },
                   });
    }

    public username(username: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.validate.username, {
                       endpointValue: { username },
                   });
    }

    public regCode(regCode: string): Promise<ApiResponseFrame<RegcodeValidation>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.validate.regCode, {
                       body: {
                           regCode,
                       },
                   });
    }
}
