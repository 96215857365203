/**
 * Array of all components in matching
 {any[]}
 */
import { MatchingFilmRoll } from './matching-film-roll/matching-film-roll';
import { MatchingFilmRollItem } from './matching-film-roll/matching-film-roll-item';
import { MatchingGame } from './matching-game/matching-game';
import { MatchingImageIndicator } from './matching-image-indicator/matching-image-indicator';
import { MatchingListHeader } from './matching-list-header/matching-list-header';
import { MatchingMatch } from './matching-match/matching-match';
import { MatchingOption } from './matching-option/matching-option';
import { MatchingUserCard } from './matching-user-card/matching-user-card';

export default [
    MatchingOption,
    MatchingListHeader,
    MatchingGame,
    MatchingMatch,
    MatchingUserCard,
    MatchingImageIndicator,
    MatchingFilmRoll,
    MatchingFilmRollItem
];
