import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { FeedbackService } from '../../../service/feedback.service';
import { ModalDialog } from '../../../service/global-modal.service';
import { NavigationService } from '../../../service/navigation.service';

import { Modal } from '../modal/modal';
@Component({
            selector: 'bk-modal-return-to-legacy',
            template: `
                      <ng-container>
                          <modal #modal no-border>
                              <div class="modal__return__title">
                                  {{ 'RETURN_TO_LEGACY.TITLE'|translate }}
                              </div>
                              <div class="modal__return__text">
                                  {{ 'RETURN_TO_LEGACY.TEXT'|translate }}
                              </div>

                              <div class="modal__return__text-area">
                                  <text-area [placeholder]="'RETURN_TO_LEGACY.PLACEHOLDER'|translate"
                                             [maxLength]="150"
                                             [autoresize]="false"
                                             [(value)]="message"
                                             rows="2"
                                  ></text-area>
                              </div>

                              <div class="modal__return__button-bar">
                                  <flat-button (click)="close()">
                                      {{ 'RETURN_TO_LEGACY.CANCEL'|translate }}
                                  </flat-button>
                                  <raised-button (click)="returnToLegacy()">
                                      {{ 'RETURN_TO_LEGACY.CONTINUE'|translate }}
                                  </raised-button>
                              </div>
                          </modal>
                      </ng-container>
                      `,
            styles: [require('./bk-modal-return-to-legacy.scss')]
           })
export class BkModalReturnToLegacy implements ModalDialog {
    @ViewChild('modal', { static: true }) private bkModal: Modal;

    static readonly typeId = 'BkModalReturnToLegacy';

    private loading: boolean;

    private message: string;

    constructor(
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private feedbackService: FeedbackService
    ) {
    }

    public open(config) {
        this.bkModal.open();
    }

    public close() {
        this.trackingService.hit('BetaOnboarding', 'ZuruekZuAltBK', 'Klick: inDialog: AbfrageZuBetaAbbrechen');
        this.bkModal.close();
    }

    public get typeId(): string {
        return BkModalReturnToLegacy.typeId;
    }

    private returnToLegacy(): void {
        this.loading = true;
        this.trackingService.hit('BetaOnboarding', 'ZuruekZuAltBK', 'Klick: inDialog: Btn: AbfrageZuBetaAbschicken');
        Promise.all([
                        this.currentUserService.stayOnBeta(false),
                        this.feedbackService.returnToLegacyFeedback(this.message)
                    ])
            .then(value => {
                this.navigationService.navigateToExternalUrl('https://www.bildkontakte.de/?mailEventSource=bildkontakte&mailEventMedium=web&mailEventTerm=button&mailEventCampaign=goToBKalt');
            })
            .finally(() => {
                this.loading = false;
            });
    }
}