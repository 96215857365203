/**
 * Created by olifra on 04.05.2017.
 */
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { ApiResponseFrame } from 'BKModels';
import { ApiService } from 'BKService';
import {
    Autocomplete,
    AutocompleteSelectItem,
} from '../autocomplete/autocomplete';

/**
 *
 * Komponente zum gerraussuchen der GeoId
 * @hideconstructor
 * @example
 * <bk-search-geolocation.api.ts formControlName="geodata"></bk-search-geolocation.api.ts>
 */
@Component({
               selector:  'geolocation',
               template:  `
                              <autocomplete #locationInput 
                                            [value]="textValue"
                                            (valueChange)="changeValue($event)"
                                            [options]="options"
                                            (completeMethod)="search($event)"
                                            [placeholder]="'REGISTRATION.PLACE' | translate"
                                            [showPremiumIcon]="showPremiumIcon"
                                            trailingIcon="location-on"
                                            (focus)="focus.emit($event)"
                              ></autocomplete>
                          `,
               providers: [
                   getValueAccessorProvider(Geolocation),
               ],
           })
export class Geolocation extends ValueAccessorBase<any> implements AfterViewInit {
    @Output() private focus = new EventEmitter();
    @Output() private change = new EventEmitter();
    @Input() private country: string;
    @Input() private showPremiumIcon: boolean = false;
    @ViewChild('locationInput', { static: true }) private locationInput: Autocomplete;

    private options: AutocompleteSelectItem[] = [];

    private _textValue: String;

    private get textValue(): String {
        return this._textValue;
    }

    private set textValue(val: String) {
        this._textValue = val;
    }

    public discardChanges(value: String) {
        this.locationInput.displayValue = value.toString();
    }

    public clearTextValue() {
        this.textValue = null;
    }

    /**
     * constructor
     {ApiService} api
     */
    public constructor(private api: ApiService) {
        super();
    }

    private changeValue(e) {
        this.writeValue(e);
        if (e && e.hasOwnProperty('displayValue')) {
            this.valueChange.emit(e.displayValue);
        }
    }

    public ngAfterViewInit() {
        this.layout();
    }


    private search(term: any) {
        const limit = 302;// max district count of city in DACH and PL

        if (typeof term !== 'string') {
            return;
        }

        this.api
            .geoLocation
            .search(this.country, term, limit)
            .then(({ data }: ApiResponseFrame<any>) => {
                this.options = data.locations
                                   .map(location => ({
                                       displayValue: (location.city === location.district ? `${location.city}` : `${location.city} - ${location.district}`),
                                       value:        location.geoId,
                                   }));

                this.options.sort(function (a, b) {
                    const leftName = a.displayValue.toUpperCase();
                    const rightName = b.displayValue.toUpperCase();

                    if (leftName < rightName) {
                        return -1;
                    }
                    if (leftName > rightName) {
                        return 1;
                    }

                    return 0;
                });
            });
    }

    public layout(): Promise<any> {
        return this.locationInput.layout().then(() => {
            this._textValue = this.value;
            return null;
        });

    }

    public clear() {
        this.clearTextValue();
        this.innerValue = null;
        setTimeout(() => this.layout());
    }
}
