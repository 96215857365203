import { AccordionTab } from './accordion-tab';
import { AccordionTabContent } from './accordion-tab-content';
import { AccordionTabHeader } from './accordion-tab-header';
import { AccordionComponent } from './accordion.component';


export default [
    AccordionComponent,
    AccordionTab,
    AccordionTabHeader,
    AccordionTabContent,
];
