import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { emojis } from './emoji';

@Component({
               selector: 'emoji-picker',
               template: `
        <div class="emoji-picker">

            <div class="emoji-picker__categories">
                <div class="emoji-picker__categories__category"
                     *ngFor="let cat of emojis"
                     [class.current]="cat.name == currentCat"
                     (click)="setCurrentCat(cat.name)"
                >
                    <p class="emoji-picker__categories__category-icon emoji-wrapper"
                       [innerHTML]="cat.icon[0] | emoji"
                    ></p>
                </div>
            </div>

            <div class="emoji-picker__emoji-holder">
                <div *ngFor="let smile of currentEmojis"
                     class="emoji-picker__emoji-holder__smiley emoji-wrapper"
                     (click)="addSmileToText(smile[0])"
                     [innerHtml]="smile[0] | emoji"
                ></div>
            </div>

            <div class="emoji-picker__drop-arrow"></div>
        </div>
    `,
               styles: [require('./emoji-picker.scss')],
           })

export class EmojiPicker {
    private emojis: any[] = emojis;

    private currentCat: string = this.emojis[0].name;

    private currentEmojis: any[] = this.emojis[0].emojis;

    // the current emojis in selected category
    @Output() private select: EventEmitter<any> = new EventEmitter();

    // ADDS SMILEY TO BOX
    private addSmileToText(smile: string): void {
        this.select.emit(smile);
    }

    // SETS THE CURRENT EMOJIS BY CATNAME
    private getCurrentEmojis(catname: string): any[] {
        return this.emojis
                   .reduce((res, cur) => (
                       cur.name == catname ? cur.emojis : res
                   ), []);
    }

    // SETS THE CURRENT CATEGORY + KEEPS CURRENT EMOJIS UP TO DATE
    private setCurrentCat(catname): void {
        this.currentCat = catname;
        this.currentEmojis = this.getCurrentEmojis(catname);
    }
}
