import { Injectable } from '@angular/core';
import {
    AchievementId,
    ApiResponseFrame,
    DecisionBatch,
    MatchingDecision,
    MatchingQueueItem,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import { addLogoutEventListener } from 'BKUtils';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';
import {
    Cache,
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';
import { ListVariant } from './api/api.service';
import { TrackingService } from './tracking/tracking.service';
import { CurrentUserService } from './user.service';

/**
 * Cached Service für das Matching
 */
@Injectable({ providedIn: 'root' })
export class MatchingService {
    private static readonly loadingLimit = 30;
    private static readonly minLengthMatchingQueue = 10;
    private decideCallRun: boolean = false;
    private queuedDecisions: DecisionBatch[] = [];
    private matchedIds = [];
    public static readonly photoFramesPreviewLimit = 5;
    private statDecisionCount = 0;

    private matchingQueueSubject = new BehaviorSubject<MatchingQueueItem[]>([]);

    public get matchingQueue(): Observable<MatchingQueueItem[]> {
        return this.matchingQueueSubject.asObservable();
    }

    private isQueueLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public get isQueueLoading(): Observable<boolean> {
        return this.isQueueLoadingSubject.asObservable();
    }

    private playedUserListSubject = new BehaviorSubject(new PlayedPartnerQueue());

    public get playUserList(): Observable<PlayedPartnerQueue> {
        return this.playedUserListSubject.asObservable();
    }

    public constructor(
        private api: ApiService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService
    ) {
        addLogoutEventListener(() => {
            this.matchedIds = [];
            this.queuedDecisions = [];
            this.matchingQueueSubject.next([]);
            this.playedUserListSubject.next(new PlayedPartnerQueue());
        });
    }

    public reload() {
        this.matchingQueueSubject.next([]);
        this.playedUserListSubject.next(new PlayedPartnerQueue());
        this.loadQueue();
    }

    /**
     * Löscht eine Entscheidung
     {number} id
     {Promise<ApiResponseFrame<any>>}
     */
    public deleteDecision(id: number): Promise<ApiResponseFrame<any>> {
        return this.api.matching.deleteDecision(id);
    }

    public get isQueueEmpty(): boolean {
        return this.matchingQueueSubject.getValue().length === 0;
    }

    /**
     * Load Matching Queue
     */
    public loadQueue() {
        this.isQueueLoadingSubject.next(true);

        this.api
            .matching
            .loadQueue(MatchingService.loadingLimit)
            .then((res: ApiResponseFrame<any>) => {
                const currentMatchingQueue = this.matchingQueueSubject.getValue();
                const currentMatchingQueueIds = currentMatchingQueue.map(cur => cur.partner.id);
                const newMatchingQueue = res.data.nextSubjects;

                const queue = currentMatchingQueue.concat(newMatchingQueue
                                                 .filter(cur => this.matchedIds.indexOf(cur.partner.id) === -1 && currentMatchingQueueIds.indexOf(cur.partner.id) === -1)
                                                 .map(cur => MatchingQueueItem.create(cur)));

                if (queue.isEmpty()) {
                    this.trackingService.hit('Matching', 'MatchingQueueLaden', 'KeineProfileMehrGefunden');
                }

                this.matchingQueueSubject.next(queue);
                this.isQueueLoadingSubject.next(false);
            });
    }

    public setDecision(partner: ProfileInfo, decision: MatchingDecision) {
        if (++this.statDecisionCount === 10) {
            this.trackingService.hit('Aktivitaet', 'Gematcht', '10Mal');
        }

        const id = partner.id;
        partner.decisionOwner = decision;
        const playedPartnerList = this.playedUserListSubject.getValue();
        playedPartnerList.enqueue(partner);

        this.playedUserListSubject.next(playedPartnerList);

        if (this.checkForMatch(partner, decision)) Cache.getInstance().delete(CacheKey.MatchPartner);
         Cache.getInstance().delete(CacheKey.MatchingYouWant, CacheKey.MatchingWantYou);

        this.currentUserService.playMatching();
        this.matchedIds.push(id);

        this.queuedDecisions.push(new DecisionBatch(id, decision));

        this.runDecisionBatch();
    }

    private checkForMatch(partner: ProfileInfo, decision: MatchingDecision): boolean {
        return (partner.decisionPartner === MatchingDecision.Yes || partner.decisionPartner === MatchingDecision.Maybe) && (decision === MatchingDecision.Yes || decision === MatchingDecision.Maybe);
    }

    public moveMatchingQueue() {
        const queue = this.matchingQueueSubject.getValue();
        queue.shift();
        this.matchingQueueSubject.next(queue);
        this.checkMatchingQueueAndLoad();
    }

    public removeMatchedItemFromQueue(user: ProfileInfo) {
        const queue = this.matchingQueueSubject.getValue();
        const index = queue.findIndex(it => it.partner.id === user.id);
        if (index !== -1) {
            queue.splice(index, 1);
            this.matchingQueueSubject.next(queue);
        }
        this.checkMatchingQueueAndLoad();
    }

    private checkMatchingQueueAndLoad() {
        if (this.matchingQueueSubject.getValue().length <= MatchingService.minLengthMatchingQueue) this.loadQueue();
    }

    private runDecisionBatch() {
        if (this.decideCallRun) return;

        this.decideCallRun = true;

        const decisions = this.queuedDecisions;
        this.queuedDecisions = [];

        this.runBatchedDecisions(decisions).then(() => {
            if (this.queuedDecisions.length !== 0) this.runDecisionBatch();
            this.decideCallRun = false;

            if (!this.queuedDecisions.isEmpty()) this.runDecisionBatch();
        });
    }

    private async runBatchedDecisions(decisions: DecisionBatch[]){
        await Promise.all(decisions.map(decision => this.api.matching.setDecision(decision.id, decision.decision)));
        const achievements = await this.currentUserService.achievements();

        if (!achievements.achieved(AchievementId.FirstTimeMatching)) await this.currentUserService.refreshAchievements();
    }

    @Cacheable(CacheKey.MatchPartner)
    public matchPartner(limit: number, offset: number = 0, variant: ListVariant = ListVariant.FirstPictureNotBlur): Promise<UserBaseView[]> {
        return this.api.matching.getMatches(limit, offset, variant)
                   .then(frame => UserBaseView.createMultiple(frame.data['matches']
                                                                  .map(match => {
                                                                      return  Object.assign(match.partner, {
                                                                          decisionOwner: parseInt(match.decisionOwner),
                                                                          decisionPartner: parseInt(match.decisionPartner)
                                                                      });
                                                                  })));
    }

    @Cacheable(CacheKey.MatchingYouWant)
    public youWant(limit: number, offset: number = 0, listVariant = ListVariant.FirstPictureNotBlur): Promise<UserBaseView[]> {
        return this.api.matching.getWantToMeet(limit, offset, listVariant);
    }

    @Cacheable(CacheKey.MatchingWantYou)
    public async wantYou(limit: number, offset: number = 0, listVariant = ListVariant.FirstPictureNotBlur): Promise<UserBaseView[]> {
        const result = await this.wantYouWithCount(limit, offset, listVariant);
        return result.wantToMeetMe;
    }

    public async wantYouWithCount(limit: number, offset: number = 0, listVariant = ListVariant.FirstPictureNotBlur): Promise<{ count: number, wantToMeetMe: UserBaseView[] }> {
        return await this.api.matching.getWantToMeetYou(limit, offset, listVariant);
    }

    public secondChance(limit: number, offset: number = 0): Promise<ProfileInfo[]> {
        return this.api.matching.getSecondChance(limit, offset);
    }

}

export class PlayedPartnerQueue {
    private array: ProfileInfo[] = [];


    public constructor() {
        this.array = new Array(MatchingService.photoFramesPreviewLimit).fill(new ProfileInfo());
    }

    public enqueue(item: ProfileInfo) {
        this.array.push(item);
        if (this.array.length > MatchingService.photoFramesPreviewLimit) {
            this.array.shift();
        }
    }

    public get queue(): ProfileInfo[] {
        return this.array.slice(0);
    }

}
