/**
 * Created by olifra on 04.07.2017.
 */

import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { MatchCriteria } from './components/match-criteria/match-criteria';
import { ProfileHeader } from './components/profile-header/profile-header';
import info from './components/profile-info';
import matching from './components/profile-matching';
import { ProfileMenu } from './components/profile-menu/profile-menu';
import panel from './components/profile-panel';
import { ProfileModal } from './profile-modal/profile-modal';
import placeholder from './profile-placeholder';

import { ProfileViewService } from './profile-view-service';
import { ProfileView } from './profile-view/profile-view';

@NgModule({
              imports:      [
                  ShareModule,
              ],
              exports:      [
                  ProfileModal,
              ],
              declarations: [
                  ProfileModal,
                  ProfileView,
                  ProfileHeader,
                  ProfileMenu,
                  ...panel,
                  ...matching,
                  ...info,
                  ...placeholder,
                  MatchCriteria,
              ],
              providers:    [ProfileViewService],
          })
export class ProfileViewModule {
}
