import { Label } from '../../../models/label';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export class ProfileEditApi {
    /**
     * constructor
     *
     {ApiRequestService} apiRequestService
     @todo parametrize endpoints (food/music/movies)
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public getAllFoodLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.food.all)
                   .then(res => res['data']['labels']);
    }

    public getSelectedFoodLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.food.fetch)
                   .then(res => res['data']['labels']);
    }

    public saveSelectedFoodLabels(labels: Label[]): Promise<boolean> {

        let body = { profile: '' };

        if (labels.length > 0) {
            body = {
                // @ts-ignore
                profile: { labels: labels.map<number>(label => label.id) },
            };
        }

        return this.apiRequestService
                   .createPost(Endpoints.profile.food.edit, { body })
                   .then(res => res['data']['state']);
    }

    public getAllMovieLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.movies.all)
                   .then(res => res['data']['labels']);
    }

    public getSelectedMovieLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.movies.fetch)
                   .then(res => res['data']['labels']);
    }

    public saveSelectedMovieLabels(labels: Label[]): Promise<boolean> {

        let body = { profile: '' };

        if (labels.length > 0) {
            body = {
                // @ts-ignore
                profile: { labels: labels.map<number>(label => label.id) },
            };
        }

        return this.apiRequestService
                   .createPost(Endpoints.profile.movies.edit, { body })
                   .then(res => res['data']['state']);
    }

    public getAllMusicLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.music.all)
                   .then(res => res['data']['labels']);
    }

    public getSelectedMusicLabels(): Promise<Label[]> {
        return this.apiRequestService
                   .createGet(Endpoints.profile.music.fetch)
                   .then(res => res['data']['labels']);
    }

    public saveSelectedMusicLabels(labels: Label[]): Promise<boolean> {

        let body = { profile: '' };

        if (labels.length > 0) {
            body = {
                // @ts-ignore
                profile: { labels: labels.map<number>(label => label.id) },
            };
        }

        return this.apiRequestService
                   .createPost(Endpoints.profile.music.edit, { body })
                   .then(res => res['data']['state']);
    }

}
