import {
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

export class ObModalBase {
    /**
     * Event emitter for close
     {EventEmitter<any>}
     * @private
     */
    @Output('close') private _close: EventEmitter<any> = new EventEmitter();

    /**
     * Event emitter for skip
     {EventEmitter<any>}
     * @private
     */
    @Output('skip') private _skip: EventEmitter<any> = new EventEmitter();

    /**
     * Flag for open the modal
     {boolean}
     * @private
     */
    @Input() private open: boolean = false;

    protected close() {
        this._close.emit();
    }

    protected skip() {
        this._skip.emit();
    }
}
