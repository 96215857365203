/**
 * Created by olifra on 06.07.2017.
 */
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalServiceBase } from 'BKBaseClass';
import { NavigationService } from '../navigation.service';
import { ProfileService } from '../profile.service';
import { LovestoryModal } from './childs/lovestory.modal';
import { ProfileViewModal } from './childs/profile-view.modal';

@Injectable({ providedIn: 'root' })
export class ModalService {
    public readonly lovestory: LovestoryModal = new LovestoryModal();
    public readonly profileView: ProfileViewModal = new ProfileViewModal(this.navigationService, this.activeRoute, this.location, this.profileService);

    public constructor(
        private navigationService: NavigationService,
        private activeRoute: ActivatedRoute,
        private location: Location,
        private profileService: ProfileService,
    ) {}
}
