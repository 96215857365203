import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    BodyType,
    ExtendedSearchCriteriaProperty,
    ExtendedSearchEnumValueObject,
    EyeColor,
    getEnumValues,
    HairColor,
} from 'BKModels';
import { range } from 'rxjs';
import { Dropdown } from '../../../../shared/mat-design/dropdown/dropdown';

@Component({
               selector:  'search-extended-look',
               template:  `
                              <search-modal-box [title]="'Größe' | translate"
                                                class="search-extended-box">
                                  <range [min]="minSizeConstraint"
                                         [max]="maxSizeConstraint"
                                         [step]="1"
                                         [tooltips]="[true, true]"
                                         [margin]="sizeDistanceConstraint"
                                         [connect]="[false, true, false]"
                                         [value]="size"
                                         (valueChange)="sizeChange.emit($event)"
                                         [attr.id]="ids.Size"
                                  ></range>
                                  
                                  <div class="search-extended-box__dropdowns">
                                      <dropdown [placeholder]="'SEARCH.EXTENDED.SIZE.FROM'|translate"
                                                [(value)]="minSize">
                                          <dropdown-option *ngFor="let s of minSizeValues" [value]="s"
                                                           [selected]="s == minSize">
                                              {{ s }} cm
                                          </dropdown-option>
                                      </dropdown>
                                      <dropdown [placeholder]="'SEARCH.EXTENDED.SIZE.TO'|translate"
                                                [(value)]="maxSize">
                                          <dropdown-option *ngFor="let s of maxSizeValues" [value]="s"
                                                           [selected]="s == maxSize">
                                              {{ s }} cm
                                          </dropdown-option>
                                      </dropdown>
                                  </div>
                              </search-modal-box>

                              <search-modal-box [title]="'PROFILE_INFO.BODYSHAPE' | translate">
                                  <search-extended-chips
                                          [translationKey]="'PROFILE_INFO.BODYSHAPE.OPTIONS.'"
                                          [value]="bodyType"
                                          (valueChange)="bodyTypeChange.emit($event)"
                                          [attr.id]="ids.BodyType"
                                  ></search-extended-chips>
                              </search-modal-box>

                              <search-modal-box [title]="'PROFILE_INFO.EYE_COLOR.LABEL' | translate">
                                  <search-extended-chips
                                          [translationKey]="'PROFILE_INFO.EYE_COLOR.OPTIONS.'"
                                          [value]="eyeColor"
                                          (valueChange)="eyeColorChange.emit($event)"
                                          [attr.id]="ids.EyeColor"
                                  ></search-extended-chips>
                              </search-modal-box>

                              <search-modal-box [title]="'PROFILE_INFO.HAIR_COLOR.LABEL' | translate">
                                  <search-extended-chips
                                          [translationKey]="'PROFILE_INFO.HAIR_COLOR.OPTIONS.'"
                                          [value]="hairColor"
                                          (valueChange)="hairColorChange.emit($event)"
                                          [attr.id]="ids.HairColor"
                                  ></search-extended-chips>
                              </search-modal-box>
                          `,
               styles: [require('./search-extended-look.scss')],
           })
export class SearchExtendedLook implements AfterViewInit {
    @ViewChildren(Dropdown) private dropdowns !: QueryList<Dropdown>;

    private ids = ExtendedSearchCriteriaProperty;

    private minSizeConstraint = 80;
    private maxSizeConstraint = 220;
    private sizeDistanceConstraint = 10;
    private get minSizeValues(): Array<number> {
        return Array.from(
            {length: (this.maxSizeConstraint - this.minSizeConstraint - this.sizeDistanceConstraint + 1)},
            (v, k) => k + this.minSizeConstraint
        );
    }
    private get maxSizeValues(): Array<number> {
        return Array.from(
            {length: (this.maxSizeConstraint - this.minSizeConstraint - this.sizeDistanceConstraint + 1)},
            (v, k) => k + this.minSizeConstraint + this.sizeDistanceConstraint
        );
    }

    @Input() private size: Array<number>;
    @Output() private sizeChange = new EventEmitter<Array<number>>();

    @Input() private bodyType:ExtendedSearchEnumValueObject;
    @Output() private bodyTypeChange = new EventEmitter<Array<boolean>>();


    @Input() private eyeColor: ExtendedSearchEnumValueObject;
    @Output() private eyeColorChange = new EventEmitter<Array<boolean>>();

    @Input() private hairColor: ExtendedSearchEnumValueObject;
    @Output() private hairColorChange = new EventEmitter<Array<boolean>>();

    private get minSize(): number {
        return this.size[0];
    }

    private set minSize(value) {
        var minSize = Number(value) || 0;
        var maxSize = this.maxSize;
        if (maxSize - minSize < this.sizeDistanceConstraint) maxSize = minSize + this.sizeDistanceConstraint;
        this.setSize(minSize, maxSize);
    }

    private get maxSize(): number {
        return this.size[1];
    }

    private set maxSize(value) {
        var minSize = this.minSize;
        var maxSize = Number(value) || 0;
        if (maxSize - minSize < this.sizeDistanceConstraint) minSize = maxSize - this.sizeDistanceConstraint;
        this.setSize(minSize, maxSize);
    }

    private setSize(minSize: number, maxSize: number) {
        this.size = Array.of(minSize, maxSize);
        this.sizeChange.emit(this.size);
    }

    public ngAfterViewInit(): void {
        setTimeout( args => this.layout, 0);
    }

    public layout(){
        this.dropdowns.forEach(it => it.layout());
    }
}
