import {
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { PaymentType } from '../../../../../models/product';

@Component({
               selector:      'premium-booking-page-footer',
               template:      `
                                  <div class="premium-booking-page-footer">
                                      <div class="premium-booking-page-footer__disclaimer">
                                          <div [innerHTML]="'PREMIUM.BOOKING.FOOTER.DISCLAIMER.AGB' | translate | markdown"></div>
                                          <div [innerHTML]="textMapping[paymentType] | translate | markdown"></div>
                                      </div>
                                      <footer-links class="premium-booking-page-footer__footer"></footer-links>
                                  </div>
                              `,
               styles:     [require('./premium-booking-page-footer.scss')],
               encapsulation: ViewEncapsulation.None,
           })
export class PremiumBookingPageFooter {
    @Input() private paymentType: PaymentType;

    private textMapping = {
        [PaymentType.Bank]: 'PREMIUM.BOOKING.FOOTER.DISCLAIMER.DEBIT',
        [PaymentType.Cash]: 'PREMIUM.BOOKING.FOOTER.DISCLAIMER.CASH',
        [PaymentType.Credit]: 'PREMIUM.BOOKING.FOOTER.DISCLAIMER.CREDIT',
        [PaymentType.Paypal]: 'PREMIUM.BOOKING.FOOTER.DISCLAIMER.PAYPAL',
    }
}
