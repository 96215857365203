import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'system-msg-layout',
               template: `
    <div class="msg-layout">
        <div class="logo-container">
            <img src="./assets/img/bk-logo-white.svg" />
        </div>
        <card class="message-card">
            <div class="message-content"><ng-content></ng-content></div>
            <div class="support-box">
                <div class="support-container">
                    <premium-support noMargin="true"></premium-support>
                </div>
            </div>
        </card>
    </div>
  `,
               styles: [require('./system-msg-layout.scss')],
           })

export class SystemMsgLayout {}
