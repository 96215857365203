/**
 * Created by olifra on 10.05.2017.
 */
import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../outer-component';
import { ChangeSucessful } from './components/change-sucessful.component';
import { InvalidLinkComponent } from './components/invalid-link';
import { PasswordRecoveryComponent } from './components/password-recovery';
import { PasswordRecovery } from './container/password-recovery';

@NgModule({
              imports:         [
                  ShareModule,
                  OuterComponentModule,
              ],
              providers:       [],
              declarations:    [
                  PasswordRecovery,
                  PasswordRecoveryComponent,
                  ChangeSucessful,
                  InvalidLinkComponent,
              ],
              entryComponents: [],
              exports:         [PasswordRecovery],
          })
export class PasswordRecoveryModule {
}
