import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'generic-modal-banner',
               template:  `
                              <div class="generic-modal-banner-container">
                                  <svg class="generic-modal-banner-bg-large" width="481px" height="96px" viewBox="0 0 481 96" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                      <title>Group 12</title>
                                      <defs>
                                          <filter x="-1.2%" y="-0.6%" width="102.3%" height="101.6%" filterUnits="objectBoundingBox" id="filter-1">
                                              <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.319383741 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                                              <feMerge>
                                                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                                  <feMergeNode in="SourceGraphic"></feMergeNode>
                                              </feMerge>
                                          </filter>
                                          <filter x="-1.5%" y="-6.2%" width="103.0%" height="117.5%" filterUnits="objectBoundingBox" id="filter-2">
                                              <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                              <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.317116477 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                                              <feMerge>
                                                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                                  <feMergeNode in="SourceGraphic"></feMergeNode>
                                              </feMerge>
                                          </filter>
                                      </defs>
                                      <g id="VIP-Aufruf" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <g id="VIP-Aufruf-Layer-[Desktop]" transform="translate(-718, -134)">
                                              <g id="Group-Copy-2" filter="url(#filter-1)" transform="translate(661, 98)">
                                                  <g id="Group-12" filter="url(#filter-2)" transform="translate(65.5531, 40)">
                                                      <polygon id="Rectangle-Copy-4" fill="#EB8C1B" points="384 16 464 16 445 48 464 80 384 80"></polygon>
                                                      <polygon id="Rectangle-Copy-5" fill="#EB8C1B" transform="translate(40, 48) scale(-1, 1) translate(-40, -48)" points="0 16 80 16 61 48 80 80 0 80"></polygon>
                                                      <polygon id="Rectangle" fill="#A45D09" points="50 42 80 42 80 80 50 64"></polygon>
                                                      <polygon id="Rectangle-Copy-6" fill="#A45D09" transform="translate(399, 61) scale(-1, 1) translate(-399, -61)" points="384 42 414 42 414 80 384 64"></polygon>
                                                      <rect id="Rectangle" fill="#F78600" x="50" y="0" width="364" height="64"></rect>
                                                  </g>
                                              </g>
                                          </g>
                                      </g>
                                  </svg>
                                  <svg class="generic-modal-banner-bg-small" width="278px" height="58px" viewBox="0 0 278 58" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                      <title>Group 12</title>
                                      <defs>
                                          <filter x="-1.8%" y="-7.7%" width="103.7%" height="119.3%" filterUnits="objectBoundingBox" id="filter-1">
                                              <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                              <feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                                              <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.317116477 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                                              <feMerge>
                                                  <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                                                  <feMergeNode in="SourceGraphic"></feMergeNode>
                                              </feMerge>
                                          </filter>
                                      </defs>
                                      <g id="VIP-Aufruf" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <g id="VIP-Aufruf-Layer-[Mobile]" transform="translate(-21, -22)">
                                              <g id="Group-12" filter="url(#filter-1)" transform="translate(24, 24)">
                                                  <polygon id="Rectangle-Copy-4" fill="#EB8C1B" points="220.190476 10.3619048 272 10.3619048 259.695238 31.0857143 272 51.8095238 220.190476 51.8095238"></polygon>
                                                  <polygon id="Rectangle-Copy-5" fill="#EB8C1B" transform="translate(25.9048, 31.0857) scale(-1, 1) translate(-25.9048, -31.0857)" points="0 10.3619048 51.8095238 10.3619048 39.5047619 31.0857143 51.8095238 51.8095238 0 51.8095238"></polygon>
                                                  <polygon id="Rectangle" fill="#A45D09" points="32.3809524 27.2 51.8095238 27.2 51.8095238 51.8095238 32.3809524 41.447619"></polygon>
                                                  <polygon id="Rectangle-Copy-6" fill="#A45D09" transform="translate(229.9048, 39.5048) scale(-1, 1) translate(-229.9048, -39.5048)" points="220.190476 27.2 239.619048 27.2 239.619048 51.8095238 220.190476 41.447619"></polygon>
                                                  <rect id="Rectangle" fill="#F78600" x="32.3809524" y="0" width="207.238095" height="41.447619"></rect>
                                              </g>
                                          </g>
                                      </g>
                                  </svg>
                                  <div class="banner-text-container">
                                      <div class="banner-text">
                                          {{ title }}
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./generic-modal-banner.scss')],
           })
export class GenericModalBanner {
    @Input() title: string;
}
