import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ApiResponseFrame,
    ErrorNumber,
} from 'BKModels';
import {
    ApiService,
    CommunicationService,
    CurrentUserService,
    TrackingService,
    ValidationService,
} from 'BKService';
import { EmailValidator } from 'BKShared/Validator';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { DialogBox } from '../../../shared/mat-design/dialog/dialog-box';
import { dataUriToFile } from '../../../utils/file-converter';
import { ImageUploadModal } from '../../../shared/mat-design/image-upload-modal/image-upload-modal';

enum RegCodeView {
    RegCode,
    SendAgain,
    ImageUpload,
    ImageUploadFinished
}

@Component({
               selector:  'reg-code',
               template: `
                             <div class="page">
                                 <communication></communication>
                                 <single-page-header [canLeave]="true" class="header"></single-page-header>
                                 <div class="content">
                                     <card class="regcode-card">
                                         <div class="container">
                                             <ng-container *ngIf="regCodeView === RegCodeView.RegCode">
                                                 <div class="title">{{ 'REGCODE.CONFIRM_EMAIL.TITLE'|translate }}</div>
                                                 <div class="info-text" [innerHtml]="'REGCODE.CONFIRM_EMAIL.TEXT'|translate|inlineMarkdown"></div>
                                                 <text-field
                                                         [(value)]="regcode"
                                                         [placeholder]="'REGCODE.CONFIRM_EMAIL.PLACEHOLDER'|translate"
                                                         [valid]="regcodeErrorText.isEmpty()"
                                                         [errorText]="regcodeErrorText|translate"
                                                         maxLength="6"
                                                         class="input-field"
                                                 ></text-field>
                                                 <div class="send-again">
                                                     <div class="send-again-text">{{ 'REGCODE.CONFIRM_EMAIL.NO_MAIL'|translate }}</div>
                                                     <a (click)="!loading && (regCodeView = RegCodeView.SendAgain)" class="send-again-link">{{ 'REGCODE.CONFIRM_EMAIL.SEND_AGAIN'|translate }}</a>
                                                 </div>
                                                 <div class="button-bar">
                                                     <raised-button (click)="confirmEmail()" [disabled]="!regcodeValid || loading" class="confirm-button">
                                                         {{ 'REGCODE.CONFIRM_EMAIL.CONFIRM'|translate }}
                                                     </raised-button>
                                                 </div>
                                             </ng-container>
                                             <ng-container *ngIf="regCodeView === RegCodeView.SendAgain">
                                                 <div class="title">{{ 'REGCODE.SEND_AGAIN.TITLE'|translate }}</div>
                                                 <div class="info-text" [innerHtml]="'REGCODE.SEND_AGAIN.TEXT'|translate|inlineMarkdown"></div>
                                                 <text-field
                                                         [(value)]="email"
                                                         [valid]="emailErrorText.isEmpty()"
                                                         [errorText]="emailErrorText|translate"
                                                         [placeholder]="'REGCODE.SEND_AGAIN.PLACEHOLDER'|translate"
                                                         class="input-field"
                                                 ></text-field>
                                                 <div class="button-bar">
                                                     <raised-button (click)="sendAgain()" [disabled]="!emailValid || loading">
                                                         {{ 'REGCODE.SEND_AGAIN.CONFIRM'|translate }}
                                                     </raised-button>
                                                     <flat-button (click)="regCodeView = RegCodeView.RegCode">{{ 'REGCODE.SEND_AGAIN.BACK'|translate }}</flat-button>
                                                 </div>
                                             </ng-container>
                                             <ng-container *ngIf="regCodeView === RegCodeView.ImageUpload">
                                                 <div class="title">{{ 'REGCODE.UPLOAD_IMAGE.TITLE'|translate }}</div>
                                                 <div class="info-text" [innerHtml]="'REGCODE.UPLOAD_IMAGE.TEXT'|translate|inlineMarkdown"></div>

                                                 <images-drag-drop class="image-upload-drop" (onDrop)="selectImage($event)"></images-drag-drop>
                                                 
                                                 <image-upload #imageUpload (selectImg)="selectImage($event)"
                                                               class="image-upload-button"
                                                               [id]="'profile-image-upload-input'"
                                                 >
                                                     {{'EDIT-IMAGES.ADD-IMAGES-BUTTON'|translate}}
                                                 </image-upload>
                                                 <hint-box [title]="'EDIT-IMAGES.HINT-BOX.TITLE'|translate" class="hint-box">
                                                     <list-checked role="content"
                                                                   [list]="['EDIT-IMAGES.HINT-BOX.HINT1'|translate,'EDIT-IMAGES.MAX-FILE-SIZE.TYPES'|translate,'EDIT-IMAGES.MAX-FILE-SIZE.SIZE'|translate]"
                                                     ></list-checked>

                                                 </hint-box>
                                                 <premium-support [showEmail]="false" class="support-box"></premium-support>
                                                 
                                                 <dialog-box #dialogUnsuitable type="warning"
                                                             [title]="'EDIT-IMAGES.DIALOG.UNSUITABLE.TITLE'|translate"
                                                             [rejectText]="'EDIT-IMAGES.DIALOG.CLOSE'|translate"
                                                 >
                                                     {{ 'EDIT-IMAGES.DIALOG.UNSUITABLE.CONTENT'|translate }}
                                                 </dialog-box>
                                                 
                                                 <image-upload-modal #imageUploadModal 
                                                                     [imageUrl]="selectedImage"
                                                                     (uploadComplete)="uploadSuccessful()"
                                                                     (onError)="uploadFailed()"
                                                 ></image-upload-modal>
                                             </ng-container>
                                             <ng-container *ngIf="regCodeView === RegCodeView.ImageUploadFinished">
                                                 <div class="title">{{ 'REGCODE.UPLOAD_IMAGE_FINISHED.TITLE'|translate }}</div>
                                                 <div class="info-text" [innerHtml]="'REGCODE.UPLOAD_IMAGE_FINISHED.TEXT'|translate|inlineMarkdown"></div>
                                                 <div class="button-bar">
                                                     <raised-button (click)="continueToStartpage()">
                                                         {{ 'REGCODE.UPLOAD_IMAGE_FINISHED.CONTINUE'|translate }}
                                                     </raised-button>
                                                 </div>
                                             </ng-container>
                                         </div>
                                     </card>
                                 </div>
                                 <bk-footer-out class="footer"></bk-footer-out>
                             </div>
                         `,
               styles: [require('./regcode.scss')],
           })

export class RegCode implements OnInit {
    RegCodeView = RegCodeView
    private _regcode = "";
    regcodeErrorText = "";
    email = "";
    emailErrorText = "";
    loading = false;

    selectedImage = null;

    @ViewChild('imageUploadModal', { static: false }) private imageUploadModal: ImageUploadModal;

    @ViewChild('dialogUnsuitable', { static: true }) private dialogUnsuitable: DialogBox;

    _regCodeView = RegCodeView.RegCode;
    get regCodeView(): RegCodeView {
        return this._regCodeView;
    }

    set regCodeView(v: RegCodeView) {
        this._regCodeView = v;
        if (v === RegCodeView.SendAgain) {
            this.regcodeErrorText = "";
            this.emailErrorText = "";
        }
    }

    get regcode(): string {
        return this._regcode.replace(/\D+/g, '');
    }

    set regcode(v: string){
        this._regcode = v.replace(/\D+/g, '');
    }

    get regcodeValid(): boolean {
        const result = !!this.regcode.match(/^\d{1,6}$/);

        if( !result && this.regcode !== '' ){
            this.regcodeErrorText = 'REGCODE.CONFIRM_EMAIL.INVALID';
        }else if ( result && this.regcodeErrorText === 'REGCODE.CONFIRM_EMAIL.INVALID'){
            this.regcodeErrorText = '';
        }

        return result;
    }

    get emailValid(): boolean {
        return !!this.email.match(EmailValidator.emailRegex);
    }

    confirmEmail() {
        this.loading = true;
        this.regcodeErrorText = "";

        this.validationService.validateRegCode(this.regcode)
            .then(value => {
                if (!value.state) throw new Error('regcode error');
                this.currentUserService.loadCurrentUser();
                this.trackingService.hit('regcode', 'doubleOptin', 'correct');
                this.regCodeView = RegCodeView.ImageUpload;
            })
            .catch((response: ApiResponseFrame<any>) => {
                if(response.errno === ErrorNumber.EMAIL_ALREADY_VALIDATED){
                    this.regcodeErrorText = "REGCODE.EMAIL_ALREADY_VALIDATED";
                }else{
                    this.regcodeErrorText = "REGCODE.CONFIRM_EMAIL.ERROR";
                    this.trackingService.hit('regcode', 'doubleOptin', 'wrong');
                }
            })
            .finally(() => {
               this.loading = false;
            });
    }

    sendAgain() {
        this.loading = true;

        this.apiService.user.resendRegCode(this.email)
            .then(value => {
                if (value.error) throw value.error_description.pop();
                if (!value.data.state) throw new Error('email error');

                this.regCodeView = RegCodeView.RegCode;
                this.communicationService.growl.regCode.resend.handleSuccess(this.email);
            }).catch((response: ApiResponseFrame<any>) => {
                if(response.errno === ErrorNumber.EMAIL_ALREADY_VALIDATED){
                    this.emailErrorText = "REGCODE.EMAIL_ALREADY_VALIDATED";
                }else{
                    this.emailErrorText = "REGCODE.SEND_AGAIN.ERROR";
                }
            }).finally(() => {
                this.loading = false;
            });
    }


    selectImage(event) {
        const imageData = dataUriToFile(event);
        const maxFileSize = 12582912;//Server allow maximum 12MB files

        if (imageData.content.size > maxFileSize || imageData.content.type.substring(0, 5) !== 'image') {
            this.dialogUnsuitable.open();
            return;
        }

        this.selectedImage = event;
        this.imageUploadModal.open();
    }

    uploadFailed() {
        this.trackingService.hit('regcode', 'BildHochladen', 'BildHochladenFehlgeschlagen');
        this.dialogUnsuitable.open();
    }

    uploadSuccessful() {
        this.trackingService.hit('regcode', 'BildHochladen', 'BildHochgeladen');
        this.regCodeView = RegCodeView.ImageUploadFinished;
    }

    continueToStartpage() {
        this.trackingService.hit('regcode', 'BildHochladen', 'WeiterZurStartseite');
        this.navigationService.navigateTo(Routes.Startpage);
    }

    public ngOnInit(): void {
        const url = new URL(this.navigationService.url, 'http://dummy/');

        if (url.searchParams.has('regcode')) {
            this.regcode = url.searchParams.get('regcode');
            this.confirmEmail();
        }
    }

    constructor(
        private validationService: ValidationService,
        private navigationService: NavigationService,
        private communicationService: CommunicationService,
        private apiService: ApiService,
        private trackingService: TrackingService,
        private currentUserService: CurrentUserService
    ) {}

}
