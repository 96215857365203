/* eslint-disable camelcase */


export interface News {
    birthday_reminder_news: BirthdayReminderNews[];
    failed_login_news: FailedLoginNews;
    image_news: ImageNews[];
    message_news: MessageNews;
    premium_news: PremiumNews;
    profile_visitor_news: ProfileVisitorNews;
}

export interface FailedLoginNews {
    failed_login_count: number
}

export interface PremiumNews {
    expire_date: Date
}

export interface ImageNews {
    name: string
    status: number

}

export interface MessageNews {
    unread_message_count: number
}

export interface BirthdayReminderNews {
    birthday: Date
    username: string
}

export interface ProfileVisitorNews {
    profile_visitor_count: number
}
