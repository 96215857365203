import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import { Offer } from '../../../models/offer';
import { Tariff } from '../../../models/tariff';
import { NavigationService } from '../../../service/navigation.service';
import { PaymentService } from '../../../service/payment.service';

@Component({
               selector:  'loyalty-offer',
               template:  `
                              <div class="loyalty-offer">
                                  <div class="loyalty-offer__filter"></div>
                                  <div class="loyalty-offer-header">
                                      <div class="loyalty-offer-header__title">
                                          <img class="loyalty-offer-header__banner" src="./assets/img/modal/loyalty-banner.svg"/>
                                          <div class="loyalty-offer-header__title__description">
                                              <div class="loyalty-offer__text--bold" [innerText]="'OFFER.LOYALTY_OFFER.MODAL.DESCRIPTION.TITLE'|translate:{'username': username}"></div>
                                              <p [innerText]="'OFFER.LOYALTY_OFFER.MODAL.DESCRIPTION.TEXT'|translate"></p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="loyalty-offer__box">
                                      <div class="loyalty-offer__box__title">{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.TITLE'|translate }}</div>
                                      <div class="loyalty-offer__box__price">{{ offerTariff.formattedPrice }}</div>
                                      <div class="loyalty-offer__box__subtitle">{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.SUBTITLE'|translate:{'percentDiscount': discount} }}</div>
                                      <div class="loyalty-offer__box__usp-title">{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.USP_TITLE'|translate }}</div>
                                      <usp-item>{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.USP_1'|translate }}</usp-item>
                                      <usp-item>{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.USP_2'|translate }}</usp-item>
                                      <usp-item>{{ 'OFFER.LOYALTY_OFFER.MODAL.BOX.USP_3'|translate }}</usp-item>
                                      <deko-star class="star">
                                          <h3 class="star-text">{{discount}}%</h3>
                                          <span>gespart</span>
                                      </deko-star>
                                  </div>
                                  <div class="loyalty-offer__button-panel">
                                      <raised-button class="welcome-offer-option__content__cta"
                                                     premium
                                                     (click)="goToPremium()"
                                      >{{ 'OFFER.LOYALTY_OFFER.CTA.TEXT'|translate }}</raised-button>
                                  </div>
                              </div>
                          `,
               styles: [require('./loyalty-offer.scss')],
           })
export class LoyaltyOffer {
    @Input() username: string;
    @Input() private offer: Offer;
    @Output() private close: EventEmitter<any> = new EventEmitter();
    offerTariff: Tariff = Tariff.empty;
    private expensivePremiumProduct: Tariff = Tariff.empty;

    constructor(
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    get discount(): string {
        const discount = this.expensivePremiumProduct.getDiscount(this.offerTariff);
        if (isNaN(discount)) return '--';
        return Math.floor(discount).toString();
    }

    ngOnInit() {
        this.paymentService.tariff(this.offer.tariffId).then(res => this.offerTariff = res);
        this.paymentService.mostExpensivePremiumProduct.then(res => this.expensivePremiumProduct = res);
    }

    goToPremium() {
        this.trackingService.hit('TreueAngebotslayer', 'AngebotSichernGeklickt', 'geklickt');
        this.navigationService.navigateToPurchasePage(this.offer.tariffId, this.offer.orderReason, this.offer.id);
    }
}