/**
 * Created by olifra on 09.06.2017.
 */
import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../outer-component';
import { SendRecoveryEmailComponent } from './container/send-recovery-mail.component';

@NgModule({
              imports:      [
                  ShareModule,
                  OuterComponentModule,
              ],
              exports:      [SendRecoveryEmailComponent],
              declarations: [SendRecoveryEmailComponent],
              providers:    [],
          })
export class SendRecoveryMailModule {
}
