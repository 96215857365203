import {
    Component,
    Input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
               selector:      'profile-info-item',
               template:      `
    <div class="mdc-layout-grid profile-info-item">
      <div class="profile-info-item mdc-layout-grid__inner">
        <div class="profile-info-item__title mdc-layout-grid__cell--span-4-phone  mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-6-desktop">
          <icon *ngIf="!icon.isEmpty()" class="profile-info-item__icon" [icon]="icon"></icon>
          {{title}}:
        </div>
        <div class="profile-info-item__text mdc-layout-grid__cell--span-4-phone  mdc-layout-grid__cell--span-4-tablet mdc-layout-grid__cell--span-6-desktop">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
               styles:     [require('./profile-info-item.scss'), require('../profile-info.scss')],
               encapsulation: ViewEncapsulation.None,
           })

export class ProfileInfoItem {
    @Input() private icon: string = '';
    @Input() private title: string;
}
