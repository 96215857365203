/**
 * Created by olifra on 10.07.2017.
 */


import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';


/**
 * Komponete für Subheader
 */
@Component({
               selector: 'subheader-menu',
               template: `
        <subheader>
            <div class="subheader-menu"
                 [class.subheader-menu--tabs]="tabs">
                <back-arrow *ngIf="showBack" (back)="this.back.emit($event)"></back-arrow>
                <ng-content></ng-content>
                <div *ngIf="showMenu" class="subheader-menu__more">
                    <menu>
                        <ng-content select="subheader-menu-items"></ng-content>
                    </menu>
                </div>
            </div>
        </subheader>
    `,
               styles: [require('./subheader-menu.scss')],
           })
export class SubheaderMenu {
    @Output() private back: EventEmitter<any> = new EventEmitter();

    @Input() private showBack: boolean = false;

    @Input() private showMenu: boolean = false;

    @Input() private tabs: boolean = false;
}
