import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Modal } from '../modal/modal';

@Component({
    selector: 'bk-modal',
    template: `
                <modal #modal
                       no-border
                       [closeIcon]="closeIcon"
                       [backgroundClose]="closeViaBackdrop"
                       (onClose)="onClose.emit()">
                    <div class="bk-modal__content">
                        <img class="bk-modal__content__img" [src]="imageSource"/>
                        <div class="bk-modal__content__title">{{ titleKey|translate }}</div>
                        <div class="bk-modal__content__text markdown first-last-nomargin checkbox-list" [innerHTML]="textKey|translate|markdown"></div>
                        <raised-button class="bk-modal__content__action" (click)="action.emit()">
                            {{ actionTextKey|translate }}
                        </raised-button>
                        <a *ngIf="closeViaLink" class="bk-modal__content__close" (click)="close()">
                            {{ closeTextKey|translate }}
                        </a>
                    </div>
                </modal>
              `,
    styles: [require('./bk-modal.scss')]
           })
export class BkModal {
    @ViewChild('modal', { static: true }) private modal: Modal;

    @Input() public imageSource: string;
    @Input() public titleKey: string;
    @Input() public textKey: string;
    @Input() public actionTextKey: string;
    @Input() public closeTextKey: string = "ACTION.LATER";

    @Input() public closeIcon: boolean = false;
    @Input() public closeViaBackdrop: boolean = true;
    @Input() public closeViaLink: boolean = true;

    @Output() public action = new EventEmitter();
    @Output() public onClose = new EventEmitter();

    public open() {
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }
}