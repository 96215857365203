import {
    Component,
    OnInit,
} from '@angular/core';
import { FavoriteDataSource } from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';

@Component({
               selector: 'favorite-exist',
               template: `<favorite-list [listDataSource]="dataSource"></favorite-list>`,
           })

export class FavoriteExist implements OnInit {
    private dataSource: FavoriteDataSource;

    public constructor(private dataSourceFactory: DataSourceFactory) {
    }

    public ngOnInit() {
        this.dataSourceFactory.createFavoriteDataSource().then(dataSource => {
            this.dataSource = dataSource;
            this.dataSource.loadMore();
        });
    }
}
