/* eslint no-console: off */
/*
 * Created by olifra on 07.09.2017.
 */
/**
 * Logger Klasse
 */
export class Logger {
    /**
     * Logt inst Level debug
     msg {Array}
     */
    public static debug(...msg: any[]) {
        console.debug(...msg);
    }

    /**
     * Logt inst Level error
     msg {Array}
     */
    public static error(...msg: any[]) {
        console.error(...msg);
    }

    /**
     * Logt inst Level info
     msg {Array}
     */
    public static info(...msg: any[]) {
        console.info(...msg);
    }

    /**
     * Logt inst Level trace
     msg {Array}
     */
    public static trace(...msg: any[]) {
        console.trace(...msg);
    }

    /**
     * Logt inst Level warn
     msg {Array}
     */
    public static warn(...msg: any[]) {
        console.warn(...msg);
    }

    private static stringify(msg: any[]): string {
        return msg.join(' ');
    }
}

/**
 * Interface für den Logger
 * Ist nötig sonst kann TypeScript die Static function nicht auflösen
 */
export interface ILogger {
    /**
     * Logt inst Level debug
     * @param msg {Array}
     */
    debug(...msg: any[]): void;

    /**
     * Logt inst Level error
     * @param msg {Array}
     */
    error(...msg: any[]): void;

    /**
     * Logt inst Level info
     * @param msg {Array}
     */
    info(...msg: any[]): void;

    /**
     * Logt inst Level trace
     * @param msg {Array}
     */
    trace(...msg: any[]): void;

    /**
     * Logt inst Level warn
     * @param msg {Array}
     */
    warn(...msg: any[]): void;
}
