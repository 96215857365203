import {
    Component,
    OnInit,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import {
    CurrentUserService,
} from 'BKService';
import { OpenInvoice } from '../../../../../models/open-invoice';
import { PaymentService } from '../../../../../service/payment.service';

@Component({
               selector:  'premium-reverse-debit',
               template:  `
                            <ng-container *ngIf="invoice && currentUser">
                                
                              <hint-box type="error">
                                  <ng-container role="content">
                                      <div [innerHTML]="'PREMIUM.REVERSE-DEBIT.BOX.TEXT' | translate:{
                                            date: invoice.formatDate,
                                            price: invoice.formatPrice,
                                            reference: reference
                                          }|markdown">
                                      </div>
                                      <pay-with-qr
                                              bic="WELADEDLLEV"
                                              iban="DE02375514400107007411"
                                              recipient="Entrex e.K."
                                              [reference]="reference"
                                              [value]="invoice.total"
                                      ></pay-with-qr>
                                  </ng-container>
                              </hint-box>
                            </ng-container>
                          `,
               styles: [require('./premium-reverse-debit.scss')],
           })

export class PremiumReverseDebit implements OnInit {
    protected invoice: OpenInvoice = null;
    protected currentUser: CurrentUser = null;

    protected get reference(): string {
        if (!this.currentUser) return '';
        return `RLE${this.currentUser.id} ${this.currentUser.nickname}`;
    }

    public constructor(
        private paymentService: PaymentService,
        protected currentUserService: CurrentUserService,
    ) {
    }

    public ngOnInit(): void {
        this.paymentService.openInvoices().then(invoice => this.invoice = invoice);
        this.currentUserService.currentUser.then(currentUser => this.currentUser = currentUser);
    }

}
