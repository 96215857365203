import { AbstractControl } from '@angular/forms';
const bigInt = require("big-integer");

/**
 * Validator für das Password
 */
export class IbanValidator {
    private static readonly ibanLengths = {
        'AD': 24,
        'AE': 23,
        'AL': 28,
        'AO': 25,
        'AT': 20,
        'AZ': 28,
        'BA': 20,
        'BE': 16,
        'BF': 27,
        'BG': 22,
        'BH': 22,
        'BI': 16,
        'BJ': 28,
        'BR': 29,
        'BY': 28,
        'CF': 27,
        'CG': 27,
        'CH': 21,
        'CI': 28,
        'CM': 27,
        'CV': 25,
        'CR': 22,
        'CY': 28,
        'CZ': 24,
        'DE': 22,
        'DK': 18,
        'DO': 28,
        'DZ': 24,
        'EE': 20,
        'EG': 27,
        'ES': 24,
        'FI': 18,
        'FO': 18,
        'FR': 27,
        'GA': 27,
        'GB': 22,
        'GE': 22,
        'GI': 23,
        'GL': 18,
        'GR': 27,
        'GT': 28,
        'HR': 21,
        'HU': 28,
        'IE': 22,
        'IL': 23,
        'IQ': 23,
        'IR': 26,
        'IS': 26,
        'IT': 27,
        'JO': 30,
        'KW': 30,
        'KZ': 20,
        'LB': 28,
        'LC': 32,
        'LI': 21,
        'LT': 20,
        'LU': 20,
        'LV': 21,
        'MC': 27,
        'MD': 24,
        'ME': 22,
        'MG': 27,
        'MK': 19,
        'ML': 28,
        'MR': 27,
        'MT': 31,
        'MU': 30,
        'MZ': 25,
        'NL': 18,
        'NO': 15,
        'PK': 24,
        'PL': 28,
        'PS': 29,
        'PT': 25,
        'QA': 29,
        'RO': 24,
        'RS': 22,
        'SA': 24,
        'SC': 31,
        'SE': 24,
        'SI': 19,
        'SK': 24,
        'SM': 27,
        'SN': 28,
        'ST': 25,
        'SV': 28,
        'TL': 23,
        'TN': 24,
        'TR': 26,
        'UA': 29,
        'VA': 22,
        'VG': 24,
        'XK': 20,
    };

    private static splitIban(iban: string) {
        let country = null;
        let checksum = null;
        let account = null;

        if (iban.length >= 2) country = iban.substr(0, 2);
        if (iban.length >= 4) checksum = iban.substr(2, 2);
        if (iban.length >= 5) account = iban.substr(4).split('')
                                            .map(cur => {
                                                if (isNaN(cur)) {
                                                    return this.charToNumber(cur);
                                                }
                                                return cur;
                                            }).join('');

        return { country, checksum, account };
    }

    private static encodeCountry(country: String) {
        return IbanValidator.charToNumber(country.substr(0, 1)) + IbanValidator.charToNumber(country.substr(1, 1));
    }

    private static charToNumber(char) {
        const searchTable = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return (searchTable.indexOf(char) + 10).toString(10);
    }

    public static validate(control: AbstractControl): KeyValue {
        let iban = (control.value as string).toUpperCase().replace(/\s/g, '');

        let { country, checksum, account } = IbanValidator.splitIban(iban);

        if (!country || iban.length !== IbanValidator.ibanLengths[country]) {
            return { 'ERROR.VALIDATOR.IBAN.LENGTH': true };
        }

        if (!IbanValidator.ibanLengths[country]) {
            return { 'ERROR.VALIDATOR.IBAN.UNKNOWN_COUNTRY': true };
        }

        let ibanCheckValue = bigInt(`${account}${IbanValidator.encodeCountry(country)}${checksum}`);
        if (ibanCheckValue.mod(97).notEquals(1)) {
            return { 'ERROR.VALIDATOR.IBAN.CHECKSUM': true };
        }

        return null;
    }
}
