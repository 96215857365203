import { Injectable } from '@angular/core';
import { Settings } from 'BKModels';
import { addLoginEventListener } from 'BKUtils';
import { Observable } from 'rxjs';
import { ApiService } from './api/api.service';
import { CommunicationService } from './communication/communication.service';
import { StoreService } from './storage/store.service';

@Injectable()
export class SettingsService {
    public settings: Observable<Settings> = this.storeService.settings.get();

    public get cachedSettings(): Settings {
        return this.storeService.settings.getValues();
    }

    public constructor(private api: ApiService, private communicationService: CommunicationService, private storeService: StoreService) {
        addLoginEventListener(() => this.loadSettings());
    }

    public loadSettings() {
        Promise.all([this.api.settings.get(), this.api.settings.push.get()])
               .then(([settings, pushSettings]) => {
                   const newSettings = this.storeService.settings.getValues();
                   newSettings.addSettings(settings);
                   newSettings.addPushSettings(pushSettings);
                   this.storeService.settings.next(newSettings);
               });
    }

    public sendToApi(curSettings: Settings) {
        if (!this.storeService.settings.getValues().equal(curSettings)) {
            const settings = this.api.settings.patch(curSettings.createApiBody());
            const pushSettings = this.api.settings.push.post(curSettings.pushSettings);
            Promise.all([settings, pushSettings])
                   .then(() => {
                       this.changeSettings(curSettings);
                       this.communicationService.growl.settings.changeSettings.handleSuccess();
                   })
                .catch(() => this.communicationService.growl.settings.changeSettings.handleError());
        }
    }


    public changeSettings(curSettings: Settings) {
        this.storeService.settings.next(curSettings);
    }


    public changePassword(oldPassword: string, newPassword: string, newPasswordRepetition: string): Promise<boolean> {
        return this.api.password
                   .change(oldPassword, newPassword, newPasswordRepetition)
                   .then(res => {
                       this.communicationService.growl.settings.changePassword.handleSuccess();
                       return res.data;
                   })
                   .catch((err) => {
                       this.communicationService.growl.settings.changePassword.handleError();
                       throw err;
                   });
    }
}
