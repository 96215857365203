import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderReason } from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { TimeoutError } from 'rxjs';
import { Offer } from '../../../../../models/offer';
import {
    PaymentType,
    Product,
} from '../../../../../models/product';
import { Tariff } from '../../../../../models/tariff';
import {
    NavigationService,
    PremiumLandingPageFragment,
    QueryParameter,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { OfferService } from '../../../../../service/offer.service';
import { PaymentService } from '../../../../../service/payment.service';
import { ECommerceTrackingService } from '../../../../../service/tracking/e-commerce-tracking.service';

@Component({
               selector:  'premium-booking-page',
               template: `
                             <div class="premium-booking-page">
                                 <premium-booking-page-header></premium-booking-page-header>

                                 <div class="premium-booking-page__content">
                                     <div class="premium-booking-page__title">{{'PREMIUM.BOOKING.TITLE' | translate}}</div>

                                     <div class="premium-booking-page__steps-list">
                                         <div class="premium-booking-page__step premium-booking-page__step--tariff">
                                             <premium-booking-page-step-title class="premium-booking-page__step__title"
                                                                              [title]="'PREMIUM.BOOKING.STEP_1.TITLE' | translate"
                                                                              [done]="true"
                                             ></premium-booking-page-step-title>
                                             <premium-booking-page-offer
                                                     [percentSaved]="getDiscount(tariff)"
                                                     [tariff]="tariff"
                                                     [specialOffer]="offer"
                                                     (onClick)="changeOffer()"
                                             ></premium-booking-page-offer>

                                             <premium-support class="premium-booking-page__support"></premium-support>

                                             <awards [full]="false" class="awards-side"></awards>
                                         </div>

                                         <div class="premium-booking-page__step premium-booking-page__step--payment">
                                             <premium-booking-page-step-title class="premium-booking-page__step__title"
                                                                              [title]="'PREMIUM.BOOKING.STEP_2.TITLE' | translate"
                                                                              [stepNumber]="2"
                                             ></premium-booking-page-step-title>

                                             <premium-booking-page-payment class="premium-booking-page__payments"
                                                                           [currentUser]="currentUserService.currentUserObservable | async"
                                                                           [tariff]="tariff"
                                                                           [orderReason]="orderReason"
                                                                           [offerId]="offerId"
                                                                           [(currentPaymentType)]="paymentType"
                                             ></premium-booking-page-payment>


                                             <banner-selector *ngIf="!!offer"
                                                              class="premium-booking-page__banner"
                                                              [offer]="offer"
                                                              [small]="true"
                                             ></banner-selector>
                                         </div>
                                     </div>
                                     
                                     <premium-landing-page-testimonials></premium-landing-page-testimonials>
                                     <awards [full]="false" class="awards-page"></awards>
                                     
                                     <premium-booking-page-footer [paymentType]="paymentType"></premium-booking-page-footer>
                                 </div>
                             </div>
                         `,
               styles: [require('./premium-booking-page.scss')],
           })
export class PremiumBookingPage implements OnInit {
    private expensivePremiumProduct: Product;
    paymentType: PaymentType = PaymentType.Bank;
    tariff: Tariff = new Tariff();
    orderReason: OrderReason = OrderReason.None;
    offerId: string = null;
    offer: Offer;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        public currentUserService: CurrentUserService,
        private eCommerceTrackingService: ECommerceTrackingService,
        private trackingService: TrackingService,
        private offerService: OfferService,
    ) {
    }

    ngOnInit(): void {
        const activeRoute = this.activatedRoute.snapshot;
        const tariffId = activeRoute.params[RouteParameter.TariffId];

        this.orderReason = OrderReason.fromActivatedRoute(this.activatedRoute);
        this.offerId = this.getOfferId();

        this.paymentService.tariff(tariffId)
            .then(tariff => {
                this.tariff = tariff;
                this.eCommerceTrackingService.trackProductDetail(tariff, this.orderReason);
            })
            .catch(err => this.navigationService.navigateTo(Routes.PremiumLandingPage));

        this.paymentService.mostExpensivePremiumProduct
            .then(res => this.expensivePremiumProduct = res);

        this.offerService.loadOffer().then(offers => {
            const filtered = offers.filter(it => it.orderReason === this.orderReason);
            if (!filtered.isEmpty()) {
                this.offer = filtered[0];
            }
        });
        this.offerService.triggerCanceledPurchaseOffer().catch(reason => {
            if (reason instanceof TimeoutError) return;
            else throw reason;
        });
    }

    changeOffer() {
        this.trackingService.hit('Premium', 'ZahlungsartSeite', 'AuswahlAendern');

        this.navigationService.navigateTo(Routes.PremiumLandingPage, {
            fragment: PremiumLandingPageFragment.Offers,
        });
    }

    getDiscount(offer) {
        if (this.expensivePremiumProduct) {
            return this.expensivePremiumProduct.getDiscount(offer);
        }
        return 0;
    }

    private getOfferId(){
        return this.activatedRoute.snapshot.queryParamMap.get(QueryParameter.OfferId);
    }
}
