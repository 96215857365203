import {
    Component,
    Input,
} from '@angular/core';
import { LovestoryListItem } from 'BKModels';
import { ModalService } from 'BKService';

@Component({
               selector:  'lovestory-item',
               template:  `
                              <div class="lovestory-item"
                                   (click)="openLovestory()">
                                  <card>
                                      <img [src]="item.mainImageUrl"
                                           class="lovestory-item__img"
                                      >
                                      <div class="lovestory-item__content">
                                          <div class="lovestory-item__content__title">
                                              {{item.nameMale}} & {{item.nameFemale}}
                                          </div>
                                          <div class="lovestory-item__content__text">
                                              {{item.summary | escape}}
                                          </div>


                                          <div class="lovestory-item__footer">
                                              <raised-button class="lovestory-item__button"
                                              >{{'LOVESTORY.LIST.ITEM.OPEN'| translate}}</raised-button>
                                          </div>
                                      </div>
                                  </card>
                              </div>
                          `,
               styles: [require('./lovestory-item.scss')],
           })

export class LovestoryItem {
    @Input() private item: LovestoryListItem;


    public constructor(private modalService: ModalService) {

    }

    private openLovestory() {
        this.modalService.lovestory.open(this.item.id);
    }
}
