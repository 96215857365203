import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';
import { MailSettingsProfileVisitor } from './mail-settings-profile-visitor/mail-settings-profile-visitor';
import { MailSettingsUserSuggestion } from './mail-settings-user-suggestion/mail-settings-user-suggestion';
import { MailSettingsWeeklySummary } from './mail-settings-weekly-summary/mail-settings-weekly-summary';
const premiumRoute: NgRoutes = [
    {
        path:     '',
        children: [{
            path:      '',
            component: InternDefaultHeader,
            outlet:    'header', data: { title: 'LAYOUT.SIDE_NAV.PREMIUM' },
        }, {
            path:      '',
            component: MailSettingsWeeklySummary,
        }, {
            path:        routeName(Routes.MailSettingsWeeklySummary),
            component:   MailSettingsWeeklySummary,
            canActivate: [],
        }, {
            path:        routeName(Routes.MailSettingsProfileVisitor),
            component:   MailSettingsProfileVisitor,
            canActivate: [],
        }, {
            path:        routeName(Routes.MailSettingsUserSuggestion),
            component:   MailSettingsUserSuggestion,
            canActivate: [],
        },
        ],
    },
];

export const MailSettingsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(premiumRoute);
