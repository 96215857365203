import { Injectable } from '@angular/core';
import {
    ProfileInfo,
    Settings,
} from 'BKModels';
import { Cacheable } from '../utils/cache';
import { ApiService } from './api';
import { SettingsService } from './settings.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private settings: Settings = new Settings();

    public constructor(private apiService: ApiService, private settingsService: SettingsService) {
        this.settingsService.settings.subscribe(settings => this.settings = settings);
    }

    public getUserProfile(userId: string | number, isCurrentUser: boolean = false): Promise<ProfileInfo> {
        return this.apiService.user.profileInfo(userId, isCurrentUser);
    }

    public trackProfileVisitor(userId: number) {
        if (!this.settings.anonymous) {
            this.apiService.trackProfileVisitor(userId);
        }
    }


}
