import { Location } from '@angular/common';
import { OnDestroy } from '@angular/core';
import {
    ActivatedRoute,
    Router,
} from '@angular/router';
import {
    BehaviorSubject,
    Observable,
    Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../service/navigation.service';

export class ModalServiceBase {
    protected openState = new BehaviorSubject(false);


    public get get(): Observable<boolean> {
        return this.openState.asObservable();
    }

    public close() {
        this.openState.next(false);
        document.body.style.overflow = 'auto';
    }

    public getValue(): boolean {
        return this.openState.getValue();
    }

    public open(id ?: any) {
        this.openState.next(true);
        document.body.style.overflow = 'hidden';
    }
}


export abstract class ModalServiceUrl extends ModalServiceBase implements OnDestroy {
    protected abstract param: string;
    private unsubscribe$ = new Subject();

    private selectedId: string = '';

    private skipNext = false;

    public get id(): string {
        return this.selectedId;
    }

    public constructor(
        protected navigationService: NavigationService,
        private activeRoute: ActivatedRoute,
        private location: Location,
    ) {
        super();
        setTimeout(() => this.subscribeRoute());
        location.subscribe(val => {
            this.close();
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public close() {
        if (this.getValue()) {
            let newUrl = this.routeStringRemoveQueryParameter(this.navigationService.url, this.param);
            this.navigationService.navigateToUrl(newUrl);
            super.close();
            this.selectedId = '';
        }
    }

    public open(id, requestByUser = true) {
        this.skipNext = requestByUser;
        let newUrl = this.routeStringSetQueryParameter(this.navigationService.url, this.param, id);
        this.navigationService.navigateToUrl(newUrl);
        this.selectedId = id;
        super.open();
    }

    private subscribeRoute() {
        this.activeRoute
            .queryParams
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((params: any) => {
                if (this.skipNext) {
                    this.skipNext = false;
                    return;
                }

                if (!params.hasOwnProperty(this.param)) {
                    this.close();
                } else {
                    this.open(params[this.param], false);
                }
            });
    }

    private routeStringSetQueryParameter(routeString: string, param: string, value: any): string {
        let url = this.routeStringToUrl(routeString);
        url.searchParams.set(param, value);
        return this.urlToRouteString(url);
    }

    private routeStringRemoveQueryParameter(routeString: string, param: string): string {
        let url = this.routeStringToUrl(routeString);
        url.searchParams.delete(param);
        return this.urlToRouteString(url);
    }

    private urlToRouteString(url: URL): string {
        return `${url.pathname}${url.search || ''}${url.hash || ''}`;
    }

    private routeStringToUrl(routeString: string): URL {
        return new URL(routeString, 'http://dummy/');
    }
}
