import {
    Component,
    ContentChild,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';

@Component({
               selector:  'dropdown-option',
               template:  `
                              <li class="mdc-list-item dropdown-option"
                                  [class.mdc-list-item--selected]="selected"
                                  [class.mdc-list-item--disabled]="disabled"
                                  [attr.data-value]="value"
                                  [attr.aria-selected]="selected"
                                  [hidden]="hidden"
                              >
                                  <div #text>
                                      <ng-content></ng-content>
                                  </div>
                              </li>

                          `,
               styles: [require('./dropdown.scss')],
           })
export class DropdownOption {
    @Input() private disabled: boolean = false;
    @Input() private hidden: boolean = false;
    @Input() private selected: boolean = false;
    @Input() public value: string | number = '';
    @ViewChild('text', { static: false }) private textRef: ElementRef;

    /**
     * Nur für den gebrauch innerhalb der Dropdowns
     */
    public get text() {
        return this.textRef.nativeElement.innerText;
    }
}
