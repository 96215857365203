import { HttpClient } from '@angular/common/http';
import {
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    NavigationEnd,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';

import {
    CurrentUserService,
    StoreService,
    TrackingService,
} from 'BKService';
import { parseLang } from 'BKUtils';
import { Subject } from 'rxjs';
import {
    filter,
    takeUntil,
} from 'rxjs/operators';
import * as smoothscrollPolyfill from 'smoothscroll-polyfill';
import { NavigationService } from '../service/navigation.service';
import { BkLink } from '../shared/mat-design/link/bk-link';


/**
 * Start Komponete für die Anwendung
 */
@Component({
               selector:      'app',
               encapsulation: ViewEncapsulation.None,
               template:      `
                                  <http-debug></http-debug>
                                  <router-outlet></router-outlet>
                              `,
               styles:     [
                   require('../../../node_modules/nouislider/distribute/nouislider.min.css'),
                   require('../../../node_modules/normalize.css/normalize.css'),
                   require('../../style/bk-global.scss'),
               ],

           })
export class AppComponent implements OnInit, OnDestroy {
    private previousUrl: string = '';
    private unsubscribe$ = new Subject();

    public constructor(
        injector: Injector,
        private activatedRoute: ActivatedRoute,
        private http: HttpClient,
        private translate: TranslateService,
        //private fb: FacebookService,
        private titleService: Title,
        private currentUserService: CurrentUserService,
        private storage: StoreService,
        private navigationService: NavigationService,
        private trackingService: TrackingService, // Force instantiation
    ) {
        const bkLink = createCustomElement(BkLink, { injector });
        customElements.define('bk-link', bkLink);
    }

    public ngOnInit() {
        this.setAccessTokenFromQuery();
        this.translate.addLangs(['key', 'de-DE', 'en-GB', 'pl-PL']);
        this.translate.setDefaultLang('key');

        this.navigationService.events
            .pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.unsubscribe$))
            .subscribe((event: NavigationEnd) => {
                this.scrollTop(event);
                if (this.translate.currentLang === 'key') return;

                let { lang } = this.activatedRoute.snapshot.firstChild.params;
                this.changeTranslation(lang || BkConfig.defaultLanguage);
            });


        // TODO Find a better solution instead of waiting (Events, ...)
        setTimeout(() => this.setMomentLang(), 2000);
        smoothscrollPolyfill.polyfill();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * change the translation
     */
    private changeTranslation(lang: string) {
        const parsedLang = parseLang(lang);
        if (parsedLang !== this.translate.currentLang) {
            this.navigationService.language = lang;

            this.translate.use(parsedLang).toPromise().then(translation => {
                this.titleService.setTitle(translation['APP.TITLE']);
            });

            this.loadTranslation(parsedLang);
        }
    }

    /**
     * if a parameter set the lang load form the extren tool
     {string} lang
     */
    private loadTranslation(lang: string) {
        if ((document as any).translate) {
            const url = `http://services.janhom.ex/translate/translate.php?lang=${lang}`;

            this.http.get(url).toPromise().then((res) => {
                this.translate.setTranslation(lang, res);
            });
        }
    }

    /**
     * Scroll the body top when is nessecary
     {NavigationEnd} res
     */
    private scrollTop(res: NavigationEnd) {
        if (this.previousUrl.indexOf('profileView') === -1 && res.url.indexOf('profileView') === -1) {
            window.scrollTo(0, 0);
        }
        this.previousUrl = res.url;
    }

    private setAccessTokenFromQuery() {
        const accessToken = new URLSearchParams(window.location.search).get('accessToken');
        if (accessToken) {
            this.storage.token.next(accessToken);
            history.replaceState({}, '', window.location.origin);
        }
    }

    private setMomentLang() {
        const parseTranslation = (arr, str) => arr.map(cur => `${str}.${cur}`)
                                                  .map(cur => this.translate.instant(cur));

        const monthArray = [
            'JANUARY',
            'FEBRUARY',
            'MARCH',
            'APRIL',
            'MAY',
            'JUNE',
            'JULY',
            'AUGUST',
            'SEPTEMBER',
            'OCTOBER',
            'NOVEMBER',
            'DECEMBER',
        ];
        const months = parseTranslation(monthArray, 'CALENDER.MONTH');
        const monthsShort = parseTranslation(monthArray, 'CALENDER.MONTH_SHORT');

        const weekdayArray = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
        const weekdays = parseTranslation(weekdayArray, 'CALENDER.WEEKDAY');
        const weekdaysShort = parseTranslation(weekdayArray, 'CALENDER.WEEKDAY_SHORT');
        const weekdaysMin = parseTranslation(weekdayArray, 'CALENDER.WEEKDAY_MIN');

        //@TODO Bessere Lösung finden
        /*moment.locale(this.translate.currentLang.split('-')[0], {
            parentLocale:       'en',
            months,
            monthsShort,
            monthsParseExact:   true,
            weekdays,
            weekdaysShort,
            weekdaysMin,
            weekdaysParseExact: true,
            longDateFormat:     {
                LT:   'HH:mm',
                LTS:  'HH:mm:ss',
                L:    'DD/MM/YYYY',
                LL:   'D MMMM YYYY',
                LLL:  'D MMMM YYYY HH:mm',
                LLLL: 'dddd D MMMM YYYY HH:mm',
            },
        });
        */
    }
}

