import {
    Component,
    Input,
} from '@angular/core';
import { Answer } from 'BKModels';

@Component({
               selector:  'lovestory-modal-item',
               template:  `
                              <div class="lovestory-modal-item">
                                  <div class="lovestory-modal-item__title"> {{title}}</div>


                                  <div class="lovestory-modal-item__line">
                                      <div class="lovestory-modal-item__line__name"> {{nameMale}}:</div>
                                      <div class="lovestory-modal-item__line__answer">
                                          {{answer.male}}
                                      </div>
                                  </div>
                                  <div class="lovestory-modal-item__line">
                                      <div class="lovestory-modal-item__line__name">{{nameFemale}}:</div>
                                      <div class="lovestory-modal-item__line__answer">
                                          {{answer.female}}
                                      </div>

                                  </div>
                              </div>
                          `,
               styles: [require('./lovestory-modal-item.scss')],
           })

export class LovestoryModalItem {
    @Input() private answer: Answer;
    @Input() private nameFemale: string = '';
    @Input() private nameMale: string = '';
    @Input() private title: string;
}
