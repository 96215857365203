import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector: 'premium-landing-page-header',
               template: `
                             <div class="premium-landing-page-header">
                                 <div class="premium-landing-page-header__content_wrap">
                                     <icon class="premium-landing-page-header__icon" (click)="backToBk()" icon="arrow-back"></icon>
                                     <div class="premium-landing-page-header__logo" [routerLink]="mainpage"></div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-header.scss')],
           })

export class PremiumLandingPageHeader {
    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private get mainpage() {
        return this.navigationService.getRoute(Routes.Startpage);
    }

    private backToBk() {
        this.trackingService.hit('Premium', 'KaufenSeite', 'ZurueckZuBildkontakte');
        this.navigationService.navigateBack();
    }
}
