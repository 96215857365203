import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdSlot } from './ad-slot.component';

@NgModule({
              imports: [
                  CommonModule,
              ],
              declarations: [
                  AdSlot
              ],
              exports: [
                  AdSlot,
              ],
              providers: [],
          })
export class AdsModule {
}