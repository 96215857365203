import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Image,
    ImageStatus,
} from 'BKModels';
import { ApiService } from './api';
import { CommunicationService } from './communication/communication.service';


@Injectable()
export class ImageService {

    constructor(private apiService: ApiService, private communicationService: CommunicationService, private translate: TranslateService) {
    }

    public activate(image: Image): Promise<any> {
        let canChange = image.canChangeToActivated();

        if (canChange) {
            image.status = image.isOkButDeactivate() ? ImageStatus.Ok : ImageStatus.OkNotMainPic;
        }

        return this.saveSetting(image, canChange, 'EDIT-IMAGES.CHANGE_TO_ACTIVATED.SUCCESS', 'EDIT-IMAGES.CHANGE_TO_ACTIVATED.NOT_ALLOWED', 'EDIT-IMAGES.CHANGE_TO_ACTIVATED.ERROR');
    }


    public deactivate(image: Image): Promise<any> {

        let canChange = image.canChangeToDeactivated();

        if (canChange) {
            image.status = (image.isOk() || image.isOkButNoRating()) ? ImageStatus.OkButDeactivate : ImageStatus.OkNotMainPicButDeactivate;
        }

        return this.saveSetting(image, canChange, 'EDIT-IMAGES.CHANGE_TO_DEACTIVATED.SUCCESS', 'EDIT-IMAGES.CHANGE_TO_DEACTIVATED.NOT_ALLOWED', 'EDIT-IMAGES.CHANGE_TO_DEACTIVATED.ERROR');
    }

    public useAsMain(image: Image): Promise<any> {
        let canChange = image.canChangeToMain();

        if (canChange) {
            image.status = ImageStatus.OkMainPic;
        }

        return this.saveSetting(image, canChange, 'EDIT-IMAGES.CHANGE_TO_MAIN.SUCCESS', 'EDIT-IMAGES.CHANGE_TO_MAIN.NOT_ALLOWED', 'EDIT-IMAGES.CHANGE_TO_MAIN.ERROR');
    }

    private saveSetting(image: Image, canChange: boolean, successMessage: string, changeNotAllowedMessage: string, generalErrorMessage): Promise<any> {
        if (!canChange) {
            this.communicationService.growl.negative(this.translate.instant(changeNotAllowedMessage));
            return new Promise(() => {
                // no nothing
            });
        }

        return this.apiService.image
                   .saveSettings(image.status, image.description, image.image)
                   .then(result => {
                       if (result.error === true) {
                           this.communicationService.growl.negative(this.translate.instant(generalErrorMessage));
                           throw {
                               errno:             result.errno,
                               error_description: result.error_description,
                           };
                       }
                       this.communicationService.growl.positive(this.translate.instant(successMessage));
                   });
    }
}
