import { DOCUMENT } from '@angular/common';
import {
    Inject,
    Injectable,
    Optional,
} from '@angular/core';

const DEGREE_IN_RADIANS: number = Math.PI / 180;

@Injectable()
export class RoundProgressService {
    public supportsSvg: boolean;

    private _base: HTMLBaseElement;

    private _hasPerf: boolean;

    public constructor(@Optional() @Inject(DOCUMENT) document: any) {
        this.supportsSvg = !!(
            document
            && document.createElementNS
            && document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect
        );

        this._base = document && document.head.querySelector('base');
        this._hasPerf = typeof window !== 'undefined'
            && window.performance
            && window.performance.now
            && typeof window.performance.now() === 'number';
    }

    /**
     * Generates the value for an SVG arc.
     current       Current value.
     total         Maximum value.
     pathRadius    Radius of the SVG path.
     elementRadius Radius of the SVG container.
     isSemicircle  Whether the elementRef should be a semicircle.
     */
    public getArc(
        current: number,
        total: number,
        pathRadius: number,
        elementRadius: number,
        isSemicircle = false,
    ): string {
        const value = Math.max(0, Math.min(current || 0, total));
        const maxAngle = isSemicircle ? 180 : 359.9999;
        const percentage = total === 0 ? maxAngle : (value / total) * maxAngle;
        const start = this._polarToCartesian(elementRadius, pathRadius, percentage);
        const end = this._polarToCartesian(elementRadius, pathRadius, 0);
        const arcSweep = (percentage <= 180 ? 0 : 1);

        return `M ${start} A ${pathRadius} ${pathRadius} 0 ${arcSweep} 0 ${end}`;
    }

    /**
     * Generates a timestamp.
     */
    public getTimestamp(): number {
        return this._hasPerf ? window.performance.now() : Date.now();
    }

    /**
     * Resolves a SVG color against the page's `base` tag.
     */
    public resolveColor(color: string): string {
        if (this._base && this._base.href) {
            const hashIndex = color.indexOf('#');

            if (hashIndex > -1 && color.indexOf('url') > -1) {
                return color.slice(0, hashIndex) + location.href + color.slice(hashIndex);
            }
        }

        return color;
    }

    /**
     * Converts polar cooradinates to Cartesian.
     elementRadius  Radius of the wrapper elementRef.
     pathRadius     Radius of the path being described.
     angleInDegrees Degree to be converted.
     */
    private _polarToCartesian(
        elementRadius: number,
        pathRadius: number,
        angleInDegrees: number,
    ): string {
        const angleInRadians = (angleInDegrees - 90) * DEGREE_IN_RADIANS;
        const x = elementRadius + (pathRadius * Math.cos(angleInRadians));
        const y = elementRadius + (pathRadius * Math.sin(angleInRadians));

        return `${x} ${y}`;
    }
}
