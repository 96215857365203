import { Injectable } from '@angular/core';
import {
    ApiResponseFrame,
    IgnoredUser,
    UserBaseView,
} from 'BKModels';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class IgnoreService {
    public constructor(
        private apiService: ApiService,
    ) {}

    public list(limit: number, offset?: number): Promise<IgnoredUser[]> {
        return this.apiService
                   .ignore
                   .list(limit, offset)
                   .then(res => res.data.ignores.map(item => IgnoredUser.create(item)));
    }

    public add(user: UserBaseView): Promise<ApiResponseFrame<any>> {
        return this.apiService.ignore.add(user.id);
    }

    public remove(user: UserBaseView): Promise<ApiResponseFrame<any>> {
        return this.apiService.ignore.remove(user.id);
    }
}
