/**
 * Created by olifra on 26.04.2017.
 */

import {
    isBetaMode,
    isDevMode,
} from 'BKUtils';

/**
 * Konfiguration Klasse
 */
export class BkConfig {
    public static readonly bkHomePicLimit: number = 15;

    public static readonly chatRefreshInterval = 5000;

    public static readonly defaultLanguage: string = 'de';

    public static readonly facebookAppId: string = '130665850343788';

    public static readonly refreshInterval: number = 1000 * 60 * 2;

    public static readonly supportedLang: string[] = ['de-de', 'de', 'en-gb', 'pl-pl', 'pl'];
    public static readonly cookieSaveTime = 2*3600*24*30;
    public static readonly defaultCacheTime = 120000;

    public static readonly profileFieldDeprecationDate = new Date(2019, 9, 3);
    public static readonly profileFieldsRemoved = false;

    private static _translationPrefix: string = './locales/';

    private static _translationSuffix: string = '.json';

    public static get apiPath(): string {
        return this.getApis().bk;
    }

    public static get client(): ApiClient {
        return process.env.client as any;
    }

    public static get glossaryUrl(): string {
        return this.getApis().glossar;
    }

    public static get imageApi(): string {
        return this.getApis().image;
    }

    public static get lovestoryImageApi(): string {
        return this.getApis().lovestoryImage;
    }


    public static get magazinUrl(): string {
        return this.getApis().magazin;
    }

    public static get sentryDsn(): string {
        return this.getApis().sentry;
    }

    public static get sentryRelease() {
        return BkConfig.version;
    }

    public static get sentryEnvironment(): string {
        if(isDevMode){
            return 'development'
        }
        if(isBetaMode) {
            return 'beta'
        }
        return 'prod';
    }


    public static get translationConfig(): { prefix: string, suffix: string } {
        return {
            prefix: this._translationPrefix,
            suffix: this._translationSuffix,
        };
    }

    public static get version(): string {
        return process.env.appVersion;
    }

    private static getApis(): EnvApi {
        return process.env.api as any;
    }

    static get vapidPubKeyBase64(): string {
        return this.getApis().vapidPubKey;
    }
}
