import {
    Component,
    Input,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'mail-settings-changed',
               template:  `
                              <card class="settings-changed-card">
                                  <div class="container">
                                      <div class="title">{{ titleKey|translate }}</div>
                                      <div class="description" [innerHtml]="textKey|translate|inlineMarkdown"></div>
                                      <img [src]="image">
                                      <div class="button-bar">
                                          <raised-button (click)="toStartpage()">
                                              {{ 'MAIL_SETTINGS.CHANGED.ACTION'|translate }}
                                          </raised-button>
                                      </div>
                                      <premium-support [showEmail]="false"></premium-support>
                                  </div>
                              </card>
                          `,
               styles: [require('./mail-settings-changed.scss')],
           })

export class MailSettingsChanged {
    @Input() titleKey = '[missing]';
    @Input() textKey = '[missing]';
    @Input() image = '';

    public constructor(
        private navigationService: NavigationService
    ) {}

    toStartpage() {
        this.navigationService.navigateTo(Routes.Startpage);
    }
}
