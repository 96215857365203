import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { CountrySelectService } from './country-select.service';
import { ImageService } from './image.service';
import { ProfileEditService } from './profile-edit.service';
import { SettingsService } from './settings.service';
import { CurrentUserService } from './user.service';
import { ValidationService } from './validation.service';


@NgModule()
export class AppServiceModule {
    public static forRoot(): ModuleWithProviders<AppServiceModule> {
        return {
            ngModule:  AppServiceModule,
            providers: [
                CurrentUserService,
                ImageService,
                SettingsService,
                CountrySelectService,
                ValidationService,
                ProfileEditService,
            ],
        };
    }
}

export * from './storage/store.service';
export * from './user.service';
export * from './modal';
export * from './matching.service';
export * from './api/api.service';
export * from './settings.service';
export * from './country-select.service';
export * from './tracking/tracking.service';
export * from './communication/communication.service';
export * from './rss/rss.service';
export * from './validation.service';
