import { OrderReason } from 'BKModels';
import { PremiumService } from '../premium.service';
import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class RemainingPremiumDaysNotification extends Notification {
    public static notificationTypeId = 'e8943d5e-91d6-41cc-bae1-dc5b39644f0b';

    public notificationId = RemainingPremiumDaysNotification.notificationTypeId;

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Warning;
    public contentKey = 'NOTIFICATION.PREMIUM-EXPIRATION.TEXT';
    public titleKey = 'NOTIFICATION.PREMIUM-EXPIRATION.TITLE';

    public actionKey = 'NOTIFICATION.PREMIUM-EXPIRATION.ACTION';
    public action = () => {
        this.premiumService.navigateToPremium(OrderReason.PremiumExpiration);
    };

    public foldable = true;
    public premiumAction = true;

    constructor(private premiumService: PremiumService) {
        super();
    }
}
