import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { OpenInvoice } from '../../../../../../models/open-invoice';
import { PaymentService } from '../../../../../../service/payment.service';

@Component({
               selector: 'settings-account-delete-footer',
               template: `
                             <div class="footer">
                                 <div class="footer__content">
                                     <ng-content></ng-content>
                                 </div>

                                 <hint-box class="footer__open-invoic" type="error" *ngIf="invoice.hasDebt">
                                     <ng-container role="content">
                                         {{'ACCOUNT.DELETE.OPEN_INVOICE' | translate:{amount: invoice.total.toCurrencyString()} }}
                                     </ng-container>
                                 </hint-box>
                                 
                                 <div class="footer__buttons">
                                     <raised-button (click)="cancel.emit($event)" class="footer__buttons__left">{{cancelText}}</raised-button>
                                     <raised-button [disabled]="disabledNext" (click)="next.emit($event)" fake-disable class="footer__buttons__right">{{nextText}}</raised-button>    
                                 </div>
                                 
                             </div>`,
               styles: [require('./settings-account-delete-footer.scss')],
           })

export class SettingsAccountDeleteFooter {
    @Output() private next = new EventEmitter();
    @Input() private nextText: string = '';

    @Output() private cancel = new EventEmitter();
    @Input() private cancelText: string = '';

    @Input() private disabledNext: boolean = false;
    private invoice: OpenInvoice = new OpenInvoice();

    public constructor(private paymentService: PaymentService) {
    }

    public ngOnInit(): void {
        this.paymentService.openInvoices().then(invoice => this.invoice = invoice)
    }

}
