import { Component } from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector: 'profile-visitor-tabbar',
               template: `
                   <div class="profile-visitor-chip-bar">
                       <navigation-chip (click)="clickVisitors()" [select]="visitorsSelected">
                           {{ 'PROFILE_VISITORS.TAB.VISITORS' | translate }}
                       </navigation-chip>
                       <navigation-chip (click)="clickVisited()" [select]="visitedSelected">
                           {{ 'PROFILE_VISITORS.TAB.VISITED' | translate }}
                       </navigation-chip>
                   </div>
                         `,
               styles: [require('./profile-visitor-tabbar.scss')]
           })

export class ProfileVisitorTabbar {
    private get visitorsSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ProfileVisitorsOtherVisits);
    }

    private get visitedSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ProfileVisitorsMyVisits);
    }

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {
    }

     private clickVisitors() {
        this.trackingService.hit('Profilbesucher', 'TabGeklickt', 'Besucher');
        this.navigationService.navigateTo(Routes.ProfileVisitorsOtherVisits);
    }

    private clickVisited() {
        this.trackingService.hit('Profilbesucher', 'TabGeklickt', 'BesuchteProfile');
        this.navigationService.navigateTo(Routes.ProfileVisitorsMyVisits);
    }
}
