import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'payment-methode-tab',
               template:  `
                              <div class="payment-methode-tab"
                                   [class.active]="active"
                                   (click)="click($event)"
                              >
                                  <img [src]="img" class="payment-methode-tab__img">
                                  <ng-content></ng-content>
                              </div>
                          `,
               styles: [require('./payment-methode-tab.scss')],
           })

export class PaymentMethodeTab {
    @Output('click') private _click = new EventEmitter();
    @Input() private active: boolean = true;
    @Input() private img: string = '';


    private click(event) {
        event.stopPropagation();
        this._click.emit(event);
    }
}
