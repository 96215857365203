/**
 * Created by olifra on 08.06.2017.
 */
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import { getImageUrl } from 'BKUtils';

@Component({
               selector:  'bk-picture-preview-card',
               template:  `
                              <div class="bk-picture-preview">
                                  <div class="bk-picture-preview__content__title">
                                      {{content}}
                                  </div>
                                  <div class="bk-picture-preview__content">
                                      <div class="bk-picture-preview__content__left">
                                          <icon icon="arrow-back-ios"
                                                class="bk-picture-preview__content__arrow"
                                                [class.bk-picture-preview__content__arrow--disable]="disabledLeftArrow"
                                                (click)="scrollLeft()"
                                          ></icon>
                                      </div>

                                      <div class="bk-picture-preview__content__picture-scroll"
                                           #viewBox
                                      >
                                          <div class="bk-picture-preview__content__picture-scroll__wrapper"
                                               #scrollContent
                                               [style.left]="leftPos+'px'"
                                          >

                                              <div class="bk-picture-preview__content__picture"
                                                   *ngFor="let item of items"
                                                   (click)="clickProfile.emit(item)"
                                              >
                                                  <img [src]="getImageUrl(item)" class="bk-picture-preview__content__picture__img">
                                                  <div class="bk-picture-preview__content__picture__overlay" *ngIf="item.isBasisView()">
                                                      <icon class="bk-picture-preview__content__picture__overlay__icon" icon="crown-outline"></icon>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="bk-picture-preview__content__right">
                                          <icon icon="arrow-back-ios"
                                                class="bk-picture-preview__content__arrow bk-picture-preview__content__arrow--right"
                                                [class.bk-picture-preview__content__arrow--disable]="disableRightArrow"
                                                (click)="scrollRight()"
                                          ></icon>
                                      </div>
                                  </div>
                                  <div class="bk-picture-preview__footer ">
                                      <ng-content></ng-content>
                                  </div>
                              </div>
                          `,
               styles: [require('./picture-preview.scss')],
           })
export class PicturePreViwComponent {
    @Input() private content: string = '';
    @Input() private items: UserBaseView[] = [];
    @Output() private clickProfile = new EventEmitter<UserBaseView>();

    private leftPos: number = 0;
    private scrollContent: any;
    private viewBox: any;

    public constructor(private elementRef: ElementRef) {
    }


    public ngAfterViewInit() {
        this.scrollContent = this.elementRef
                                 .nativeElement
                                 .querySelector('.bk-picture-preview__content__picture-scroll__wrapper');
        this.viewBox = this.elementRef
                           .nativeElement
                           .querySelector('.bk-picture-preview__content__picture-scroll');
    }

    private getImageUrl(item: any): string {
        return getImageUrl(item.image, '100x100');
    }


    private get imageWidth() {
        return 130;
    }

    private scrollLeft() {
        const tmpPos = this.leftPos + this.imageWidth;
        this.leftPos = tmpPos < 0 ? tmpPos : 0;
    }

    private scrollRight() {
        let tmpPos = this.leftPos - this.imageWidth;
        let rest = this.scrollContent.clientWidth - this.viewBox.clientWidth;
        rest += tmpPos;
        if (rest < 0) {
            tmpPos -= rest;
        }

        this.leftPos = tmpPos;
    }

    private get disabledLeftArrow(): boolean {
        return this.leftPos === 0;
    }

    private get disableRightArrow(): boolean {
        if (this.viewBox && this.scrollContent) {
            let rest = this.scrollContent.clientWidth - this.viewBox.clientWidth;
            rest += this.leftPos;
            return rest <= 0;
        }
        return false;
    }
}
