import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { FailedChargebackPaymentInfo } from '../../../service/premium.service';
import { DialogBox } from '../dialog/dialog-box';
import { growShrink } from 'BKUtils';
import * as moment from 'moment';

@Component({
               selector:  'bk-modal-chargeback-payment-failed',
               template: `
                             <ng-container>
                                 <dialog-box #dialog
                                             [title]="'NOTIFICATION.CHARGEBACK.TITLE'|translate"
                                             [acceptText]="'GLOBAL.CLOSE'|translate"
                                 >
                                     <ng-container *ngIf="failedChargebackPaymentInfo">
                                         <div class="markdown first-last-nomargin chargeback-failed-text"
                                              [innerHTML]="description|translate:failedChargebackPaymentInfo|markdown"
                                         ></div>
                                         <div class="qr-container">
                                             <a *ngIf="folded" (click)="folded = false">{{ 'PAY_WITH_QR.FOLD_ACTION'|translate }}</a>
                                             <div *ngIf="!folded" [@growShrink]="false">
                                                 <epc-qr [bic]="failedChargebackPaymentInfo.bic"
                                                         [iban]="failedChargebackPaymentInfo.iban"
                                                         [recipient]="failedChargebackPaymentInfo.accountOwner"
                                                         [reference]="failedChargebackPaymentInfo.reference"
                                                         [value]="failedChargebackPaymentInfo.value" width="240"
                                                 >
                                                 </epc-qr>
                                                 <div class="markdown first-last-nomargin qr-instructions" [innerHTML]="'PAY_WITH_QR.INSTRUCTIONS'|translate|markdown"></div>
                                             </div>
                                         </div>
                                     </ng-container>
                                 </dialog-box>
                             </ng-container>
                         `,
               styles: [require('./bk-modal-chargeback-payment-failed.scss')],
               animations: [growShrink],
           })
export class BkModalChargebackPaymentFailed implements ModalDialog {
    @ViewChild('dialog', { 'static': true }) private dialog: DialogBox;

    @Input('account-disabled') alreadyDisabled = false;

    failedChargebackPaymentInfo: {
        value: string,
        reference: string,
        paymentDue: string,
        accountOwner: string,
        iban: string,
        bic: string
    };

    folded = true;

    static readonly typeId = 'BkModalChargebackPaymentFailed';

    formattedValue(value: number): string {
        return `EUR${value.toFixed(2)}`;
    }

    public get description(): string
    {
        return (this.alreadyDisabled? 'NOTIFICATION.CHARGEBACK_LOCKED.TEXT':'NOTIFICATION.CHARGEBACK.TEXT')
    }

    public open(config) {
        const typedConfig = config as FailedChargebackPaymentInfo;
        this.failedChargebackPaymentInfo = {
            value: this.formattedValue(typedConfig.value),
            reference: typedConfig.reference,
            paymentDue: moment(typedConfig.paymentDue).locale('de').format('L'),
            accountOwner: typedConfig.accountOwner,
            iban: typedConfig.iban,
            bic: typedConfig.bic
        };
        this.folded = true;
        this.dialog.open();
    }

    public close() {
        this.dialog.close();
    }

    public get typeId(): string {
        return BkModalChargebackPaymentFailed.typeId;
    }
}
