import {
    Component,
    Input,
} from '@angular/core';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import { mapCountryCodeToFlagEmoji } from 'BKUtils';
import { geodesicDistance } from '../../../../../../utils/geo-helpers';

@Component({
               selector:  'profile-over-me',
               template:  `
                              <div class="mdc-layout-grid profile-over-me">
                                  <div class="mdc-layout-grid__inner">
                                      <div class="mdc-layout-grid__cell--span-12">
                                          <profile-info-item [title]="'PROFILE_INFO.SIZE' | translate"
                                                             icon="body-size"
                                          >
                                              {{user.size}} cm
                                          </profile-info-item>
                                          <profile-info-item [title]="'PROFILE_INFO.BODYSHAPE' | translate"
                                                             icon="figure"
                                          >
                                              {{user.figure | bodyshape | translate}}
                                          </profile-info-item>
                                          <profile-info-item [title]="'PROFILE_INFO.GENDER.LABEL' | translate"
                                                             icon="wc"
                                          >
                                              {{user.gender | gender | translate}}

                                          </profile-info-item>
                                          <profile-info-item [title]="'PROFILE_INFO.AGE' | translate"
                                                             icon="event"
                                          >
                                              {{user.age}} {{'PROFILE_INFO.AGE_IN_YEARS' | translate}}
                                          </profile-info-item>
                                          <profile-info-item [title]="'PROFILE_INFO.EYE_COLOR.LABEL' | translate"
                                                             icon="remove-red-eye"
                                          >
                                              {{user.eyecolor | eyecolor | translate}}
                                          </profile-info-item>
                                      </div>
                                      <div class="mdc-layout-grid__cell--span-12">
                                          <profile-info-item [title]="'PROFILE_INFO.HAIR_COLOR.LABEL' | translate"
                                                             icon="face"
                                          >
                                              {{user.haircolor | haircolor | translate}}
                                          </profile-info-item>
                                          <profile-info-item [title]="'PROFILE_INFO.LOCATION.LABEL' | translate"
                                                             icon="location-on"
                                          >
                                              <div [innerHTML]="locationInfo | emoji" class="emoji-small"></div> 
                                          </profile-info-item>
                                      </div>
                                      <div class="mdc-layout-grid__cell--span-12">
                                          <profile-info-item *ngIf="!user.aboutme.isEmpty()" [title]="'PROFILE_INFO.ABOUT_ME' | translate"
                                                             icon="info-outline"
                                          >
                                              <div [innerHTML]="user.aboutme | emoji" class="emoji-small"></div>
                                          </profile-info-item>
                                      </div>

                                  </div>
                              </div>
                          `,
               styles: [require('../profile-info.scss')],
           })

export class ProfileOverMe {
    @Input() user: ProfileInfo;
    @Input() currentUser: CurrentUser = new CurrentUser();

    get geodesicDistance(): number {
        if (!this.currentUser.hasCoordinates || !this.user.hasCoordinates) return 0;

        return Math.round(geodesicDistance(this.currentUser, this.user));
    }

    get locationInfo(): string {
        return `${mapCountryCodeToFlagEmoji(this.user.country)}–${this.user.postalCode.substring(0,3)}... ${this.user.city} (${this.geodesicDistance} km)`;
    }
}
