import { Injectable } from '@angular/core';
import {
    addForcedLogoutEventListener,
    addLoginEventListener,
    addLogoutEventListener,
} from 'BKUtils';
import {
    BehaviorSubject,
    interval,
    Subscription,
} from 'rxjs';
import { skip } from 'rxjs/operators';
import { ApiService } from './api';
import {
    MailSetting,
    MailSettingsInterval,
    MailSettingsType,
} from './api/calls/settings.api';
import { SessionService } from './session.service';

export class MailSettings {
    private constructor(
        public weeklyActivity: MailSettingsInterval,
        public newOpenMatch: MailSettingsInterval,
        public match: MailSettingsInterval,
        public newMessage: MailSettingsInterval,
        public profileVisitor: MailSettingsInterval,
        public nearbyUsers: MailSettingsInterval,
        public ads: MailSettingsInterval,
        public offers: MailSettingsInterval,
        public events: MailSettingsInterval,
        public travel: MailSettingsInterval,
        public magazine: MailSettingsInterval,
        public userSuggestion: MailSettingsInterval
    ) {}

    static default() {
        return MailSettings.create(
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never,
            MailSettingsInterval.Never
        );
    }

    static copy(other: MailSettings) {
        return MailSettings.default().applySelf(other.allSettings());
    }

    static create(
        weeklyActivity: MailSettingsInterval,
        newOpenMatch: MailSettingsInterval,
        match: MailSettingsInterval,
        newMessage: MailSettingsInterval,
        profileVisitor: MailSettingsInterval,
        nearbyUsers: MailSettingsInterval,
        ads: MailSettingsInterval,
        offers: MailSettingsInterval,
        events: MailSettingsInterval,
        travel: MailSettingsInterval,
        magazine: MailSettingsInterval,
        userSuggestion: MailSettingsInterval,
    ) {
        return new MailSettings(
                weeklyActivity,
                newOpenMatch,
                match,
                newMessage,
                profileVisitor,
                nearbyUsers,
                ads,
                offers,
                events,
                travel,
                magazine,
                userSuggestion
            );
    }

    allSettings(): MailSetting[] {
        return [
            { type: MailSettingsType.WeeklyActivity, interval: this.weeklyActivity },
            { type: MailSettingsType.NewOpenMatch, interval: this.newOpenMatch },
            { type: MailSettingsType.Match, interval: this.match },
            { type: MailSettingsType.NewMessage, interval: this.newMessage },
            { type: MailSettingsType.ProfileVisitor, interval: this.profileVisitor },
            { type: MailSettingsType.NearbyUsers, interval: this.nearbyUsers },
            { type: MailSettingsType.Ads, interval: this.ads },
            { type: MailSettingsType.Offers, interval: this.offers },
            { type: MailSettingsType.Events, interval: this.events },
            { type: MailSettingsType.Travel, interval: this.travel },
            { type: MailSettingsType.Magazine, interval: this.magazine },
            { type: MailSettingsType.UserSuggestion, interval: this.userSuggestion },
        ];
    }

    diff(other: MailSettings): MailSetting[] {
        const otherSettings = other.allSettings();
        return this.allSettings()
            .map((value, index) => {
                return value.interval !== otherSettings[index].interval ? value : null;
            })
            .filter(value => value !== null);
    }

    apply(settings: MailSetting[]): MailSettings {
        return MailSettings.copy(this).applySelf(settings);
    }

    applySingle(type: MailSettingsType, interval: MailSettingsInterval): MailSettings {
        return this.apply([{ type, interval}]);
    }

    private applySelf(settings: MailSetting[]): MailSettings {
        const applyFns = {
            [MailSettingsType.WeeklyActivity]: (interval) => this.weeklyActivity = interval,
            [MailSettingsType.NewOpenMatch]: (interval) => this.newOpenMatch = interval,
            [MailSettingsType.Match]: (interval) => this.match = interval,
            [MailSettingsType.NewMessage]: (interval) => this.newMessage = interval,
            [MailSettingsType.ProfileVisitor]: (interval) => this.profileVisitor = interval,
            [MailSettingsType.NearbyUsers]: (interval) => this.nearbyUsers = interval,
            [MailSettingsType.Ads]: (interval) => this.ads = interval,
            [MailSettingsType.Offers]: (interval) => this.offers = interval,
            [MailSettingsType.Events]: (interval) => this.events = interval,
            [MailSettingsType.Travel]: (interval) => this.travel = interval,
            [MailSettingsType.Magazine]: (interval) => this.magazine = interval,
            [MailSettingsType.UserSuggestion]: (interval) => this.userSuggestion = interval,
        };

        settings.forEach(value => {
            if( !applyFns[value.type] ) return; // ignore unknown emails
            applyFns[value.type](value.interval);
        });

        return this;
    }
}

@Injectable({ providedIn: 'root' })
export class MailSettingsService {
    static readonly intervals = {
        [MailSettingsType.Match]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.NearbyUsers]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.NewOpenMatch]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.LimitedPerDay,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.NewMessage]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.LimitedPerDay,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.ProfileVisitor]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.LimitedPerDay,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.WeeklyActivity]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.UserSuggestion]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.Ads]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.Offers]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.Events]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.Travel]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ],
        [MailSettingsType.Magazine]: [
            MailSettingsInterval.Never,
            MailSettingsInterval.Always
        ]
    }

    readonly intervals = MailSettingsService.intervals;

    private mailSettingsSubject = new BehaviorSubject<MailSettings>(null);

    readonly mailSettings$ = this.mailSettingsSubject.asObservable().pipe(skip(1));

    private weeklyActivitySubject = new BehaviorSubject<MailSettingsInterval>(null);

    readonly weeklyActivity$ = this.weeklyActivitySubject.asObservable().pipe(skip(1));

    private newOpenMatchSubject = new BehaviorSubject<MailSettingsInterval>(null);

    readonly newOpenMatch$ = this.newOpenMatchSubject.asObservable().pipe(skip(1));

    private matchSubject = new BehaviorSubject<MailSettingsInterval>(null);

    readonly match$ = this.matchSubject.asObservable().pipe(skip(1));

    private profileVisitorSubject = new BehaviorSubject<MailSettingsInterval>(null);

    readonly profileVisitor$ = this.profileVisitorSubject.asObservable().pipe(skip(1));

    private userSuggestionSubject = new BehaviorSubject<MailSettingsInterval>(null);

    readonly userSuggestion$ = this.userSuggestionSubject.asObservable().pipe(skip(1));

    private interval$ = interval(5 * 60 * 1000);

    private intervalSubscription = new Subscription();

    setWeeklyActivity(interval: MailSettingsInterval): Promise<void> {
        if (this.weeklyActivitySubject.value === interval) return new Promise(resolve => resolve());

        return this.setInterval(MailSettingsType.WeeklyActivity, interval)
            .then(() => {
                this.mailSettingsSubject.next(
                    this.mailSettingsSubject.value.applySingle(MailSettingsType.WeeklyActivity, interval)
                );
            });
    }

    setProfileVisitor(interval: MailSettingsInterval): Promise<void> {
        if (this.profileVisitorSubject.value === interval) return new Promise(resolve => resolve());

        return this.setInterval(MailSettingsType.ProfileVisitor, interval)
            .then(() => {
                this.mailSettingsSubject.next(
                    this.mailSettingsSubject.value.applySingle(MailSettingsType.ProfileVisitor, interval)
                );
            });
    }

    setUserSuggestion(interval: MailSettingsInterval): Promise<void> {
        if (this.userSuggestionSubject.value === interval) return new Promise(resolve => resolve());

        return this.setInterval(MailSettingsType.UserSuggestion, interval)
            .then(() => {
                this.mailSettingsSubject.next(
                    this.mailSettingsSubject.value.applySingle(MailSettingsType.UserSuggestion, interval)
                );
            });
    }

    private setInterval(type: MailSettingsType, interval: MailSettingsInterval): Promise<void> {
        return this.apiService
            .settings
            .interval(type, interval)
            .then(result => {
                if (!result) throw Error('Error setting mail interval');
            });
    }

    setIntervals(settings: MailSetting[]): Promise<void> {
        return this.apiService
            .settings
            .multipleIntervals(settings)
            .then(result => {
               if (!result) throw Error('Error setting multiple mail intervals');
            });
    }

    public update(): Promise<boolean> {
        return this.apiService.settings.allIntervals().then((value) => {
            this.mailSettingsSubject.next(MailSettings.default().apply(value));
            return true;
        }).catch(_ => false);
    }

    constructor(
        private apiService: ApiService,
        sessionService: SessionService
    ) {
        this.mailSettings$.subscribe((value) => {
            this.weeklyActivitySubject.next(value?.weeklyActivity);
            this.newOpenMatchSubject.next(value?.newOpenMatch);
            this.matchSubject.next(value?.match);
            this.profileVisitorSubject.next(value?.profileVisitor);
            this.userSuggestionSubject.next(value?.userSuggestion);
        });

        addLoginEventListener(() => this.onLogin());
        addLogoutEventListener(() => this.onLogout());
        addForcedLogoutEventListener(() => this.onLogout());
        if (sessionService.isLoggedIn()) this.onLogin();
    }

    private onLogout() {
        this.intervalSubscription.unsubscribe();
    }

    private onLogin() {
        this.intervalSubscription = this.interval$.subscribe(() => this.update());
    }

}
