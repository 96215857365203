export class State {
    public state = false;

    public static create(data: State): State {
        const res = new State();

        res.state = data.state;

        return res;
    }
}
