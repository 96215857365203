import {
    Component,
    Input,
} from '@angular/core';

enum MatchingOptionType {
    Yes= "yes",
    Maybe = "maybe",
    No = "no"
}

/**
 * MatchingOptionComponent
 */
@Component({
               selector:  'matching-option',
               template:  `
                              <div [ngClass]="'matching-option'"
                                   [class]="type"
                              >
                                  <div class="matching-option__circle">
                                      <icon class="matching-option__icon"
                                            [icon]="icon"
                                      ></icon>
                                  </div>
                                  <div class="matching-option__text">
                                      {{text}}
                                  </div>
                              </div>
                          `,
               styles: [
                   require('./matching-option.scss'),
               ],
           })
export class MatchingOption {
    @Input() private icon: string = '';

    @Input() private text: string = '';

    @Input() private type: MatchingOptionType;
}
