import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { AdsService } from './ads.service';

@Component({
               selector:  'ad-slot',
               template:  `
                              <div class="ad-slot" [id]="id" *ngIf="state"></div>
                          `,
               styles: [require('./ad-slot.component.scss')],
           })

export class AdSlot implements OnInit, AfterViewInit, OnDestroy {
    @Input() slotName = '';
    public id: string = '';
    public state: boolean = false;

    constructor(private adsService: AdsService) {
    }

    public ngOnInit() {
        this.id = this.adsService.generateId();
    }

    ngAfterViewInit() {
        this.adsService.isSlotActive(this.slotName)
            .then(isActive => {
                this.state = isActive;
                if (this.state) {
                    setTimeout(() => {
                                   this.adsService.defineSlot(this.slotName, this.id);
                                   this.adsService.displaySlot(this.slotName, this.id);
                               },0 // wait until next tick, else gpt will throw error because element id is still not set yet
                    );
                }
            });
    }

    ngOnDestroy() {
        this.adsService.destroySlot(this.slotName, this.id);
    }
}
