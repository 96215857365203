import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'on-boarding-item',
               template:  `
                              <div class="on-boarding-item__wrapper" (click)="onButton.emit($event)">
                                  <icon class="on-boarding-item__icon" [icon]="icon"></icon>

                                  <div class="on-boarding-item__text">
                                      <div class="on-boarding-item__text--title">
                                          {{title}}
                                      </div>
                                      {{text}}
                                  </div>

                              </div>
                              <raised-button class="on-boarding-item__button" (click)="onButton.emit($event)">{{buttonText}}</raised-button>
                          `,
               styles: [require('./on-boarding-item.scss')],
           })

export class OnBoardingItem {
    @Input() private buttonText: string;
    @Input() private icon: string;
    @Output() private onButton = new EventEmitter();
    @Input() private text: string;
    @Input() private title: string;
}
