import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Conversation,
    CurrentUser,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import * as moment from 'moment/moment';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../../../service/message.service';
import { EmojiPipe } from '../../../../../shared/pipes/emoji.pipe';

enum DisplayType {
    Normal,
    Transmitted,
    Unsent
}

@Component({
               selector:  'po-box-list-chat-text',
               template:  `
                              <div class="text" (click)="clickItem.emit()">
                                  <div class="text__name"
                                       [class.list-chats__content__text__name-unread]="chat.isUnread()"
                                  >{{ chat.partner.nickname }}
                                      <icon *ngIf="chat.partner.favorite"
                                            class="text__favorite"
                                            icon="star"
                                      ></icon>
                                  </div>
                                  <div class="text__msg"
                                       *ngIf="!chat.partner.isSuspended"
                                  >
                                      <ng-container *ngIf="displayType === DisplayType.Normal">
                                          <div class="text__msg__text"
                                               [class.text__msg__text--unread]="chat.isUnread()"
                                               [innerHtml]="lastMessage"
                                          ></div>
                                      </ng-container>
                                      <ng-container *ngIf="displayType === DisplayType.Transmitted">
                                          <icon *ngIf="chat.isTransmitted()"
                                                class="text__msg__check"
                                                [class.text__msg__check--readed]="chat.isRead()"
                                                icon="check"
                                          ></icon>
                                          <div class="text__msg__text"
                                               [class.text__msg__text--unread]="chat.isUnread()"
                                               [innerHtml]="lastMessage"
                                          ></div>
                                      </ng-container>
                                      <ng-container *ngIf="displayType === DisplayType.Unsent">
                                          <icon class="text__msg__check text__msg__check--unsent"
                                                icon="edit"
                                          ></icon>
                                          <div class="text__msg__text text__msg__text--unsent"
                                               [innerHtml]="unsentMessage"
                                          ></div>
                                      </ng-container>
                                  </div>
                                  <div class="text__msg"
                                       *ngIf="chat.partner.isSuspended"
                                  >
                                      <div class="text__msg__text"
                                           [class.text__msg__text--unread]="chat.isUnread()"
                                      >
                                          {{'POST_BOX.LINE.USER_LOCKED' | translate}}
                                      </div>
                                  </div>
                              </div>

                          `,
               styles: [require('./po-box-list-chat-text.scss')],
           })

export class PoBoxListChatText implements AfterViewInit, OnDestroy {
    @Input() chat: Conversation;
    @Output() clickItem = new EventEmitter();

    DisplayType = DisplayType;

    private currentUserSubscription = new Subscription();
    private currentUser = new CurrentUser();

    public constructor(
        private currentUserService: CurrentUserService,
        private emojis: EmojiPipe,
        private translate: TranslateService,
        private messageService: MessageService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.currentUserSubscription = this.currentUserService.currentUserObservable.subscribe((value) => {
            this.currentUser = value;
        });
    }

    public ngOnDestroy(): void {
        this.currentUserSubscription.unsubscribe();
    }

    get displayType(): DisplayType {
        if (this.messageService.hasUnsentMessage(this.chat.id)) {
            return DisplayType.Unsent;
        } else if (this.chat.isTransmitted()) {
            return DisplayType.Transmitted;
        } else {
            return DisplayType.Normal;
        }
    }

    get unsentMessage(): string {
        const message = this.messageService.getUnsentMessage(this.chat.id);
        return `${this.translate.instant('POST_BOX.LIST.PREFIX.UNSENT').toString()} ${message}`;
    }

    get lastMessage(): string {
        if (this.chat.partner.isSystemUser) {
            return this.parseTemplate();
        }

        const msg = this.chat
                        .lastMessage
                        .replace(/[\r\n]+/g, ' ');

        return this.emojis.transform(msg);
    }

    private parseTemplate(): string {
        const params = {
            username: this.currentUser.nickname,
            partnerName: this.chat.partner.nickname,
            date: moment(this.chat.lastMessageOn * 1000).format('DD.MM.YYYY')
        };

        // TODO re-add '_PRIME' suffix when the other user is available in this.chat
        switch (this.chat.lastMessage) {
            case '$template=willkommen':
                return this.translate.instant('POST_BOX.SYSTEM_MSG.WELCOME.TITLE', params);
            case '$template=geburtstag':
                return this.translate.instant('POST_BOX.SYSTEM_MSG.BIRTHDAY.TITLE', params);
            case '$template=favHatGeburtstag':
                return this.translate.instant(`POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV${this.currentUser.premium ? '' : ''}.TITLE`, params);
            case '$template=freundHatGeburtstag':
                return this.translate.instant(`POST_BOX.SYSTEM_MSG.BIRTHDAY_FRIEND${this.currentUser.premium ? '' : ''}.TITLE`, params);
            default:
                return this.chat.lastMessage;
        }
    }
}
