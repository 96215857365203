import { B } from '@angular/cdk/keycodes';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    enableProdMode,
    ErrorHandler,
    Inject,
    Injectable,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';
import { AppServiceModule } from 'BKService';
import { ShareModule } from 'BKShared';
import {
    isBetaMode,
    isDevMode,
    Logger,
} from 'BKUtils';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import {
    AppRouting,
    CustomPreloadingStrategy,
} from './app.routing';
import { translationConfig } from './app.translation';
import { AppComponent } from './components/app.component';
import { Error } from './components/error/error';
import { PageNotFound } from './components/pageNotFound/pageNotFound';
import { TestComponent } from './components/test/test.component';
import { HttpDebugModule } from './http-debug';
import { AppOuterModule } from './module';
import { AccountDeleteSuccessful } from './module/account-delete-successful/account-delete-successful';
import { CancelContracts } from './module/cancel-contracts/cancel-contracts';
import { LayoutModule } from './module/intern/layout';
import { OuterComponentModule } from './module/outer-component';
import * as Sentry from '@sentry/browser';
import { StoreModule } from '@ngrx/store';
import { headerReducer } from './store/header.reducer';

enableProdMode();

const imports = (() => {
    const res = [
        AppOuterModule.forRoot(),
        AppServiceModule.forRoot(),
        AppRouting,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        ShareModule,
        // FacebookModule.forRoot(),
        TranslateModule.forRoot(translationConfig),
    ];
    if (isDevMode) res.push(HttpDebugModule.forRoot());

    return res;
})();

Sentry.init({
                enabled: !isDevMode,
                debug: isDevMode && isBetaMode,
                dsn:         BkConfig.sentryDsn,
                release:     BkConfig.sentryRelease,
                environment: BkConfig.sentryEnvironment,
            });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    public handleError(err: any): void {
        Logger.error(err);
        if (err.hasOwnProperty('error') && typeof err.error === 'object') {
            this.trackError(err.error);
            Sentry.captureException(err.error);
        } else {
            this.trackError(err);
            Sentry.captureException(err);
        }
    }

    private trackError(err) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({
                                           'event':    'error',
                                           'category': 'Error',
                                           action:     'Logging',
                                           'label':    err.message,
                                       });
    }
}

@NgModule({
              bootstrap:    [AppComponent],
              declarations: [
                  AppComponent,
                  TestComponent,
                  PageNotFound,
                  Error,
                  AccountDeleteSuccessful,
                  CancelContracts,
              ],
              imports:      [
                  ...imports,
                  OuterComponentModule,
                  StoreModule.forRoot({ headerExtended: headerReducer })
              ],
              exports:      [
                  ShareModule,
              ],
              providers:    [
                  APP_RESOLVER_PROVIDERS,
                  CustomPreloadingStrategy,
                  { provide: ErrorHandler, useClass: SentryErrorHandler },
              ],
              schemas:      [CUSTOM_ELEMENTS_SCHEMA],
          })

export class AppModule {

}
