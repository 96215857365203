import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MDCTextFieldHelperText } from '@material/textfield/helper-text';
import { TranslateService } from '@ngx-translate/core';
import { getValueAccessorProvider } from 'BKBaseClass';
import { TextFieldBase } from './text-field-base';

@Component({
               selector:  'text-field',
               template:  `
                              <div #textField
                                   class="mdc-text-field mdc-text-field--outlined"
                                   [class.mdc-text-field--with-leading-icon]="!leadingIcon.isEmpty()"
                                   [class.mdc-text-field--with-trailing-icon]="hasErrors || !trailingIcon.isEmpty()"
                                   [class.mdc-text-field--disabled]="disabled"
                                   [class.mdc-text-field--invalid]="hasErrors"
                              >
                                  <icon *ngIf="!leadingIcon.isEmpty()" class="mdc-text-field__icon" [icon]="leadingIcon"></icon>

                                  <input #input
                                         [attr.type]="type"
                                         [id]="id"
                                         (keypress)="filterKeyPress($event)"
                                         (paste)="filterPaste($event)"
                                         class="mdc-text-field__input"
                                         [class.mdc-text-field__input--invalid]="hasErrors"
                                         [(ngModel)]="value"
                                         [disabled]="disabled"
                                         (keyup)="keyup.emit($event)"
                                         (keydown)="keydown.emit($event)"
                                         (focus)="focus.emit($event)"
                                         (blur)="blur.emit($event)"
                                  >
                                  
                                  
                                  <icon class="mdc-text-field__icon mdc-text-field__icon--invalid" tabindex="-1" icon="cancel"></icon>
                                  <icon class="mdc-text-field__icon mdc-text-field__icon--valid" tabindex="-1" icon="check"></icon>
                                  <icon *ngIf="!trailingIcon.isEmpty() && !showPremiumIcon" class="mdc-text-field__icon mdc-text-field__icon--custom" tabindex="-1" [icon]="trailingIcon"></icon>
                                  <icon *ngIf="showPremiumIcon" class="mdc-text-field__icon mdc-text-field__icon--premium" tabindex="-1" icon="crown-outline"></icon>
                                  <div class="mdc-notched-outline"
                                       [class.mdc-notched-outline--notched]="(isNotched || (hasErrors && value.length > 0))"
                                  >
                                      <div class="mdc-notched-outline__leading"></div>
                                      <div class="mdc-notched-outline__notch">
                                          <label #label
                                                 [for]="id"
                                                 class="mdc-floating-label"
                                                 [class.mdc-floating-label--float-above]="isNotched"
                                          >{{placeholder}}</label>
                                      </div>
                                      <div class="mdc-notched-outline__trailing"></div>
                                  </div>

                              </div>
                              <div class="mdc-text-field__outer">
                                  <div *ngIf="!hintText.isEmpty()" class="mdc-text-field-helper-line">
                                      <div #helperText
                                           class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                           [class.mdc-text-field-helper-text--validation-msg]="hasErrors"
                                           aria-hidden="true"
                                      >
                                          {{ hintText | translate}}
                                      </div>
                                  </div>

                                  <div class="mdc-text-field-character-counter" #counter *ngIf="maxLength">{{currentLength}} / {{maxLength}}</div>
                              </div>
                          `,
               styles: [require('./text-field.scss')],
               providers: [
                   getValueAccessorProvider(TextField),
               ],
           })
export class TextField extends TextFieldBase implements AfterViewInit {
    @Input() private type: string = 'text';

    @Input() private leadingIcon: string = '';
    @Input("trailingIcon") private _trailingIcon: string = '';
    @Input() private showPremiumIcon: boolean = false;


    private get trailingIcon(): string {
        if(!this.valid) return 'cancel';
        return this._trailingIcon
    }

    @Input() private max: number;
    @Input() private min: number;


    public ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.max) this.inputElement.setAttribute('max', this.max.toString());
        if (this.min) this.inputElement.setAttribute('min', this.min.toString());
    }

    constructor(translateService: TranslateService) {
        super(translateService);
    }

}
