import {
    AfterViewInit,
    Component,
    Input,
} from '@angular/core';
import {
    ImageSize,
    MatchingQueueItem,
} from 'BKModels';
import {
    MatchingService,
    TrackingService,
} from 'BKService';
import { Observable } from 'rxjs';

import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { HintBoxType } from '../../../../shared/mat-design/hint-box/hint-box';

@Component({
               selector:  'user-daily-suggestion',
               template:  `
                              <div class="mdc-card">
                                  <div class="user-daily-suggestion">
                                      <div class="user-daily-suggestion__title">
                                          {{ 'USER_DAILY_SUGGESTION.TITLE'|translate }}
                                      </div>
                                      <div class="user-daily-suggestion__description">
                                          {{ 'USER_DAILY_SUGGESTION.TEXT'|translate }}
                                      </div>
                                      <div class="user-daily-suggestion__bottom-container">
                                          <div class="user-daily-suggestion__bottom-container__images" (click)="action()">
                                              <img class="user-daily-suggestion__bottom-container__images__background"
                                                   src="./assets/img/daily-suggestion/picMatches.png">
                                              <img class="user-daily-suggestion__bottom-container__images__user-image" 
                                                   [src]="(matchingQueue|async)[0].partner.getProfilePictureUrl(ImageSize['160x160'])">
                                              <div class="user-daily-suggestion__bottom-container__images__user-info">
                                                  <span class="user-daily-suggestion__bottom-container__images__user-info--name">
                                                      {{ (matchingQueue|async)[0].partner.nickname }},
                                                  </span>
                                                  <span class="user-daily-suggestion__bottom-container__images__user-info--age">
                                                      {{ (matchingQueue|async)[0].partner.age }}
                                                  </span>
                                              </div>
                                          </div>
                                          <div class="user-daily-suggestion__bottom-container__hint">
                                              <hint-box class="user-daily-suggestion__bottom-container__hint__box"
                                                        [type]="HintBoxType.Tip">
                                                  <ng-container role="content">
                                                      <div class="markdown p-nomargin" [innerHTML]="'USER_DAILY_SUGGESTION.HINT'|translate|markdown"></div>
                                                  </ng-container>
                                              </hint-box>
                                              <raised-button class="user-daily-suggestion__bottom-container__hint__action"
                                                             (click)="action()">
                                                  <ng-container *ngIf="premium">
                                                      {{ 'USER_DAILY_SUGGESTION.PREMIUM.ACTION'|translate }}
                                                  </ng-container>
                                                  <ng-container *ngIf="!premium">
                                                      {{ 'USER_DAILY_SUGGESTION.ACTION'|translate:{ 'count': (contingent.toString()) } }}
                                                  </ng-container>
                                              </raised-button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./user-daily-suggestion.component.scss')],
           })

export class UserDailySuggestion implements AfterViewInit {
    private HintBoxType = HintBoxType;
    private ImageSize = ImageSize;

    @Input() private premium: boolean = true;
    @Input() private contingent: number = 0;

    private get matchingQueue(): Observable<MatchingQueueItem[]> {
        return this.matchingService.matchingQueue;
    }

    public constructor(
        private matchingService: MatchingService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.trackingService.hit('Startseite', 'AuswahlDesTages', 'View: AuswahlDesTages');
    }

    private action() {
        if (this.premium) this.trackingService.hit('Startseite', 'AuswahlDesTages', 'Klick: Btn: Noch X Profile Matchen');
        else this.trackingService.hit('Startseite', 'AuswahlDesTages', 'Klick: Btn: Profile Ansehen und Matchen');
        this.navigationService.navigateTo(Routes.Matching);
    }
}
