import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Image,
    ImageSize,
    ImageStatus,
    Settings,
} from 'BKModels';
import {
    CurrentUserService,
    ModalService,
    TrackingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

export enum AccountStateIcon {
    None,
    Offline,
    Notice
}

@Component({
               selector:  'account-menu',
               template:  `                          
                              <div (click)="open = !open"
                                   #icon
                                   class="account-menu-img"
                              >
                                  <img [src]="profileImage" (click)="clickProfileImage()">
                                  <icon *ngIf="!hasMainPic && hasPendingProfilePicture" icon="hourglass-empty" class="account-menu-img__pending"></icon>
                                  
                                  <div class="account-menu-img__circle" *ngIf="stateIcon !== AccountStateIcon.None"
                                       [class.account-menu-img__circle-offline]="stateIcon === AccountStateIcon.Offline"
                                       [class.account-menu-img__circle-notice]="stateIcon === AccountStateIcon.Notice"
                                  ></div>
                                  
                                  <icon class="account-menu-img__icon" icon="arrow-drop-down"></icon>
                              </div>
                              
                              <div *ngIf="open" class="account-menu" #accountMenu>
                                  <div class="account-menu__arrow"></div>
                                  <div class="account-menu__section">
                                      <div class="account-menu__navigation">
                                          <account-menu-item
                                                  icon="profile-outline"
                                                  [showState]="stateIcon"
                                                  [label]="'LAYOUT.HEADER.MY_PROFILE'|translate"
                                                  (click)="toMyProfile()"
                                          ></account-menu-item>
                                          <account-menu-item
                                                  icon="settings-outline"
                                                  [label]="'LAYOUT.HEADER.SETTINGS'|translate"
                                                  (click)="toSettings()"
                                          ></account-menu-item>
                                      </div>
                                  </div>
                                  <flat-button (click)="logout()" class="account-menu__footer">
                                      {{'LAYOUT.SIDE_NAV.LOGOUT'|translate}}
                                  </flat-button>
                              </div>
                          `,
               styles: [require('./account-menu.scss')],
           })

export class AccountMenu implements OnInit, OnDestroy {
    @Output('logout') private _logout: EventEmitter<any> = new EventEmitter();
    @ViewChild('accountMenu', { static: false }) private accountMenuRef: ElementRef;
    @Input() private settings: Settings = new Settings();
    @ViewChild('icon', { static: true }) private iconRef: ElementRef;

    private currentUserSubscription = new Subscription();
    private gallerySubscription = new Subscription();
    private currentUser: CurrentUser;
    private gallery: Image[] = [];

    open: boolean = false;

    AccountStateIcon = AccountStateIcon;
    get stateIcon(): AccountStateIcon {
        if (this.hasNotice) return AccountStateIcon.Notice;
        else if (this.settings.offline) return AccountStateIcon.Offline;
        else return AccountStateIcon.None;
    }

    private get hasNotice(): boolean {
        return !this.hasMainPic && !this.hasPendingProfilePicture;
    }

    get hasMainPic(): boolean {
        return !this.currentUser.image.isEmpty();
    }

    get hasPendingProfilePicture(): boolean {
        return !!this.pendingProfilePicture;
    }

    private get pendingProfilePicture(): Image {
        return this.gallery.find(value => value.status === ImageStatus.NotApproved);
    }

    public constructor(
        private navigationService: NavigationService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private modalService: ModalService
    ) {
    }

    public ngOnInit(): void {
        this.currentUserSubscription = this.currentUserService.currentUserObservable
            .subscribe(user => this.currentUser = user);
        this.gallerySubscription = this.currentUserService.gallery$.subscribe(gallery => this.gallery = gallery);
    }

    public ngOnDestroy(): void {
        this.gallerySubscription.unsubscribe();
        this.currentUserSubscription.unsubscribe();
    }

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!this.open) return;
        if (!targetElement) return;

        const clickedInside = this.accountMenuRef.nativeElement.contains(targetElement) || this.iconRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.open = false;
        }
    }

    get profileImage() {
        if (this.hasMainPic) return this.currentUser.getProfilePictureUrl(ImageSize['100x100']);
        else if (this.hasPendingProfilePicture) return this.pendingProfilePicture.getUrl(ImageSize['100x100']);
        else return Image.Placeholder.getUrl(ImageSize['100x100']);
    }

    logout() {
        this.trackingService.hit('RechteNavi', 'IconGeklickt', 'Hilfe');
        this._logout.emit();
    }

    toMyProfile() {
        this.trackingService.hit('RechteNavi', 'IconGeklickt', 'MeinProfil');
        this.modalService.profileView.open(this.currentUser.id);
    }

    toSettings(){
        this.trackingService.hit('RechteNavi', 'IconGeklickt', 'Einstellungen');
        this.navigationService.navigateTo(Routes.Settings);
    }

    clickProfileImage() {
        this.trackingService.hit('RechteNavi', 'HeaderGeklickt', 'Profilebild');
    }
}
