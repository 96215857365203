import {
    Component,
    OnInit,
} from '@angular/core';
import { ConversationListDataSource } from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import { PostboxState } from '../po-box-list/po-box-list';

@Component({
               selector: 'po-box-all',
               template: `
                             <po-box-list-empty *ngIf="showEmptyList"></po-box-list-empty>
                             <po-box-list [dataSource]="dataSource"></po-box-list>
                         `,

           })

export class PoBoxAll implements OnInit {
    public dataSource: ConversationListDataSource;

    public constructor(private dataSourceFactory: DataSourceFactory) {
    }

    public ngOnInit() {
        const state = PostboxState.previousState;
        if (!!state.dataSource) {
            this.dataSource = state.dataSource;
        } else {
            this.dataSourceFactory
                .createAllConversationListDataSource()
                .then(dataSource => {
                    this.dataSource = dataSource;
                    this.dataSource.loadMore();
                });
        }
    }

    private get showEmptyList(): boolean {
        return !!this.dataSource && this.dataSource.isEmpty && !this.dataSource.isLoading;
    }

}
