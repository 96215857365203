import { ModuleWithProviders } from '@angular/core';
import {
    Route as NgRoute,
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import { WorkInProgress } from '../../components/workInProgress/workInProgress';
import {
    routeName,
    Routes,
} from '../../service/navigation.service';
import { InternDefaultHeader } from '../../shared/mat-design/header/intern-default-header';
import { Layout } from './layout';
import { PremiumBookingPage } from './premium/premium-booking-page/premium-booking-page/premium-booking-page';
import { PremiumLandingPage } from './premium/premium-landing-page/premium-landing-page/premium-landing-page';
import { PremiumPurchaseAfterSale } from './premium/premium-purchase-after-sale/premium-purchase-after-sale/premium-purchase-after-sale';

const createPlaceholderRoute = (path: string): NgRoute => ({
    path,
    children: [{
        path:      '',
        component: WorkInProgress,
    }, {
        path:      '',
        component: InternDefaultHeader,
        outlet:    'header',
    },
    ],

});


const internRoutes: NgRoutes = [
    {
        path:      routeName(Routes.PremiumLandingPage),
        component: PremiumLandingPage,
    }, {
        path:     routeName(Routes.PremiumBooking),
        children: [{
            path:      routeName(Routes.PremiumBookingPage),
            component: PremiumBookingPage,
        }, {
            path:      routeName(Routes.PremiumBookingAfterSale),
            component: PremiumPurchaseAfterSale,
        },
        ],

    },
    {
        path:         routeName(Routes.MailSettings),
        loadChildren: () => import('./mail-settings/mail-settings.module.ts').then(mod => mod.MailSettingsModule),
    },
    {
        path:         routeName(Routes.RegCode),
        loadChildren: () => import('./reg-code/reg-code.module.ts').then(mod => mod.RegCodeModule),
    },

    {
        path:      '',
        component: Layout,
        children:  [
            {
                path:         routeName(Routes.Startpage),
                loadChildren: () => import('./startpage/startpage.module').then(mod => mod.StartpageModule),
            }, {
                path:         routeName(Routes.Matching),
                loadChildren: () => import('./matching/matching.module').then(mod => mod.MatchingModule),
            }, {
                path:         routeName(Routes.ProfileVisitors),
                loadChildren: () => import('./profile-visitors/profile-visitors.module').then(mod => mod.ProfileVisitorsModule),
            }, {
                path:         routeName(Routes.Contacts),
                loadChildren: () => import('./contacts/contacts.module').then(mod => mod.ContactsModule),
            }, {
                path:         routeName(Routes.Favorite),
                loadChildren: () => import('./favorite/favorite.module').then(mod => mod.FavoriteModule),
            }, {
                path:         routeName(Routes.Search),
                loadChildren: () => import('./search/search.module').then(mod => mod.SearchModule),
            }, {
                path:         routeName(Routes.Conversation),
                loadChildren: () => import('./po-box/po-box.module').then(mod => mod.PoBoxModule),
            }, {
                path:         routeName(Routes.MyProfile),
                loadChildren: () => import('./profile-edit/profile-edit.module').then(mod => mod.ProfileEditModule),
            }, {
                path:     routeName(Routes.Lovestory),
                children: [{
                    path:         '',
                    loadChildren: () => import('./lovestory/lovestory-page/lovestory.module').then(mod => mod.LovestoryModule),
                },
                ],
            }, {
                path:         routeName(Routes.Settings),
                loadChildren: () => import('./settings/settings.module').then(mod => mod.SettingsModule),

            }, {
                path:         routeName(Routes.SchlagerparadiesOverview),
                loadChildren: () => import('./schlagerparadies/schlagerparadies.module').then(mod => mod.SchlagerparadiesModule),
            }, {
                path:         routeName(Routes.PremiumOverview),
                loadChildren: () => import('./premium/premium.module').then(mod => mod.PremiumModule),
            }, {
                path:         routeName(Routes.ActiveUsers),
                loadChildren: () => import('./active-users/active-users.module').then(mod => mod.ActiveUsersModule),
            },
            createPlaceholderRoute(routeName(Routes.Contacts)),
            {
                path:       '**',
                redirectTo: routeName(Routes.Startpage),
                pathMatch:  'full',
            },
        ],
    },
];

export const InternRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(internRoutes);
