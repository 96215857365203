import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { WorkInProgress } from '../../components/workInProgress/workInProgress';
import { InternComponentsModule } from './intern-components';
import { InternRouting } from './intern.routing';
import { LayoutModule } from './layout';
import { LovestoryModalModule } from './lovestory';
import { PremiumModule } from './premium/premium.module';
import { ProfileViewModule } from './profile-view';
import { SchlagerparadiesModule } from './schlagerparadies';
import { StartpageModule } from './startpage';

@NgModule({
              imports:      [
                  InternRouting,
                  LayoutModule,
                  ProfileViewModule,
                  ShareModule,
                  StartpageModule,
                  LovestoryModalModule,
                  InternComponentsModule,
                  PremiumModule,
                  SchlagerparadiesModule,
              ],
              exports:      [
                  ShareModule,
              ],
              declarations: [
                  WorkInProgress,
              ],
              providers:    [],
          })
export class InternModule {
}

