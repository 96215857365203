import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';

import { ProfileVisitorEmpty } from './profile-visitor-empty/profile-visitor-empty';
import { ProfileVisitorHeader } from './profile-visitor-header/profile-visitor-header';
import { ProfileVisitorsMyVisits } from './profile-visitors-my-visits/profile-visitors-my-visits.component';
import { ProfileVisitorsOthers } from './profile-visitors-others/profile-visitors-others';
import { ProfileVisitorTabbar } from './profile-visitors-tabbar/profile-visitor-tabbar';
import { ProfileVisitorsRouting } from './profile-visitors.routing';
import { ProfileVisitors } from './profile-visitors/profile-visitors';


@NgModule({
              imports:      [
                  ShareModule,
                  ProfileVisitorsRouting,
              ],
              exports:      [ProfileVisitors],
              declarations: [ProfileVisitors, ProfileVisitorsOthers, ProfileVisitorsMyVisits, ProfileVisitorEmpty, ProfileVisitorHeader, ProfileVisitorTabbar],
              providers:    [],
          })
export class ProfileVisitorsModule {
}
