import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    Conversation,
    ImageSize,
    Online,
} from 'BKModels';

@Component({
               selector: 'po-box-list-chat-image',
               template: `
                             <div class="container">
                                 <img [src]="image"
                                      class="image"
                                      (click)="openProfile.emit($event)"
                                 >
                                 <online-state type="circle"
                                               [state]="online" 
                                               class="online"
                                 ></online-state>
                             </div>`,
               styles: [require('./po-box-list-chat-image.scss')],
           })

export class PoBoxListChatImage {
    @Input() private chat: Conversation;
    @Output() private openProfile = new EventEmitter();

    private get image(): string {
        return this.chat.partner.getProfilePictureUrl(ImageSize['70x70']);
    }

    private get online(): Online {
        if(this.chat.partner.isSystemUser) {
            return Online.Online;
        }

        return this.chat.partner.online;
    }
}
