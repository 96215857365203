import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    RssService,
    TrackingService,
} from 'BKService';
import { AccountDeleteReasons } from '../../../../../models/delete-reasons';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { Article } from '../../../../../service/rss/childs/rss-feed';


@Component({
               selector:  'settings-account-delete-reason-box',
               template:  `
                              <div class="settings-account-delete-reason-box">


                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.FindPartnerBk">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.FindPartnerBk"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_FIND_PARTNER_BK' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.FindPartnerBk"
                                      ></radio>
                                  </div>
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.NoInterest">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.NoInterest"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_INTEREST' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.NoInterest"
                                      ></radio>
                                  </div>
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.RareUse">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.RareUse"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_RARE_USE' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.RareUse"
                                      ></radio>
                                  </div>
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.Confusion">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.Confusion"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_CONFUSION' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.Confusion"
                                      ></radio>
                                  </div>
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.FindPartnerSomewhereElse">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.FindPartnerSomewhereElse"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_FIND_PARTNER_SOMEWHERE_ELSE' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.FindPartnerSomewhereElse"
                                      ></radio>
                                  </div>
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.NoSuccess">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.NoSuccess"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_SUCCESS' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.NoSuccess"
                                      ></radio>
                                  </div>                                  
                                  <div class="settings-account-delete-reason-box__part" *ngIf="reason == AccountDeleteReasons.Other">
                                      <radio [name]="'settingsAccountDeleteReason'"
                                             [value]="AccountDeleteReasons.Other"
                                             label="{{'ACCOUNT.DELETE.STEP.REASON.REASON_OTHER' | translate}}"
                                             (select)="selectReason($event)"
                                             [small]="true"
                                             [checked]="selectedReason == AccountDeleteReasons.NoSuccess"
                                      ></radio>
                                  </div>

                                  <div class="settings-account-delete-reason-box__content"
                                       *ngIf="isContentOpen(AccountDeleteReasons.FindPartnerSomewhereElse)"
                                  >
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_FIND_PARTNER_SOMEWHERE_ELSE.TITLE'|translate}}
                                      </div>

                                      <div class="settings-account-delete-reason-box__content__textBox">
                                          <text-area rows="4"
                                                     [placeholder]="'ACCOUNT.DELETE.STEP.REASON.REASON_FIND_PARTNER_SOMEWHERE_ELSE.PLACEHOLDER'|translate"
                                                     [(value)]="findElseWhereText"
                                          ></text-area>
                                      </div>
                                  </div>


                                  <div class="settings-account-delete-reason-box__content" *ngIf="isContentOpen(AccountDeleteReasons.NoSuccess)">
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_SUCCESS.TITLE'|translate}}
                                      </div>


                                      <div class="settings-account-delete-reason-box__content__glossary">
                                          <settings-account-delete-glossary-article
                                                  class="settings-account-delete-reason-box__content__glossary__article"
                                                  *ngFor="let article of articles"
                                                  [article]="article"
                                          >
                                          </settings-account-delete-glossary-article>
                                      </div>
                                  </div>


                                  <div class="settings-account-delete-reason-box__content" *ngIf="isContentOpen(AccountDeleteReasons.Confusion)">
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.CONFUSION.TITLE'|translate}}
                                      </div>

                                      <div class="settings-account-delete-reason-box__content__support">
                                          <div class="settings-account-delete-reason-box__content__support__links">
                                              <a [innerHTML]="'ACCOUNT.DELETE.STEP.REASON.CONFUSION.LINK.MATCHPARTNER' | translate | markdown"
                                                 target="_blank"
                                                 href="https://www.bildkontakte.de/app/help#matching"
                                                 (click)="track('LoeschgrundUnuebersichtlich', 'MatchpartnerSehen')"
                                              ></a>
                                              <a [innerHTML]="'ACCOUNT.DELETE.STEP.REASON.CONFUSION.LINK.NEW_FUNCTIONS' | translate | markdown"
                                                 target="_blank"
                                                 href="https://www.bildkontakte.de/app/help"
                                                 (click)="track('LoeschgrundUnuebersichtlich','FunktionenBildkontakte')"
                                              ></a>
                                              <a [innerHTML]="'ACCOUNT.DELETE.STEP.REASON.CONFUSION.LINK.CONVERSATION_DELETE' | translate | markdown"
                                                 target="_blank"
                                                 href="https://www.bildkontakte.de/app/help"
                                                 (click)="track('LoeschgrundUnuebersichtlich','NachrichtenLoeschen')"
                                              ></a>
                                              <a [innerHTML]="'ACCOUNT.DELETE.STEP.REASON.CONFUSION.LINK.I_WANT_LIST' | translate | markdown"
                                                 target="_blank"
                                                 href="https://www.bildkontakte.de/app/help#mailbox"
                                                 (click)="track('LoeschgrundUnuebersichtlich','KennenlernListe')"
                                              ></a>
                                          </div>

                                          <div class="settings-account-delete-reason-box__content__support__box">
                                              <premium-support (clickPhoneNumber)="track('LoeschgrundUnuebersichtlich','Supportnummer')"
                                                               [noMargin]="true"
                                                               [showEmail]="false"
                                                               [showTitle]="false"
                                              ></premium-support>
                                          </div>
                                      </div>

                                      <flat-button fit-content
                                                   class="settings-account-delete-reason-box__content__button"
                                                   (click)="toHelp();track('LoeschgrundUnuebersichtlich','WeitereAntworten')"
                                      >{{'ACCOUNT.DELETE.STEP.REASON.CONFUSION.LINK.BUTTON' | translate}}</flat-button>

                                  </div>


                                  <div class="settings-account-delete-reason-box__content" *ngIf="isContentOpen(AccountDeleteReasons.RareUse)">
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_RARE_USE.TITLE'|translate}}
                                      </div>
                                      <div class="settings-account-delete-reason-box__content__text">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_RARE_USE.TEXT'|translate}}
                                      </div>
                                      <div class="settings-account-delete-reason-box__content__button">
                                          <raised-button (click)="navigationService.navigateTo(Routes.Matching);track('LoeschgrundSelten','TraumpartnerFinden')">
                                              {{'ACCOUNT.DELETE.STEP.REASON.REASON_RARE_USE.CTA'|translate}}
                                          </raised-button>
                                      </div>
                                  </div>


                                  <div class="settings-account-delete-reason-box__content" *ngIf="isContentOpen(AccountDeleteReasons.NoInterest)">
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_INTEREST.TITLE'|translate}}
                                      </div>
                                      <div class="settings-account-delete-reason-box__content__text">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_INTEREST.TEXT'|translate}}
                                      </div>
                                      <div class="settings-account-delete-reason-box__content__button">
                                          <raised-button (click)="navigationService.navigateTo(Routes.Startpage);track('LoeschgrundInteresse','TraumpartnerFinden')">
                                              {{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_INTEREST.CTA'|translate}}
                                          </raised-button>
                                      </div>
                                  </div>

                                  <div class="settings-account-delete-reason-box__content"
                                       *ngIf="isContentOpen(AccountDeleteReasons.Other)"
                                  >
                                      <div class="settings-account-delete-reason-box__content__title">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_OTHER.TITLE'|translate}}
                                      </div>

                                      <div class="settings-account-delete-reason-box__content__textBox">
                                          <text-area rows="4"
                                                     [placeholder]="'ACCOUNT.DELETE.STEP.REASON.REASON_OTHER.PLACEHOLDER'|translate"
                                                     [(value)]="otherText"
                                          ></text-area>
                                      </div>
                                  </div>


                              </div>
                          `,
               styles: [require('./settings-account-delete-reason-box.scss')],
           })

export class SettingsAccountDeleteReasonBox {
    @Input() private reason: number;
    @Input() private selectedReason: AccountDeleteReasons = 0;
    @Output() private select = new EventEmitter();

    @Output() private findElseWhereTextChange = new EventEmitter<string>();
    private _findElseWhereText: string = '';
    private set findElseWhereText(text: string) {
        this._findElseWhereText = text;
        this.findElseWhereTextChange.emit(text);
    }

    private get findElseWhereText(): string {
        return this._findElseWhereText;
    }

    @Output() private otherTextChange = new EventEmitter<string>();
    private _otherText: string = '';
    private set otherText(text: string) {
        this._otherText = text;
        this.otherTextChange.emit(text);
    }

    private get otherText(): string {
        return this._findElseWhereText;
    }

    private Routes = Routes;

    private AccountDeleteReasons = AccountDeleteReasons;

    @Input() private articleCount = 4;
    private articles: Article[] = [];

    public constructor(
        private rssService: RssService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        this.rssService.glossary.getEntrys(this.articleCount, 'bildkontakte').then(articles => this.articles = articles);
    }

    private selectReason(reason) {
        this.selectedReason = reason;
        this.select.emit(reason);
    }


    private isContentOpen(value: AccountDeleteReasons): boolean {
        return this.reason === value && this.selectedReason === value;
    }


    private toHelp() {
        window.open('https://www.bildkontakte.de/app/help', '_blank').focus();
    }

    private track(action: string, label: string) {
        this.trackingService.hit('AccountLoeschen', action, label);
    }
}
