import {
    Component,
    Input,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    ImageSize,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';

@Component({
               selector:  'premium-table-row',
               template:  `
                              <div class="premium-table-row">
                                  <div class="premium-table-row__title">
                                      <div class="premium-table-row__title__text">
                                          <ng-content></ng-content>
                                      </div>

                                      <div class="premium-table-row__info" *ngIf="!infoText.isEmpty()">
                                          <div class="tooltip"
                                               [class.open]="isTooltipOpen"
                                               (click)="isTooltipOpen = !isTooltipOpen"
                                          >
                                              <icon icon="info-outline"></icon>
                                              <div class="box">
                                                  <div class="cancel">
                                                      <icon icon="close"></icon>
                                                  </div>
                                                  {{infoText}}
                                                  <i></i>
                                              </div>
                                          </div>
                                      </div>

                                      <img class="premium-table-row__image" [routerLink]="listRoute" *ngFor="let user of list" [src]="getImage(user)" alt="">
                                  </div>

                                  <div class="premium-table-row__container">
                                      <div class="premium-table-row__container__icon"
                                           [class.premium-table-row__container__icon--color]="!premium"
                                      >
                                          <icon [icon]="icon"></icon>
                                      </div>

                                      <div class="premium-table-row__container__icon"
                                           [class.premium-table-row__container__icon--color]="premium"
                                      >
                                          <icon icon="check"></icon>
                                      </div>
                                  </div>

                                  <div class="premium-table-row__new-banner" *ngIf="newFunction">
                                      <div class="premium-table-row__new-banner__banner">
                                          {{'PREMIUM.USP.ROW.NEW' | translate}}
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./premium-table-row.scss')],
           })
export class PremiumTableRow {
    @Input() private premium: boolean = false;
    @Input() private forBasic: boolean = false;
    @Input() private list: ProfileInfo[] = [];
    @Input() private listRoute: string[] = [];
    @Input() private newFunction: boolean = false;
    @Input() private infoText: string = '';
    private isTooltipOpen: boolean = false;

    public constructor(private router: Router) {

    }

    private get icon(): string {
        return this.forBasic ? 'check' : 'close';
    }

    private getImage(user: UserBaseView): string {
        return user.getProfilePictureUrl(ImageSize['70x70']);
    }
}


