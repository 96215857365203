import {
    AfterViewInit,
    Component,
    Directive,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
} from '@angular/core';
import { MDCTextFieldHelperText } from '@material/textfield/index';
import { TranslateService } from '@ngx-translate/core';
import { getValueAccessorProvider } from 'BKBaseClass';
import { TextFieldBase } from './text-field-base';


@Directive({
               selector: 'textarea[autoresize]',
           })
export class TextareaAutosize {
    @Input('autoresize') private enable: boolean = true;

    public constructor(public elementRef: ElementRef) {
    }

    @HostListener('input', ['$event.target'])
    public resize() {
        if (!this.enable) return;

        const element = this.elementRef.nativeElement;
        element.style.height = 'auto';

        if(element && element.scrollHeight){
             element.style.height = `${element.scrollHeight}px`;
        }

    }
}


@Component({
               selector:  'text-area',
               template:  `
                              <div #textField class="mdc-text-field mdc-text-field--textarea"
                                   [class.mdc-text-field--with-trailing-icon]="!icon.isEmpty()"
                                   [class.mdc-text-field--disabled]="disabled"
                                   [class.mdc-text-field--invalid]="hasErrors"
                              >
                                  <textarea [autoresize]="autoresize"
                                            #input
                                            [id]="id"
                                            [rows]="rows"
                                            [(ngModel)]="value"
                                            class="mdc-text-field__input"
                                            [class.mdc-text-field__input--invalid]="hasErrors"
                                            [readOnly]="isReadOnly"
                                            (keypress)="filterKeyPress($event)"
                                            (paste)="filterPaste($event)"
                                            (keyup)="keyup.emit($event)"
                                            (keydown)="keydown.emit($event)"
                                            (focus)="focus.emit($event)"
                                            (blur)="blur.emit($event)"
                                  ></textarea>
                                  <icon class="mdc-text-field__icon" [icon]="icon"></icon>
                                  <div class="mdc-notched-outline"
                                       [class.mdc-notched-outline--notched]="isNotched"
                                  >
                                      <div class="mdc-notched-outline__leading"></div>
                                      <div class="mdc-notched-outline__notch" *ngIf="!placeholder.isEmpty()">
                                          <label #label
                                                 [for]="id"
                                                 class="mdc-floating-label"
                                          >{{placeholder}}</label>
                                      </div>
                                      <div class="mdc-notched-outline__trailing"></div>
                                  </div>
                              </div>
                              <div class="mdc-text-field__outer">
                                  <div class="mdc-text-field-helper-line">
                                      <div *ngIf="!hintText.isEmpty()" #helperText
                                           class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
                                           [class.mdc-text-field-helper-text--validation-msg]="hasErrors"
                                           aria-hidden="true"
                                      >
                                          {{ hintText }}
                                      </div>
                                  </div>

                                  <div class="mdc-text-field-character-counter" #counter *ngIf="maxLength">{{currentLength}} / {{maxLength}}</div>
                              </div>
                          `,
               styles: [require('./text-area.scss')],
               providers: [
                   getValueAccessorProvider(TextArea),
               ],
               host:      {},
           })
export class TextArea extends TextFieldBase implements AfterViewInit {
    @Input() private autoresize: boolean = false;
    @Input() private rows = 1;
    @Input() private icon: string = '';
    @Input() private isReadOnly: boolean = false;

    @ViewChild(TextareaAutosize, { static: false }) private autoResizeRef: TextareaAutosize;


    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        setTimeout(() => {
            this.autoResizeRef.resize();
        }, 100);
    }

    constructor(translateService: TranslateService) {
        super(translateService);
    }

}
