import { Component } from '@angular/core';
import { ConversationListDataSource } from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import { PostboxState } from '../po-box-list/po-box-list';

@Component({
               selector: 'po-box-system',
               template: '<po-box-list [dataSource]="dataSource"></po-box-list>',
           })

export class PoBoxSystem {
    public dataSource: ConversationListDataSource;

    public constructor(private dataSourceFactory: DataSourceFactory) {
    }

    public ngOnInit() {
        const state = PostboxState.previousState;

        if (!!state.dataSource) {
            this.dataSource = state.dataSource;
        } else {
            this.dataSourceFactory
                .createOnlySystemConversationListDataSource()
                .then(dataSource => {
                this.dataSource = dataSource;
                this.dataSource.loadMore();
            });
        }
    }
}
