import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Label } from '../../../../models/label';

@Component({
               selector:  'profile-interview-tags',
               template:  `
                              <div class="container">
                                  <div class="chip-list">
                                      <chip
                                              *ngFor="let label of selectable"
                                              [select]="valueSelected(label)"
                                              (click)="toggle(label)"
                                      >
                                          {{ label.localizedNames.de }}
                                      </chip>
                                  </div>
                                  <flat-button *ngIf="!selectable.length < allowed.length && !showingAll" (click)="showAll()">{{ 'PROFILE_INTERVIEW.SHOW_ALL'|translate }}</flat-button>
                              </div>
                          `,
               styles: [require('./profile-interview-tags.scss')],
           })
export class ProfileInterviewTags {
    showingAll = false;
    private _allowed: Label[] = [];

    @Input() set allowed(labels: Label[]) {
        this._allowed = labels;

        this.updateSelectable();
    }

    get allowed(): Label[] {
        return this._allowed;
    }

    private _value: Label[] = [];
    @Input() set value(labels: Label[]) {
        this._value = labels;
        this.updateSelectable();
    }

    get value(): Label[] {
        return this._value;
    }

    @Input() showInitially = 12;
    @Output() valueChange = new EventEmitter();

    selectable: Label[] = [];

    private updateSelectable() {
        const fill = Math.max(0, this.showInitially - this.value.length);
        this.selectable = [
            ...this.value,
            ...(this.allowed
                    .filter(v => !this.value.find(e => v.id === e.id))
                    .shuffle()
                    .filter((_, index) => index < fill) as Label[]),
        ];
    }

    valueSelected(v: Label) {
        return !!this.value.find(i => v.id === i.id);
    }

    toggle(v: Label) {
        if (this.valueSelected(v)) {
            const index = this.value.findIndex(i => v.id === i.id);
            if (index >= 0) this.value.splice(index, 1);
        } else {
            this.value.push(v);
        }
    }

    showAll() {
        this.showingAll = true;
        this.selectable = this.allowed;
    }

}
