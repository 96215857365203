import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    ImageSize,
    ProfileInfo,
} from 'BKModels';
import { TrackingService } from 'BKService';


/**
 * MatchingMatchComponent
 */
@Component({
               selector: 'bk-matching-match',
               template: `
        <div class="bk-matching-match">
            <div class="bk-matching-match__title">
                {{'MATCHING.MATCH.CONGRATULATION' | translate }}
            </div>
            <div class="bk-matching-match__title">
                {{'MATCHING.MATCH.YOU_HAVE_A_MATCH' | translate }}
            </div>
            <div class="bk-matching-match__text">
                {{'MATCHING.MATCH.CONTACT' | translate:partner }}
            </div>
            <div class="bk-matching-match__img-wrapper">
                <div class="bk-matching-match__heart">
                    <p>{{'MATCHING.MATCH.ANIMATION' | translate:partner }}
                </div>
                <img class="bk-matching-match__img-wrapper__img animation__left" [src]="getCurUserImage()">
                <img class="bk-matching-match__img-wrapper__img animation__right" [src]="getPartnerImage()">
            </div>

            <raised-button class="bk-matching-match__button"
                           (click)="openConversation()"
            >{{'MATCHING.MATCH.BUTTON' | translate }}</raised-button>

            <a bk-link (click)="continueMatching()">
                {{'MATCHING.MATCH.LINK' | translate }}
            </a>
        </div>

    `,
               styles: [
                   require('./matching-match.scss'),
               ],
           })
export class MatchingMatch implements OnDestroy {
    @Output("continueMatching") private _continueMatching = new EventEmitter();
    @Input() private currentUser: CurrentUser = new CurrentUser();
    @Input() private partner: ProfileInfo = new ProfileInfo();
    @Output() private toConversation = new EventEmitter();
    private interactedWithView: boolean = false;

    public constructor(private trackingService: TrackingService) {
    }

    public ngOnDestroy() {
        if(!this.interactedWithView) {
            this.trackingService.hit('Matching', 'EsIstEinMatch', 'KeineUserAktion');
        }
    }

    private getCurUserImage(): string {
        return this.currentUser.getProfilePictureUrl(ImageSize['640x640']);
    }

    private getPartnerImage(): string {
        return this.partner.getProfilePictureUrl(ImageSize['640x640']);
    }

    private openConversation() {
        this.trackingService.hit("Matching", "EsIstEinMatch", "NachrichtenSchreiben");
        this.interactedWithView = true;
        this.toConversation.emit(this.currentUser.id +'-' + this.partner.id);
    }

    private continueMatching() {
        this.trackingService.hit("Matching", "EsIstEinMatch", "Weitersuchen");
        this.interactedWithView = true;
        this._continueMatching.emit();
    }
}
