import {
    ApiResponseFrame,
    UserBaseView,
} from 'BKModels';
import { State } from '../../../models/state';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export class RadioApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public upcoming(): Promise<UserBaseView[]> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.radio.upcoming)
                   .then(frame => frame.data['result'].map(res => UserBaseView.create(res)));
    }

    public previous(): Promise<UserBaseView[]> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.radio.previous)
                   .then(frame => frame.data['result'].map(res => UserBaseView.create(res)));
    }

    public application(name: string, phone: string, message: string): Promise<State> {
        const body = {
            application: { name, phone, message }
        };
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.radio.application,  { body })
                   .then(res => State.create(res['data']));
    }

    public isOnAir(): Promise<State> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.radio.onAir)
                   .then(res => State.create(res['data']));
    }
}
