/**
 * Created by olifra on 22.05.2017.
 */
import {
    ApiResponseFrame,
    CurrentUser,
    News,
    ProfileInfo,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiNotification } from '../../../models/api-notification';
import { BadgeCounts } from '../../../models/badge-counts';
import { UserCounts } from '../../../models/user-counts';
import { ApiRequestService } from '../api-request.service';

// TODO allgemeines Errorhandling
/**
 * ApiService für den eingeloggten Benutzers
 */
export class UserApi {
    /**
     * @param apiRequestService
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public counts(): Promise<UserCounts> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.counts)
                   .then(res => {
                       if (!res.data) return new UserCounts();
                       return UserCounts.create(res.data);
                   });
    }

    public changeEmailAddress(email: string): Promise<ApiResponseFrame<any>> {
        const emailBody = {
            'email': email,
        };
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.email.change, { body: emailBody });
    }

    public resendRegCode(email: string): Promise<ApiResponseFrame<any>> {
        const emailBody = {
            'emailChange':{
                'email': {
                    newEmail: email,
                    newEmailRepetition: email,
                },
            }
        };
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.email.regCode.resend, { body: emailBody });
    }

    public abortEmailAddressChange(): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.email.abort);
    }

    public current(hide: boolean = false): Promise<ApiResponseFrame<CurrentUser>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<CurrentUser>>(Endpoints.currentUser, {
                       body: { hideStatus: hide },
                   })
                   .then((res: ApiResponseFrame<CurrentUser>) => {
                       res.data = CurrentUser.create(res.data);

                       return res;
                   });
    }

    public badgeCount(): Promise<BadgeCounts> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.userBadgeCount)
                   .then((frame) => {
                       let badgeCounts = new BadgeCounts();
                       if (!!frame.data && !!frame.data.badgeCounts) {
                           badgeCounts = BadgeCounts.createFromApi(frame.data.badgeCounts);
                       }

                       return badgeCounts;
                   });
    }

    public notifications(): Promise<ApiNotification[]> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.notifications)
                   .then((frame) => {
                       let notifications: ApiNotification[] = [];

                       for (let notification of frame.data) {
                           notifications.push(ApiNotification.createFromApi(notification));
                       }

                       return notifications;
                   });
    }

    public news(): Promise<News> {
        return this.apiRequestService.createGet(Endpoints.news.get);
    }

    public profileInfo(id: number | string, currentUser: boolean = false): Promise<ProfileInfo> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.profileInfo, {
                       body: { id },
                   })
                   .then((cur) => {
                       cur.data.birthdate = new Date(cur.data.birthdate * 1000);
                       return ProfileInfo.create(cur.data);
                   });
    }

    public report(id: number | string, reason: number, text: String) {
        return this.apiRequestService
                   .createPost(Endpoints.reportUser, {
                       body: {
                           'reportUser[userId]':     id,
                           'reportUser[reason]':     reason,
                           'reportUser[reportText]': text,
                       },
                   });
    }

    public uploadProfileInfo(profileInfo: any): Promise<ApiResponseFrame<any>> {
        const info = {};
        for (const key in profileInfo) {
            const field = `userInfoProfile[${key}]`;
            info[field] = profileInfo[key];
        }

        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.settings.profileEdit, {
                       body: info,
                   });
    }

    public stayOnBeta(stay: boolean): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
            .createPost<ApiResponseFrame<any>>(Endpoints.settings.stayOnBeta, {
                body: {
                    stay
                }
            });
    }

    public deleteAccount(password: string, deleteReason: number, deleteReasonText: string, recommendation: boolean): Promise<boolean> {
        return this.apiRequestService.createPost(Endpoints.deleteAccount, {
            body: {
                'delete_reason_text': deleteReasonText,
                'delete_reason':      deleteReason,
                'recommendation':     recommendation,
                'password':           password,
            },
            json: true,
        });

    }
}
