import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { RadioButtonOption } from 'BKModels';


@Component({
               selector:  'radio-panel',
               template:  `
                              <div class="radio-button-panel">
                                  <radio *ngFor="let option of options;"
                                         [value]="option.value"
                                         [label]="option.name"
                                         [checked]="isChecked(option.value)"
                                         (select)="value = $event;select($event)"
                                         [small]="small"
                                         [disabled]="disabled"
                                  ></radio>
                              </div>
                          `,
               styles: [require('./radio-panel.scss')],
               providers: [
                   getValueAccessorProvider(forwardRef(() => RadioPanel)),
               ],
           })

export class RadioPanel extends ValueAccessorBase<string | number> {
    @Output('onChange') private _onChange = new EventEmitter();
    @Input() private controlName: string;
    @Input() private small: boolean = false;

    @Input() private options: RadioButtonOption[] = [];

    private select(event) {
        if(this.disabled) return;
        this._onChange.emit(event);
    }

    private isChecked(val: string | number): boolean {
        return val == this.value;
    }
}
