import { DatePipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';

@Component({
               selector: 'profile-edit-row',
               template: `
                             <div class="profile-edit-row">
                                 <div *ngIf="deprecationDate != null" class="profile-edit-row__deprecated">
                                     {{ 'PROFILE_EDIT_ROW.DEPRECATION_HINT'|translate:deprecationHintParams }}
                                     <footer class="mdc-dialog__actions">
                                        <flat-button (click)="deleteContent.emit($event)">{{ 'PROFILE_EDIT_ROW.REMOVE_DEPRECATED_ACTION'|translate }}</flat-button>
                                     </footer>
                                 </div>
                                 <div class="profile-edit-row__label"
                                      *ngIf="label.length > 0"
                                 >{{label}}
                                 </div>
                                 <ng-content></ng-content>
                                 <icon *ngIf="!info.isEmpty()" 
                                       class="profile-edit-row__info" 
                                       [class.no-label]="label.isEmpty()"                            
                                       icon="info"
                                       (click)="showInfo()" > </icon>
                             </div>
                             <dialog-box #dialogInfo type="success" [rejectText]="'PROFILE_EDIT_ROW.INFO_DIALOG.CLOSE'|translate">
                                 <span [innerHTML]="info"></span>
                             </dialog-box>
                         `,
               styles: [require('./profile-edit-row.scss')],
           })

export class ProfileEditRow {
    @Input() private label: string = '';
    @Input() private info: string = '';
    @Input() private deprecationDate: Date = null;

    @Output('deleteContent') private deleteContent = new EventEmitter();

    @ViewChild('dialogInfo', { static: true }) private dialogInfo: DialogBox;

    public constructor(private datePipe: DatePipe) {
    }

    public showInfo() {
        this.dialogInfo.open();
    }

    private get deprecationHintParams() {
        return { 'deprecationDate': this.datePipe.transform(this.deprecationDate) };
    }
}
