import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ExtendedSearchCriteria,
    ExtendedSearchCriteriaProperty,
} from 'BKModels';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
               selector: 'search-extended-criteria',
               template: `
                             <div class="search-extended-criteria" *ngFor="let item of extendedCriteria.getUsed()">
                                 <chip [closeable]="true"
                                       (close)="remove(item)"
                                       (click)="showExtendedSearch.emit(item);"
                                 >
                                     {{getTitleKey(item) | translate}}
                                 </chip>
                             </div>
                         `,
               styles:   [`
                              .search-extended-criteria {
                                  display: inline-block;
                              }`,
               ],
           })

export class SearchExtendedCriteria {
    @Input() private extendedCriteria: ExtendedSearchCriteria;
    @Output() private extendedCriteriaChange = new EventEmitter();

    @Output() private showExtendedSearch = new EventEmitter();

    private remove(key: ExtendedSearchCriteriaProperty) {
        this.extendedCriteria.reset(key);
        this.extendedCriteriaChange.emit(cloneDeep(this.extendedCriteria));
    }

    private getTitleKey(item: ExtendedSearchCriteriaProperty): string {
        switch (item) {
            case ExtendedSearchCriteriaProperty.Children:
                return 'PROFILE_INFO.CHILDREN.LABEL';
            case ExtendedSearchCriteriaProperty.ChildrenWish:
                return 'PROFILE_INFO.CHILDREN_WISH.LABEL';
            case ExtendedSearchCriteriaProperty.ContactWish:
                return 'PROFILE_INFO.CONTACT_WISH.LABEL';
            case ExtendedSearchCriteriaProperty.Echeck:
                return 'SEARCH.EXTENDED.EXTENDED.CHIP.ECHECK';
            case ExtendedSearchCriteriaProperty.EyeColor:
                return 'PROFILE_INFO.EYE_COLOR.LABEL';
            case ExtendedSearchCriteriaProperty.BodyType:
                return 'PROFILE_INFO.BODYSHAPE';
            case ExtendedSearchCriteriaProperty.FilledFields:
                return 'SEARCH.EXTENDED.EXTENDED.CHIP.FILLED_FIELDS';
            case ExtendedSearchCriteriaProperty.HairColor:
                return 'PROFILE_INFO.HAIR_COLOR.LABEL';
            case ExtendedSearchCriteriaProperty.Languages:
                return 'SEARCH.EXTENDED.PERSONAL.LANGUAGE';
            case ExtendedSearchCriteriaProperty.ZodiacSign:
                return 'PROFILE_INFO.ZODIAC_SIGN.LABEL';
            case ExtendedSearchCriteriaProperty.Online:
                return 'SEARCH.EXTENDED.EXTENDED.CHIP.ONLY_ONLINE';
            case ExtendedSearchCriteriaProperty.Size:
                return 'PROFILE_INFO.SIZE';
            case ExtendedSearchCriteriaProperty.Smoker:
                return 'PROFILE_INFO.SMOKER.LABEL';
            case ExtendedSearchCriteriaProperty.Relationship:
                return 'PROFILE_INFO.RELATIONSHIP.LABEL';
            case ExtendedSearchCriteriaProperty.FullTextSearch:
                return 'SEARCH.EXTENDED.EXTENDED.TEXT_SEARCH.LABEL';
            default:

        }
    }


}
