import { Product } from './product';

export class ProductList {
    public coins: Product[];
    public premium: Product[];

    public static create(apiObject: any): ProductList {
        let productList = new ProductList();

        productList.coins = apiObject.coins.map(v => Product.create(v));
        productList.premium = apiObject.premium.map(v => Product.create(v));
        return productList;
    }
}
