import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    PremiumSubscription,
    SubscriptionType,
} from '../../../../models/premium-details';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'settings-account-payment',
               template:  `
                              <div class="premium-settings-payment">
                                  <div class="premium-settings-payment__highlight">
                                      <div class="premium-settings-payment__title"> {{'SETTINGS.ACCOUNT.PAYMENT_METHOD' | translate}}</div>
                                      <div class="premium-settings-payment__methode">
                                          <img [src]="paymentImg" class="premium-settings-payment__methode__img">
                                          {{paymentTitle | translate}}
                                      </div>

                                      <div class="premium-settings-payment__footer" *ngIf="premiumSubscription.isDebit">
                                          <flat-button (click)="changePayment()">{{'SETTINGS.ACCOUNT.CHANGE_PAYMENT_DATA' | translate}}</flat-button>
                                      </div>
                                  </div>

                              </div>
                          `,
               styles: [require('./settings-account-payment.scss')],
           })

export class SettingsAccountPayment {
    @Input() private premiumSubscription: PremiumSubscription;
    @Output() private cancelSubscription = new EventEmitter();

    private paymentMethods = {
        [SubscriptionType.Bank]:      {
            title: 'PREMIUM.BOOKING.PAYMENT.BANK',
            img:   './assets/img/payment/badgeEc.png',
        },
        [SubscriptionType.Paypal]:    {
            title: '',
            img:   './assets/img/payment/badgePaypal.svg',
        },
        [SubscriptionType.Cash]:      {
            title: 'PREMIUM.BOOKING.PAYMENT.CASH',
            img:   './assets/img/payment/badgeBar.png',
        },
        [SubscriptionType.PlayStore]: {
            title: 'PREMIUM.BOOKING.PAYMENT.PLAYSTORE',
            img:   './assets/img/payment/playstore.svg',
        },
    };

    private Routes = Routes;

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private get paymentImg(): string {
        return this.paymentMethods[this.premiumSubscription.type].img;
    }

    private get paymentTitle(): string {
        return this.paymentMethods[this.premiumSubscription.type].title;
    }

    private changePayment() {
        this.trackingService.hit('Einstellungen', 'LinkGeklickt', 'ZahlungsdatenAendernBegonnen');
        this.navigationService.navigateTo(Routes.SettingChangeSepa);
    }

}
