import {
    Component,
    Input,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { CurrentUserService } from 'BKService';

@Component({
               selector: 'system-msg-birthday',
               template: `
        <system-msg-layout>
            <div class="headline markdown p-nomargin"
                 [innerHTML]="'POST_BOX.SYSTEM_MSG.BIRTHDAY.TITLE'|translate:{
                                        'username': (this.currentUserService.currentUserObservable|async).nickname
                                        }|markdown"></div>
            <div class="image">
                <img src="./assets/img/picHeartFirework.svg" />
            </div>
            <div class="text markdown first-last-nomargin checkbox-list"
                 [innerHTML]="'POST_BOX.SYSTEM_MSG.BIRTHDAY.TEXT'|translate:{
                                        'username': (this.currentUserService.currentUserObservable|async).nickname
                                        }|markdown"></div>

            <!--
            <separator thickness="1px"></separator>
            <system-msg-action-box image="./assets/img/iconEdit.svg"
                                   textKey="MESSAGE.SYSTEM.WELCOME.ACTION.PROFILE_EDIT.TEXT"
                                   actionTextKey="MESSAGE.SYSTEM.WELCOME.ACTION.PROFILE_EDIT.ACTION"
                                   (action)="premiumCta()"></system-msg-action-box>
                                   -->
        </system-msg-layout>
    `,
               styles: [require('../system-msg-common.scss')]
           })

export class SystemMsgBirthday {
    @Input() private curUser: CurrentUser;

    public constructor(
        private currentUserService: CurrentUserService
    ) {}
}
