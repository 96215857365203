/* eslint-disable */

import {
    Component,
    Input,
} from '@angular/core';
import { EmojiPipe } from '../../../../../shared/pipes/emoji.pipe';


@Component({
               selector: 'chat-bubble',
               template: `
                             <div class="chat-bubble"
                                  [class.chat-bubble--me]="sender"
                             >
                                 <div class="chat-bubble__text"
                                      [innerHtml]="text | emoji"
                                 ></div>
                                 <div class="chat-bubble__time">
                                     {{time}}
                                 </div>
                             </div>`,
               styles: [require('./chat-bubble.scss')],
           })

export class ChatBubble {
    @Input() private lasSenderMsg: boolean = false;
    @Input() private lastDayMsg: boolean = false;
    @Input() private sender: boolean = false;
    @Input() private text: string;
    @Input() private time: string;
}
