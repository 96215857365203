import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { RegCode } from './reg-code';

const regCodeRoutes: Routes = [
    {
        path:     '',
        children: [
            { path: '', component: RegCode },
        ],
    },
];

export const RegCodeRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(regCodeRoutes);
