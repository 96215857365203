import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Label } from '../../../../models/label';

@Component({
               'selector': 'label-box',
               'template': `
                               <card-title [title]="title">
                                   <div class="labels labels__selected">
                                       <chip *ngFor="let label of selectedLabels; index as index"
                                             select="true"
                                             closeable="true"
                                             (click)="deselect(index)"
                                             (close)="deselect(index)"
                                       >{{ label.localizedNames.de }}</chip>
                                   </div>
                                   <separator class="label-separator"></separator>
                                   <div class="labels__selectable">
                                       <div class="labels">
                                           <chip *ngFor="let label of selectableLabels; index as index" (click)="select(index)">{{ label.localizedNames.de }}</chip>
                                       </div>
                                       <div *ngIf="allowExpandSelectables" class="labels__expandable">
                                           <flat-button (click)="expandSelectablesEvent.emit()">{{'PROFILE.EDIT.EXPAND_SELECTABLES' | translate}}</flat-button>
                                       </div>
                                   </div>
                                   <div class="mdc-card__action">
                                   <flat-button [disabled]="pristine" (click)="revertEvent.emit()">{{'PROFILE.EDIT.DISCARD' | translate}}</flat-button>
                                   <raised-button [disabled]="pristine" (click)="saveEvent.emit()">{{'PROFILE.EDIT.SAVE' | translate}}</raised-button>
                                   </div>
                               </card-title>`,
    styles: [require('./label-box.scss')]
           })
export class LabelBox {
    @Input('selected-labels') public selectedLabels: Label[];
    @Input('selectable-labels') public selectableLabels: Label[];
    @Input() public title: string = '---';
    @Input() public pristine: boolean = true;
    @Input() public allowExpandSelectables: boolean = false;

    @Output('select') selectEvent = new EventEmitter<number>();
    @Output('deselect') deselectEvent = new EventEmitter<number>();
    @Output('revert') revertEvent = new EventEmitter<void>();
    @Output('save') saveEvent = new EventEmitter<void>();
    @Output('expandSelectables') expandSelectablesEvent = new EventEmitter<void>();

    public select(index: number)
    {
        this.selectEvent.emit(index);
    }
    public deselect(index: number)
    {
        this.deselectEvent.emit(index);
    }
}