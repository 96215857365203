import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
} from '@angular/core';
import { ShareModule } from 'BKShared';
import chat from './chat';
import poBox from './po-box';

import { PoBoxService } from './po-box-service';
import { PoBoxRouting } from './po-box.routing';
import { SpamReport } from './spam-report/spam-report';
import systemMsg from './system-msg';

@NgModule({
              imports:      [
                  ShareModule,
                  PoBoxRouting,
              ],
              exports:      [],
              declarations: [
                  ...poBox,
                  ...chat,
                  ...systemMsg,
                  SpamReport,
              ],
              providers:    [
                  PoBoxService,
              ],
              schemas:      [CUSTOM_ELEMENTS_SCHEMA],
          })
export class PoBoxModule {
}
