import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getScrollContainer } from 'BKUtils';
import {
    Subject,
    Subscription,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileEditRouteFragment } from '../../../../../service/navigation.service';
import { Subheader } from '../../../../../shared/mat-design/subheader/subheader';
import { ProfileEditNavigationService } from '../profile-edit-navigation.service';
import { NavigationElement } from './navigation-element';

@Component({
               selector: 'navigation-list',
               template: `
                             <div class="navigation-list scroll-horizontal-hint-bp2-max">
                                 <chip *ngFor="let item of profileEditNavigationService.navigationElements"
                                       (click)="profileEditNavigationService.requestScroll(item.id)"
                                       [attr.id]="item.chipId"
                                       [class.selected]="item.selected"
                                 >
                                     {{item.titleKey | translate}}
                                 </chip>
                             </div>`,
               styles: [require('./navigation-list.scss')],
           })
export class NavigationList implements OnInit, OnDestroy {
    private static isProgramaticScroll: boolean = false;
    private unsubscribe$ = new Subject();
    private scrollStateSubscription: Subscription;

    public constructor(
        private route: ActivatedRoute,
        private elementRef: ElementRef,
        private profileEditNavigationService: ProfileEditNavigationService
    ) {
    }

    private onScroll = () => {
        if (!NavigationList.isProgramaticScroll) this.updateActiveChip();
    };

    public ngOnInit(): void {
        getScrollContainer().addEventListener('scroll', this.onScroll);
    }

    public ngOnDestroy(): void {
        this.scrollStateSubscription.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        getScrollContainer().removeEventListener('scroll', this.onScroll);
    }

    public ngAfterViewInit() {
        this.updateActiveChip();

        this.scrollStateSubscription = this.profileEditNavigationService.isScrolling$.subscribe(
            value => {
                if (value) this.startScrolling();
                else this.endScrolling();
            });
    }

    public get headerHeight() {
        const mainHeader = document.getElementById('main-header');
        const subheaderHeight = Subheader._getHeight();
        return mainHeader.clientHeight + subheaderHeight + 8;
    }

    private startScrolling() {
        NavigationList.isProgramaticScroll = true;

        if (!!this.profileEditNavigationService.navigationElements) {
            this.profileEditNavigationService.navigationElements.forEach(item => item.select = false);
        }
    }

    private endScrolling() {
        NavigationList.isProgramaticScroll = false;
        this.updateActiveChip();
    }

    private updateActiveChip() {
        this.profileEditNavigationService.navigationElements.forEach(item => {
            const isScrolledToBottom = getScrollContainer().scrollTop + getScrollContainer().containerHeight === getScrollContainer().scrollHeight;
            if (isScrolledToBottom) {
                item.select = item.isLastChild;
            } else {
                item.select = item.isScrolledInBox(getScrollContainer().scrollTop, this.headerHeight);
            }
        });
    }
}
