import { Injectable } from '@angular/core';
import { DataLayerService } from './dataLayer.service';
import { LoginEvents } from './login.events';

@Injectable({ providedIn: 'root' })
export class TrackingService {
    public readonly login = new LoginEvents((prop) => this.push(prop));

    public constructor(private dataLayerService: DataLayerService) {}

    public push(event: Object): void {
        this.dataLayerService.push(event);
    }

    public hit(category: string, action: string, label: string, value: any = '') {
        let hitEvent = {
            'event':         'allgemeinesEvent',
            'eventCategory': category,
            'eventAction':   action,
            'eventLabel':    label,
            'eventValue':    value.toString(),
        };

        this.push(hitEvent);
    }
}
