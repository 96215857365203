import { Component } from '@angular/core';

@Component({
               selector: 'settings',
               template: `
                             <subheader class="is-hidden-tablet">
                                 <div class="title-bar">
                                     <div class="title">{{ 'LAYOUT.HEADER.SETTINGS'|translate }}</div>
                                     <separator thickness="1px"></separator>
                                 </div>
                                 <settings-tabbar></settings-tabbar>
                             </subheader>

                             <router-outlet></router-outlet>
                         `,
               styles: [require('./settings.scss')]
           })

export class Settings {
}
