import { Component } from '@angular/core';
import { TrackingService } from 'BKService';

@Component({
               selector: 'premium-landing-page-awards',
               template: `
                             <awards (trackRequest)="trackEvent($event)"></awards>`,
           })

export class PremiumLandingPageAwards {

    constructor(
        private trackingService: TrackingService,
    ) {
    }


    public trackEvent(event: {action: string, label: string}) {
        this.trackingService.hit('PremiumLandingPage', event.action, event.label);
    }
}
