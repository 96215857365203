import * as moment from 'moment';
import {
    Duration,
    DurationUnit,
} from '../utils/duration';

export enum SubscriptionType {
    ITunes = 'ITunes',
    PlayStore = 'playStore',
    Paypal = 'paypal',
    Present = 'present',
    Cash = 'cash',
    Transfer = 'transfer',
    Bank = 'bank',
    Phone = 'phone',
    Unknown = 'unknown'
}


export enum Period {
    Day = 'D',
    Week = 'W',
    Month = 'M',
}


export class PremiumSubscription {
    public period: Duration = new Duration(0, DurationUnit.Day);
    public price: number;
    public type: SubscriptionType;
    public tariffId: number;

    private SubscriptionTypeTranslation = {
        [SubscriptionType.ITunes]:    'PREMIUM_OVERVIEW.PAYMENT.ITUNES',
        [SubscriptionType.PlayStore]: 'PREMIUM_OVERVIEW.PAYMENT.PLAY_STORE',
        [SubscriptionType.Paypal]:    'PREMIUM_OVERVIEW.PAYMENT.PAYPAL',
        [SubscriptionType.Present]:   'PREMIUM_OVERVIEW.PAYMENT.PRESENT',
        [SubscriptionType.Cash]:      'PREMIUM_OVERVIEW.PAYMENT.CASH',
        [SubscriptionType.Transfer]:  'PREMIUM_OVERVIEW.PAYMENT.TRANSFER',
        [SubscriptionType.Bank]:      'PREMIUM_OVERVIEW.PAYMENT.DEBIT',
        [SubscriptionType.Phone]:     'PREMIUM_OVERVIEW.PAYMENT.PHONE',
    };


    public static create(obj: any): PremiumSubscription {
        const res =  new PremiumSubscription();
        res.period = Duration.parsePeriod(obj.period);
        res.price = obj.price;
        res.type = obj.type;
        res.tariffId = obj.tariffId;
        return res;
    }

    public get isEmpty(): boolean {
        return !this.price || this.period.isEmpty;
    }

    public get isPresent() {
        return this.type === SubscriptionType.Present;
    }

    public get isPayPal(): boolean {
        return this.type === SubscriptionType.Paypal;
    }

    public get isDebit(): boolean {
        return this.type === SubscriptionType.Bank;
    }

    public get isCash(): boolean {
        return this.type === SubscriptionType.Cash;
    }

    public get isITunes(): boolean {
        return this.type === SubscriptionType.ITunes;
    }

    public get isUnknown(): boolean {
        return this.type === SubscriptionType.Unknown;
    }

    public get isPlayStore(): boolean {
        return this.type === SubscriptionType.PlayStore;
    }

    public get isTransfer(): boolean {
        return this.type === SubscriptionType.Transfer;
    }

    public get isPhone(): boolean {
        return this.type === SubscriptionType.Phone;
    }

    public get typeTranslationKey(): string {
        return this.SubscriptionTypeTranslation[this.type];
    }
}

export class PremiumActiveSubscription extends PremiumSubscription {
    public expireAt: Date;

    public get autoRenewing(): boolean {
        return this.type in [SubscriptionType.Bank, SubscriptionType.Paypal, SubscriptionType.ITunes, SubscriptionType.PlayStore];
    }

    public get remainingDays(): number {
        // Ein Tag wird drauf gerechnet da expireAt der letzte Tag ist an dem ein Benutzer Premium hat.
        return moment(this.expireAt).add(1, 'days').diff(moment(), 'days')
    }

    public static create(obj): PremiumActiveSubscription {
        const res = new PremiumActiveSubscription();
        res.period = Duration.parsePeriod(obj.period);
        res.price = obj.price;
        res.type =  obj.type;
        res.tariffId =  obj.tariffId;
        res.expireAt = obj.expireAt;
        return res;
    }

}

export class PremiumDetails {
    public active: PremiumActiveSubscription = new PremiumActiveSubscription();
    public subscriptions: PremiumSubscription[] = [];

    public constructor() {
    }

    public static create(obj): PremiumDetails {
        const detail = new PremiumDetails();
        detail.active = PremiumActiveSubscription.create(obj.active);
        detail.subscriptions = obj.subscriptions.map(subscription => PremiumSubscription.create(subscription));

        return detail;
    }

    public get hasSubscription(): boolean {
        return !this.subscriptions.isEmpty();
    }
}

export class PremiumPeriod {
    public day: number = 0;
    public week: number = 0;
    public month: number = 0;

    constructor(period: string) {
        if (period) {

            let time = '';
            period.split('').forEach(it => {
                if (it.isLetter()) {
                    this.addNumberToPeriod(time.toInt(), it as Period);
                    time = '';
                } else {
                    time += it;
                }
            });
        }
    }

    public get isEmpty(): boolean {
        return this.day === 0 && this.week === 0 && this.month === 0;
    }

    public get periodArray() {
        const res = [];
        if (this.day > 0) res.push({ count: this.day, type: Period.Day });
        if (this.week > 0) res.push({ count: this.week, type: Period.Week });
        if (this.month > 0) res.push({ count: this.month, type: Period.Month });
        return res;
    }

    private addNumberToPeriod(number, period: Period) {
        switch (period) {
            case Period.Day:
                this.day += number;
                break;
            case Period.Week:
                this.week += number;
                break;
            case Period.Month:
                this.month += number;
                break;
            default:
                throw new Error(`Unsuported period: ${period}`);
        }
    }
}

