import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    CurrentUser,
    Image,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import {
    CurrentUserService,
    ModalService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    NavigationService,
    StartpageRouteFragment,
    Routes,
} from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';

@Component({
               selector: 'selected-partner',
               template: `
                             <card-title [title]="'SELECTED-PARTNER.TITLE' | translate"
                                         infoIcon="info-outline"
                                         (clickInfo)="openExplainBox()"
                                         *ngIf="userList.length > 0"
                                         id="selectedUserCard"
                             >
                                 <div>{{'SELECTED-PARTNER.CONTENT' | translate}}</div>
                                 <div class="selected-partner__content">
                                     <div *ngFor="let user of userList"
                                          class="selected-partner__item"
                                          (click)="clickOnItem(user)"
                                     >

                                         <div class="selected-partner__item__image">
                                             <div class="selected-partner__item__image__overlay" *ngIf="user.basicView">
                                                 <icon no-algin icon="crown-outline"></icon>
                                             </div>
                                             <online-state class="selected-partner__item__image__online"
                                                           *ngIf="user.isOnline"
                                                           [state]="user.online"
                                             ></online-state>
                                             <img [src]="getImage(user)">
                                         </div>


                                         <div class="selected-partner__item__bar">
                                             <div class="selected-partner__item__bar__text"> {{user.matchValue}}% Match</div>
                                             <div class="selected-partner__item__bar__bar" [style.width.%]="user.matchValue"></div>
                                         </div>

                                         <div class="selected-partner__item__text" *ngIf="user.basicView">
                                             <div class="selected-partner__item__text__name-blurred"></div>
                                             <div class="selected-partner__item__text__city-blurred"></div>
                                         </div>    
                                         <div class="selected-partner__item__text" *ngIf="!user.basicView">
                                             {{user.nickname}},
                                             <span class="selected-partner__item__text__age">{{user.age}}</span>
                                             <div class="selected-partner__item__text__city">{{user.city}}</div>
                                         </div>
                                     </div>
                                 </div>
                                 <div class="selected-partner__footer" *ngIf="!currentUser.isPremium()">
                                     <flat-button premium (click)="toPremium()">{{'SELECTED-PARTNER.UNLOCK' | translate}}</flat-button>
                                 </div>
                             </card-title>

                             <dialog-box #explain [title]="'SELECTED-PARTNER.EXPLAIN-DIALOG.TITLE' | translate"
                                         [rejectText]="'SELECTED-PARTNER.EXPLAIN-DIALOG.CLOSE' | translate"
                             >
                                 <div [innerHTML]="'SELECTED-PARTNER.EXPLAIN-DIALOG.CONTENT' | translate | markdown"></div>
                             </dialog-box>


               `,
               styles: [require('./selected-partner.scss')],
           })

export class SelectedPartner implements OnInit, OnDestroy, AfterViewInit {
    private unsubscribe$ = new Subject();
    private currentUser: CurrentUser = new CurrentUser();
    @Input() private userList: ProfileInfo[] = [];

    @ViewChild('explain', { static: true }) private explainDialog: DialogBox;

    public constructor(
        private currentUserService: CurrentUserService, private modalService: ModalService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private activatedRoute: ActivatedRoute,
        private trackingService: TrackingService
    ) {
    }

    public ngOnInit(): void {
        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(currentUser => this.currentUser = currentUser);
    }

    public ngAfterViewInit() {
        if (this.activatedRoute.snapshot.fragment === StartpageRouteFragment.SelectedUser) {
            setTimeout(() => {
                document.getElementById('selectedUserCard')
                        .scrollIntoView({ block: 'center' });
            });
        }
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private getImage(user: ProfileInfo): string {
        return Image.getUrlFromString('285x285', user.image, user.basicView);
    }

    private clickOnItem(user: ProfileInfo) {
        this.trackingService.hit('Startseite', 'FuerDichAusgewaehlt', 'AufrufProfil');

        if (user.basicView) {
            this.toPremium();
        } else {
            this.modalService.profileView.open(user.id);
        }
    }

    private toPremium() {
        this.navigationService
            .navigateTo(Routes.Startpage, { fragment: StartpageRouteFragment.SelectedUser })
            .then(() => this.premiumService.navigateToPremium(OrderReason.SelectedUser));
    }

    private openExplainBox() {
        this.explainDialog.open();
    }

}
