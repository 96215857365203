import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ShareModule } from 'BKShared';
import { Form } from './form/form';
import { HeaderOut } from './header/header-out';
import { InputWrapper } from './input-wrapper/input-wrapper';
import { AppLinkItem } from './outer-footer/app-link/app-link-list';
import { FooterFlagItem } from './outer-footer/flag-list/flag';
import { FooterFlagList } from './outer-footer/flag-list/flag-list';
import { FooterOut } from './outer-footer/footer/footer-out';
import { SectionOut } from './section/section-out';

const components = [
    FooterOut,
    FooterFlagList,
    FooterFlagItem,
    AppLinkItem,
    HeaderOut,
    SectionOut,
    Form,
    InputWrapper,
];

@NgModule({
              imports:      [TranslateModule, ShareModule],
              exports:      [...components],
              declarations: [...components],
              providers:    [],
          })
export class OuterComponentModule {
}
