import { Injectable } from '@angular/core';
import {
    Achievements,
    ApiResponseFrame,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiRequestService } from '../api-request.service';

@Injectable()
export class AchievementsApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public list(): Promise<Achievements> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<Achievements>>(Endpoints.achievement.list)
                   .then((res: ApiResponseFrame<Achievements>) => {
                       const data = res ? res.data.achievements : [];
                       return Achievements.create(data);
                   });
    }
}
