import {
    Component,
    Input,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';

@Component({
               selector: 'from-till',
               template: `
        <div class="bk-age-from-till">
            <range [min]="minValue"
                      [max]="maxValue"
                      [margin]="5"
                      [tooltips]="[true, true]"
                      [connect]="[false, true, false]"
                      [value]="value"
                      (valueChange)="change($event)"
            ></range>
        </div>

    `,
               styles: [
                   `
        .bk-age-from-till {
            width: 100%;
        }
    `,
               ],
               providers: [
                   getValueAccessorProvider(SelectFromTill),
               ],
           })

export class SelectFromTill extends ValueAccessorBase<[number, number]> {
    @Input('tll') private maxValue: number = 99;

    @Input('from') private minValue: number = 18;

    private change($event) {
        this.value = $event;
    }
}
