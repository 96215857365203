import {
    Component,
    Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';

@Component({
               selector: 'lovestory-formular-consent',
               template: `
        <form [formGroup]="group">
            <checkbox formControlName="agree">
    <span class="ls-form-consent__lable">
        {{'LOVESTORY.FORMULAR.CONSENT.TEXT' | translate}}
    </span>
            </checkbox>

            <div class="ls-form-consent__box"
                 *ngIf="group.controls.agree.value"
            >
                <div class="ls-form-consent__box__title">{{'LOVESTORY.FORMULAR.CONSENT.ADRESS' | translate}}:</div>
                <div class="ls-form-consent__box__line">
                    <text-field class="ls-form-consent__box__line__input"
                                [placeholder]="'LOVESTORY.FORMULAR.CONSENT.STREET' | translate"
                                formControlName="street"
                    ></text-field>
                    <text-field class="ls-form-consent__box__line__input"
                                [placeholder]="'LOVESTORY.FORMULAR.CONSENT.NUM' | translate"
                                formControlName="number"
                    ></text-field>
                </div>
                <div class="ls-form-consent__box__line">
                    <text-field class="ls-form-consent__box__line__input"
                                [placeholder]="'LOVESTORY.FORMULAR.CONSENT.ZIP' | translate"
                                formControlName="zip"
                    ></text-field>
                    <text-field class="ls-form-consent__box__line__input"
                                [placeholder]="'LOVESTORY.FORMULAR.CONSENT.CITY' | translate"
                                formControlName="city"
                    ></text-field>
                </div>

                <div class="ls-form-consent__box__title">{{'LOVESTORY.FORMULAR.CONSENT.AGE' | translate}}:</div>
                <div class="ls-form-consent__box__line">
                    <dropdown formControlName="yourAge"
                              class="ls-form-consent__box__line__input"
                              [placeholder]="'LOVESTORY.FORMULAR.CONSENT.YOU' | translate"
                    >
                        <dropdown-option *ngFor="let item of options"
                                         [value]="item">
                            {{item}}
                        </dropdown-option>
                    </dropdown>

                    <dropdown formControlName="partnerAge"
                              class="ls-form-consent__box__line__input"
                              [placeholder]="'LOVESTORY.FORMULAR.CONSENT.PARTNER' | translate"
                    >
                        <dropdown-option *ngFor="let item of options"
                                         [value]="item">
                            {{item}}
                        </dropdown-option>
                    </dropdown>
                </div>
                <div class="ls-form-consent__box__title">{{'LOVESTORY.FORMULAR.CONSENT.TELEFON' | translate}}:</div>
                <text-field [placeholder]="'LOVESTORY.FORMULAR.CONSENT.TELEFON' | translate"
                            formControlName="tele"
                            type="number"
                ></text-field>
            </div>
        </form>

    `,
               styles: [require('./lovestory-formular-consent.scss')],
               providers: [
                   getValueAccessorProvider(LovestoryFormularConsent),
               ],
           })

export class LovestoryFormularConsent extends ValueAccessorBase<any> {
    @Input() private group: FormGroup = new FormGroup({});

    private options = Array.from(new Array(82), (val, index) => index + 18);
}
