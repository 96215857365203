import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CurrentUser } from 'BKModels';

export enum ListItemType {
    DefaultUser,
    Advertisement,
    Conversation,
    Lovestory,
    Ignore,
    Favorite,
    PreviewList,
    Matching,
    MatchingSecondChance
}

export class ListItem<T> {
    public constructor(public type: ListItemType, public item: T) {
    }
}

@Component({
               selector: 'list-item-selector',
               template: `
                             <ng-container [ngSwitch]="listItem.type">
                                 <list-default-user-item *ngSwitchCase="ListItemType.DefaultUser"
                                                         [user]="listItem.item"
                                                         (onOpenProfile)="onOpenProfile.emit()"
                                                         (onOpenConversation)="onOpenConversation.emit()"
                                                         (onAddFavorite)="onAddFavorite.emit()"
                                                         (onDeleteFavorite)="onDeleteFavorite.emit()"
                                                         (gotoPremium)="gotoPremium.emit()"
                                 ></list-default-user-item>                                 
                                 
                                 <list-matching-user-item *ngSwitchCase="ListItemType.Matching"
                                                         [user]="listItem.item"
                                                         [currentUser]="currentUser"
                                                         (onOpenProfile)="onOpenProfile.emit()"
                                                         (onVoteYes)="onVoteYes.emit()"
                                                         (onVoteMaybe)="onVoteMaybe.emit()"
                                                         (onVoteNo)="onVoteNo.emit()"
                                                         (gotoPremium)="gotoPremium.emit()"
                                 ></list-matching-user-item>             
                                 
                                 <list-matching-second-chance-item *ngSwitchCase="ListItemType.MatchingSecondChance"
                                                                   [user]="listItem.item" 
                                                                   [currentUser]="currentUser"
                                                                   (onOpenProfile)="onOpenProfile.emit()" 
                                                                   (onVoteMaybe)="onVoteMaybe.emit()" 
                                                                   (onVoteYes)="onVoteYes.emit()" 
                                                                   (gotoPremium)="gotoPremium.emit()"
                                 ></list-matching-second-chance-item>

                                 <list-preview-list-user-item *ngSwitchCase="ListItemType.PreviewList"
                                                              [user]="listItem.item"
                                                              (onOpenProfile)="onOpenProfile.emit()"
                                                              (gotoPremium)="gotoPremium.emit()"
                                 ></list-preview-list-user-item>

                                 <list-ignore-item *ngSwitchCase="ListItemType.Ignore"
                                                   [item]="listItem.item"
                                                   (remove)="removeIgnoredUser.emit($event)"
                                 ></list-ignore-item>

                                 <list-advertisement-item *ngSwitchCase="ListItemType.Advertisement" [slotId]="listItem.item"></list-advertisement-item>

                             </ng-container>


                         `,
               styles: [require('./list-item-selector.scss')],
           })

export class ListItemSelector {
    @Input() currentUser = new CurrentUser();
    @Input() listItem: ListItem<any>;
    ListItemType = ListItemType;

    @Output() onOpenProfile = new EventEmitter();
    @Output() onOpenConversation = new EventEmitter();
    @Output() onAddFavorite = new EventEmitter();
    @Output() onDeleteFavorite = new EventEmitter();
    @Output() gotoPremium = new EventEmitter();
    @Output() removeIgnoredUser = new EventEmitter();
    @Output() onVoteYes = new EventEmitter();
    @Output() onVoteNo = new EventEmitter();
    @Output() onVoteMaybe = new EventEmitter();

}
