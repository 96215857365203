import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import * as moment from 'moment';
import { PremiumDetails } from '../../../../../models/premium-details';

@Component({
               selector:  'premium-cancel-successful',
               template:  `
                              <div class="premium-cancel-successful__title">{{'PREMIUM.CANCEL.SUCCESSFUL.TITLE' | translate}}</div>
                              <div class="premium-cancel-successful__text" [innerHTML]="'PREMIUM.CANCEL.SUCCESSFUL.TEXT' | translate:{expireAt: expireAt} | markdown"></div>

                              <div class="premium-cancel-successful__box">
                                  <icon icon="tipps-outline" class="premium-cancel-successful__box__icon"></icon>
                                  <div class="premium-cancel-successful__box__content">
                                      <div class="premium-cancel-successful__box__content--title">{{'PREMIUM.CANCEL.SUCCESSFUL.TIPP.TITLE' | translate }}</div>
                                      {{'PREMIUM.CANCEL.SUCCESSFUL.TIPP.TEXT' | translate}}
                                      <div class="premium-cancel-successful__box__content--small">{{'PREMIUM.CANCEL.SUCCESSFUL.TIPP.EXPLAIN' | translate}}</div>
                                  </div>
                              </div>


                              <div class="premium-cancel-successful__email" [innerHTML]="'PREMIUM.CANCEL.SUCCESSFUL.EMAIL' | translate:{ email: email }"></div>

                              <div class="premium-cancel-successful__footer">
                                  <raised-button (click)="onButtonClick()">{{'PREMIUM.CANCEL.SUCCESSFUL.TIPP.BACK_TO_STARTPAGE' | translate}}</raised-button>
                              </div>

                          `,
               styles: [require('./premium-cancel-successful.scss')],
           })

export class PremiumCancelSuccessful {
    @Input() private premiumDetails: PremiumDetails = new PremiumDetails();
    @Input() private email: String;
    @Output() private buttonClick = new EventEmitter();

    private get expireAt() {
        return moment(this.premiumDetails.active.expireAt).format('DD.MM.YYYY');
    }

    constructor(private trackingService: TrackingService) {
    }

    private onButtonClick() {
        this.trackingService.hit('PremiumKuendigen', 'Kuendigen', 'Erfolgreich');
        this.buttonClick.emit();
    }
}
