import {
    Component,
    Input,
} from '@angular/core';
import {
    Gender,
    ProfileInfo,
} from 'BKModels';

@Component({
               selector: 'profile-search',
               template: `
        <div class="profile-info-title">Ich suche:</div>
        <div class="mdc-layout-grid profile-over-me">
            <div class="mdc-layout-grid__inner">
                <div class="mdc-layout-grid__cell--span-12">
                    <match-criteria [user]="user" ></match-criteria>
                </div>
                <div class="mdc-layout-grid__cell--span-12">
                    <profile-info-item [title]="'PROFILE_INFO.GENDER.LABEL' | translate"
                                       icon="wc"
                    >
                        {{user.searchGender | gender | translate}}
                    </profile-info-item>
                    <profile-info-item [title]="'PROFILE_INFO.CONTACT_WISH.LABEL' | translate"
                                       icon="person"
                    >
                        {{user.contactWish | contactWish | translate}}
                    </profile-info-item>
                </div>
                <div class="mdc-layout-grid__cell--span-12">
                    <profile-info-item [title]="personOfDreamLabel | translate"
                                       icon="match"
                    >
                        <div [innerHTML]="user.personOfDream | emoji" class="emoji-small"></div>
                    </profile-info-item>
                </div>
                <div class="mdc-layout-grid__cell--span-12">
                    <profile-info-item [title]="'PROFILE_INFO.AGE' | translate"
                                       icon="search-age"
                    >
                        {{user.ageFrom}} - {{user.ageTill}} {{'PROFILE_INFO.AGE_IN_YEARS' | translate}}
                    </profile-info-item>
                </div>
            </div>
        </div>
    `,
               styles: [require('../profile-info.scss')],
           })

export class ProfileSearch {
    @Input() private user: ProfileInfo;

    private get personOfDreamLabel(): string {
        switch (this.user.searchGender) {
            case Gender.Male:
                return "PROFILE_INFO.PERSON_OF_DREAM.MALE";
            case Gender.Female:
                return "PROFILE_INFO.PERSON_OF_DREAM.FEMALE";
            default:
                return "PROFILE_INFO.PERSON_OF_DREAM";
        }
    }
}
