import {
    Component,
    ElementRef,
    Input,
} from '@angular/core';

@Component({
               selector:  'intern-header',
               template:  `
                              <div class="intern-header__top-row">
                                  <div class="intern-header__top-row__left"
                                       [class.is-hidden-tablet]="showMenuContent"
                                  >
                                      <ng-content select="[left]"></ng-content>
                                  </div>
                                  <div class="intern-header__top-row__center"
                                       [class.is-hidden-tablet]="showMenuContent"
                                  >
                                      <ng-content select="[center]"></ng-content>
                                  </div>
                                  <div class="intern-header__top-row__right"
                                       [class.is-hidden-tablet]="showMenuContent"
                                  >
                                      <ng-content select="[right]"></ng-content>
                                  </div>
                                  <div class="intern-header__top-row__menu is-hidden-desktop"
                                    [class.intern-header__top-row__menu__content--open]="showMenuContent"
                                  >
                                      <ng-content select="[menu]"></ng-content>
                                  </div>

                              </div>
                              <div class="intern-header__extra-row is-hidden-desktop"
                                   [class.is-hidden-tablet]="showMenuContent"
                              >
                                  <ng-content select="[extra]"></ng-content>
                              </div>

                          `,
               styles: [require('./intern-header.scss')],
           })

export class InternHeader {
    @Input() private showMenuContent: boolean = false;

    public constructor(private element: ElementRef) {
    }


    private get height() {
        const computedHeight = window.getComputedStyle(this.element.nativeElement.firstChild.firstChild).height;
        const heightNumber = parseInt(computedHeight.replace('px', ''));
        return heightNumber;
    }
}
