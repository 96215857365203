import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'arrow-link',
               template: `
        <a (click)="nav()"
        [class.link--blue]="blueLink"
        >
            <ng-content></ng-content>
            <icon class="arrow-link__icon"
                  icon="arrow-forward-ios"
            ></icon>
        </a>       `,
               styles:   [
                   `
        .arrow-link__icon {
            font-size: 10px;
        }
    `,
               ],
           })

export class ArrowLink {
    @Input() private href: string = '';
    @Input() private target: string;
    @Input() private blueLink: boolean = false;


    private nav() {
        if (this.href.length > 0) {
            window.open(this.href, this.target);
        }
    }
}
