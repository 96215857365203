import { CurrentUser } from 'BKModels';
import * as moment from 'moment';
import {
    PaymentType,
    Product,
} from '../models/product';
import { Tariff } from '../models/tariff';
import {
    navigationRoute,
    RouteParameter,
    Routes,
} from '../service/navigation.service';
import { DurationUnit } from './duration';
import { isDevMode } from './utils';


export class PaypalLink {
    private a1: string = '';
    private a3: string = '';
    private amount: string = '';
    private business = 'paypal@bildkontakte.de';
    private charset = 'UTF%2d8';

    private cmd = '';
    private cpp_header_image: string = '';
    private currency_code = 'EUR';
    private custom: string = null;
    private item_name: string = '';
    private item_number: string = '';
    private lc: string = '';
    private no_note = 1;
    private no_shipping = 1;
    private p1: string = '';
    private p3: string = '';
    private sra = 1;
    private src = 1;
    private t1: string = '';
    private t3: string = '';

    private return: string = '';

    private paypalLink = '';

    static get Builder() {
        class Builder {
            private cmd: string = '';
            private userHasActiveSubscription = false;
            private tariff: Tariff;
            private currentUser: CurrentUser;
            private receiverId: number;
            private itemName: string;
            private cppHeaderImage: string = 'https://www.bildkontakte.de/images/header800';
            private localeString: string = 'de';

            private paypalLink = 'https://www.paypal.com/cgi-bin/webscr';
            private sandboxLink = 'https://sandbox.paypal.com/cgi-bin/webscr';
            private returnRoute: Routes = Routes.PremiumBookingAfterSale;


            public build(): string {
                const link = new PaypalLink();

                link.item_name = this.itemName;
                link.item_number = `${this.tariff.tariffId}-${this.receiverId}-${this.currentUser.id}`;
                link.lc = this.localeString;
                link.cpp_header_image = this.cppHeaderImage;

                if (this.userHasActiveSubscription) {
                    link.amount = this.tariff.price.toFixed(2);
                } else {
                    link.a1 = this.tariff.price.toFixed(2);
                    link.p1 = this.tariff.period.amount.toString();
                    link.t1 = this.durationUnitMap[this.tariff.period.unit];
                    link.a3 = this.tariff.subscriptionPrice.toFixed(2);
                    link.p3 = this.tariff.subscriptionPeriod.amount.toString();
                    link.t3 = this.durationUnitMap[this.tariff.period.unit];
                }
                link.cmd = this.cmd;
                link.paypalLink = isDevMode ? this.sandboxLink : this.paypalLink;
                link.return = this.createReturnLink();

                return link.buildLink();
            }

            public item(tariff: Tariff, currentUser: CurrentUser, receiverId: number = currentUser.id): Builder {
                this.tariff = tariff;
                this.currentUser = currentUser;
                this.receiverId = receiverId;
                return this;
            }

            public name(itemName: string): Builder {
                this.itemName = itemName;
                return this;
            }

            private durationUnitMap: { [key in DurationUnit]: string } = {
                [DurationUnit.Day]:   'D',
                [DurationUnit.Week]:  'W',
                [DurationUnit.Month]: 'M',
                [DurationUnit.Year]:  'A',
            };

            public locale(locale: string): Builder {
                const firstSign = locale.substr(0, 2);
                this.localeString = firstSign.toUpperCase();
                const imgLink = 'https://www.bildkontakte.de/images/header800';
                this.cppHeaderImage = (firstSign == 'de') ? `${imgLink}.jpg` : `${imgLink}-${firstSign}.jpg`;
                return this;
            }

            public withActiveSubscription(flag: boolean): Builder {
                this.cmd = flag ? '_xclick' : '_xclick-subscriptions';
                this.userHasActiveSubscription = flag;
                return this;
            }

            private createReturnLink(): string {
                const protocol = location.protocol;
                const hostname = location.hostname;
                const port = location.port ? ':' + location.port : '';
                const paymentType = PaymentType.Paypal;
                let startDate = moment();

                const curDate = moment();
                const premiumTill = moment(this.currentUser.premiumTill);
                if(premiumTill.isBefore(curDate)) {
                    startDate = premiumTill.add(1, 'd');
                }

                const uri = navigationRoute(Routes.PremiumBookingAfterSale, {
                    [RouteParameter.TariffId]: this.tariff.tariffId,
                    [RouteParameter.PurchaseDate]: moment().unix() * 1000,
                    [RouteParameter.StartDate]: startDate.unix() * 1000,
                    [RouteParameter.PaymentType]: paymentType,

                });

                return `${protocol}//${hostname}${port}/${uri}`;
            }
        }

        return Builder;
    }

    public buildLink(): string {
        const param = Object.entries(this)
                            .filter(([key]) => key !== 'paypalLink')
                            .map(([key, value]) => {
                                if (key === 'charset') return `${key}=${value}`;
                                if (value) return `${key}=${this.encodeValue(value)}`;
                                return '';
                            }).filter(cur => cur.length > 0)
                            .join('&');

        return `${this.paypalLink}?${param}`;
    }

    protected encodeValue(value) {
        return encodeURIComponent(value)
            .replace(/\./g, '%2e');
    }
}
