import {
    Component,
    Input,
} from '@angular/core';
import { Article as ArticleModel} from '../../../service/rss/childs/rss-feed';


@Component({
               selector: 'article',
               template: `
        <div class="article"
        >
            <img [src]="article.image"
                 alt=""
                 class="article__image"
            >
            <div class="article__headline">{{article.title}}</div>
            <div class="article__text">{{article.text}}</div>


            <arrow-link [href]="article.link" class="article__link"
            >{{'BK_HOME.MAGAZINE.READ_MORE'|translate}}</arrow-link>
        </div>
    `,
               styles: [require('./article.scss')],
           })

export class Article {
    @Input() private article: ArticleModel;
}
