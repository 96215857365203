import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ContactWish,
    Gender,
    ProfileInfo,
} from 'BKModels';
import { maxLengthMap } from '../../profile-edit/profile-edit';
import { ProfileEditBoxBase } from '../profile-edit-box/profile-edit-box-base';

@Component({
               selector: 'profile-edit-search',
               template: `
                             <profile-edit-box [title]="'PROFILE-EDIT.TITLE.SEARCH'|translate"
                                               [group]="group"
                                               (save)="save($event)"
                                               (reset)="reset($event)"
                             >
                                 <form [formGroup]="group">
                                     <profile-edit-row [label]="'PROFILE_INFO.YOU_SEARCH' | translate">
                                         <radio-panel [options]="searchGender"
                                                      formControlName="searchGender"
                                         ></radio-panel>
                                     </profile-edit-row>
                                     <profile-edit-row slider
                                                       [label]="'PROFILE_INFO.YOU_SEARCH_AGE' | translate"
                                     >
                                         <from-till formControlName="searchAge"></from-till>
                                     </profile-edit-row>

                                     <profile-edit-row>
                                         <dropdown [placeholder]="'PROFILE_INFO.CONTACT_WISH.LABEL' | translate"
                                                   formControlName="contactWish"
                                         >
                                             <ng-container *ngFor="let item of contactWish">
                                                 <dropdown-option [value]="item"
                                                                  [selected]="item == getGroupValue('contactWish')"
                                                                  [hidden]="item == 0"
                                                 >
                                                     {{'PROFILE_INFO.CONTACT_WISH.OPTIONS.' + item  | translate }}
                                                 </dropdown-option>
                                             </ng-container>
                                         </dropdown>
                                     </profile-edit-row>
                                     
                                     <profile-edit-row>
                                         <text-area [autoresize]="true"
                                                    [placeholder]="personOfDreamLabel | translate"
                                                    [maxLength]="maxLengthMap.personOfDream"
                                                    formControlName="personOfDream" icon="edit"
                                                    (focusin)="focusInput.emit($event)"
                                         ></text-area>
                                     </profile-edit-row>
                                 </form>
                             </profile-edit-box>
                         `,
           })

export class ProfileEditSearch extends ProfileEditBoxBase {
    @Input() private curProfile: ProfileInfo = new ProfileInfo();
    @Output() private focusInput = new EventEmitter();

    private maxLengthMap = maxLengthMap;


    private searchGender = [
        {
            name:  this.translate.instant('PROFILE_INFO.SEARCH_GENDER.FEMALE'),
            value: Gender.Female,
        }, {
            name:  this.translate.instant('PROFILE_INFO.SEARCH_GENDER.MALE'),
            value: Gender.Male,
        }, {
            name:  this.translate.instant('PROFILE_INFO.SEARCH_GENDER.BOTH'),
            value: Gender.Both,
        },
    ];


    private contactWish = [
        ContactWish.Partnership,
        ContactWish.Friendship,
        ContactWish.Acquaintance,
        ContactWish.Chat,
        ContactWish.LetsSee,
    ];

    public constructor(private translate: TranslateService) {
        super();
    }


    private get personOfDreamLabel() {
        switch (this.getGroupValue('searchGender')) {
            case Gender.Male:
                return "PROFILE_INFO.PERSON_OF_DREAM.MALE";
            case Gender.Female:
                return "PROFILE_INFO.PERSON_OF_DREAM.FEMALE";
            default:
                return "PROFILE_INFO.PERSON_OF_DREAM";
        }
    }
}
