import {
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';

@Component({
               selector:  'list-empty',
               template:  `
                              <card>
                                  <div class="empty-list">
                                      <div class="empty-list__box">
                                          <img *ngIf="img.length > 0" class="empty-list__box__img" [src]="img">
                                          <div class="empty-list__box__title">{{title}}</div>
                                          <div class="empty-list__box__text" [innerHTML]="text"></div>
                                          <div *ngIf="hasContent" class="empty-list__box__content" #content>
                                              <ng-content></ng-content>
                                          </div>

                                      </div>
                                  </div>
                              </card>
                          `,
               styles: [require('./list-empty.scss')],
           })
export class ListEmptyComponent {
    @ViewChild('content', { static: true }) private contentRef: ElementRef;
    @Input() private img: string = '';
    @Input() private text: string = '';
    @Input() private title: string = '';

    private get hasContent(): boolean {
        return this.contentRef && !!this.contentRef.nativeElement.innerHTML;
    }
}
