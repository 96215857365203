import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../../outer-component';
import { LayoutModule } from '../layout';

import { RegCode } from './reg-code';
import { RegCodeRouting } from './reg-code.routing';

@NgModule({
              imports: [
                  ShareModule,
                  LayoutModule,
                  RegCodeRouting,
                  OuterComponentModule,
              ],
              declarations: [
                  RegCode
              ],
              providers:    [],
          })
export class RegCodeModule {
}
