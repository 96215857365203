import { FormControl } from '@angular/forms';

export class BicValidator {
    private static bicRegex = /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/;


    public static validate(bicControl: FormControl): any {
        const bic: string = bicControl.value.toString();

        if(bic.isEmpty()) {
            return null
        }

        if (!BicValidator.bicRegex.test(bic)) {
            return { 'ERROR.VALIDATOR.BIC.FORMAT': true };
        }
        return null;
    }


    public static requierd(ibanControl: FormControl): any {
        return (bicControl: FormControl) => {
            if (ibanControl.value.length >= 2 && ibanControl.value.toUpperCase().indexOf('DE') === -1) {
                const bic: string = bicControl.value.toString();
                if (bic.isEmpty()) {
                    return { 'ERROR.VALIDATOR.REQUIRED': true };
                }
            }
            return null;
        };
    }
}
