import {
    Component,
    Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';

@Component({
               selector: 'lovestory-formular-names',
               template: `
        <lovestory-formular-card [title]="'LOVESTORY.FORMULAR.YOUR-NAMES' | translate">
            <form [formGroup]="group">
                <text-field [placeholder]="'LOVESTORY.FORMULAR.YOUR-NAME' | translate"
                            class="ls-form-names__input"
                            formControlName="yourName"
                ></text-field>
                <text-field [placeholder]="'LOVESTORY.FORMULAR.PARTNER-NAME' | translate"
                            class="ls-form-names__input"
                            formControlName="partnerName"
                ></text-field>
            </form>
        </lovestory-formular-card>
    `,
               styles: [require('./lovestory-formular-names.scss')],
               providers: [
                   getValueAccessorProvider(LovestoryFormularNames),
               ],
           })

export class LovestoryFormularNames extends ValueAccessorBase<any> {
    @Input() private group: FormGroup = new FormGroup({});
}
