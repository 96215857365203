import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import {
    GooglePlayPaymentsService,
} from '../../../service/google-play-payments.service';
import { Modal } from '../modal/modal';
import { BkModalPremiumBought } from './bk-modal-premium-bought';
import { BkModalPremiumPaymentError } from './bk-modal-premium-payment-error';

type Offer = {
    sku: string;
    interval: number;
    rate: PaymentCurrencyAmount;
    monthlyRateValue: number,
    monthlyRate: string;
    savingsPercent: number;
    data: any|undefined;
}

type HeaderData = {
    title: string;
    text: string;
    image: string;
    background: string;
}

@Component({
            selector: 'bk-premium-modal',
            template: `
                          <ng-container>
                              <modal #modal
                                     no-border
                                     no-padding
                                     [closeIcon]="false"
                                     [backgroundClose]="true"
                                     class="modal"
                              >
                                  <div class="content-container">
                                      <div class="top" [style.background-image]="background">
                                          <div class="title">{{ 'MODAL.PREMIUM.TITLE'|translate }}</div>
                                          <div class="image-and-controls">
                                              <icon icon="chevron-left" (click)="previous()" class="chevron"></icon>
                                              <img [src]="image">
                                              <icon icon="chevron-right" (click)="next()" class="chevron"></icon>
                                          </div>
                                          <div class="np-title">{{ npTitle|translate }}</div>
                                          <div class="np-text">{{ npText|translate }}</div>
                                      </div>
                                      <div class="middle">
                                          <div class="page-indicator"></div>
                                      </div>
                                      <div class="offer-list">
                                          <div *ngFor="let offer of offers; let i = index" class="offer-item" [class.active]="selectedOfferIndex == i" (click)="select(i)">
                                              <div class="month-number">{{ offer.interval }}</div>
                                              <div class="month-label">{{ ((offer.interval > 1)?'MODAL.PREMIUM.MONTHS.PLURAL':'MODAL.PREMIUM.MONTHS.SINGULAR')|translate }}</div>
                                              <div class="price-label">{{ 'MODAL.PREMIUM.MONTHLY_RATE'|translate:{ monthlyRate: offer.monthlyRate } }}</div>
                                              <div class="savings-label">{{ offer.savingsPercent > 0 ? ('MODAL.PREMIUM.SAVINGS'|translate:{ savingsPercent: offer.savingsPercent.toString() }) : '' }}</div>
                                          </div>
                                      </div>
                                      <div class="actions">
                                          <raised-button premium class="continue-action" (click)="purchase()" [disabled]="busy">{{ 'MODAL.PREMIUM.ACTION.PURCHASE'|translate }}</raised-button>
                                          <flat-button class="cancel-action" (click)="close()" [disabled]="busy">{{ 'MODAL.PREMIUM.ACTION.CANCEL'|translate }}</flat-button>
                                      </div>
                                      <div class="terms-box">
                                          <div class="terms-title">
                                              {{ 'MODAL.PREMIUM.TERMS.TITLE'|translate }}
                                          </div>
                                          <div class="terms-text markdown p-nomargin checkbox-list" [innerHTML]="'MODAL.PREMIUM.TERMS.TEXT'|translate:termsTranslationVars">
                                          </div>
                                      </div>
                                  </div>
                              </modal>
                          </ng-container>
                      `,
            styles: [require('./bk-premium-modal.scss')]
           })
export class BkPremiumModal implements ModalDialog {
    @ViewChild('modal', { static: true }) private modal: Modal;

    private reason: OrderReason;

    static readonly typeId = 'BkPremiumModal';

    protected selectedOfferIndex = 1;

    private headerIndex = 0;

    protected busy = false;

    protected get selectedOffer(): Offer {
        return this.offers[this.selectedOfferIndex];
    }

    protected get termsTranslationVars() {
        return {
            interval: this.selectedOffer.interval.toString(),
            monthlyRate: this.selectedOffer.monthlyRate.toString(),
            price: this.formattedPrice(parseFloat(this.selectedOffer.rate.value), this.selectedOffer.rate.currency)
        };
    }

    protected orderReasonToHeaderIndex: { [reason: number]: number } = {
        [OrderReason.None]: 6,
        [OrderReason.ProfileVisitor]: 11,
        [OrderReason.NewUser]: 12,
        [OrderReason.NearByUser]: 10,
        [OrderReason.NoAdvertising]: 16,
        [OrderReason.FavoriteBirthday]: 8,
        [OrderReason.MatchPartner]: 4,
        [OrderReason.WishPartner]: 5,
        [OrderReason.WantToKnowYou]: 3,
        [OrderReason.AllImages]: 6,
        [OrderReason.ExtendedSearch]: 7,
        [OrderReason.SearchRadius]: 9,
        [OrderReason.ReadingState]: 8,
        [OrderReason.IncognitoMode]: 12,
        [OrderReason.HideOnlineState]: 15,
        [OrderReason.UnlimitedMatching]: 1,
        [OrderReason.MatchingSeeMatchpartner]: 3,
        [OrderReason.OlderMessages]: 8,
        [OrderReason.MatchingOpenProfile]: 0,
        [OrderReason.PremiumServiceOverview]: 6,
        [OrderReason.SelectedUser]: 6,
        [OrderReason.RealUser]: 6,
        [OrderReason.SelectedUserAccountDelete]: 6,
        [OrderReason.PremiumExpiration]: 6,
        [OrderReason.LegacyWelcomeOffer]: 11,
        [OrderReason.LegacyWelcomeDelayedOffer]: 11,
        [OrderReason.ExtendPremium]: 11,
        [OrderReason.RecurringOffer]: 6,
        [OrderReason.ActivityList]: 6,
        [OrderReason.ActivityListPreview]: 6,
        [OrderReason.ChangeNegativeMatchDecision]: 2,
        [OrderReason.BirthdayOffer]: 6,
        [OrderReason.LoyaltyOffer]: 6,
    }

    protected headers: HeaderData[] = [
        {
            title: 'MODAL.PREMIUM.NP.MATCHING_PROFILE.TITLE',
            text: 'MODAL.PREMIUM.NP.MATCHING_PROFILE.TEXT',
            image: 'iconMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.UNLIMITED_MATCHING.TITLE',
            text: 'MODAL.PREMIUM.NP.UNLIMITED_MATCHING.TEXT',
            image: 'iconMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.REVERT_DECISION.TITLE',
            text: 'MODAL.PREMIUM.NP.REVERT_DECISION.TEXT',
            image: 'iconRedoMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.OPEN_MATCH.TITLE',
            text: 'MODAL.PREMIUM.NP.OPEN_MATCH.TEXT',
            image: 'iconMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.MATCH.TITLE',
            text: 'MODAL.PREMIUM.NP.MATCH.TEXT',
            image: 'iconMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.WANT_TO_MEET.TITLE',
            text: 'MODAL.PREMIUM.NP.WANT_TO_MEET.TEXT',
            image: 'iconMatch',
            background: 'npBackgroundBlue'
        },
        {
            title: 'MODAL.PREMIUM.NP.VIEW_GALLERY.TITLE',
            text: 'MODAL.PREMIUM.NP.VIEW_GALLERY.TEXT',
            image: 'iconShowPics',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.EXTENDED_SEARCH.TITLE',
            text: 'MODAL.PREMIUM.NP.EXTENDED_SEARCH.TEXT',
            image: 'iconSearch',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.READ_STATE.TITLE',
            text: 'MODAL.PREMIUM.NP.READ_STATE.TEXT',
            image: 'iconMassegeView',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.LOWER_SEARCH_RADIUS.TITLE',
            text: 'MODAL.PREMIUM.NP.LOWER_SEARCH_RADIUS.TEXT',
            image: 'iconRadius',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.SINGLES_NEARBY.TITLE',
            text: 'MODAL.PREMIUM.NP.SINGLES_NEARBY.TEXT',
            image: 'iconLocation',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.PROFILE_VISITORS.TITLE',
            text: 'MODAL.PREMIUM.NP.PROFILE_VISITORS.TEXT',
            image: 'iconVisitor',
            background: 'npBackgroundOrange'
        },
        {
            title: 'MODAL.PREMIUM.NP.NEW_SINGLES.TITLE',
            text: 'MODAL.PREMIUM.NP.NEW_SINGLES.TEXT',
            image: 'iconWavingHand',
            background: 'npBackgroundGreen'
        },
        {
            title: 'MODAL.PREMIUM.NP.INCOGNITO.TITLE',
            text: 'MODAL.PREMIUM.NP.INCOGNITO.TEXT',
            image: 'iconIncognito',
            background: 'npBackgroundGreen'
        },
        {
            title: 'MODAL.PREMIUM.NP.FASTER_ACCOUNT_ACTIVATION.TITLE',
            text: 'MODAL.PREMIUM.NP.FASTER_ACCOUNT_ACTIVATION.TEXT',
            image: 'iconUnlock',
            background: 'npBackgroundGreen'
        },
        {
            title: 'MODAL.PREMIUM.NP.OFFLINE_MODE.TITLE',
            text: 'MODAL.PREMIUM.NP.OFFLINE_MODE.TEXT',
            image: 'iconShowOffline',
            background: 'npBackgroundGreen'
        },
        {
            title: 'MODAL.PREMIUM.NP.NO_ADS.TITLE',
            text: 'MODAL.PREMIUM.NP.NO_ADS.TEXT',
            image: 'iconNoAds',
            background: 'npBackgroundGreen'
        },
    ];

    protected offers: Offer[] = [{
        sku:            'de.entrex.bildkontakte.android.premium.1m.subscription',
        interval:       1,
        rate:           {
            value: '14.90',
            currency: 'EUR'
        },
        monthlyRateValue: 14.90,
        monthlyRate: this.formattedPrice(14.90),
        savingsPercent: 0,
        data: undefined
    }, {
        sku:            'de.entrex.bildkontakte.android.premium.3m.subscription',
        interval:       3,
        rate:           {
            value: '29.90',
            currency: 'EUR'
        },
        monthlyRateValue: 29.90/3,
        monthlyRate: this.formattedPrice(29.90 / 3),
        savingsPercent: 0,
        data: undefined
    }, {
        sku:            'de.entrex.bildkontakte.android.premium.6m.subscription',
        interval:       6,
        rate:           {
            value: '49.90',
            currency: 'EUR'
        },
        monthlyRateValue: 49.90/6,
        monthlyRate: this.formattedPrice(49.90 / 6),
        savingsPercent: 0,
        data: undefined
    }];

    private formattedPrice(amount: number, currency = 'EUR') {
        return Intl.NumberFormat('de-DE', {
            style:           'currency',
            currency:        currency,
            currencyDisplay: 'symbol',
        }).format(amount);
    }

    private updateSavings() {
        const offersClone = [...this.offers];
        const mostExpensive = offersClone.sort((a, b) => (a.monthlyRate > b.monthlyRate ? 1 : a.monthlyRate < b.monthlyRate ? -1 : 0)).first() as Offer;

        this.offers = this.offers.map(offer => {
            return {
                sku: offer.sku,
                interval: offer.interval,
                rate: offer.rate,
                monthlyRateValue: offer.monthlyRateValue,
                monthlyRate: offer.monthlyRate,
                savingsPercent: Math.round((1 - (offer.monthlyRateValue / mostExpensive.monthlyRateValue)) * 100),
                data: offer.data
            };
        });
    }

    select(index: number) {
        if (this.busy) return;
        this.selectedOfferIndex = index;
    }

    protected previous() {
        this.headerIndex = (this.headerIndex + this.headers.length - 1) % this.headers.length;
    }

    protected next() {
        this.headerIndex = (this.headerIndex + 1) % this.headers.length;
    }

    open(config) {
        if (!config?.reason) throw 'Missing reason config for BkPremiumModal';

        this.reason = config?.reason;

        this.headerIndex = this.orderReasonToHeaderIndex[this.reason] || 0;

        this.googlePlayPaymentsService.availableItems().then(items => {
            this.offers = this.offers.map((offer): Offer => {
                const item = items.find(item => item.itemId === offer.sku);
                return {
                    sku: offer.sku,
                    interval: offer.interval,
                    rate: item.price,
                    monthlyRateValue: parseFloat(item.price.value) / offer.interval,
                    monthlyRate: this.formattedPrice(parseFloat(item.price.value) / offer.interval, item.price.currency),
                    savingsPercent: 0,
                    data: item
                };
            });

            this.updateSavings();
        }).catch(() => {
            // Load default values
            this.updateSavings();
        });

        this.modal.open();
    }

    close() {
        this.modal.close();
    }

    async purchase(){
        this.busy = true;
        try {
            const success = await this.googlePlayPaymentsService.purchase(this.offers[this.selectedOfferIndex].data);
            if (!success) {
                console.log('Purchase error on backend');
                this.showError();
                this.busy = false;
                return;
            }
        } catch (e) {
            // if (e instanceof AbortError) {
            //     // User canceled purchase
            //     return;
            // }
            console.log('Purchase error', e);

            this.showError();
            this.busy = false;
            return;
        }

        // Force reload to update premium status
        const currentUser = await this.currentUserService.loadCurrentUser();
        this.globalModalService.open(BkModalPremiumBought.typeId, { currentUser });

        this.busy = false;
        this.close();
    }

    showError() {
        this.globalModalService.open(BkModalPremiumPaymentError.typeId, { });
    }

    get typeId(): string {
        return BkPremiumModal.typeId;
    }

    get image(): string {
        return `./assets/img/modal/premium/${this.headers[this.headerIndex].image}.svg`;
    }

    get background(): string {
        return `url(./assets/img/modal/premium/${this.headers[this.headerIndex].background}.svg)`;
    }

    get npText(): string {
        return this.headers[this.headerIndex].text;
    }

    get npTitle(): string {
        return this.headers[this.headerIndex].title;
    }

    constructor(
        private googlePlayPaymentsService: GooglePlayPaymentsService,
        private currentUserService: CurrentUserService,
        private globalModalService: GlobalModalService
    ) {}
}
