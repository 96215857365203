import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    Duration,
    DurationUnit,
} from '../../../../../utils/duration';



@Component({
               selector: 'premium-landing-page-offer-box',
               template: `
                             <div class="premium-landing-page-offer-box">
                                 <div class="premium-landing-page-offer-box__savings" *ngIf="savingsRounded > 0">
                                     <premium-flag class="premium-booking-page-tariff__flag" [discount]="savingsRounded"></premium-flag>
                                 </div>
                                 <div class="premium-landing-page-offer-box__container">

                                     <div class="premium-landing-page-offer-box__entry premium-landing-page-offer-box__title" *ngIf="duration.unit == DurationUnit.Month">
                                         <span *ngIf="duration.amount == 1">{{'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_MONTH' | translate}}</span>
                                         <span *ngIf="duration.amount > 1">{{'PREMIUM_LANDINGPAGE.OFFER_BOX.DURATION_MONTHS' | translate:{ 'amount': duration.amount.toString() } }}</span>
                                     </div>

                                     <div class="premium-landing-page-offer-box__entry" *ngIf="duration.unit == DurationUnit.Month">
                                         <div class="premium-landing-page-offer-box__duration_price">
                                             {{pricePerMonth}}
                                         </div>
                                         <div class="premium-landing-page-offer-box__duration_unit">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.MONTHLY_PRICE' | translate}}
                                         </div>
                                     </div>

                                     <div class="premium-landing-page-offer-box__entry premium-landing-page-offer-box__price">
                                         <span *ngIf="duration.unit == DurationUnit.Month && duration.amount == 1">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_MONTH' | translate:{'amount': price} }}
                                         </span>
                                         <span *ngIf="!(duration.unit == DurationUnit.Month && duration.amount == 1)">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.PRICE_MONTHS' | translate:{'amount': price} }}
                                         </span>
                                     </div>

                                     <div class="premium-landing-page-offer-box__entry" *ngIf="duration.unit == DurationUnit.Month" [ngSwitch]="duration.amount">
                                         <div *ngSwitchCase="1" class="premium-landing-page-offer-box__extra_text">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.1M_TEXT_TITLE' | translate}}
                                             <br>
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.1M_TEXT' | translate}}
                                         </div>
                                         <div *ngSwitchCase="3" class="premium-landing-page-offer-box__extra_text">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.3M_TEXT_TITLE' | translate}}
                                             <br>
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.3M_TEXT' | translate}}
                                         </div>
                                         <div *ngSwitchCase="6" class="premium-landing-page-offer-box__extra_text">
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.6M_TEXT_TITLE' | translate}}
                                             <br>
                                             {{'PREMIUM_LANDINGPAGE.OFFER_BOX.6M_TEXT' | translate}}
                                         </div>
                                     </div>
                                 </div>

                                 <div>
                                     <div class="premium-landing-page-offer-box__button first">
                                         <raised-button class="premium-landing-page-offer-box__button__buy"
                                                        premium
                                                        #button
                                         >{{'PREMIUM_LANDINGPAGE.OFFER_BOX.BUTTON' | translate}}</raised-button>
                                     </div>

                                     <div class="premium-landing-page-offer-box__button" [class.hidden]="!showPaypal">
                                         <flat-button class="premium-landing-page-offer-box__button__buy"
                                                      premium
                                                      #buttonPaypal
                                         >{{'PREMIUM_LANDINGPAGE.OFFER_BOX.BUTTON_PAYPAL' | translate}}</flat-button>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-offer-box.scss')],
           })

export class PremiumLandingPageOfferBox {
    @Input() private percentSaved: number;
    @Input() private duration: Duration;
    @Input() private price: string;
    @Input() private pricePerMonth: number;
    @Input() private showPaypal: boolean = false;
    @Output() private chooseTariff = new EventEmitter();
    @Output() private choosePaypal = new EventEmitter();
    @ViewChild('button', {static: true}) private button;
    @ViewChild('buttonPaypal', {static: true}) private buttonPaypal;

    private DurationUnit = DurationUnit;

    private ngAfterViewInit() {
        this.addEventListeners(this.button.elementRef.nativeElement, () => this.chooseTariff.emit());
        this.addEventListeners(this.buttonPaypal.elementRef.nativeElement, () => this.choosePaypal.emit());
    }

    private addEventListeners(ele: any, handler: ()=>void) {
        const hammer = new Hammer(ele);
        hammer.on('tap', handler);
        ele.addEventListener('click', handler);
    }

    private get savingsRounded(): number {
        return Math.floor(this.percentSaved);
    }
}
