import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
               selector: 'lovestory-formular-footer',
               template: `
                             <div class="lovestory-formular-footer">
                                 <div class="lovestory-formular-footer__item">
                                     <raised-button class="lovestory-formular-footer__button"
                                             (click)="clickButton()"
                                     >{{getButtonText() | translate}}</raised-button>
                                     <a class="lovestory-formular-footer__link">{{"PROFILE.DELETE.LOVESTORY.FORMULAR.FOOTER.DONT_SHARE" | translate}}</a>
                                 </div>
                                 <div class="lovestory-formular-footer__item lovestory-formular-footer__item--page">
                                     <p class="lovestory-formular-footer__text">{{"PROFILE.DELETE.LOVESTORY.FORMULAR.FOOTER.EXPLAINATION" | translate}}</p>
                                     <p class="lovestory-formular-footer__text lovestory-formular-footer__text--page">{{ pageText }}</p>
                                 </div>
                             </div>`,
               styles: [require('./lovestory-formular-footer.scss')],
           })

export class LovestoryFormularFooter implements OnDestroy, OnInit {
    private unsubscribe$ = new Subject();
    @Input() private curPage: number = 1;

    @Input() private maxPage: number = 3;

    @Output() private nextStep = new EventEmitter();

    @Output() private noStory = new EventEmitter();

    private pageText = '';

    public constructor(private translate: TranslateService) {

    }

    public ngOnInit() {
        this.translate.get('PROFILE.DELETE.LOVESTORY.FORMULAR.FOOTER.PAGE')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((text) => {
                this.pageText = text.replace('{{curPage}}', this.curPage)
                                    .replace('{{maxPage}}', this.maxPage);
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private clickButton() {
        // if(this.curPage == this.maxPage) this.send();
        // else
        this.nextStep.emit();
    }

    private getButtonText(): string {
        if (this.curPage == this.maxPage) return 'PROFILE.DELETE.LOVESTORY.FORMULAR.FOOTER.BUTTON.SHARE';
        return 'PROFILE.DELETE.LOVESTORY.FORMULAR.FOOTER.BUTTON.NEXT-STEP';
    }

    private send() {
        // TODO
        // eslint-disable-next-line no-alert
        alert('send lovesotry');
    }
}
