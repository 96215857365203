import {
    Component,
    Input,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { OrderReason } from 'BKModels';
import { PremiumService } from '../../../../service/premium.service';

@Component({
               selector:  'settings-switch',
               template:  `
                              <div class="settings-switch">
                                  <div class="settings-switch__content">
                                      <div class="settings-switch__icon" *ngIf="!icon.isEmpty()">
                                          <icon [icon]="icon"></icon>
                                      </div>
                                      <div class="settings-switch__text">
                                          <div class="settings-switch__text__title">
                                              {{title}}
                                              <icon class="settings-switch__text__title__icon" icon="crown-outline" *ngIf="premiumFunction"></icon>
                                          </div>
                                          <div class="settings-switch__text__description" *ngIf="!description.isEmpty()">{{description}}</div>
                                      </div>
                                  </div>
                                  <switch [(value)]="value" (click)="click()" [disabled]="(premiumFunction && !userIsPremium) || disabled"></switch>
                              </div>
                          `,
               styles: [require('./settings-switch.scss')],
               providers: [
                   getValueAccessorProvider(SettingsSwitch),
               ],
           })

export class SettingsSwitch extends ValueAccessorBase<boolean> {
    @Input() protected icon: string = '';
    @Input() protected title: string = '';
    @Input() protected description: string = '';
    @Input() protected orderReason: OrderReason = OrderReason.None;
    @Input() protected premiumFunction: boolean = false;
    @Input() protected userIsPremium: boolean = false;

    public constructor(private premiumService: PremiumService) {
        super();
    }

    protected click() {
        if (this.premiumFunction && !this.userIsPremium) {
            this.premiumService.navigateToPremium(this.orderReason);
        }
    }

}
