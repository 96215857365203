import {
    Component,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';
import {
    isDevMode,
    parseLang,
} from 'BKUtils';

@Component({
               selector:  'show-translation-keys',
               template:  `
                              <div *ngIf="isDevMode" class="show-translation-keys">
                                  <checkbox [(value)]="value">Anzeigen der Übersetzungsschlüssel</checkbox>
                              </div>
                          `,
               styles: [require('./show-translation-key.scss')],
           })
export class ShowTranslationKeys implements OnInit {
    private _value = false;
    private stroageName = 'showTranslationKey';
    private previous: string = BkConfig.defaultLanguage;
    private isDevMode = isDevMode;

    private get value() {
        return this._value;
    }

    private set value(val) {
        this._value = val;
        sessionStorage.setItem(this.stroageName, JSON.stringify(val));

        if (val) {
            this.previous = this.translate.currentLang;
            this.translate.use('key');
        } else {
            this.translate.use(parseLang(this.previous));
        }
    }

    public constructor(private translate: TranslateService) {

    }

    public ngOnInit() {
        if (this.isDevMode) this.value = JSON.parse(sessionStorage.getItem(this.stroageName));
    }
}
