import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {
    Growl,
    GrowlType,
} from 'BKModels';
import { CommunicationService } from 'BKService';

@Component({
               selector:  'growl',
               template:  `
                              <div class="growl"
                                   [class.growl--positive]="isPositive()"
                                   [class.growl--warning]="isWarning()"
                                   [class.growl--info]="isInfo()"
                                   [class.growl--negative]="isNegative()"
                              >
                                  <div class="growl__header">
                                      <div class="growl__header__close">
                                          <icon-button icon="close" (click)="close()"></icon-button>
                                      </div>
                                  </div>

                                  <div class="growl__content">
                                      <div class="growl__content__circle">
                                          <icon [icon]="getIcon()" class="growl__content__circle__icon"></icon>
                                      </div>
                                      <div class="growl__content__text">
                                          <span class="growl__content__text--title">{{growl.title}}</span>
                                          {{growl.text}}
                                      </div>
                                  </div>

                              </div>
                          `,
               styles: [require('./growl.scss')],
           })

export class GrowlElement implements OnInit {
    @Input() private growl: Growl;

    @Input() private index: number;

    private interval;


    public constructor(private comService: CommunicationService) {
    }

    public ngOnInit() {
        this.interval = setInterval(() => {
            if (this.growl.expire <= Date.now()) this.close();
        }, 1500);
    }

    private close() {
        clearInterval(this.interval);
        this.comService.growl.remove(this.index);
    }

    private getIcon(): string {
        switch (this.growl.type) {
            case GrowlType.Positive:
                return 'check-circle';
            case GrowlType.Warning:
                return 'error';
            case GrowlType.Info:
                return 'info';
            case GrowlType.Negative:
                return 'error';
            default:
                return '';
        }
    }

    private isInfo(): boolean {
        return this.growl.type == GrowlType.Info;
    }

    private isNegative(): boolean {
        return this.growl.type == GrowlType.Negative;
    }

    private isPositive(): boolean {
        return this.growl.type == GrowlType.Positive;
    }

    private isWarning(): boolean {
        return this.growl.type == GrowlType.Warning;
    }
}
