import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ExtendedSearchCriteria,
    ExtendedSearchCriteriaProperty,
} from 'BKModels';
import { TrackingService } from 'BKService';
import * as cloneDeep from 'lodash.clonedeep';
import { Tabs } from '../../../../shared/mat-design/tabs/tabs';
import { SearchModal } from '../search-modal/search-modal';
import { SearchExtendedLook } from './search-extended-look';
import { SearchExtendedPersonal } from './search-extended-personal';

enum TabId {
    Look = 'look',
    Personal = 'personal',
    Other = 'other'
}

@Component({
               selector: 'search-extended',
               template: `
                             <search-modal #modal [title]="'Erweiterte Suche'"
                                           (save)="save()"
                             >
                                 <tabs #tabs [activeFirst]="true" shadow (change)="layout()">
                                     <tab [title]="'SEARCH.EXTENDED.LOOK' | translate"
                                          [id]="TabId.Look"
                                     >
                                         <search-extended-look #look
                                                               [(size)]="criteria.size"
                                                               [(bodyType)]="criteria.figure"
                                                               [(hairColor)]="criteria.hairColor"
                                                               [(eyeColor)]="criteria.eyeColor"
                                         ></search-extended-look>
                                     </tab>
                                     <tab [title]="'SEARCH.EXTENDED.PERSONAL' | translate"
                                          [id]="TabId.Personal"
                                     >
                                         <search-extended-personal #personal
                                                                   [(children)]="criteria.children"
                                                                   [(childrenWish)]="criteria.childrenWish"
                                                                   [(smoker)]="criteria.smoker"
                                                                   [(relationship)]="criteria.relationship"
                                                                   [(contactWish)]="criteria.contactWish"
                                                                   [(zodiacSign)]="criteria.zodiacSign"
                                                                   [(language)]="criteria.languages"
                                         ></search-extended-personal>
                                     </tab>
                                     <tab [title]="'SEARCH.EXTENDED.EXTENDED' |translate"
                                          [id]="TabId.Other"
                                     >
                                         <search-extended-others [(online)]="criteria.online"
                                                                 [(verified)]="criteria.echeck"
                                                                 [(filledFields)]="criteria.filledFields"
                                                                 [(fullTextSearch)]="criteria.fullTextSearch"
                                         ></search-extended-others>
                                     </tab>

                                 </tabs>
                             </search-modal>
                         `,
           })

export class SearchExtended {
    @ViewChild('modal', { static: true }) private modal: SearchModal;
    @ViewChild('personal', { static: false }) private personal: SearchExtendedPersonal;
    @ViewChild('look', { static: false }) private look: SearchExtendedLook;
    @ViewChild('tabs', { static: true }) private tabs: Tabs;

    private TabId = TabId;

    private trackingMapping = {
        [TabId.Look]:     'Aussehen',
        [TabId.Personal]: 'Persoenliches',
        [TabId.Other]:    'Extras',
    };

    private criteria: ExtendedSearchCriteria;

    @Input('criteria')
    private set _criteria(val: ExtendedSearchCriteria) {
        this.criteria = cloneDeep(val);
    }

    @Output() private criteriaChange = new EventEmitter();

    public constructor(private trackingService: TrackingService) {

    }


    public show(key: ExtendedSearchCriteriaProperty = null) {
        switch (key) {
            case ExtendedSearchCriteriaProperty.HairColor:
            case ExtendedSearchCriteriaProperty.EyeColor:
            case ExtendedSearchCriteriaProperty.BodyType:
            case ExtendedSearchCriteriaProperty.Size:
                this.tabs.open(TabId.Look);
                break;
            case ExtendedSearchCriteriaProperty.Children:
            case ExtendedSearchCriteriaProperty.ChildrenWish:
            case ExtendedSearchCriteriaProperty.Smoker:
            case ExtendedSearchCriteriaProperty.ZodiacSign:
            case ExtendedSearchCriteriaProperty.Relationship:
            case ExtendedSearchCriteriaProperty.ContactWish:
            case ExtendedSearchCriteriaProperty.Languages:
                this.tabs.open(TabId.Look);
                break;
            case ExtendedSearchCriteriaProperty.Online:
            case ExtendedSearchCriteriaProperty.Echeck:
            case ExtendedSearchCriteriaProperty.FilledFields:
                this.tabs.open(TabId.Other);
                break;
            default: // no nothing
        }

        this.modal.show();
        this.layout();

        if (key) {
            setTimeout(() => document.getElementById(key).scrollIntoView(true), 200);
        }
    }

    public hide() {
        this.modal.hide();
    }

    private layout() {
        this.personal.layout();
        this.look.layout();
    }

    private cancel() {
        this.hide();
    }

    private save() {
        this.criteriaChange.emit(this.criteria);
        this.trackingService.hit('ErweiterteSuche', 'SuchenGeklickt', this.trackingMapping[this.tabs.activeTab]);
        this.hide();
    }
}
