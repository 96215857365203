import {
    PreloadingStrategy,
    Route,
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    Observable,
    of,
} from 'rxjs';
import {
    AuthenticationInternCheck,
    AuthenticationLanguageCheck,
    AuthenticationLoginCheck,
} from './app.resolver';
import { allRedirect } from './app.routing.redirect';
import { Error } from './components/error/error';
import { TestComponent } from './components/test/test.component';
import { AccountDeleteSuccessful } from './module/account-delete-successful/account-delete-successful';
import { CancelContracts } from './module/cancel-contracts/cancel-contracts';
import { Login } from './module/login/login';
import { Logout } from './module/logout';
import { PasswordRecovery } from './module/password-recovery/container/password-recovery';
import { SendRecoveryEmailComponent } from './module/send-recovery-mail/container/send-recovery-mail.component';
import { VerifyEmailChangeComponent } from './module/verify-email-change';
import {
    routeName,
    Routes,
} from './service/navigation.service';


/**
 * Custom PreloadStrategy
 *
 */
export class CustomPreloadingStrategy implements PreloadingStrategy {
    /**
     * Preloadfunction
     * if the Property preload is true
     *
     {Route} route
     {Function} load
     {Observable<any>}
     */
    public preload(route: Route, load: Function): Observable<any> {
        return route.data && route.data.preload ? load() : of(null);
    }
}

/**
 * Array of the children of the app
 {Route[]}
 */
const children: NgRoutes = [
    {
        path:             routeName(Routes.Intern),
        canActivate:      [AuthenticationInternCheck],
        canActivateChild: [AuthenticationInternCheck],
        loadChildren:     () => import('./module/intern/intern.module').then(mod => mod.InternModule),
        data:             {
            preload: true,
        },
    }, {
        path:      'test',
        component: TestComponent,
    }, {
        path:      `${routeName(Routes.VerifyEmailChange)}`,
        component: VerifyEmailChangeComponent,
    }, {
        path:        routeName(Routes.Login),
        canActivate: [AuthenticationLoginCheck],
        component:   Login,
    }, {
        path:      routeName(Routes.Logout),
        component: Logout,
    }, {
        path:      routeName(Routes.SendRecoveryEmail),
        component: SendRecoveryEmailComponent,
    }, {
        path:      `${routeName(Routes.ResetPassword)}/:token`,
        component: PasswordRecovery,
    }, {
        path:      routeName(Routes.AccountDeleteSuccessful),
        component: AccountDeleteSuccessful,
    }, {
        path:      routeName(Routes.CancelContracts),
        component: CancelContracts,
    },
];

/**
 * Array of all Routes for the app;
 {Route[]}
 */
const appRoute: NgRoutes = [
    ...allRedirect,
    ...children, {
        path:      'error',
        component: Error,
    },
    {
        path:        ':lang',
        canActivate: [AuthenticationLanguageCheck],
        children:    [...children],
    },
    {
        path:       '**',
        redirectTo: routeName(Routes.Login),
        pathMatch:  'full',
    },
];

/**
 * App Routing Module
 {ModuleWithProviders}
 */
export const AppRouting = RouterModule.forRoot(
    appRoute,
    {
        useHash:            false,
        anchorScrolling:    'enabled',
        preloadingStrategy: CustomPreloadingStrategy,
    },
);
