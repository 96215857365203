/**
 * Created by olifra on 12.06.2017.
 */
import {
    Component,
    OnInit,
} from '@angular/core';
import {
    LovestoryBase,
    LovestoryListItem,
} from 'BKModels';
import { ModalService } from 'BKService';
import { LovestoryService } from '../../../../../service/lovestory.service';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'lovestory-card',
               template:  `
                              <card-title *ngIf="lovestory">
                                  <div class="lovestory__title">
                                      {{'BK-HOME.LOVESTORY.TITLE' | translate }}
                                  </div>
                                  <div class="lovestory__subtitle">
                                      {{'BK-HOME.LOVESTORY.SUBTITLE' | translate }}
                                  </div>
                                  <div class="lovestory__content">
                                      <lovestory-item [story]="lovestory"
                                                      (click)="modalService.lovestory.open(lovestory.id)"
                                      ></lovestory-item>
                                      <div class="lovestory__content__link">
                                          <flat-button [routerLink]="lovestoryLink">{{'BK-HOME.LOVESTORY.MORE_LOVESTORIES' | translate}}</flat-button>
                                      </div>
                            
                                  </div>
                              </card-title>


                              <lovestory-modal></lovestory-modal>
                          `,
               styles: [require('./lovestory-card.scss')],
           })
export class LovestoryCard implements OnInit {
    private lovestory: LovestoryBase|null = null;
    public constructor(
        private navigationService: NavigationService,
        private modalService: ModalService,
        private lovestoryService: LovestoryService,
    ) {

    }

    private get lovestoryLink() {
        return this.navigationService.getRoute(Routes.Lovestory);
    }

    public ngOnInit() {
        this.lovestoryService
            .get('random')
            .then((res) => {
                this.lovestory = res;
            });
    }
}
