import {
    Pipe,
    PipeTransform,
} from '@angular/core';


@Pipe({
          name: 'replace',
      })

export class ReplacePipe implements PipeTransform {
    public transform(val: string, pattern: string, replace: string | number, interpolation: boolean = false): string {
        const regex = interpolation ? new RegExp(`{{${pattern}}}`, 'g') : new RegExp(pattern, 'g');
        return val.replace(regex, replace.toString());
    }
}
