import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import {
    Children,
    ChildrenWish,
    ContactWish,
    ExtendedSearchCriteriaProperty,
    ExtendedSearchEnumValueObject,
    getEnumKeys,
    Language,
    Relationship,
    Smoker,
    ZodiacSign,
} from 'BKModels';
import { Dropdown } from '../../../../shared/mat-design/dropdown/dropdown';

const oneFieldTop = (enumObject, topField) => {
    return (a, b) => {
        if (enumObject[a] == topField) return -1;
        if (enumObject[b] == topField) return 1;
        return 0;
    };
};

@Component({
               selector:  'search-extended-personal',
               template:  `
                              <search-modal-box>
                                  <dropdown [placeholder]="'SEARCH.EXTENDED.PERSONAL.CHILDREN' | translate"
                                            [value]="children"
                                            (valueChange)="childrenChange.emit($event)"
                                            [attr.id]="ids.Children"
                                  >
                                      <dropdown-option *ngFor="let key of childrenKeys"
                                                       [value]="ChildrenEnum[key]"
                                                       [selected]="ChildrenEnum[key] == children"
                                      >
                                          {{getChildrenTranslationKey(key) | translate}}
                                      </dropdown-option>
                                  </dropdown>
                              </search-modal-box>
                              <search-modal-box>
                                  <dropdown [placeholder]="'SEARCH.EXTENDED.PERSONAL.CHILDREN_WISH' | translate"
                                            [value]="childrenWish"
                                            (valueChange)="childrenWishChange.emit($event)"
                                            [attr.id]="ids.ChildrenWish"
                                  >
                                      <dropdown-option *ngFor="let key of childrenWishKeys"
                                                       [value]="ChildrenWishEnum[key]"
                                                       [selected]="ChildrenWishEnum[key] == childrenWish"
                                      >
                                          {{getChildrenWishTranslationKey(key) | translate}}
                                      </dropdown-option>

                                  </dropdown>
                              </search-modal-box>
                              <search-modal-box>
                                  <dropdown [placeholder]="'PROFILE_INFO.SMOKER.LABEL' | translate"
                                            [value]="smoker"
                                            (valueChange)="smokerChange.emit($event)"
                                            [attr.id]="ids.Smoker"
                                  >
                                      <dropdown-option *ngFor="let key of smokerKeys"
                                                       [value]="SmokerEnum[key]"
                                                       [selected]="SmokerEnum[key] == smoker"
                                      >
                                          {{ getSmokerTranslationKey(key) | translate}}
                                      </dropdown-option>
                                  </dropdown>
                              </search-modal-box>
                              <search-modal-box>
                                  <dropdown [placeholder]="'PROFILE_INFO.RELATIONSHIP.LABEL' | translate"
                                            [value]="relationship"
                                            (valueChange)="relationshipChange.emit($event)"
                                            [attr.id]="ids.Relationship"
                                  >
                                      <dropdown-option *ngFor="let key of relationshipKeys"
                                                       [value]="RelationshipEnum[key]"
                                                       [selected]="RelationshipEnum[key] == relationship"
                                      >
                                          {{getRelationshipTranslationKey(key) | translate}}
                                      </dropdown-option>

                                  </dropdown>
                              </search-modal-box>
                              <search-modal-box>
                                  <dropdown [placeholder]="'PROFILE_INFO.CONTACT_WISH.LABEL' | translate"
                                            [value]="contactWish"
                                            (valueChange)="contactWishChange.emit($event)"
                                            [attr.id]="ids.ContactWish"
                                  >
                                      <dropdown-option *ngFor="let key of contactWishKeys"
                                                       [value]="ContactWishEnum[key]"
                                                       [selected]="ContactWishEnum[key] == contactWish"
                                      >
                                          {{getContactWishTranslationKey(key)| translate}}
                                      </dropdown-option>

                                  </dropdown>
                              </search-modal-box>

                              <search-modal-box [title]="'SEARCH.EXTENDED.PERSONAL.ZODIAC' | translate">
                                  <search-extended-chips
                                          [possibleValues]="zodiacSignKeys"
                                          [translationKey]="'PROFILE_INFO.ZODIAC_SIGN.OPTIONS.'"
                                          [value]="zodiacSign"
                                          (valueChange)="zodiacSignChange.emit($event)"
                                          [attr.id]="ids.ZodiacSign"
                                  ></search-extended-chips>
                              </search-modal-box>

                              <search-modal-box [title]="'SEARCH.EXTENDED.PERSONAL.LANGUAGE' | translate">
                                  <search-extended-chips
                                          [possibleValues]="languageKeys"
                                          [translationKey]="'PROFILE_INFO.LANGUAGES.OPTIONS.'"
                                          [value]="language"
                                          (valueChange)="languageChange.emit($event)"
                                          [attr.id]="ids.Languages"
                                  ></search-extended-chips>
                              </search-modal-box>


                          `,
               styles: [require('./search-extended-personal.scss')],
           })

export class SearchExtendedPersonal implements AfterViewInit {
    private ids = ExtendedSearchCriteriaProperty;

    @ViewChildren(Dropdown) private dropdowns !: QueryList<Dropdown>;

    private ChildrenEnum = Children;
    private childrenKeys = getEnumKeys(Children).sort(oneFieldTop(Children, Children.AskMe));
    @Input() private children: Children;
    @Output() private childrenChange = new EventEmitter<Children>();

    private getChildrenTranslationKey(key): string {
        if (key == 'AskMe') return 'SEARCH.EXTENDED.PERSONAL.CHILDREN.NO_MATTER';
        return `SEARCH.EXTENDED.PERSONAL.CHILDREN.${key.toUpperCase()}`;
    }

    private ChildrenWishEnum = ChildrenWish;
    private childrenWishKeys = getEnumKeys(ChildrenWish).sort(oneFieldTop(ChildrenWish, ChildrenWish.AskMe));
    @Input() private childrenWish: ChildrenWish;
    @Output() private childrenWishChange = new EventEmitter<ChildrenWish>();

    private getChildrenWishTranslationKey(key): string {
        if (key == 'AskMe') return 'SEARCH.EXTENDED.PERSONAL.CHILDREN.NO_MATTER';
        if (key === 'DontKnow') return 'SEARCH.EXTENDED.PERSONAL.CHILDREN_WISH.DONT_KNOW';
        return 'SEARCH.EXTENDED.PERSONAL.CHILDREN_WISH.' + key.toUpperCase();
    }


    private SmokerEnum = Smoker;
    private smokerKeys = getEnumKeys(Smoker).sort(oneFieldTop(Smoker, Smoker.AskMe));
    @Input() private smoker: Smoker;
    @Output() private smokerChange = new EventEmitter<Smoker>();

    private getSmokerTranslationKey(key): string {
        if (key == 'AskMe') return 'SEARCH.EXTENDED.PERSONAL.SMOKER.NO_MATTER';
        return `SEARCH.EXTENDED.PERSONAL.SMOKER.${key.toUpperCase()}`;
    }


    private zodiacSignKeys = getEnumKeys(ZodiacSign);
    @Input() private zodiacSign: ExtendedSearchEnumValueObject;
    @Output() private zodiacSignChange = new EventEmitter();

    private languageKeys = getEnumKeys(Language);
    @Input() private language: ExtendedSearchEnumValueObject;
    @Output() private languageChange = new EventEmitter();

    private RelationshipEnum = Relationship;
    private relationshipKeys = getEnumKeys(Relationship).sort(oneFieldTop(Relationship, Relationship.AskMe));
    @Input() private relationship: Relationship;
    @Output() private relationshipChange = new EventEmitter<Relationship>();

    private getRelationshipTranslationKey(key): string {
        if (key == 'AskMe') return 'SEARCH.EXTENDED.PERSONAL.RELATIONSHIP.NO_MATTER';
        return `SEARCH.EXTENDED.PERSONAL.RELATIONSHIP.${key.toUpperCase()}`;
    }

    private ContactWishEnum = ContactWish;
    private contactWishKeys = getEnumKeys(ContactWish).sort(oneFieldTop(ContactWish, ContactWish.AskMe));
    @Input() private contactWish: ContactWish;
    @Output() private contactWishChange = new EventEmitter<Relationship>();

    private getContactWishTranslationKey(key): string {
        if (key == 'AskMe') return 'SEARCH.EXTENDED.PERSONAL.CONTACT_WISH.NO_MATTER';
        return `SEARCH.EXTENDED.PERSONAL.CONTACT_WISH.${key.toUpperCase()}`;
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.layout(), 400);
    }

    public layout() {
        this.dropdowns.forEach(it => it.layout());
    }
}
