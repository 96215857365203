import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCFormField } from '@material/form-field';
import { MDCRadio } from '@material/radio';
import { uuidv4 } from 'BKUtils';


@Component({
               selector:  'radio',
               template:  `
                              <div #formField class="mdc-form-field radio-field" [class.radio-field--checked]="checked" (click)="click()">
                                  <div #radio class="mdc-radio"
                                       [class.mdc-radio--disabled]="disabled"
                                  >
                                      <input class="mdc-radio__native-control"
                                             type="radio"
                                             [id]="id"
                                             [checked]="checked"
                                             [name]="name"
                                             [disabled]="disabled"
                                      >
                                      <div class="mdc-radio__background">
                                          <div class="mdc-radio__outer-circle"></div>
                                          <div class="mdc-radio__inner-circle"></div>
                                      </div>
                                  </div>
                                  <label [for]="id"
                                         [class.radio__label--small]="small"
                                         [class.radio__label--disabled]="disabled"
                                  >
                                      {{label | translate}}
                                  </label>
                              </div>
                          `,
               styles: [require('./radio.scss')],
           })

export class Radio implements AfterViewInit {
    @Input() private checked: boolean = false;
    @ViewChild('formField', { static: true }) private formFieldRef: ElementRef<HTMLElement>;
    private id: string = uuidv4();
    @Input() private label: string = '';
    @Input() private name: string = uuidv4();
    @ViewChild('radio', { static: true }) private radioRef: ElementRef<HTMLElement>;
    @Output() private select = new EventEmitter();
    @Input() private value;
    @Input() private small: boolean = false;
    @Input() private disabled: boolean = false;

    public ngAfterViewInit() {
        const radio = new MDCRadio(this.radioRef.nativeElement);
        const formField = new MDCFormField(this.formFieldRef.nativeElement);
        formField.input = radio;
    }

    private click() {
        if (this.disabled) return;
        this.select.emit(this.value);
    }
}
