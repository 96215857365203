import {
    Component,
    Input,
} from '@angular/core';

/**
 * Component for the ProfileHeader in the Matchinglists
 */
@Component({
               selector: 'bk-matching-list-header',
               template: `
                   <card padding class="bk-matching-list-header">
                       <div class="bk-matching-list-header__title">
                           {{title}}
                       </div>
                       <div class="bk-matching-list-header__text">
                           <ng-content></ng-content>
                       </div>
                   </card>
               `,
               styles: [require('./matching-list-header.scss')],
           })
export class MatchingListHeader {
    @Input() private title: string = '';
}
