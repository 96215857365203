import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCDialog } from '@material/dialog/component';
import {
    ImageSize,
    ProfileInfo,
} from 'BKModels';

@Component({
               selector: 'sub-modal',
               template: `

        <div #modal
             class="mdc-dialog sub-modal"
             role="alertdialog"
             aria-modal="true"
        >
            <div class="mdc-dialog__container">
                <div class="mdc-dialog__surface">
                    <div class="mdc-dialog__title sub-modal__header">
                        <img class="sub-modal__header__img" [src]="user.getProfilePictureUrl(imageSize['70x70'])" alt="">
                        <div class="sub-modal__header__text">
                            <div class="sub-modal__header__text__title">{{user.nickname}}</div>
                            <div class="sub-modal__header__text__subtitle">{{user.age}}, {{user.city}}</div>
                        </div>
                    </div>


                    <div class="mdc-dialog__content">
                        <ng-content></ng-content>

                    </div>

                    <footer class="mdc-dialog__actions">
                        <flat-button *ngIf="!rejectText.isEmpty()"
                                     (click)="onReject($event)"
                                     class="sub-modal__first-button"
                                     tabindex="0"
                        >
                            {{rejectText}}
                        </flat-button>
                        <raised-button *ngIf="!acceptText.isEmpty()"
                                     (click)="onAccept($event)"
                                     tabindex="0"
                        >
                            {{acceptText}}
                        </raised-button>
                    </footer>
                </div>
            </div>
            <div class="mdc-dialog__scrim"></div>
        </div>
    `,
               styles: [require('./sub-modal.scss')],
           })

export class SubModal implements AfterViewInit {
    @Output('onAccept') private _onAccept = new EventEmitter();
    @Output('onReject') private _onReject = new EventEmitter();
    @Input() private acceptText: String = '';
    private modal: MDCDialog;
    @ViewChild('modal', { static: true }) private modalRef: ElementRef;
    @Input() private rejectText: String = '';
    @Input() private user: ProfileInfo = new ProfileInfo();
    private imageSize = ImageSize;


    public close() {
        setTimeout(() => this.modal.close());
    }

    public ngAfterViewInit() {
        this.modal = new MDCDialog(this.modalRef.nativeElement);
    }

    public open() {
        this.modal.open();
    }

    private onAccept(event) {
        this._onAccept.emit(event);
        this.close();
    }

    private onReject(event) {
        this._onReject.emit(event);
        this.close();
    }

    public get isOpen(): boolean {
        return this.modal && this.modal.isOpen;
    }
}
