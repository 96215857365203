import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { Modal } from '../../../../shared/mat-design/modal/modal';

@Component({
               selector:  'authenticity-check-modal',
               template:  `
                              <modal #modal [closeIcon]="true">
                                  <div class="authenticity-check-modal">

                                      <div class="authenticity-check-modal__title">{{title}}</div>

                                      <div class="authenticity-check-modal__description" [innerHTML]="description"></div>

                                      <ng-content></ng-content>
                                  </div>
                              </modal>

                          `,
               styles: [require('./authenticity-check-modal.scss')],
           })

export class AuthenticityCheckModal {
    @ViewChild('modal', { static: true }) private modal: Modal;

    @Input() private title: string;
    @Input() private description: string;


    public open() {
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }
}
