import { Injectable } from '@angular/core';
import { OrderReason } from 'BKModels';
import { PaymentType } from '../../models/product';
import { Tariff } from '../../models/tariff';
import { PremiumDebitResult } from '../api/calls/payment.api';
import { DataLayerService } from './dataLayer.service';

export enum ECommerceTrackingCheckoutStep {
    DebitDetails = 1,
    PaymentAddress= 2
}

@Injectable({ providedIn: 'root' })
export class ECommerceTrackingService {
    constructor(private dataLayerService: DataLayerService) {
    }

    // eslint-disable-next-line no-empty-function
    public trackProductDetail(tariff: Tariff, orderReason: OrderReason, callback: () => void = () => {}) {
        const event = {
            event:         'eec.productDetail',
            ecommerce:     {
                detail: {
                    actionField: { 'list': OrderReason.getQueryString(orderReason) },
                    products:    [ECommerceTrackingService.createProductListEntry(tariff)],
                },
            },
            eventCallback: callback(),
        };

        this.dataLayerService.push(event);
    }

    // eslint-disable-next-line no-empty-function
    public trackCheckout(tariff: Tariff, paymentOption: PaymentType, step: ECommerceTrackingCheckoutStep, callback: () => void = () => {}) {
        const event = {
            event:         'eec.checkout',
            ecommerce:     {
                checkout: {
                    actionField: { step: step, option: PaymentType[paymentOption] },
                    products:    [ECommerceTrackingService.createProductListEntry(tariff)],
                },
            },
            eventCallback: callback(),
        };

        this.dataLayerService.push(event);
    }

    // eslint-disable-next-line no-empty-function
    public trackPurchase(tariff: Tariff, debitResult: PremiumDebitResult, callback: () => void = () => {}) {
        const event = {
            event:         'eec.purchase',
            ecommerce:     {
                purchase: {
                    actionField: {
                        id:        'Transaction-'+debitResult.transactionId,
                        'revenue': tariff.price,
                        'tax':     debitResult.vat,
                    },
                    products:    [ECommerceTrackingService.createProductListEntry(tariff)],
                },
            },
            eventCallback: callback(),
        };

        this.dataLayerService.push(event);
    }

    private static createProductListEntry(tariff: Tariff) {
        return {
            name:       tariff.productId,
            id:         'Tariff-'+tariff.tariffId,
            quantity:   1,
            price:      tariff.price,
        };
    }
}
