import { Component } from '@angular/core';
import {
    CurrentUserService,
    ModalService,
} from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'quick-nav',
               template:  `
                              <div class="quick-nav">
                                  <div class="item" [routerLink]="navigationService.getRoute(Routes.Startpage)" routerLinkActive="active">
                                      <icon class="icon" icon="home-outline"></icon>
                                      <div class="label">{{'LAYOUT.SIDE_NAV.STARTPAGE' | translate}}</div>
                                  </div>
                                  <div class="item" [routerLink]="navigationService.getRoute(Routes.Contacts)" routerLinkActive="active">
                                      <icon class="icon" icon="contacts"></icon>
                                      <div class="label">{{'LAYOUT.SIDE_NAV.CONTACTS' | translate}}</div>
                                      <badge [count]="(currentUserService.badgeCountsObservable | async).profileVisitors" class="quick-nav-badge"></badge>
                                  </div>
                                  <div class="item" [routerLink]="navigationService.getRoute(Routes.Matching)" routerLinkActive="active">
                                      <icon class="icon" icon="match-outline"></icon>
                                      <div class="label">{{'LAYOUT.SIDE_NAV.MATCHING' | translate}}</div>
                                      <badge [count]="(currentUserService.badgeCountsObservable | async).openMatches + (currentUserService.badgeCountsObservable | async).matches" class="quick-nav-badge"></badge>
                                  </div>
                                  <div class="item" [routerLink]="navigationService.getRoute(Routes.Conversation)" routerLinkActive="active">
                                      <icon class="icon" icon="chat-outline"></icon>
                                      <div class="label">{{'LAYOUT.SIDE_NAV.MESSAGES' | translate}}</div>
                                      <badge [count]="(currentUserService.badgeCountsObservable | async).unreadMessages" class="quick-nav-badge"></badge>
                                  </div>
                                  <div class="item" [routerLink]="navigationService.getRoute(Routes.Search)" routerLinkActive="active">
                                      <icon class="icon" icon="search-outline"></icon>
                                      <div class="label">{{'LAYOUT.SIDE_NAV.SEARCH' | translate}}</div>
                                  </div>
                              </div>
                          `,
               styles: [require('./quick-nav.scss')],
           })

export class QuickNav {
    Routes = Routes;

    public constructor(
        private navigationService: NavigationService,
        private currentUserService: CurrentUserService,
        ) {}
}
