import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    MailSettingsInterval,
    MailSettingsType,
} from '../../../../service/api/calls/settings.api';

import {
    MailSettings,
    MailSettingsService,
} from '../../../../service/mail-settings.service';

@Component({
               selector:  'settings-notification-email',
               template:  `
                   
                              <settings-title>{{'SETTINGS.NOTIFICATION.EMAIL' | translate}}</settings-title>
                              
                              <settings-switch [title]="'Neue Nachricht' | translate" [(value)]="newMessageActive"></settings-switch>
                              <radio-panel [options]="newMessageOptions"
                                           [(value)]="newMessage"
                                           [disabled]="!newMessageActive"
                                           [small]="true"
                              ></radio-panel>
                              
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.NEW_MATCHES' | translate" [(value)]="newMatch"></settings-switch>
                              
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.NEW_MATCH_PARTNER' | translate" [(value)]="newOpenMatchActive"></settings-switch>
                              <radio-panel [options]="newOpenMatchOptions"
                                           [(value)]="newOpenMatch"
                                           [disabled]="!newOpenMatchActive"
                                           [small]="true"
                              ></radio-panel>

                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.PROFILE_VISITOR' | translate" [(value)]="profileVisitorActive"></settings-switch>
                              <radio-panel [options]="profileVisitorOptions"
                                           [(value)]="profileVisitor"
                                           [disabled]="!profileVisitorActive"
                                           [small]="true"
                              ></radio-panel>

                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.WEEKLY_ACTIVITY_SUMMARY' | translate" [(value)]="weeklyActivity"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.USER_SUGGESTION_SUMMARY' | translate" [(value)]="userSuggestion"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.SINGLES_NEAR_BY' | translate" [(value)]="nearbyUsers"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.SINGLES_EVENTS' | translate" [(value)]="events"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.OFFERS' | translate" [(value)]="offers"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.TRAVEL' | translate" [(value)]="travel"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.MAGAZINE' | translate" [(value)]="magazine"></settings-switch>
                              <settings-switch [title]="'SETTINGS.NOTIFICATION.EMAIL.ADS' | translate" [(value)]="ads"></settings-switch>
                          
                          <div class="footer">
                              <raised-button (click)="save.emit($event)">{{'SETTINGS.NOTIFICATION.EMAIL.SAVE' | translate}}</raised-button>
                          </div>
                          `,
               styles: [require('./settings-notification.scss')],
           })

export class SettingsNotificationEmail {
    @Input() settings = MailSettings.default();
    @Output() settingsChange = new EventEmitter<MailSettings>();
    @Output() save = new EventEmitter();

    settingsTranslationKeyMap = {
        [MailSettingsInterval.Never]: 'SETTINGS.NOTIFICATION.EMAIL.NEVER',
        [MailSettingsInterval.Always]: 'SETTINGS.NOTIFICATION.EMAIL.EVERY_TIME',
        [MailSettingsInterval.LimitedPerDay]: 'SETTINGS.NOTIFICATION.EMAIL.THREE_TIMES_PER_DAY',
    };

    private updateSetting(changeFn: (settings: MailSettings) => void) {
        changeFn(this.settings);
        this.settingsChange.emit(this.settings);
    }

    get travel(): boolean {
        return this.settings.travel !== MailSettingsInterval.Never;
    }

    set travel(v: boolean) {
        this.updateSetting(settings => {
            settings.travel = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get ads(): boolean {
        return this.settings.ads !== MailSettingsInterval.Never;
    }

    set ads(v: boolean) {
        this.updateSetting(settings => {
            settings.ads = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get magazine(): boolean {
        return this.settings.magazine !== MailSettingsInterval.Never;
    }

    set magazine(v: boolean) {
        this.updateSetting(settings => {
            settings.magazine = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get offers(): boolean {
        return this.settings.offers !== MailSettingsInterval.Never;
    }

    set offers(v: boolean) {
        this.updateSetting(settings => {
            settings.offers = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get events(): boolean {
        return this.settings.events !== MailSettingsInterval.Never;
    }

    set events(v: boolean) {
        this.updateSetting(settings => {
            settings.events = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get weeklyActivity(): boolean {
        return this.settings.weeklyActivity !== MailSettingsInterval.Never;
    }

    set weeklyActivity(v: boolean) {
        this.updateSetting(settings => {
            settings.weeklyActivity = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get userSuggestion(): boolean {
        return this.settings.userSuggestion !== MailSettingsInterval.Never;
    }

    set userSuggestion(v: boolean) {
        this.updateSetting(settings => {
            settings.userSuggestion = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get nearbyUsers(): boolean {
        return this.settings.nearbyUsers !== MailSettingsInterval.Never;
    }

    set nearbyUsers(v: boolean) {
        this.updateSetting(settings => {
            settings.nearbyUsers = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    get newMatch(): boolean {
        return this.settings.match !== MailSettingsInterval.Never;
    }

    set newMatch(v: boolean) {
        this.updateSetting(settings => {
            settings.match = v ? MailSettingsInterval.Always : MailSettingsInterval.Never;
        });
    }

    // Profile visitors
    readonly profileVisitorOptions = MailSettingsService.intervals[MailSettingsType.ProfileVisitor]
            .filter(value => value !== MailSettingsInterval.Never)
            .map(value => ({ value, name: this.settingsTranslationKeyMap[value] }));

    get profileVisitorActive(): boolean {
        return this.settings.profileVisitor !== MailSettingsInterval.Never;
    }

    set profileVisitorActive(v: boolean) {
        if (v === this.profileVisitorActive) return;

        if (v) {
            this.updateSetting(settings => {
                settings.profileVisitor = MailSettingsInterval.Always;
            });
        } else {
            this.updateSetting(settings => {
                settings.profileVisitor = MailSettingsInterval.Never;
            });
        }
    }

    get profileVisitor(): MailSettingsInterval {
        return this.settings.profileVisitor;
    }

    set profileVisitor(v: MailSettingsInterval) {
        if (v === this.settings.profileVisitor) return;

        this.updateSetting(settings => {
            settings.profileVisitor = v;
        });
    }

    // New Messages
    readonly newMessageOptions = MailSettingsService.intervals[MailSettingsType.NewMessage]
            .filter(value => value !== MailSettingsInterval.Never)
            .map(value => ({ value, name: this.settingsTranslationKeyMap[value] }));

    get newMessageActive(): boolean {
        return this.settings.newMessage !== MailSettingsInterval.Never;
    }

    set newMessageActive(v: boolean) {
        if (v === this.newMessageActive) return;

        if (v) {
            this.updateSetting(settings => {
                settings.newMessage = MailSettingsInterval.Always;
            });
        } else {
            this.updateSetting(settings => {
                settings.newMessage = MailSettingsInterval.Never;
            });
        }
    }

    get newMessage(): MailSettingsInterval {
        return this.settings.newMessage;
    }

    set newMessage(v: MailSettingsInterval) {
        if (v === this.settings.newMessage) return;

        this.updateSetting(settings => {
            settings.newMessage = v;
        });
    }

    // New open Match
    readonly newOpenMatchOptions = MailSettingsService.intervals[MailSettingsType.NewOpenMatch]
            .filter(value => value !== MailSettingsInterval.Never)
            .map(value => ({ value, name: this.settingsTranslationKeyMap[value] }));

    get newOpenMatchActive(): boolean {
        return this.settings.newOpenMatch !== MailSettingsInterval.Never;
    }

    set newOpenMatchActive(v: boolean) {
        if (v === this.newOpenMatchActive) return;

        if (v) {
            this.updateSetting(settings => {
                settings.newOpenMatch = MailSettingsInterval.Always;
            });
        } else {
            this.updateSetting(settings => {
                settings.newOpenMatch = MailSettingsInterval.Never;
            });
        }
    }

    get newOpenMatch(): MailSettingsInterval {
        return this.settings.newOpenMatch;
    }

    set newOpenMatch(v: MailSettingsInterval) {
        if (v === this.settings.newOpenMatch) return;

        this.updateSetting(settings => {
            settings.newOpenMatch = v;
        });
    }

}
