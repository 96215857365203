import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
} from '@angular/forms';
import { ApiService } from 'BKService';
import {
    required,
    requiredIf,
} from 'BKShared/Validator';

@Component({
               selector:  'lovestory-formular',
               template:  `
        <subheader-arrow [title]="'LOVESTORY.FORMULAR.TITLE' | translate"></subheader-arrow>
        <ng-container *ngIf="!success">
            <card padding>
                <div class="lovestory-formular__title lovestory-formular__distance">{{'LOVESTORY.FORMULAR.TITLE' | translate}}</div>
                <div class="lovestory-formular__text">
                    {{'LOVESTORY.FORMULAR.DESCRIPTION' | translate}}
                </div>
            </card>

            <form [formGroup]="lsFormular">
                <lovestory-formular-names formControlName="names"
                                          [group]="lsFormular.controls.names"
                ></lovestory-formular-names>

                <lovestory-formular-card [title]="'LOVESTORY.FORMULAR.TITLE' | translate">
                    <lovestory-formular-item [title]="'LOVESTORY.QUESTION.WIE-AUF-BK' | translate"
                                             [number]="1"
                                             [active]="isActive(1)"
                                             (setActive)="setActive($event)"
                                             formControlName="howFindUs"
                                             [group]="lsFormular.controls.howFindUs"
                                             class="lovestory-formular__distance"
                    ></lovestory-formular-item>
                    <lovestory-formular-item [title]="'LOVESTORY.QUESTION.ERST-KONTAKT' | translate"
                                             [number]="2"
                                             [active]="isActive(2)"
                                             (setActive)="setActive($event)"
                                             formControlName="firstContact"
                                             [group]="lsFormular.controls.firstContact"
                                             class="lovestory-formular__distance"
                    ></lovestory-formular-item>
                    <lovestory-formular-item [title]="'LOVESTORY.QUESTION.ERSTES-DATE' | translate"
                                             [number]="3"
                                             [active]="isActive(3)"
                                             (setActive)="setActive($event)"
                                             formControlName="firstDate"
                                             [group]="lsFormular.controls.firstDate"
                                             class="lovestory-formular__distance"
                    ></lovestory-formular-item>
                    <lovestory-formular-item [title]="'LOVESTORY.QUESTION.WIE-WEITER' | translate"
                                             [number]="4"
                                             [active]="isActive(4)"
                                             (setActive)="setActive($event)"
                                             formControlName="howContinue"
                                             [group]="lsFormular.controls.howContinue"
                                             class="lovestory-formular__distance"
                    ></lovestory-formular-item>
                    <lovestory-formular-item [title]="'LOVESTORY.QUESTION.BK-WEITER-EMP' | translate"
                                             [number]="5"
                                             [active]="isActive(5)"
                                             (setActive)="setActive($event)"
                                             formControlName="recommendation"
                                             [group]="lsFormular.controls.recommendation"
                                             class="lovestory-formular__distance"
                    ></lovestory-formular-item>

                    <div class="lovestory-formular__text lovestory-formular__distance">
                        {{'LOVESTORY.FORMULAR.PIC-UPLOAD' | translate}}
                    </div>
                    <div class="lovestory-formular__images"
                         formGroupName="images"
                    >
                        <lovestory-form-image formControlName="0"></lovestory-form-image>
                        <lovestory-form-image formControlName="1"></lovestory-form-image>
                        <lovestory-form-image formControlName="2"></lovestory-form-image>
                    </div>

                    <lovestory-formular-consent class="lovestory-formular__distance"
                                                formControlName="consent"
                                                [group]="lsFormular.controls.consent"
                    ></lovestory-formular-consent>


                    <div class="lovestory-formular__text lovestory-formular__distance">
                        {{'LOVESTORY.FORMULAR.AGREEMENT' | translate}}
                    </div>
                    <raised-button [disabled]="!valid()"
                                   (click)="send()"
                    >
                        {{'LOVESTORY.FORMULAR.SEND' | translate}}
                    </raised-button>

                </lovestory-formular-card>
            </form>
        </ng-container>

        <lovestory-formular-success *ngIf="success"></lovestory-formular-success>
    `,
               styles: [require('./lovestory-formular.scss')],
           })
export class LovestoryFormular implements OnInit {
    private active: number = 1;

    private lsFormular: FormGroup;

    private success: boolean = false;


    public constructor(private formBuilder: FormBuilder, private api: ApiService) {

    }

    public ngOnInit() {
        this.buildForm();
    }

    private buildForm() {
        const names = this.formBuilder
                          .group({
                                     yourName:    ['', [required]],
                                     partnerName: ['', [required]],
                                 });


        const agree = new FormControl(false);


        const consent = this.formBuilder
                            .group({
                                       agree,
                                       street:     ['', [requiredIf(() => agree.value)]],
                                       number:     ['', [requiredIf(() => agree.value)]],
                                       zip:        ['', [requiredIf(() => agree.value)]],
                                       city:       ['', [requiredIf(() => agree.value)]],
                                       yourAge:    ['', [requiredIf(() => agree.value)]],
                                       partnerAge: ['', [requiredIf(() => agree.value)]],
                                       tele:       ['', [requiredIf(() => agree.value)]],
                                   });

        const images = this.formBuilder.group({
                                                  0: ['', []],
                                                  1: ['', []],
                                                  2: ['', []],
                                              });

        this.lsFormular = this.formBuilder
                              .group({
                                         names,
                                         howFindUs:      this.createAnswerGroup(),
                                         firstContact:   this.createAnswerGroup(),
                                         firstDate:      this.createAnswerGroup(),
                                         howContinue:    this.createAnswerGroup(),
                                         recommendation: this.createAnswerGroup(),
                                         consent,
                                         images,
                                     });
    }


    private createAnswerGroup(): FormGroup {
        return this.formBuilder
                   .group({
                              yourAnswer:    ['', [required]],
                              partnerAnswer: ['', [required]],
                          });
    }


    private isActive(id: number): boolean {
        return this.active === id;
    }

    private send() {
        this.api.lovestories.send();
    }

    private setActive(id) {
        this.active = id;
    }

    private valid(): boolean {
        return this.lsFormular.valid;
    }
}
