import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'premium-booking-page-step-title',
               template:  `
                              <div class="premium-booking-page-step-title"
                                   [class.premium-booking-page-step-title--done]="done"
                              >
                                  <div class="premium-booking-page-step-title__icon">
                                      <icon *ngIf="done; else number" icon="check"></icon>
                                      <ng-template #number>{{stepNumber}}</ng-template>
                                  </div>
                                  <div class="premium-booking-page-step-title__text">
                                      {{title}}
                                  </div>
                              </div>
                          `,
               styles: [require('./premium-booking-page-step-title.scss')],
           })

export class PremiumBookingPageStepTitle {
    @Input() private title: string = "";
    @Input() private done: boolean = false;
    @Input() private stepNumber: number;
}
