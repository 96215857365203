import {
    ComponentFactoryResolver,
    ComponentRef,
    ViewContainerRef,
} from '@angular/core';
import {
    formatAmount,
    formatIban,
} from 'BKUtils';
import { PayWithQr } from '../../shared/mat-design/pay-with-qr/pay-with-qr';
import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class ChargebackPaymentFailedNotification extends Notification {
    public static notificationTypeId = '8d996d85-a27e-4e71-a11f-7b4399ed8656';
    public notificationId = ChargebackPaymentFailedNotification.notificationTypeId;

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Error;
    public importance = -10000;

    public contentKey = 'NOTIFICATION.CHARGEBACK.TEXT';
    public titleKey = 'NOTIFICATION.CHARGEBACK.TITLE';

    public foldable = false;

    private payWithQr: ComponentRef<PayWithQr>;

    public addExtraContent = (container: ViewContainerRef, componentFactoryResolver: ComponentFactoryResolver) => {
        const factory = componentFactoryResolver.resolveComponentFactory<PayWithQr>(PayWithQr);
        this.payWithQr = container.createComponent<PayWithQr>(factory);
        this.payWithQr.instance.value = this.value;
        this.payWithQr.instance.recipient = this.accountOwner;
        this.payWithQr.instance.iban = this.iban;
        this.payWithQr.instance.bic = this.bic;
        this.payWithQr.instance.reference = this.reference;
        this.payWithQr.changeDetectorRef.detectChanges();
    }

    public removeExtraContent = () => {
        if (this.payWithQr) this.payWithQr.destroy();
    }

    constructor(public value: number, public paymentDue: number, public accountOwner: string, public iban: string, public bic: string, public reference: string) {
        super();
        this.contentParams = {
            value: formatAmount(value),
            paymentDue: new Intl.DateTimeFormat().format(paymentDue),    // TODO dateStyle is valid - may need an update
            accountOwner,
            iban: formatIban(iban),
            bic,
            reference
        };
    }
}
