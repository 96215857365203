import { Injectable } from '@angular/core';
import {
    Lovestory,
    LovestoryListItem,
} from 'BKModels';
import {
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class LovestoryService {

    public constructor(private api: ApiService) {
    }

    @Cacheable(CacheKey.Lovestory)
    public get(id: string, language: string|undefined = undefined): Promise<Lovestory> {
        return this.api.lovestories.get(id, language).then(frame => frame.data[0]);
    }

    @Cacheable(CacheKey.LovestoryList)
    public getList(limit: number, offset?: number): Promise<LovestoryListItem[]> {
        return this.api.lovestories.getList(limit, offset).then(frame => frame.data);
    }


}
