import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

@Component({
               selector: 'page-indicator',
               template: `
                             <div class="page-indicator">
                                 <div *ngFor="let item of array; index as i"
                                      class="page-indicator__indicator"
                                      [class.page-indicator__indicator--active]="curId == i"
                                      (click)="change.emit(i)"
                                 ></div>
                             </div>`,
               styles: [require('./page-indicator.scss')],
           })

export class PageIndicator implements OnInit {
    private array = [];
    @Output() private change = new EventEmitter<number>();

    @Input() private count: number = 0;
    @Input() private curId: number = 0;

    public constructor() {
    }

    public ngOnInit() {
        this.array = Array.from(Array(this.count));
    }
}
