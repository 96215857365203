import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import {
    BehaviorSubject,
    combineLatest,
    interval,
} from 'rxjs';

import { CurrentUserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class RandomQuoteService {
    private quoteSource = new BehaviorSubject<{ text: string, source: string }>({ text: '', source: '' });
    quote$ = this.quoteSource.asObservable();

    constructor(
        private currentUserService: CurrentUserService,
        private http: HttpClient
    ) {
        combineLatest(this.http.get<{ text: string, source: string }[]>('./assets/quotes.json'), this.currentUserService.currentUserObservable, interval(1000))
            .subscribe(value => {
                const [quotes, currentUser] = value;
                const offset = Math.floor(moment.duration(moment().diff(moment('2021-01-01T00:00:00'))).asDays());
                const quote = quotes[(currentUser.id + offset) % quotes.length];
                if (this.quoteSource.value !== quote) this.quoteSource.next(quote);
            });
    }
}
