import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';
import { ApiService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { ProfileService } from '../../../../service/profile.service';
import { ProfileViewService } from '../profile-view-service';

enum Placeholder {
    None,
    NotAvailable,
    Ignore,
    IgnoredBy,
    Scammer
}

@Component({
               selector: 'profile-placeholder',
               template: `
                         <div class="profile-placeholder">
                             <profile-placeholder-item *ngIf="user && !user.exists" [title]="'PROFILE_VIEW.PLACEHOLDER.USER_NOT_EXIST.TITLE' | translate"></profile-placeholder-item>

                             <profile-placeholder-item *ngIf="placeholder === Placeholder.NotAvailable"
                                                       [title]="'PROFILE_VIEW.PLACEHOLDER.USER_NOT_AVAILABLE.TITLE' | translate | replace:'nickname':user.nickname:true"
                             ></profile-placeholder-item>
                             <profile-placeholder-item *ngIf="placeholder === Placeholder.Scammer"
                                                       [title]="'PROFILE_VIEW.PLACEHOLDER.SCAMMER.TITLE' | translate:{'nickname':user.nickname}"
                             ></profile-placeholder-item>

                             <profile-placeholder-item *ngIf="placeholder === Placeholder.IgnoredBy"
                                                       [title]="'PROFILE_VIEW.PLACEHOLDER.IGNORED_BY.TITLE' | translate:user"
                                                       [text]="'PROFILE_VIEW.PLACEHOLDER.IGNORED_BY.TEXT' | translate:user"
                             ></profile-placeholder-item>

                             <profile-placeholder-item *ngIf="placeholder === Placeholder.Ignore" [title]="'PROFILE_VIEW.PLACEHOLDER.IGNORE.TITLE' | translate:user"
                                                       [text]="'PROFILE_VIEW.PLACEHOLDER.IGNORE.TEXT' | translate:user"
                             >
                                 <flat-button (click)="goToIgnoreList()">
                                     {{'PROFILE_VIEW.PLACEHOLDER.IGNORE.TO_IGNORE_LIST' | translate}}
                                 </flat-button>
                                 <raised-button (click)="ignoreRemove()">{{'PROFILE_VIEW.PLACEHOLDER.IGNORE.REMOVE' | translate}}</raised-button>
                             </profile-placeholder-item>
                         </div>
  `,
               styles: [require('./profile-placeholder.scss')],
           })

export class ProfilePlaceholder {
    @Output() private close = new EventEmitter();
    @Input() private user: ProfileInfo = new ProfileInfo();
    @Output() private userChange = new EventEmitter<ProfileInfo>();

    private Placeholder = Placeholder;
    private get placeholder(): Placeholder {
        if (!this.user) return Placeholder.NotAvailable;
        if (this.user.ignore) return Placeholder.Ignore;
        if (this.user.ignoresMe) return Placeholder.IgnoredBy;
        if (this.user.scammer) return Placeholder.Scammer;
        if (!this.user.isAvailable && this.user.exists) return Placeholder.NotAvailable;
        return Placeholder.None;
    }

    public get isShowingPlaceholder(): boolean {
        return this.placeholder !== Placeholder.None;
    }

    public constructor(
        private navigationService: NavigationService,
        private profileViewService: ProfileViewService,
        private profileService: ProfileService,
    ) {
    }


    private goToIgnoreList() {
        setTimeout(() => {
            this.navigationService.navigateTo(Routes.SettingsIgnoreList);
        }, 100);
    }

    private ignoreRemove() {
        this.profileViewService
            .ignore
            .remove(this.user)
            .then(() => {
                this.user.ignore = false;
                this.profileService.trackProfileVisitor(this.user.id);
                this.userChange.emit(this.user);
            });
    }
}
