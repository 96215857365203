import {
    Component,
    EventEmitter,
    Output
} from '@angular/core';
import {
    Children,
    ChildrenWish,
    Language,
} from 'BKModels';
import { maxLengthMap } from '../../profile-edit/profile-edit';
import { ProfileEditBoxBase } from '../profile-edit-box/profile-edit-box-base';

@Component({
               selector:  'profile-edit-personal',
               template:  `
                              <profile-edit-box [title]="'PROFILE-EDIT.TITLE.PERSONALITY'|translate"
                                                [group]="group"
                                                (save)="save($event)"
                                                (reset)="reset($event)"
                              >
                                  <form [formGroup]="group">
                                      <profile-edit-row>
                                          <dropdown [placeholder]="'PROFILE_INFO.CHILDREN.LABEL' | translate"
                                                    [(value)]="children"
                                          >
                                              <dropdown-option *ngFor="let option of childrenOptions; let index = index"
                                                               [value]="index"
                                                               [selected]="isChildrenSelected(option)"
                                              >{{option.text | translate}}</dropdown-option>
                                          </dropdown>

                                      </profile-edit-row>
                                      <profile-edit-row>
                                          <dropdown [placeholder]="'PROFILE_INFO.CHILDREN_WISH.LABEL' | translate"
                                                    [(value)]="childrenWish"
                                          >
                                              <dropdown-option *ngFor="let option of childrenWishOptions; let index = index"
                                                               [value]="index"
                                                               [selected]="isChildrenWishSelected(option)"
                                              >{{option.text| translate }}</dropdown-option>
                                          </dropdown>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <dropdown [placeholder]="'PROFILE_INFO.SMOKER.LABEL' | translate" formControlName="smoker">
                                              <ng-container *ngFor="let key of 4 | range">
                                                  <dropdown-option *ngIf="key > 0"
                                                                   [value]="key"
                                                                   [selected]="key == getGroupValue('smoker')"
                                                  >
                                                      {{'PROFILE_INFO.SMOKER.OPTIONS.' + key  | translate }}
                                                  </dropdown-option>
                                              </ng-container>
                                          </dropdown>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.CAREER' | translate"
                                                     [maxLength]="maxLengthMap.career"
                                                     formControlName="career"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     (focusin)="focusInput.emit($event)"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.LIKE' | translate"
                                                     [maxLength]="maxLengthMap.like"
                                                     formControlName="like"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     (focusin)="focusInput.emit($event)"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.DISLIKE' | translate"
                                                     [maxLength]="maxLengthMap.dislike"
                                                     formControlName="dislike"
                                                     [autoresize]="true"
                                                     icon="edit"
                                          ></text-area>

                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.DESCRIPTION' | translate"
                                                     [maxLength]="maxLengthMap.description"
                                                     formControlName="description"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     (focusin)="focusInput.emit($event)"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.ABOUT_ME' | translate"
                                                     formControlName="aboutme"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     [maxLength]="maxLengthMap.aboutme"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.MOTTO' | translate"
                                                     formControlName="motto"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     [maxLength]="maxLengthMap.motto"
                                                     (focusin)="focusInput.emit($event)"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row>
                                          <text-area [placeholder]="'PROFILE_INFO.HOBBYS' | translate"
                                                     formControlName="hobbys"
                                                     [autoresize]="true"
                                                     icon="edit"
                                                     [maxLength]="maxLengthMap.hobbys"
                                                     (focusin)="focusInput.emit($event)"
                                          ></text-area>
                                      </profile-edit-row>

                                      <profile-edit-row [label]="'PROFILE_INFO.LANGUAGES' | translate" formGroupName="languages">
                                          <div class="mdc-layout-grid">
                                              <div class="mdc-layout-grid__inner" *ngFor="let language of languages">
                                                  <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-phone">
                                                      <checkbox [formControlName]="language[0]">
                                                          {{getKey(language[0]) | translate}}
                                                      </checkbox>
                                                  </div>
                                                  <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-desktop mdc-layout-grid__cell--span-6-phone">
                                                      <checkbox [formControlName]="language[1]">
                                                          {{getKey(language[1]) | translate}}
                                                      </checkbox>
                                                  </div>
                                              </div>
                                          </div>
                                      </profile-edit-row>
                                  </form>
                              </profile-edit-box>
                          `,
               styles: [require('./profile-edit-personal.scss')],
           })

export class ProfileEditPersonal extends ProfileEditBoxBase {
    @Output() focusInput = new EventEmitter();

    maxLengthMap = maxLengthMap;
    childrenOptions = [
        new ChildrenOption(Children.AskMe, 'PROFILE_INFO.CHILDREN.OPTIONS.ASK_ME'),
        new ChildrenOption(Children.No, 'PROFILE_INFO.CHILDREN.OPTIONS.NO'),
        new ChildrenOption(Children.Yes, 'PROFILE_INFO.CHILDREN.OPTIONS.YES'),
    ];

    languages = Object.values(Language).map((cur, i, arr) => i % 2 === 0 ? arr.slice(i, i + 2) : null).filter(cur => cur);

    childrenWishOptions = [
        new ChildrenOption(ChildrenWish.AskMe, 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.ASK_ME'),
        new ChildrenOption(ChildrenWish.DontKnow, 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.DONT_KNOW'),
        new ChildrenOption(ChildrenWish.No, 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.NO'),
        new ChildrenOption(ChildrenWish.Yes, 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.YES'),
    ];

    get children(): number {
        return this.childrenOptions.findIndex(cur => this.isChildrenSelected(cur));
    }

    set children(value: number) {
        if (value < 0) return;
        const selected = this.childrenOptions[value];
        if (this.group.controls.children.value !== selected.type) {
            this.group.markAsDirty();
            this.group.controls.children.setValue(selected.type);
        }
    }

    get childrenWish(): number {
        return this.childrenWishOptions.findIndex(cur => this.isChildrenWishSelected(cur));
    }

    set childrenWish(value: number) {
        if (value < 0) return;
        const selected = this.childrenWishOptions[value];

        if (this.group.controls.childrenWish.value !== selected.type) {
            this.group.markAsDirty();
            this.group.controls.childrenWish.setValue(selected.type);
        }
    }

    getKey(key: string): string {
        return `PROFILE_INFO.LANGUAGES.OPTIONS.${key.toUpperCase()}`;
    }

    isChildrenSelected(value: ChildrenOption<Children>) {
        return this.group.controls.children.value === value.type;
    }

    isChildrenWishSelected(value: ChildrenOption<ChildrenWish>) {
        return this.group.controls.childrenWish.value === value.type;
    }

}

class ChildrenOption<T> {
    constructor(public type: T, public text: string) {

    }
}
