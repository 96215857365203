import {
    Directive,
    ElementRef,
    HostListener,
    OnDestroy,
} from '@angular/core';
import * as htmlElementStringify from 'html-element-stringify';
import { Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import {
    delay,
    takeUntil,
} from 'rxjs/operators';

/**
 * Color interface
 */
interface Irgb {
    /**
     * Value of blue
     * @type {number}
     */
    blue: number;
    /**
     * Value of green
     * @type {number}
     */
    green: number;
    /**
     * Value of red
     * @type {number}
     */
    red: number;
}

/**
 * Directive for the Ripple effect
 */
@Directive({
               selector: '[ripple]',
               host:     {
                   '[class.ripple]':   'true',
                   '[style.position]': '"relative"',
                   '[style.outline]':  '"none"',
               },
           })
export class RippleDirective implements OnDestroy{
    private unsubscribe$ = new Subject();

    /**
     * constructor
     *
     {ElementRef} elRef
     */
    public constructor(private elRef: ElementRef) {

    }

    private getOffset(item): any {
        const { offsetParent } = item;
        if (offsetParent == null) {
            return {
                top:  item.offsetTop,
                left: item.offsetLeft,
            };
        }
        const { top, left } = this.getOffset(offsetParent);
        return {
            top:  item.offsetTop + top,
            left: item.offsetLeft + left,
        };
    }

    private getWaveColor(bgColor: string): string {
        const parsed = this.parseColor(bgColor);
        const newColor = this.shadeColor(parsed);
        return `rgb(${newColor.red},${newColor.green},${newColor.blue})`;
    }

    /**
     * EventListener for the click that starts the ripple
     {MouseEvent} e
     */
    @HostListener('click', ['$event'])
    private onClick(e: MouseEvent) {
        e.preventDefault();

        const item: any = e.currentTarget;
        const itemBgColor = window.getComputedStyle(this.elRef.nativeElement).backgroundColor;
        const offset = this.getOffset(item);

        const div = document.createElement('div');
        div.classList.add('ripple-effect');
        div.style.height = item.clientHeight;
        div.style.width = item.clientWidth;
        div.style.top = `${e.pageY - offset.top - 10}px`;
        div.style.left = `${e.pageX - offset.left - 10}px`;
        div.style.backgroundColor = this.getWaveColor(itemBgColor);

        item.insertAdjacentHTML('beforeend', htmlElementStringify(div));


        of(null)
            .pipe(delay(1500), takeUntil(this.unsubscribe$))
            .subscribe(() => item.removeChild(item.querySelector('.ripple-effect')));
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private parseColor(color: string): Irgb {
        const rgb = color.replace('rgb(', '')
                         .replace(')', '')
                         .split(',')
                         .map(cur => Number.parseInt(cur));
        return {
            red:   rgb[0],
            green: rgb[1],
            blue:  rgb[2],
        };
    }

    private shadeColor(color: Irgb): Irgb {
        const luminance = ((color.red * 299) + (color.green * 587) + (color.blue * 114)) / 1000;
        const percent = (luminance >= 128) ? -0.4 : 0.4;
        const baseColor = percent < 0 ? 0 : 255;
        const calc = num => Math.round((baseColor - num) * Math.abs(percent)) + num;

        return {
            red:   calc(color.red),
            green: calc(color.green),
            blue:  calc(color.blue),
        };
    }
}
