import { SideNavFooter } from './side-nav-footer/side-nav-footer';
import { SideNavItem } from './side-nav-item/side-nav-item';
import { SideNavNavigation } from './side-nav-navigation/side-nav-navigation';
import { SideNavSwitchItem } from './side-nav-switch-item/side-nav-switch-item';
import { SideNav } from './side-nav/side-nav';

export default [
    SideNav,
    SideNavItem,
    SideNavSwitchItem,
    SideNavFooter,
    SideNavNavigation,
];
