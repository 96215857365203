import { Component } from '@angular/core';
import {
    CurrentUser,
    UserBaseView,
} from 'BKModels';
import {
    CurrentUserService,
    ModalService,
} from 'BKService';
import { Subscription } from 'rxjs';
import { RadioUsersDataSource } from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';
import { GlobalModalService } from '../../../../service/global-modal.service';
import { BkModalProfilePictureMessage } from '../../../../shared/mat-design/bk-modals/bk-modal-profile-picture-message';
import { BkModalSchlagerherzenApplication } from '../../../../shared/mat-design/bk-modals/bk-modal-schlagerherzen-application';
import { RadioService } from '../radio.service';

@Component({
    selector: 'schlagerparadies-overview',
    template: `
                  <ng-container>
                      <schlagerherzen-on-air-banner *ngIf="isOnAir|async" (click)="listen()"></schlagerherzen-on-air-banner>
                      <schlagerherzen-banner *ngIf="!(isOnAir|async)"></schlagerherzen-banner>
                      <card class="info-card">
                          <div class="content">
                              <div class="title">
                                  <span>
                                      <img src="./assets/schlagerparadies/icon-heart.svg"/>
                                      {{ 'SCHLAGERHERZEN.OVERVIEW.TITLE'|translate }}
                                  </span>
                                  <img src="./assets/schlagerparadies/logo-radio-schlagerparadies.svg"/>
                              </div>
                              <p class="info-text">{{ 'SCHLAGERHERZEN.OVERVIEW.INFO_TEXT'|translate }}</p>
                              <div class="facts">
                                  <img src="./assets/schlagerparadies/Grafic-LoveRadar-Black.svg"/>
                                  <div class="fact-list">
                                      <div>
                                          <span>{{ 'SCHLAGERHERZEN.OVERVIEW.FACT.0.TITLE'|translate }}</span>
                                          <span class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.FACT.0.TEXT'|translate|markdown"></span>
                                      </div>
                                      <div>
                                          <span>{{ 'SCHLAGERHERZEN.OVERVIEW.FACT.1.TITLE'|translate }}</span>
                                          <span class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.FACT.1.TEXT'|translate|markdown"></span>
                                      </div>
                                      <div>
                                          <span>{{ 'SCHLAGERHERZEN.OVERVIEW.FACT.2.TITLE'|translate }}</span>
                                          <span class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.FACT.2.TEXT'|translate|markdown"></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="actions">
                                  <div>
                                      <div>{{ 'SCHLAGERHERZEN.OVERVIEW.APPLY.TEXT'|translate }}</div>
                                      <raised-button schlagerparadies class="apply-button" (click)="apply()">{{ 'SCHLAGERHERZEN.OVERVIEW.APPLY.ACTION'|translate }}</raised-button>
                                  </div>
                                  <div>
                                      <flat-button class="listen-button" (click)="listen()">
                                          <img src="./assets/schlagerparadies/icon-play-circle-yellow.svg"/>
                                          {{ 'SCHLAGERHERZEN.OVERVIEW.LISTEN.ACTION'|translate }}
                                      </flat-button>
                                  </div>
                              </div>
                          </div>
                      </card>

                      <card-openable [title]="'SCHLAGERHERZEN.SUPPORT.TITLE' | translate" class="support" state="visible">
                          <div class="text">
                              {{ 'SCHLAGERHERZEN.SUPPORT.TEXT'|translate }}
                          </div>
                          <div class="actions">
                              <raised-button (click)="openSupportProfile()">{{ 'SCHLAGERHERZEN.SUPPORT.ACTION'|translate }}</raised-button>
                          </div>
                      </card-openable>
                      
                      <separator *ngIf="!upcomingUsersDataSource.isEmpty" label="{{ 'SCHLAGERHERZEN.OVERVIEW.SINGLES.CURRENT.TITLE'|translate }}"></separator>
                      <schlagerparadies-single-list [listDataSource]="upcomingUsersDataSource"></schlagerparadies-single-list>
                      <separator *ngIf="!previousUsersDataSource.isEmpty" label="{{ 'SCHLAGERHERZEN.OVERVIEW.SINGLES.PREVIOUS.TITLE'|translate }}"></separator>
                      <schlagerparadies-single-list [listDataSource]="previousUsersDataSource"></schlagerparadies-single-list>
                      <faq [title]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.TITLE'|translate">
                          <faq-item [question]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.0.QUESTION'|translate">
                              <div class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.0.ANSWER'|translate|markdown"></div>
                          </faq-item>
                          <faq-item [question]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.1.QUESTION'|translate">
                              <div class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.1.ANSWER'|translate|markdown"></div>
                          </faq-item>
                          <faq-item [question]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.2.QUESTION'|translate">
                              <div class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.2.ANSWER'|translate|markdown"></div>
                          </faq-item>
                          <faq-item [question]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.3.QUESTION'|translate">
                              <div class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.3.ANSWER'|translate|markdown"></div>
                          </faq-item>    
                          <faq-item [question]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.4.QUESTION'|translate">
                              <div class="markdown p-nomargin" [innerHTML]="'SCHLAGERHERZEN.OVERVIEW.SINGLES.FAQ.4.ANSWER'|translate|markdown"></div>
                          </faq-item>
                      </faq>
                  </ng-container>`,
    styles: [require('./schlagerparadies-overview.scss')]
           })
export class SchlagerparadiesOverview {
    private upcomingUsersDataSource: RadioUsersDataSource;
    private previousUsersDataSource: RadioUsersDataSource;
    private isOnAir: Promise<boolean>

    private currentUserSubscription = new Subscription();
    private currentUser: CurrentUser = new CurrentUser();

    constructor(
        private currentUserService: CurrentUserService,
        private globalModalService: GlobalModalService,
        private modalService: ModalService,
        private dataSourceFactory: DataSourceFactory,
        private radioService: RadioService
    ) {
        this.currentUserSubscription = this
            .currentUserService
            .currentUserObservable
            .subscribe(currentUser => this.currentUser = currentUser);

        dataSourceFactory.createRadioUpcomingUsersDataSource().then(value => this.upcomingUsersDataSource = value);
        dataSourceFactory.createRadioPreviousUsersDataSource().then(value => this.previousUsersDataSource = value);
        this.isOnAir = radioService.isOnAir();
    }

    private apply() {
        if (this.currentUser.profileImgExist() || this.currentUser.isInRegistrationWithImage()) {
            this.globalModalService.open(BkModalSchlagerherzenApplication.typeId);
        } else {
            this.globalModalService.open(BkModalProfilePictureMessage.typeId, { textKey: 'MODAL.MISSING_PROFILE_PICTURE.MESSAGE.TEXT.SCHLAGERHERZEN' });
        }
    }

    private listen() {
        window.open('https://schlagerparadies.de/player/webplayer', '_blank');
    }

    private openSupportProfile() {
        this.modalService.profileView.open(UserBaseView.SchlagerherzenUserId);
    }
}