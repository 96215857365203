import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    forwardRef,
    Input,
    OnDestroy,
    QueryList,
    ViewChild,
} from '@angular/core';
import { MDCFloatingLabel } from '@material/floating-label';
import { MDCSelect } from '@material/select';
import { MDCSelectHelperText } from '@material/select/helper-text';
import { TranslateService } from '@ngx-translate/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownOption } from './dropdown-option';


@Component({
               selector:  'dropdown',
               template:  `
                              <div #select class="mdc-select mdc-select--outlined dropdown"
                                   [class.mdc-select--disabled]="disabled"
                              >
                                  <div class="mdc-select__anchor dropdown__anchor">
                                      <i class="mdc-select__dropdown-icon"></i>

                                      <div #selectedText class="mdc-select__selected-text dropdown__selected-text"></div>

                                      <div class="mdc-notched-outline"
                                      >
                                          <div class="mdc-notched-outline__leading"></div>
                                          <div class="mdc-notched-outline__notch">
                                              <label #label class="mdc-floating-label">{{placeholder | translate}}</label>
                                          </div>
                                          <div class="mdc-notched-outline__trailing"></div>
                                      </div>
                                  </div>

                                  <div class="mdc-select__menu mdc-menu mdc-menu-surface dropdown__menu">
                                      <ul class="mdc-list dropdown__menu__list">
                                          <ng-content></ng-content>
                                      </ul>
                                  </div>
                              </div>
                              <div #helperText
                                   *ngIf="!hintText.isEmpty()"
                                   class="mdc-select-helper-text mdc-select-helper-text--persistent"
                                   [class.mdc-select-helper-text--validation-msg]="!valid"
                                   aria-hidden="true"
                              >
                                  {{hintText}}
                              </div>
                          `,
               styles: [require('./dropdown.scss')],
               providers: [
                   getValueAccessorProvider(forwardRef(() => Dropdown)),
               ],
           })
export class Dropdown extends ValueAccessorBase<any> implements AfterViewInit, OnDestroy {
    public unsubscribe$ = new Subject();
    @ViewChild('select', { static: true }) public selectRef: ElementRef<HTMLElement>;
    @ViewChild('selectedText', { static: true }) public selectedTextRef: ElementRef<HTMLElement>;
    @Input() private errorText: string = '';
    private helperTextElement: MDCSelectHelperText;
    @ViewChild('helperText', { static: true }) private helperTextRef: ElementRef<HTMLElement>;
    @Input() private hintText: string = '';
    private innerChange = false;
    private label: MDCFloatingLabel = null;
    @ViewChild('label', { static: true }) private labelRef: ElementRef<HTMLElement>;
    @ContentChildren(DropdownOption) private options: QueryList<DropdownOption>;
    @Input() private placeholder: string = '';
    private select: MDCSelect = null;

    private isNotched = true;

    private get filled(): boolean {
        return this.value && this.value.toString().length > 0;
    }

    private get valid() {
        if (this.select == null) return true;
        return this.select.valid;
    }

    private set valid(value) {
        this.select.valid = value;
    }

    public constructor(private translateService: TranslateService) {
        super();
    }

    public layout(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                this.select.getDefaultFoundation().handleFocus();
                this.select.getDefaultFoundation().handleBlur();
                this.innerValue = this.value;
                resolve();
            }, 200);
        });
    }

    public ngAfterViewInit(): void {
        if (!this.hintText.isEmpty() && !this.errorText.isEmpty()) {
            this.helperTextElement = new MDCSelectHelperText(this.helperTextRef.nativeElement);
        }
        this.select = new MDCSelect(this.selectRef.nativeElement);
        this.label = new MDCFloatingLabel(this.labelRef.nativeElement);


        this.select.listen('MDCSelect:change', (event: any) => {
            this.innerChange = true;
            this.writeValue(event.detail.value);
        });


        setTimeout(() => {
            this.translateService
                .stream(this.selectedTextRef.nativeElement.innerText)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(text => {
                    this.selectedTextRef.nativeElement.innerText = text;
                    this.layout();
                });
        }, 100);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public writeValue(value: any) {
        if (this.select && !this.innerChange) {
            this.select.value = value;

            if(value){
                this.label.float(true);
                this.select.getDefaultFoundation().notchOutline(true);
            }

            this.options.forEach(it => {
                if (it.value == value) {
                    this.selectedTextRef.nativeElement.innerText = it.text;
                }
            });
        }

        this.innerChange = false;
        super.writeValue(value);
    }

    public get selectedIndex() {
        return this.select.selectedIndex;
    }

    public set selectedIndex(index) {
        this.select.selectedIndex = index;
    }

}
