import {
    AfterViewInit,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
} from '@angular/core';
import { growShrink } from 'BKUtils';
import { Subscription } from 'rxjs';
import { FaqItem } from './faq-item';

@Component({
               selector: 'faq',
               template: `
                             <div class="faq">
                                 <div class="faq__content_wrap">
                                     <div class="faq__title">{{ title }}</div>
                                     <div #container class="faq__container">
                                         <ng-content></ng-content>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./faq.scss')],
               animations: [growShrink],
           })

export class Faq implements AfterViewInit, OnDestroy {
    @Input() public title = '';
    @ContentChildren(FaqItem) items: QueryList<FaqItem>;

    private itemSubscriptions: Subscription[] = [];

    public ngAfterViewInit(): void {
        this.items.forEach((item, index, array) => {
            let subscription = item.click.subscribe((_) => this.toggleOpen(item));
            this.itemSubscriptions.push(subscription);
        });
    }

    private toggleOpen(openItem: FaqItem) {
        this.items.forEach(item => item.open = item === openItem);
    }

    public ngOnDestroy(): void {
        this.itemSubscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
