import { Component } from '@angular/core';

/**
 * Komponete für Seiten die nicht gefunden wurden 404
 */
@Component({
               selector: 'page-not-found',
               template: `
        <h1>Seite nicht gefunden</h1>
    `,
           })
export class PageNotFound {
}
