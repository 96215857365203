import { Injectable } from '@angular/core';
import {
    addLoginEventListener,
    addLogoutEventListener,
} from 'BKUtils';
import * as moment from 'moment';
import {
    BehaviorSubject,
    interval,
    Subscription,
} from 'rxjs';
import { skip } from 'rxjs/operators';
import { Survey } from '../models/Survey';
import { BkNps } from '../shared/mat-design/bk-modals/bk-nps';
import { ApiService } from './api';
import { SurveyType } from './api/calls/survey.api';
import { GlobalModalService } from './global-modal.service';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class SurveyService {
    private readonly surveys = new BehaviorSubject<Survey[]>(null);
    readonly surveys$ = this.surveys.asObservable().pipe(skip(1));

    private readonly registrationSurvey = new BehaviorSubject<Survey>(null);
    readonly registrationSurvey$ = this.registrationSurvey.asObservable().pipe(skip(1));

    private readonly npsSurvey = new BehaviorSubject<Survey>(null);
    readonly npsSurvey$ = this.npsSurvey.asObservable().pipe(skip(1));

    private readonly refreshInterval = interval(30 * 60 * 1000);
    private intervalSubscription = new Subscription();

    private npsSurveyShown = false;

    public constructor(
        private apiService: ApiService,
        private globalModalService: GlobalModalService,
        sessionService: SessionService
    ) {
        this.surveys$.subscribe((v) => {
            if (!v) return;

            let survey;
            survey = v.find(v => v.type === SurveyType.RegistrationSurvey);
            if (survey) this.registrationSurvey.next(survey);

            survey = v.find(v => v.type === SurveyType.NpsSurvey);
            if (survey) this.npsSurvey.next(survey);
        });

        this.npsSurvey$.subscribe(v => {
            if (!v) return;
            if (this.npsSurveyShown) return;
            if (moment() > moment(v.end_date)) return;

            globalModalService.open(BkNps.typeId);
            this.npsSurveyShown = true;
        });

        addLoginEventListener(() => this.onLogin());
        addLogoutEventListener(() => this.onLogout());
        if (sessionService.isLoggedIn()) this.onLogin();
    }

    sendRegistrationSurvey(score: number, message?: string): Promise<void> {
        return this.send(SurveyType.RegistrationSurvey, score, message);
    }

    sendNpsSurvey(score: number, message?: string): Promise<void> {
        return this.send(SurveyType.NpsSurvey, score, message);
    }

    send(type: SurveyType, score: number, message?: string): Promise<void> {
        return this.apiService.survey.post(type, score, message);
    }

    private onLogin() {
        this.intervalSubscription = this.refreshInterval.subscribe(() => this.onUpdate());
        this.onUpdate();
    }

    private onLogout() {
        this.intervalSubscription.unsubscribe();
        this.intervalSubscription = new Subscription();
    }

    private onUpdate() {
        this.apiService.survey.get()
            .then(response => this.surveys.next(response));
    }
}
