import {
    Component,
    Input,
} from '@angular/core';
import { Routes } from '../../../service/navigation.service';
import { NavigationRoute } from '../../../service/navigation/navigation-route';


export enum HintBoxType {
    Info= 'info',
    Warning = 'warning',
    Error = 'error',
    Tip = 'tip'
}

const iconMap: { [key in HintBoxType]: string } = {
    [HintBoxType.Info]:    '',
    [HintBoxType.Warning]: '',
    [HintBoxType.Error]:   'info-outline',
    [HintBoxType.Tip]:   'tipps-outline',
};


@Component({
               selector:  ' hint-box',
               template:  `
                              <div class="hint-box"
                                   [class.hint-box--warning]="isWarning"
                                   [class.hint-box--error]="isError"
                              >
                                  <div class="hint-box__body">
                                      <icon *ngIf="!icon.isEmpty()"
                                            class="hint-box__body__icon"
                                            [icon]="icon"
                                      ></icon>
                                      <div class="hint-box__body__wrapper">
                                          <div class="hint-box__body__title" *ngIf="!title.isEmpty()">{{title}}</div>
                                          <div class="hint-box__body__content">
                                              <ng-content select="[role=content]"></ng-content>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="hint-box__footer">
                                      <ng-content select="[role=footer]"></ng-content>
                                  </div>
                              </div>
                          `,
               styles: [require('./hint-box.scss')],
           })
export class HintBox {
    @Input() private title: string = '';
    @Input() private type: HintBoxType = HintBoxType.Info;

    private get icon(): string {
        return iconMap[this.type];
    }

    private get isWarning(): boolean {
        return this.type == HintBoxType.Warning;
    }

    private get isError(): boolean {
        return this.type == HintBoxType.Error;
    }


}
