import { Component } from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector: 'contacts-tabbar',
               template: `
                   <div class="contacts-chip-bar scroll-horizontal-hint-bp2-max">
                       <navigation-chip (click)="visitors()" [select]="visitorsSelected">
                           {{ 'CONTACTS.TAB.VISITORS' | translate }}
                       </navigation-chip>
                       <navigation-chip (click)="visited()" [select]="visitedSelected">
                           {{ 'CONTACTS.TAB.VISITED' | translate }}
                       </navigation-chip>
                       <navigation-chip (click)="favorites()" [select]="favoritesSelected">
                           {{ 'CONTACTS.TAB.FAVORITES' | translate }}
                       </navigation-chip>
                       <navigation-chip (click)="favoritesDeleted()" [select]="favoritesDeletedSelected">
                           {{ 'CONTACTS.TAB.FAVORITES.DELETED' | translate }}
                       </navigation-chip>
                   </div>
                         `,
               styles: [require('./contacts-tabbar.scss')]
           })

export class ContactsTabbar {
    private get visitorsSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ContactsVisitors);
    }

    private get visitedSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ContactsVisitorsMyVisits);
    }

    private get favoritesSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ContactsFavorites);
    }

    private get favoritesDeletedSelected(): boolean {
        return this.navigationService.isRouteActive(Routes.ContactsFavoritesDeleted);
    }

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {
    }

    private visitors() {
        this.trackingService.hit('Kontakte', 'TabGeklickt', 'Profilbesucher');
        this.navigationService.navigateTo(Routes.ContactsVisitors);
    }

    private visited() {
        this.trackingService.hit('Kontakte', 'TabGeklickt', 'BesuchteProfile');
        this.navigationService.navigateTo(Routes.ContactsVisitorsMyVisits);
    }

    private favorites() {
        const route = this.navigationService.getRoute(Routes.ContactsFavorites);
        console.log(route);

        this.trackingService.hit('Kontakte', 'TabGeklickt', 'Favoriten');
        this.navigationService.navigateTo(Routes.ContactsFavorites);
    }

    private favoritesDeleted() {
        const route = this.navigationService.getRoute(Routes.ContactsFavoritesDeleted);

        this.trackingService.hit('Kontakte', 'TabGeklickt', 'GeloeschteFavoriten');
        this.navigationService.navigateTo(Routes.ContactsFavoritesDeleted);
    }
}
