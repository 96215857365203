import { Component } from '@angular/core';

@Component({
               selector:  'settings-account-box',
               template:  `
                              <div class="settings-account-box">
                                  <div class="settings-account-box__left">
                                      <ng-content select="[left]"></ng-content>
                                  </div>
                                  <div class="settings-account-box__right">
                                      <ng-content select="[right]"></ng-content>
                                  </div>
                              </div>
                          `,
               styles: [require('./settings-account-box.scss')],
           })

export class SettingsAccountBox {}
