import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'tile',
               template:  `
                              <div class="mdc-card card">
                                  <div class="mdc-card__primary-action" tabindex="0" (click)="onClick.emit()">
                                      <div class="mdc-card__media card__media">
                                          <img-with-loading-indicator class="card__media__image" [src]="img"></img-with-loading-indicator>
                                      </div>

                                      <div class="card__primary">
                                          <h2 class="card__title">
                                              {{title}}
                                              <icon *ngIf="verified" class="card__verified-icon" no-top icon="check-circle"></icon>
                                          </h2>
                                          <h3 class="card__subtitle">{{subtitle}}</h3>
                                      </div>
                                  </div>
                                  <div class="mdc-card__actions card__action">
                                      <div class="mdc-card__action-buttons">
                                          <ng-content select="[buttons]"></ng-content>
                                      </div>
                                      <div class="mdc-card__action-icons">
                                          <ng-content select="[actions]"></ng-content>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./tile.scss')],
           })
export class Tile {
    @Input() private img: string = '';
    @Output() private onClick = new EventEmitter();
    @Input() private subtitle: string = '';
    @Input() private title: string = '';
    @Input() private verified: boolean = false;

    private get url(): string {
        return `url(${this.img})`;
    }
}


@Component({
               selector:  'card-action',
               template:  `
                              <icon-button classes="mdc-card__action mdc-card__action--icon"
                                           [icon]="icon"
                              ></icon-button>
                          `,
               styles: [require('./tile.scss')],
           })
export class CardAction {
    @Output() private click = new EventEmitter();
    @Input() private icon: string;
}


@Component({
               selector:  'card-button',
               template:  `

                              <flat-button classes="mdc-card__action mdc-card__action--icon">
                                  <ng-content></ng-content>
                              </flat-button>
                          `,
               styles: [require('./tile.scss')],
           })
export class CardButton {
    @Output() private click = new EventEmitter();
}
