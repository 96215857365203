/**
 * Created by olifra on 10.07.2017.
 */


import { Location } from '@angular/common';
import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { Subheader } from './subheader';


/**
 * Komponete für Subheader
 */
@Component({
               selector: 'subheader-arrow',
               template: `
        <subheader white>
            <div class="subheader-arrow">
                <back-arrow (back)="navBack($event)"></back-arrow>
                <div class="subheader-arrow__headline">
                    {{title}}
                </div>
            </div>
            <ng-content></ng-content>
        </subheader>
    `,
               styles: [require('./subheader-arrow.scss')],
           })
export class SubheaderArrow {
    @Input() private routerLink: Routes = Routes.Empty;
    @ViewChild(Subheader, { static: true }) private subheader: Subheader;
    @Input() private title: string;

    public constructor(private navigationService: NavigationService) {

    }

    public getHeight(): number {
        return this.subheader.getHeight();
    }

    private navBack($event) {
        if (this.routerLink === Routes.Empty) {
            this.navigationService.navigateBack()
        } else {
            this.navigationService.navigateTo(this.routerLink)
        }
    }
}
