import { Location } from '@angular/common';
import {
    AfterViewInit,
    Component,
    HostListener,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { growShrink } from 'BKUtils';
import {
    Observable,
    Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    extendHeader,
    shrinkHeader,
} from '../../../store/header.actions';
import { BKStore } from '../../../store/store';
import { Subheader } from './subheader';

@Component({
               selector:   'subheader-foldable',
               template:   `
                               <subheader>
                                   <div class="subheader-foldable">
                                       <div class="subheader-foldable__title">
                                           <p class="subheader-foldable__title__text"
                                              [class.subheader-foldable__title__text--disabled]="disabled"
                                              (click)="toggle()"
                                           >
                                               <back-arrow *ngIf="backArrow"
                                                           class="subheader-foldable__back-arrow"
                                                           (back)="location.back()"
                                               ></back-arrow>
                                               {{title}}
                                               <icon class="subheader-foldable__title__icon" [icon]="!(isHeaderExtended$|async) ? 'arrow-drop-up': 'arrow-drop-down'"></icon>
                                           </p>

                                           <div class="subheader-foldable__options">
                                               <ng-content select="[options]"></ng-content>
                                           </div>

                                       </div>

                                   </div>
                                   <div class="subheader-foldable__sub" id="subheader-foldable__sub" *ngIf="isHeaderExtended$|async"
                                        [@growShrink]
                                        (@growShrink.done)="layout()"
                                   >
                                       <ng-content select="[sub]"></ng-content>
                                   </div>
                               </subheader>
                           `,
               styles:  [require('./subheader-foldable.scss')],
               animations: [growShrink],
           })

export class SubheaderFoldable implements AfterViewInit, OnDestroy{
    private unsubscribe$ = new Subject();
    @ViewChild(Subheader, { static: true }) private subheader: Subheader;
    @Input() public disabled: boolean = false;
    @Input() public title: string = '';
    @Input() public backArrow: boolean = false;
    isHeaderExtended$: Observable<boolean>;
    headerExtendedState: boolean = true;



    public constructor(public location: Location, private store: Store<BKStore>) {
    }

    ngAfterViewInit()
    {
        this.isHeaderExtended$ = this.store.select('headerExtended');
        this.isHeaderExtended$.pipe(takeUntil(this.unsubscribe$)).subscribe(
{
            next: (headerState: boolean) =>
                  {
                      this.headerExtendedState = headerState;
                  },
        });
    }

    @HostListener('window:resize', ['$event'])
    public layout() {
        setTimeout(this.subheader.setHeight);
    }


    public open() {
        this.store.dispatch(extendHeader());
    }

    public close() {
        this.store.dispatch(shrinkHeader());
    }

    toggle() {
        if (this.disabled) return;
        if( this.headerExtendedState ) {
            this.close();
        }else{
            this.open();
        }
        this.layout();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

}
