import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    BehaviorSubject,
    Observable,
    throwError as observableThrowError,
} from 'rxjs';
import {
    catchError,
    filter,
    tap,
} from 'rxjs/operators';
import { LogEntry } from './log-entry';

@Injectable()
export class HttpDebugService {
    private requestQueue: BehaviorSubject<LogEntry[]> = new BehaviorSubject([]);

    public get logs(): Observable<LogEntry[]> {
        return this.requestQueue
                   .asObservable();
    }

    public add(event: HttpResponse<any>, sendTime: Date, error: boolean = false) {
        if (event.url && event.url.indexOf(BkConfig.apiPath) !== -1) {
            const header = event.headers;

            const time = {
                send: sendTime,
                response: new Date(),
            };

            const entry = new LogEntry(event,
                                       this.requestQueue.value.length,
                                       time,
                                       error);

            const newVal = [...this.requestQueue.value, entry];
            this.requestQueue.next(newVal);
        }
    }

    public setClicked(id: number) {
        const val = this.requestQueue.value;
        val[id].clicked = true;
        this.requestQueue.next(val);
    }
}

@Injectable()
export class HttpLogger implements HttpInterceptor {
    public constructor(private _httpDebugService: HttpDebugService) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const time: Date = new Date();
        return next.handle(req)
                   .pipe(
                       filter(event => event instanceof HttpResponse),
                       tap((event: HttpResponse<any>) => {
                           if (event.body !== null) {
                               this._httpDebugService.add(event, time);
                           }
                       }),
                       catchError((event) => {
                           this._httpDebugService.add(event, time, true);
                           return observableThrowError(event);
                       }),
                   );
    }
}
