import {
    EventEmitter,
    Output,
} from '@angular/core';

export class SaveAndReset {
    @Output('reset') private _reset: EventEmitter<any> = new EventEmitter();

    @Output('save') private _save: EventEmitter<any> = new EventEmitter();

    //eslint-disable-next-line no-empty-function
    protected onReset($event) {}

    //eslint-disable-next-line no-empty-function
    protected onSave($event) {}

    protected reset($event) {
        this._reset.emit($event);
        this.onReset($event);
    }

    protected save($event) {
        this._save.emit($event);
        this.onSave($event);
    }
}
