import {
    ApiResponseFrame,
    Gender,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import { Endpoints } from 'BKUtils';
import { map } from 'rxjs/operators';
import { ApiList } from '../../list.service';
import { ApiRequestService } from '../api-request.service';
import { ListVariant } from '../api.service';

/**
 * ApiEndpoints for Lists
 */

export class ListApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    /**
     * Läd die Liste der zuletzt Registrierten Benutzer
     *
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     {Observable<ApiResponseFrame<any>>}
     */
    public latestUser(limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.list.latestUser, {
                       query: {
                           limit,
                           offset,
                       },
                   })
                   .then((res) => {
                       res.data.result = UserBaseView.createMultiple(res.data.result);
                       return res;
                   });
    }

    /**
     * Läd eine Liste von Benutzern in der Nähe des Wohnortes des eingeloggten Benutzers
     *
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     {Observable<ApiResponseFrame<any>>}
     */
    public nearBy(limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.list.nearBy, {
                       query: {
                           limit,
                           offset,
                       },
                   })
                   .then((res) => {
                       res.data.result = UserBaseView.createMultiple(res.data.result);
                       return res;
                   });
    }

    /**
     * Läd die Lister der Profilbesucher
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     {Observable<ApiResponseFrame<any>>}
     */
    public profileVisitor(userInitiated: boolean, limit: number, offset: number = 0, variant: ListVariant = ListVariant.FirstPictureNotBlur, filtered: boolean = false): Promise<any> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.list.profileVisitors, {
                       body: {
                           userInitiated: userInitiated ? 1 : 0,
                           limit,
                           offset,
                           variant,
                           filtered: filtered ? 1 : 0
                       },
                   })
                   .then(frame => frame.data['visitors'].map(user => ProfileInfo.create(user)));
    }

    public profilesVisited(limit: number, offset: number = 0, filtered: boolean = false): Promise<ProfileInfo[]> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<{ visits: ProfileInfo[] }>>(Endpoints.list.profilesVisited, {
                       body: {
                           limit,
                           offset,
                           filtered: filtered ? 1 : 0
                       },
                   })
                   .then(frame => frame.data.visits.map(user => ProfileInfo.create(user)));
    }

    /**
     * Läd eine Liste von Benutzern die einen Echtheitscheckgeaht haben.
     *
     {number} limit - Anzahl der auszugebenen Einträge
     {number} offset - Anzahl der zu überspringenden Einträge bei der Ausgabe
     {Observable<ApiResponseFrame<any>>}
     */
    public real(gender: Gender, searchGender: Gender, ageFrom: number, ageTill: number, limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.search.get, {
                       body: {
                           limit,
                           offset,
                           'userSearch[verified]': true,
                           'userSearch[gender]': gender,
                           'userSearch[searchGender]': searchGender,
                           'userSearch[ageFrom]': ageFrom,
                           'userSearch[ageTo]': ageTill,
                       },
                   })
                   .then((res) => {
                       res.data.result = UserBaseView.createMultiple(res.data.result);
                       return res;
                   });
    }

    public selectedUsers(limit: number, offset: number = 0, premium: boolean = false): Promise<ApiList> {
        return this._apiRequestService
                   .createGet<any>(Endpoints.list.selectedUsers, {
                       query: {
                           offset,
                           limit,
                           premium,
                       },
                   })
                   .then(frame => {
                       const list = frame['data'];
                       list.result = list.result.map(user => ProfileInfo.create(user));
                       return list;
                   });
    }

    activeUsers(limit: number, offset: number = 0): Promise<ProfileInfo[]> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.list.activeUsers, {
                       query: {
                           limit,
                           offset,
                       },
                   })
                   .then(frame => frame.data.result.map(user => ProfileInfo.create(user)));
    }

    private createUserView(field = 'result') {
        return map((cur: any) => {
            cur[field] = UserBaseView.createMultiple(cur.data[field]);
            return cur;
        });
    }
}
