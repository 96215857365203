import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { PremiumBookingPageFooter } from './premium-booking-page/premium-booking-page-footer/premium-booking-page-footer';
import { PremiumBookingPageHeader } from './premium-booking-page/premium-booking-page-header/premium-booking-page-header';
import { PremiumBookingPageOffer } from './premium-booking-page/premium-booking-page-offer/premium-booking-page-offer';
import { PremiumBookingPagePaymentCash } from './premium-booking-page/premium-booking-page-payment-cash/premium-booking-page-payment-cash';
import { PremiumBookingPagePaymentCredit } from './premium-booking-page/premium-booking-page-payment-credit/premium-booking-page-payment-credit';
import { PremiumBookingPagePaymentDebit } from './premium-booking-page/premium-booking-page-payment-debit/premium-booking-page-payment-debit';
import { PremiumBookingPagePaymentPaypal } from './premium-booking-page/premium-booking-page-payment-paypal/premium-booking-page-payment-paypal';
import { PremiumBookingPagePaymentPrice } from './premium-booking-page/premium-booking-page-payment-price/premium-booking-page-payment-price';
import { PremiumBookingPagePayment } from './premium-booking-page/premium-booking-page-payment/premium-booking-page-payment';
import { PremiumBookingPageSafePaying } from './premium-booking-page/premium-booking-page-safe-paying/premium-booking-page-safe-paying';
import { PremiumBookingPageStepTitle } from './premium-booking-page/premium-booking-page-step-title/premium-booking-page-step-title';
import { PremiumBookingPage } from './premium-booking-page/premium-booking-page/premium-booking-page';
import { PremiumReverseDebit } from './premium-overview/premium-reverse-debit/premium-reverse-debit';
import { PremiumCancelLists } from './premium-cancel/premium-cancel-lists/premium-cancel-lists';
import { PremiumCancelSingleList } from './premium-cancel/premium-cancel-single-list/premium-cancel-single-list';
import { PremiumCancelStepList } from './premium-cancel/premium-cancel-step-list/premium-cancel-step-list';
import { PremiumCancelSuccessful } from './premium-cancel/premium-cancel-successful/premium-cancel-successful';
import { PremiumCancel } from './premium-cancel/premium-cancel/premium-cancel';
import { PremiumCancelReason } from './premium-cancel/premium-cancel-reason/premium-cancel-reason';
import { PremiumPurchaseAfterSaleDelaybox } from './premium-purchase-after-sale/premium-purchase-after-sale-delaybox/premium-purchase-after-sale-delaybox';
import { PremiumPurchaseAfterSaleList } from './premium-purchase-after-sale/premium-purchase-after-sale-list/premium-purchase-after-sale-list';
import { PremiumPurchaseAfterSaleSummary } from './premium-purchase-after-sale/premium-purchase-after-sale-summary/premium-purchase-after-sale-summary';
import { PremiumPurchaseAfterSale } from './premium-purchase-after-sale/premium-purchase-after-sale/premium-purchase-after-sale';
import { PremiumColumnBadge } from './premium-overview/premium-column-badge/premium-column-badge';
import { PremiumFlag } from './premium-flag/premium-flag';
import { PremiumInfo } from './premium-overview/premium-info/premium-info';
import { PremiumFeaturesFullModal } from './premium-overview/premium-features-full-modal/premium-features-full-modal';
import { PremiumLandingPageOfferBox } from './premium-landing-page/premium-landing-page-offer-box/premium-landing-page-offer-box';
import { PremiumOverview } from './premium-overview/premium-overview/premium-overview';
import { PremiumTableHeader } from './premium-overview/premium-table-header/premium-table-header';
import { PremiumTableRow } from './premium-overview/premium-table-row/premium-table-row';
import { PremiumLandingPageAwards } from './premium-landing-page/premium-landing-page-awards/premium-landing-page-awards';
import { PremiumLandingPageFaq } from './premium-landing-page/premium-landing-page-faq/premium-landing-page-faq';
import { PremiumLandingPageHeader } from './premium-landing-page/premium-landing-page-header/premium-landing-page-header';
import { PremiumLandingPageOffers } from './premium-landing-page/premium-landing-page-offers/premium-landing-page-offers';
import { PremiumLandingPageTestimonials } from './premium-landing-page/premium-landing-page-testimonials/premium-landing-page-testimonials';
import { PremiumLandingPageUsp } from './premium-landing-page/premium-landing-page-usp/premium-landing-page-usp';
import { PremiumLandingPage } from './premium-landing-page/premium-landing-page/premium-landing-page';
import { PremiumStar } from './premium-star/premium-star';
import { PremiumRouting } from './premium.routing';

@NgModule({
              imports:      [
                  ShareModule,
                  PremiumRouting,
              ],
              declarations: [
                  PremiumOverview,
                  PremiumColumnBadge,
                  PremiumCancel,
                  PremiumCancelLists,
                  PremiumCancelSingleList,
                  PremiumFlag,
                  PremiumTableRow,
                  PremiumTableHeader,
                  PremiumFeaturesFullModal,
                  PremiumInfo,
                  PremiumLandingPage,
                  PremiumLandingPageHeader,
                  PremiumLandingPageUsp,
                  PremiumLandingPageOffers,
                  PremiumLandingPageOfferBox,
                  PremiumLandingPageTestimonials,
                  PremiumLandingPageAwards,
                  PremiumLandingPageFaq,
                  PremiumBookingPage,
                  PremiumBookingPageHeader,
                  PremiumBookingPageStepTitle,
                  PremiumBookingPageOffer,
                  PremiumBookingPagePayment,
                  PremiumBookingPageFooter,
                  PremiumBookingPagePaymentDebit,
                  PremiumBookingPagePaymentPrice,
                  PremiumPurchaseAfterSale,
                  PremiumBookingPageSafePaying,
                  PremiumPurchaseAfterSaleList,
                  PremiumPurchaseAfterSaleList,
                  PremiumPurchaseAfterSaleSummary,
                  PremiumBookingPagePaymentCash,
                  PremiumBookingPagePaymentCredit,
                  PremiumBookingPagePaymentPaypal,
                  PremiumPurchaseAfterSaleDelaybox,
                  PremiumReverseDebit,
                  PremiumCancelReason,
                  PremiumCancelSuccessful,
                  PremiumCancelStepList,
                  PremiumStar,
              ],
              providers:    [],
          })
export class PremiumModule {
}
