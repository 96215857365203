import { Injectable } from '@angular/core';
import {
    ApiResponseFrame,
    RegcodeValidation,
} from 'BKModels';
import { ApiService } from './api';
import { ValidationApi } from './api/calls/validation.api';

@Injectable({ providedIn: 'root' })
export class ValidationService {
    private validationApi: ValidationApi;

    public constructor(apiService: ApiService) {
        this.validationApi = apiService.validation;
    }

    public validateRegCode(regCode: string): Promise<RegcodeValidation> {
        return this.validationApi.regCode(regCode)
            .then(response => {
                if (response.error) throw response.error_description.pop();
                return response.data;
            });
    }
}
