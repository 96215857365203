import {
    Component,
    Input,
} from '@angular/core';
import {
    CurrentUser,
    Message,
    OrderReason,
} from 'BKModels';
import {
    CommunicationService,
    CurrentUserService,
} from 'BKService';
import { getImageUrl } from '../../../../../service/api/api-endpoints';
import { ConversationService } from '../../../../../service/conversation.service';
import { MessageService } from '../../../../../service/message.service';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';
import * as moment from 'moment';

type ExtraInformation = {
    username: string;
    image: string;
}

@Component({
               selector: 'system-msg-fav-birthday',
               template: `
                             <system-msg-layout>
                                 <div class="headline markdown p-nomargin"
                                      [innerHTML]="headlineKey|translate:params|markdown"></div>
                                 <div class="image">
                                     <div *ngIf="hasImage" class="profile-image-border">
                                         <img [src]="image" />
                                     </div>
                                     <ng-container *ngIf="!hasImage">
                                         <img src="./assets/img/picStarParty.svg" />
                                     </ng-container>
                                 </div>
                                 <div class="text markdown first-last-nomargin checkbox-list"
                                      [innerHTML]="textKey|translate:params |markdown"></div>
                                 
                                 <ng-container *ngIf="!friend">
                                     <separator thickness="1px"></separator>
                                     <ng-container *ngIf="hasName">
                                         <system-msg-action-box image="./assets/img/sys-message/picBDayStarPremium.svg"
                                                                textKey="POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.ACTION.MESSAGE.TEXT"
                                                                actionTextKey="POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.ACTION.MESSAGE.ACTION"
                                                                [params]="params"
                                                                [premiumAction]="false"
                                                                (action)="messageUser()"></system-msg-action-box>
                                     </ng-container>
                                     <ng-container *ngIf="!hasName">
                                         <system-msg-action-box image="./assets/img/iconCrown.svg"
                                                                textKey="POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.ACTION.PREMIUM.TEXT"
                                                                actionTextKey="POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.ACTION.PREMIUM.ACTION"
                                                                [params]="params"
                                                                [premiumAction]="true"
                                                                (action)="premiumCta()"></system-msg-action-box>
                                     </ng-container>
                                 </ng-container>

                                 <separator thickness="1px"></separator>
                                 
                                 <div class="button-bar">
                                     <raised-button (click)="deleteMessage()">{{ 'POST_BOX.SYSTEM_MSG.ACTION.DELETE'|translate }}</raised-button>
                                 </div>
                             </system-msg-layout>
  `,
               styles: [require('../system-msg-common.scss')]
           })

export class SystemMsgFavBirthday {
    @Input() message: Message;
    @Input() friend: boolean = false;

    private currentUser = new CurrentUser();

    get params() {
        return {
            'username':    this.currentUser.nickname,
            'partnerName': this.message.extraInformation.username,
            'date':        moment(this.message.sendtime * 1000).format('DD.MM.YYYY')
        };
    }

    get extraInformation(): ExtraInformation {
        return this.message.extraInformation as ExtraInformation;
    }

    get hasName(): boolean {
        return this.isPremium && this.extraInformation.username && this.extraInformation.username !== 'unknown';
    }

    get hasImage(): boolean {
        return this.isPremium && this.extraInformation.image && !this.extraInformation.image.isEmpty();
    }

    get image(): string {
        return getImageUrl(this.extraInformation.image, '100x100', false);
    }

    get isPremium(): boolean {
        return this.currentUser.premium;
    }

    get headlineKey(): string {
        if (this.friend) return this.hasName ? 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FRIEND.TITLE': 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FRIEND.TITLE';
        else return this.hasName ? 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV_PRIME.TITLE': 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.TITLE';
    }

    get textKey(): string {
        if (this.friend) return 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FRIEND.TEXT';
        else return this.hasName ? 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV_PRIME.TEXT': 'POST_BOX.SYSTEM_MSG.BIRTHDAY_FAV.TEXT';
    }

    premiumCta() {
        this.premiumService.navigateToPremium(OrderReason.FavoriteBirthday);
    }

    messageUser() {
        const id = `${this.currentUser.id}-${this.message.triggeringUser}`;

        // TODO: hack - navigate to another route first so the conversation is properly loaded
        this.navigationService.navigateTo(Routes.MessagesAll).then(
            () => {
                this.navigationService.navigateTo(Routes.MessagesConversation, {
                    urlParam: {
                        [RouteParameter.ConversationId]: id,
                    },
                });
            }
        );
    }

    deleteMessage() {
        this.conversationService.delete([this.message.conversationId])
            .then((value) => {
                if (!value) throw new Error();
                this.gotoMessages();
            })
            .catch(() => {
                this.communicationService.growl.message.deleteConversation.handleError();
            });
    }

    private gotoMessages() {
        this.navigationService.navigateTo(Routes.MessagesAll);
    }

    public constructor(
        private currentUserService: CurrentUserService,
        private premiumService: PremiumService,
        private messageService: MessageService,
        private conversationService: ConversationService,
        private communicationService: CommunicationService,
        private navigationService: NavigationService
    ) {
        this.currentUserService.currentUser.then((value) => { this.currentUser = value; });
    }
}
