import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProfileEditRouteFragment } from '../../../../service/navigation.service';
import { NavigationElement } from './navigation-element/navigation-element';

@Injectable()
export class ProfileEditNavigationService {
    private scrollRequestSource = new Subject<ProfileEditRouteFragment>();
    private scrollStateSource = new Subject<boolean>();
    scrollRequest$ = this.scrollRequestSource.asObservable();
    isScrolling$ = this.scrollStateSource.asObservable();

    readonly navigationElements = [
        new NavigationElement(ProfileEditRouteFragment.Images, 'PROFILE-EDIT.TITLE.EDIT-IMAGES'),
        new NavigationElement(ProfileEditRouteFragment.MainData, 'PROFILE-EDIT.TITLE.MAINDATA'),
        new NavigationElement(ProfileEditRouteFragment.Look, 'PROFILE-EDIT.TITLE.LOOK'),
        new NavigationElement(ProfileEditRouteFragment.Search, 'PROFILE-EDIT.TITLE.SEARCH'),
        new NavigationElement(ProfileEditRouteFragment.Personal, 'PROFILE-EDIT.TITLE.PERSONALITY'),
        new NavigationElement(ProfileEditRouteFragment.Authenticity, 'ECHECK.MAIN.HEADLINE'),
    ];

    requestScroll(to: ProfileEditRouteFragment) {
        this.scrollRequestSource.next(to);
    }

    scrollState(scrolling: boolean) {
        this.scrollStateSource.next(scrolling);
    }
}
