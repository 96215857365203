import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({
          name: 'messages',
      })

export class MessagesPipe implements PipeTransform {
    public transform(value: string): string {
        return value.replace(new RegExp('↵', 'g'), '<br>');
    }
}
