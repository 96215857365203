import { Injectable } from '@angular/core';
import {
    ExtendedSearchCriteria,
    QuickSearchCriteria,
    UserBaseView,
    UserSearch,
} from 'BKModels';
import {
    ApiService,
    CurrentUserService,
    StoreService,
} from 'BKService';
import {
    addLoginEventListener,
} from 'BKUtils';
import {
    BehaviorSubject,
    combineLatest,
    Subscription,
} from 'rxjs';
import {
    Cache,
    CacheKey,
} from '../../../utils/cache';

export enum SearchVisiblePage {
    Search,
    NicknameSearch,
    ActiveUsers
}

@Injectable({ providedIn: 'root' })
export class SearchService {
    public static readonly minNicknameSearchLength = 3;
    public static EventKeys = {
        showQuickSearch:    'showQuickSearch',
        showExtendedSearch: 'showExtendedSearch',
    };

    private _visiblePage = new BehaviorSubject(SearchVisiblePage.Search);
    get visiblePage(): SearchVisiblePage {
        return this._visiblePage.getValue();
    }
    set visiblePage(v: SearchVisiblePage) {
        this._visiblePage.next(v);
        this.nicknameSearch.next('');
    }
    visiblePage$ = this._visiblePage.asObservable();

    public static showQuickSearch(key: string = '') {
        document.dispatchEvent(new CustomEvent(SearchService.EventKeys.showQuickSearch, { detail: key }));
    }

    public static showExtendedSearch(key: string = '') {
        document.dispatchEvent(new CustomEvent(SearchService.EventKeys.showExtendedSearch, { detail: key }));
    }

    public extendedCriteria = new BehaviorSubject<ExtendedSearchCriteria>(new ExtendedSearchCriteria());
    public quickCriteria = new BehaviorSubject<QuickSearchCriteria>(new QuickSearchCriteria());
    public nicknameSearch: BehaviorSubject<string> = new BehaviorSubject('');

    private criteriaUpdateSubscription: Subscription;

    public constructor(
        private currentUserService: CurrentUserService,
        private api: ApiService,
    ) {
        addLoginEventListener(() => this.reset());

        this.reset();
    }

    public reset() {
        if (this.criteriaUpdateSubscription) {
            this.criteriaUpdateSubscription.unsubscribe();
        }

        this.extendedCriteria.next(new ExtendedSearchCriteria());
        this.nicknameSearch.next('');
        this.visiblePage = SearchVisiblePage.Search;

        this.currentUserService.currentUser.then(user => this.quickCriteria.next(new QuickSearchCriteria(user)));

        this.criteriaUpdateSubscription = combineLatest([this.quickCriteria, this.extendedCriteria])
            .subscribe(([quickCriteria, extendedCriteria]) => {
                Cache.getInstance().delete(CacheKey.Search);
            });
    }

    public async search(limit: number, offset?: number): Promise<UserBaseView[]> {
        return this.api.search.search(await this.createUserSearch(), limit, offset);
    }

    public usernameSearch(limit: number, offset?: number): Promise<UserBaseView[]> {
        if (this.nicknameSearch.getValue().length < SearchService.minNicknameSearchLength) {
            return Promise.resolve([]);
        }
        return this.api.search.searchNickname(this.nicknameSearch.getValue(), limit, offset);
    }

    public resetSearch() {
        this.extendedCriteria.next(new ExtendedSearchCriteria());
        this.quickCriteria.next(new QuickSearchCriteria(this.currentUserService.cachedCurrentUser));
        this.visiblePage = SearchVisiblePage.Search;
    }

    private async createUserSearch(): Promise<UserSearch> {
        const search = {
            gender:       this.quickCriteria.getValue().gender,
            searchGender: (await this.currentUserService.currentUser).gender,
            ageFrom:      this.quickCriteria.getValue().ageRange[0],
            ageTo:        this.quickCriteria.getValue().ageRange[1],
            country:      this.quickCriteria.getValue().country,
            geoId:        this.quickCriteria.getValue().geolocation.geoId,
            distance:     this.quickCriteria.getValue().distance,
        };

        const extendedSearch = this.extendedCriteria.getValue().getUserSearch();
        return Object.assign(search, extendedSearch);
    }
}

