import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Gender } from 'BKModels';
import {
    ApiService,
    CommunicationService,
    CurrentUserService,
} from 'BKService';
import { Cropper } from '../cropper/cropper';
import { DialogBox } from '../dialog/dialog-box';
import { Modal } from '../modal/modal';

@Component({
               selector:  'image-upload-modal',
               template:  `
                              <modal [backgroundClose]="false">
                                  <div class="image-upload-modal__header">
                                      {{'EDIT_IMAGE_MODAL.TITLE' | translate}}
                                  </div>

                                  <div class="image-upload-modal__content">
                                      <cropper #cropper *ngIf="modal.isOpen" [imageUrl]="imageUrl"></cropper>

                                      <hint-box class="image-upload-modal__content__hint"
                                                [title]="'EDIT_IMAGE_MODAL.HINT.LABEL' | translate"
                                      >
                                          <div role="content" class="image-upload-modal__content__hint__content">
                                              <list-checked class="image-upload-modal__content__hint__content__list"
                                                            [list]="['EDIT_IMAGE_MODAL.LIST.1' | translate , 'EDIT_IMAGE_MODAL.LIST.2' | translate]"
                                              ></list-checked>

                                              <div>
                                                  <img class="image-upload-modal__content__hint__content__image" [src]="hintCheckImageUrl">
                                                  <img class="image-upload-modal__content__hint__content__image" [src]="hintFailImageUrl">
                                              </div>
                                          </div>
                                          
                                      </hint-box>
                                  </div>
                                  <div class="image-upload-modal__footer">
                                      <flat-button class="image-upload-modal__footer__button" (click)="openCloseDialog()">{{'EDIT_IMAGE_MODAL.CANCEL' | translate}}</flat-button>
                                      <raised-button class="image-upload-modal__footer__button" (click)="save()" [disabled]="saving">
                                          <loading-indicator *ngIf="saving" class="loading-indicator-button"></loading-indicator>
                                          {{'EDIT_IMAGE_MODAL.SAVE' | translate}}
                                      </raised-button>
                                  </div>
                              </modal>

                              <dialog-box #closeDialog
                                          [title]="'EDIT_IMAGE_MODAL.CLOSE_DIALOG.TITLE' | translate"
                                          [acceptText]="'GLOBAL.YES' | translate"
                                          [rejectText]="'GLOBAL.NO' | translate"
                                          (onAccept)="close()"
                              ></dialog-box>
                          `,
               styles: [require('./image-upload-modal.scss')],
           })

export class ImageUploadModal {
    @ViewChild('closeDialog', { static: true }) private closeDialog: DialogBox;
    @ViewChild('cropper', { static: false }) private cropper: Cropper;
    @Input() private gender: Gender = Gender.Male;
    private imageUrl: string = '';
    @ViewChild(Modal, { static: true }) private modal: Modal;
    @Output() private onError = new EventEmitter();
    @Output() private uploadComplete = new EventEmitter();

    @Input('imageUrl')
    private set _imageUrl(imageUrl: string) {
        this.imageUrl = imageUrl || '';
    }

    saving = false;

    private get hintCheckImageUrl(): string {
        const prefix = './assets/img/image-upload';
        if (this.gender === Gender.Female) {
            return `${prefix}/portrait-woman-check.png`;
        }
        return `${prefix}/portrait-man-check.png`;

    }

    private get hintFailImageUrl(): string {
        const prefix = './assets/img/image-upload';
        if (this.gender === Gender.Female) {
            return `${prefix}/portrait-woman-fail.png`;
        }
        return `${prefix}/portrait-man-fail.png`;

    }

    public constructor(
        private apiService: ApiService,
        private communicationService: CommunicationService,
        private translate: TranslateService,
        private currentUserService: CurrentUserService) {
    }

    public close() {
        this.modal.close();
    }

    public open() {
        this.modal.open();
    }

    private openCloseDialog() {
        this.closeDialog.open();
    }

    private save() {
        const img = this.cropper.getCroppedImage();
        this.saving = true;
        this.apiService
            .image
            .upload(img)
            .then((res) => {
                if (res.error) {
                    this.communicationService.growl.image.upload.handleError();
                    this.onError.emit();
                } else {
                    this.communicationService.growl.image.upload.handleSuccess();
                    if (res.data.state) {
                        this.currentUserService.loadCurrentUser();
                    }
                    this.uploadComplete.emit();
                }
                this.close();
            })
            .finally(() => {
                this.saving = false;
            });
    }
}
