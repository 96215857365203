import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'profile-interview-text',
               template:  `
                   <text-area class="text-input"
                             [maxLength]="limit"
                             [(ngModel)]="model"
                             rows="3"
                   ></text-area>
                          `,
           })
export class ProfileInterviewText {
    @Input() limit = 0;
    @Input() value = '';
    @Output() valueChange = new EventEmitter();

    get model(): string {
        return this.value;
    }

    set model(v: string) {
        this.valueChange.emit(v);
    }
}
