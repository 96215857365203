export class UserCounts {
    public profileVisitors: number = 0;
    public openMatches: number = 0;
    public matches: number = 0;
    public conversations: number = 0;
    public conversationsWithoutSystem: number = 0;
    public favorites: number = 0;

    public static create(data: UserCounts): UserCounts {
        const res = new UserCounts();

        res.profileVisitors = data.profileVisitors;
        res.openMatches = data.openMatches;
        res.matches = data.matches;
        res.conversations = data.conversations;
        res.conversationsWithoutSystem = data.conversationsWithoutSystem;
        res.favorites = data.favorites;

        return res;
    }
}
