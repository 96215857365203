import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { InternDefaultHeader } from '../../../shared/mat-design/header/intern-default-header';

import { Matching } from './container/matching';
import { MatchingHeader } from './container/matching-header';
import {
    MatchingPartner,
    MatchingStart,
    MatchingWantToMeet,
    MatchingWantYou,
    MatchingSecondChance
} from './pages';


const matchingRoutes: NgRoutes = [
    {
        path:     '',
        children: [
            { path: '', component: MatchingHeader, outlet: 'header' },

            {
                path:      '',
                component: Matching,
                children:  [
                    {
                        path:      routeName(Routes.MatchingStart),
                        component: MatchingStart,
                    }, {
                        path:      routeName(Routes.MatchingPartner),
                        component: MatchingPartner,
                    }, {
                        path:      routeName(Routes.MatchingYouWantMeet),
                        component: MatchingWantToMeet,
                    }, {
                        path:      routeName(Routes.MatchingSecondChance),
                        component: MatchingSecondChance,
                    }, {
                        path: routeName(Routes.MatchingLikeYou),
                        component: MatchingWantYou,
                    }
                ],
            },
        ],
    },
];


export const MatchingRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(matchingRoutes);
