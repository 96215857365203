import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    Achievements,
    CurrentUser,
    ProfileInfo,
    Settings,
} from 'BKModels';
import { addLogoutEventListener } from 'BKUtils';
import { BadgeCounts } from '../../models/badge-counts';
import { TrackingService } from '../tracking/tracking.service';
import { StoreBase } from './storage-base';
import {
    CookieStorage,
    CurrentLoginStorage,
    LocalStorage,
    SessionStorage,
} from './storages';

type UnsentMessages = {
    [conversationId: string]: string
};

/**
 * Store Service
 */
@Injectable({ providedIn: 'root' })
export class StoreService {
    readonly pushesRejected = new SessionStorage<boolean>((v = false) => v, 'bk.pushesRejected');
    readonly currentUser = new SessionStorage<CurrentUser>(CurrentUser.create, 'bk.currentUser');
    readonly currentUserProfileInfo = new SessionStorage<ProfileInfo>(ProfileInfo.create, 'bk.profileInfo');
    readonly achievements = new CurrentLoginStorage<Achievements>(Achievements.create);
    readonly badgeCounts = new CurrentLoginStorage<BadgeCounts>(BadgeCounts.create);
    private readonly _token = new SessionStorage<string>((cur = '') => cur, 'bk.token');
    readonly persistedToken = new CookieStorage((cur = '') => cur, 'bk.token', BkConfig.cookieSaveTime);
    // max-age of 0 as we are only reading the token
    readonly transferToken = new CookieStorage((cur = '') => cur, 'bk.transferToken', 0);
    readonly settings = new SessionStorage<Settings>(Settings.create, 'bk.settings');
    readonly matchingOnboardingAlreadyShown = new CurrentLoginStorage<boolean>(() => false);
    readonly matchingSecondChanceDontShow = new CurrentLoginStorage<boolean>(() => false);
    readonly matchingTutorialShown = new LocalStorage<boolean>((cur = false) => cur, 'bk.matchingTutorialClosed');
    readonly sendMessageWithEnter = new LocalStorage<boolean>((cur = false) => cur, 'bk.sendMessageWithEnter');

    readonly unsentMessages = new LocalStorage<UnsentMessages>((current:UnsentMessages = {}) => current, 'bk.unsentMessages');

    public get token(): StoreBase<string> {
        if (this._token.getValues().length === 0) {
            const persistedTokenValue = this.persistedToken.getValues().replace(/"|%22/g,'');
            const transferTokenValue = this.transferToken.getValues();
            if (!persistedTokenValue.isEmpty()) {
                this._token.next(persistedTokenValue);
                this.trackingService.hit('Login', 'GespeicherterLogin', 'GespeicherterLogin');
                this.persistedToken.next(persistedTokenValue);
            } else if (!transferTokenValue.isEmpty()) {
                this.trackingService.hit('Login', 'TransferLogin', 'TransferLogin');
                this._token.next(transferTokenValue);
                // Remove the used up token
                this.transferToken.remove();
            }
        }
        return this._token;
    }

    public constructor(
        private trackingService: TrackingService
    ) {
        addLogoutEventListener(() => {
            this.cleanAll();
        });
    }

    /**
     * Clear All Stores
     */
    public cleanAll() {
        Object.keys(this)
              .filter(key => this[key] instanceof StoreBase)
              .filter(key => !this[key].isProtected)
              .forEach(key => this[key].reset());
    }

    public cleanSession() {
        Object.keys(this)
              .filter(key => this[key] instanceof StoreBase)
              .filter(key => !this[key].isProtected)
              .filter(key => !this[key].isPersisted())
              .forEach(key => this[key].reset());
    }
}
