import {
    Component,
    Input,
} from '@angular/core';
import {
    Image,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
    StartpageRouteFragment,
} from '../../../../../service/navigation.service';

@Component({
               selector:  'premium-purchase-after-sale-list',
               template:  `
                              <div class="premium-purchase-after-sale-list">
                                  <div class="premium-purchase-after-sale-list__title">{{title}}</div>
                                  <div class="premium-purchase-after-sale-list__images-container">
                                      <img *ngFor="let user of list" class="premium-purchase-after-sale-list__user-image" [src]="getImage(user)"/>
                                      <div *ngIf="list.isEmpty()">
                                          {{emptyText}}
                                      </div>
                                  </div>
                                  <raised-button (click)="navigateToRoute()">{{ actualButtonText }}</raised-button>
                              </div>


                          `,
               styles: [require('./premium-purchase-after-sale-list.scss')],
           })

export class PremiumPurchaseAfterSaleList {
    @Input() private list: ProfileInfo[] = [];
    @Input() private route: Routes;
    @Input() private title: string;
    @Input() private emptyText: string;
    @Input() private buttonText: string;
    @Input() private trackingLabel: string;
    @Input() private emptyButtonText: string = null;
    @Input() private emptyRoute: Routes = null;

    private get actualRoute(): Routes {
        if (this.list.isEmpty() && !!this.emptyRoute) return this.emptyRoute;
        return this.route;
    }

    private get actualButtonText(): string {
        if (this.list.isEmpty() && !!this.emptyButtonText) return this.emptyButtonText;
        return this.buttonText;
    }

    public constructor(private navigationService: NavigationService, private trackingService: TrackingService) {
    }

    private navigateToRoute() {
        if (!this.list.isEmpty()) {
            this.trackingService.hit('Premium', 'AfterSales', this.trackingLabel);
        }
        this.navigationService.navigateTo(this.actualRoute, { fragment: StartpageRouteFragment.SelectedUser });
    }

    private getImage(user: UserBaseView): string {
        return Image.getUrlFromString('100x100', user.image, user.basicView);
    }
}
