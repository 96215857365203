import {
    Component,
    ViewChild,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import { ModalService } from 'BKService';
import { ModalDialog } from '../../../service/global-modal.service';
import { DialogBox } from '../dialog/dialog-box';

@Component({
               selector: 'bk-modal-removed-ignored-user',
               template: `
                             <ng-container>
                                 <dialog-box #removeSuccess
                                             [title]="'IGNORE.REMOVE.SUCCESS.TITLE'|translate"
                                             [rejectText]="'IGNORE.REMOVE.SUCCESS.PROFILE.ACTION'|translate"
                                             [acceptText]="'GLOBAL.CLOSE'|translate"
                                             (onReject)="openProfile()"
                                 >
                                     {{ 'IGNORE.REMOVE.SUCCESS.TEXT'|translate:{'username':user.nickname} }}
                                 </dialog-box>
                             </ng-container>
                         `,
           })
export class BkModalRemovedIgnoredUser implements ModalDialog {
    @ViewChild('removeSuccess', {'static': true}) private removeSuccessDialog: DialogBox;

    private user: UserBaseView = new UserBaseView();

    static readonly typeId = 'BkModalRemovedIgnoredUser';

    constructor(private modalService: ModalService) {}

    public openProfile() {
        this.close();
        this.modalService.profileView.open(this.user.id);
    }

    public open(config) {
        this.user = config;
        this.removeSuccessDialog.open();
    }

    public close() {
        this.removeSuccessDialog.close();
    }

    public get typeId(): string {
        return BkModalRemovedIgnoredUser.typeId;
    }
}