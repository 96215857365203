import { formatAmount } from 'BKUtils';
import * as moment from 'moment';

export class OpenInvoice {
    public hasDebt: boolean = false;
    public isAllowedToRebalance: boolean = true;
    public dueAt: Date = new Date();
    public total: number = 0;


    public static create(obj: any): OpenInvoice {
        const invoice = new OpenInvoice();
        invoice.hasDebt = obj.hasDebt;
        invoice.isAllowedToRebalance = obj.isAllowedToRebalance;
        invoice.dueAt = obj.dueAt;
        invoice.total = obj.total;
        return invoice;
    }


    public get formatDate() {
        return moment(this.dueAt).format('DD.MM.YYYY');
    }


    public get formatPrice() {
        return formatAmount(this.total);
    }
}
