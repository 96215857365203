import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    Gender,
    ImageSize,
    ProfileInfo,
} from 'BKModels';
import { CommunicationService } from 'BKService';
import { ModalDialog } from '../../../service/global-modal.service';
import { MessageService } from '../../../service/message.service';
import { Modal } from '../modal/modal';

@Component({
               selector:  'bk-modal-write-message',
               template: `
                             <modal #modal (onClose)="close()">
                                 <div class="modal-content">
                                     <ng-container *ngIf="showWarning && !writeAnyway">
                                         <div class="title-bar">
                                             <icon icon="error" class="icon"></icon>
                                             <div class="title markdown p-nomargin" [innerHTML]="'MODAL.WRITE_MESSAGE.AGE_MISMATCH.TITLE'|translate:translationVars|markdown"></div>
                                         </div>
                                         <div class="text markdown p-nomargin" [innerHTML]="mismatchTextKey|translate:translationVars|markdown"></div>
                                         <div class="actions">
                                             <flat-button (click)="writeAnywayAction()">{{ 'MODAL.WRITE_MESSAGE.ACTION.WRITE_ANYWAY'|translate }}</flat-button>
                                             <raised-button (click)="close()">{{ 'GLOBAL.CANCEL'|translate }}</raised-button>
                                         </div>
                                     </ng-container>
                                     <ng-container *ngIf="!showWarning || writeAnyway">
                                         <div class="user-panel">
                                             <img-with-loading-indicator class="user-image" [src]="image"></img-with-loading-indicator>
                                             <div class="user-info">
                                                 <div class="name">
                                                     {{ user.nickname }}
                                                 </div>
                                                 <div class="age-and-location">
                                                     {{ user.age }}&nbsp;|&nbsp;{{ user.city }}
                                                 </div>
                                             </div>
                                         </div>
                                         <div class="text markdown p-nomargin" [innerHTML]="'MODAL.WRITE_MESSAGE.TEXT'|translate:translationVars|markdown"></div>
                                         <text-area class="message"
                                                    [placeholder]="'MODAL.WRITE_MESSAGE.MESSAGE_PLACEHOLDER'|translate:translationVars"
                                                    [(value)]="message"
                                                    maxLength="250"
                                                    rows="2"
                                         ></text-area>
                                         <div class="info markdown p-nomargin">
                                             {{ 'MODAL.WRITE_MESSAGE.MESSAGE_EXAMPLE'|translate:translationVars }}
                                         </div>
                                         <div class="actions">
                                             <flat-button (click)="close()" [disabled]="busy">{{ 'GLOBAL.CLOSE'|translate }}</flat-button>
                                             <raised-button (click)="writeAction()" [disabled]="message.trim().isEmpty() || busy">
                                                 <loading-indicator *ngIf="busy" class="loading-indicator-button"></loading-indicator>
                                                 {{ 'MODAL.WRITE_MESSAGE.ACTION.WRITE'|translate }}
                                             </raised-button>
                                         </div>
                                     </ng-container>
                                 </div>
                             </modal>
                         `,
               styles: [require('./bk-modal-write-message.scss')]
           })
export class BkModalWriteMessage implements ModalDialog {
    @ViewChild('modal', { 'static': true }) private modal: Modal;

    private currentUser = new CurrentUser();

    user = new ProfileInfo();

    message = "";

    busy = false;

    writeAnyway = false;

    get showWarning(): boolean {
        if (this.user.isMatch) return false;

        return this.currentUser.age < this.user.ageFrom
            || this.currentUser.age > this.user.ageTill
            || !(this.currentUser.gender === this.user.searchGender || this.user.searchGender === Gender.Both);
    }

    get mismatchTextKey(): string {
        if (this.user.searchGender === Gender.Male) return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT.MALE';
        else if (this.user.searchGender === Gender.Female) return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT.FEMALE';
        else return 'MODAL.WRITE_MESSAGE.SEARCH_MISMATCH.TEXT';
    }

    get translationVars() {
        return {
            username: this.user?.nickname ?? '',
            from: (this.user?.ageFrom ?? 0).toString(),
            to: (this.user?.ageTill ?? 0).toString()
        };
    }

    get image(): string {
        if (!this.user) return "";

        return this.user.getProfilePictureUrl(ImageSize['100x100']);
    }

    static readonly typeId = 'BkModalWriteMessage';

    open(config) {
        if (!config?.currentUser) throw "Missing currentUser config for BkModalWriteMessage";
        if (!config?.user) throw "Missing user config for BkModalWriteMessage";

        this.writeAnyway = false;
        this.message = "";
        this.currentUser = config?.currentUser;
        this.user = config?.user;

        this.modal.open();
    }

    close() {
        // TODO tracking
        this.modal.close();
    }

    get typeId(): string {
        return BkModalWriteMessage.typeId;
    }

    writeAnywayAction() {
        // TODO tracking
        this.writeAnyway = true;
    }

    writeAction() {
        // TODO tracking

        this.busy = true;
        this.messageService.sendMessage(this.user, this.message)
            .then(() => {
                this.communicationService.growl.message.send.handleSuccess(this.user);
                this.close();
            })
            .catch((reason) => {
                this.communicationService.growl.message.send.handleError(reason.errno, this.user);
            })
            .finally(() => {
                this.busy = false;
            });
    }

    constructor(
       private messageService: MessageService,
       private communicationService: CommunicationService
    ) {}
}
