/**
 * Created by olifra on 02.05.2017.
 */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MasonryModule } from 'angular2-masonry';
import { MarkdownModule } from 'ngx-markdown';
import { translationConfig } from '../app.translation';
import { AdsModule } from '../module/intern/ads/ads.module';
import { Awards } from '../module/intern/awards/awards';
import { LogoutLovestoryBox } from '../module/logout/components/lovestory/logout-lovestory-box';
import { MatModule } from './mat-design/mat.module';
import { PipesModule } from './pipes';
import { DirectiveModule } from './directives';
import { ShowTranslationKeys } from './show-translation-keys/show-translation-keys';


@NgModule({
              imports: [
                  CommonModule,
                  FormsModule,
                  HttpClientModule,
                  ReactiveFormsModule,
                  RouterModule,
                  TranslateModule.forChild(translationConfig),
                  MarkdownModule.forRoot(),
                  MasonryModule,
                  PipesModule,
                  MatModule,
                  DirectiveModule,
                  AdsModule,
              ],

              exports: [
                  CommonModule,
                  FormsModule,
                  HttpClientModule,
                  ReactiveFormsModule,
                  RouterModule,
                  TranslateModule,
                  MasonryModule,
                  PipesModule,
                  DirectiveModule,
                  MatModule,
                  MarkdownModule,
                  ShowTranslationKeys,
                  LogoutLovestoryBox,
                  Awards,
                  AdsModule,
              ],
              declarations: [ShowTranslationKeys, LogoutLovestoryBox, Awards],
              providers: [],
          })
export class ShareModule {
}


export * from './pipes';
