import { Survey } from '../../../models/Survey';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export enum SurveyType {
    Invalid = '',
    RegistrationSurvey = 'registration-survey',
    NpsSurvey = 'nps'
}

export class SurveyApi {
    public constructor(private apiRequestService: ApiRequestService) {}

    public get(): Promise<Survey[]> {
        return this.apiRequestService
                   .createGet<Survey[]>(Endpoints.survey.get);
    }

    public post(type: SurveyType, score: number, message?: string): Promise<void> {
        const body = { type, vote: score, comment: message };
        const endpointValue = { type };

        return this.apiRequestService
                   .createPost<void>(Endpoints.survey.post, { body, endpointValue, json: true });
    }

}
