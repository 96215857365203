import {
    AfterViewInit,
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import { ProfileVisitorDataSource } from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';
import { ListService } from '../../../../service/list.service';
import { PremiumService } from '../../../../service/premium.service';

@Component({
               selector:  'profile-visitors-others',
               template: `
                             <div class="profile-visitor">
                                 <profile-visitor-empty *ngIf="isEmpty && !isLoading" [currentUser]="curUser"></profile-visitor-empty>

                                 <list-container [dataSource]="listDataSource">
                                     <ng-template let-item>
                                         <list-item-selector two-columns-max
                                                             [listItem]="item"
                                                             (onOpenProfile)="onOpenProfile()"
                                                             (onAddFavorite)="onAddFavorite()"
                                                             (onOpenConversation)="onOpenConversation()"
                                                             (onDeleteFavorite)="onDeleteFavorite()"
                                                             (gotoPremium)="gotoPremium()"
                                         ></list-item-selector>
                                     </ng-template>
                                 </list-container>
                             </div>
                         `,
               styles: [require('./profile-visitors-others.style.scss')],
           })

export class ProfileVisitorsOthers implements OnInit, AfterViewInit {
    private curUser: CurrentUser = new CurrentUser();
    private listDataSource: ProfileVisitorDataSource;

    public constructor(
        private listService: ListService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private dataSourceFactory: DataSourceFactory,
        private premiumService: PremiumService,
    ) {
    }

    public ngOnInit() {
        this.currentUserService
            .currentUser
            .then(currentUser => this.curUser = currentUser);

        this.dataSourceFactory.createProfileVisitorsDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });
    }

    public ngAfterViewInit(): void {
        this.currentUserService.refreshBadgeCount();
    }

    private get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    private get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    private onOpenProfile() {
        this.trackingService.hit('Profilbesucher', 'Profilbesucherkarte', 'ProfilAufgerufen');
    }

    private onOpenConversation() {
        this.trackingService.hit('Profilbesucher', 'Profilbesucherkarte', 'Konversation');
    }

    private onAddFavorite() {
        this.trackingService.hit('Profilbesucher', 'Profilbesucherkarte', 'FavoritHinzugefuegt');
    }

    private onDeleteFavorite() {
        this.trackingService.hit('Profilbesucher', 'Profilbesucherkarte', 'FavoritGeloescht');
    }

    private gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.ProfileVisitor);
    }
}
