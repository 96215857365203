import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { OrderReason } from 'BKModels';
import { TrackingService } from 'BKService';
import {
    interval,
    Subscription,
} from 'rxjs';
import {
    ProfilesVisitedPreviewDataSource,
    ProfileVisitorPreviewDataSource,
} from '../../../models/list-data-sources';
import { DataSourceFactory } from '../../../service/data-source-factory';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { PremiumService } from '../../../service/premium.service';

@Component({
               selector:  'profile-visits-preview',
               template: `
                             <ng-container>
                                 <card class="profile-visits-card" *ngIf="showContainer">
                                     <div #visitsContainer class="profile-visits-container">
                                         <ng-container *ngIf="showVisitors">
                                             <div class="list-title">
                                                 <span>{{ 'PROFILE_VISITS_PREVIEW.VISITORS.TITLE'|translate }}</span>
                                                 <a (click)="moreVisitors()" class="list-link">{{ 'PROFILE_VISITS_PREVIEW.MORE'|translate }}</a>
                                             </div>
                                             <list-container *ngIf="visitorDataSource" [dataSource]="visitorDataSource" [singleFetch]="true">
                                                 <ng-template let-item>
                                                     <list-item-selector three-columns
                                                                         [listItem]="item"
                                                                         (onOpenProfile)="onOpenProfile()"
                                                                         (gotoPremium)="onGotoPremium()"
                                                     ></list-item-selector>
                                                 </ng-template>
                                             </list-container>
                                         </ng-container>
                                         <ng-container *ngIf="showVisited">
                                             <div class="list-title">
                                                 <span>{{ 'PROFILE_VISITS_PREVIEW.VISITED.TITLE'|translate }}</span>
                                                 <a (click)="moreVisited()" class="list-link">{{ 'PROFILE_VISITS_PREVIEW.MORE'|translate }}</a>
                                             </div>
                                             <list-container *ngIf="visitedDataSource" [dataSource]="visitedDataSource" [singleFetch]="true">
                                                 <ng-template let-item>
                                                     <list-item-selector three-columns
                                                                         [listItem]="item"
                                                                         (onOpenProfile)="onOpenProfile()"
                                                                         (gotoPremium)="onGotoPremium()"
                                                     ></list-item-selector>
                                                 </ng-template>
                                             </list-container>
                                         </ng-container>
                                     </div>
                                 </card>
                             </ng-container>
                         `,
               styles: [require('./profile-visits-preview.scss')],
           })
export class ProfileVisitsPreview implements AfterViewInit, OnDestroy {
    @ViewChild('visitsContainer', { read: ElementRef, static: false }) visitsContainerRef: ElementRef<HTMLElement>
    visitorDataSource: ProfileVisitorPreviewDataSource;
    visitedDataSource: ProfilesVisitedPreviewDataSource;

    private intervalSubscription = new Subscription();

    get showVisitors(): boolean {
        return this.visitorDataSource && this.visitorDataSource.length >= 3;
    }

    get showVisited(): boolean {
        return this.visitedDataSource && this.visitedDataSource.length >=3;
    }

    get showContainer(): boolean {
        return this.showVisitors || this.showVisited;
    }

    onOpenProfile() {
        this.trackingService.hit('Startseite', 'Profilbesuche', 'ProfilAufgerufen');
    }

    onOpenFullList() {
        this.navigationService.navigateTo(Routes.ActiveUsers);
    }

    onGotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.ProfileVisitor);
    }

    moreVisitors() {
        this.navigationService.navigateTo(Routes.ProfileVisitorsOtherVisits);
    }

    moreVisited() {
        this.navigationService.navigateTo(Routes.ProfileVisitorsMyVisits);
    }

    ngAfterViewInit(): void {
        Promise.all([this.dataSourceFactory.createProfileVisitorsPreviewDataSource(), this.dataSourceFactory.createProfilesVisitedPreviewDataSource()])
               .then(([visitorDataSource, visitedDataSource]) => {
                   this.visitorDataSource = visitorDataSource;
                   this.visitedDataSource = visitedDataSource;

                   this.refresh();

                   // Regular updates
                   this.intervalSubscription = interval(1 * 60 * 1000).subscribe(() => {
                       if (this.visitsContainerRef && this.visitsContainerRef.nativeElement.offsetParent !== null) {
                           this.refresh();
                       }
                   });
               });
    }

    refresh() {
        this.visitorDataSource.refresh();
        this.visitedDataSource.refresh();
    }

    ngOnDestroy(): void {
        this.intervalSubscription.unsubscribe();
    }

    constructor(
        private dataSourceFactory: DataSourceFactory,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private premiumService: PremiumService,
    ) {
    }
}
