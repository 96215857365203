import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    Image,
    MatchingDecision,
    MatchingQueueItem,
    ProfileInfo,
} from 'BKModels';
import {
    PlayedPartnerQueue,
    TrackingService,
} from 'BKService';
import { GlobalModalService } from '../../../../../service/global-modal.service';
import { BkMissedChanceMatching } from '../../../../../shared/mat-design/bk-modals/bk-missed-chance-matching';

/**
 * Component for the Matching game
 */
@Component({
               selector:  'bk-matching-game',
               template:  `
                              <div class="bk-matching-game">
                                  <matching-film-roll [matchingQueue]="matchingQueue"
                                                      [playedPartnerList]="playedPartnerList"
                                                      class="bk-matching-game__roll"
                                  ></matching-film-roll>

                                  <div class="bk-matching-game__contingent" *ngIf="!currentUser.isPremium()">
                                      {{currentUser.remainingMatchingContingent}} {{'MATCHING.GAME.CONTINGENT' | translate}}
                                  </div>

                                  <matching-user-card class="bk-matching-game__card"
                                                      [partner]="partner"
                                                      [currentUser]="currentUser"
                                                      [(didShowDecisionDialog)]="didShowDecisionDialog"
                                  ></matching-user-card>


                                  <div class="bk-matching-game__decision">
                                      <matching-option
                                              icon="close"
                                              [text]="'GLOBAL.NO'| translate"
                                              (click)="clickNo()"
                                              type="no"
                                      ></matching-option>
                                      <matching-option
                                              icon="help"
                                              [text]="'MATCHING.MAYBE'| translate"
                                              (click)="clickMaybe()"
                                              type="maybe"
                                      ></matching-option>
                                      <matching-option
                                              icon="match"
                                              [text]="'GLOBAL.YES'| translate"
                                              (click)="clickYes()"
                                              type="yes"
                                      ></matching-option>
                                  </div>

                              </div>
                          `,
               styles: [
                   require('./matching-game.scss'),
               ],
           })
export class MatchingGame {
    /**
     * Decision od the partner
     {EventEmitter<any>}
     */
    @Output() decide: EventEmitter<any> = new EventEmitter();
    @Input() currentUser: CurrentUser = new CurrentUser();
    @Input() matchingQueue: MatchingQueueItem[] = [];
    @Input() playedPartnerList: PlayedPartnerQueue = new PlayedPartnerQueue();

    didShowDecisionDialog = false;

    public constructor(
        private trackingService: TrackingService,
        private globalModalService: GlobalModalService
        ) {}

    /**
     * The user who is displayed to the current user in the match
     {ProfileInfo}
     */
    @Input() partner: ProfileInfo = new ProfileInfo();

    private get partnerDecisionYesOrMaybe() {
        return this.partner.decisionPartner === MatchingDecision.Yes || this.partner.decisionPartner === MatchingDecision.Maybe;
    }

    clickMaybe() {
        this.decide.emit(MatchingDecision.Maybe);
        this.trackingService.hit('Matching', 'MatchingSpielen', 'Vielleicht');
        this.didShowDecisionDialog = false;
    }

    clickNo() {
        this.decide.emit(MatchingDecision.No);
        this.trackingService.hit('Matching', 'MatchingSpielen', 'Nein');
        this.didShowDecisionDialog = false;

        if (this.partnerDecisionYesOrMaybe) {
            this.globalModalService.open(BkMissedChanceMatching.typeId, { user: this.partner });
        }
    }

    clickYes() {
        this.decide.emit(MatchingDecision.Yes);
        this.trackingService.hit('Matching', 'MatchingSpielen', 'Ja');
        this.didShowDecisionDialog = false;
    }

    private getUrlArray(): any[] {
        return Image.getUrlArray('640x640', this.partner.fullGallery);
    }
}
