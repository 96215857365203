import { NavigationList } from './navigation-element/navigation-list';
import profileEditBox from './profile-edit-box';
import { ProfileEditEmail } from './profile-edit-email/profile-edit-email';
import { ProfileEditHeader } from './profile-edit-header/profile-edit-header';
import { ProfileEditRow } from './profile-edit-row/profile-edit-row';
import { ProfileEdit } from './profile-edit/profile-edit';


export default [
    ProfileEdit,
    ProfileEditRow,
    ProfileEditEmail,
    ProfileEditHeader,
    NavigationList,
    ...profileEditBox,
];
