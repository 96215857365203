import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { StoreService } from 'BKService';
import { replaceTextSmileysByEmoji } from 'BKUtils';
import { Subscription } from 'rxjs';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import { MessageService } from '../../../../../service/message.service';
import { TextareaAutosize } from '../../../../../shared/mat-design/text-field/text-area';

@Component({
               selector:  'chat-input-field',
               template:  `
                              <ng-container>
                                  <card class="chat-input-field">
                                      <div class="chat-input-field__line">
                                          <div class="emojis" (clickOutside)="emojisOpen = false">

                                              <a bk-link (click)="toggleEmojis()" class="chat-input-field__icon">
                                                  <icon icon="insert-emoticon"></icon>
                                              </a>

                                              <emoji-picker *ngIf="emojisOpen"
                                                            class="emoji-picker"
                                                            (select)="selectEmoji($event)"
                                              ></emoji-picker>
                                          </div>
                                          <textarea class="chat-input-field__input"
                                                    #input
                                                    [autoresize]="true"
                                                    [(ngModel)]="text"
                                                    [placeholder]="'CHAT.INPUT.PLACEHOLDER' | translate"
                                                    [disabled]="disabled"
                                                    rows="1"
                                          ></textarea>

                                          <div class="chat-input-field__line__send"
                                               [class.chat-input-field__line__send--not-disabled]="textExist"
                                               (click)="send()"
                                          >
                                              <icon icon="send"
                                                    class="chat-input-field__line__icon chat-input-field__line__icon--send"
                                              ></icon>
                                          </div>
                                      </div>
                                  </card>
                                  <div class="chat-input-field__info">
                                      <div class="chat-input-field__info__length" [class.limit-reached]="textLength >= textLimit" [class.flash-text]="textLengthWarn && flashing">
                                      <span *ngIf="textLengthWarn">
                                          {{ textLength }}/{{ textLimit }}
                                      </span>
                                      </div>
                                      <div class="chat-input-field__info__enter">
                                          <checkbox [(ngModel)]="sendWithEnter">{{'CHAT.INPUT.SEND_ENTER'|translate}}</checkbox>
                                      </div>
                                  </div>
                                  <dialog-box #limitDialog
                                              [title]="'CHAT.INPUT.LIMIT.DIALOG.TITLE'|translate"
                                              [acceptText]="'CHAT.INPUT.LIMIT.DIALOG.ACTION'|translate"
                                  >
                                      <span class="markdown p-nomargin" [innerHTML]="'CHAT.INPUT.LIMIT.DIALOG.TEXT'|translate|markdown"></span>
                                  </dialog-box>
                              </ng-container>
                          `,
               styles: [require('./chat-input-field.scss')],
           })

export class ChatInputField implements OnInit, OnDestroy {
    @ViewChild('limitDialog', { static: true }) private limitDialog: DialogBox;

    @Input() conversationId: string;

    @Input() disabled: boolean = false;
    private _text: string = '';
    get text(): string {
        return this._text;
    }

    set text(value: string) {
        this._text = replaceTextSmileysByEmoji(value);

        if (this._text.length > 0) this.messageService.updateUnsentMessage(this.conversationId, this._text);
        else this.messageService.clearUnsentMessage(this.conversationId);
    }

    get textLength(): number {
        return this._text.length;
    }

    get textLengthWarn(): boolean {
        return this.textLength * 2 >= this.textLimit;
    }

    private _flashing = false;
    private _flashingTimeout = false;

    get flashing(): boolean {
        return this._flashing;
    }

    set flashing(v: boolean) {
        this._flashing = v;
        if (v) {
            if (this._flashingTimeout) return;
            this._flashingTimeout = true;
            setTimeout(() => {
                this._flashingTimeout = false;
                this._flashing = false;
            }, 300);
        }
    }

    @ViewChild('input', { static: true }) private input: ElementRef;
    @ViewChild(TextareaAutosize, { static: true }) private autoresize: TextareaAutosize;

    private get inputElement() {
        return this.input.nativeElement;
    }

    @Output() private change = new EventEmitter();
    @Output('send') private _send = new EventEmitter();

    readonly textLimit = 10000;
    emojisOpen: boolean = false;
    private selection = {
        start: 0,
        end:   0,
    };

    sendWithEnterSubscription = new Subscription();
    private _sendWithEnter = false;
    get sendWithEnter(): boolean {
        return this._sendWithEnter;
    }

    set sendWithEnter(v: boolean) {
        this._sendWithEnter = v;
        this.messageService.sendMessageWithEnter = v;
    }

    toggleEmojis(): void {
        this.setSelection();
        this.emojisOpen = !this.emojisOpen;
    }

    selectEmoji(icon) {
        this.text = `${this.text.substring(0, this.selection.start)}${icon}${this.text.substring(this.selection.end, this.text.length)}`;

        if (this.selection.start === this.selection.end) {
            this.selection.start = this.selection.start + icon.length;
            this.selection.end = this.selection.end + icon.length;
        } else {
            this.selection.start = this.selection.start + icon.length;
            this.selection.end = this.selection.start;
        }

    }

    showMessageLimitInfo() {
        this.limitDialog.open();
    }

    get textExist(): boolean {
        return this.text.trim().utf8Length() > 0 && this.text.trim().utf8Length() <= this.textLimit;
    }

    private textChange($event) {
        this.text = $event;
        this.change.emit();
    }

    public ngOnInit() {
        this._text = this.messageService.getUnsentMessage(this.conversationId);
        this.sendWithEnterSubscription = this.messageService.sendMessageWithEnter$
                                             .subscribe((value: boolean) => this._sendWithEnter = value);
        this.inputElement.addEventListener('click', this.setSelection);
        this.inputElement.addEventListener('keydown', this.setSelection);
        this.inputElement.addEventListener('focus', this.setSelection);
    }

    public ngOnDestroy(): void {
        this.sendWithEnterSubscription.unsubscribe();
        this.inputElement.removeEventListener('click', this.setSelection);
        this.inputElement.removeEventListener('keydown', this.setSelection);
        this.inputElement.removeEventListener('focus', this.setSelection);
    }

    private setSelection = () => {
        setTimeout(() => {
            this.selection.start = this.inputElement.selectionStart;
            this.selection.end = this.inputElement.selectionEnd;
        });
    };

    @HostListener('document:keyup', ['$event'])
    @HostListener('document:keydown', ['$event'])
    private keyEvent(event: KeyboardEvent) {
        if (event.key === 'Enter' && (this.sendWithEnter || event.ctrlKey) && !event.shiftKey) {
            this.send();
            event.preventDefault();
            return;
        }

        if (this.text.length >= this.textLimit && this.useTextKey(event)) {
            this.flashing = true;
            this.showMessageLimitInfo();
            event.preventDefault();
        }
    }

    private useTextKey(event: KeyboardEvent): boolean {
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowLeft':
            case 'ArrowRight':
            case 'ArrowUp':
            case 'End':
            case 'Home':
            case 'PageDown':
            case 'PageUp':
            case 'Backspace':
            case 'Clear':
            case 'Copy':
            case 'CrSel':
            case 'Cut':
            case 'Delete':
            case 'EraseEof':
            case 'ExSel':
            case 'Insert':
            case 'Redo':
            case 'Undo':
            case 'Alt':
            case 'AltGraph':
            case 'CapsLock':
            case 'Control':
            case 'Fn':
            case 'FnLock':
            case 'Hyper':
            case 'Meta':
            case 'NumLock':
            case 'ScrollLock':
            case 'Shift':
            case 'Super':
            case 'Symbol':
            case 'SymbolLock':
                return false;
            default:
                return true;
        }
    }

    send() {
        if (this.textExist) {
            this._send.emit(this.text);
            this.emojisOpen = false;
            this.text = '';
            setTimeout(() => this.autoresize.resize());
        }
    }

    constructor(
        public storeService: StoreService,
        public messageService: MessageService
    ) {
    }
}
