import { Injectable } from '@angular/core';
import {
    ReplaySubject,
} from 'rxjs';

export type ServiceWorkerMessage<T> = {
    name: string;
    payload: T;
}

export class NoServiceWorkerException {}

@Injectable({ providedIn: 'root' })
export class ServiceWorkerConnectorService {

    private messageSubject = new ReplaySubject<ServiceWorkerMessage<any>>(1);
    message$ = this.messageSubject.asObservable();

    private availableSubject = new ReplaySubject<boolean>(1);
    available$ = this.availableSubject.asObservable();

    private _registration: ServiceWorkerRegistration = null;
    get registration(): ServiceWorkerRegistration|null {
        return this._registration;
    }
    private set registration(v: ServiceWorkerRegistration) {
        this._registration = v;
    }

    private registrationPromise = null;
    ready(): Promise<ServiceWorkerRegistration> {
        if (!this.registrationPromise) {
            if (!navigator.serviceWorker) {
                throw new NoServiceWorkerException();
            }

            this.registrationPromise = navigator.serviceWorker.ready.then(registration => {
                    this.registration = registration;
                    return this.registration;
                });
        }

        return this.registrationPromise;
    }

    public constructor() {
        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                if (!event.data || !event.data.payload) return;
                if (event.data.name === "error") console.log("service worker posted error", event.data.payload);
                this.messageSubject.next(event.data);
            });
            this.availableSubject.next(true);
        } else {
            this.availableSubject.next(false);
        }
    }

    async post(name: string, payload: any) {
        const serviceWorker = this.registration.active;
        if (!serviceWorker) throw new NoServiceWorkerException();

        serviceWorker.postMessage({ name, payload });
    }
}
