import { Component } from '@angular/core';

@Component({
               selector:  'matching-header',
               template:  `
                              <intern-default-header [title]="'LAYOUT.SIDE_NAV.MATCHING' | translate" [closeable]="true">
                                  <matching-tabbar class="is-hidden-desktop"></matching-tabbar>
                              </intern-default-header>
                          `,
           })

export class MatchingHeader {}
