import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Subscription,
} from 'rxjs';
import { BkModalReturnToLegacy } from '../shared/mat-design/bk-modals/bk-modal-return-to-legacy';
import { CommunicationService } from './communication/communication.service';
import { GlobalModalService } from './global-modal.service';
import { StoreService } from './storage/store.service';
import { CurrentUserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class StayOnBetaService {
    private currentUserSubscription = new Subscription();
    private stayOnBetaSubject = new BehaviorSubject(true)

    public stayOnBeta$ = this.stayOnBetaSubject.asObservable();

    public set stayOnBeta(state: boolean) {

        if (!this.currentUserService.isCurrentUserInitialized) return; // prevents setting stayOnBeta value when currentUser was not loaded yet

        if (this.stayOnBetaSubject.value !== state) {

            const previousValue = this.stayOnBeta;
            this.currentUserService.stayOnBeta(state)
                .catch(_ => {
                    this.communicationService.growl.stayOnBeta.handleError();
                });

            this.stayOnBetaSubject.next(state);
        }
    }

    public get stayOnBeta():boolean {
        return this.stayOnBetaSubject.value;
    }

    public constructor(
        private currentUserService: CurrentUserService,
        private globalModalService: GlobalModalService,
        private storeService: StoreService,
        private communicationService: CommunicationService
    ) {
        this.currentUserSubscription = currentUserService
            .currentUserObservable
            .subscribe(value => this.stayOnBeta = value.stayOnBeta);
    }

    public returnToLegacy() {
        this.globalModalService.open(BkModalReturnToLegacy.typeId);
    }

    forceStay() {
        this.currentUserService.currentUser.then(() => {
            this.stayOnBeta = true;
        });
    }
}
