import {
    Component,
    Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
               selector:  'intern-header-title',
               template:  `
                              <div class="intern-header-title is-hidden-desktop">
                                  <div class="intern-header-title__text">
                                      <ng-content></ng-content>
                                  </div>

                                  <ng-container *ngIf="closeable">
                                      <icon class="intern-header-title__icon" [icon]="arrowIcon"></icon>
                                  </ng-container>
                                  
                              
                              </div>
                          `,
               styles: [require('./intern-header-title.scss')],
           })

export class InternHeaderTitle {
    @Input() private closeable: boolean = false;
    @Input() private showContent: boolean = false;

    private get arrowIcon() {
        return this.showContent ? 'arrow-drop-up' : 'arrow-drop-down';
    }


}

