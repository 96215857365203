import {
    Component,
    Input,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'on-boarding',
               template:  `
                              <card-title>
                                  <div class="on-boarding__title">{{'ON_BOARDING.TABS.TITLE' | translate}}</div>
                                  <div class="on-boarding__content">
                                      <on-boarding-item *ngIf="!currentUser.profileImgExist()"
                                                        class="on-boarding__content__item"
                                                        icon="photo-upload"
                                                        [title]="'ON_BOARDING.TABS.IMG.TITLE' | translate"
                                                        [text]="'ON_BOARDING.TABS.IMG.TEXT' | translate"
                                                        [buttonText]="'ON_BOARDING.TABS.IMG.BUTTON' | translate"
                                                        (onButton)="navigateToImageUpload()"
                                      ></on-boarding-item>
                                      <on-boarding-item *ngIf="!currentUser.filledMandatoryDetails"
                                                        class="on-boarding__content__item"
                                                        icon="edit"
                                                        [title]="'ON_BOARDING.TABS.PROFILE_DATA.TITLE' | translate"
                                                        [text]="'ON_BOARDING.TABS.PROFILE_DATA.TEXT' | translate"
                                                        [buttonText]="'ON_BOARDING.TABS.PROFILE_DATA.BUTTON' | translate"
                                                        (onButton)="navigateToProfileInfo()"
                                      ></on-boarding-item>
                                      <on-boarding-item
                                              *ngIf="!currentUser.emailValidated"
                                              class="on-boarding__content__item"
                                              icon="mail-outline"
                                              [title]="'ON_BOARDING.TABS.EMAIL.TITLE' | translate"
                                              [text]="'ON_BOARDING.TABS.EMAIL.TEXT' | translate"
                                              [buttonText]="'ON_BOARDING.TABS.EMAIL.BUTTON' | translate"
                                              (onButton)="navigateToEmail()"
                                      ></on-boarding-item>

                                  </div>
                              </card-title>
                          `,
               styles: [require('./on-boarding.scss')],
           })

export class OnBoarding {
    @Input() private currentUser: CurrentUser;

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private trackEvent(label: string) {
        this.trackingService.hit('Startseite', 'Onboarding', label);
    }

    private navigateToImageUpload() {
        this.trackEvent('BildHochGeladen');

        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Images,
        });
    }

    private navigateToProfileInfo() {
        this.trackEvent('ProfilAusfuellenBegonnen');
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Look,
        });
    }

    private navigateToEmail() {
        this.trackEvent('EMailBestaetigenBegonnen');
        this.navigationService.navigateTo(Routes.RegCode);
    }
}
