import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'profile-placeholder-item',
               template: `

        <div class="profile-placeholder-item">
            <div class="profile-placeholder-item__title">{{title}}</div>
            <div class="profile-placeholder-item__text">{{text}}</div>
            <div class="profile-placeholder-item__content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
               styles: [require('./placeholder-item.scss')],
           })

export class ProfilePlaceholderItem {
    @Input() private text: string = '';

    @Input() private title: string = '';
}
