import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Button } from './button';

@Component({
               selector:  'paypal-button',
               template:  `
                              <button #button class="mdc-button mdc-button--raised paypal-button" (click)="toPaypal()">
                                  <div class="mdc-button__ripple"></div>
                                  <span class="mdc-button__label paypal-button__label">
                                      {{'PAYPAL-BUTTON.TITLE' | translate}}
                                      <img src="./assets/img/payment/badgePaypal.svg" class="paypal-button__img">
                                  </span>
                              </button>
                          `,
               styles: [require('./paypal-button.scss')],
           })

export class PaypalButton extends Button  {
    @Input() private link: string;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    private toPaypal() {
        window.open(this.link, '_blank');
    }
}
