import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector: 'premium-booking-page-header',
               template: `
                             <div class="premium-booking-page-header" [routerLink]="mainpage">
                                 <div class="premium-booking-page-header__logo"></div>
                             </div>`,
               styles: [require('./premium-booking-page-header.scss')],
           })

export class PremiumBookingPageHeader {
    private get mainpage() {
        return this.navigationService.getRoute(Routes.Startpage);
    }

    public constructor(private navigationService: NavigationService) {
    }
}
