import {
    AfterViewInit,
    Component,
    ViewChild,
} from '@angular/core';
import {
    CommunicationService,
    CurrentUserService,
} from 'BKService';
import { RadioService } from '../../../module/intern/schlagerparadies/radio.service';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';
import { HintBoxType } from '../hint-box/hint-box';
import { Modal } from '../modal/modal';
import { TextField } from '../text-field/text-field';
import { BkModal } from './bk-modal';
import { BkModalSchlagerherzenError } from './bk-modal-schlagerherzen-error';
import { BkModalSchlagerherzenSuccess } from './bk-modal-schlagerherzen-success';

@Component({
            selector: 'bk-modal-schlagerherzen-application',
            template: `
                      <ng-container>
                          <modal #modal
                                 no-border
                                 [closeIcon]="true"
                                 [backgroundClose]="true">
                              <div class="content">
                                  <div class="title">{{ 'SCHLAGERHERZEN.APPLICATION.TITLE'|translate }}</div>
                                  <div class="info-text">{{ 'SCHLAGERHERZEN.APPLICATION.INFO'|translate }}</div>
                                  <hint-box [type]="HintBoxType.Tip">
                                      <ng-container role="content">{{ 'SCHLAGERHERZEN.APPLICATION.HINT'|translate }}</ng-container>
                                  </hint-box>
                                  <text-field 
                                          [(value)]="firstName"
                                          [placeholder]="'SCHLAGERHERZEN.APPLICATION.FIRST_NAME'|translate"></text-field>
                                  <text-field
                                          [(value)]="phoneNumber"
                                          [placeholder]="'SCHLAGERHERZEN.APPLICATION.TELEPHONE'|translate"></text-field>
                                  <text-field #emailAddressInput
                                          [placeholder]="'SCHLAGERHERZEN.APPLICATION.EMAIL'|translate"
                                          [disabled]="true"
                                          [value]="(currentUserService.currentUserProfileInfo|async).email"
                                  ></text-field>
                                  <a class="change-email" (click)="changeEmail()">{{ 'SCHLAGERHERZEN.APPLICATION.CHANGE_EMAIL'|translate }}</a>
                                  <text-area 
                                          [(value)]="personalMessage"
                                          [placeholder]="'SCHLAGERHERZEN.APPLICATION.PERSONAL_MESSAGE'|translate"
                                          rows="4"
                                  ></text-area>
                                  <div class="action-bar">
                                      <raised-button 
                                              schlagerparadies 
                                              [disabled]="!validInput || transmitting" (click)="sendApplication()">
                                          <loading-indicator *ngIf="transmitting"></loading-indicator>
                                          {{ 'SCHLAGERHERZEN.APPLICATION.ACTION'|translate }}
                                      </raised-button>
                                  </div>
                              </div>
                          </modal>
                      </ng-container>
                      `,
            styles: [require('./bk-modal-schlagerherzen-application.scss')]
           })
export class BkModalSchlagerherzenApplication implements ModalDialog {
    @ViewChild('modal', { static: true }) private modal: Modal;
    @ViewChild('emailAddressInput', { static: true }) private emailAddressInput: TextField;

    private firstName = '';

    private phoneNumber = '';

    private personalMessage = '';

    private transmitting = false;

    private get validInput(): boolean {
        return !this.firstName.isEmpty() && !this.phoneNumber.isEmpty();
    }

    static readonly typeId = 'BkModalSchlagerherzenApplication';

    private HintBoxType = HintBoxType;

    constructor(
        private navigationService: NavigationService,
        private currentUserService: CurrentUserService,
        private globalModalService: GlobalModalService,
        private radioService: RadioService,
        private communicationService: CommunicationService) {}

    public changeEmail() {
        this.close();
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.MainData });
    }

    public open(config) {
        if (!this.modal.isOpen) {
            this.firstName = '';
            this.phoneNumber = '';
            this.personalMessage = '';
            this.modal.open();
            // Force recalculation of layout to fix error with placeholder text not rendering in the notch
            this.emailAddressInput.layout();
        }
    }

    public close() {
        this.modal.close();
    }

    private sendApplication() {
        this.transmitting = true;
        this.radioService.apply(this.firstName, this.phoneNumber, this.personalMessage)
            .then(success => {
                if (success) {
                    this.close();
                    this.globalModalService.open(BkModalSchlagerherzenSuccess.typeId);
                } else {
                    this.showError();
                }
            })
            .catch(_ => {
                this.showError();
            })
            .finally(() => {
                this.transmitting = false;
            });
    }

    private showError() {
        this.close();
        this.globalModalService.open(BkModalSchlagerherzenError.typeId);
    }

    public get typeId(): string {
        return BkModalSchlagerherzenApplication.typeId;
    }
}