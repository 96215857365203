import {
    Component,
    Input,
} from '@angular/core';
import { FormatAmount } from 'BKUtils';

@Component({
               selector:  'premium-booking-page-payment-price',
               template:  `
                              <div class="premium-booking-page-payment-price">
                                  <div class="premium-booking-page-payment-price__title">{{'PREMIUM.BOOKING.PAYMENT.CURRENT_BILL_PRICE' | translate}}</div>
                                  <div class="premium-booking-page-payment-price__price">{{price}}</div>
                              </div>

                          `,
               styles: [require('./premium-booking-page-payment-price.scss')],
           })

export class PremiumBookingPagePaymentPrice {
    @Input() private price: number;
}
