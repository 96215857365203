import { Component } from '@angular/core';

@Component({
               selector:  'contacts-header',
               template:  ` 
                              <intern-default-header [title]="'LAYOUT.SIDE_NAV.CONTACTS' | translate" [closeable]="true">
                                  <contacts-tabbar class="is-hidden-desktop"></contacts-tabbar>
                              </intern-default-header>
                          `,
           })

export class ContactsHeader {
}
