import {
    Component,
    OnInit,
} from '@angular/core';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';

@Component({
               selector:  'settings-debit-again-header',
               template:  `
                              <intern-default-header [showMenuContent]="true">
                                  <settings-debit-again-header-bar menu></settings-debit-again-header-bar> {{'GLOBAL.BACK' | translate}}
                              </intern-default-header>
                          `,

           })

export class SettingsDebitAgainHeader {
}
