import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
    Settings,
} from 'BKModels';
import {
    CurrentUserService,
    SettingsService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureExplainDialog } from '../../../../shared/mat-design/dialog/feature-explain-dialog';

@Component({
               selector:  'settings-privacy',
               template:  `
                              <card-title>
                                  <settings-switch bold
                                                   [title]="'SETTINGS.PRIVACY.ONLINE_STATE.TITLE' | translate"
                                                   [description]="'SETTINGS.PRIVACY.ONLINE_STATE.DESCRIPTION' | translate"
                                                   icon="visibility-off"
                                                   [(value)]="offline"
                                                   [orderReason]="OrderReason.HideOnlineState"
                                                   class="settings-privacy__switch"
                                                   [premiumFunction]="true"
                                                   [userIsPremium]="currentUser.isPremium()"
                                  ></settings-switch>

                                  <settings-switch bold
                                                   [title]="'SETTINGS.PRIVACY.INCOGNITO.TITLE' | translate"
                                                   [description]="'SETTINGS.PRIVACY.INCOGNITO.DESCRIPTION' | translate"
                                                   [(value)]="incognitoMode"
                                                   [premiumFunction]="true"
                                                   [userIsPremium]="currentUser.isPremium()"
                                                   [orderReason]="OrderReason.IncognitoMode"
                                                   icon="invisible"
                                                   class="settings-privacy__switch"
                                  ></settings-switch>


                                  <settings-switch bold
                                                   [title]="'SETTINGS.PRIVACY.PUBLIC_PROFILE.TITLE' | translate"
                                                   [description]="'SETTINGS.PRIVACY.PUBLIC_PROFILE.DESCRIPTION' | translate"
                                                   icon="language"
                                                   [(value)]="publicProfile"
                                  ></settings-switch>
                              </card-title>

                              <feature-explain-dialog #offlineModal
                                                      [title]="'SETTINGS.PRIVACY.ONLINE_STATE.MODAL.TITLE' | translate"
                                                      [text]="'SETTINGS.PRIVACY.ONLINE_STATE.MODAL.DESCRIPTION' | translate | markdown"
                                                      [img]="'./assets/img/settings/settings-explain-offline-mode.svg'"
                              ></feature-explain-dialog>

                              <feature-explain-dialog #incognitoModal
                                                      incognito
                                                      [title]="'SETTINGS.PRIVACY.INCOGNITO.MODAL.TITLE' | translate"
                                                      [text]="'SETTINGS.PRIVACY.INCOGNITO.MODAL.DESCRIPTION' | translate | markdown"
                                                      [img]="'./assets/img/settings/settings-explain-incognito-mode.svg'"
                              ></feature-explain-dialog>


                          `,
               styles: [require('./settings-privacy.scss')],
           })

export class SettingsPrivacy implements OnInit, OnDestroy {
    private curSettings: Settings;
    private OrderReason = OrderReason;
    private currentUser: CurrentUser = new CurrentUser();
    private unsubscribe$ = new Subject();
    @ViewChild('offlineModal', { static: true }) private offlineExplain: FeatureExplainDialog;
    @ViewChild('incognitoModal', { static: true }) private incognitoExplain: FeatureExplainDialog;

    public constructor(
        private settingsService: SettingsService,
        private currentUserService: CurrentUserService,
    ) {
    }

    public ngOnInit(): void {
        this.settingsService
            .settings
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((curSettings) => {
                this.curSettings = Settings.create(curSettings);
            });

        this.currentUserService
            .currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(currentUser => this.currentUser = currentUser);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private get offline(): boolean {
        return this.curSettings.offline;
    }

    private set offline(value: boolean) {
        this.curSettings.offline = value;
        if (value) this.offlineExplain.open();
        this.settingsService.changeSettings(this.curSettings);
        this.currentUserService.loadCurrentUser();
    }

    private get incognitoMode(): boolean {
        return this.curSettings.anonymous;
    }

    private set incognitoMode(value: boolean) {
        this.curSettings.anonymous = value;
        if (value) this.incognitoExplain.open();
        this.settingsService.changeSettings(this.curSettings);
    }

    private get publicProfile(): boolean {
        return this.curSettings.publicProfile;
    }

    private set publicProfile(value: boolean) {
        this.curSettings.publicProfile = value;
        this.settingsService.sendToApi(this.curSettings);
    }

}
