import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Image } from 'BKModels';

@Component({
               selector: 'matching-image-indicator',
               template: `
                             <div class="matching-image-indicator">
                                 <div *ngFor="let it of indicatorArray; let index = index" 
                                      class="matching-image-indicator__bar"
                                      [class.matching-image-indicator__bar--active]="currentIndex == index"
                                      (click)="onClick($event, index)">
                                     <div class="matching-image-indicator__bar__inner"></div>
                                     
                                 </div>
                             </div>`,
               styles: [require('./matching-image-indicator.scss')],
           })

export class MatchingImageIndicator {
    @Input() private length: number = 0;

    private _currentIndex = 0;

    @Input("index") private set currentIndex(val: number) {
        this._currentIndex = val;
        this.indexChange.emit(val)
    }

    private get currentIndex(): number {
        return this._currentIndex;
    }
    @Output() private indexChange = new EventEmitter();


    private get indicatorArray() {
        return Array.from({length: this.length}, (v,k) => k);
    }


    private onClick(event, index) {
        event.stopPropagation();
        this.currentIndex = index
    }

    public nextImage() {
        this.currentIndex = (this.currentIndex + 1) % this.length;
    }
}
