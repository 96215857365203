import { Component } from '@angular/core';

@Component({
               selector:  'premium-purchase-after-sale-delaybox',
               template:  `
                              <div class="premium-purchase-after-sale-delaybox">
                                  <icon icon="info-outline" class="premium-purchase-after-sale-delaybox__icon"></icon>
                                  {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.BOX.TEXT.1' | translate}}
                                  <icon icon="crown-outline" class="premium-purchase-after-sale-delaybox__premium premium-purchase-after-sale-delaybox__icon--premium"></icon>
                                  {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.BOX.TEXT.2' | translate}}
                              </div>
                          `,
               styles: [require('./premium-purchase-after-sale-delaybox.scss')],
           })

export class PremiumPurchaseAfterSaleDelaybox {
    public constructor() {
    }
}
