import { Component } from '@angular/core';

@Component({
               selector:  'lovestory-description',
               template:  `
                              <card>
                                  <div class="lovestory-description">
                                      <div class="lovestory-description__title">
                                          {{'LOVESTORY.DESCRIPTION.TITLE' | translate}}
                                      </div>
                                      <div class="lovestory-description__text" [innerHTML]="'LOVESTORY.DESCRIPTION' | translate | markdown"></div>
                                  </div>
                              </card>


                          `,
               styles: [require('./lovestory-description.scss')],
           })

export class LovestoryDescription {
}
