import { Input } from '@angular/core';

/**
 * Baseclass for Enable a TextCollapse
 */
export class TextCollapse {
    /**
     * flag that show if collapse or not
     {boolean}
     */
    protected collapse: boolean = true;

    private truncateLength: number = 100;

    private _value: string = '';

    /**
     * getter for value
     {string}
     */
    protected get value(): string {
        if (this._value.length >= this.truncateLength && this.collapse) {
            return `${this._value.substring(0, this.truncateLength - 7)}`;
        }
        return this._value;
    }

    /**
     * setter for value
     {string} val
     */
    @Input()
    protected set value(val: string) {
        this._value = val;
        if (val.length < this.truncateLength) {
            this.collapse = false;
        }
    }

    /**
     * constructor
     {number} _truncateLength
     */
    public constructor(_truncateLength: number) {
        this.truncateLength = _truncateLength;
    }

    /**
     * Open the Collapse Text
     */
    protected toggleExpand() {
        this.collapse = !this.collapse;
    }
}
