import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    BillingAddress,
    SepaAccount,
} from 'BKModels';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { PaymentService } from '../../../../service/payment.service';
import { PaymentDebit } from '../../../../shared/mat-design/payment-debit/payment-debit';

@Component({
               selector:  'settings-change-sepa',
               template:  `
                              <subheader class="is-hidden-tablet">
                                  <settings-change-sepa-header-bar></settings-change-sepa-header-bar>
                              </subheader>
                              
                              <settings-sepa-data
                                      [billingAddress]="billingAddress"
                                      [sepaAccount]="sepaAccount"
                                      [title]="'SETTINGS.CHANGE-PAYMENT-DATA.TITLE' | translate"
                                      [text]="'SETTINGS.CHANGE-PAYMENT-DATA.TEXT' | translate"
                                      [saveTitle]="'GLOBAL.SAVE' | translate"
                                      (save)="save($event)"
                                      [cancelTitle]="'GLOBAL.CANCEL' | translate"
                                      (cancel)="cancel()"
                                      [inProgress]="inProgress"
                              ></settings-sepa-data>
                          `,
               styles: [require('./settings-change-sepa.scss')],
           })

export class SettingsChangeSepa implements OnInit {
    billingAddress: BillingAddress = new BillingAddress();
    sepaAccount: SepaAccount = new SepaAccount();
    inProgress: boolean = false;


    public constructor(private paymentService: PaymentService, private navigationService: NavigationService) {
    }

    public ngOnInit(): void {
        this.paymentService.billingAddress().then(billingAddress => this.billingAddress = billingAddress);
        this.paymentService.sepaAccount().then(sepaAccount => this.sepaAccount = sepaAccount);
    }

    cancel() {
        this.navigationService.navigateTo(Routes.Settings);
    }

    save({billingAddress, sepaAccount}) {
        this.inProgress = true;
        this.paymentService
            .changePaymentData(billingAddress, sepaAccount)
            .then(() => this.navigationService.navigateTo(Routes.Settings))
            .finally(() => this.inProgress = false);
    }
}
