import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';
import { ReportService } from '../../../service/report.service';
import { DialogBox } from '../dialog/dialog-box';
import { TextArea } from '../text-field/text-area';

enum ReportSteps {
    Input,
    Sending,
    Error,
    Done
}

@Component({
               selector: 'bk-dialog-report-violation',
               template: `
                             <dialog-box #reportDialog
                                         [title]="'REPORT_VIOLATION.TITLE'|translate"
                             >
                                 <ng-container *ngIf="reportStep === ReportSteps.Input || reportStep === ReportSteps.Sending || reportStep === ReportSteps.Error">
                                     <div class="report-violation__description markdown first-last-nomargin" [innerHTML]="'REPORT_VIOLATION.DESCRIPTION'|translate|markdown"></div>
                                     <text-area #reportViolationReason
                                                class="report-violation__input"
                                                maxLength="255"
                                                rows="4"
                                                [placeholder]="'REPORT_VIOLATION.PLACEHOLDER'|translate">
                                     </text-area>
                                     <div *ngIf="reportStep === ReportSteps.Sending || reportStep === ReportSteps.Error"
                                          class="report-violation__info"
                                          [class.info-error]="reportStep === ReportSteps.Error">
                                         <ng-container *ngIf="reportStep === ReportSteps.Sending">
                                             {{ 'REPORT_VIOLATION.SENDING'|translate }}
                                         </ng-container>
                                         <ng-container *ngIf="reportStep === ReportSteps.Error">
                                             {{ 'REPORT_VIOLATION.ERROR'|translate }}
                                         </ng-container>
                                     </div>
                                     <div class="mdc-dialog__actions report-violation__actions">
                                         <flat-button (click)="reportDialog.close()" tabindex="0">
                                             {{ 'REPORT_VIOLATION.ACTION.CANCEL'|translate }}
                                         </flat-button>
                                         <raised-button (click)="sendReport(reportViolationReason.value.toString())"
                                                        [disabled]="reportReasonEmpty || reportStep === ReportSteps.Sending"
                                                        tabindex="0" >
                                             {{ 'REPORT_VIOLATION.ACTION.REPORT'|translate }}
                                         </raised-button>
                                     </div>
                                     <div class="report-violation__hint">
                                         <icon class="report-violation__hint__icon" icon="info-outline"></icon>
                                         <div class="report-violation__hint__text">
                                             {{ 'REPORT_VIOLATION.HINT'|translate }}
                                         </div>
                                     </div>
                                 </ng-container>
                                 <ng-container *ngIf="reportStep === ReportSteps.Done">
                                     <div class="markdown first-last-nomargin"
                                          [innerHTML]="'REPORT_VIOLATION.SUCCESS'|translate|markdown"></div>
                                     <div class="mdc-dialog__actions report-violation__actions">
                                         <flat-button (click)="reportDialog.close()" tabindex="0">
                                             {{ 'REPORT_VIOLATION.SUCCESS.ACTION.CLOSE'|translate }}
                                         </flat-button>
                                     </div>
                                 </ng-container>
                             </dialog-box>
              `,
               styles: [require('./bk-dialog-report-violation.scss')]
           })
export class BkDialogReportViolation {
    @Input() private user: ProfileInfo;

    @ViewChild('reportDialog', { static: false }) private reportDialog: DialogBox;
    @ViewChild('reportViolationReason', { static: false }) private reportViolationReason: TextArea;

    private ReportSteps = ReportSteps;
    private reportStep = ReportSteps.Input;

    private get reportReasonEmpty(): boolean {
        if (!this.reportViolationReason?.value) return true;
        return this.reportViolationReason.value.toString().isEmpty();
    }

    constructor(
        private reportService: ReportService) {
    }

    private sendReport(reason: string) {
        this.reportStep = ReportSteps.Sending;
        this.reportService.reportViolation(this.user, reason).then(value => {
            // Ignore state as the reported user had been deleted in case it is false
            this.reportStep = ReportSteps.Done;
        }).catch(error => {
            this.reportStep = ReportSteps.Error;
        });
    }

    public open() {
        this.reportStep = ReportSteps.Input;
        this.reportDialog.open();
    }
}