import { User } from '@sentry/browser';
import * as cloneDeep from 'lodash.clonedeep';
import { Geolocation } from './geolocation';

import {
    BodyType,
    Children,
    ChildrenWish,
    ContactWish,
    CurrentUser,
    EyeColor,
    Gender,
    getEnumKeys,
    HairColor,
    Language,
    Relationship,
    SexOrientation,
    Smoker,
    ZodiacSign,
} from './user';

export interface UserSearch {
    ageFrom?: number;
    ageTo?: number;
    children?: Children[];
    childrenWish?: ChildrenWish[];
    contactWish?: ContactWish[];
    country?: string
    distance?: number;
    echeck?: number;
    eyecolor?: EyeColor[];
    gender?: Gender;
    geoId?: number
    haircolor?: HairColor[];
    languages?: any;
    latitude?: number;
    longitude?: number;
    n1code?: any;
    n2code?: any;
    nickname?: string;
    numOfChildren?: number;
    online?: boolean;
    postalCode?: number;
    relationShip?: Relationship[];
    searchGender?: Gender;
    sexOrientation?: SexOrientation;
    sign?: ZodiacSign[];
    sizeFrom?: number;
    sizeTo?: number;
    smoker?: Smoker;
    webcam?: boolean;
    weightFrom?: number;
    weightTo?: number;
    figure?: BodyType[];
}

/**
 * criteria for the quick search
 */
export class QuickSearchCriteria {
    /**
     * Age range in that the user search
     {[number, number]}
     */
    public ageRange: number[];

    /**
     * Search Country
     {string}
     */
    public country: string;

    /**
     * Distance form the Search City in that the user search
     {number}
     */
    public distance: number;

    /**
     * Gender that the user Search
     {Gender}
     */
    public gender: Gender;

    public geolocation: Geolocation;

    /**
     * constructor
     {CurrentUser} user
     {number} distance
     */
    public constructor(user: CurrentUser = new CurrentUser(), distance: number = 75) {
        this.gender = user.searchGender;
        this.ageRange = [user.ageFrom, user.ageTill];
        this.country = user.country;
        this.geolocation = user.createGeolocation();
        this.distance = distance;
    }

    public static create(data: QuickSearchCriteria): QuickSearchCriteria {
        const res = new QuickSearchCriteria();
        if (data !== null && data !== undefined) {
            res.gender = data.gender || Gender.None;
            res.ageRange = cloneDeep(data.ageRange);
            res.country = data.country;
            res.geolocation = cloneDeep(data.geolocation);
            res.distance = data.distance;
        }
        return res;
    }

    public copy(): QuickSearchCriteria {
        const res = new QuickSearchCriteria();
        res.ageRange = [this.ageRange[0], this.ageRange[1]];
        res.gender = this.gender;
        res.country = this.country;
        res.geolocation = Object.assign({}, this.geolocation);
        res.distance = this.distance;
        return res;
    }
}

export type ExtendedSearchEnumValueObject = {
    [key: string]: {
        enumValue: string | number,
        value: boolean,
    }
}

export enum ExtendedSearchCriteriaProperty {
    Children = 'children',
    ChildrenWish = 'childrenWish',
    ContactWish = 'contactWish',
    Echeck = 'echeck',
    EyeColor = 'eyeColor',
    BodyType = 'figure',
    FilledFields = 'filledFields',
    HairColor = 'hairColor',
    Languages = 'languages',
    ZodiacSign = 'zodiacSign',
    Online = 'online',
    Size = 'size',
    Smoker = 'smoker',
    Relationship = 'relationship',
    FullTextSearch = 'fullTextSearch'
}

// TODO switch case to mapping objects
export class ExtendedSearchCriteria {
    public children: Children = Children.AskMe;

    public childrenWish: ChildrenWish = ChildrenWish.AskMe;

    public contactWish: ContactWish = ContactWish.AskMe;

    public echeck: boolean = false;

    public eyeColor = ExtendedSearchCriteria.getEnumArray(EyeColor, [EyeColor.Undefined]);

    public figure = ExtendedSearchCriteria.getEnumArray(BodyType, [BodyType.AskMe]);

    public hairColor = ExtendedSearchCriteria.getEnumArray(HairColor, [HairColor.Undefined]);

    public languages = ExtendedSearchCriteria.getEnumArray(Language);

    public online: boolean = false;

    public size: number[] = [80, 220];

    public smoker: Smoker = Smoker.AskMe;

    public relationship: Relationship = Relationship.AskMe;

    public zodiacSign = ExtendedSearchCriteria.getEnumArray(ZodiacSign);

    public filledFields = {
        personOfDream: {
            enumValue: 'personOfDream',
            value:     false,
        },
        career:        {
            enumValue: 'career',
            value:     false,
        },
        like:          {
            enumValue: 'like',
            value:     false,
        },
        description:   {
            enumValue: 'description',
            value:     false,
        },
        hobbys:        {
            enumValue: 'hobbys',
            value:     false,
        },
    };

    public fullTextSearch: string = null;

    public getUserSearch(): UserSearch {
        let res = {} as UserSearch;
        this.getUsed().forEach(it => {
            switch (it) {
                case ExtendedSearchCriteriaProperty.Children:
                    res['children'] = [this.children];
                    break;
                case ExtendedSearchCriteriaProperty.ChildrenWish:
                    res['childrenWish'] = [this.childrenWish];
                    break;
                case ExtendedSearchCriteriaProperty.ContactWish:
                    res['contactWish'] = [this.contactWish];
                    break;
                case ExtendedSearchCriteriaProperty.Echeck:
                    res['verified'] = true;
                    break;
                case ExtendedSearchCriteriaProperty.FullTextSearch:
                    res['fullTextSearch'] = this.fullTextSearch;
                    break;
                case ExtendedSearchCriteriaProperty.EyeColor:
                    res['eyecolor'] = this.getValueOfTrue(this.eyeColor) as EyeColor[];
                    break;
                case ExtendedSearchCriteriaProperty.BodyType:
                    res['figure'] = this.getValueOfTrue(this.figure) as BodyType[];
                    break;
                case ExtendedSearchCriteriaProperty.FilledFields:
                    if (this.filledFields.personOfDream.value) res['filledFieldPersonOfDream'] = true;
                    if (this.filledFields.career.value) res['filledFieldCareer'] = true;
                    if (this.filledFields.like.value) res['filledFieldLike'] = true;
                    if (this.filledFields.description.value) res['filledFieldDescription'] = true;
                    if (this.filledFields.hobbys.value) res['filledFieldHobbys'] = true;
                    break;
                case ExtendedSearchCriteriaProperty.HairColor:
                    res['haircolor'] = this.getValueOfTrue(this.hairColor) as HairColor[];
                    break;
                case ExtendedSearchCriteriaProperty.Languages:
                    res['languages'] = this.getValueOfTrue(this.languages) as Language[];
                    break;
                case ExtendedSearchCriteriaProperty.Online:
                    res['online'] = true;
                    break;
                case ExtendedSearchCriteriaProperty.Relationship:
                    res['relationship'] = [this.relationship as Relationship];
                    break;
                case ExtendedSearchCriteriaProperty.Size:
                    res['sizeFrom'] = this.size[0];
                    res['sizeTo'] = this.size[1];
                    break;
                case ExtendedSearchCriteriaProperty.Smoker:
                    res['smoker'] = this.smoker;
                    break;
                case ExtendedSearchCriteriaProperty.ZodiacSign:
                    res['sign'] = this.getValueOfTrue(this.zodiacSign) as ZodiacSign[];
                    break;
                default:
                    throw new Error('unknown field');
            }
        });
        return res;
    }

    private getValueOfTrue(val): Array<string | number> {
        return Object.keys(val).reduce((res, key) => {
            const item = val[key];
            if (item.value) res.push(item.enumValue);
            return res;
        }, []);
    }

    private static getEnumArray(enumObject: any, exculded: any[] = []): ExtendedSearchEnumValueObject {
        return getEnumKeys(enumObject).reduce((res, cur) => {
            if (exculded.indexOf(enumObject[cur]) === -1) {
                res[cur] = { enumValue: enumObject[cur], value: false };
            }
            return res;
        }, {});
    }

    public static create(props: ExtendedSearchCriteria = null): ExtendedSearchCriteria {
        const res = new ExtendedSearchCriteria();

        if (props) {
            Object.keys(res).forEach(key => {
                res[key] = cloneDeep(props[key]);
            });
        }
        return res;

    }

    public getUsed(): ExtendedSearchCriteriaProperty[] {
        const res = [];
        Object.keys(this).forEach((it: ExtendedSearchCriteriaProperty) => {
            switch (it) {
                case ExtendedSearchCriteriaProperty.Children:
                    if (this.children && this.children != Children.AskMe) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.ChildrenWish:
                    if (this.childrenWish && this.childrenWish != ChildrenWish.AskMe) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.ContactWish:
                    if (this.contactWish && this.contactWish as ContactWish != ContactWish.AskMe) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Echeck:
                    if (this.echeck) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.FullTextSearch:
                    if (this.fullTextSearch !== null && !this.fullTextSearch.isEmpty()) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.EyeColor:
                    if (Object.values(this.eyeColor).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.BodyType:
                    if (Object.values(this.figure).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.FilledFields:
                    if (Object.values(this.filledFields).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.HairColor:
                    if (Object.values(this.hairColor).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Languages:
                    if (Object.values(this.languages).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.ZodiacSign:
                    if (Object.values(this.zodiacSign).some(it => it.value)) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Online:
                    if (this.online) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Size:
                    if (this.size[0] !== 80 || this.size[1] !== 220) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Smoker:
                    if (this.smoker && this.smoker as Smoker != Smoker.AskMe) res.push(it);
                    break;
                case ExtendedSearchCriteriaProperty.Relationship:
                    if (this.relationship && this.relationship as Relationship != Relationship.AskMe) res.push(it);
                    break;
                default:
                    throw new Error('unknown field');

            }
        });
        return res;
    }

    public reset(key: ExtendedSearchCriteriaProperty) {
        switch (key) {
            case ExtendedSearchCriteriaProperty.Children:
                this.children = Children.AskMe;
                break;
            case ExtendedSearchCriteriaProperty.ChildrenWish:
                this.childrenWish = ChildrenWish.AskMe;
                break;
            case ExtendedSearchCriteriaProperty.ContactWish:
                this.contactWish = ContactWish.AskMe;
                break;
            case ExtendedSearchCriteriaProperty.Echeck:
                this.echeck = false;
                break;
            case ExtendedSearchCriteriaProperty.FullTextSearch:
                this.fullTextSearch = null;
                break;
            case ExtendedSearchCriteriaProperty.EyeColor:
                Object.keys(this.eyeColor).forEach(it => this.eyeColor[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.BodyType:
                Object.keys(this.figure).forEach(it => this.figure[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.FilledFields:
                Object.keys(this.filledFields).forEach(it => this.filledFields[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.HairColor:
                Object.keys(this.hairColor).forEach(it => this.hairColor[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.Languages:
                Object.keys(this.languages).forEach(it => this.languages[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.ZodiacSign:
                Object.keys(this.zodiacSign).forEach(it => this.zodiacSign[it].value = false);
                break;
            case ExtendedSearchCriteriaProperty.Online:
                this.online = false;
                break;
            case ExtendedSearchCriteriaProperty.Size:
                this.size = [80, 220];
                break;
            case ExtendedSearchCriteriaProperty.Smoker:
                this.smoker = Smoker.AskMe;
                break;
            case ExtendedSearchCriteriaProperty.Relationship:
                this.relationship = Relationship.AskMe;
                break;
            default:
                throw new Error('unknown field');
        }

    }

    public get isEmpty(): boolean {
        return this.getUsed().isEmpty();
    }

}

export class EditBoxOpen {
    public age: boolean = false;

    public country: boolean = false;

    public distance: boolean = false;

    public gender: boolean = false;

    public geolocation: boolean = false;

    public closeAll() {
        Object.keys(this)
              .map(key => this[key] = false);
    }

    public isOpen(): boolean {
        return Object.keys(this)
                     .some(key => this[key]);
    }
}
