import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { AccordionTab } from './accordion-tab';


/**
 * Accordion Parent component
 */
@Component({
               selector: 'accordion',
               template: `
                             <div class="accordion">
                                 <ng-content></ng-content>
                             </div>
                         `,
               styles:   [
                   `
                       .accordion {
                           box-sizing: border-box;
                           width: 100%;
                       }
                       `,
               ],
           })
export class AccordionComponent {
    /**
     * Input if a Index is pre-opened
     {number}
     */
    @Input() public activeIndex: number;

    /**
     * if true you cann open multiple tabs
     {boolean}
     */
    @Input() public multiple: boolean = false;

    /**
     * EventEmitter for closing
     {EventEmitter<any>}
     */
    @Output() public onClose: EventEmitter<any> = new EventEmitter();

    /**
     * EventEmitter for open
     {EventEmitter<any>}
     */
    @Output() public onOpen: EventEmitter<any> = new EventEmitter();

    private tabs: AccordionTab[] = [];


    /**
     * added a Tab to the Accordion
     {AccordionTab} tab
     */
    public addTab(tab: AccordionTab) {
        this.tabs.push(tab);
    }

    /**
     * Close all Tabs
     */
    public closeAll() {
        this.tabs = this.tabs
                        .map((cur: AccordionTab) => {
                            cur.setSelect(false);
                            return cur;
                        });
    }

    /**
     * get the index of the given Tab
     {AccordionTab} cur
     {number}
     */
    public findTabIndex(cur: AccordionTab): number {
        let index = -1;
        for (let i = 0; i < this.tabs.length; i++) {
            if (this.tabs[i] === cur) {
                index = i;
                break;
            }
        }
        return index;
    }
}
