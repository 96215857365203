import { AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MDCRipple } from "@material/ripple/component";
import { Disabled } from '../../base-classes/disabled';


export class Button extends Disabled implements AfterViewInit {
    @ViewChild('button', { static: true }) protected button: ElementRef;
    protected ripple: MDCRipple;

    public constructor(elementRef: ElementRef) {
        super(elementRef)
    }

    public ngAfterViewInit() {
        this.ripple = new MDCRipple(this.button.nativeElement);
        setTimeout(() => {this.ripple.layout()},500)
    }
}



