import { Component } from '@angular/core';
import { CurrentUserService } from 'BKService';
import { HeaderService } from '../../../service/header.service';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

enum SpecialDate {
    Default,
    Carnival,
    Easter,
    Halloween,
    Christmas,
    Silvester
}

@Component({
               selector:  'intern-header-left',
               template:  `
                              <div class="intern-header-left">
                                  <div class="intern-header-left__menu"
                                       (click)="toggleSideNav($event)"
                                  >
                                      <icon [icon]="menuIcon(headerService.sideNavOpen | async)" class="intern-header-left__menu__glyph"></icon>
                                      <badge class="intern-header-left__menu__badge" [count]="(currentUserService.badgeCountsObservable | async).all"></badge>
                                  </div>

                                  <img class="intern-header-left__logo__img"
                                       [src]="logo"
                                       [routerLink]="navigationService.getRoute(Routes.Startpage)"
                                       alt="Bildkontakte Logo"
                                  >
                              </div>


                          `,
               styles: [require('./intern-header-left.scss')],
           })

export class InternHeaderLeft {
    Routes = Routes;
    private cachedSpecialDate = [];

    menuIcon(isOpen: boolean) {
        return isOpen ? 'close' : 'menu';
    }

    public constructor(
        public headerService: HeaderService,
        public navigationService: NavigationService,
        public currentUserService: CurrentUserService,
    ) {
    }

    toggleSideNav($event) {
        $event.stopPropagation();
        this.headerService.toggleSideNav();
    }

    get logo(): string {
        switch (this.specialDate) {
            case SpecialDate.Easter:
                return '/assets/img/logos/bk-logo-easter.svg';
            case SpecialDate.Christmas:
                return '/assets/img/logos/bk-logo-xmas.svg';
            case SpecialDate.Halloween:
                return '/assets/img/logos/bk-logo-halloween.svg';
            case SpecialDate.Silvester:
                return '/assets/img/logos/bk-logo-silvester.svg';
            case SpecialDate.Carnival:
                return '/assets/img/logos/bk-logo-carnival.svg';
            default:
                return '/assets/img/logo_bk_opt.svg';
        }
    }

    private get specialDate(): SpecialDate {
        let now = new Date();

        if (!this.cachedSpecialDate[now.toDateString()]) {
            this.cachedSpecialDate[now.toDateString()] = this.calcSpecialDate();
        }

        return this.cachedSpecialDate[now.toDateString()];
    }

    private calcSpecialDate(): SpecialDate {
        let now = new Date();
        let year = now.getFullYear();

        if (now.getMonth() === 11 && now.getDate() >= 28) {
            return SpecialDate.Silvester;
        }

        if (now.getMonth() === 9 && now.getDate() >= 24) {
            return SpecialDate.Halloween;
        }

        let firstAdvent = this.calcFirstAdvent(year);

        if (now.getTime() > firstAdvent.getTime() && now.getTime() < new Date(year + '-12-27').getTime()) {
            return SpecialDate.Christmas;
        }

        let easterSunday = this.calcEaster(year).getTime();

        if (
            now.getTime() > (easterSunday - (86400000 * 7)) &&
            now.getTime() < easterSunday + 86400000) {

            return SpecialDate.Easter;
        }
        if (
            now.getTime() > (easterSunday - (86400000 * 52)) &&
            now.getTime() < easterSunday - (86400000 * 46)) {
            return SpecialDate.Carnival;
        }

        return SpecialDate.Default;
    }

    /**
     * Based on gauss's easter algorithm
     * @param year
     * @private
     */
    private calcEaster(year: number) {
        let a = year % 19;
        let b = year % 4;
        let c = year % 7;

        let p = Math.floor(year / 100);
        let q = Math.floor(13 + (8 * p)) / 25;
        let m = Math.floor(15 - q + p - Math.floor(p / 4)) % 30;
        let n = Math.floor(4 + p - Math.floor(p / 4)) % 7;
        let d = Math.floor(19 * a + m) % 30;
        let e = Math.floor(n + (2 * b) + (4 * c) + (6 * d)) % 7;

        let days = 22 + d + e;

        if (d === 29 && e === 6) {
            return new Date(year + '-04-19');
        } else if (d === 28 && e === 6) {
            return new Date(year + '-04-18');
        } else if (days > 31) {
            return new Date(year + '-04-' + (days - 31));
        }

        return new Date(year + '-03-' + (days));
    }

    private calcFirstAdvent(year: number): Date {
        let christmasEve = new Date(year + '-12-24');
        switch (christmasEve.getDay()) {
            case 0:
                return new Date(year + '-12-03');
            case 1:
                return new Date(year + '-12-02');
            case 2:
                return new Date(year + '-12-01');
            case 3:
                return new Date(year + '-11-30');
            case 4:
                return new Date(year + '-11-29');
            case 5:
                return new Date(year + '-11-28');
            case 6:
                return new Date(year + '-11-27');
        }

    }
}
