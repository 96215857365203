/**
 * Created by olifra on 19.05.2017.
 */
import {
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';
import {
    ApiService,
    CurrentUserService,
    ModalService,
    TrackingService,
} from 'BKService';
import { Subscription } from 'rxjs';
import { LoadingState } from '../../../../service/modal/childs/profile-view.modal';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { ProfileService } from '../../../../service/profile.service';
import { Lightbox } from '../../../../shared/mat-design/lightbox/lightbox';
import { Modal } from '../../../../shared/mat-design/modal/modal';
import { ProfileView } from '../profile-view/profile-view';

@Component({
               selector: 'profile-view-modal',
               template: `
                             <modal (onClose)="onClose()"
                                    (scroll)="profileView && profileView.profileHeader.profileMenu.menu.close()"
                             >
                                 <div *ngIf="loadingState === LoadingState.Uninitialized" class="profile-modal__loading">
                                     <icon-button icon="close" class="profile-modal__loading__close" (click)="modal.close()"></icon-button>
                                     <loading-spinner></loading-spinner>
                                 </div>
                                 <profile-view *ngIf="loadingState === LoadingState.Finished"
                                               [user]="user"
                                               [modal]="true"
                                               (close)="modal.close()"
                                               (openGallery)="lightbox.open($event)"
                                 ></profile-view>
                                 <div *ngIf="loadingState === LoadingState.Error">
                                     <div class="profile-modal__error__title">{{ 'PROFILE_MODAL.USER_NOT_FOUND.TITLE'|translate }}</div>
                                     <markdown>{{ 'PROFILE_MODAL.USER_NOT_FOUND.DESCRIPTION'|translate }}</markdown>
                                     <div class="mdc-dialog__actions dialog-box__actions">
                                         <flat-button (click)="onCloseUserNotFound()">{{ 'PROFILE_MODAL.USER_NOT_FOUND.CANCEL'|translate }}</flat-button>
                                         <raised-button (click)="onClickNavigateToMatching()">{{ 'PROFILE_MODAL.USER_NOT_FOUND.TO_MATCHING'|translate }}</raised-button>
                                     </div>
                                 </div>
                             </modal>


                             <lightbox [user]="user" [currentUser]="currentUserService.currentUserObservable | async"></lightbox>
                         `,
                styles: [require('./profile-modal.scss')]
           })
export class ProfileModal implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild(Lightbox, { static: true }) lightbox: Lightbox;
    LoadingState = LoadingState;
    loadingState: LoadingState = LoadingState.Uninitialized;
    @ViewChild(Modal, { static: true }) modal: Modal;
    @ViewChild(ProfileView, { static: true }) profileView: ProfileView;
    user: ProfileInfo = new ProfileInfo();
    private loadingStateSubscription: Subscription = new Subscription();
    private userSubscription: Subscription = new Subscription();
    private openStateSubscription: Subscription;

    constructor(
        private modalService: ModalService,
        public currentUserService: CurrentUserService,
        private api: ApiService,
        private profileService: ProfileService,
        private trackingService: TrackingService,
        private navigationService: NavigationService
    ) {
    }

    ngAfterViewInit() {
        this.openStateSubscription = this.modalService.profileView.get
            .subscribe((open: boolean) => {
                if (open) this.modal.open();
                else this.modal.close();
            });

        this.modal.escapeKeyAction = '';
    }

    ngOnInit() {
        this.userSubscription = this.modalService
            .profileView
            .selectedUser
            .subscribe((user: ProfileInfo) => {
                if (!user) return;

                this.user = user;
                if (this.user !== null && !this.user.ignore && !this.user.ignoresMe && this.user.id > 0) {
                    this.profileService.trackProfileVisitor(this.user.id);
                }
            });


        this.loadingStateSubscription = this.modalService
                                                 .profileView
                                                 .loadingState
                                                 .subscribe(this.loadingStateSubscriber.bind(this));
    }

    private loadingStateSubscriber(state: LoadingState){
        if (this.loadingState !== state && state === LoadingState.Error) {
            this.trackingService.hit('ProfilAnsehen', 'ProfilExistiertNicht', 'View: HinweisProfilExistiertNicht');
        }
        this.loadingState = state;
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.loadingStateSubscription.unsubscribe();
        this.openStateSubscription.unsubscribe();
    }

    onClose() {
        this.modalService.profileView.close();
    }

    @HostListener('document:keydown.escape', ['$event'])
    private keyPress(event) {
        if (this.modal.isOpen) {
            if (this.lightbox.isOpen) {
                this.lightbox.close();
            } else {
                this.modal.close();
            }
        }
    }

    onCloseUserNotFound(){
        this.trackingService.hit('ProfilAnsehen','ProfilExistiertNicht','Klick: LinkSchliessen');
        this.modal.close();
    }

    onClickNavigateToMatching(){
        this.trackingService.hit('ProfilAnsehen','ProfilExistiertNicht','Klick: BtnZumMatching');
        this.modal.close();
        this.navigationService.navigateTo(Routes.Matching);
    }
}
