import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { Button } from './button';

@Component({
               selector:  'icon-button',
               template:  `
                              <button #button 
                                      class="mdc-icon-button icon-button"
                                      [class.mdc-icon-button--disabled]="disabled"
                                      [ngClass]="classes"
                                      [disabled]="disabled"
                              >
                                  <div class="mdc-button__ripple"></div>
                                  <icon class="icon-button__icon" [icon]="icon"></icon>
                              </button>
                          `,
               styles: [require('./icon-button.scss')],
           })
export class IconButton extends Button implements AfterViewInit{
    @Input() classes: string = '';
    @Input() icon: string;

    public constructor(elementRef: ElementRef) {
        super(elementRef);
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();
        this.ripple.unbounded = true;
    }
}
