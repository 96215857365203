import {
    AfterViewInit,
    Component,
    ViewChild,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import * as moment from 'moment';
import { Dropdown } from '../dropdown/dropdown';

@Component({
               selector: 'date-picker',
               template: `
        <div class="date-picker">
            <dropdown class="date-picker__dropdown" [placeholder]="'DATE_PICKER.DAY' | translate" [value]="day" [disabled]="disabled" #day>
                <dropdown-option *ngFor="let dayValue of dayArray"
                                 [value]="dayValue"
                                 [disabled]="dateDisabled(dayValue)"
                                 [selected]="dayValue == day"
                >
                    {{dayValue}}
                </dropdown-option>
            </dropdown>
            <dropdown class="date-picker__dropdown" [placeholder]="'DATE_PICKER.MONTH' | translate" [value]="month" [disabled]="disabled" #month>
                <dropdown-option *ngFor="let monthValue of monthArray"
                                 [value]="monthValue"
                                 [selected]="monthValue == month"
                                 [disabled]="monthDisabled(monthValue)"

                >
                    {{monthValue + 1}}
                </dropdown-option>
            </dropdown>

            <dropdown class="date-picker__dropdown" [placeholder]="'DATE_PICKER.YEAR' | translate" [value]="year" [disabled]="disabled" #year>
                <dropdown-option *ngFor="let yearValue of yearArray"
                                 [value]="yearValue"
                                 [selected]="yearValue == year"
                >
                    {{yearValue}}
                </dropdown-option>
            </dropdown>
        </div>
    `,
               styles: [require('./date-picker.scss')],
               providers: [
                   getValueAccessorProvider(DatePicker),
               ],
           })
export class DatePicker extends ValueAccessorBase<string> implements AfterViewInit{
    @ViewChild('day', {static: true}) private dayDropdown: Dropdown;
    @ViewChild('month', {static: true}) private monthDropdown: Dropdown;
    @ViewChild('year', {static: true}) private yearDropdown: Dropdown;


    private _curDate = moment().subtract(18, 'year');

    private get curDate() {
        return this._curDate;
    }

    private set curDate(value) {
        this._curDate = value;
        this.day = this.curDate.get('date');
        this.month = this.curDate.get('month');
        this.year = this.curDate.get('year');
    }

    private dayArray = this.calcDayArray();

    private monthArray = DatePicker.createArray(0, 11);

    private yearArray = DatePicker.createArray(this.curDate.get('year'), 1900);

    private get day(): number {
        return this.curDate.get('date');
    }

    private set day(val: number) {
        this.curDate.set('date', val);
        this.dayDropdown.selectedIndex = this.dayArray.indexOf(val)
        this.setValue();
    }

    private get month(): number {
        return this.curDate.get('month');
    }

    private set month(val: number) {
        this.curDate.set('month', val);
        this.dayArray = this.calcDayArray();
        this.monthDropdown.selectedIndex = this.monthArray.indexOf(val)
        this.setValue();
    }

    private get year(): number {
        return this.curDate.get('year');
    }

    private set year(val: number) {
        this.curDate.set('year', val);
        this.dayArray = this.calcDayArray();
        this.yearDropdown.selectedIndex = this.yearArray.indexOf(val);
        this.setValue();
    }

    private static createArray(from: number, to: number): number[] {
        if (from > to) return DatePicker.createArray(to, from)
                                        .reverse();

        return Array.from(new Array((to - from) + 1), (v, i) => i + from);
    }

    private initComplete = false;

    public ngAfterViewInit() {
        this.curDate = moment(this.value);
        this.initComplete = true
    }

    private calcDayArray(): number[] {
        return DatePicker.createArray(1, this.curDate.daysInMonth());
    }

    private dateDisabled(day): boolean {
        const b = moment([this.year, this.month, day]);
        return moment()
            .diff(b, 'year') < 18;
    }

    private monthDisabled(month): boolean {
        const b = moment([this.year, month]);
        return moment()
            .diff(b, 'year') < 18;
    }

    private setValue() {
        if(this.initComplete) {
              this.value = this.curDate.format('YYYY-MM-DD');
        }
    }
}
