import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SaveAndReset } from 'BKBaseClass';

export abstract class ProfileEditBoxBase extends SaveAndReset {
    @Input() protected group: FormGroup;

    protected getGroupValue(name: string): any {
        return this.group.controls[name].value;
    }

    protected getGroupError(name: string): any {
        const errors = this.group.controls[name].errors;
        if (errors) return Object.keys(errors).filter(key => errors[key])[0];

        return '';
    }

    protected isPending(name: string) {
        return this.group.controls[name].pending;
    }

    protected invalid(controlName: string): boolean {
        if (!this.group.controls.hasOwnProperty(controlName)) {
            throw new Error(`${controlName} is not a valid control`);
        }

        return (this.group.controls[controlName].errors
            && !this.group.controls[controlName].pristine);
    }
}
