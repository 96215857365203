import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'lovestory-formular-card',
               template: `
        <card padding class="ls-form-card">
            <div class="ls-form-card__title">
                {{title}}
            </div>
            <ng-content></ng-content>
        </card>
    `,
               styles: [require('./lovestory-formular-card.scss')],
           })

export class LovestoryFormularCard {
    @Input() private title: string = '';
}
