import { BkConfig } from 'BKConfig';
import * as moment from 'moment';


export enum CacheKey {
    ProfileVisitors,
    SelectedUser,
    MatchPartner,
    MatchingYouWant,
    MatchingWantYou,
    ConversationList,
    Conversations,
    RealUser,
    NearBy,
    LatestUser,
    PremiumDetails,
    BillingAddess,
    SepaAccount,
    CashReference,
    PremiumProductList,
    Search,
    SearchNickname,
    Magazin,
    Glossary,
    Favorite,
    FavoriteList,
    FavoriteListDeleted,
    Ignore,
    Lovestory,
    LovestoryList,
    SingleEventList,
    Tariff,
    OpenInvoice,
    AllConversation,
    UnreadConversationWithoutSystem,
}

export class Cache {
    private static _instance: Cache;

    public static getInstance() {
        if (!Cache._instance) Cache._instance = new Cache();
        return Cache._instance;
    }

    private cache: Map<CacheKey, Map<string, CacheItem>> = new Map();

    public get<T>(key: CacheKey, params: any[], fallback: any, timeToLive: number = BkConfig.defaultCacheTime): Promise<T> {
        const innerKey = params.join('-');

        if (!this.cache.has(key)) this.cache.set(key, new Map<string, CacheItem>());
        if (!this.cache.get(key).has(innerKey)) this.cache.get(key).set(innerKey, new CacheItem(fallback, timeToLive));

        return this.cache.get(key)
                   .get(innerKey)
                   .item
                   .catch(err => {
                       this.cache.delete(key);
                       throw err;
                   });
    }

    public reload(key: CacheKey) {
        if (this.cache.has(key)) this.cache.get(key).forEach(item => item.reload());
    }

    public clear() {
        this.cache.clear();
    }

    public delete(...keys: CacheKey[]) {
        keys.forEach(key => this.cache.delete(key));
    }

}

class CacheItem {
    private expiry;
    private _item: any;
    private loadingPromise: Promise<any> = null;

    public get item(): Promise<any> {
        return this.isValid ? Promise.resolve(this._item) : this.load();
    }

    public constructor(
        private fallback: () => Promise<any>,
        private timeToLife: number = BkConfig.defaultCacheTime,
    ) {
        this.setExpiry();
    }

    public reload() {
        this.load();
    }

    public get isValid(): boolean {
        return this._item && this.expiry.isAfter(moment());
    }

    private setExpiry() {
        this.expiry = moment().add(this.timeToLife, 'ms');
    }

    private load(): Promise<any> {
        if (!this.loadingPromise) this.loadingPromise = this.fallback()
                                                            .then(res => {
                                                                this._item = res;
                                                                this.loadingPromise = null;
                                                                this.setExpiry();
                                                                return res;
                                                            });
        return this.loadingPromise;
    }
}

export function Cacheable(key: CacheKey, timeToLife: number = BkConfig.defaultCacheTime) {
    return function (target: Object, method: string, descriptor: TypedPropertyDescriptor<any>) {
        if (descriptor.value) {
            const originalMethod = descriptor.value;
            descriptor.value = function (...params) {
                return Cache.getInstance().get(key, params, () => originalMethod.apply(this, params), timeToLife);
            };
        } else {
            const originalMethod = descriptor.get;
            descriptor.get = function (...params) {
                return Cache.getInstance().get(key, params, () => originalMethod.apply(this, params), timeToLife);
            };
        }
    };


}
