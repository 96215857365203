import { Component } from '@angular/core';
import { NavigationService } from '../../../../service/navigation.service';

@Component({
               selector:  'settings-debit-again-header-bar',
               template:  `
                              <div class="settings-debit-again-header-bar">
                                  <flat-button big-icon leadingIcon="arrow-back" 
                                    (click)="navigationService.navigateBack()"
                                  >
                                      <span class="is-hidden-tablet"> {{'GLOBAL.BACK' | translate}} </span>
                                  </flat-button>
                              </div>
                          `,
               styles: [require('./settings-debit-again-header-bar.scss')],

           })

export class SettingsDebitAgainHeaderBar {

    public constructor(private navigationService: NavigationService) {
    }

}
