import {
    Component,
    Input,
} from '@angular/core';
import { Article } from '../../../../service/rss/childs/rss-feed';

@Component({
               selector:  'logout-glossar-article',
               template:  `
                              <div class="mdc-card logout-glossar-article" (click)="openLink()">
                                  <div class="mdc-card__primary-action mdc-ripple-upgraded" tabindex="0">
                                      <div class="mdc-card__media mdc-card__media--16-9"
                                           [style.backgroundImage]="image"
                                      ></div>
                                      <div class="logout-glossar-article__primary">
                                          <div class="logout-glossar-article__title">{{article.title}}</div>
                                          <div class="logout-glossar-article__category">{{article.category}}</div>
                                      </div>
                                      <div class="logout-glossar-article__secondary">
                                          <logout-gloassar-rating [rating]="article.rating"></logout-gloassar-rating>
                                          <div class="logout-glossar-article__readtime">
                                              <icon icon="av-timer" class="logout-glossar-article__readtime__icon"></icon>
                                              {{article.readtime}}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./logout-glossar-article.scss')],
           })

export class LogoutGlossarArticle {
    @Input() private article: Article;

    private get image() {
        return `url(${this.article.image})`;
    }

    private openLink(){
        window.open(this.article.link, '_blank');
    }
}
