import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { PremiumModule } from '../premium/premium.module';
import { SettingsSepaData } from './components/settings-sepa-data/settings-sepa-data';
import { SettingRouting } from './setting.routing';
import { SettingsAccountDeleteFooter } from './settings-account-delete/components/settings-account-delete-footer/settings-account-delete-footer';
import { SettingsAccountDeleteGlossaryArticle } from './settings-account-delete/settings-account-delete-glossar-article/settings-account-delete-glossary-article';
import { SettingsAccountDeleteHeader } from './settings-account-delete/settings-account-delete-header/settings-account-delete-header';
import { SettingsAccountDeleteReasonBox } from './settings-account-delete/settings-account-delete-reason-box/settings-account-delete-reason-box';
import { SettingsAccountDeleteBasicStepList } from './settings-account-delete/settings-account-delete-step-list/settings-account-delete-basic-step-list';
import { SettingsAccountDeleteStepReason } from './settings-account-delete/settings-account-delete-step-reason/settings-account-delete-step-reason';
import { SettingsAccountDeleteTitle } from './settings-account-delete/components/settings-account-delete-title/settings-account-delete-title';
import { SettingsAccountDeleteStepRecommendation } from './settings-account-delete/settings-account-delete-step-recommendation/settings-account-delete-step-recommendation';
import { SettingsAccountDeleteStepSelectedUser } from './settings-account-delete/settings-account-delete-step-selected-user/settings-account-delete-step-selected-user';
import { SettingsAccountBox } from './settings-account/settings-account-box';
import { SettingsAccountPayment } from './settings-account/settings-account-payment';
import { SettingsAccountTariff } from './settings-account/settings-account-tariff';
import { SettingsAccountDeletePremiumStepList } from './settings-account-delete/settings-account-delete-step-list/settings-account-delete-premium-step-list';
import { SettingsAccountDelete } from './settings-account-delete/settings-account-delete/settings-account-delete';
import { SettingsChangeSepa } from './settings-change-sepa/settings-change-sepa';
import { SettingsChangeSepaHeader } from './settings-change-sepa/settings-change-sepa-header';
import { SettingsChangeSepaHeaderBar } from './settings-change-sepa/settings-change-sepa-header-bar';
import { SettingsDebitAgain } from './settings-debit-again/settings-debit-again';
import { SettingsDebitAgainHeader } from './settings-debit-again/settings-debit-again-header';
import { SettingsDebitAgainHeaderBar } from './settings-debit-again/settings-debit-again-header-bar';
import { SettingsHeader } from './settings-header/settings-header';
import { SettingsAccount } from './settings-account/settings-account';
import { SettingsIgnoreList } from './settings-ignore/settings-ignore-list';
import { SettingNotification } from './settings-notification/settings-notification';
import { SettingsNotificationEmail } from './settings-notification/settings-notification-email';
import { SettingsNotificationPush } from './settings-notification/settings-notification-push';
import { SettingsChangePassword } from './settings-password-change/settings-change-password';
import { SettingsPrivacy } from './settings-privacy/settings-privacy';
import { SettingsSwitch } from './settings-switch/settings-switch';
import { SettingsTabbar } from './settings-tabbar/settings-tabbar';
import { SettingsTitle } from './settings-title/settings-title';
import { Settings } from './settings/settings';

@NgModule({
              imports: [
                  ShareModule,
                  SettingRouting,
                  PremiumModule,
              ],
              exports:      [],
              declarations: [
                  Settings,
                  SettingsTitle,
                  SettingsSwitch,
                  SettingsChangePassword,
                  SettingsAccount,
                  SettingsAccountPayment,
                  SettingsAccountTariff,
                  SettingsAccountBox,
                  SettingsNotificationEmail,
                  SettingsNotificationPush,
                  SettingsChangeSepa,
                  SettingsHeader,
                  SettingsTabbar,
                  SettingsChangeSepaHeader,
                  SettingsChangeSepaHeaderBar,
                  SettingNotification,
                  SettingsPrivacy,
                  SettingsDebitAgain,
                  SettingsDebitAgainHeader,
                  SettingsDebitAgainHeaderBar,
                  SettingsSepaData,
                  SettingsAccountDelete,
                  SettingsAccountDeletePremiumStepList,
                  SettingsAccountDeleteBasicStepList,
                  SettingsAccountDeleteStepSelectedUser,
                  SettingsAccountDeleteStepReason,
                  SettingsAccountDeleteTitle,
                  SettingsAccountDeleteFooter,
                  SettingsAccountDeleteReasonBox,
                  SettingsAccountDeleteStepRecommendation,
                  SettingsAccountDeleteGlossaryArticle,
                  SettingsAccountDeleteHeader,
                  SettingsIgnoreList,
              ],
              providers:    [],
          })
export class SettingsModule {
}
