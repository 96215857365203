import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlossaryRss } from './childs/glossary.rss';
import { MagazineRss } from './childs/magazine.rss';

@Injectable({providedIn: 'root'})
export class RssService {
    public readonly magazine = new MagazineRss(this.httpClient);
    public readonly glossary = new GlossaryRss(this.httpClient);

    public constructor(private httpClient: HttpClient) {
    }
}
