import {
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { MatchingWantYouDataSource } from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

enum VisibleElement {
    List,
    MissingProfilePicture,
    EmptyList
}

/**
 * MatchingWantYouComponent
 */
@Component({
               selector:  'matching-want-you',
               template:  `
                              <ng-container>
                                  <ng-container *ngIf="visibleElement == VisibleElement.MissingProfilePicture">
                                      <list-empty img="/assets/img/picMatchingList.svg"
                                                  [title]="'MATCHING.WANT_YOU.MODAL.MISSING_PROFILE_PICTURE.TITLE'|translate"
                                                  [text]="'MATCHING.WANT_YOU.MODAL.MISSING_PROFILE_PICTURE.TEXT'|translate"
                                      >
                                          <div class="matching-want-you__missing-picture__action-wrap">
                                              <raised-button [routerLink]="navigationService.getRoute(Routes.MyProfile)"
                                                             [fragment]="ProfileEditRouteFragment.Images"
                                                             class="profile-visitor-empty__button"
                                              >
                                                  {{'MATCHING.WANT_YOU.MISSING_PROFILE_PICTURE.ACTION'|translate}}
                                              </raised-button>
                                          </div>
                                      </list-empty>
                                  </ng-container>
                                  <ng-container *ngIf="visibleElement == VisibleElement.EmptyList">
                                      <list-empty
                                              img="assets/img/bild-upload-pic.svg"
                                              [text]="'MATCHING.WANT_YOU.EMPTY_LIST.TEXT'|translate|markdown"
                                              [title]="'MATCHING.WANT_YOU.EMPTY_LIST.TITLE'|translate"
                                      >
                                          <raised-button
                                                  class="matching-want-you__empty-list__action-wrap"
                                                  [routerLink]="navigationService.getRoute(Routes.MyProfile)"
                                                  (click)="trackingService.hit('Matching', 'MoegenDich', 'Klick: Btn: JetztProfilAusfuellen')"
                                          >
                                              {{'MATCHING.WANT_YOU.EMPTY_LIST.ACTION'|translate}}
                                          </raised-button>
                                          <hr class="matching-want-you__empty-list__separator"/>
                                          <div class="matching-want-you__empty-list__lovestory">
                                              <div class="bk-matching-partner__empty-list__lovestory__title">
                                                  {{ 'MATCHING.WANT_YOU.EMPTY_LIST.EXTRA.TITLE'|translate }}
                                              </div>
                                              <img class="matching-want-you__empty-list__lovestory__image" src="assets/img/matching-diana-eric.png"/>
                                              <div class="matching-want-you__empty-list__lovestory__text">
                                                  {{ 'MATCHING.WANT_YOU.EMPTY_LIST.EXTRA.TEXT'|translate }}
                                              </div>
                                          </div>
                                      </list-empty>
                                  </ng-container>
                                  <ng-container *ngIf="visibleElement == VisibleElement.List">
                                      <bk-matching-list-header [title]="'MATCHING.WANT_YOU.HEADER.TITLE'| translate:currentUser">
                                          <div class="matching-want-you__text">
                                              <span *ngIf="!currentUser.isPremium()" [innerHTML]="'MATCHING.WANT_YOU.HEADER.TEXT.BASIC'| translate:{ count: totalCount.toString() } | bypassSanitizer"></span>
                                              <span *ngIf="currentUser.isPremium()" [innerHTML]="'MATCHING.WANT_YOU.HEADER.TEXT.PREMIUM'| translate:{ count: totalCount.toString() } | bypassSanitizer"></span>
                                          </div>
                                      </bk-matching-list-header>

                                      <div class="bk-matching-want-you">
                                          <list-container #listContainer
                                                          [dataSource]="listDataSource"
                                          >
                                              <ng-template let-item>
                                                  <list-item-selector two-columns-max
                                                                      [listItem]="item"
                                                                      [currentUser]="currentUser"
                                                                      (onOpenProfile)="onOpenProfile()"
                                                                      (onAddFavorite)="onAddFavorite()"
                                                                      (onOpenConversation)="onOpenConversation()"
                                                                      (onDeleteFavorite)="onDeleteFavorite()"
                                                                      (gotoPremium)="gotoPremium()"
                                                                      (onVoteYes)="onVoteYes()"
                                                                      (onVoteMaybe)="onVoteMaybe()"
                                                                      (onVoteNo)="onVoteNo()"
                                                  ></list-item-selector>
                                              </ng-template>
                                          </list-container>
                                      </div>
                                  </ng-container>
                              </ng-container>
                          `,
               styles: [require('./matching-want-you.scss')],
           })
export class MatchingWantYou implements OnInit {
    currentUser: CurrentUser = new CurrentUser();
    listDataSource: MatchingWantYouDataSource;

    Routes = Routes;
    ProfileEditRouteFragment = ProfileEditRouteFragment;

    VisibleElement = VisibleElement;

    get totalCount(): number {
        return this.currentUser?.openMatches ?? 0;
    }

    get visibleElement(): VisibleElement {
        if (this.currentUserValid && !this.currentUser.profileImgExist()) return VisibleElement.MissingProfilePicture;
        if (this.listDataSource && this.listDataSource.isEmpty && !this.listDataSource.isLoading) return VisibleElement.EmptyList;
        return VisibleElement.List;
    }

    private get currentUserValid(): boolean {
        return !!this.currentUser && this.currentUser.id !== 0;
    }

    public constructor(
        private currentUserService: CurrentUserService,
        private matchingService: MatchingService,
        public trackingService: TrackingService,
        public navigationService: NavigationService,
        private premiumService: PremiumService,
        private dataSourceFactory: DataSourceFactory,
    ) {
    }

    ngOnInit() {
        this.currentUserService
            .currentUser
            .then((currentUser) => this.currentUser = currentUser);

        this.dataSourceFactory.createMatchingWantYouDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });
    }

    onOpenProfile() {
        this.trackingService.hit('Matching', 'MoegenDich', 'ProfilAufgerufen');
    }

    onOpenConversation() {
        this.trackingService.hit('Matching', 'MoegenDich', 'Konversation');
    }

    onAddFavorite() {
        this.trackingService.hit('Matching', 'MoegenDich', 'FavoritHinzugefuegt');
    }

    onDeleteFavorite() {
        this.trackingService.hit('Matching', 'MoegenDich', 'FavoritGeloescht');
    }

    gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.WantToKnowYou);
    }

    onVoteYes() {
        this.trackingService.hit('Matching', 'MoegenDich', 'Ja');
    }

    onVoteMaybe() {
        this.trackingService.hit('Matching', 'MoegenDich', 'Vielleicht');
    }

    onVoteNo() {
        this.trackingService.hit('Matching', 'MoegenDich', 'Nein');
    }
}
