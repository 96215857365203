/**
 * Slider Formatter Interface
 */
export interface SliderFormatterBase {
    /**
     * Format string to number
     * @param {number} value
     * @returns {string}
     */
    from(value: string): number;

    /**
     * Format number to string
     *
     * @param {number} value
     * @returns {string}
     */
    to(value: number): string;
}
