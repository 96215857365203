/**
 * Created by olifra on 15.05.2017.
 */

import { Image } from '../image';
import { MatchingDecision } from '../matching';

import {
    AccountState,
    BodyType,
    Children,
    ChildrenWish,
    ContactWish,
    createContactWishFromApiContactWish,
    createRelationshipFromApiRelationship,
    EyeColor,
    Gender,
    HairColor,
    Online,
    Relationship,
    SexOrientation,
    Smoker,
    ZodiacSign,
} from './status';
import { UserBaseView } from './user-base-view';

/**
 * Profile Informationen eins Benutzers
 */
export class ProfileInfo extends UserBaseView {
    private static storageName: string = 'Bildkontakte.curProfileInfo';

    public aboutme: string = '';

    public accountState: AccountState = AccountState.Deactivated;

    public age: number = 0;

    public birthdate: Date = new Date();

    public career: string = '';

    public children: Children = Children.AskMe;

    public childrenWish: ChildrenWish = ChildrenWish.No;

    public contactWish: ContactWish = ContactWish.LetsSee;

    public conversation: boolean = false;

    public conversationState: string = '';

    public country: string = '';

    public description: string = '';

    public dislike: string = '';

    public email: string = '';

    public eyecolor: EyeColor = EyeColor.Undefined;

    public favorite: boolean = false;

    public figure: BodyType = BodyType.AskMe;

    public fullGallery: Image[] = [];

    public gallery: string[] = [''];

    public haircolor: HairColor = HairColor.Undefined;

    public hobbys: string = '';

    public ignore: boolean = false;

    public ignoresMe: boolean = false;

    public imageCount: number = 0;

    public languages: string[] = [];

    public like: string = '';

    public linearDistance: string = '0';
    public logins: number = 0;
    public motto: string = '';
    public n1Code: string = '';
    public n2Code: string = '';
    public numOfChildren: number = 0;
    public numOfChildrenWish: number = 0;
    public personOfDream: string = '';
    public postalCode: string = '';
    public premium: boolean = false;
    public relationship: Relationship = Relationship.AskMe;
    public sexOrientation: SexOrientation = SexOrientation.Others;
    public size: number = 0;
    public smoker: Smoker = Smoker.AskMe;
    public suspended: boolean = false;
    public scammer: boolean = false;

    public weight: number = 0;

    public zodiacSign: string = ZodiacSign.Aquarius;

    public matchValue: number;

    private mainImage: Image;

    public get exists(): boolean {
        return this.id !== undefined;
    }

    public get isAvailable(): boolean {
        return !this.isSuspended && this.exists && !this.ignoresMe;
    }

    public get isSuspended(): boolean {
        return this.accountState == AccountState.Deactivated || this.accountState == AccountState.OpenBill || this.suspended || this.scammer;
    }

    public get isTeam(): boolean {
        // eslint-disable-next-line
        return this.vip == true;
    }

    public static create(data ?: any): ProfileInfo {
        let resData = new ProfileInfo();
        if (data !== null && data !== undefined) {
            resData.accountState = data.accountState;
            resData.id = data.id;
            resData.nickname = data.nickname;
            resData.email = data.email;
            resData.premium = data.premium;
            resData.vip = !!data.vip;
            resData.age = data.age;
            resData.birthdate = data.birthdate;
            resData.gender = data.gender;
            resData.image = data.image || new Array(33).join('0');
            resData.motto = data.motto;
            resData.echeck = data.echeck;
            resData.online = data.online;
            resData.favorite = data.favorite;
            resData.ignore = data.ignore;
            resData.ignoresMe = data.ignoresMe;
            resData.gallery = data.gallery;
            resData.fullGallery = Image.createMultiple(data.fullGallery);
            resData.imageCount = data.imageCount;
            resData.conversation = data.conversation;
            resData.conversationState = data.conversationState;
            resData.deleted = data.deleted;
            resData.suspended = data.locked || false;
            resData.scammer = data.scammer;
            resData.logins = data.logins;
            resData.decisionOwner = data.decisionOwner || MatchingDecision.NoDecision;
            resData.decisionPartner = data.decisionPartner;
            resData.basicView = data.basicView;
            resData.linearDistance = data.linearDistance;
            resData.matchValue = data.matchValue;
            resData.messageReceive = data.messageReceive;
            resData.interview = data.interview;
            resData.matchingCriteria = data.matchingCriteria ?? false;
            resData = ProfileInfo.unpackSubFields(resData, data);
        }
        return resData;
    }

    private static unpackSubFields(infos: ProfileInfo, data: any): ProfileInfo {
        const resData = infos;
        if (typeof data.aboutMe === 'object' && typeof data.search === 'object' && typeof data.private === 'object') {
            resData.size = data.aboutMe.size;
            resData.weight = data.aboutMe.weight;
            resData.figure = data.aboutMe.figure;
            resData.eyecolor = data.aboutMe.eyecolor;
            resData.haircolor = data.aboutMe.haircolor;
            resData.city = data.aboutMe.city;
            resData.postalCode = data.aboutMe.postalCode;
            resData.country = data.aboutMe.country;
            resData.n1Code = data.aboutMe.n1Code;
            resData.n2Code = data.aboutMe.n2Code;
            resData.longitude = data.aboutMe.longitude;
            resData.latitude = data.aboutMe.latitude;
            resData.food = data.aboutMe.food ?? [];
            resData.movies = data.aboutMe.movies ?? [];
            resData.music = data.aboutMe.music ?? [];
            resData.searchGender = data.search.searchGender;
            resData.ageFrom = data.search.ageFrom;
            resData.ageTill = data.search.ageTill;
            resData.contactWish = createContactWishFromApiContactWish(data.search.contactWish);
            resData.personOfDream = data.search.personOfDream;
            resData.sexOrientation = data.search.sexOrientation;
            resData.relationship = createRelationshipFromApiRelationship(data.private.relationship);
            resData.career = data.private.career;
            resData.hobbys = data.private.hobbys;
            resData.children = data.private.children;
            resData.numOfChildren = data.private.numOfChildren;
            resData.childrenWish = data.private.childrenWish;
            resData.numOfChildrenWish = data.private.numOfChildrenWish;
            resData.like = data.private.like;
            resData.dislike = data.private.dislike;
            resData.zodiacSign = data.private.zodiacSign;
            resData.smoker = data.private.smoker;
            resData.languages = data.private.languages || [];
            resData.description = data.private.description;
            resData.aboutme = data.private.aboutme;
        } else {
            resData.size = data.size || 0;
            resData.weight = data.weight || 0;
            resData.figure = data.figure || 0;
            resData.eyecolor = data.eyecolor || EyeColor.Others;
            resData.haircolor = data.haircolor || HairColor.Other;
            resData.city = data.city || '';
            resData.postalCode = data.postalCode || '';
            resData.country = data.country || '';
            resData.n1Code = data.n1Code || '';
            resData.n2Code = data.n2Code || '';
            resData.longitude = data.longitude || null;
            resData.latitude = data.latitude || null;
            resData.searchGender = data.searchGender || Gender.Female;
            resData.ageFrom = data.ageFrom || 99;
            resData.ageTill = data.ageTill || 99;
            resData.contactWish = createContactWishFromApiContactWish(data.contactWish);
            resData.personOfDream = data.personOfDream || '';
            resData.sexOrientation = data.sexOrientation || '';
            resData.relationship = createRelationshipFromApiRelationship(data.relationship);
            resData.career = data.career || '';
            resData.hobbys = data.hobbys || '';
            resData.children = data.children || Children.AskMe;
            resData.numOfChildren = data.numOfChildren || 0;
            resData.childrenWish = data.childrenWish || ChildrenWish.AskMe;
            resData.numOfChildrenWish = data.numOfChildrenWish || 0;
            resData.like = data.like || '';
            resData.dislike = data.dislike || '';
            resData.zodiacSign = data.zodiacSign || ZodiacSign.Aquarius;
            resData.smoker = data.smoker || Smoker.AskMe;
            resData.languages = data.languages || [];
            resData.description = data.description || '';
            resData.aboutme = data.aboutme || '';
        }
        return resData;
    }

    public getMainImage(): Image {
        if (!this.mainImage) {
            this.mainImage = this.fullGallery
                                 .find((curImg: Image) => curImg.main);
        }
        return this.mainImage;
    }
}
