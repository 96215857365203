import { Component } from '@angular/core';

@Component({
               selector:  'bk-footer-out',
               template:
                          `
                              <div class="footer-out">
                                  <div class="footer-out__content">
                                      <span class="footer-out__content__logo"></span>
                                      <footer-links></footer-links>
                                  </div>
                              </div>

                          `,
               styles: [require('./footer-out.scss')],
           })

export class FooterOut {
    public constructor() {
    }
}
