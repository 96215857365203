import {
    Component,
    Input,
} from '@angular/core';
import {
    MatchingQueueItem,
    ProfileInfo,
} from 'BKModels';
import {
    MatchingService,
    PlayedPartnerQueue,
} from 'BKService';

@Component({
               selector:  'matching-film-roll',
               template:  `
                              <div class="matching-film-roll">
                                  <div class="matching-film-roll__overlay"></div>
                                  <div class="matching-film-roll__line"></div>
                                  <div class="matching-film-roll__images">
                                      <matching-film-roll-item *ngFor="let played of playedItems " [user]="played"></matching-film-roll-item>
                                      <matching-film-roll-item center [user]="currentPartner"></matching-film-roll-item>
                                      <matching-film-roll-item *ngFor="let queueItem of queueItems " [user]="queueItem.partner"></matching-film-roll-item>
                                  </div>
                                  <div class="matching-film-roll__line"></div>
                              </div>
                          `,
               styles: [require('./matching-film-roll.scss')],
           })
export class MatchingFilmRoll {
    @Input() private matchingQueue: MatchingQueueItem[] = new Array(5);
    @Input() private playedPartnerList: PlayedPartnerQueue = new PlayedPartnerQueue();

    private get queueItems(): MatchingQueueItem[] {

        const queue = this.matchingQueue.slice(1, MatchingService.photoFramesPreviewLimit + 1);
        while (queue.length <= MatchingService.photoFramesPreviewLimit) {
            queue.push(new MatchingQueueItem());
        }
        return queue;
    }

    private get currentPartner(): ProfileInfo {
        return this.matchingQueue.first().partner;
    }

    private get playedItems(): ProfileInfo[] {
        return this.playedPartnerList.queue;
    }
}
