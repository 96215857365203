export enum ApiContactWish {
    AllContacts = 0,
    LetsSee = 1,
    Partnership = 2,
    EMailAcquaintance = 3,
    Others = 4,
    NicePeople = 5,
    TravelPartner = 6,
    SportsPartner = 7,
}


export enum ApiRelationship {
    FindItOut = 0,
    Single = 1,
    Married = 2,
    Separately = 3,
    Divorced = 4,
    Relationship = 5,
    Widowed = 6,
    Engaged = 7,
    Others = 8,
    OpenRelationship = 9,
    SingleParenting = 10,
}

export enum SpamReportReason {
    Advertising = 1,
    RipOff = 2, // abzocke
    WrongSexuality = 3, // falsches Geschlecht
    Celebrity = 4, // Prominenter
    SexualAssault = 5,
    Other = 6,
}
