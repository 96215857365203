import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../../outer-component';
import { LayoutModule } from '../layout';
import {
    MailSettingsCard,
} from './mail-settings-card/mail-settings-card';
import { MailSettingsChanged } from './mail-settings-changed/mail-settings-changed';
import { MailSettingsProfileVisitor } from './mail-settings-profile-visitor/mail-settings-profile-visitor';
import { MailSettingsUserSuggestion } from './mail-settings-user-suggestion/mail-settings-user-suggestion';
import { MailSettingsWeeklySummary } from './mail-settings-weekly-summary/mail-settings-weekly-summary';
import { MailSettingsRouting } from './mail-settings.routing';

@NgModule({
              imports: [
                  ShareModule,
                  MailSettingsRouting,
                  LayoutModule,
                  OuterComponentModule,
              ],
              declarations: [
                  MailSettingsWeeklySummary,
                  MailSettingsProfileVisitor,
                  MailSettingsUserSuggestion,
                  MailSettingsCard,
                  MailSettingsChanged,
              ],
              providers:    [],
          })
export class MailSettingsModule {
}
