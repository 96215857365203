import { Component } from '@angular/core';

@Component({
               selector: 'schlagerherzen-on-air-banner',
               template: `
                             <div class="banner">

                                 <div class="main-section">
                                     <div class="main-section__play-button">
                                        <img class="main-section__play-button__icon" src="./assets/schlagerparadies/icon-play-circle.svg"/>
                                     </div>
                                     <div class="main-section__header">
                                         <h2 class="main-section__header__headline">{{ 'BANNER.SCHLAGERHERZEN.ON_AIR.TITLE'|translate }}</h2>
                                         <h3 class="main-section__header__subheadline">{{ 'BANNER.SCHLAGERHERZEN.ON_AIR.SUBTITLE'|translate }}</h3>
                                     </div>
                                 </div>
                                 <div class="on-air__container"><span class="on-air">On Air</span></div>
                                 <div class="cooperation">
                                     <span>{{ 'BANNER.SCHLAGERHERZEN.ON_AIR.COOPERATION'|translate }}</span>
                                     <img class="cooperation__logo--bk" src="./assets/schlagerparadies/logo-bildkontakte.svg" />
                                     <img class="cooperation__logo--rsp" src="./assets/schlagerparadies/logo-radio-schlagerparadies.svg" />
                                 </div>
                             </div>
                         `,
               styles: [require('./schlagerherzen-on-air-banner.scss')],
           })
export class SchlagerherzenOnAirBanner {}