import { Component } from '@angular/core';

@Component({
               selector:  'loverstory-formular-delete-container',
               template:  `

    <form [ngSwitch]="curPage">
      <div *ngSwitchCase="1">
        <lovestory-formular-header></lovestory-formular-header>

      </div>
      <div *ngSwitchCase="2">
        <h1>Page2</h1>
      </div>
      <div *ngSwitchCase="3">
        <h1>Page3</h1>
      </div>
    </form>


    <lovestory-formular-footer
      [curPage]="curPage"
      [maxPage]="maxPage"
      (nextStep)="nextStep()"

    ></lovestory-formular-footer>
  `,
               styles: [require('./loverstory-formular-delete-container.scss')],
           })

export class LoverstoryFormularDeleteContainer {
    private curPage = 1;

    private maxPage = 3;

    private nextStep() {
        this.curPage = (this.curPage % this.maxPage) + 1;
    }
}
