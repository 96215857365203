import {
    Component,
    Input,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SaveAndReset } from 'BKBaseClass';

@Component({
               selector:  'profile-edit-box',
               template:  `
                              <card-title [title]="title">
                                  <ng-content></ng-content>

                                  <div class="profile-edit-box__wrapper">
                                      <flat-button class="profile-edit-box__wrapper__button" 
                                                   [disabled]="disabled"
                                                   (click)="reset($event)"
                                      >
                                          {{'PROFILE.EDIT.DISCARD' | translate}}
                                      </flat-button>

                                      <raised-button class="profile-edit-box__wrapper__button"
                                                     (click)="save($event)" [disabled]="disabled"
                                      >
                                          {{'PROFILE.EDIT.SAVE' | translate}}
                                      </raised-button>

                                  </div>
                              </card-title>
                          `,
               styles: [require('./profile-edit-box.scss')],
           })

export class ProfileEditBox extends SaveAndReset {
    @Input() private group: FormGroup;
    @Input() private title: string = '';

    get disabled(): boolean {
        return !this.group.dirty || !this.group.valid
    }
}

