import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { DialogBox } from '../dialog/dialog-box';

@Component({
            selector: 'bk-modal-schlagerherzen-success',
            template: `
                      <ng-container>
                          <dialog-box #dialogBox
                                      [title]="'SCHLAGERHERZEN.SUCCESS.TITLE'|translate"
                                      [acceptText]="'SCHLAGERHERZEN.SUCCESS.ACTION'|translate"
                          >
                              {{ 'SCHLAGERHERZEN.SUCCESS.TEXT'|translate }}
                          </dialog-box>
                      </ng-container>
                      `,
            styles: [require('./bk-modal-schlagerherzen-application.scss')]
           })
export class BkModalSchlagerherzenSuccess implements ModalDialog {
    @ViewChild('dialogBox', { static: true }) dialogBox: DialogBox;

    static readonly typeId = 'BkModalSchlagerherzenSuccess';

    public open(config) {
        this.dialogBox.open();
    }

    public close() {
        this.dialogBox.close();
    }

    public get typeId(): string {
        return BkModalSchlagerherzenSuccess.typeId;
    }
}