import { LovestoryDescription } from './lovestory-description/lovestory-description';
import { LovestoryHeader } from './lovestory-header/lovestory-header';
import { LovestoryItem } from './lovestory-item/lovestory-item';
import { Lovestory } from './lovestory/lovestory';


export const lovestoryOverviewComponents = [
    Lovestory,
    LovestoryItem,
    LovestoryDescription,
    LovestoryHeader,
];


export * from './lovestory/lovestory';
