import {
    Component,
    OnInit,
} from '@angular/core';
import { LovestoryListItem } from 'BKModels';
import { LovestoryDataSource } from '../../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../../service/data-source-factory';
import { LovestoryService } from '../../../../../../service/lovestory.service';

@Component({
               selector:  'lovestory',
               template: `
                             <subheader-arrow [title]="'LOVESTORY.TITLE' | translate" class="is-hidden-tablet"></subheader-arrow>
                             <div class="lovestory__list">
                                 <lovestory-description class="lovestory__list__description"></lovestory-description>

                                 <masonry [useImagesLoaded]="true">
                                     <list-container [dataSource]="listDataSource">
                                         <ng-template let-item>
                                             <masonry-brick class="lovestory__list__col">
                                                 <div class="lovestory__list__col__item">
                                                     <lovestory-item [item]="item.item"></lovestory-item>
                                                 </div>
                                             </masonry-brick>
                                         </ng-template>
                                     </list-container>
                                 </masonry>
                             </div>

                             <lovestory-modal></lovestory-modal>
                         `,
               styles: [require('./lovestory.scss')],
           })
export class Lovestory implements OnInit {
    private listDataSource: LovestoryDataSource;

    public constructor(private lovestoryService: LovestoryService, private dataSourceFactory: DataSourceFactory) {
    }

    public ngOnInit() {
        this.dataSourceFactory
            .createLovestoryDataSource()
            .then(dataSource => {
                this.listDataSource = dataSource;
                this.listDataSource.loadMore();
            });
    }

    private get isLoading(): boolean {
        return !!this.listDataSource || this.listDataSource.isLoading;
    }

    protected createItems(val: any[]): LovestoryListItem[] {
        return val.map(LovestoryListItem.create);
    }
}
