import { Observable } from 'rxjs';
/**
 * Aufbau der antwort der Api
 */
import { ErrorNumber } from './errors';


/**
 * Interface if Options object for ApiRequest Calls
 */
export interface IApiRequestOptions {
    body?: KeyValue;
    endpointValue?: KeyValue;
    observable?: Observable<any>;
    query?: KeyValue;
    json?: boolean
}

export class ApiRequestOptions implements IApiRequestOptions {
    public body: KeyValue = {};
    public endpointValue: KeyValue = {};
    public observable: Observable<any> = new Observable(() => {/* do nothing*/});
    public query: KeyValue = {};
    public json = false;


    public static create(options: IApiRequestOptions) {
        const newOptions = new ApiRequestOptions();
        newOptions.body = options.body || {};
        newOptions.endpointValue = options.endpointValue || {};
        newOptions.query = options.query || {};
        newOptions.observable = options.observable || new Observable(() => {/* do nothing*/});
        newOptions.json = options.json || false;

        return newOptions;
    }
}


export class ApiResponseFrame<T> {
    /**
     * Payload
     {T}
     */
    public data: T;

    /**
     * Fehlernumber
     {number}
     */
    public errno: ErrorNumber;

    /**
     * Errorflag
     {boolean}
     */
    public error: boolean;

    /**
     * Fehlerbeschreibung
     {string[]}
     */
        // eslint-disable-next-line camelcase
    public error_description: string[];

    public constructor(val) {
        this.error = false;
        this.errno = 0;
        this.error_description = [];
        this.data = val;
    }
}

export type ApiResponsePromise<T> = Promise<ApiResponseFrame<T>>;
export type ApiResponse<T> = Observable<ApiResponseFrame<T>>;
