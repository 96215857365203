import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector:  'premium-flag',
               template:  `
                              <div class="content">
                                  {{discount}}% {{'PREMIUM_LANDINGPAGE.OFFER_BOX.SAVINGS' | translate}}
                              </div>
                              <div class="right"></div>
                          `,
               styles: [require('./premium-flag.scss')],
           })
export class PremiumFlag {
    @Input() private discount: number;
}