import { Injectable } from '@angular/core';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import {
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';
import { ListVariant } from './api/api.service';

@Injectable({ providedIn: 'root' })
export class ListService {
    public constructor(private apiService: ApiService,) {
    }

    //@Cacheable(CacheKey.SelectedUser)
    public selectedUser(limit: number, offset: number = 0, premium: boolean = false): Promise<ApiList> {
        return this.apiService.lists.selectedUsers(limit, offset, premium);
    }


    @Cacheable(CacheKey.ProfileVisitors)
    public profileVisitors(userInitiated: boolean, limit: number, offset: number = 0, variant: ListVariant = ListVariant.FirstPictureNotBlur, filtered: boolean = true): Promise<ProfileInfo[]> {
        return this.apiService.lists.profileVisitor(userInitiated, limit, offset, variant, filtered);
    }

    public profilesVisited(limit: number, offset: number = 0, filtered: boolean = true): Promise<ProfileInfo[]> {
        return this.apiService.lists.profilesVisited(limit, offset, filtered);
    }

    @Cacheable(CacheKey.RealUser)
    public realUser(currentUser: CurrentUser, limit: number, offset: number) {
        return this.apiService.lists.real(currentUser.searchGender, currentUser.gender, currentUser.ageFrom, currentUser.ageTill, limit, offset);
    }

    @Cacheable(CacheKey.NearBy)
    public userNearBy(limit: number, offset: number) {
        return  this.apiService.lists.nearBy(limit, offset);
    }

    @Cacheable(CacheKey.LatestUser)
    public latestUser(limit: number, offset: number) {
        return this.apiService.lists.latestUser(limit, offset);
    }

    public activeUsers(limit: number, offset: number = 0): Promise<ProfileInfo[]> {
        return this.apiService.lists.activeUsers(limit, offset);
    }
}

export interface ApiList {
    result: ProfileInfo[];
    resultCount: number;
    searchOriginCoordinates: Coordinates;
}

export interface Coordinates {
    longitude: number;
    latitude: number;
}
