/**
 * Created by olifra on 21.06.2017.
 */
import {
    Component,
    Input,
} from '@angular/core';

/**
 * Komponete zur Darstellung des Sicherheit des Passworts
 */
@Component({
               selector: 'password-strength',
               template: `
        <div *ngIf="strengthMsg() !== null"
             [class.password-strength--bad]="points < 3"
             [class.password-strength--ok]="points == 3 || points == 4"
             [class.password-strength--good]="points > 4"
        >
            {{strengthMsg() | translate}}
        </div>`,
               styles: [require('./password-strength.scss')],
           })
export class PasswordStrength {
    private points: number = 0;

    @Input()
    private set password(val: string) {
        this.points = this.passwordStrength(val);
    }

    private getDifferentCharacter(val: string): string[] {
        return val.split('')
                  .reduce((res: string[], cur: string): string[] => {
                      if (!res.includes(cur)) res.push(cur);
                      return res;
                  }, []);
    }

    private passwordStrength(val): number {
        if (val.length < 4) return 0;

        const lowerCase: boolean = /[a-z]+/.test(val);
        const upperCase: boolean = /[A-Z]+/.test(val);
        const numbers: boolean = /[0-9]+/.test(val);
        const symbols: boolean = /[^a-zA-Z0-9]+/.test(val);
        const onlyNumbers: boolean = val.length === val.replace(/[^0-9]/g, '').length;
        const differentChar: string[] = this.getDifferentCharacter(val);

        const good: boolean[] = [
            lowerCase,
            upperCase,
            numbers,
            symbols,
            differentChar.length > 4,
            val.length > 7,
        ];
        const bad: boolean[] = [onlyNumbers, differentChar.length < 2];
        let points: number = good.reduce((res: number, cur: boolean): number => (cur ? res + 1 : res), 0);
        points = bad.reduce((res: number, cur: boolean): number => (cur ? res - 1 : res), points);

        return points;
    }

    private strengthMsg(): string {
        switch (this.points) {
            case 1:
                return 'PASSWORD_STRENGTH.VERY_INSECURE';
            case 2:
                return 'PASSWORD_STRENGTH.INSECURE';
            case 3:
                return 'PASSWORD_STRENGTH.RELATIVE_SECURE';
            case 4:
                return 'PASSWORD_STRENGTH.SECURE';
            case 5:
                return 'PASSWORD_STRENGTH.VERY_SECURE';
            case 6:
                return 'PASSWORD_STRENGTH.BEST_SECURE';
            default:
                return '';
        }
    }
}
