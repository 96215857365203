/**
 * Created by olifra on 14.06.2017.
 */
import { HttpClient } from '@angular/common/http';
import {
    MissingTranslationHandler,
    MissingTranslationHandlerParams,
    TranslateDefaultParser,
    TranslateLoader,
    TranslateParser,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BkConfig } from 'BKConfig';
import {
    isDevMode,
    Logger,
} from 'BKUtils';

/**
 * Translation Logger
 {ILogger}
 */

/**
 * TranslationLoaderFactory
 {HttpClient} http
 {TranslateHttpLoader}
 * @constructor
 */
export function TranslationLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, BkConfig.translationConfig.prefix, BkConfig.translationConfig.suffix);
}

/**
 * Custom Handler für fehlende Übersetzungen
 */
export class BkMissingTranslationHandler implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams) {
        //if(isDevMode && params.key.toUpperCase() === params.key) Logger.warn(`Missing translation for ${params.key}`);
        return params.key;
    }
}

/**
 * Custom Parser für das TranslationTool
 */
export class BKTranslationParser extends TranslateDefaultParser {
    public getValue(target: any, key: string): string {
        const result: string = super.getValue(target, key);
        if (result && result.length > 0) {
            return result;
        }
        return undefined;
    }
}

/**
 * private translation config object
 * @private
 */
const _translationConfig: any = {
    parser:                    {
        provide:  TranslateParser,
        useClass: BKTranslationParser,
    },
    missingTranslationHandler: {
        provide:  MissingTranslationHandler,
        useClass: BkMissingTranslationHandler,
    },
};

if ((document as any).translate) {
    Logger.debug('Translator Version');
} else {
    _translationConfig.loader = {
        provide:    TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps:       [HttpClient],
    };
}

/**
 * translation config object
 */
export const translationConfig = _translationConfig;
