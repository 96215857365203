import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCMenu } from '@material/menu/component';
import { getScrollContainer } from 'BKUtils';

@Component({
               selector:  'menu',
               template:  `

                              <div class="mdc-menu-surface--anchor">
                                  <icon class="mat-menu menu__icon" [icon]="icon" (click)="toggle()"></icon>
                                  <div #menu class="mdc-menu mdc-menu-surface menu__list"
                                       tabindex="-1"
                                       [class.menu--left]="directionX == 'left'"
                                       [class.menu--right]="directionX == 'right'"
                                       [class.menu--top]="directionY == 'top'"
                                       [class.menu--bottom]="directionY == 'bottom'"
                                  >
                                      <ul class="mdc-list" role="menu" aria-hidden="true" aria-orientation="vertical" tabindex="-1">
                                          <ng-content></ng-content>
                                      </ul>
                                  </div>
                              </div>
                          `,
               styles: [require('./menu.scss')],
           })

export class Menu implements OnInit, AfterViewInit, OnDestroy {
    @Input() icon = 'more-vert';
    @Input() private anchored: boolean = false;
    @Input() directionX: 'left' | 'right' | '' = '';
    @Input() directionY: 'top' | 'bottom' | '' = '';
    @Output() onOpen = new EventEmitter();
    @Output() onClose = new EventEmitter();
    private menu: MDCMenu;
    @ViewChild('menu', { static: true }) private menuRef: ElementRef;

    private closeIfOpen = () => {
        if (this.menu.open) this.close();
    };

    public toggle() {
        if (this.menu.open) {
            this.close();
        } else {
            this.open();
        }
    }

    public close() {
        this.menu.open = false;
        this.onClose.emit();
    }

    public ngOnInit() {
        getScrollContainer().addEventListener('scroll', this.closeIfOpen);
    }

    public ngAfterViewInit() {
        this.menu = new MDCMenu(this.menuRef.nativeElement);
        this.menu.quickOpen = true;
    }

    public ngOnDestroy(): void {
        this.menuRef.nativeElement.remove();
        getScrollContainer().removeEventListener('scroll', this.closeIfOpen);
    }

    public open() {
        this.menu.open = true;
        this.onOpen.emit();
    }
}
