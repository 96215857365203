import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { FavoriteModule } from '../favorite/favorite.module';
import { InternComponentsModule } from '../intern-components';
import { ProfileVisitorsModule } from '../profile-visitors';
import { ContactsTabbar } from './contacts-tabbar/contacts-tabbar';
import { Contacts } from './contacts/contacts';
import { ContactsHeader } from './contacts-header/contacts-header';
import { ContactsRouting } from './contacts.routing';

@NgModule({
              imports:      [ShareModule, ContactsRouting, InternComponentsModule, FavoriteModule, ProfileVisitorsModule],
              exports:      [],
              declarations: [Contacts, ContactsHeader, ContactsTabbar],
              providers:    [],
          })
export class ContactsModule {
}
