import {
    Component,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';


/**
 * Items der Seitennavigation
 */
@Component({
               selector: 'side-nav-footer',
               template: `

                   <div #footer class="side-nav-footer">
                       <ul class="side-nav-footer__list">
                           <li class="side-nav-footer__list__item">
                               <a href="emptyLink">
                                   {{'SIDEBAR.FOOTER.ABOUT'|translate}}
                               </a>
                           </li>
                           <li class="side-nav-footer__list__item">
                               <a href="https://www.bildkontakte.de/app/agb/"
                                  target="_blank"
                               >
                                   {{'SIDEBAR.FOOTER.AGB'|translate}}
                               </a>
                           </li>
                           <li class="side-nav-footer__list__item">
                               <a [routerLink]="navigationService.getRoute(Routes.CancelContracts)" target="_blank">
                                   {{'FOOTER.CANCEL_CONTRACTS'|translate}}
                               </a>
                           </li >
                           <li class="side-nav-footer__list__item side-nav-footer__list__item--has-two">
                               <a href="https://www.bildkontakte.de/app/privacy/"
                                  target="_blank"
                               >
                                   {{'FOOTER_INTERN.NAVIGATION.DATA_PROTECTION'|translate}}
                               </a>
                               <a href="https://www.bildkontakte.de/pops/impressum.php"
                                  target="_blank"
                               >
                                   {{'FOOTER_INTERN.NAVIGATION.IMPRESSUM'|translate}}
                               </a>
                           </li>
                       </ul>

                       <p class="side-nav-footer__copyright">{{version}} &copy;{{currentYear}} {{'BILDKONTAKTE.BK'|translate}}</p>
                   </div>
               `,
               styles: [require('./side-nav-footer.scss')],
           })
export class SideNavFooter {
    Routes = Routes;

    private currentYear: number = new Date().getFullYear();

    @ViewChild('footer', { static: true }) private footerRef: ElementRef;

    public get height(): string {
        return window.getComputedStyle(this.footerRef.nativeElement).height;
    }

    private get version() {
        return BkConfig.version;
    }

    constructor(
        private navigationService: NavigationService
    ) {}
}
