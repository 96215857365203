import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    SingleEvent,
    SingleEventType,
} from '../models/single-event';
import {
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';


@Injectable({ providedIn: 'root' })
export class SingleEventService {

    public constructor(private apiService: ApiService) {
    }

    @Cacheable(CacheKey.SingleEventList)
    public list(limit: number, offset?: number): Promise<SingleEvent[]> {
        return this.apiService.singleEvents.list(limit, offset).then(res => res.data.result.map(event => SingleEvent.create(event)));
    }
}
