/**
 * Created by olifra on 10.07.2017.
 */


import {
    AfterViewChecked,
    Component,
    HostListener,
} from '@angular/core';

/**
 * Komponete für Subheader
 */
@Component({
               selector:  'subheader',
               template:  `
                              <div class="subheader" id="subheader">
                                  <div class="subheader__content">
                                      <ng-content></ng-content>
                                  </div>
                              </div>
                              <div class="subheader-distance"
                                   id="subheader-distance"
                              ></div>
                          `,
               styles: [require('./subheader.scss')],
           })
export class Subheader implements AfterViewChecked {
    public static _getHeight(): number {
        const subheader = document.getElementById('subheader');
        if (subheader) {
            const { display } = window.getComputedStyle(subheader.parentElement);
            if (display === 'none') return 0;

            return subheader.clientHeight;
        }
        return 0;
    }

    public static _setHeight() {
        const height = Subheader._getHeight();
        const distance = document.getElementById('subheader-distance');
        if (distance) distance.style.height = `${height}px`;
    }

    public getHeight(): number {
        return Subheader._getHeight();
    }

    public ngAfterViewChecked(): void {
        Subheader._setHeight();
    }

    public setHeight() {
        Subheader._setHeight();
    }

    @HostListener('window:resize')
    private resize() {
        Subheader._setHeight();
    }
}
