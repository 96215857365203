import {
    Component,
    OnInit,
} from '@angular/core';
import {
    DetailedConversationUnreadCounts,
} from 'BKModels';
import {
    ApiService,
} from 'BKService';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { PreviousRouteService } from '../../../../../service/previous-route.service';
import { PostboxState } from '../po-box-list/po-box-list';

@Component({
               selector:  'po-box',
               template: `
                             <div class="po-box">
                                 <subheader class="is-hidden-tablet">
                                     <div class="po-box__subheader">
                                         <div class="title-bar">
                                             <div class="title">{{ 'LAYOUT.SIDE_NAV.MESSAGES'|translate }}</div>
                                             <separator thickness="1px"></separator>
                                         </div>
                                         <po-box-header-tabbar [counts]="this.conversationCounts"></po-box-header-tabbar>
                                     </div>
                                 </subheader>
                                 <div class="po-box__list">
                                     <router-outlet></router-outlet>
                                 </div>
                             </div>
                         `,
               styles: [require('./po-box.scss')],

           })

export class PoBox implements OnInit {
    conversationCounts: DetailedConversationUnreadCounts = new DetailedConversationUnreadCounts();

    constructor(
        private navigationService: NavigationService,
        private previousRoute: PreviousRouteService,
        private apiService: ApiService,
    ) {
    }

    ngOnInit() {
        if (!this.returningFromConversation()) {
            PostboxState.previousState.reset();
        }
        this.apiService.conversation.fetchDetailedConversationCounts().then(counts => this.conversationCounts = counts);
    }

    returningFromConversation(): boolean {
        const conversationRoute = this.navigationService.getRoute(Routes.MessagesConversation, { [RouteParameter.ConversationId]: '' });
        return this.previousRoute.previousUrl.indexOf(conversationRoute[0]) !== -1;
    }

}
