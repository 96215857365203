/**
 * Created by olifra on 10.05.2017.
 */
import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResetState } from 'BKModels';
import { openRegistration } from 'BKUtils';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector: 'bk-password-recovery',
               template: `
                             <bk-header-out>
                                 <div class="header-right">
                                     {{'GLOBAL.NEW' | translate }} <a bk-link (click)="registrationClick()"
                                 > {{'GLOBAL.REGISTER_NOW' |
                                         translate
                                     }}</a>
                                 </div>
                             </bk-header-out>


                             <bk-section-out>
                                 <div class="bk-reset-password">
                                     <bk-form [active]="resetState.changePasswordToken == actState"
                                              [first]="true"
                                     >
                                         <password-recovery (setState)="setState($event)"
                                                            [token]="token"
                                         ></password-recovery>
                                     </bk-form>

                                     <bk-form [active]="resetState.changeSuccessfull == actState">
                                         <reset-change-sucessful></reset-change-sucessful>
                                     </bk-form>

                                     <bk-form [active]="resetState.invalidLink == actState">
                                         <bk-invalid-link></bk-invalid-link>
                                     </bk-form>
                                 </div>


                             </bk-section-out>

                             <bk-footer-out></bk-footer-out>
                         `,
               styles: [require('./password-recovery.scss')],
           })
export class PasswordRecovery implements OnInit {
    private resetState: any = ResetState;

    private actState: ResetState = this.resetState.changePasswordToken;

    private token: string = '';

    public constructor(
        private route: ActivatedRoute,
        private navigationService: NavigationService,
    ) {
        this.resetState = ResetState;
    }

    public ngOnInit(): void {
        this.token = this.route.snapshot.params.token;
    }

    private registrationClick() {
        openRegistration();
    }

    private setState(event: any) {
        this.actState = event;
    }
}
