import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ModalServiceUrl } from 'BKBaseClass';
import { ProfileInfo } from 'BKModels';
import { BehaviorSubject } from 'rxjs';
import {
    NavigationService,
    Routes,
} from '../../navigation.service';
import { ProfileService } from '../../profile.service';

export enum LoadingState {
    Uninitialized,
    Finished,
    Error,
}

export class ProfileViewModal extends ModalServiceUrl {
    public selectedUser: BehaviorSubject<ProfileInfo> = new BehaviorSubject(new ProfileInfo());
    public loadingState: BehaviorSubject<LoadingState> = new BehaviorSubject<LoadingState>(LoadingState.Uninitialized);

    protected param: string = 'profileView';

    public constructor(
        navigationService: NavigationService,
        activeRoute: ActivatedRoute,
        location: Location,
        private profileService: ProfileService,
    ) {
        super(navigationService, activeRoute, location);
    }

    public open(id: number) {
        super.open(id.toString());
        this.selectedUser.next(null);
        this.loadingState.next(LoadingState.Uninitialized);
        if (this.openState.getValue()) {
            this.profileService
                .getUserProfile(id)
                .then((profile) => {
                    this.selectedUser.next(profile);
                    this.loadingState.next(LoadingState.Finished);
                }).catch((_ => {
                    this.loadingState.next(LoadingState.Error);
            }));
        }
    }


    public openOnStartpage(id: number) {
        this.navigationService.navigateTo(Routes.Startpage).then(() => this.open(id));
    }
}
