/**
 * Created by olifra on 04.05.2017.
 */

import {
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

/**
 * Base-klasse um eine Komponente an einen Form-Controller zu binden
 * und die den die Werte in das Controller zu schreiben
 */
export class ValueAccessorBase<T> implements ControlValueAccessor {
    protected changed: Array<(value: T) => void> = [];
    @Input() protected disabled: boolean = false;
    protected innerValue: T;
    protected touched: Array<() => void> = [];
    @Output() protected valueChange: EventEmitter<T> = new EventEmitter();

    @Input()
    public get value(): T {
        return this.innerValue;
    }

    public set value(value: T) {
        this.writeValue(value);
    }

    public registerOnChange(fn: (value: T) => void) {
        this.changed
            .push(fn);
    }


    public registerOnTouched(fn: () => void) {
        this.touched
            .push(fn);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public touch() {
        this.touched
            .forEach(f => f());
    }


    public writeValue(value: T) {
        if (this.innerValue !== value) {
            this.innerValue = value;
            this.valueChange.emit(value);
            this.changed.forEach(f => f(value));
        }
    }
}

export function getValueAccessorProvider(component: any): any {
    return {
        provide: NG_VALUE_ACCESSOR,
        useExisting: component,
        multi: true,
    };
}
