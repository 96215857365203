import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ImageSize,
    Online,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import {
    ModalService,
} from 'BKService';

@Component({
               selector: 'list-preview-list-user-item',
               template: `
                              <div
                                      class="list-item"
                                      [class.list-item--basis]="user.isBasisView()"
                              >
                                  <div class="mdc-card__primary-action" tabindex="0" (click)="openProfileView()">
                                      <div class="mdc-card__media mdc-card__media--16-9 user-image"
                                           [ngStyle]="{'background-image' : imageUrl}"
                                      ><icon *ngIf="user.matchingCriteria" class="loyalty" icon="loyalty"></icon></div>

                                      <div class="info">
                                          <online-state *ngIf="user.hasOnlineState && !user.isOffline"
                                                        type="circle"
                                                        class="online-state"
                                                        [state]="onlineState"
                                          ></online-state>
                                          
                                          <div class="user-info">
                                              <div class="name-and-age">
                                                  <span class="name">{{user.nickname}}</span>
                                                  <span class="age">{{user.age}}</span>
                                              </div>
                                              <div class="city">{{user.city}}</div>
                                          </div>
                                      </div>

                                  </div>
                              </div>
                          `,
               styles: [require('./list-preview-list-user-item.scss')],
           })
export class ListPreviewListUserItem {
    @Input() private user: UserBaseView = new UserBaseView();
    @Output() private onOpenProfile = new EventEmitter();
    @Output() private gotoPremium = new EventEmitter()

    private get imageUrl(): string {
        return `url(${this.user.getProfilePictureUrl(ImageSize['530x530'])}`;
    }

    private get profileInfo(): ProfileInfo {
        return <ProfileInfo>this.user;
    }

    private get onlineState(): number {
        return this.profileInfo.online;
    }

    private get isOffline(): boolean {
        return this.onlineState === Online.Offline;
    }

    private get isVerified(): boolean {
        return this.user.isVerified;
    }

    private get hasVerifiedState(): boolean {
        return !this.user.isBasisView();
    }

    public constructor(
        private modalService: ModalService
    ) {
    }

    private openProfileView() {
        if (this.user.isBasisView()) {
            this.toPremium();
            return;
        }

        this.onOpenProfile.emit();
        this.modalService.profileView.open(this.user.id);
    }

    private toPremium() {
        this.gotoPremium.emit();
    }
}
