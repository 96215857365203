import {
    BehaviorSubject,
    Observable,
} from 'rxjs';

export class CommunicationBase<T> {
    private subject: BehaviorSubject<T[]> = new BehaviorSubject([]);

    public get get(): Observable<T[]> {
        return this.subject.asObservable();
    }

    public add(val: T) {
        const curArr = this.subject.getValue();
        curArr.push(val);
        this.subject.next(curArr);
    }

    public remove(id: number) {
        const curArray = this.subject.getValue();
        curArray.splice(id, 1);
        this.subject.next(curArray);
    }
}
