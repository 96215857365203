import {
    Notification,
    NotificationStyle,
    NotificationType,
} from './Notification';

export class PlayStorePaymentHoldNotification extends Notification {
    public notificationId = '8028bf2d-4bf4-4fb8-b9e2-5c631cb34ea1';

    public types = NotificationType.StartPage;
    public style = NotificationStyle.Error;
    public importance = 1000;

    public contentKey = 'NOTIFICATION.PLAYSTORE_PAYMENT_HOLD.TEXT';
    public titleKey = 'NOTIFICATION.PLAYSTORE_PAYMENT_HOLD.TITLE';

    public foldable = false;
}
