import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import {
    NavigationEnd,
    Router,
} from '@angular/router';
import { MDCTabBar } from '@material/tab-bar';
import { Subject } from 'rxjs';
import {
    filter,
    takeUntil,
} from 'rxjs/operators';
import { NavigationService } from '../../../service/navigation.service';
import { Tab } from './tab';

@Component({
               selector:  'tabs',
               template:  `

                              <div class="mdc-tab-bar" #tabbar role="tablist">
                                  <div class="mdc-tab-scroller" #tabbarscroller>
                                      <div class="mdc-tab-scroller__scroll-area tab-scroller" style="margin-bottom: 0px;">
                                          <div class="mdc-tab-scroller__scroll-content tab-scroller__content">
                                              <button *ngFor="let tab of tabs"
                                                      class="mdc-tab"
                                                      [class.mdc-tab--hidden]="tab.hidden"
                                                      [class.mdc-tab--min-width]="fitContent"
                                                      role="tab"
                                                      tabindex="0"
                                                      (click)="tab.onClick.emit($event)"
                                              >
                                                  <span class="mdc-tab__content">
                                                    <span class="mdc-tab__text-label">{{tab.title}}</span>
                                                    <span class="badge" *ngIf="tab.badges > 0">
                                                      {{tab.badges}}
                                                    </span>
                                                    <span class="filter-count" *ngIf="tab.filterCount">
                                                        ({{tab.filterCount}})
                                                    </span>
                                                  </span>
                                                  <span class="mdc-tab-indicator">
                                                    <span class="mdc-tab-indicator__content mdc-tab-indicator__content--underline"></span>
                                                </span>
                                                  <span class="mdc-tab__ripple"></span>
                                              </button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="tab-content" *ngIf="!tabsOnly">
                                  <ng-content></ng-content>
                              </div>
                          `,
               styles: [require('./tabs.scss')],
           })

export class Tabs implements OnInit, AfterViewInit, OnDestroy {
    private unsubscribe$ = new Subject();

    @Input() private disableRouting: boolean = false;
    @Input() private activeFirst: boolean = false;
    @Input() private activeRouting: boolean = false;
    @Input() private tabsOnly: boolean = false;
    @Input() private fitContent: boolean = false;
    @Output() private change = new EventEmitter();

    @ViewChild('tabbar', { static: true }) private tabbarRef: ElementRef;
    private tabbar: MDCTabBar;
    @ViewChild('tabbarscroller', { static: true }) private tabbarScrollerRef: ElementRef;

    @ContentChildren(Tab) private _tabs: QueryList<Tab>;
    private get tabs(): Tab[] {
        return this._tabs.toArray();
    }


    public constructor(private router: Router, private navigationService: NavigationService) {
    }

    public ngOnInit() {
        if (this.tabsOnly) {
            this.router
                .events
                .pipe(
                    filter(event => event instanceof NavigationEnd),
                    takeUntil(this.unsubscribe$),
                )
                .subscribe((val: NavigationEnd) => {
                    this.setActiveRouteForUrl();
                });
        }
    }

    public ngAfterViewInit() {
        this.tabbar = new MDCTabBar(this.tabbarRef.nativeElement);

        setTimeout(() => {
            if (this.activeFirst) {
                const index = this.tabs.findIndex(cur => !cur.hidden);
                this.setTabActive(index);
            } else if (this.activeRouting) {
                this.setActiveRouteForUrl();
            }
        });

        this.tabbar.listen('MDCTabBar:activated', (it: any) => {
            const index = it.detail.index;
            this.setTabActive(index);

            if (this.tabsOnly && !this.disableRouting) {
                this.navigationService.navigateTo(this.tabs[index].route);
            }
            this.change.emit(index);
        });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private setActiveRouteForUrl() {
        const index = this.tabs.findIndex(it => this.navigationService.isRouteActive(it.route));
        if (index !== this.activeTabIndex) {
            this.setTabActive(index);
            this.tabs[index].onClick.emit();
        }
    }

    public setTabActive(index) {
        this.tabbar.activateTab(index);
        this.tabs.forEach(it => it.active = false);
        this.tabs[index].active = true;
    }

    public open(id: string) {
        this.change.emit(id);
        const index = this.tabs.findIndex(it => it.id === id);
        this.tabbar.activateTab(index);
    }

    public get activeTab(): string {
        return this.tabs.find(it => it.active).id;
    }

    public get activeTabIndex(): number {
        return this.tabs.findIndex(value => value.active);
    }
}
