import {
    Component,
    ViewChild,
} from '@angular/core';
import { FeatureExplainDialog } from '../../shared/mat-design/dialog/feature-explain-dialog';



/**
 * Eine Testkomponente
 */
@Component({
               selector:  'test',
               template:  `
                              <feature-explain-dialog title="Jetzt wird es spanned!" 
                                                      [text]="text | markdown"
                                                      [img]="'./assets/img/matching/matching-tutorial-2-basis.svg'"
                              > </feature-explain-dialog>
                          
                                <raised-button (click)="dialog.open()" >Test</raised-button>
                          `,
               styles: [
                   require('./test.style.scss'),
               ],
           })
export class TestComponent {
    @ViewChild(FeatureExplainDialog, { static: true }) private dialog: FeatureExplainDialog;

    text = "Achte gut auf das Herz-Symbol auf der Matching-Karte.\n\n Wenn die beiden Herzen grün ausgefüllt sind, hat er bereits „ja“ gesagt und zeigt Interesse an dir."


}
