import {
    AfterViewInit,
    Component,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';
import { Relationship } from 'BKModels';
import { CountrySelect } from '../../../../../../shared/mat-design/country-select/country-select';
import { Geolocation } from '../../../../../../shared/mat-design/geolocation/geolocation';
import { ProfileEditRow } from '../../profile-edit-row/profile-edit-row';
import { maxLengthMap } from '../../profile-edit/profile-edit';
import { ProfileEditBoxBase } from '../profile-edit-box/profile-edit-box-base';


@Component({
               selector: 'profile-edit-main',
               template: `
                             <profile-edit-box [title]="'PROFILE-EDIT.TITLE.MAINDATA'|translate"
                                               [group]="group"
                                               (save)="save($event)"
                                               (reset)="reset($event)"
                             >
                                 <form [formGroup]="group">
                                     <profile-edit-row #nicknameRow
                                                       (click)="nicknameRow.showInfo()"
                                                       [info]="'PROFILE_EDIT.NICKNAME.CHANGE_INFO' | translate | markdown"
                                     >
                                         <text-field [placeholder]="'PROFILE_INFO.NICKNAME' | translate"
                                                     formControlName="nickname"
                                         ></text-field>
                                     </profile-edit-row>
                                     
                                     <profile-edit-email [maxLength]="maxLengthMap.email"
                                     ></profile-edit-email>

                                     <profile-edit-row #birthdayRow
                                                       (click)="birthdayRow.showInfo()"
                                                       [info]="'PROFILE_EDIT.BIRTH_DATE.CHANGE_INFO' | translate | markdown"
                                                       [label]="'PROFILE_INFO.BIRTH_DATE' | translate"
                                     >
                                         <date-picker
                                                 formControlName="birthdate"
                                                 [disabled]="true"
                                         ></date-picker>

                                     </profile-edit-row>

                                     <profile-edit-row>
                                         <dropdown [placeholder]="'PROFILE_INFO.RELATIONSHIP.LABEL' | translate"
                                                   formControlName="relationship"
                                         >
                                             <ng-container *ngFor="let key of relationship">
                                                 <dropdown-option [value]="key"
                                                                  [selected]="key == getGroupValue('relationship')"
                                                                  [hidden]="key == 0"
                                                 >
                                                     {{'PROFILE_INFO.RELATIONSHIP.OPTIONS.' + key  | translate }}
                                                 </dropdown-option>
                                             </ng-container>
                                         </dropdown>
                                     </profile-edit-row>

                                     <profile-edit-row>
                                         <country-select #profileEditCountry formControlName="country"
                                                         [value]="group.controls.country.value"
                                         ></country-select>
                                     </profile-edit-row>

                                     <profile-edit-row last>
                                         <geolocation #profileEditGeolocationCity formControlName="city"
                                                      [country]="this.group.controls.country.value"
                                                      (focusin)="focusInput.emit($event)"
                                         ></geolocation>
                                     </profile-edit-row>
                                 </form>
                             </profile-edit-box>
                         `,
           })

export class ProfileEditMain extends ProfileEditBoxBase implements AfterViewInit {
    @ViewChild('profileEditGeolocationCity', { static: true }) private cityField: Geolocation;
    @ViewChild('profileEditCountry', { static: true }) private countryField: CountrySelect;
    @ViewChild('nicknameRow', { static: true }) private nicknameRow: ProfileEditRow;
    @ViewChild('birthdayRow', { static: true }) private birthdayRow: ProfileEditRow;
    @Output() private focusInput = new EventEmitter();

    private maxLengthMap = maxLengthMap;

    private relationship = [
        Relationship.Single,
        Relationship.Divorced,
        Relationship.Widowed,
        Relationship.SingleParenting,
        Relationship.Separately,
        Relationship.AskMe,
    ];


    public ngAfterViewInit() {
        this.countryField.registerOnChange(this.resetCity.bind(this));
    }

    protected onReset(event) {
        super.onReset(event);
        this.cityField.discardChanges(this.getGroupValue('city')
                                          .toString());
    }

    private resetCity() {
        this.cityField.clearTextValue();
    }
}
