import {
    Component,
    Input,
} from '@angular/core';
import {
    generateEpcString,
    growShrink,
} from 'BKUtils';

@Component({
               selector: 'epc-qr',
               template: `
                             <qrcode errorCorrectionLevel="M" [qrdata]="data" elementType="svg" [width]="width"></qrcode>
                         `,
               animations: [growShrink],
           })
export class EpcQr {
    @Input() value: string;
    @Input() recipient: string;
    @Input() iban: string;
    @Input() bic: string = '';
    @Input() reference: string = '';
    @Input() isCreditorReference: boolean = false;
    @Input() info: string = '';
    @Input() width: number = 256;

    get data(): string {
        return generateEpcString(
            this.value,
            this.recipient,
            this.iban,
            this.bic,
            this.reference,
            this.isCreditorReference,
            this.info
        );
    }
}
