import { Component } from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';

/**
 * MatchingPlaceholderComponent
 */
@Component({
               selector:  'matching-placeholder-queue-empty',
               template:  `
                              <card class="bk-matching-placeholder">
                                  <list-empty [title]="'MATCHING.QUEUE_EMPTY.TITLE' | translate"
                                              [text]="'MATCHING.QUEUE_EMPTY.TEXT' | translate"
                                  >
                                      <raised-button fit-content
                                                     class="bk-matching-placeholder__button"
                                                     (click)="toProfileEditSearch()"
                                      >
                                          {{'MATCHING.QUEUE_EMPTY.BUTTON' | translate}}
                                      </raised-button>
                                  </list-empty>
                              </card>
                          `,
               styles: [
                   require('./matching-placeholder-queue-empty.scss'),
               ],
           })
export class MatchingPlaceholderQueueEmpty {

    /**
     * @param navigationService
     */
    public constructor(
        private trackingService: TrackingService,
        private navigationService: NavigationService,
    ) {
    }

    private toProfileEditSearch() {
        this.trackingService.hit('Matching', 'MatchingPlaceholderQueueEmpty', 'Klick: SucheinstellungenAnpassen');
        this.navigationService.navigateTo(Routes.MyProfile, {
            fragment: ProfileEditRouteFragment.Search,
        });
    }
}
