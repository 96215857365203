import {
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
    ApiService,
    CommunicationService,
    TrackingService,
} from 'BKService';
import { SpamReportReason } from '../../../../models/api-enums';
import { ConversationService } from '../../../../service/conversation.service';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';
import { TextArea } from '../../../../shared/mat-design/text-field/text-area';

@Component({
               selector:  'spam-report',
               template:  `
                              <dialog-box #dialog
                                          (onAccept)="sendReport()"
                                          [acceptDisabled]="acceptDisabled"
                                          [acceptText]="'POST_BOX.SPAM_REPORT.DIALOG.ACCEPT_TEXT'|translate"
                                          [rejectText]="'DIALOG.CLOSE'|translate"
                                          [title]="'POST_BOX.SPAM_REPORT.DIALOG.TITLE'|translate"
                              >
                                  <div class="spam-report">
                                      <div>{{'POST_BOX.SPAM_REPORT.DIALOG.DESCRIPTION'|translate}}</div>
                                      <radio-panel class="spam-report__reasons" [options]="options" [(value)]="reasonType"
                                      ></radio-panel>
                                      <text-area #textArea [placeholder]="'POST_BOX.SPAM_REPORT.DIALOG.REASON_TEXT' | translate"
                                                 [maxLength]="255"
                                                 [autoresize]="true"
                                                 icon="edit"
                                                 [(value)]="reason"
                                                 (valueChange)="validate()"
                                      ></text-area>
                                  </div>
                              </dialog-box>
                          `,
               styles: [require('./spam-report.scss')],
           })
export class SpamReport {

    @Input() private conversationId: string;

    @ViewChild('dialog', { static: true }) private dialog: DialogBox;
    @ViewChild('textArea', { static: true }) private textArea: TextArea;
    private options = [
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.ADVERTISMENT'),
            value: SpamReportReason.Advertising,
        },
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.RIP_OFF'),
            value: SpamReportReason.RipOff,
        },
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.WRONG_SEXUALITY'),
            value: SpamReportReason.WrongSexuality,
        },
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.CELEBRITY'),
            value: SpamReportReason.Celebrity,
        },
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.SEXUAL_ASSAULT'),
            value: SpamReportReason.SexualAssault,
        },
        {
            name:  this.translate.instant('POST_BOX.SPAM_REPORT.DIALOG.OPTIONS.OTHER'),
            value: SpamReportReason.Other,
        },
    ];
    private trackingLabels = new Map([
        [SpamReportReason.Advertising, 'BenutzerWerbung'],
        [SpamReportReason.RipOff, 'Abzocke'],
        [SpamReportReason.WrongSexuality, 'FalscheGeschlechtsangabe'],
        [SpamReportReason.Celebrity, 'PromiBild'],
        [SpamReportReason.SexualAssault, 'SexuelleNoetigung'],
        [SpamReportReason.Other, 'Sonstiges'],
    ]);

    private reasonType: SpamReportReason = SpamReportReason.Advertising;
    private reason: string = '';
    private acceptDisabled = false;

    constructor(
        private translate: TranslateService,
        private conversationService: ConversationService,
        private communicationService: CommunicationService,
        private trackingService: TrackingService) {
    }

    /**
     * @description Show spam report dialog
     */
    public show() {
        this.acceptDisabled = true;
        this.dialog.open();
    }

    private validate() {
        this.acceptDisabled = this.reason.isEmpty();
    }

    private sendReport() {
        this.trackingService.hit('Konversation', 'VerstossMelden', this.trackingLabels.get(this.reasonType));

        this.conversationService.report(this.conversationId, this.reasonType, this.reason)
            .then((response) => {
                this.communicationService.growl.positive(this.translate.instant('POST_BOX.SPAM_REPORT.SUCCESS_MESSAGE'));
            })
            .catch(() => {
                this.communicationService.growl.negative(this.translate.instant('POST_BOX.SPAM_REPORT.GENERAL_FAIL_MESSAGE'));
            });
    }
}
