import {
    routeName,
    Routes,
} from '../navigation.service';

export class NavigationRoute {
    private _route: string;

    protected get route(): string {
        return this._route;
    }

    public constructor(...routes: Routes[]) {
        this._route = routes.map(r => routeName(r)).join('/');
    }

    protected parsedRoute(urlParam) {
        let parsedRoute = this.route;
        if (urlParam) {
            Object.keys(urlParam).forEach(key => {
                parsedRoute = parsedRoute.replace(`:${key}`, urlParam[key]);
            });
        }

        return parsedRoute;
    }

    public getRouteArray(urlParam: KeyValue = null, lang: string = ''): any[] {
        return [`${this.getLanguagesPrefix(lang)}/${this.parsedRoute(urlParam)}`];
    }

    protected getLanguagesPrefix(lang: string): string {
        return lang.isEmpty() ? '' : `/${lang}`;
    }
}

export class NavigationRouteIntern extends NavigationRoute {
    public constructor(...routes: Routes[]) {
        super(...routes);
    }

    public getRouteArray(urlParam: KeyValue = null, lang: string = ''): any[] {
        return [`${this.getLanguagesPrefix(lang)}/${routeName(Routes.Intern)}/${this.parsedRoute(urlParam)}`];
    }
}
