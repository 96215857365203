/**
 * Created by olifra on 27.06.2017.
 */
import {
    ApiResponseFrame,
    FavWithNotice,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiRequestService } from '../api-request.service';

export class FavoriteApi {
    public constructor(private apiRequestService: ApiRequestService) {

    }

    public add(favoriteId: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.favorite.add, {
                       body: {
                           favoriteId,
                           notice: '',
                       }
                   });
    }


    public changeNotice(favoriteId: number, notice: string = ''): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.favorite.changeNotice, {
                       body:  {
                           favoriteId,
                           notice,
                       },
                   });
    }

    public getSingle(id: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.favorite.single, {
                       endpointValue: { id },
                   })
                   .then((res) => {
                       res.data.favorite = FavWithNotice.create(res.data.favorite);
                       return res;
                   })
    }

    public list(limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.favorite.list, {
                       query: {
                           limit,
                           offset,
                       },
                   });
    }


    public listDeleted(limit: number, offset: number = 0): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.favorite.listDeleted, {
                       query: {
                           limit,
                           offset,
                       },
                   });
    }

    public remove(favoriteId: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.favorite.delete, {
                       body:  {
                           favoriteId,
                       },
                   });
    }

    public removeMultiple(favoriteIds: number[]): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.favorite.deleteMultiple, {
                       body:  {
                           favoriteIds,
                       }
                   });
    }
}
