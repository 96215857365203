import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { QuickSearchCriteria } from 'BKModels';
import { CountryPipe } from 'BKShared';

@Component({
               selector: 'search-quick-criteria',
               template: `
                             <div class="search-quick-criteria" *ngFor="let key of quickCriteriaKeys">
                                 <chip
                                         (click)="showQuickSearch.emit(key)"
                                         [icon]="getIconName(key)"
                                 >
                                     {{getTitle(key) | translate:param}}
                                 </chip>
                             </div>
                         `,
               styles:   [`
                              .search-quick-criteria {
                                  display: inline-block;
                              }`,
               ],
           })

export class SearchQuickCriteria {
    @Input() private quickCriteria: QuickSearchCriteria;
    @Output() private showQuickSearch = new EventEmitter();

    private get quickCriteriaKeys(): string[] {
        return Object.keys(this.quickCriteria);
    }

    private getIconName(key): string {
        const iconMap = {
            'gender': 'wc',
            'ageRange': 'search-age',
            'geolocation': 'location-on',
            'country': 'language',
            'distance': 'my-location',
        };

        return iconMap[key];
    }

    private getTitle(key): string {
        switch (key) {
            case 'gender':
                return `SEARCH.CRITERIA.GENDER.${this.quickCriteria.gender.toUpperCase()}`;
            case 'ageRange':
                return 'SEARCH.CRITERIA.AGE';
            case 'geolocation':
                return this.cityTitle;
            case 'country':
                return new CountryPipe().transform(this.quickCriteria.country);
            case 'distance':
                return 'SEARCH.CRITERIA.DISTANCE';
            default:
                return key;
        }
    }

    private get param() {
        return {
            ageFrom:  this.quickCriteria.ageRange[0].toString(),
            ageTill:  this.quickCriteria.ageRange[1].toString(),
            distance: this.quickCriteria.distance.toString(),
        };
    }


    private get cityTitle() {
        const location = this.quickCriteria.geolocation;
        if (!location || !location.displayValue) return 'city';
        return location.displayValue;
    }


}
