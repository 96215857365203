import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';
import {
    ApiResponseFrame,
    Lovestory,
    LovestoryListItem,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '../api-request.service';

export class LovestoriesApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService, private translate: TranslateService) {
    }


    public get(id: string, language: string|undefined = undefined): Promise<ApiResponseFrame<Lovestory[]>> {
        let query = Object.create({});
        if (language) query.lng = language;

        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.lovestories.get, {
                       query,
                       endpointValue: {
                           id,
                           client_id: BkConfig.client.id,
                       },
                   })
                   .then((res) => {
                       res.data = res.data.map(Lovestory.create);
                       return res;
                   });
    }

    public getList(limit: number, offset: number = 0): Promise<ApiResponseFrame<LovestoryListItem[]>> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.lovestories.all, {
                       query: {
                           lng: this.lang,
                           limit,
                           offset,
                           client_id: BkConfig.client.id,
                       },
                   })
                   .then((res) => {
                       res.data = res.data.result.map(LovestoryListItem.create);
                       return res;
                   });
    }

    public send() {
        // TODO im senden auch den Growl aktivierten
    }


    private get lang() {
        let lang = this.translate.currentLang || '';
        return lang.split('-')[0];
    }
}
