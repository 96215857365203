import {
    Component,
    ViewChild,
} from '@angular/core';
import { ModalDialog } from '../../../service/global-modal.service';
import { FailedChargebackPaymentInfo } from '../../../service/premium.service';
import { DialogBox } from '../dialog/dialog-box';
import { growShrink } from 'BKUtils';
import * as moment from 'moment';

@Component({
               selector:  'bk-modal-account-disabled',
               template:  `
                              <ng-container>
                                  <dialog-box #dialog
                                              [title]="'ACCOUNT_DISABLED.TITLE'|translate"
                                              [acceptText]="'GLOBAL.CLOSE'|translate"
                                  >
                                      <div [innerHtml]="'ACCOUNT_DISABLED.TEXT'|translate:{'email':email}|inlineMarkdown"></div>
                                  </dialog-box>
                              </ng-container>
                          `,
               animations: [growShrink],
           })
export class BkModalAccountDisabled implements ModalDialog {
    @ViewChild('dialog', { 'static': true }) private dialog: DialogBox;

    static readonly typeId = 'BkModalAccountDisabled';

    email: string = '';

    public open(config) {
        this.email = config;
        this.dialog.open();
    }

    public close() {
        this.dialog.close();
    }

    public get typeId(): string {
        return BkModalAccountDisabled.typeId;
    }
}
