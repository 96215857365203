export interface Coordinates {
    latitude: number;
    longitude: number;
}

const EARTH_RADIUS_KM = 6371;

class Vector {
    public static createFromCoordinates(latitude: number, longitude: number): Vector {
        latitude = deg2rad(latitude);
        longitude = deg2rad(longitude);
        return new Vector(
            Math.cos(latitude) * Math.cos(longitude),
            Math.cos(latitude) * Math.sin(longitude),
            Math.sin(latitude),
        );
    }

    constructor(public x: number, public y: number, public z: number) {
    }

    public crossProduct(other: Vector): Vector {

        return new Vector(
            this.y * other.z - this.z * other.y,
            this.z * other.x - this.x * other.z,
            this.x * other.y - this.y * other.x,
        );
    }

    public dotProduct(other: Vector): number {

        return this.x * other.x + this.y * other.y + this.z * other.z;

    }

    public length() {
        return Math.sqrt(this.dotProduct(this));
    }
}

export const deg2rad = value =>  (value / 180) * Math.PI;


export const geodesicDistance = (p: Coordinates, q: Coordinates) =>  {
    const pVector = Vector.createFromCoordinates(p.latitude, p.longitude);
    const qVector = Vector.createFromCoordinates(q.latitude, q.longitude);
    const crossProduct = pVector.crossProduct(qVector);
    const dotProduct = pVector.dotProduct(qVector);
    const centralAngle = Math.atan2(crossProduct.length(), dotProduct);

    return centralAngle * EARTH_RADIUS_KM;
};
