/**
 * Created by olifra on 22.05.2017.
 */
import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    AccountState,
    Achievements,
    ApiResponseFrame,
    CurrentUser,
    Gender,
    Image,
    ProfileInfo,
} from 'BKModels';
import { addLogoutEventListener } from 'BKUtils';
import {
    BehaviorSubject,
    Observable,
    Subscription,
} from 'rxjs';
import { timer } from 'rxjs/internal/observable/timer';
import { ApiNotification } from '../models/api-notification';
import { BadgeCounts } from '../models/badge-counts';
import { UserCounts } from '../models/user-counts';
import { Cache } from '../utils/cache';
import { ApiService } from './api';
import { MailSettingsService } from './mail-settings.service';
import { ProfileService } from './profile.service';
import { SettingsService } from './settings.service';
import { StoreService } from './storage/store.service';
import { DataLayerService } from './tracking/dataLayer.service';
import { TrackingService } from './tracking/tracking.service';

type TrackingDimensions = {
    event: string,
    userAge: number,
    userAgeRange: string,
    userGender: string,
    userLevel: string,
    userStatus: string,
    bkutg: string,
};

/**
 * Chachedservice für den Aktuell eingeloggten Benutzer
 */
@Injectable()
export class CurrentUserService {
    private currentUserInitialized: boolean = false;
    public currentUserObservable: Observable<CurrentUser> = this.storeService.currentUser.get();

    private gallerySubject = new BehaviorSubject<Image[]>([]);
    public gallery$ = this.gallerySubject.asObservable();

    private activeCurrentUserLoadPromise: Promise<CurrentUser> = null;
    private lastCurrentUserLoadPromise: Promise<CurrentUser> = null;

    private activeNotificationsLoadPromise: Promise<ApiNotification[]> = null;
    private lastNotificationsLoadPromise: Promise<ApiNotification[]> = null;

    // TODO deprecated - switch to async
    public get cachedCurrentUser(): CurrentUser {
        return this.storeService.currentUser.getValues();
    }

    public get currentUser(): Promise<CurrentUser> {
        if (!!this.activeCurrentUserLoadPromise) return this.activeCurrentUserLoadPromise;

        if (!!this.lastCurrentUserLoadPromise) return this.lastCurrentUserLoadPromise;

        return this.loadCurrentUser();
    }

    public achievementsObservable: Observable<Achievements> = this.storeService.achievements.get();

    public achievements(): Promise<Achievements> {
        const achievements = this.storeService.achievements.getValues();
        if (!!achievements) return Promise.resolve(achievements);
        return this.refreshAchievements();
    }

    public badgeCountsObservable: Observable<BadgeCounts> = this.storeService.badgeCounts.get();

    public badgeCounts(): Promise<BadgeCounts> {
        const cachedBadgeCounts = this.storeService.badgeCounts.getValues();
        if (!!cachedBadgeCounts) return Promise.resolve(cachedBadgeCounts);
        return this.refreshBadgeCount();
    }

    private notificationsSubject = new BehaviorSubject<ApiNotification[]>([]);
    public notifications$ = this.notificationsSubject.asObservable();

    public notifications(): Promise<ApiNotification[]> {
        if (!!this.activeNotificationsLoadPromise) return this.activeNotificationsLoadPromise;
        if (!!this.lastNotificationsLoadPromise) return this.lastNotificationsLoadPromise;

        return this.loadNotifications();
    }

    private loadNotifications(): Promise<ApiNotification[]> {
        this.activeNotificationsLoadPromise = this.api.user.notifications()
                                                  .then(notifications => {
                                                      this.notificationsSubject.next(notifications);
                                                      this.lastNotificationsLoadPromise = this.activeNotificationsLoadPromise;
                                                      this.activeNotificationsLoadPromise = null;
                                                      return notifications;
                                                  });

        return this.activeNotificationsLoadPromise;
    }

    public currentUserProfileInfo: Observable<ProfileInfo> = this.storeService.currentUserProfileInfo.get();

    public get cachedProfileInfo(): ProfileInfo {
        return this.storeService.currentUserProfileInfo.getValues();
    }

    private _isLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private loading: boolean = false;

    private refreshIntervalSubscription: Subscription;
    private refreshInterval: Observable<number> = timer(0, BkConfig.refreshInterval);

    public get isLoaded(): Observable<boolean> {
        return this._isLoadedSubject.asObservable();
    }

    public changeEmail(email: string): Promise<any> {
        return this.api.user.changeEmailAddress(email)
                   .then(res => {
                       if (res.error) throw res;

                       this.changePersistedProfileInfo('email', email);
                       this.changePersistedCurrentUser('email', email);
                       return res;
                   });
    }

    public changePersistedCurrentUser(key: string, value: any): Promise<CurrentUser> {
        return this.storeService
                   .currentUser
                   .change(key, value);
    }

    public changePersistedProfileInfo(key: string, value: any): Promise<ProfileInfo> {
        return this.storeService
                   .currentUserProfileInfo
                   .change(key, value);
    }

    public changeProfileInfoBundle(profileInfo): Promise<any> {
        return this.api.user.uploadProfileInfo(profileInfo)
                   .then((result) => {
                       if (result.data.hasOwnProperty('state') && result.data.state) {
                           Object.keys(profileInfo)
                                 .forEach(key => {
                                     this.changePersistedProfileInfo(key, profileInfo[key]);
                                 });
                           this.loadCurrentUser();
                       } else {
                           throw new Error('Upload of the Profileinfo failed.');
                       }

                       return result;
                   });
    }

    public loadCurrentUser(): Promise<CurrentUser> {
        this.refreshAchievements();

        if (!this.activeCurrentUserLoadPromise) {
            this.activeCurrentUserLoadPromise = this.api
                                                    .user
                                                    .current(this.settingsService.cachedSettings.offline)
                                                    .then((res: ApiResponseFrame<any>) => {
                                                        this.currentUserInitialized = true;
                                                        return this.readCurrentUserResponse(res);
                                                    })
                                                    .catch((reason)=> {
                                                        this.activeCurrentUserLoadPromise = null;

                                                        return reason;
                                                    });
        }

        return this.activeCurrentUserLoadPromise;
    }

    private readCurrentUserResponse(response: ApiResponseFrame<any>): CurrentUser {
        const data: CurrentUser = !!response ? response.data : new CurrentUser();
        const user = CurrentUser.create(data);

        if (user.isPremium() !== this.cachedCurrentUser.isPremium()) {
            Cache.getInstance().clear();
        }

        this.storeService.currentUser.next(user);
        this.storeService.badgeCounts.next(BadgeCounts.createFromCurrentUser(user));
        this.updateTrackingDimensions(user);

        this.refreshProfileInfo(data.id);

        this.dataLayerService.userId = user.id;
        this.loading = false;

        // Reset active promise to enable loading again
        this.lastCurrentUserLoadPromise = this.activeCurrentUserLoadPromise;
        this.activeCurrentUserLoadPromise = null;

        this.refreshGallery();

        return user;
    }

    private previousTrackingDimensions: TrackingDimensions = null;
    private updateTrackingDimensions(user: CurrentUser) {
        const userAge = user.age;
        const userAgeRange = user.ageRange;
        const userGender = user.gender === Gender.Male ? 'männlich' : 'weiblich';
        const userLevel = user.premium ? 'Premium' : 'Basis';
        const userStatus = {
            [AccountState.Activated]: 'Freigeschaltet',
            [AccountState.Deactivated]: 'Gesperrt',
            [AccountState.InRegistration]: 'NichtFreigeschaltet',
            [AccountState.InRegistrationWithImage]: 'BildHochgeladen',
            [AccountState.PreActivated]: 'Vorfreigeschaltet',
            [AccountState.OpenBill]: 'Ruecklastschrift'
        } [user.accountState];
        const bkutg = user.testGroups
                          .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                          .map(g => `${g.name}-${g.group.pad(2)}`)
                          .join(',');

        const newTrackingDimensions: TrackingDimensions = { event: 'userData', userAge, userAgeRange, userGender, userLevel, userStatus, bkutg };

        if (this.previousTrackingDimensions
            && newTrackingDimensions.userAge === this.previousTrackingDimensions.userAge
            && newTrackingDimensions.userAgeRange === this.previousTrackingDimensions.userAgeRange
            && newTrackingDimensions.userGender === this.previousTrackingDimensions.userGender
            && newTrackingDimensions.userLevel === this.previousTrackingDimensions.userLevel
            && newTrackingDimensions.userStatus === this.previousTrackingDimensions.userStatus
            && newTrackingDimensions.bkutg === this.previousTrackingDimensions.bkutg
        ) {
            return;
        }

        this.trackingService.push(newTrackingDimensions);
        this.previousTrackingDimensions = newTrackingDimensions;
    }

    public get isCurrentUserInitialized(): boolean {
        return this.currentUserInitialized;
    }

    public refreshGallery() {
        this.currentUser.then(user => {
            this.api.image.getGallery(user.id).then(images => {
                const currentImages = this.gallerySubject.value;

                // Find updates
                const newImages     = images.filter(newImage => !currentImages.find(currentImage => newImage.image === currentImage.image));
                const removedImages = currentImages.filter(currentImage => !images.find(newImage => newImage.image === currentImage.image));
                const changedImages = currentImages.map(currentImage => [currentImage, images.find(newImage => newImage.image === currentImage.image)])
                                                   .filter(pair => {
                                                       const [currentImage, newImage] = pair;
                                                       return !currentImage.equals(newImage);
                                                   }).map(pair => pair[0]);

                // Skip update if no changes are detected
                if (newImages.isEmpty() && removedImages.isEmpty() && changedImages.isEmpty()) return;

                this.gallerySubject.next(images);
            });
        });
    }

    public picUploaded(): Promise<CurrentUser> {
        return this.changePersistedCurrentUser('accountState', AccountState.InRegistrationWithImage);
    }

    public playMatching() {
        const value = this.storeService.currentUser.getValues();
        this.changePersistedCurrentUser('remainingMatchingContingent', value.remainingMatchingContingent - 1);
    }

    private get intervalRun(): boolean {
        return this.refreshIntervalSubscription && !this.refreshIntervalSubscription.closed;
    }

    public startRefreshInterval() {
        if (!this.intervalRun) {
            this.refreshIntervalSubscription = this.refreshInterval.subscribe(() => {
                if (!this.loading) {
                    this.loading = true;
                    this.loadCurrentUser();
                    this.loadNotifications();
                }
            });
        }
    }

    public onLogout() {
        this.refreshIntervalSubscription.unsubscribe();
        this.gallerySubject.next([]);
        this.activeCurrentUserLoadPromise = null;
        this.lastCurrentUserLoadPromise = null;
        this.activeNotificationsLoadPromise = null;
        this.lastNotificationsLoadPromise = null;
    }

    public getCounts(): Promise<UserCounts> {
        return this.api.user.counts();
    }

    public refreshBadgeCount(): Promise<BadgeCounts> {
        return this.api
                   .user
                   .badgeCount()
                   .then((badgeCounts) => {
                       this.storeService.badgeCounts.next(badgeCounts);
                       return badgeCounts;
                   });
    }

    public refreshAchievements(): Promise<Achievements> {
        return this.api
                   .achievements
                   .list()
                   .then((achievements: Achievements) => {
                       this.storeService.achievements.next(achievements);
                       return achievements;
                   });
    }

    private refreshProfileInfo(id: number) {
        this.profileService.getUserProfile(id, true)
            .then(profileInfo => {
                this.storeService.currentUserProfileInfo.next(profileInfo);
                this._isLoadedSubject.next(true);
            });
    }

    public deleteAccount(password: string, deleteReason: number, deleteReasonText: string, recommendation: boolean): Promise<boolean> {
        return this.api.user.deleteAccount(password, deleteReason, deleteReasonText, recommendation);
    }

    public stayOnBeta(stay: boolean): Promise<void> {
        return this.api.user.stayOnBeta(stay).then(value => { return; });
    }

    public constructor(
        private api: ApiService,
        private profileService: ProfileService,
        private settingsService: SettingsService,
        private trackingService: TrackingService,
        private storeService: StoreService,
        private dataLayerService: DataLayerService
    ) {
        addLogoutEventListener(() => this.onLogout());
    }
}
