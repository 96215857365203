/**
 * Created by denjas on 07.04.2017.
 */
import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
} from '@angular/forms';
import { ApiResponseFrame } from 'BKModels';
import { ApiService } from 'BKService';
import { isEmpty } from 'BKShared/Validator';

/**
 * Validation Objekt für den Username
 */
export class UsernameValidator {
    /**
     * Regulärer Ausdruck zum Aufbau des Username
     {RegExp}
     */
    public static usernameRegex: RegExp = new RegExp(/^([a-zA-Z])+([a-zA-Z0-9]|-)+([a-zA-Z0-9])+$/i);

    public static required(control: AbstractControl): KeyValue {
        if (isEmpty(control.value)) {
            return {
                'ERROR.VALIDATOR.USERNAME.REQUIRED': true,
            };
        }

        return {};
    }

    /**
     * Check if username used
     {ApiService} api
     {AsyncValidatorFn}
     */
    public static used(api: ApiService): AsyncValidatorFn {
        return (c: AbstractControl): Promise<ValidationErrors | null> => api.validation
                                                                            .username(c.value)
                                                                            .then((response: ApiResponseFrame<any>) => {
                                                                                if (!response.error && response.data.userExists) {
                                                                                    return { 'ERROR.VALIDATOR.USERNAME.ALREADY_USED': true };
                                                                                }

                                                                                if (!response.error && response.data.usernameForbidden) {
                                                                                    return { 'ERROR.VALIDATOR.USERNAME.FORBIDDEN': true };
                                                                                }

                                                                                return null;
                                                                            });
    }

    /**
     * Überprüft den Username und gibt den den Fehler zurück
     {AbstractControl} control
     {KeyValue}
     */
    public static username(control: AbstractControl): KeyValue {
        if (isEmpty(control.value)) {
            return {
                'ERROR.VALIDATOR.USERNAME.REQUIRED': true,
            };
        }
        if (control.value.length < 3) {
            return {
                'ERROR.VALIDATOR.USERNAME.TO_SHORT': true,
            };
        }
        if (control.value.length > 15) {
            return {
                'ERROR.VALIDATOR.USERNAME.TO_LONG': true,
            };
        }

        if (control.value.includes('test')) {
            return {
                'ERROR.VALIDATOR.USERNAME.INCLUDE_TEST': true,
            };
        }
        if (!UsernameValidator.usernameRegex.test(control.value)) {
            return {
                'ERROR.VALIDATOR.USERNAME.FORBIDDEN_CHARS': true,
            };
        }
        return {};
    }
}
