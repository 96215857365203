import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({ name: 'range' })
export class RangePipe implements PipeTransform {
    transform(value: number, ...args: any[]): any {
        // return Array.apply(null, { length: value }).map(Number.call, Number);
        return Array.from(Array(value)
                              .keys());
    }
}
