import { ChatBubble } from './chat-bubble/chat-bubble';
import { ChatDate } from './chat-date/chat-date';
import { ChatErrorDialogs } from './chat-error-dialog';
import { ChatGoBottom } from './chat-go-bottom/chat-go-bottom';
import { ChatHeader } from './chat-header/chat-header';
import { ChatInput } from './chat-input/chat-input';
import { ChatInputErrorBox } from './chat-input/chat-input-error-box';
import { ChatInputField } from './chat-input/chat-input-field';
import { ChatMsgBoxComponent } from './chat-msg-box/chat-msg-box';
import { ChatReadingStatus } from './chat-reading-status/chat-reading-status';
import { ChatReport } from './chat-report/chat-report';
import { ChatUnreadSeparator } from './chat-unread-separator/chat-unread-separator';
import { Chat } from './chat/chat';

export default [
    Chat,
    ChatHeader,
    ChatMsgBoxComponent,
    ChatBubble,
    ChatDate,
    ChatInput,
    ChatReadingStatus,
    ChatGoBottom,
    ChatInputField,
    ChatInputErrorBox,
    ChatUnreadSeparator,
    ChatErrorDialogs,
    ChatReport
];

export * from './chat/chat';
