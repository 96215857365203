import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    CurrentUser,
    ImageSize,
    MatchingDecision,
    ProfileInfo,
} from 'BKModels';
import {
    MatchingService,
    ModalService,
} from 'BKService';
import { GlobalModalService } from '../../../service/global-modal.service';
import { BkModalMatch } from '../bk-modals/bk-modal-match';

@Component({
               selector: 'list-matching-second-chance-item',
               template: `
                              <div
                                      class="mdc-card list-item"
                                      [class.list-item--basis]="user.isBasisView()"
                              >
                                  <div class="mdc-card__primary-action" tabindex="0" (click)="openProfileView()">
                                      <div class="mdc-card__media list-item__media">
                                          <img-with-loading-indicator class="list-item__media__image" [src]="imageUrl"></img-with-loading-indicator>
                                          <icon *ngIf="user.isBasisView()" icon="crown-outline" class="list-item__media__crown"></icon>
                                          <icon *ngIf="user.matchingCriteria" class="loyalty" icon="loyalty"></icon>
                                          <div class="list-item__heartbreak-icon" *ngIf="partnerPositiveDecision"></div>
                                      </div>
                                      <div *ngIf="user.vip" class="list-item__badge">
                                          <img class="list-item__badge__image" src="./assets/img/VipBannerIcon.svg">
                                      </div>

                                      <div class="list-item__primary" *ngIf="!user.isBasisView()">
                                          <div class="list-item__title">
                                              <online-state *ngIf="user.hasOnlineState && !user.isOffline"
                                                 type="circle"
                                                 class="list-item__online-state"
                                                 [state]="user.online"
                                                ></online-state>
                                              <span><span class="list-item__title__name">{{user.nickname}},</span> {{user.age}}</span>
                                              
                                              <icon *ngIf="hasVerifiedState && isVerified" class="list-item__verified-icon" icon="verified-user"></icon>
                                          </div>
                                          <div class="list-item__subtitle">{{user.city}}</div>
                                      </div>

                                      <div class="list-item__primary" *ngIf="user.isBasisView()">
                                          <div class="list-item__title">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                          <div class="list-item__subtitle">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                      </div>

                                  </div>
                                  <separator thickness="1px" class="list-item__second-chance-separator"></separator>
                                  <div class="list-item__second-chance-respond">{{ 'MATCHING.SECOND_CHANCE.RESPOND'|translate }}</div>
                                  <div class="mdc-card__actions list-item__action">
                                      <icon-button small shadow neutral
                                                   icon="help"
                                                   [disabled]="hasDecision"
                                                   class="list-item__action__button"
                                                   [class.selected]="hasVotedMaybe"
                                                   (click)="voteMaybe()">
                                      </icon-button>
                                      <icon-button small shadow positive
                                                   icon="match"
                                                   [disabled]="hasDecision"
                                                   class="list-item__action__button"
                                                   [class.selected]="hasVotedYes"
                                                   (click)="voteYes()">
                                      </icon-button>
                                  </div>
                              </div>
                          `,
               styles: [require('./list-matching-second-chance-item.scss')],
           })
export class ListMatchingSecondChanceItem {
    @Input() currentUser = new CurrentUser();
    @Input() user: ProfileInfo = new ProfileInfo();
    @Output() gotoPremium = new EventEmitter();
    @Output() onOpenProfile = new EventEmitter();
    @Output() onVoteYes = new EventEmitter();
    @Output() onVoteMaybe = new EventEmitter();

    get partnerPositiveDecision(): boolean {
        return true;
    }

    get hasDecision(): boolean {
        return !(this.user.decisionOwner === MatchingDecision.NoDecision || this.user.decisionOwner === MatchingDecision.No);
    }

    get hasVotedMaybe(): boolean {
        return this.user.decisionOwner === MatchingDecision.Maybe;
    }

    get hasVotedYes(): boolean {
        return this.user.decisionOwner === MatchingDecision.Yes;
    }

    get imageUrl(): string {
        return this.user.getProfilePictureUrl(ImageSize['530x530']);
    }

    get isVerified(): boolean {
        return this.user.isVerified;
    }

    get hasVerifiedState(): boolean {
        return !this.user.isBasisView();
    }

    constructor(
        private modalService: ModalService,
        private matchingService: MatchingService,
        private globalModalService: GlobalModalService
    ) {}

    openProfileView() {
        if (this.user.isBasisView()) {
            this.gotoPremium.emit();
            return;
        }

        this.onOpenProfile.emit();
        this.modalService.profileView.open(this.user.id);
    }

    voteYes() {
        this.matchingService.setDecision(this.user, MatchingDecision.Yes);
        this.matchingService.removeMatchedItemFromQueue(this.user);
        this.user.decisionOwner = MatchingDecision.Yes;
        this.globalModalService.open(BkModalMatch.typeId, { user: this.user });

        this.onVoteYes.emit();
    }

    voteMaybe() {
        this.matchingService.setDecision(this.user, MatchingDecision.Maybe);
        this.matchingService.removeMatchedItemFromQueue(this.user);
        this.user.decisionOwner = MatchingDecision.Maybe;
        this.globalModalService.open(BkModalMatch.typeId, { user: this.user });

        this.onVoteMaybe.emit();
    }

}
