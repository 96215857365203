import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';


@Component({
               selector: 'asll-item',
               template: `
        <div class="asll-item">
            <div class="asll-item__wrapper" (click)="clickLink.emit()">
                <icon [icon]="icon" class="asll-item__wrapper__icon" [class.highlighted]="highlighted"></icon>
                
            </div>
            <div class="asll-item__text" (click)="clickLink.emit()">
                <a *ngIf="showCounter" bk-link class="asll-item__text__counter" [class.highlighted]="highlighted">{{counter}}</a>
                <span [class.highlighted]="highlighted"><ng-content ></ng-content></span>
            </div>
        </div>
    `,
               styles: [require('./asll-item.scss')],
           })
export class AsllItem {
    @Input() protected showCounter: boolean = true;
    @Input() protected highlighted: boolean = false;
    @Input() protected icon: string = '';
    @Output() protected clickLink = new EventEmitter();

    @Input('counter') private _counter: number = 0;

    protected get counter(): string {
        if (!this._counter) return '0';
        if (this._counter >= 1000) return '+999';
        if (this._counter < 10) return this._counter.pad(2);
        return this._counter.toString();
    }
}
