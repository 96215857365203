export enum AccountDeleteReasons {
    None = 0,
    FindPartnerBk = 1,
    NoInterest = 2,
    RareUse = 3,
    Confusion = 4,
    FindPartnerSomewhereElse = 5,
    NoSuccess = 6,
    Other = 7,
}
