import {
    Component,
    Input,
} from '@angular/core';


@Component({
               selector:  'icon',
               template:  `
                              <i [ngClass]="'icon bk-new-icon'" [class]="combine"></i>
                          `,
               styles: [require('./icon.scss')],
           })

export class Icon {
    @Input() classes: string = '';
    @Input() icon: string;
    @Input() active: boolean;

    private get combine(): string {
        const name = this.icon.replace('icon-', '');
        return `icon-${name} ${this.classes}`;
    }
}
