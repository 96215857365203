import {
    Component,
    Input,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';

@Component({
               selector:  'profile-matching-has-match',
               template:  `
                              <div class="profile-matching-has-match">
                                  <div class="profile-matching-has-match">
                                      <div class="profile-matching-has-match__title">
                                          <div class="profile-matching-has-match__title__icon"></div>
                                          <div class="profile-matching-has-match__title__text">
                                              <span>{{'MATCHING.CONGRATULATION' | translate}}</span>
                                          </div>
                                      </div>
                                      <div class="profile-matching-has-match__text">
                                          {{ user.gender | i18nSelect:msgMappingMatchingReminder | translate:user }}
                                      </div>
                                  </div>

                              </div>
                          `,
               styles: [require('./profile-matching-has-match.scss')],
           })

export class ProfileMatchingHasMatch {
    private msgMappingMatchingReminder: KeyValue = {
        m: 'MATCHING.REMINDER.MALE',
        w: 'MATCHING.REMINDER.FEMALE',
        p: 'MATCHING.REMINDER.PAIR',
    };
    @Input() private user: ProfileInfo;
}
