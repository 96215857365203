import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import { AccountDeleteReasons } from '../../../../../models/delete-reasons';

@Component({
               selector:  'settings-account-delete-step-reason',
               template:  `
                              <div class="settings-account-delete-step-reason">

                                  <settings-account-delete-title [title]="'ACCOUNT.DELETE.STEP.REASON.TITLE' | translate"
                                                                 [text]="'ACCOUNT.DELETE.STEP.REASON.TEXT' | translate"
                                  ></settings-account-delete-title>


                                  <div class="settings-account-delete-step-reason__reason_box">
                                      <settings-account-delete-reason-box *ngFor="let reason of reasons"
                                                                          [reason]="reason"
                                                                          (select)="onSelect($event)"
                                                                          [selectedReason]="selectedReason"
                                                                          (findElseWhereTextChange)="findElseWhereText = $event"
                                                                          (otherTextChange)="otherText = $event"
                                      ></settings-account-delete-reason-box>
                                  </div>

                                  <div class="settings-account-delete-step-reason__text-area">
                                      <div>{{'ACCOUNT.DELETE.STEP.REASON.ADDITIONAL_TEXT' | translate}}</div>
                                      <text-area rows="4" (valueChange)="deleteReasonText = $event"></text-area>
                                  </div>

                                  <div class="settings-account-delete-step-reason__footer">

                                      <div class="settings-account-delete-step-reason__footer__buttons">
                                          <settings-account-delete-footer [cancelText]="'ACCOUNT.DELETE.STEP.SELECTED_USER.CANCEL' | translate"
                                                                          (cancel)="onCancel($event)"
                                                                          [nextText]="'ACCOUNT.DELETE.STEP.SELECTED_USER.NEXT' | translate"
                                                                          (next)="onNext($event)"
                                                                          [disabledNext]="selectedReason == AccountDeleteReasons.None"
                                          ></settings-account-delete-footer>

                                      </div>
                                      <div class="settings-account-delete-step-reason__footer__support">
                                          <premium-support (clickPhoneNumber)="trackSupport()" [noMargin]="true" [showEmail]="false" [showTitle]="false"></premium-support>
                                      </div>
                                  </div>
                              </div>

                          `,
               styles: [require('./settings-account-delete-step-reason.scss')],
           })

export class SettingsAccountDeleteStepReason {
    @Output() private cancel = new EventEmitter();
    @Output() private next = new EventEmitter();
    @Output() private select = new EventEmitter();
    @Output() private changeAdditionalReason = new EventEmitter();
    private deleteReasonText = '';
    private findElseWhereText = '';
    private otherText = '';

    private selectedReason: number = AccountDeleteReasons.None;
    private AccountDeleteReasons = AccountDeleteReasons;

    private reasons = [
        AccountDeleteReasons.FindPartnerBk,
        AccountDeleteReasons.NoInterest,
        AccountDeleteReasons.RareUse,
        AccountDeleteReasons.FindPartnerSomewhereElse,
        AccountDeleteReasons.NoSuccess,
        AccountDeleteReasons.Confusion,
        AccountDeleteReasons.Other,

    ].shuffle();

    public constructor(private trackingService: TrackingService) {
    }

    private onSelect(reason) {
        this.selectedReason = reason;
        this.select.emit(reason);
    }

    private onNext(event){
        let map = {
            [AccountDeleteReasons.FindPartnerBk]: 'PartnerGefunden',
            [AccountDeleteReasons.NoInterest]: 'KeinInteresse',
            [AccountDeleteReasons.RareUse]: 'ZuSelten',
            [AccountDeleteReasons.FindPartnerSomewhereElse]: 'WoandersPartner',
            [AccountDeleteReasons.NoSuccess]: 'KeinErfolg',
            [AccountDeleteReasons.Confusion]: 'Unuebersichtlich',
            [AccountDeleteReasons.Other]: 'Sonstiges',
        };

        let changeAdditionalReasonText = this.deleteReasonText;

        switch (this.selectedReason) {
            case AccountDeleteReasons.FindPartnerSomewhereElse:
                changeAdditionalReasonText = `${changeAdditionalReasonText}

Partner wo anders gefunden: ${this.findElseWhereText}
                `;
                break;
            case AccountDeleteReasons.Other:
                changeAdditionalReasonText = `${changeAdditionalReasonText}

Sonstiges: ${this.otherText}
                `;
                break;
        }

        this.changeAdditionalReason.emit(changeAdditionalReasonText)

        this.next.emit(event);
        this.trackingService.hit('AccountLoeschen','Loeschgrund', map[this.selectedReason]);
    }

    private onCancel(event){
        this.cancel.emit(event);
        this.trackingService.hit('AccountLoeschen','Loeschgrund','Abbrechen');
    }

    private trackSupport(){
        this.trackingService.hit('AccountLoeschen','Loeschgrund','Supportnummer');
    }
}
