import {
    Component,
    Input,
} from '@angular/core';

export enum UspType {PRO = 'pro', CONTRA = 'contra'}

@Component({
               selector:  `usp-item`,
               template:  `
                              <div [class]="type==='pro' ? 'usp-item usp-item--pro': 'usp-item usp-item--contra'">
                                  <icon [icon]="type==='pro' ? 'check': 'close'" class="usp-icon"></icon>
                                  <div class="usp-text">
                                    <ng-content></ng-content>
                                  </div>
                              </div>
                          `,
               styles: [require('./usp-item.scss')],
           })
export class UspItem {
    @Input() private type: UspType = UspType.PRO;
}