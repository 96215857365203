import {
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { LoginModule } from './login';
import { LogoutModule } from './logout';
import { PasswordRecoveryModule } from './password-recovery';
import { SendRecoveryMailModule } from './send-recovery-mail';
import {VerifyEmailChangeModule} from './verify-email-change';

@NgModule({
              imports: [
                  LoginModule,
                  LogoutModule,
                  SendRecoveryMailModule,
                  PasswordRecoveryModule,
                  VerifyEmailChangeModule,
              ],
              exports: [
                  LoginModule,
                  LogoutModule,
                  SendRecoveryMailModule,
                  PasswordRecoveryModule,
                  VerifyEmailChangeModule,
              ],
          })
export class AppOuterModule {
    public static forRoot(): ModuleWithProviders<AppOuterModule> {
        return {
            ngModule: AppOuterModule,
            providers: [],
        };
    }
}
