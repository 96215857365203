import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ApiResponseFrame,
    FavWithNotice,
    UserBaseView,
} from 'BKModels';
import {
    Cache,
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';
import { CommunicationService } from './communication/communication.service';

@Injectable({ providedIn: 'root' })
export class FavoriteService {

    public constructor(
        private apiService: ApiService,
        private communicationService: CommunicationService,
        private translate: TranslateService,
    ) {
    }


    public add(user: UserBaseView): Promise<ApiResponseFrame<any>> {
        return this.apiService.favorite
                   .add(user.id)
                   .then(res => {
                       this.communicationService.growl.positive(this.translate.instant('SNACKBAR.FAVORITE.ADD', {'nickname': user.nickname}));
                       this.deleteCache();
                       return res;
                   });
    }

    public changeNotice(favoriteId: number, notice: string = ''): Promise<ApiResponseFrame<any>> {
        return this.apiService.favorite.changeNotice(favoriteId, notice)
                   .then(res => {
                       this.communicationService.growl.positive(this.translate.instant('SNACKBAR.FAVORITE.ADD_NOTE'));
                       this.deleteCache();
                       return res;
                   });
    }

    @Cacheable(CacheKey.FavoriteList)
    public list(limit: number, offset?: number): Promise<FavWithNotice[]> {
        return this.apiService.favorite.list(limit, offset).then(res => res['data']['favorites'].map(cur => FavWithNotice.create(cur)));
    }

    @Cacheable(CacheKey.FavoriteListDeleted)
    public listDeleted(limit: number, offset?: number): Promise<FavWithNotice[]> {
        return this.apiService.favorite.listDeleted(limit, offset).then(res => res['data']['deletedFavorites'].map(cur => {
            const fav = FavWithNotice.create(cur);
            fav.favorite.nickname = this.translate.instant('FAVORITE.EMPTY_ENTRY');
            fav.favorite.image = 'a1b004663DsAXNPOlJ1bBZ07Kybqnrrf';
        }));
    }

    public remove(user: UserBaseView): Promise<ApiResponseFrame<any>> {
        return this.apiService.favorite.remove(user.id)
                   .then(res => {
                       this.communicationService.growl.positive(this.translate.instant('SNACKBAR.FAVORITE.REMOVE', {'nickname': user.nickname}));
                       this.deleteCache();
                       return res;
                   });

    }

    public removeMultiple(ids: number[]): Promise<ApiResponseFrame<any>> {
        return this.apiService.favorite.removeMultiple(ids)
                   .then(res => {
                       this.communicationService.growl.positive(this.translate
                                                                    .instant('SNACKBAR.FAVORITE.REMOVE_MULTI', {
                                                                        length: ids.length.toString(),
                                                                    }));
                       this.deleteCache();
                       return res;
                   });

    }


    private deleteCache() {
        Cache.getInstance().delete(CacheKey.FavoriteList, CacheKey.FavoriteListDeleted, CacheKey.Favorite, CacheKey.Search);
    }


}
