/**
 * Created by olifra on 22.05.2017.
 */

import {
    ApiResponseFrame,
    UserBaseView,
    UserSearch,
} from 'BKModels';
import { Endpoints } from 'BKUtils';
import {
    Observable,
    Subscriber,
} from 'rxjs';
import {
    Cacheable,
    CacheKey,
} from '../../../utils/cache';
import { ApiRequestService } from '../api-request.service';

/**
 * Service der die Api für den Geolocationservice zurverfügung stellt
 */
export class SearchApi {
    private searchSubscriber: Subscriber<any>;

    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public cancel() {
        if (this.searchSubscriber) {
            this.searchSubscriber.error('cancel');
        }
    }

    public search(userSearch: UserSearch, limit: number, offset: number): Promise<UserBaseView[]> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.search.get, {
                       query: {
                           offset,
                           limit,
                           userSearch,
                       },
                   }).then(frame => frame.data['result'].map(res => UserBaseView.create(res)));

    }

    public searchNickname(nickname: string, limit: number, offset?: number): Promise<any> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.search.get, {
                       body: {
                           offset,
                           limit,
                           userSearch: { nickname: nickname },
                       },
                   }).then(frame => frame.data['result'].map(res => UserBaseView.create(res)));

    }
}
