import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { MailSettingsInterval } from '../../../../service/api/calls/settings.api';

@Component({
               selector:  'mail-settings-card',
               template: `
                             <card class="settings-card">
                                 <div class="container">
                                     <div class="title">{{ settingsTitle|translate }}</div>
                                     <div class="info-text">{{ 'MAIL_SETTINGS.INFO'|translate }}</div>
                                     <separator thickness="1px"></separator>
                                     <ng-container *ngIf="showIntervalSettings">
                                         <div class="settings-text">{{ settingsText|translate }}</div>

                                         <dropdown class="selection" [(value)]="intervalSetting" [placeholder]="'MAIL_SETTINGS.INTERVAL.TITLE'|translate">
                                             <dropdown-option *ngFor="let option of intervalOptions"
                                                              [value]="option.setting"
                                                              [selected]="intervalSetting === option.setting"
                                             >{{option.textKey | translate}}</dropdown-option>
                                         </dropdown>

                                         <div class="button-bar save">
                                             <raised-button (click)="save.emit($event)">{{ 'MAIL_SETTINGS.ACTION.SAVE'|translate }}</raised-button>
                                         </div>
                                         <separator thickness="1px"></separator>
                                     </ng-container>
                                     <div class="disable-text">{{ disableText|translate }}</div>
                                     <div class="button-bar disable">
                                         <flat-button (click)="disable.emit($event)">{{ 'MAIL_SETTINGS.ACTION.DISABLE'|translate }}</flat-button>
                                     </div>
                                 </div>
                             </card>
                         `,
               styles: [require('./mail-settings-card.scss')],
           })

export class MailSettingsCard implements OnInit {
    @Input() settingsTitle = '[missing]';
    @Input() settingsText = '[missing]';
    @Input() disableText = '[missing]';
    @Input() interval = MailSettingsInterval.Never;
    @Input() intervalSettings: MailSettingsInterval[] = [];
    @Input() showIntervalSettings = false;
    @Output() intervalChange = new EventEmitter<MailSettingsInterval>();
    @Output() save = new EventEmitter();
    @Output() disable = new EventEmitter();

    intervalOptions: { textKey: string, setting: MailSettingsInterval}[] = [];

    get intervalSetting(): MailSettingsInterval {
        return this.interval;
    }

    set intervalSetting(value: MailSettingsInterval) {
        this.interval = value;
        this.intervalChange.emit(value);
    }

    public ngOnInit(): void {
        const intervalKeyMap = {
            [MailSettingsInterval.Never]: "MAIL_SETTINGS.INTERVAL.NEVER",
            [MailSettingsInterval.Always]: "MAIL_SETTINGS.INTERVAL.ALWAYS",
            [MailSettingsInterval.LimitedPerDay]: "MAIL_SETTINGS.INTERVAL.LIMITED_PER_DAY",
        };

        this.intervalOptions = this.intervalSettings
                   .filter(value => value !== MailSettingsInterval.Never)
                   .map(value => ({ setting: value, textKey: intervalKeyMap[value] }));
    }

    isIntervalSelected(option: MailSettingsInterval): boolean {
        return this.intervalSetting === option;
    }

}
