import { Component } from '@angular/core';

@Component({
               selector: 'schlagerherzen-banner',
               template: `
                             <div class="banner">
                                 <div class="slogan">{{'BANNER.SCHLAGERHERZEN.SLOGAN'|translate}}</div>
                                 <div class="time">
                                     <div class="time__day">
                                         <img class="time__day__icon" src="./assets/schlagerparadies/triangle.svg" />
                                         <span>{{'BANNER.SCHLAGERHERZEN.TIME_DAY'|translate}}</span>
                                     </div>
                                     <p class="time__time">{{'BANNER.SCHLAGERHERZEN.TIME_TIME'|translate}}</p>
                                     <span class="time__title">{{'BANNER.SCHLAGERHERZEN.TIME_TITLE'|translate}}</span>
                                 </div>
                                 <div class="cooperation">
                                     <span>{{ 'BANNER.SCHLAGERHERZEN.COOPERATION'|translate }}</span>
                                     <img class="cooperation__logo--bk" src="./assets/schlagerparadies/logo-bildkontakte.svg"/>
                                     <img class="cooperation__logo--rsp" src="./assets/schlagerparadies/logo-radio-schlagerparadies.svg"/>
                                 </div>

                             </div>`,
               styles: [require('./schlagerherzen-banner.scss')],
           })
export class SchlagerherzenBanner {}