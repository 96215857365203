import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BkConfig } from 'BKConfig';
import { BkLoginResponse } from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiResponseFrame } from '../../models/api';
import { ApiRequestService } from './api-request.service';
import { AchievementsApi } from './calls/achievements.api';
import { ConversationApi } from './calls/conversation.api';
import { FavoriteApi } from './calls/favorite.api';
import { FeedbackApi } from './calls/feedback.api';
import { GeolocationApi } from './calls/geolocation.api';
import { IgnoreApi } from './calls/ignore.api';
import { ImageApi } from './calls/image.api';
import { ListApi } from './calls/list';
import { LovestoriesApi } from './calls/lovestories.api';
import { MatchingApi } from './calls/matching.api';
import { MessagesApi } from './calls/messages.api';
import { MicrotransactionsApi } from './calls/microtransactions.api';
import { OfferApi } from './calls/offer.api';
import { PasswordApi } from './calls/password.api';
import { PaymentApi } from './calls/payment.api';
import { PremiumApi } from './calls/premium.api';
import { ProfileEditApi } from './calls/profile-edit.api';
import { PushApi } from './calls/push.api';
import { RadioApi } from './calls/radio.api';
import { ReportApi } from './calls/report.api';
import { SearchApi } from './calls/search.api';
import { SettingsApi } from './calls/settings.api';
import { SingleEventsApi } from './calls/single-events.api';
import { SurveyApi } from './calls/survey.api';
import { UserApi } from './calls/user.api';
import { ValidationApi } from './calls/validation.api';

export enum ListVariant {
    AllPictureBlur,
    FirstPictureNotBlur
}

export enum CancelType {
    Undefined = 'undefined',
    Normal = "normal",
    WithoutNotice = "without_notice"
}

/**
 * Service for Apicalls
 */
@Injectable({ providedIn: 'root' })
export class ApiService {
    public readonly achievements: AchievementsApi = new AchievementsApi(this.apiRequestService);
    public readonly favorite: FavoriteApi = new FavoriteApi(this.apiRequestService);
    public readonly geoLocation: GeolocationApi = new GeolocationApi(this.apiRequestService);
    public readonly image: ImageApi = new ImageApi(this.apiRequestService);
    public readonly password: PasswordApi = new PasswordApi(this.apiRequestService);
    public readonly push: PushApi = new PushApi(this.apiRequestService);
    public readonly message: MessagesApi = new MessagesApi(this.apiRequestService);
    public readonly user: UserApi = new UserApi(this.apiRequestService);
    public readonly validation: ValidationApi = new ValidationApi(this.apiRequestService);
    public readonly lists: ListApi = new ListApi(this.apiRequestService);
    public readonly matching: MatchingApi = new MatchingApi(this.apiRequestService);
    public readonly lovestories: LovestoriesApi = new LovestoriesApi(this.apiRequestService, this.translateService);
    public readonly microtransaction: MicrotransactionsApi = new MicrotransactionsApi(this.apiRequestService);
    public readonly search: SearchApi = new SearchApi(this.apiRequestService);
    public readonly settings: SettingsApi = new SettingsApi(this.apiRequestService);
    public readonly conversation: ConversationApi = new ConversationApi(this.apiRequestService);
    public readonly ignore: IgnoreApi = new IgnoreApi(this.apiRequestService);
    public readonly payment: PaymentApi = new PaymentApi(this.apiRequestService);
    public readonly singleEvents: SingleEventsApi = new SingleEventsApi(this.apiRequestService);
    public readonly premium: PremiumApi = new PremiumApi(this.apiRequestService);
    public readonly offer: OfferApi = new OfferApi(this.apiRequestService);
    public readonly feedback: FeedbackApi = new FeedbackApi(this.apiRequestService);
    public readonly report: ReportApi = new ReportApi(this.apiRequestService);
    public readonly radio: RadioApi = new RadioApi(this.apiRequestService);
    public readonly profile: ProfileEditApi = new ProfileEditApi(this.apiRequestService);
    public readonly survey: SurveyApi = new SurveyApi(this.apiRequestService);

    public constructor(
        private apiRequestService: ApiRequestService,
        private translateService: TranslateService,
    ) {
    }

    public fbLogin(email: string, id: string, token: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.authentication.fbconnect, {
                       body: {
                           'fbConnect[email]':         email,
                           'fbConnect[facebookId]':    id,
                           'fbConnect[facebookToken]': token,
                           type:                       'session',
                       },
                   });
    }

    public login(payload: any): Promise<BkLoginResponse> {
        return this.apiRequestService
                   .createPost<BkLoginResponse>(Endpoints.authentication.login, {
                       body: {
                           username:      payload._username,
                           password:      payload._password,
                           client_id:     BkConfig.client.id,
                           client_secret: BkConfig.client.secret,
                           grant_type:    'https://api.bildkontakte.de/grants/api_password',
                       },
                   });
    }

    /**
     * apicall for the logout
     */
    public logout(): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.authentication.logout);
    }

    /**
     * ApiCall zum Tracken von Profilebesucher
     {number} profileId
     */
    public trackProfileVisitor(profileId: number): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(
                       Endpoints.profileTrackVisit, { body: { profileId } },
                   );
    }

    public sendDeviceInfos(): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(
                       Endpoints.authentication.deviceInfo,
                       { body: { screenResolution: '0x0' } },
                   );
    }

    public verifyEmail(token: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService.createPost<ApiResponseFrame<any>>(
            Endpoints.settings.email.verify,
            {
                body: {
                    client_id: BkConfig.client.id,
                    token:     token,
                },
            },
        );
    }

    public loginWithNonce(nonce: string): Promise<BkLoginResponse> {
        return this.apiRequestService
                   .createPost<BkLoginResponse>(Endpoints.authentication.login, {
                       body: {
                           nonce,
                           client_id:     BkConfig.client.id,
                           client_secret: BkConfig.client.secret,
                           grant_type:    'https://api.bildkontakte.de/grants/nonce',
                       },
                   });
    }

    public terminateContracts(
        email: string,
        password: string,
        usernameOrId: string,
        firstName: string,
        lastName: string,
        street: string,
        streetNumber: string,
        postCode: string,
        city: string,
        cancelDate: string,
        cancelType: CancelType,
        cancelReason: string,
    ): Promise<void> {
        const body = {
            cancelForm: {
                email,
                password,
                username:       usernameOrId,
                name:           firstName,
                lastName,
                street,
                streetNumber,
                postalCode:     postCode,
                city,
                cancellationAt: cancelDate,
                cancelType,
                cancelReason,
            },
            client_id:  BkConfig.client.id,
        };

        return this.apiRequestService
                   .createPost<void>(Endpoints.terminateContracts, { body });
    }
}
