import {
    Component,
    ViewChild,
} from '@angular/core';
import { ApiService } from 'BKService';
import { ModalDialog } from '../../../service/global-modal.service';
import { FailedChargebackPaymentInfo } from '../../../service/premium.service';
import { DialogBox } from '../dialog/dialog-box';
import { growShrink } from 'BKUtils';
import * as moment from 'moment';

@Component({
               selector:  'bk-modal-id-requested',
               template:  `
                              <ng-container>
                                  <dialog-box #dialog
                                              [title]="'ID_REQUESTED.TITLE'|translate"
                                              [rejectText]="'GLOBAL.CLOSE'|translate"
                                  >
                                      <ng-container *ngIf="!success">
                                          <div class="markdown first-last-nomargin" [innerHtml]="'ID_REQUESTED.TEXT'|translate|markdown"></div>
                                          <div class="id-requested-action">
                                              <raised-button (click)="fileUpload.click()" [disabled]="uploadInProgress">
                                                  <loading-indicator *ngIf="uploadInProgress"></loading-indicator>
                                                  {{ 'ID_REQUESTED.UPLOAD'|translate }}
                                              </raised-button>
                                              <input class="file-input" type="file" [accept]="acceptedFileTypes" (change)="onFileSelected($event)" #fileUpload>
                                              <div>{{ 'ID_REQUESTED.MAX_SIZE'|translate }}</div>
                                              <div class="error-text" *ngIf="error">{{ 'ID_REQUESTED.ERROR'|translate }}</div>
                                          </div>
                                      </ng-container>
                                      <ng-container *ngIf="success">
                                          <div class="markdown first-last-nomargin" [innerHtml]="'ID_REQUESTED.SUCCESS'|translate|markdown"></div>
                                      </ng-container>
                                  </dialog-box>
                              </ng-container>
                          `,
               styles: [require('./bk-modal-id-requested.scss')],
               animations: [growShrink],
           })
export class BkModalIdRequested implements ModalDialog {
    @ViewChild('dialog', { 'static': true }) private dialog: DialogBox;
    @ViewChild('fileUpload', { 'static': true }) private fileUpload: DialogBox;

    acceptedFileTypes = "image/*";

    static readonly typeId = 'BkModalIdRequested';

    token: string = '';

    uploadInProgress = false;
    error = false;
    success = false;

    public open(config) {
        this.token = config;
        this.dialog.open();
    }

    public close() {
        this.dialog.close();
    }

    public get typeId(): string {
        return BkModalIdRequested.typeId;
    }

    onFileSelected($event) {
        const file: File = $event.target.files[0];
        if (!file) return;

        this.uploadInProgress = true;
        this.error = false;
        this.api.validation.idWithNonce(file, this.token)
            .then(response => {
                this.success = true;
            })
            .catch(reason => {
                this.error = true;
            })
            .finally(() => {
                this.uploadInProgress = false;
            });
    }

    constructor(
        private api: ApiService
    ) {}

}
