import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'edit-images-box',
               template: `
                             <card-title [title]="'PROFILE-EDIT.TITLE.EDIT-IMAGES'|translate">
                                 <edit-images-layout></edit-images-layout>
                             </card-title>
                         `,
           })

export class EditImagesBoxComponent {}
