import {
    EventEmitter,
    Output,
} from '@angular/core';
import { Label } from '../../../../models/label';

export abstract class AbstractLabel {
    public pristine: boolean = true;
    public isSelectableExpandable: boolean = false;
    public selectedLabels: Label[] = [];
    public selectableLabels: Label[] = [];
    protected labels: Label[] = [];

    @Output('save') public saveEvent = new EventEmitter<void>();

    protected readonly SelectableAmount = 10;

    public abstract loadData(): void;

    public select(index: number) {
        let selection = this.selectableLabels.splice(index, 1);
        this.selectedLabels.push(...selection);
        this.updateSelectableLabels();
        this.pristine = false;
    }

    public deselect(index: number) {
        let selection = this.selectedLabels.splice(index, 1);
        this.selectableLabels.push(...selection);
        this.pristine = false;
    }

    public expandSelectables() {
        let availableLabels = this.availableLabels();
        this.selectableLabels.splice(this.selectableLabels.length, 0, ...availableLabels);
        this.isSelectableExpandable = false;
    }

    protected availableLabels() {
        return this.labels.filter((availableLabel => {
            let maxItems = Math.max(this.selectedLabels.length, this.selectableLabels.length);
            for (let i = 0; i < maxItems; i++) {
                if (this.selectedLabels[i] !== undefined && this.selectedLabels[i].id === availableLabel.id) {
                    return false;
                }
                if (this.selectableLabels[i] !== undefined && this.selectableLabels[i].id === availableLabel.id) {
                    return false;
                }
            }
            return true;
        }));
    }

    protected updateSelectableLabels() {
        if (this.selectableLabels.length < this.SelectableAmount) {
            let availableLabels = this.availableLabels();
            while ((this.SelectableAmount - this.selectableLabels.length) > 0 && availableLabels.length > 0) {
                this.selectableLabels.push(availableLabels.pop());
            }
            this.isSelectableExpandable = availableLabels.length > 0;
        }
    }

    public abstract save();
}