import {
    BehaviorSubject,
    Observable,
} from 'rxjs';

/**
 * Storebase class
 */
export abstract class StoreBase<T> {
    // TODO Is this really the responsibility of storage?
    protected store: BehaviorSubject<T>;

    public get isProtected(): boolean {
        return this._isProtected;
    }

    public constructor(protected creator, protected storageName ?: string, private _isProtected: boolean = false) {
        let initObj: T;
        if (this.storeIsEmpty()) {
            initObj = this.creator();
        }
        else {
            initObj = this.creator(this.loadFromStore());
        }

        this.store = new BehaviorSubject(initObj);
    }

    public change(key: string, value: any): Promise<T> {
        const change = this.store.getValue();
        change[key] = value;

        this.store.next(change);
        return new Promise(() => change) as Promise<T>;
    }

    public reset() {
        this.remove();
        this.store.next(this.creator());
    }

    public get(): Observable<T> {
        return this.store.asObservable();
    }

    public getValues(): T {
        return this.store.getValue();
    }

    public isEmpty(): boolean {
        return this.storeIsEmpty();
    }

    public isPersisted(): boolean {
        return false;
    }

    public next(val: T) {
        this.saveToStore(val);
        this.store.next(val);
    }

    protected abstract loadFromStore(): T;

    public abstract remove();

    protected abstract saveToStore(val: T);

    protected storeIsEmpty(): boolean {
        const value = this.loadFromStore();
        return value === null || value === undefined;
    }
}
