import {
    Component,
    OnInit,
} from '@angular/core';
import {
    Conversation,
    UserBaseView,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
} from 'BKService';
import {
    dispatchLogoutEvent,
    getScrollContainer,
    startLoadingBar,
    stopLoadingBar,
} from 'BKUtils';
import { AccountDeleteReasons } from '../../../../../models/delete-reasons';
import { PremiumDetails } from '../../../../../models/premium-details';
import { UserCounts } from '../../../../../models/user-counts';
import { ConversationService } from '../../../../../service/conversation.service';
import { FavoriteService } from '../../../../../service/favorite.service';
import { ListService } from '../../../../../service/list.service';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

enum AccountDeleteSteps {
    List,
    SelectedUser,
    Reason,
    Recommended,
}


@Component({
               selector:  'settings-account-delete',
               template:  `
                              <card>
                                  <div class="settings-account-delete" [ngSwitch]="currentStep" *ngIf="show">

                                      <ng-container *ngSwitchCase="AccountDeleteSteps.List">
                                          <settings-account-delete-premium-step-list *ngIf="isPremium"
                                                                                     [interestedInMeUserList]="interestedInMeUserList"
                                                                                     [matchPartnerUserList]="matchPartnerUserList"
                                                                                     [profileVisitorList]="profileVisitorList"
                                                                                     [favoritesList]="favoritesList"
                                                                                     [listSize]="listSize"
                                                                                     [premiumDetails]="premiumDetails"
                                                                                     [counts]="userCounts"
                                                                                     [conversations]="conversations"
                                                                                     (next)="nextStep(AccountDeleteSteps.SelectedUser)"
                                                                                     (cancel)="toStartpage()"
                                          ></settings-account-delete-premium-step-list>

                                          <settings-account-delete-basic-step-list
                                                  *ngIf="!isPremium"
                                                  [favoritesList]="favoritesList"
                                                  [counts]="userCounts"
                                                  [conversations]="conversations"
                                                  (next)="nextStep(AccountDeleteSteps.SelectedUser)"
                                                  (cancel)="toStartpage()"
                                          ></settings-account-delete-basic-step-list>

                                      </ng-container>

                                      <settings-account-delete-step-selected-user *ngSwitchCase="AccountDeleteSteps.SelectedUser"
                                                                                  [list]="selectedUserList"
                                                                                  (next)="nextStep(AccountDeleteSteps.Reason)"
                                                                                  (cancel)="toStartpage()"
                                                                                  [premium]="currentUserService.cachedCurrentUser.isPremium()"
                                                                                  [premiumDetails]="premiumDetails"
                                      ></settings-account-delete-step-selected-user>

                                      <settings-account-delete-step-reason *ngSwitchCase="AccountDeleteSteps.Reason"
                                                                           (next)="nextStep(AccountDeleteSteps.Recommended)"
                                                                           (select)="deleteReason = $event"
                                                                           (cancel)="toStartpage()"
                                                                           (changeAdditionalReason)="deletedReasonText = $event;"
                                      ></settings-account-delete-step-reason>

                                      <settings-account-delete-step-recommendation *ngSwitchCase="AccountDeleteSteps.Recommended"
                                                                                   (cancel)="toStartpage()"
                                                                                   (deleteProfile)="deleteProfile($event)"
                                                                                   [error]="error"
                                                                                   [apiCallRun]="apiCallRun"
                                      ></settings-account-delete-step-recommendation>
                                  </div>
                              </card>
                          `,
               styles: [require('./settings-account-delete.scss')],
           })

export class SettingsAccountDelete implements OnInit {
    private AccountDeleteSteps = AccountDeleteSteps;
    private currentStep = AccountDeleteSteps.List;

    private minListCounterPremium = 3;

    private minFavoritesCounterBasic = 2;
    private minConversationCounterBasic = 2;

    private userCounts = new UserCounts();
    private interestedInMeUserList: UserBaseView[] = [];
    private matchPartnerUserList: UserBaseView[] = [];
    private profileVisitorList: UserBaseView[] = [];
    private selectedUserList: UserBaseView[] = [];
    private favoritesList: UserBaseView[] = [];
    private premiumDetails: PremiumDetails = null;
    private conversations: Conversation[] = [];

    private listSize = 5;
    private selectedUserListSize = 4;
    private show = false;

    private deleteReason: AccountDeleteReasons;
    private deletedReasonText: string = '';
    private error: boolean = false;

    private apiCallRun = false;


    private get isPremium(): boolean {
        return this.currentUserService.cachedCurrentUser.isPremium();
    }

    public constructor(
        private listService: ListService,
        private matchingService: MatchingService,
        private currentUserService: CurrentUserService,
        private favoriteService: FavoriteService,
        private premiumService: PremiumService,
        private navigationService: NavigationService,
        private conversationService: ConversationService,
    ) {
    }

    public ngOnInit(): void {
        startLoadingBar();
        const countsPromise = this.currentUserService.getCounts().then(value => this.userCounts = value);
        const profileVisitorPromise = this.listService.profileVisitors(false, this.listSize).then(value => this.profileVisitorList = value);
        const matchPartnerPromise = this.matchingService.matchPartner(this.listSize).then(value => this.matchPartnerUserList = value);
        const interestedInMePromise = this.matchingService.wantYou(this.listSize).then(value => this.interestedInMeUserList = value);
        const selectedUserPromise = this.listService.selectedUser(this.selectedUserListSize, 0, this.isPremium).then(value => this.selectedUserList = value.result);
        const favoritePromise = this.favoriteService.list(this.listSize).then(value => this.favoritesList = value.map(cur => cur.favorite));

        const conversationPromise = this.conversationService.unreadWithoutSystem(2, 0).then(value => this.conversations = value);

        Promise.all([countsPromise, profileVisitorPromise, matchPartnerPromise, interestedInMePromise, selectedUserPromise, favoritePromise, conversationPromise]).then(() => {
            this.show = true;

            let listCounter = 0;
            if (this.interestedInMeUserList.length >= this.listSize) listCounter++;
            if (this.matchPartnerUserList.length >= this.listSize) listCounter++;
            if (this.profileVisitorList.length >= this.listSize) listCounter++;
            if (this.favoritesList.length >= this.listSize) listCounter++;
            if (this.conversations.length >= 2) listCounter++;


            this.nextStep(AccountDeleteSteps.List);

            if (this.isPremium) {
                if (listCounter < this.minListCounterPremium) {
                    this.nextStep(AccountDeleteSteps.SelectedUser);
                }
            } else {
                if (this.conversations.length < this.minConversationCounterBasic || this.favoritesList.length < this.minFavoritesCounterBasic) {
                    this.nextStep(AccountDeleteSteps.SelectedUser);
                }
            }

            stopLoadingBar();
        });

        if (this.isPremium) {
            this.premiumService.getDetails().then(value => this.premiumDetails = value);
        }
    }

    private nextStep(step: AccountDeleteSteps) {
        this.currentStep = step;
        getScrollContainer().scrollTop = 0;
    }

    private toStartpage() {
        this.navigationService.navigateTo(Routes.Startpage);
    }

    private deleteProfile(data: { recommended: boolean, recommendedText: string, password: string }) {
        startLoadingBar();
        this.apiCallRun = true;
        this.currentUserService.deleteAccount(data.password, this.deleteReason, this.deletedReasonText, data.recommended)
            .then(res => {
                dispatchLogoutEvent();
                this.navigationService.navigateTo(Routes.AccountDeleteSuccessful);
            }).catch(err => this.error = true)
            .finally(() => {
                stopLoadingBar();
                this.apiCallRun = false;
            });
    }
}
