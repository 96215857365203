import {
    AfterViewInit,
    Component,
    Input,
    ViewChild,
} from '@angular/core';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';
import { CountrySelectService } from '../../../service/country-select.service';
import { Dropdown } from '../dropdown/dropdown';

@Component({
               selector: 'country-select',
               template: `
    <dropdown [placeholder]="'REGISTRATION.COUNTRY' | translate"
              [name]="name"
              [id]="id"
              [(ngModel)]="value"
    >
      <dropdown-option *ngFor="let item of countrySelect"
                       [value]="item.value"
                       [selected]="item.value == value"
      >
        {{item.name | translate}}
      </dropdown-option>
    </dropdown>

  `,
               styles: [
                   `.bk-country-select {
    width: 100%;
  }`,
               ],
               providers: [
                   getValueAccessorProvider(CountrySelect),
               ],
           })
export class CountrySelect extends ValueAccessorBase<string> implements AfterViewInit {
    private countrySelect: SelectItem[] = this.countryService.frequent;

    @ViewChild(Dropdown, { static: true }) private dropdown: Dropdown;

    @Input() private id: string = uuidv4();

    @Input() private name: string = uuidv4();

    public constructor(private countryService: CountrySelectService) {
        super();
    }

    public ngAfterViewInit() {
        this.layout()
    }

    public layout(): Promise<any> {
        return this.dropdown.layout();
    }
}
