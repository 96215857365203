import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ExtendedSearchCriteriaProperty } from 'BKModels';

@Component({
               selector:  'search-extended-others',
               template:  `

                              <search-modal-box>
                                  <div class="search-extendend-others__checkbox" (click)="onlineChange.emit(!online)"
                                       [attr.id]="ids.Online"
                                  >
                                      <div>{{'SEARCH.EXTENDED.EXTENDED.ONLY_ONLINE' | translate}}</div>
                                      <switch [value]="online" (valueChange)="onlineChange.emit($event)"></switch>
                                  </div>
                              </search-modal-box>

                              <search-modal-box>
                                  <div class="search-extendend-others__checkbox" (click)="verifiedChange.emit(!verified)"
                                       [attr.id]="ids.Echeck"
                                  >
                                      <div>{{'SEARCH.EXTENDED.EXTENDED.VERIFIED' | translate}}</div>
                                      <switch [value]="verified" (valueChange)="verifiedChange.emit($event)"></switch>
                                  </div>
                              </search-modal-box>
                              <search-modal-box [title]="'SEARCH.EXTENDED.EXTENDED.FILLED_FIELDS.LABEL' | translate">
                                  <search-extended-chips
                                          [attr.id]="ids.FilledFields"
                                          [translationKey]="'SEARCH.EXTENDED.EXTENDED.FILLED_FIELDS.'"
                                          [value]="filledFields"
                                          (valueChange)="filledFieldsChange.emit($event)"
                                  >

                                  </search-extended-chips>
                              </search-modal-box>         
                              <search-modal-box [title]="'SEARCH.EXTENDED.EXTENDED.TEXT_SEARCH.LABEL' | translate">
                                  <text-field [placeholder]="'SEARCH.EXTENDED.TEXT_SEARCH' | translate"
                                              [value]="fullTextSearch"
                                              (valueChange)="fullTextSearchChange.emit($event)"
                                              leadingIcon="search-outline"
                                  ></text-field>
                              </search-modal-box>

                          `,
               styles: [require('./search-extended-others.scss')],
           })

export class SearchExtendedOthers {
    private ids = ExtendedSearchCriteriaProperty;

    @Input() private online: boolean;
    @Output() private onlineChange = new EventEmitter();
    @Input() private verified : boolean;
    @Output() private verifiedChange = new EventEmitter();
    @Input() private filledFields;
    @Output() private filledFieldsChange = new EventEmitter();
    @Input() fullTextSearch: string;
    @Output() fullTextSearchChange = new EventEmitter();
}

