import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
               selector:  'image-upload',
               template:  `
                              <div class="image-upload">
                                  <input #input
                                         type="file"
                                         accept="image/*"
                                         class="image-upload__input"
                                         [attr.id]="id"
                                         (change)="select($event)"
                                  >

                                  <raised-button
                                          [disabled]="disabled"
                                          (click)="openFileUpload()"
                                  >
                                      <ng-content></ng-content>
                                  </raised-button>
                              </div>
                          `,
               styles: [require('./image-upload.scss')],
           })

export class ImageUpload {
    @ViewChild('input', { static: true }) private inputRef: any;
    @Input() private disabled: boolean = false;
    @Input() private id;
    @Output() private selectImg = new EventEmitter();

    public openFileUpload() {
        this.inputRef.nativeElement.value = null;
        document.getElementById(this.id).click();
    }


    private select(event) {
        if (event == null) return;

        const uploadData = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (data) => {
            const img = (data.target as any).result;
            this.selectImg.emit(img);
        };

        reader.readAsDataURL(uploadData);
    }


}
