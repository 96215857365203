import {
    Component,
    Input,
} from '@angular/core';


@Component({
               selector: 'chat-go-bottom',
               template: `
        <div class="chat-go-bottom">
            <div *ngIf="counter > 0" class="chat-go-bottom__badge">{{counter}}</div>
            <icon icon="arrow-down"
                  class="chat-go-bottom__icon"></icon>
        </div>`,
               styles: [require('./chat-go-bottom.scss')],
           })

export class ChatGoBottom {
    @Input() private counter: number;
}
