import {
    Component,
} from '@angular/core';


//@TOO rename
@Component({
               selector: 'premium-landing-page-footer',
               template: `
                             <div class="premium-landing-page-footer">
                                 <div class="premium-landing-page-footer__content_wrap">
                                     <div class="premium-landing-page-footer__logo"></div>
                                     <footer-links></footer-links>
                                 </div>
                             </div>`,
               styles: [require('./premium-landing-page-footer.scss')],
           })

export class PremiumLandingPageFooter {
}
