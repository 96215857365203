import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCDialog } from '@material/dialog/component';
import * as moment from 'moment';
import {
    Offer,
    OfferType,
} from '../../../models/offer';

@Component({
               selector:  'offer-modal',
               template:  `
                              <div #modal class="mdc-dialog modal"
                                   [class.modal--scrim-disabeld]="!backgroundClose"
                              >
                                  <div #content class="mdc-dialog__container modal__container">
                                      <div class="mdc-dialog__surface modal__surface">
                                          <div *ngIf="closeIcon" class="modal__surface__wrapper">
                                              <icon-button [tabIndex]="-1" class="modal__surface__wrapper__close-icon" icon="close" (click)="close()"></icon-button>
                                          </div>

                                          <div class="mdc-dialog__content modal__content" (scroll)="scroll.emit($event)">
                                              <welcome-offer *ngIf="shouldShowLegacyOffer"
                                                             [username]="username"
                                                             [offer]="offer"
                                                             (close)="close()"
                                              ></welcome-offer>
                                              <birthday-offer *ngIf="shouldShowBirthdayOffer"
                                                              [username]="username"
                                                              [offer]="offer"
                                                              (close)="close()">
                                                  
                                              </birthday-offer>
                                              <loyalty-offer *ngIf="shouldShowLoyaltyOffer"
                                                              [username]="username"
                                                              [offer]="offer"
                                                              (close)="close()">

                                              </loyalty-offer>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="mdc-dialog__scrim" (click)="close()"></div>
                              </div>
                          `,
               styles: [require('./offer-modal.scss')],
           })

export class OfferModal implements AfterViewInit {
    @ViewChild('content', { static: true }) private contentRef: ElementRef;
    public modal: MDCDialog;
    @ViewChild('modal', { static: true }) private modalRef: ElementRef;
    @Output() private onClose = new EventEmitter();
    @Output() private scroll = new EventEmitter();
    @Input() private backgroundClose: boolean = true;
    @Input() private closeIcon: boolean = false;

    @Input() public offer: Offer;
    @Input() public username: string;

    private static modalsShown = {
        [OfferType.LegacyWelcomeOffer]: false,
        [OfferType.LegacyWelcomeDelayedOffer]: false,
        [OfferType.BirthdayOffer]: false,
        [OfferType.LoyaltyOffer]: false,
    };

    public get isOpen(): boolean {
        if (!this.modal) return false;
        return this.modal.isOpen;
    }

    public get shouldShowLegacyOffer(): boolean{
        return this.isOpen && (this.offer.offerType === OfferType.LegacyWelcomeOffer || this.offer.offerType === OfferType.LegacyWelcomeDelayedOffer);
    }

    public get shouldShowBirthdayOffer(): boolean{
        return this.isOpen && this.offer.offerType === OfferType.BirthdayOffer;
    }

    public get shouldShowLoyaltyOffer(): boolean{
        return this.isOpen && this.offer.offerType === OfferType.LoyaltyOffer;
    }

    public close() {
        if (!this.modal) return;
        this.modal.close();
    }

    public ngAfterViewInit() {
        this.modal = new MDCDialog(this.modalRef.nativeElement);
        if (!this.backgroundClose) {
            this.modal.scrimClickAction = '';
            this.modal.escapeKeyAction = '';
        }
        this.modal.listen('MDCDialog:closing', () => {
            document.body.style.overflow = 'auto';
            this.onClose.emit();
        });
    }

    public open() {
        if (this.modal.isOpen || this.wasShown()) return;
        this.modal.open();
        this.setAsShown();
    }

    public set escapeKeyAction(val: string) {
        this.modal.escapeKeyAction = val;
    }

    public get escapeKeyAction(): string {
        return this.modal.escapeKeyAction;
    }

    private setAsShown(): void {
        OfferModal.modalsShown[this.offer.offerType] = true;
        if( this.offer.offerType === OfferType.BirthdayOffer || this.offer.offerType === OfferType.LoyaltyOffer ){
            let expire = moment().add('7','days');
            localStorage.setItem(this.offer.offerType + 'LayerShown', expire.toISOString());
        }
    }

    private wasShown(): boolean {

        if( OfferModal.modalsShown[this.offer.offerType] === true ){
            return true;
        }

        if( this.offer.offerType === OfferType.BirthdayOffer || this.offer.offerType === OfferType.LoyaltyOffer ){
            let date = localStorage.getItem(this.offer.offerType + 'LayerShown');
            if( date ){
                let doNotShowUntil = new Date(Date.parse(date));
                let now = new Date();
                if( doNotShowUntil.getTime() > now.getTime() ){
                    OfferModal.modalsShown[this.offer.offerType] = true;
                }
            }
        }
        return OfferModal.modalsShown[this.offer.offerType];
    }
}
