import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { LovestoryListItem } from 'BKModels';
import { LovestoryService } from '../../../../service/lovestory.service';
import { Carousel } from '../../../../shared/mat-design/carousel/carousel';

@Component({
               selector:  'logout-lovestory',
               template:  `
                              <div class="logout-lovestory" *ngIf="!isEmpty">
                                  <div class="logout-lovestory__content">
                                      <div class="logout-lovestory__title">{{'LOGOUT.LOVESTORY.TITLE' | translate}}</div>
                                      

                                      <div class="logout-lovestory__content__list">
                                          <icon class="logout-lovestory__arrow is-hidden-tablet" icon="arrow-back-ios" (click)="carousel.previous()"></icon>
                                          <carousel #carousel [hideControls]="true">
                                              <ng-container *ngFor="let story of lovestoryList;">
                                                  <ng-container *carouselItem>
                                                      <logout-lovestory-box [lovestory]="story"></logout-lovestory-box>
                                                  </ng-container>
                                              </ng-container>
                                          </carousel>
                                          <icon class="logout-lovestory__arrow is-hidden-tablet" icon="arrow-forward-ios" (click)="carousel.next()"></icon>
                                      </div>
                                      <div class="logout-lovestory__arrow-box is-hidden-desktop">
                                          <icon class="logout-lovestory__arrow" icon="arrow-back-ios" (click)="carousel.previous()"></icon>
                                          <icon class="logout-lovestory__arrow" icon="arrow-forward-ios" (click)="carousel.next()"></icon>

                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./logout-lovestory.scss')],
           })

export class LogoutLovestory implements OnInit {
    private lovestoryList: LovestoryListItem[] = [new LovestoryListItem()];
    private isEmpty = true;
    @ViewChild('carousel', {static: false}) private carousel: Carousel;

    public constructor(private lovestoryService: LovestoryService) {}

    public ngOnInit(): void {
        this.lovestoryService.getList(10).then(list => {
            if (list.length > 0){
                this.lovestoryList = list;
                this.isEmpty = false;
            }
        });
    }

}
