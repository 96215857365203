import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'images-drag-drop',
               template:  `
                              <div class="edit-images-drag-drop"
                                   [class.edit-images-drag-drop--disabled]="disabled"
                                   (drop)="drop($event)"
                                   (dragover)="dragOverHandler($event)"
                                   (click)="click()"
                              >
                                  <div class="edit-images-drag-drop__content">


                                      <div *ngIf="!disabled; else inputDisabled">
                                          <icon class="edit-images-drag-drop__content__icon" icon="photo-upload"></icon>
                                          <div>{{'EDIT-IMAGES.DRAG-DROP.UPLOAD' | translate}}</div>
                                      </div>

                                      <ng-template #inputDisabled>
                                          <icon class="edit-images-drag-drop__content__icon" icon="photo-upload-stop"></icon>
                                          <div> {{'EDIT-IMAGES.DRAG-DROP.DISABLED' | translate}}</div>
                                      </ng-template>


                                  </div>
                              </div>

                              <input type="file"
                                     accept="image/*"
                                     class="edit-images-drag-drop__input"
                                     [attr.id]="inputId"
                                     (change)="select($event)"
                              >
                          `,
               styles: [require('./images-drag-drop.scss')],
           })

export class ImagesDragDrop {
    @Input() private disabled: boolean = false;
    private inputId = 'edit-images-drag-and-drop-input';
    @Output() private onDrop = new EventEmitter();

    private click() {
        document.getElementById(this.inputId)
                .click();
    }

    private dragOverHandler(event) {
        event.preventDefault();
    }

    private drop(event) {
        event.preventDefault();
        this.renderImg(event.dataTransfer.files[0]);

    }

    private renderImg(image) {
        const reader = new FileReader();
        reader.onload = (data) => {
            const renderedImage = (data.target as any).result;
            this.onDrop.emit(renderedImage);
        };

        reader.readAsDataURL(image);
    }

    private select(event) {
        this.renderImg(event.target.files[0]);
    }


}
