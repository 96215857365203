import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    Conversation,
    UserBaseView,
} from 'BKModels';
import { PremiumDetails } from '../../../../../models/premium-details';
import { UserCounts } from '../../../../../models/user-counts';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector: 'settings-account-delete-basic-step-list',
               template: `                         
                              <settings-account-delete-title [title]="'ACCOUNT.DELETE.STEP.LIST.TITLE' | translate"
                                                             [subtitle]="'ACCOUNT.DELETE.STEP.LIST.SUBTITLE' | translate"
                                                             [text]="'ACCOUNT.DELETE.STEP.LIST.TEXT' | translate"
                              ></settings-account-delete-title>

                              <cancel-list-header *ngIf="conversations.length == 2" [titleText]="'ACCOUNT.DELETE.STEP.LIST.MESSAGES' | translate" (action)="openPostbox()">
                                  <div class="settings-account-delete-step-list__item" (click)="openPostbox();">
                                      <div class="settings-account-delete-step-list__conversation" *ngFor="let conv of conversations">
                                          <div class="settings-account-delete-step-list__conversation__user">Von {{conv.partner.nickname }}:</div>
                                          <div class="settings-account-delete-step-list__conversation__msg">{{ conv.lastMessage }}</div>
                                      </div>
                                  </div>

                              </cancel-list-header>
                              
                              <cancel-user-list [userList]="favoritesList"
                                                (action)="openFavorites()"
                                                [titleText]="'ACCOUNT.DELETE.STEP.LIST.FAVORITE.TITLE'|translate:{count:this.counts.favorites.toString()}"
                                                [buttonText]="'ACCOUNT.DELETE.STEP.LIST.FAVORITE.ACTION'|translate"
                              ></cancel-user-list>
                              
                              <cancel-list-header [noLink]="true" [titleText]="'ACCOUNT.DELETE.STEP.LIST.DELETE' | translate"></cancel-list-header>

                              
                              
                              <settings-account-delete-footer [cancelText]="'ACCOUNT.DELETE.STEP.LIST.CANCEL' | translate"
                                                              (cancel)="cancel.emit($event)"
                                                              [nextText]="'ACCOUNT.DELETE.STEP.LIST.NEXT' | translate"
                                                              (next)="next.emit($event)"
                              ></settings-account-delete-footer>

                          `,
               styles: [require('./settings-account-delete-step-list.scss')],
           })

export class SettingsAccountDeleteBasicStepList {
    @Output() private cancel = new EventEmitter();
    @Output() private next = new EventEmitter();

    @Input() private favoritesList: UserBaseView[] = [];
    @Input() private conversations: Conversation[] = [];
    @Input() private counts: UserCounts;


    constructor(private navigationService: NavigationService) {
    }


    private openFavorites() {
        this.navigationService.navigateTo(Routes.Favorite);
    }

    private openPostbox() {
        this.navigationService.navigateTo(Routes.MessagesUnread);
    }


}
