/**
 * Created by olifra on 22.05.2017.
 */

import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import { ApiResponseFrame } from 'BKModels';
import { Endpoints } from 'BKUtils';
import { Observable } from 'rxjs';
import { ApiRequestService } from '../api-request.service';

/**
 * Service der die Api für den Geolocationservice zurverfügung stellt
 */
export class GeolocationApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {

    }

    /**
     * ApiCall für die Möglichen geolocation.api.ts
     */
    public search(country = 'D', term: string, limit: number = 5): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.geoLocation.search, {
                       body:  {
                           country,
                           searchTerm: term,
                           limit,
                           client_id:  BkConfig.client.id,
                       },
                   });
    }
}
