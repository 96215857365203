import { Geolocation } from '../geolocation';
import {
    AccountState,
    createRelationshipFromApiRelationship,
    CustomerLifeTimePhase,
    Gender,
    Online,
    Relationship,
} from './status';
/**
 * Repräsentation des Aktuellen Benutzers
 * @author olifra
 */
import { UserBaseView } from './user-base-view';

export class CurrentUser extends UserBaseView {
    /**
     * Status des Users
     * - 0 : Alles in Ordnug
     * - 1 : Account ist gesperrt
     * - 2 : Account wurde noch nicht freigeschaltet
     * - 3 : So wie 2, nur wurde schon ein Profilbild hochgeladen
     * - 7 : Aufgrund von offener Rechnung gesperrt
     *
     {AccountState}
     */
    public accountState: AccountState = AccountState.Deactivated;

    /**
     * Alter des Users
     {number}
     */
    public age: number = 18;

    /**
     * Land des Users
     {string}
     */
    public country: string = '';

    /**
     * Credits
     {number}
     */
    public credits: number = 0;

    /**
     *  - -1 : NotLoaded
     *  - 0 : Registration
     *  - 1 : Validation
     *  - 2 :PreValidated
     *  - 3 : FailedValidation
     *  - 4 : EarlyBasic
     *  - 5 : AverageBasic
     *  - 6 : LongtimeBasic
     *  - 7 : EarlyPremium
     *  - 8 : AveragePremium
     *  - 9 : LogtimePremium
     {CustomerLifeTimePhase}
     */
    public customerLifeTimePhase: CustomerLifeTimePhase = CustomerLifeTimePhase.NotLoaded;

    /**
     * Noch zu bestätigende E-Mail-Adresse oder null
     * {String?}
     */
    public emailChangeInProcess?: string = null;

    /**
     * is die Email validiert
     {boolean}
     */
    public emailValidated: boolean = true;

    /**
     * zeigt an ob die verpflichtend Felder im Profil gesetzt sind
     {boolean}
     */
    public filledMandatoryDetails: boolean = false;

    /**
     * Geschlecht des Users
     {string}
     */
    public gender: Gender = Gender.None;

    /**
     * GeoId des Ortes
     {number}
     */
    public geoId: number = 0;
    /**
     * true if the user has a active subscribtion
     */
    public hasSubscription: boolean = false;
    /**
     * Indicates whether the user set the stay on beta switch to on
     */
    public stayOnBeta: boolean = false;
    /**
     * Hobbys
     {string}
     */
    public hobbys: string = '';
    /**
     * Prüfunge der Identität ist im Gange
     {boolean}
     */
    public identityValidationPending: boolean = false;
    /**
     * Anzahl der Logins
     {number}
     */
    public logins: number = 2;
    /**
     * Neue Matches
     {number}
     */
    public matches: number = 0;
    /**
     * Motto
     {string}
     */
    public motto: string = '';
    /**
     * Idenitäts Validierung wird benötigt
     {boolean}
     */
    public needIdentityValidation: boolean = false;
    /**
     * Online Status
     * - 0 : Offline
     * - 1 : Online
     * - 2 : Abwesend
     * - 3 : Mobil Online
     * - 4 : Mobil abwesend
     *
     {Online}
     */
    public online: Online = Online.Offline;
    /**
     * offene Rechnung
     {boolean}
     */
    public openInvoice: boolean = false;

    public invoice: {
        dueDate: number,
        total: number,
        reference: string
    } = null;
    /**
     * Offene Matches
     {number}
     */
    public openMatches: number = 0;
    /**
     * Photo Validation ist im Gange
     {boolean}
     */
    public photoCheckValidationPending: boolean = false;
    /**
     * Postleitzahl
     {string}
     */
    public postCode: string = '';
    /**
     * Premium flag
     {boolean}
     */
    public premium: boolean = false;
    /**
     * permium wird benötigt
     {boolean}
     */
    public premiumNeeded: boolean = false;
    /**
     * Premium bis
     {Date}
     */
    public premiumTill: Date = new Date();
    /**
     * Procent angabe wie weit das Profile gefüllt ist
     {number}
     */
    public profileDataFilled: number = 0;
    /**
     * Neue Profilebesucher
     {number}
     */
    public profileVisitors: number = 0;
    public totalMatches: number = 0;
    /**
     * Beziehungsstatus
     {Relationship}
     */
    public relationship: Relationship = Relationship.AskMe;
    /**
     * Remaining Contingent for Matching
     {number}
     */
    public remainingMatchingContingent: number = 0;
    /**
     * Gesuchtes Geschlecht
     {string}
     */
    public searchGender: Gender = Gender.Both;
    /**
     * Ungelesende Nachrichten
     {number}
     */
    public unreadedMessages: number = 0;
    /**
     * VIP status
     {boolean}
     */
    public vip: boolean = false;
    /**
     * Wall of Fame Active
     {boolean}
     */
    public wallOfFameActive: boolean = false;

    public testGroups: { name: string, group: number }[] = [];

    /**
     * Creator für den Actuellen benutzer
     data
     {CurrentUser}
     */
    public static create(data ?: any): CurrentUser {
        let resData = new CurrentUser();
        if (data !== null && data !== undefined) {
            resData.premium = data.premium;
            resData.premiumTill = data.premiumTill instanceof Date ? data.premiumTill : new Date(data.premiumTill * 1000);
            resData.openInvoice = data.openInvoice;
            resData.premiumNeeded = data.premiumNeeded;
            resData.needIdentityValidation = data.needIdentityValidation;
            resData.identityValidationPending = data.identityValidationPending;
            resData.photoCheckValidationPending = data.photoCheckValidationPending;
            resData.accountState = data.accountState;
            resData.vip = !!data.vip;
            resData.ageFrom = data.ageFrom;
            resData.ageTill = data.ageTill;
            resData.emailValidated = data.emailValidated;
            resData.profileDataFilled = data.profileDataFilled;
            resData.country = data.country;
            resData.id = data.id;
            resData.nickname = data.nickname;
            resData.latitude = data.latitude;
            resData.longitude = data.longitude;
            resData.age = data.age;
            resData.gender = data.gender;
            resData.image = data.image;
            resData.postCode = data.postCode;
            resData.city = data.city;
            resData.motto = data.motto;
            resData.hobbys = data.hobbys;
            resData.searchGender = data.searchGender;
            resData.relationship = createRelationshipFromApiRelationship(data.relationship);
            resData.echeck = data.echeck;
            resData.online = data.online;
            resData.credits = data.credits;
            resData.wallOfFameActive = data.wallOfFameActive;
            resData.customerLifeTimePhase = data.customerLifeTimePhase;
            resData.logins = data.logins;
            resData.filledMandatoryDetails = data.filledMandatoryDetails;
            resData.remainingMatchingContingent = data.remainingMatchingContingent;
            resData.emailChangeInProcess = data.emailChangeInProcess;
            resData.geoId = data.geoId;
            resData.hasSubscription = data.hasSubscription;
            resData.stayOnBeta = data.stayOnBeta;
            resData.testGroups = data.testGroups;
            resData.messageReceive = data.messageReceive;
            resData = CurrentUser.unpackSubFields(resData, data);
        }
        return resData;
    }

    private static unpackSubFields(resData: CurrentUser, data: any): CurrentUser {
        if (data.badgeCounts) {
            resData.setBadgeCounts(data.badgeCounts);
        } else {
            resData.setBadgeCounts({
                unreadedMessages : data.unreadedMessages,
                openMatches : data.openMatches,
                matches : data.matches,
                profileVisitors : data.profileVisitors,
                totalMatches : data.totalMatches,
            });
        }

        if (data.invoice) {
            resData.invoice = {
                total:     data.invoice.total,
                dueDate:   data.invoice.dueDate,
                reference: data.invoice.reference,
            };
        }

        return resData;
    }

    private setBadgeCounts(badgeCountData) {
        this.unreadedMessages = badgeCountData.unreadedMessages ? badgeCountData.unreadedMessages : 0;
        this.openMatches = badgeCountData.openMatches ? badgeCountData.openMatches : 0;
        this.matches = badgeCountData.matches ? badgeCountData.matches : 0;
        this.totalMatches = badgeCountData.totalMatches ? badgeCountData.totalMatches : 0;
        this.profileVisitors = badgeCountData.profileVisitors ? badgeCountData.profileVisitors : 0;
    }

    public createGeolocation(): Geolocation {
        const res: Geolocation = {};
        res.geoId = this.geoId;
        res.city = this.city;
        res.postalCode = this.postCode;
        res.displayValue = this.city;
        return res;
    }

    /**
     * Account is Aktive
     {boolean}
     */
    public isActivated(): boolean {
        return this.accountState === AccountState.Activated;
    }

    /**
     * Account ist Deactivated
     {boolean}
     */
    public isDeactivated(): boolean {
        return this.accountState === AccountState.Deactivated;
    }

    /**
     * Account ist in der Registreirung und noch nicht freigeschaltet
     {boolean}
     */
    public isInRegistration(): boolean {
        return this.accountState === AccountState.InRegistration;
    }

    /**
     * user ist in der Registriungsphase
     {boolean}
     */
    public isInRegistrationPhase(): boolean {
        return this.customerLifeTimePhase === CustomerLifeTimePhase.Registration;
    }

    /**
     * Account ist in der Registreirung und noch nicht freigeschaltet
     * aber hat ein Bild hochgeladen
     {boolean}
     */
    public isInRegistrationWithImage(): boolean {
        return this.accountState === AccountState.InRegistrationWithImage;
    }

    /**
     * return true when contingent Remaining
     {boolean}
     */
    public isMatchingContingentRemaining(): boolean {
        return this.remainingMatchingContingent > 0;
    }

    /**
     * isMobilAway
     {boolean}
     */
    public isMobilAway(): boolean {
        return this.online === Online.MobilAway;
    }

    /**
     * isMobilOnline
     {boolean}
     */
    public isMobilOnline(): boolean {
        return this.online === Online.MobilOnline;
    }

    /**
     * der Account hat eine Offene Rechnug
     {boolean}
     */
    public hasOpenInvoice(): boolean {
        return this.openInvoice;
    }

    /**
     * user ist noch nicht geladen
     {boolean}
     */
    public isOutOfLoadingPhase(): boolean {
        return this.customerLifeTimePhase !== CustomerLifeTimePhase.NotLoaded;
    }

    /**
     * user hat Premium
     {boolean}
     */
    public isPremium(): boolean {
        return !!this.premium;
    }

    /**
     * hat der benutzer ein profil bild
     {boolean}
     */
    public profileImgExist(): boolean {
        return this.image !== '';
    }

    public isVip(): boolean {
        return this.vip;
    }
}
