import { Location } from '@angular/common';
import {
    Injectable,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    Event,
    NavigationEnd,
    NavigationExtras,
    Router,
} from '@angular/router';
import { OrderReason } from 'BKModels';
import {
    addForcedLogoutEventListener,
    addLogoutEventListener,
    dispatchNavigationFinishedEvent,
} from 'BKUtils';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    NavigationRoute,
    NavigationRouteIntern,
} from './navigation/navigation-route';
import { DataLayerService } from './tracking/dataLayer.service';

export enum Routes {
    ActiveUsers,
    Coins,
    Contacts,
    ContactsVisitors,
    ContactsVisitorsMyVisits,
    ContactsFavorites,
    ContactsFavoritesDeleted,
    DeleteProfile,
    Favorite,
    FavoriteExist,
    FavoriteDeleted,
    Gallery,
    PremiumOverview,
    PremiumLandingPage,
    PremiumBooking,
    PremiumBookingPage,
    PremiumBookingAfterSale,
    PremiumCancel,
    Intern,
    Login,
    Logout,
    Lovestory,
    LovestoryFormular,
    MailSettings,
    MailSettingsWeeklySummary,
    MailSettingsProfileVisitor,
    MailSettingsUserSuggestion,
    Matching,
    MatchingLikeYou,
    MatchingPartner,
    MatchingStart,
    MatchingYouWantMeet,
    MatchingSecondChance,
    Conversation,
    MessagesAll,
    MessagesConversation,
    MessagesUnread,
    MessagesSystem,
    MyProfile,
    ProfileVisitors,
    ProfileVisitorsOtherVisits,
    ProfileVisitorsMyVisits,
    ResetPassword,
    RegCode,
    Search,
    SendRecoveryEmail,
    Settings,
    SettingsAccount,
    SettingsPrivacy,
    SettingsIgnoreList,
    SettingsNotification,
    SettingsPassword,
    SettingChangeSepa,
    SettingsDebitAgain,
    Startpage,
    ProfileView,
    VerifyEmailChange,
    Empty,
    AccountDeleteSuccessful,
    CancelContracts,
    SchlagerparadiesOverview
}

export enum RouteParameter {
    TariffId = 'tariffId',
    PaymentType = 'paymentType',
    PurchaseDate = 'purchaseDate',
    StartDate = 'startDate',
    ConversationId = 'conversationId'
}

export enum QueryParameter {
    OrderReason = 'or',
    OfferId = 'offerId',
}

export enum ProfileEditRouteFragment {
    Authenticity = 'authenticity',
    MainData = 'mainData',
    Look = 'look',
    Search = 'search',
    Personal = 'personal',
    Images = 'images',
}

export enum SearchRouteFragment {
    OpenExtendedSearch = 'openExtendedSearch',
}

export enum PremiumLandingPageFragment {
    Offers = 'offers',
}

export enum StartpageRouteFragment {
    SelectedUser = 'selectedUser',
}

export enum MatchingRouteFragment {
    Explain = 'explain',
}

const RouteNames: { [key in Routes]: string } = {
    [Routes.ActiveUsers]:                   'active-users',
    [Routes.Coins]:                         'coins',
    [Routes.Contacts]:                      'contacts',
    [Routes.ContactsVisitors]:              'visitors',
    [Routes.ContactsVisitorsMyVisits]:      'visited',
    [Routes.ContactsFavorites]:             'favorites',
    [Routes.ContactsFavoritesDeleted]:      'favorites-deleted',
    [Routes.Favorite]:                      'favorite',
    [Routes.FavoriteExist]:                 'exist',
    [Routes.FavoriteDeleted]:               'deleted',
    [Routes.Gallery]:                       'gallery',
    [Routes.PremiumOverview]:               'premium',
    [Routes.PremiumLandingPage]:            'premium-kaufen',
    [Routes.PremiumBooking]:                `purchase`,
    [Routes.PremiumBookingPage]:        `:${RouteParameter.TariffId}`,
    [Routes.PremiumBookingAfterSale]:   `after-sale/:${RouteParameter.TariffId}/:${RouteParameter.PaymentType}/:${RouteParameter.PurchaseDate}/:${RouteParameter.StartDate}`,
    [Routes.PremiumCancel]:             'cancel',
    [Routes.Intern]:                    'intern',
    [Routes.Login]:                     'login',
    [Routes.Logout]:                    'logout',
    [Routes.Lovestory]:                 'lovestories',
    [Routes.LovestoryFormular]:         'formular',
    [Routes.MailSettings]:              'mail-settings',
    [Routes.MailSettingsWeeklySummary]: 'weekly-summary',
    [Routes.MailSettingsProfileVisitor]:'profile-visitor',
    [Routes.MailSettingsUserSuggestion]:'user-suggestion',
    [Routes.Matching]:                  'matching',
    [Routes.MatchingLikeYou]:           'like-you',
    [Routes.MatchingPartner]:           'partner',
    [Routes.MatchingStart]:             'start',
    [Routes.MatchingYouWantMeet]:       'you-want',
    [Routes.MatchingSecondChance]:      'second-chance',
    [Routes.Conversation]:              'conversation',
    [Routes.MessagesAll]:               'all',
    [Routes.MessagesConversation]:      `messages/:${RouteParameter.ConversationId}`,
    [Routes.MessagesUnread]:            'unread',
    [Routes.MessagesSystem]:            'system',
    [Routes.MyProfile]:                     'my-profile',
    [Routes.ProfileVisitors]:               'visitors',
    [Routes.ProfileVisitorsOtherVisits]:    'others',
    [Routes.ProfileVisitorsMyVisits]:       'visited',
    [Routes.ResetPassword]:                 'password-recovery',
    [Routes.RegCode]:                       'confirm-email',
    [Routes.Search]:                        'search',
    [Routes.SendRecoveryEmail]:             'send-recovery',
    [Routes.Settings]:                      'settings',
    [Routes.SettingsAccount]:               'account',
    [Routes.SettingsPrivacy]:               'privacy',
    [Routes.SettingsIgnoreList]:            'ignores',
    [Routes.SettingsNotification]:          'notification',
    [Routes.SettingsPassword]:              'password',
    [Routes.SettingChangeSepa]:             'change-sepa',
    [Routes.SettingsDebitAgain]:            'debit-again',
    [Routes.DeleteProfile]:                 'delete',
    [Routes.Startpage]:                     'startpage',
    [Routes.ProfileView]:                   'profileView',
    [Routes.VerifyEmailChange]:             'verify-email-change/:token',
    [Routes.AccountDeleteSuccessful]:       'delete/successful',
    [Routes.CancelContracts]:               'cancel-contracts',
    [Routes.SchlagerparadiesOverview]:      'schlagerherzen',
    [Routes.Empty]:                         ''
};

export function routeName(routes: Routes): string {
    return RouteNames[routes];
}

const navigationRoutes: { [key in Routes]: NavigationRoute } = {
    [Routes.ActiveUsers]:          new NavigationRouteIntern(Routes.ActiveUsers),

    [Routes.Conversation]:         new NavigationRouteIntern(Routes.Conversation),
    [Routes.MessagesAll]:          new NavigationRouteIntern(Routes.Conversation, Routes.MessagesAll),
    [Routes.MessagesConversation]: new NavigationRouteIntern(Routes.Conversation, Routes.MessagesConversation),
    [Routes.MessagesUnread]:       new NavigationRouteIntern(Routes.Conversation, Routes.MessagesUnread),

    [Routes.MessagesSystem]:       new NavigationRouteIntern(Routes.Conversation, Routes.MessagesSystem),
    [Routes.MailSettings]:               new NavigationRouteIntern(Routes.MailSettings),
    [Routes.MailSettingsWeeklySummary]:  new NavigationRouteIntern(Routes.MailSettings, Routes.MailSettingsWeeklySummary),
    [Routes.MailSettingsProfileVisitor]: new NavigationRouteIntern(Routes.MailSettings, Routes.MailSettingsProfileVisitor),
    [Routes.MailSettingsUserSuggestion]: new NavigationRouteIntern(Routes.MailSettings, Routes.MailSettingsUserSuggestion),

    [Routes.Matching]:            new NavigationRouteIntern(Routes.Matching),
    [Routes.MatchingLikeYou]:     new NavigationRouteIntern(Routes.Matching, Routes.MatchingLikeYou),
    [Routes.MatchingPartner]:     new NavigationRouteIntern(Routes.Matching, Routes.MatchingPartner),
    [Routes.MatchingStart]:       new NavigationRouteIntern(Routes.Matching, Routes.MatchingStart),
    [Routes.MatchingYouWantMeet]: new NavigationRouteIntern(Routes.Matching, Routes.MatchingYouWantMeet),
    [Routes.MatchingSecondChance]: new NavigationRouteIntern(Routes.Matching, Routes.MatchingSecondChance),

    [Routes.Favorite]:        new NavigationRouteIntern(Routes.Favorite),
    [Routes.FavoriteExist]:   new NavigationRouteIntern(Routes.Favorite, Routes.FavoriteExist),

    [Routes.FavoriteDeleted]: new NavigationRouteIntern(Routes.Favorite, Routes.FavoriteDeleted),
    [Routes.Lovestory]:         new NavigationRouteIntern(Routes.Lovestory),

    [Routes.LovestoryFormular]: new NavigationRouteIntern(Routes.Lovestory, Routes.LovestoryFormular),
    [Routes.PremiumOverview]:         new NavigationRouteIntern(Routes.PremiumOverview),
    [Routes.PremiumCancel]:           new NavigationRouteIntern(Routes.PremiumOverview, Routes.PremiumCancel),
    [Routes.PremiumLandingPage]:      new NavigationRouteIntern(Routes.PremiumLandingPage),
    [Routes.PremiumBooking]:          new NavigationRouteIntern(Routes.PremiumBooking),
    [Routes.PremiumBookingPage]:      new NavigationRouteIntern(Routes.PremiumBooking, Routes.PremiumBookingPage),


    [Routes.PremiumBookingAfterSale]: new NavigationRouteIntern(Routes.PremiumBooking, Routes.PremiumBookingAfterSale),
    [Routes.Settings]:             new NavigationRouteIntern(Routes.Settings),
    [Routes.SettingsAccount]:      new NavigationRouteIntern(Routes.Settings, Routes.SettingsAccount),
    [Routes.SettingsPrivacy]:      new NavigationRouteIntern(Routes.Settings, Routes.SettingsPrivacy),
    [Routes.SettingsIgnoreList]:   new NavigationRouteIntern(Routes.Settings, Routes.SettingsIgnoreList),
    [Routes.SettingsNotification]: new NavigationRouteIntern(Routes.Settings, Routes.SettingsNotification),
    [Routes.SettingsPassword]:     new NavigationRouteIntern(Routes.Settings, Routes.SettingsPassword),
    [Routes.SettingChangeSepa]:    new NavigationRouteIntern(Routes.Settings, Routes.SettingChangeSepa),
    [Routes.SettingsDebitAgain]:   new NavigationRouteIntern(Routes.Settings, Routes.SettingsDebitAgain),

    [Routes.DeleteProfile]:        new NavigationRouteIntern(Routes.Settings, Routes.DeleteProfile),
    [Routes.Startpage]:                     new NavigationRouteIntern(Routes.Startpage),
    [Routes.Search]:                        new NavigationRouteIntern(Routes.Search),
    [Routes.ProfileVisitors]:               new NavigationRouteIntern(Routes.ProfileVisitors),
    [Routes.ProfileVisitorsOtherVisits]:    new NavigationRouteIntern(Routes.ProfileVisitors, Routes.ProfileVisitorsOtherVisits),
    [Routes.ProfileVisitorsMyVisits]:       new NavigationRouteIntern(Routes.ProfileVisitors, Routes.ProfileVisitorsMyVisits),
    [Routes.Favorite]:                      new NavigationRouteIntern(Routes.Favorite),
    [Routes.MyProfile]:                     new NavigationRouteIntern(Routes.MyProfile),
    [Routes.Coins]:                         new NavigationRouteIntern(Routes.Coins),
    [Routes.Contacts]:                      new NavigationRouteIntern(Routes.Contacts),
    [Routes.ContactsVisitors]:              new NavigationRouteIntern(Routes.Contacts, Routes.ContactsVisitors),
    [Routes.ContactsVisitorsMyVisits]:      new NavigationRouteIntern(Routes.Contacts, Routes.ContactsVisitorsMyVisits),
    [Routes.ContactsFavorites]:             new NavigationRouteIntern(Routes.Contacts, Routes.ContactsFavorites),
    [Routes.Gallery]:                       new NavigationRouteIntern(Routes.Gallery),
    [Routes.ContactsFavoritesDeleted]:      new NavigationRouteIntern(Routes.Contacts, Routes.ContactsFavoritesDeleted),
    [Routes.ProfileView]:                   new NavigationRouteIntern(Routes.ProfileView),

    [Routes.SchlagerparadiesOverview]: new NavigationRouteIntern(Routes.SchlagerparadiesOverview),

    [Routes.Intern]:                  new NavigationRoute(Routes.Intern),
    [Routes.Logout]:                  new NavigationRoute(Routes.Logout),
    [Routes.Login]:                   new NavigationRoute(Routes.Login),
    [Routes.ResetPassword]:           new NavigationRoute(Routes.ResetPassword),
    [Routes.RegCode]:                 new NavigationRouteIntern(Routes.RegCode),
    [Routes.SendRecoveryEmail]:       new NavigationRoute(Routes.SendRecoveryEmail),
    [Routes.VerifyEmailChange]:       new NavigationRoute(Routes.VerifyEmailChange),
    [Routes.AccountDeleteSuccessful]: new NavigationRoute(Routes.AccountDeleteSuccessful),
    [Routes.CancelContracts]:         new NavigationRoute(Routes.CancelContracts),

    [Routes.Empty]:                   new NavigationRoute(Routes.Empty),
};

export function navigationRoute(route: Routes, urlParam: KeyValue = null, lang: string = ''): string {
    return navigationRoutes[route].getRouteArray(urlParam, lang)[0];
}


@Injectable({ providedIn: 'root' })
export class NavigationService {

    public language = '';

    public constructor(
        private router: Router,
        private location: Location,
        private dataLayerService: DataLayerService,
        private titleService: Title
    ) {
        addLogoutEventListener(() => this.onLogout());
        addForcedLogoutEventListener(() => this.onForcedLogout());

        this.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.dataLayerService.push({
                                               event:          'Page View',
                                               'content-name': event.urlAfterRedirects,
                                           });

                dispatchNavigationFinishedEvent();

                if(typeof hj === 'function') hj("stateChange", event.urlAfterRedirects);
            });
    }

    public get events(): Observable<Event> {
        return this.router.events;
    }

    public get url(): string {
        return this.router.url;
    }

    public getRoute(route: Routes, urlParam: KeyValue = null): string[] {
        return navigationRoutes[route].getRouteArray(urlParam, this.language);
    }

    public getSerializedRoute(route: Routes, urlParam: KeyValue = null): string {
        return this.serializeCommand(this.getRoute(route, urlParam));
    }

    public navigateTo(route: Routes, extras: any = { skipLocationChange: false }) {
        return this.router.navigate(this.getRoute(route, extras.urlParam), extras);
    }

    /**
     * Ignores all options of NavigationExtras that alter the url e.g. queryParams
     * @param url
     * @param extras
     */
    public navigateToUrl(url: string, extras: NavigationExtras = { skipLocationChange: false }) {
        return this.router.navigateByUrl(url, extras);
    }

    public navigateToExternalUrl(url: string) {
        window.location.href = url;
    }

    /**
     * Mostly the same as navigateToUrl but also considers uri altering options
     * @param url
     * @param extras
     */
    public navigate(url: string, extras: NavigationExtras = { skipLocationChange: false }) {
        return this.router.navigate([url], extras);
    }

    public navigateBack() {
        this.location.back();
    }

    public isRouteActive(route: Routes, urlParam: KeyValue = null): boolean {
        const url = this.getRoute(route, urlParam).join('/');
        return this.router.isActive(url, { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' });
    }

    private serializeCommand(command: any[]): string {
        const urlTree = this.router.createUrlTree(command);
        return this.router.serializeUrl(urlTree);
    }

    public navigateToPurchasePage(tariffId: number, orderReason: OrderReason = OrderReason.None, offerId: string = null) {
        const queryParams = {};
        if (orderReason !== OrderReason.None) queryParams[QueryParameter.OrderReason] = OrderReason.getQueryString(orderReason);
        if (offerId !== null) queryParams[QueryParameter.OfferId] = offerId;
        this.navigateTo(Routes.PremiumBookingPage, {
            urlParam: {
                [RouteParameter.TariffId]: tariffId,
            },
            queryParams,
        });
    }

    private onLogout() {
        this.navigateTo(Routes.Logout);
        this.titleService.setTitle(this.titleService.getTitle().split(' ')[1]);
    }

    private onForcedLogout() {
        this.navigateTo(Routes.Login);
    }
}
