import {
    ApiResponseFrame,
    BillingAddress,
    CashReference,
    DebitRequest,
    SepaAccount,
} from 'BKModels';
import { CommunicationService } from 'BKService';
import { Endpoints } from 'BKUtils';
import { OpenInvoice } from '../../../models/open-invoice';
import { ProductList } from '../../../models/productList';
import { Tariff } from '../../../models/tariff';
import { ApiRequestService } from '../api-request.service';
import { PlaystorePaymentState } from './playstore-payment-state';

export class PremiumDebitResult {
    public startAt: Date;
    public endAt: Date;
    public transactionId: string;
    public vat: number;

    public static create(obj: PremiumDebitResult): PremiumDebitResult {
        const res = new PremiumDebitResult();
        res.startAt = obj.startAt;
        res.endAt = obj.endAt;
        res.transactionId = obj.transactionId;
        res.vat = obj.vat;
        return res;
    }
}

export class PaymentApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public billingAddress(): Promise<BillingAddress> {
        return this.apiRequestService
                   .createGet<BillingAddress>(Endpoints.payment.billingAddress)
                   .then(res => BillingAddress.create(res));
    }

    public sepaAccount(): Promise<SepaAccount> {
        return this.apiRequestService
                   .createGet<SepaAccount>(Endpoints.payment.sepaAccount)
                   .then(res => SepaAccount.create(res));
    }

    public directDebit(debitRequest: DebitRequest): Promise<PremiumDebitResult> {
        return this.apiRequestService.createPost<any>(Endpoints.payment.directDebit, {
            body: debitRequest,
            json: true,
        }).then(res => PremiumDebitResult.create(res.data));
    }

    public getCashReference(userId, tariffId): Promise<CashReference> {
        return this.apiRequestService.createGet(Endpoints.payment.cashReference, {
            query: {
                consumerId: userId,
                tariffId,
            },
        }).then(res => res['data']);
    }

    //TODO Sollte vielleicht aus dem PaymentService auch in den Offerservice
    public produtcs(): Promise<ProductList> {
        return this.apiRequestService.createGet<ApiResponseFrame<ProductList>>(Endpoints.payment.products).then(response => {
            return ProductList.create(response.data);
        });
    }

    public tariff(tariffId): Promise<Tariff> {
        return this.apiRequestService.createGet<ApiResponseFrame<Tariff>>(Endpoints.payment.tariff, {
            endpointValue: {
                tariffId,
            },
        }).then(res => Tariff.create(res.data));
    }

    public changePaymentData(billingAddress: BillingAddress, sepaAccount: SepaAccount): Promise<boolean> {
        return this.apiRequestService.createPost(Endpoints.payment.changeDebit, {
            body: {
                'billing_address': billingAddress,
                'sepa_account':    sepaAccount,
            },
            json: true,
        }).then(res => res['data'].state);
    }

    public payCash(tariff: Tariff): Promise<any> {
        return this.apiRequestService.createPost(Endpoints.payment.payCash, {
                       body: {
                           tariffId: tariff.tariffId,
                       },
                   })
                   .then(res => res['data'].state);
    }

    public openInvoices(): Promise<OpenInvoice> {
        return this.apiRequestService.createGet(Endpoints.payment.openInvoice).then(res => OpenInvoice.create(res['data']));
    }

    public debitAgain(billingAddress: BillingAddress, sepaAccount: SepaAccount): Promise<boolean> {
        return this.apiRequestService.createPost(Endpoints.payment.debitAgain, {
            body: {
                'billing_address': billingAddress,
                'sepa_account':    sepaAccount,
            },
            json: true,
        }).then(res => res['data'].state);
    }

    playstoreValidatePurchase(itemId: string, token: string): Promise<PlaystorePaymentState> {
        return this.apiRequestService.createPost(Endpoints.payment.playstoreValidate, {
            body: {
                'id': itemId,
                token
            },
            json: true,
        }).then(res => res['data'].state);
    }
}
