import { Component } from '@angular/core';

/**
 * Matching
 */
@Component({
               selector:  'bk-matching',
               template:  `
                              <div class="bk-matching">
                                  <subheader class="is-hidden-tablet">
                                      <div class="title-bar">
                                          <div class="title">{{ 'LAYOUT.SIDE_NAV.MATCHING'|translate }}</div>
                                          <separator thickness="1px"></separator>
                                      </div>
                                      <matching-tabbar></matching-tabbar>
                                  </subheader>
                                  <div class="bk-matching__content">
                                      <router-outlet></router-outlet>
                                  </div>
                              </div>

                          `,
               styles: [
                   require('./matching.scss'),
               ],
           })
export class Matching{}
