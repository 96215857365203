import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatchingPartnerDataSource } from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

/**
 * MatchingPartner
 */
@Component({
               selector: 'matching-partner',
               styles: [require('./matching-partner.scss')],
               template: `
                             <div class="bk-matching-partner">
                                 <list-empty
                                         *ngIf="isMissingProfilePicture"
                                         img="assets/img/bild-upload-pic.svg"
                                         [text]="'MATCHING.PARTNER.MISSING_PROFILE_PICTURE.TEXT'| translate | markdown"
                                         [title]="'MATCHING.PARTNER.MISSING_PROFILE_PICTURE.TITLE'|translate"
                                 >
                                     <raised-button 
                                             class="bk-matching-partner__empty-list__action"
                                             [routerLink]="navigationService.getRoute(Routes.MyProfile)" [fragment]="ProfileEditRouteFragment.Images" >
                                         {{'MATCHING.PARTNER.MISSING_PROFILE_PICTURE.ACTION' | translate}}
                                     </raised-button>
                                     <hr class="bk-matching-partner__empty-list__separator"/>
                                     <div class="bk-matching-partner__empty-list__lovestory">
                                        <div class="bk-matching-partner__empty-list__lovestory__title">
                                            {{ 'MATCHING.PARTNER.MISSING_PROFILE_PICTURE.EXTRA.TITLE'|translate }}
                                        </div>
                                        <img class="bk-matching-partner__empty-list__lovestory__image" src="assets/img/matching-stefan-anne.png"/>
                                        <div class="bk-matching-partner__empty-list__lovestory__text">
                                            {{ 'MATCHING.PARTNER.MISSING_PROFILE_PICTURE.EXTRA.TEXT'|translate }}
                                        </div>
                                     </div>
                                 </list-empty>
                                 <div [class.hide]="isEmpty || isMissingProfilePicture">
                                     <bk-matching-list-header [title]="'MATCHING.PARTNER.HEADER.TITLE'| translate:currentUser">
                                         <span [innerHTML]="'MATCHING.PARTNER.HEADER.TEXT'| translate | markdown"></span>
                                     </bk-matching-list-header>

                                     <list-container [dataSource]="listDataSource">
                                         <ng-template let-item>
                                             <list-item-selector two-columns-max
                                                                 [listItem]="item"
                                                                 (onOpenProfile)="onOpenProfile()"
                                                                 (onOpenConversation)="onOpenConversation()"
                                                                 (onAddFavorite)="onAddFavorite()"
                                                                 (onDeleteFavorite)="onDeleteFavorite()"
                                                                 (gotoPremium)="goToPremium()"
                                             ></list-item-selector>
                                         </ng-template>
                                     </list-container>
                                 </div>
                             </div>
                         `,
           })
export class MatchingPartner implements OnInit, OnDestroy {
    private listDataSource: MatchingPartnerDataSource;
    private currentUser: CurrentUser = new CurrentUser();
    private newMatchPartnerBadge = 0;
    private unsubscribe$ = new Subject();

    private Routes = Routes;
    private ProfileEditRouteFragment = ProfileEditRouteFragment;

    public constructor(
        private matchingService: MatchingService,
        private currentUserService: CurrentUserService,
        private trackingService: TrackingService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private dataSourceFactory: DataSourceFactory,
    ) {
    }

    public ngOnInit() {
        this.currentUserService
            .currentUser
            .then((currentUser) => this.currentUser = currentUser);

        this.dataSourceFactory.createMatchingPartnerDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });

        this.currentUserService
            .badgeCountsObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(badgeCounts => {
                if (this.newMatchPartnerBadge < badgeCounts.matches && !!this.listDataSource) {
                    this.listDataSource.loadNewest(badgeCounts.matches);
                }
                this.newMatchPartnerBadge = badgeCounts.matches;
            });
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private get isMissingProfilePicture(): boolean {
        return !!this.currentUser && !this.currentUser.profileImgExist();
    }

    private get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    private get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    private goToPremium() {
        this.premiumService.navigateToPremium(OrderReason.MatchPartner);
    }

    private onOpenProfile() {
        this.trackingService.hit('Matching', 'Matchpartner', 'ProfilAufgerufen');
    }

    private onOpenConversation() {
        this.trackingService.hit('Matching', 'Matchpartner', 'Konversation');
    }

    private onAddFavorite() {
        this.trackingService.hit('Matching', 'Matchpartner', 'FavoritHinzugefuegt');
    }

    private onDeleteFavorite() {
        this.trackingService.hit('Matching', 'Matchpartner', 'FavoritGeloescht');
    }
}
