import {
    AfterViewInit,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { MDCDialog } from '@material/dialog/component';

export class DialogBase implements AfterViewInit {
    @ViewChild('dialog', {static: true}) private dialogRef: ElementRef;
    private dialog: MDCDialog;

    public ngAfterViewInit(): void {
        this.dialog = new MDCDialog(this.dialogRef.nativeElement);
    }

    public close() {
        if(!this.isOpen) return;
        this.dialog.close();
    }

    public  open() {
        if(this.isOpen) return;
        this.dialog.open();
    }

    public get isOpen() {
        return this.dialog.isOpen
    }
}
