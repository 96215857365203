import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
} from '@angular/forms';
import {
    CurrentUser,
    ProfileInfo,
} from 'BKModels';
import {
    ApiService,
    CurrentUserService,
} from 'BKService';
import {
    EmailValidator,
} from 'BKShared/Validator';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';
import { TextField } from '../../../../../shared/mat-design/text-field/text-field';

enum EmailChangeStyle {
    Change,
    Resend
}

@Component({
               selector:  'profile-edit-email',
               template:  `
                              <div class="profile-edit-email">
                                  <div class="profile-edit-email__label">{{ 'PROFILE_INFO.EMAIL' | translate }}</div>

                                  <div class="profile-edit-email__current">
                                      <span>{{ currentEmailAddress }}</span>
                                      <icon class="profile-edit-email__currentIcon" icon="check"></icon>
                                  </div>
                                  <div *ngIf="hasUnconfirmedEmailAddress" class="profile-edit-email__unconfirmed">
                                      <span>{{ unconfirmedEmailAddress }}</span>
                                      <span class="profile-edit-email__waiting" (click)="showEmailChanged()"><icon icon="replay" class="profile-edit-email__waitingIcon"></icon>
                                          {{ 'PROFILE_EDIT.EMAIL.WAITING_FOR_CONFIRMATION' | translate }}</span>
                                      <flat-button (click)="abortEmailAddressChange()">{{ 'PROFILE_EDIT.EMAIL.ABORT' | translate }}</flat-button>
                                  </div>
                                  <flat-button class="profile-edit-email__change-button" (click)="showChangeEmail()">{{ 'PROFILE_EDIT.EMAIL.CHANGE' | translate }}</flat-button>

                                  <div *ngIf="(!emailValidated || unconfirmedEmailAddress) && !emailSent" class="profile-edit-email__notice-not-confirmed">
                                      {{ 'PROFILE_EDIT.EMAIL.ADDRESS_NOT_CONFIRMED'|translate }}
                                      <a (click)="showResendEmail()">{{ 'PROFILE_EDIT.EMAIL.ADDRESS_NOT_CONFIRMED.ACTION'|translate }}</a>
                                  </div>
                                  
                                  <div *ngIf="emailSent" class="profile-edit-email__notice-sent">
                                      {{ 'PROFILE_EDIT.EMAIL.EMAIL_SENT'|translate:{'email':unconfirmedEmailAddress} }}
                                  </div>
                                  
                                  <dialog-box #changeEmail
                                              [title]="emailChangeTitle | translate"
                                              [acceptText]="'PROFILE_EDIT.EMAIL.CHANGE.CONTINUE' | translate"
                                              [rejectText]="'GLOBAL.CANCEL' | translate"
                                              (onAccept)="sendEmailToBackend()"
                                              (onReject)="closeChangeEmail()"
                                              [acceptDisabled]="emailControl.invalid || emailControl.pending"
                                  >
                                      <div>{{ emailChangeText | translate }}</div>
                                      <form [formGroup]="emailForm">
                                          <text-field #emailInput
                                                      class="profile-edit-email__input"
                                                      type="email"
                                                      [placeholder]="'PROFILE_INFO.EMAIL' | translate"
                                                      [maxLength]="maxLength"
                                                      [valid]="validateEmail()"
                                                      formControlName="email"
                                                      [value]="unconfirmedEmailAddress"
                                                      [helperText]="hintText | translate"
                                                      [errorText]="emailError | translate"
                                                      [trailingIcon]="trailingIcon"
                                          ></text-field>
                                      </form>
                                  </dialog-box>
                                  <dialog-box #emailChanged
                                              [title]="'PROFILE_EDIT.EMAIL.EMAIL_CHANGED.TITLE' | translate"
                                              [acceptText]="'GLOBAL.CLOSE' | translate"
                                              (onAccept)="closeEmailChanged()"
                                  >
                                      <div [innerHTML]="'PROFILE_EDIT.EMAIL.EMAIL_CHANGED.INFO' | translate:{email:unconfirmedEmailAddress} | markdown">
                                      </div>
                                      <div>
                                          {{ 'PROFILE_EDIT.EMAIL.EMAIL_CHANGED.NO_MAIL' | translate }}
                                          <a (click)="retryChangeEmail()">{{ 'PROFILE_EDIT.EMAIL.EMAIL_CHANGED.RETRY' | translate }}</a>
                                      </div>
                                  </dialog-box>
                              </div>
                          `,
               styles: [require('./profile-edit-email.scss')],
           })

export class ProfileEditEmail implements OnInit, OnDestroy {
    @ViewChild('changeEmail', { static: true }) private changeEmail: DialogBox;
    @ViewChild('emailChanged', { static: true }) private emailChanged: DialogBox;
    @ViewChild('emailInput', { static: true }) private emailInput: TextField;
    @Input() private maxLength: number = 50;

    private emailValidated = false;

    private emailSent = false;

    private unsubscribe$ = new Subject();

    private emailForm: FormGroup;

    private get hasUnconfirmedEmailAddress(): boolean {
        return this.unconfirmedEmailAddress !== null;
    }

    private _unconfirmedEmailAddress: string = null;
    private get unconfirmedEmailAddress() {
        return this._unconfirmedEmailAddress;
    }

    private emailChangeStyle = EmailChangeStyle.Change;
    private get emailChangeTitle(): string {
        const keys = {
            [EmailChangeStyle.Change]: 'PROFILE_EDIT.EMAIL.CHANGE.TITLE',
            [EmailChangeStyle.Resend]: 'PROFILE_EDIT.EMAIL.RESEND.TITLE'
        };

        return keys[this.emailChangeStyle];
    }

    private get emailChangeText(): string {
        const keys = {
            [EmailChangeStyle.Change]: 'PROFILE_EDIT.EMAIL.CHANGE.ENTER_ADDRESS',
            [EmailChangeStyle.Resend]: 'PROFILE_EDIT.EMAIL.RESEND.ENTER_ADDRESS'
    };

        return keys[this.emailChangeStyle];
    }

    private set unconfirmedEmailAddress(address: string) {
        this._unconfirmedEmailAddress = address;
        if (this.emailInputValue.isEmpty()) this.emailInputValue = address;
    }

    private _currentEmailAddress: string = '';
    private get currentEmailAddress(): string {
        return this._currentEmailAddress;
    }

    private set currentEmailAddress(address: string) {
        this._currentEmailAddress = address;
    }

    private set currentUser(currentUser: CurrentUser) {
        this.emailValidated = currentUser.emailValidated;
        this.unconfirmedEmailAddress = currentUser.emailChangeInProcess;
    }

    private set currentProfile(profileInfo: ProfileInfo) {
        this.currentEmailAddress = profileInfo.email;
    }

    private set emailInputValue(val: string) {
        if(this.emailControl)  this.emailControl.patchValue(val);
    }


    private get emailInputValue(): string {
        return this.emailControl.value || '';
    }

    private get emailControl(): FormControl {
        return this.emailForm.controls['email'] as FormControl;
    }

    private get emailError() {
        const errors = this.emailControl.errors;
        return errors ? Object.keys(errors)[0] : '';
    }

    private get trailingIcon(): string {
        return  this.emailControl.pending ? 'hourglass-empty' : 'edit';
    }

    private get hintText(): string {
        if (this.emailControl.pending) return 'PROFILE_EDIT.EMAIL.CHANGE.PENDING_TEXT';
        if (this.emailControl.pristine) return 'PROFILE_EDIT.EMAIL.CHANGE.HINT_TEXT';
        return '';
    }


    public constructor(
        private currentUserService: CurrentUserService,
        private apiService: ApiService,
        private fb: FormBuilder,
    ) {
    }

    public ngOnInit(): void {
        this.emailForm = this.fb
                             .group({
                                        email: ['', [EmailValidator.email], [EmailValidator.used(this.apiService)]],
                                    });

        this.currentUserService
            .currentUserProfileInfo
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(cur => this.currentProfile = cur);

        this.currentUserService
            .currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(cur => this.currentUser = cur);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected validateEmail(): boolean {
        return !this.emailControl.errors || this.emailControl.pristine;
    }


    private sendEmailToBackend() {
        const address = this.emailInputValue;
        if (address.isEmpty()) return;

        this.unconfirmedEmailAddress = address;

        this.apiService.user.changeEmailAddress(address).then((value) => {
            let state = value.data.state || false;

            if (state) {
                this.closeChangeEmail();
                this.showEmailChanged();
                this.emailSent = true;
                this.currentUserService.loadCurrentUser().then(/* do nothing */);
            } else {
                this.emailControl.setErrors({"PROFILE_EDIT.EMAIL.CHANGE.ERROR": true});
                this.showChangeEmail();
            }
        }).catch((reason) => {
            this.emailControl.setErrors({"PROFILE_EDIT.EMAIL.CHANGE.ERROR": true});
            this.showChangeEmail();
        });
    }

    abortEmailAddressChange()
    {
        this.apiService.user.abortEmailAddressChange().then(()=>{
            this.currentUserService.loadCurrentUser();
            this.emailSent = false;
        });
    }

    private retryChangeEmail() {
        this.closeEmailChanged();
        this.showChangeEmail();
    }

    private showChangeEmail() {
        this.emailChangeStyle = EmailChangeStyle.Change;
        this.changeEmail.open();
    }

    private showResendEmail() {
        this.emailChangeStyle = EmailChangeStyle.Resend;
        this.changeEmail.open();
    }

    private closeChangeEmail() {
        this.changeEmail.close();
    }

    private showEmailChanged() {
        this.emailChanged.open();
    }

    private closeEmailChanged() {
        this.emailChanged.close();
    }
}
