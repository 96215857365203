/**
 * Created by olifra on 05.04.2017.
 */

/**
 * Loginuser als Json für den Apicall
 */
export interface LoginUserJson {
    /**
     * password
     * @type string
     */
    _password: string;
    /**
     * Username
     * @type string
     */
    _username: string;
}

/**
 * Klasse die den Loginuser repräsentiert
 */
export class LoginUser {
    /**
     * Password
     {string}
     */
    public password: string = '';

    /**
     * Username
     {string}
     */
    public username: string = '';

    /**
     * Konstruktor
     {string} _username
     {string} _password
     */
    public constructor(_username: string = '', _password: string = '') {
        this.username = _username;
        this.password = _password;
    }

    /**
     * Creator für den Login user
     {LoginUserJson} data
     {LoginUser}
     */
    public static create(data: LoginUserJson) {
        return new LoginUser(data._username, data._password);
    }
}

export enum LoginDisabledReasons {
    Locked = 'locked',
    PassportRequested = 'passport_requested',
    PassportInProgress = 'passport_in_progress',
    BillingIssue = 'billing_issue',
}

export interface BkLoginResponse {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    disabled: LoginDisabledReasons|null;
    payload: any;
}
