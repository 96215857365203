import {
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PremiumDetails } from '../../../../../models/premium-details';
import { ListService } from '../../../../../service/list.service';
import {
    NavigationService,
    Routes,
    SearchRouteFragment,
} from '../../../../../service/navigation.service';
import { ChargebackPaymentFailedNotification } from '../../../../../service/notification/ChargebackPaymentFailedNotification';
import { Notification } from '../../../../../service/notification/Notification';
import { NotificationService } from '../../../../../service/notification/notification.service';
import {
    PaymentService,
    StoreType,
} from '../../../../../service/payment.service';
import { PremiumService } from '../../../../../service/premium.service';
import { BkPremiumModal } from '../../../../../shared/mat-design/bk-modals/bk-premium-modal';
import { PremiumFeaturesFullModal } from '../premium-features-full-modal/premium-features-full-modal';

@Component({
               selector:  'premium-overview',
               template:  `
                              <card-title [title]="'PREMIUM.OVERVIEW.TITLE' | translate">
                                  <div class="premium-overview__user-id">
                                      {{'PREMIUM.OVERVIEW.USER-ID'| translate}} {{currentUser.id}}
                                  </div>
                                  <div class="premium-overview">
                                      <ng-container *ngFor="let item of filterRelevantNotification(notificationService.notifications$|async)">
                                          <notification-box [notification]="item"></notification-box>
                                      </ng-container>

                                      <premium-reverse-debit *ngIf="currentUser.hasOpenInvoice() && !currentUser.invoice" class="premium-overview__margin-bot"></premium-reverse-debit>


                                      <premium-info class="premium-overview__margin-bot"
                                                    [currentUser]="currentUser"
                                                    [details]="premiumDetails"
                                                    (extendNow)="extendNow($event)"
                                      ></premium-info>

                                      <div class="premium-overview__text" *ngIf="!currentUser.isPremium()" [innerHTML]="'PREMIUM.OVERVIEW.EXPLAIN' | translate | markdown"></div>

                                      <div class="premium-overview__table">
                                          <premium-table-header [premium]="currentUser.isPremium()"></premium-table-header>
                                          <premium-table-row [forBasic]="true"
                                                             [premium]="currentUser.isPremium()"
                                          >{{ 'PREMIUM.USP.WRITE_TO_MEMBERS' | translate }}</premium-table-row>
                                          <premium-table-row [forBasic]="true"
                                                             [premium]="currentUser.isPremium()"
                                          >{{ 'PREMIUM.USP.RECEIVE_MESSAGES' | translate }}</premium-table-row>
                                          <premium-table-row [forBasic]="true"
                                                             [premium]="currentUser.isPremium()"
                                          >{{ 'PREMIUM.USP.REPLY_TO_MESSAGES' | translate }}</premium-table-row>
                                          <premium-table-row [forBasic]="true"
                                                             [premium]="currentUser.isPremium()"
                                          >{{ 'PREMIUM.USP.MATCH_MEMBER' | translate }}</premium-table-row>

                                          <premium-table-row [list]="profileVisitor"
                                                             [premium]="currentUser.isPremium()"
                                                             [listRoute]="navigationService.getRoute(Routes.ProfileVisitors)"
                                          >
                                              <a (click)="toProfileVisitors()">{{ 'PREMIUM.USP.SEE_PROFILE_VISITOR' | translate }}</a>
                                          </premium-table-row>

                                          <premium-table-row [list]="matchPartner"
                                                             [premium]="currentUser.isPremium()"
                                                             [listRoute]="navigationService.getRoute(Routes.MatchingPartner)"
                                          >
                                              <a (click)="toMatchPartner()">{{ 'PREMIUM.USP.SEE_MATCHPARTNER' | translate }}</a>
                                          </premium-table-row>

                                          <premium-table-row [premium]="currentUser.isPremium()">
                                              {{ 'PREMIUM.USP.SEE_ALL_PICS_BIG' | translate }}
                                          </premium-table-row>

                                          <premium-table-row [premium]="currentUser.isPremium()">
                                              <a (click)="toExtendedSearch()">{{ 'PREMIUM.USP.ADVANCED_SEARCH_FUNCTIONS' | translate }}</a>
                                          </premium-table-row>

                                          <premium-table-row [premium]="currentUser.isPremium()"
                                                             [infoText]="'PREMIUM.USP.PREFERRED_IN_SEARCH.INFO_TEXT' | translate"
                                          >
                                              {{ 'PREMIUM.USP.PREFERRED_IN_SEARCH' | translate }}
                                          </premium-table-row>

                                          <premium-table-row [premium]="currentUser.isPremium()">
                                              {{ 'PREMIUM.USP.FLIRT_WITHOUT_ADS' | translate }}
                                          </premium-table-row>
                                      </div>
                                  </div>

                                  <div class="premium-overview__buttons">
                                      <div class="premium-overview__buttons__feature">
                                          <flat-button (click)="showAllFeatures()">{{'PREMIUM.OVERVIEW.SHOW_ALL_FEATURES' | translate}}</flat-button>
                                      </div>
                                      <div class="premium-overview__buttons__offer">
                                          <raised-button premium (click)="goToPremiumLandingPage($event)">{{premiumButtonText | translate}}</raised-button>
                                          <p>{{'PREMIUM.OVERVIEW.PRICE'| translate:{ price: monthPrice } }}</p>
                                      </div>
                                  </div>

                                  <premium-support class="premium-overview__footer" (clickMail)="clickMail()" (clickPhoneNumber)="clickPhoneNumber()"></premium-support>

                                  <premium-features-full-modal #featuresFullModal [premium]="currentUser.isPremium()"
                                                               [subscription]="hasSubscription"
                                                               [monthPrice]="monthPrice"
                                  ></premium-features-full-modal>

                              </card-title>
                          `,
               styles: [require('./premium-overview.scss')],
           })

export class PremiumOverview implements OnInit {
    @ViewChild('featuresFullModal', { static: true }) private featuresFullModal: PremiumFeaturesFullModal;

    private unsubscribe$ = new Subject();

    private currentUser = new CurrentUser();

    private profileVisitor = [];
    private matchPartner = [];
    private premiumDetails: PremiumDetails = new PremiumDetails();
    private Routes = Routes;

    private monthPrice: any;

    public constructor(
        private currentUserService: CurrentUserService,
        private listService: ListService,
        private matchingService: MatchingService,
        private premiumService: PremiumService,
        private paymentService: PaymentService,
        protected navigationService: NavigationService,
        private trackingService: TrackingService,
        protected notificationService: NotificationService
    ) {
    }

    filterRelevantNotification(notifications: Notification[]) {
        return notifications.filter(value => value instanceof ChargebackPaymentFailedNotification);
    }

    public ngOnInit(): void {
        this.currentUserService.currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(val => this.currentUser = val);

        this.listService.profileVisitors(false, 3, 0).then(visitor => {
            this.profileVisitor = visitor;
        });

        this.matchingService.matchPartner(3, 0).then(matchPartner => {
            this.matchPartner = matchPartner;
        });

        this.premiumService.getDetails().then(details => {
            this.premiumDetails = details;
        });

        this.paymentService.cheapestPremiumProduct.then(cheapest => this.monthPrice = cheapest.pricePerMonth.toCurrencyString());
    }

    private extendNow(event) {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'MitgliedschaftVerlaengern');
        this.premiumService.navigateToPremium(OrderReason.PremiumServiceOverview);
    }

    private goToPremiumLandingPage(event) {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'ZuPremiumAngeboten');
        this.premiumService.navigateToPremium(OrderReason.PremiumServiceOverview);
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private showAllFeatures() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'AlleFunktionen');
        this.featuresFullModal.show();
    }

    private get hasSubscription(): boolean {
        return this.premiumDetails !== undefined && this.premiumDetails.hasSubscription;
    }

    private get premiumButtonText(): string {
        return this.currentUser.isPremium() ? 'PREMIUM_OVERVIEW.EXTEND_PREMIUM' : 'PREMIUM.OVERVIEW.TO_OFFERS';
    }

    private toProfileVisitors() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'EigeneProfilbesucherSehen');
        this.navigationService.navigateTo(Routes.ProfileVisitors);
    }

    private toMatchPartner() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'MatchpartnerSehen');
        this.navigationService.navigateTo(Routes.MatchingPartner);
    }

    private toExtendedSearch() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'ErweiterteSuchfunktionen');
        this.navigationService.navigateTo(Routes.Search, { fragment: SearchRouteFragment.OpenExtendedSearch });
    }

    private clickPhoneNumber() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'Supportnummer');
    }

    private clickMail() {
        this.trackingService.hit('Leistungsuebersicht', 'LinkGeklickt', 'Supportmail');
    }

}
