import { Route as NgRoute } from '@angular/router';
import { BkConfig } from 'BKConfig';
import {
    routeName,
    Routes,
} from './service/navigation.service';

type RedirectCreator = (IRedirectPrefix) => NgRoute;

interface RedirectPrefix {
    path: string;
    redirectTo: string;
}

const login = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path.replace('/', '')}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Login)}`,
    pathMatch:  'full',
});

const startpage = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Startpage)}`,
    pathMatch:  'full',
});

const matching = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.Matching)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Matching)}/${routeName(Routes.MatchingStart)}`,
    pathMatch:  'full',
});

const conversations = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.Conversation)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Conversation)}/${routeName(Routes.MessagesAll)}`,
    pathMatch:  'full',
});

const contacts = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.Contacts)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Contacts)}/${routeName(Routes.ContactsVisitors)}`,
    pathMatch:  'full',
});

const favorite = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.Favorite)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Favorite)}/${routeName(Routes.FavoriteExist)}`,
    pathMatch:  'full',
});


const settings = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.Settings)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.Settings)}/${routeName(Routes.SettingsAccount)}`,
    pathMatch:  'full',
});

const profileVisitors = ({ path = '', redirectTo = '' }: RedirectPrefix): NgRoute => ({
    path:       `${path}${routeName(Routes.Intern)}/${routeName(Routes.ProfileVisitors)}`,
    redirectTo: `${redirectTo}/${routeName(Routes.Intern)}/${routeName(Routes.ProfileVisitors)}/${routeName(Routes.ProfileVisitorsOtherVisits)}`,
    pathMatch:  'full',
});


class Prefix {
    constructor(public path: string = '', public redirectTo: string = '') {
    }
}

const getPrefix = function (lang: string): Prefix {
    if (lang.length > 0) {
        return new Prefix(`${lang}/`, `/${lang}`);
    }
    return new Prefix();
};

const getAllRedirect = function (funcArr: RedirectCreator[]): NgRoute[] {
    return BkConfig.supportedLang.concat([''])
                   .map(cur => getPrefix(cur))
                   .map(prefix => funcArr.map(curFunc => curFunc(prefix)))
                   .reduce((res, cur) => res.concat(...cur), []);
};

/**
 * Export for all redirects
 */
export const allRedirect: NgRoute[] = getAllRedirect([
                                                         login,
                                                         startpage,
                                                         matching,
                                                         conversations,
                                                         settings,
                                                         favorite,
                                                         contacts,
                                                         profileVisitors
                                                     ]);
