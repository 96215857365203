import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
} from 'rxjs';

@Injectable({providedIn: 'root'})
export class HeaderService {
    private _isExtraContentVisible = true;
    private get isExtraContentVisible():boolean {
        return this._isExtraContentVisible
    }
    private set isExtraContentVisible(visible: boolean) {
        this._isExtraContentVisible = visible;
        this._extraContentVisibilitySubject.next(visible)
    }
    private _extraContentVisibilitySubject: BehaviorSubject<boolean> = new BehaviorSubject(this.isExtraContentVisible);
    public get extraContentVisibilitySubject(): BehaviorSubject<boolean> {
        return this._extraContentVisibilitySubject;
    }

    public sideNavOpen = new BehaviorSubject<boolean>(false);

    public setSideNavOpen(value: boolean) {
        this.sideNavOpen.next(value);
    }

     public toggleSideNav() {
        const value = this.sideNavOpen.getValue();
        this.setSideNavOpen(!value)
    }

    public showExtraContent() {
        this.isExtraContentVisible = true;
    }

    public hideExtraContent() {
        this.isExtraContentVisible = false;
    }

    public toggleExtraContentVisibility() {
        this.isExtraContentVisible = !this.isExtraContentVisible;
    }

}
