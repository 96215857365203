import { Component } from '@angular/core';
import { TrackingService } from 'BKService';

@Component({
               selector:  'account-delete-successful',
               template:  `
                              <bk-header-out></bk-header-out>
                              <bk-section-out>
                                  <div class="account-delete-successful">
                                      <card>
                                          <div class="account-delete-successful__content">
                                              <div class="account-delete-successful__title">{{'ACCOUNT.DELETE.SUCCESSFUL.TITLE' | translate}}</div>
                                              <div class="account-delete-scuessful__text">{{'ACCOUNT.DELETE.SUCCESSFUL.TEXT' | translate}}</div>
                                              <raised-button class="account-delete-successful__button" (click)="toLandingPage()">{{'ACCOUNT.DELETE.SUCCESSFUL.BUTTON' | translate}}</raised-button>
                                          </div>
                                      </card>
                                  </div>

                              </bk-section-out>
                          `,
               styles: [require('./account-delete-successful.scss')],
           })

export class AccountDeleteSuccessful {

    public constructor(private trackingService: TrackingService) {
    }

    private toLandingPage() {
        this.trackingService.hit('AccountLoeschen','NachLoeschBestaetigung','Startseite');
        window.location.href = window.location.origin;
    }
}
