import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    BillingAddress,
    SepaAccount,
} from 'BKModels';
import { PaymentDebit } from '../../../../../shared/mat-design/payment-debit/payment-debit';

@Component({
               selector:  'settings-sepa-data',
               template:  `
                              <card-title>
                                  <div class="settings-sepa-data__title">{{title}}</div>
                                  <div class="settings-sepa-data__text">{{text}}</div>

                                  <payment-methode-tab class="settings-sepa-data__tab" img="./assets/img/payment/badgeEc.png">
                                      {{'PREMIUM.BOOKING.PAYMENT.BANK' | translate}}
                                  </payment-methode-tab>

                                  <div class="settings-sepa-data__box">
                                      <div class="settings-sepa-data__box__title">{{'SETTINGS.CHANGE-PAYMENT-DATA.BOX.TITLE' | translate}}</div>
                                      <payment-debit #debit
                                                     [(sepaAccount)]="sepaAccount"
                                                     [(billingAddress)]="billingAddress"
                                      ></payment-debit>

                                      <div class="settings-sepa-data__buttons">
                                          <flat-button *ngIf="!cancelTitle.isEmpty()" class="settings-sepa-data__buttons--left" (click)="cancel.emit()">{{cancelTitle}}</flat-button>
                                          <raised-button *ngIf="!saveTitle.isEmpty()" [disabled]="debitForm.form.invalid || inProgress" (click)="save()">
                                              <loading-indicator *ngIf="inProgress" class="loading-indicator-button"></loading-indicator>
                                              {{saveTitle}}
                                          </raised-button>
                                      </div>
                                  </div>
                              </card-title>


                          `,
               styles: [require('./settings-sepa-data.scss')],
           })

export class SettingsSepaData implements AfterViewInit {
    @ViewChild('debit', { static: true }) debitForm: PaymentDebit;
    @Input() billingAddress: BillingAddress;
    @Input() sepaAccount: SepaAccount;

    @Input() title: string;
    @Input() text: string;


    @Input() saveTitle: string = '';
    @Output('save') _save = new EventEmitter();

    @Input() cancelTitle: string = '';
    @Output() cancel = new EventEmitter();
    @Input() inProgress = false;

    public ngAfterViewInit(): void {

        this.debitForm.secondStep = true;
    }

    save() {
        this._save.emit({
                            billingAddress: this.billingAddress,
                            sepaAccount:   this.sepaAccount,
                        });
    }
}
