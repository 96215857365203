import { Component } from '@angular/core';

@Component({
               selector:  'card',
               template:  `
        <div class="mdc-card card">
            <div class="card__content">
                <ng-content></ng-content>
            </div>
        </div>`,
               styles: [require('./card.scss')],
           })

export class Card {

}
