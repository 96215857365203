import {
    Component,
    Input,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

@Component({
               selector: 'single-page-header',
               template: `
                             <div class="premium-landing-page-header" [style.cursor]="canLeave ? 'pointer' : 'auto'">
                                 <div *ngIf="canLeave" class="premium-landing-page-header__content_wrap">
                                     <icon class="premium-landing-page-header__icon" (click)="backToBk()" icon="arrow-back"></icon>
                                     <div class="premium-landing-page-header__logo" [routerLink]="mainpage"></div>
                                 </div>               
                                 <div *ngIf="!canLeave" class="premium-landing-page-header__content_wrap">
                                     <div class="premium-landing-page-header__logo"></div>
                                 </div>
                             </div>`,
               styles: [require('./single-page-header.scss')],
           })

export class SinglePageHeader {
    @Input() trackingCategoryName: string;
    @Input() trackingPageName: string;
    @Input() canLeave: boolean = true;

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    private get mainpage() {
        return this.navigationService.getRoute(Routes.Startpage);
    }

    private backToBk() {
        this.trackingService.hit(this.trackingCategoryName, this.trackingPageName, 'ZurueckZuBildkontakte');
        this.navigationService.navigateBack();
    }
}
