import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';


enum ReadingStatus {
    Unlock,
    Send,
    Read,
    Error
}

@Component({
               changeDetection: ChangeDetectionStrategy.OnPush,
               selector:        'chat-reading-status',
               template:        `
                                    <div class="chat-read-status" [ngSwitch]="status">
                                        <a premium *ngSwitchCase="enum.Unlock"
                                             class="chat-read-status__text chat-read-status__text--link"
                                             (click)="openNpLayer($event)">
                                            <icon class="chat-read-status__icon" icon="check"></icon>
                                            {{'CHAT.LIST.READ_STATE.ACTIVAED' | translate }}
                                        </a>
                                        <div *ngSwitchCase="enum.Read" class="chat-read-status__text">
                                            <icon class="chat-read-status__icon chat-read-status__icon--read" icon="check"></icon>
                                            {{'CHAT.LIST.READ_STATE.READ' | translate }}
                                        </div>
                                        <div *ngSwitchCase="enum.Send" class="chat-read-status__text">
                                            <icon class="chat-read-status__icon" icon="check"></icon>
                                            {{'CHAT.LIST.READ_STATE.SEND' | translate }}
                                        </div>
                                        <div *ngSwitchCase="enum.Error" class="chat-read-status__text">
                                            <icon class="chat-read-status__icon chat-read-status__icon--error" icon="error-outline"></icon>
                                            {{'CHAT.LIST.READ_STATE.ERROR' | translate }}
                                        </div>
                                    </div>
                                `,
               styles:       [require('./chat-reading-status.scss')],
           })

export class ChatReadingStatus {
    @Output('openNpLayer') private _openNpLayer: EventEmitter<any> = new EventEmitter();

    private convRead: boolean = false;

    @Input('convRead')
    private set _convRead(val: boolean) {
        this.convRead = val;
        this.setStatus();
    }

    private isPremium: boolean = false;

    @Input('isPremium')
    private set _isPremium(val: boolean) {
        this.isPremium = val;
        this.setStatus();
    }

    private error = false;

    @Input('error')
    private set _error(val: boolean) {
        this.error = val;
        this.setStatus();
    }

    private enum = ReadingStatus;
    private status = ReadingStatus.Unlock;


    private setStatus() {
        if (this.error) {
            this.status = ReadingStatus.Error;
        } else if (!this.isPremium) {
            this.status = ReadingStatus.Unlock;
        } else if (this.convRead) {
            this.status = ReadingStatus.Read;
        } else {
            this.status = ReadingStatus.Send;
        }
    }


    private openNpLayer($event) {
        this._openNpLayer.emit($event);
    }
}
