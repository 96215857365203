import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationStart,
    Router,
} from '@angular/router';
import {
    AchievementId,
    CurrentUser,
    MatchingDecision,
    MatchingQueueItem,
    ProfileInfo,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    PlayedPartnerQueue,
    StoreService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    MatchingRouteFragment,
    NavigationService,
    ProfileEditRouteFragment,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { DialogBox } from '../../../../../shared/mat-design/dialog/dialog-box';

/**
 * Komponete für die Seite wenn man ins Matching kommt
 */
@Component({
               selector:  'matching-start',
               template:  `
                              <card padding padding-bp1 class="bk-matching-start"
                                    *ngIf="hasContingent() && !matchingService.isQueueEmpty"
                              >
                                  <div *ngIf="!isMatch">
                                      <bk-matching-game [partner]="partner"
                                                        [currentUser]="currentUser"
                                                        [matchingQueue]="matchingQueue"
                                                        [playedPartnerList]="playedPartnerList"
                                                        (decide)="decide($event)"
                                      ></bk-matching-game>

                                      <matching-explain [(open)]="openExplain"></matching-explain>
                                  </div>

                                  <bk-matching-match *ngIf="isMatch"
                                                     [currentUser]="currentUser"
                                                     [partner]="partner"
                                                     (continueMatching)="contiueWithMatching()"
                                                     (toConversation)="toConversation($event)"
                                  ></bk-matching-match>

                              </card>

                              <loading-spinner *ngIf="isQueueLoading && matchingService.isQueueEmpty && hasContingent()"></loading-spinner>
                              <matching-placeholder-queue-empty *ngIf="hasContingent() && matchingService.isQueueEmpty && !isQueueLoading"></matching-placeholder-queue-empty>

                              <bk-matching-placeholder-contingent *ngIf="!hasContingent()"></bk-matching-placeholder-contingent>

                              <dialog-box #noImageDialog [title]="'MATCHING.NO_IMG_BOX.TITLE' | translate"
                                          [acceptText]="'MATCHING.NO_IMG_BOX.ACCECPT_TEXT' | translate"
                                          [rejectText]="'MATCHING.NO_IMG_BOX.REJECT_TEXT' | translate"
                                          (onAccept)="toImageUpload()"
                                          (onReject)="noPicUpload()"
                              >
                                  {{'MATCHING.NO_IMG_BOX.CONTENT' | translate}}
                              </dialog-box>

                              <dialog-box #welcomeDialog
                                          [title]="'MATCHING.WELCOME.TITLE' | translate:currentUser"
                                          [acceptText]="'MATCHING.WELCOME.ACCECPT_TEXT' | translate"
                                          [rejectText]="'MATCHING.WELCOME.REJECT_TEXT' | translate"
                                          (onAccept)="toMatching()"
                                          (onReject)="welcomeDialogOpenExplanation()"
                              >
                                  {{'MATCHING.WELCOME.CONTENT' | translate}}
                              </dialog-box>
                          `,
               styles: [
                   require('./matching-start.scss'),
               ],
           })
export class MatchingStart implements OnInit, OnDestroy {
    unsubscribe$ = new Subject();
    currentUser: CurrentUser = new CurrentUser();
    isMatch: boolean = false;
    isQueueLoading: boolean = true;
    @ViewChild('noImageDialog', { static: true }) private noImageDialog: DialogBox;
    openExplain: boolean = false;
    matchingQueue: MatchingQueueItem[] = [];
    playedPartnerList: PlayedPartnerQueue = new PlayedPartnerQueue();
    @ViewChild('welcomeDialog', { static: true }) private welcomeDialog: DialogBox;

    get partner(): ProfileInfo {
        return this.matchingQueue.first().partner;
    }

    public constructor(
        private router: Router,
        protected matchingService: MatchingService,
        private currentUserService: CurrentUserService,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private storageService: StoreService,
    ) {
    }

    ngAfterViewInit() {
        this.currentUserService
            .currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
            });

        Promise.all([this.currentUserService.currentUser, this.currentUserService.achievements()])
               .then(([currentUser, achievements]) => {

                   if (this.currentUser.isInRegistration()) {
                       this.noImageDialog.open();
                   } else if (!achievements.achieved(AchievementId.FirstTimeMatching) && !this.storageService.matchingOnboardingAlreadyShown.getValues()) {
                       this.storageService.matchingOnboardingAlreadyShown.next(true);
                       this.welcomeDialog.open();
                   }
               });
    }

    ngOnInit() {
        this.matchingService.loadQueue();

        this.matchingService
            .isQueueLoading
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(cur => this.isQueueLoading = cur);

        this.matchingService.matchingQueue
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(queue => {
                this.matchingQueue = queue;
            });

        this.matchingService.playUserList
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(list => {
                this.playedPartnerList = list;
            });

        this.openExplain = this.activatedRoute.snapshot.fragment === MatchingRouteFragment.Explain;

        this.router.events
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val) => {
                if (val instanceof NavigationStart) {
                    if (this.isMatch) this.matchingService.removeMatchedItemFromQueue(this.partner);
                }

            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    contiueWithMatching() {
        this.isMatch = false;
        this.matchingService.removeMatchedItemFromQueue(this.partner);
    }

    decide(decision) {
        if (this.currentUser.isInRegistration() && this.isYesOrMaybe(decision)) {
            this.noImageDialog.open();
            return;
        }

        this.isMatch = this.isYesOrMaybe(decision) && this.isYesOrMaybe(this.partner.decisionPartner);
        this.matchingService.setDecision(this.partner, decision);

        if (!this.isMatch) {
            this.matchingService.removeMatchedItemFromQueue(this.partner);
        }

        if (this.isYesOrMaybe(this.partner.decisionPartner)) this.currentUserService.refreshBadgeCount();

    }

    hasContingent(): boolean {
        return this.currentUser.isMatchingContingentRemaining();
    }

    private isYesOrMaybe(decison: MatchingDecision): boolean {
        return decison === MatchingDecision.Yes || decison === MatchingDecision.Maybe;
    }

    noPicUpload() {
        //this.navigationService.navigateTo(Routes.Startpage);
    }

    openWhatIsMatching() {
        this.navigationService.navigateTo(Routes.Startpage, {
            fragment: MatchingRouteFragment.Explain,
        });
    }

    toConversation(id: string) {
        this.navigationService.navigateTo(Routes.MessagesConversation, {
            urlParam: {
                [RouteParameter.ConversationId]: id,
            },
        });
    }

    welcomeDialogOpenExplanation() {
        this.openExplain = true;
        this.trackingService.hit('Matching', 'Onboarding', 'ZurErklaerung');
    }

    toMatching() {
        this.trackingService.hit('Matching', 'Onboarding', 'JetztMatchen');
    }

    toImageUpload() {
        this.trackingService.hit('Matching', 'OnboardingFoto', 'BildHochladenBegonnen')
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Images });
    }
}
