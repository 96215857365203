import { Injectable } from '@angular/core';
import { UserBaseView } from 'BKModels';
import { State } from '../models/state';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class ReportService {
    public constructor(private apiService: ApiService) {
    }

    public reportViolation(user: UserBaseView, description: string): Promise<State> {
        return this
            .apiService
            .report
            .reportViolation(user.id, description);
    }
}