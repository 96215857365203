import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { LinearProgress } from '../../../../shared/mat-design/linear-progress/linear-progress';

/**
 * Komponente für den ProfileHeader im internen Bereich
 */
@Component({
               selector:  'bk-header',
               template:  `
                              <div class="header-container"
                                   id="main-header"
                                   [style.paddingTop.px]="height"
                              >
                                  <header #header class="header"
                                    [class.header--incognito]="incognito"
                                  >
                                      <router-outlet name="header"></router-outlet>
                                      <linear-progress #progress class="header__progress"></linear-progress>
                                  </header>
                                  
                              </div>
                          `,
               styles: [require('./header.scss')],
           })
export class Header implements AfterViewInit, OnDestroy {
    @ViewChild('progress', { static: true }) private linearProgress: LinearProgress;
    @ViewChild('header', { static: true }) private header: ElementRef;
    @Input() private incognito: boolean = false;

    private startProgress = () => this.linearProgress.show();
    private stopProgress = () => this.linearProgress.hide();

    public ngAfterViewInit() {
        this.linearProgress.hide();

        document.addEventListener('customLoading', this.startProgress);
        document.addEventListener('customLoadingFinished', this.stopProgress);
        this.height;
    }


    public ngOnDestroy(): void {
        document.removeEventListener('customLoading', this.startProgress);
        document.removeEventListener('customLoadingFinished', this.stopProgress);
    }

    public get height() {
        const computedHeight = window.getComputedStyle(this.header.nativeElement).height;
        const heightNumber = parseInt(computedHeight.replace('px', ''));
        return heightNumber;
    }

}
