import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import { CurrentUserService } from 'BKService';
import {
    Subject,
    Subscription,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { ProfileEditNavigationService } from '../../profile-edit/profile-edit-navigation.service';

@Component({
               selector:  'authenticity-check',
               template:  `
                              <card-title [title]="'ECHECK.MAIN.HEADLINE'|translate">
                                  <ng-container *ngIf="currentUser.profileImgExist()">
                                      <div class="authenticity-check__description" [innerHTML]="'ECHECK.MAIN.DESCRIPTION' | translate | markdown"></div>
    
                                      <div class="authenticity-check__wrapper">
                                          <authenticity-check-idcard
                                                  [currentUser]="currentUser"
                                                  (uploadComplete)="idCardUploadComplete()"
                                          ></authenticity-check-idcard>
    
                                          <authenticity-check-photo
                                                  [currentUser]="currentUser"
                                                  (uploadComplete)="photoUploadComplete()"
                                          ></authenticity-check-photo>
    
                                          <authenticity-check-phone
                                                  [currentUser]="currentUser"
                                          ></authenticity-check-phone>
                                      </div>
                                  </ng-container>
                                  <ng-container *ngIf="!currentUser.profileImgExist()">
                                      <div class="authenticity-check__description markdown first-last-nomargin" [innerHTML]="'ECHECK.MAIN.DESCRIPTION.MISSING_PROFILE_PICTURE' | translate | markdown"></div>
                                      <div class="authenticity-check__action">
                                          <raised-button class="authenticity-check__action__button" (click)="this.profileEditNavigationService.requestScroll(ProfileEditRouteFragment.Images)">
                                              {{ 'ECHECK.MAIN.ACTION.MISSING_PROFILE_PICTURE'|translate }}
                                          </raised-button>
                                      </div>
                                  </ng-container>
                              </card-title>
                          `,
               styles: [require('./authenticity-check.scss')],
           })

export class AuthenticityCheck implements OnInit, OnDestroy{
    private currentUserSubscription: Subscription = new Subscription(() => { return; });
    private currentUser = new CurrentUser();
    private ProfileEditRouteFragment = ProfileEditRouteFragment;

    public constructor(
        private currentUserService: CurrentUserService,
        private profileEditNavigationService: ProfileEditNavigationService
    ) {}

    public ngOnInit() {
        this.currentUserService.currentUserObservable
            .subscribe(user => {
                this.currentUser = user;
            });
    }

    public ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
    }

    public photoUploadComplete() {
        this.currentUserService.changePersistedCurrentUser('photoCheckValidationPending', true);
        this.currentUser.photoCheckValidationPending = true;
    }

    public idCardUploadComplete() {
        this.currentUserService.changePersistedCurrentUser('identityValidationPending', true);
        this.currentUser.identityValidationPending = true;
    }

}
