/**
 * Created by olifra on 10.04.2017.
 */
import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { OuterComponentModule } from '../outer-component';
import { Login } from './login';
import { LoginHeaderComponent } from './login-headaer';


@NgModule({
              imports:         [
                  ShareModule,
                  OuterComponentModule,
              ],
              providers:       [],
              declarations:    [
                  Login,
                  LoginHeaderComponent,
              ],
              entryComponents: [],
              exports:         [Login],
          })
export class LoginModule {
}


export * from './login';
