/**
 * Created by olifra on 26.05.2017.
 */

import {
    Component,
    HostListener,
    Input,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    Image,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import { PremiumService } from '../../../service/premium.service';

/**
 * Komponete für die Lightbox
 *
 */
@Component({
               selector:  'lightbox',
               template:  `
                              <div class="lightbox"
                                   *ngIf="isOpen"
                              >
                                  <div class="lightbox__box">
                                      <div class="lightbox__box__closer"
                                           (click)="close()"
                                      >
                                          <icon icon="close"></icon>
                                      </div>
                                      <div class="lightbox__box__arrow"
                                           swipe
                                           (leftSwipe)="showPreviousImage()"
                                           (rightSwipe)="showNextImage()"
                                      >
                                          <p class="lightbox__box__arrow__glyph">
                                              <icon icon="arrow-back-ios" (click)="showPreviousImage()"></icon>
                                          </p>
                                          <p class="lightbox__box__arrow__glyph">
                                              <icon icon="arrow-forward-ios" (click)="showNextImage()"></icon>
                                          </p>
                                      </div>

                                      <img-with-loading-indicator class="lightbox__box__image" indicatorHeight="48px" indicatorWidth="48px" [src]="currentImageUrl"></img-with-loading-indicator>
                                      <div class="lightbox__box__text">
                                          <p>{{currentDescription}}</p>
                                      </div>
                                  </div>

                                  <div class="lightbox__preview" (click)="$event.stopPropagation()">
                                      <div class="lightbox__preview__image"
                                           *ngFor="let preview of imageUrls; let i = index; let first = first;"
                                           (click)="showPreview(preview, i)"
                                           [class.lightbox__preview__image--current]=" i == currentPreview"
                                      >
                                          <img-with-loading-indicator class="lightbox__preview__image-image" [src]="preview"></img-with-loading-indicator>
                                          <icon *ngIf="!first && !imagesVisible" icon="crown-outline" class="lightbox__preview__image__premium"></icon>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./lightbox.scss')],
           })
export class Lightbox implements OnInit {
    currentPreview: number = 0;
    isOpen = false;
    @Input() user: ProfileInfo = new ProfileInfo();
    @Input() currentUser: CurrentUser = new CurrentUser();

    forceVisible = false;

    get imagesVisible(): boolean {
        return this.forceVisible || this.currentUser?.isPremium();
    }

    constructor(private premiumService: PremiumService) {}

    get currentDescription() {
        const image = this.user.fullGallery[this.currentPreview];
        if (!image || !image.hasOwnProperty('description')) return '';
        return image.description;
    }

    get currentImageUrl() {
        return this.imageUrls[this.currentPreview];
    }

    get imageUrls() {
        return Image.getUrlArray('640x640', this.user.fullGallery, !this.imagesVisible);
    }

    close(): void {
        this.isOpen = false;
        document.body.classList.remove('noscroll');
        this.currentPreview = 0;
    }

    ngOnInit() {
        this.currentPreview = 0;
    }

    open(forceVisible = false) {
        this.forceVisible = forceVisible;
        this.isOpen = true;
        document.body.classList.add('noscroll');
    }

    showNextImage(): void {
        if (this.imagesVisible) {
            this.currentPreview = (this.currentPreview + 1) % this.user.fullGallery.length;
        } else {
            this.openNpLayer();
        }
    }

    showPreviousImage(): void {
        if (this.imagesVisible) {
            this.currentPreview = (this.currentPreview + this.user.fullGallery.length -1) % this.user.fullGallery.length;
        } else {
            this.openNpLayer();
        }
    }

    private openNpLayer() {
        this.isOpen = false;
        this.premiumService.navigateToPremium(OrderReason.AllImages);
    }

    showPreview(imageUrl: string, clickedIndex: number): void {
        if (this.imagesVisible) {
            this.currentPreview = clickedIndex;
        } else {
            this.openNpLayer();
        }
    }

    @HostListener('document:keydown.escape', ['$event'])
    private keyPress(event) {
        if (this.isOpen) {
            event.stopPropagation();
            this.close();
        }
    }
}
