import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';
import { Tabs } from '../../../shared/mat-design/tabs/tabs';
import { debounce } from '../../../utils/decorator';
import { HeaderOut } from '../../outer-component/header/header-out';

var scrollIntoView = require('scroll-into-view');


@Component({
               selector:  'logout',
               template:  `
                              <bk-header-out #header>
                                  <raised-button (click)="toLogin()">{{"LOGOUT.TO-LOGIN" | translate}}</raised-button>

                                  <tabs extra #tabbar [tabsOnly]="true"
                                        [disableRouting]="true"
                                        [fitContent]="true"
                                  >
                                      <tab [title]="'LOGOUT.TABS.LOVESTORY' | translate" (onClick)="toLovestory()"></tab>
                                      <tab [title]="'LOGOUT.TABS.GLOSSAR' | translate" (onClick)="toGlossar()"></tab>
                                      <tab [title]="'LOGOUT.TABS.SINGLEEVENTS' | translate" (onClick)="toSingleEvents()"></tab>
                                  </tabs>
                              </bk-header-out>
                              <logout-title-box></logout-title-box>
                              <logout-lovestory id="lovestory"></logout-lovestory>
                              <logout-glossar id="glossar"></logout-glossar>
                              <logout-singleevents id="events"></logout-singleevents>
                              <logout-awards></logout-awards>
                              <logout-footer></logout-footer>

                          `,
               styles: [require('./logout.scss')],
           })

export class Logout implements OnInit, OnDestroy, AfterViewInit {
    private Routes = Routes;
    @ViewChild('tabbar', { static: true }) private tabbar: Tabs;
    @ViewChild('header', { static: true }) private header: HeaderOut;

    private get lovestory() {
        return document.getElementById('lovestory');
    }

    private get glossar() {
        return document.getElementById('glossar');
    }

    private get singleevents() {
        return document.getElementById('events');
    }


    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        window.addEventListener('scroll', this.onScroll);
    }

    public ngOnDestroy(): void {
        window.removeEventListener('scroll', this.onScroll);
    }

    public ngAfterViewInit() {
        this.onScrollDebounce();
    }

    private onScroll = () => this.onScrollDebounce();


    private toLogin() {
        this.trackingService.hit('Logout', 'Login', 'LoginMitglieder');
        this.navigationService.navigateTo(Routes.Login);
    }

    private toLovestory() {
        this.track('Erfolgsgeschichten');
        this.scrollTo('lovestory');
    }

    private toGlossar() {
        this.track('DatingGlossar');
        this.scrollTo('glossar');
    }

    private toSingleEvents() {
        this.track('SingleEvents');
        this.scrollTo('events');
    }

    private track(label: string) {
        this.trackingService.hit('Logout', 'TabGeklickt', label);
    }


    private scrollTo(id: string) {
        let element = document.getElementById(id);
        scrollIntoView(element, {
            align: {
                top:       0,
                topOffset: this.header.height,
            },
        });


    }

    @debounce(50)
    private onScrollDebounce() {
        if (this.elementInViewport(this.lovestory)) {
            this.tabbar.setTabActive(0);
            return;
        }
        if (this.elementInViewport(this.glossar)) {
            this.tabbar.setTabActive(1);
            return;
        }
        if (this.elementInViewport(this.singleevents)) {
            this.tabbar.setTabActive(2);
            return;
        }
    }


    private elementInViewport(el): boolean {
        var rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
        );
    }
}
