/**
 * Created by olifra on 03.07.2017.
 */

import { Injectable } from '@angular/core';
import { ApiResponseFrame } from 'BKModels';
import { Endpoints } from 'BKUtils';
import { ApiRequestService } from '../api-request.service';

/**
 * Apiservice für das Nachrichtensystem
 */
export class MessagesApi {
    /**
     * constructor
     *
     {ApiRequestService} _apiRequestService
     */
    public constructor(private _apiRequestService: ApiRequestService) {
    }

    public delete(convId: string, ids: string[]): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.messages.delete, {
                       endpointValue: {
                           convId,
                       },
                       body:          {
                           ids,
                       },
                   });
    }

    public get(convId: string): Promise<ApiResponseFrame<any>> {
            return this._apiRequestService
                       .createGet<ApiResponseFrame<any>>(Endpoints.messages.get, {
                           endpointValue: {
                               convId,
                           },
                       });
    }

    public messages(convId: string, startingId: number): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createGet<ApiResponseFrame<any>>(Endpoints.messages.messages, {
                       endpointValue: {
                           convId,
                       },
                       query:         {
                           startingId,
                       },
                   });
    }

    public getNumberOfUnread(): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.messages.getNumberOfUnreadMessages);
    }

    public getOlder(
        convId: string,
        startId: number,
        limit: number,
        offset: number = 0,
    ): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.messages.getOlder, {
                       body:          {
                           startingId: startId,
                           limit,
                           offset,
                       },
                       endpointValue: {
                           convId,
                       },
                   });
    }

    public write(convId: string, text: string): Promise<ApiResponseFrame<any>> {
        return this._apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.messages.write, {
                       body:          { text },
                       endpointValue: {
                           convId,
                       },
                   });
    }
}
