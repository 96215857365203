import {
    Component,
    Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Children,
    ChildrenWish,
    ProfileInfo,
} from 'BKModels';

@Component({
               selector: 'profile-personal',
               template: `
    <div class="profile-info-title">{{'PROFILE_INFO.PERSONAL' | translate}}:</div>
    <div class="mdc-layout-grid profile-over-me">
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-12">
          <profile-info-item [title]="'PROFILE_INFO.MOTTO' | translate"
                           icon="format-quote"
          >
            {{user.motto}}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.RELATIONSHIP.LABEL' | translate"
                             icon="group"
          >
            {{user.relationship | relationship | translate}}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.CAREER' | translate"
                             icon="business-center"
          >
            {{user.career }}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.HOBBYS' | translate"
                             icon="thumb-up"
          >
              <div [innerHTML]="user.hobbys | emoji" class="emoji-small"></div>
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.CHILDREN.LABEL' | translate"
                             icon="child-care"
          >
            {{childrenTranslation | translate }}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.LIKE' | translate"
                             icon="add-circle-outline"
          >
              <div [innerHTML]="user.like | emoji" class="emoji-small"></div>
          </profile-info-item>
          <profile-info-item *ngIf="!user.dislike.isEmpty()" [title]="'PROFILE_INFO.DISLIKE' | translate"
                             icon="remove-circle-outline"
          >
              <div [innerHTML]="user.dislike | emoji" class="emoji-small"></div>
          </profile-info-item>
          <profile-info-item *ngIf="user.food.length > 0 " [title]="'PROFILE_INFO.FOOD' | translate"
                             icon="restaurant"
          >
              <div [innerHTML]="food | emoji" class="emoji-small"></div>
          </profile-info-item>
          <profile-info-item *ngIf="user.music.length > 0 " [title]="'PROFILE_INFO.MUSIC' | translate"
                             icon="music-note"
          >
              <div [innerHTML]="music | emoji" class="emoji-small"></div>
          </profile-info-item>
          <profile-info-item *ngIf="user.movies.length > 0 " [title]="'PROFILE_INFO.MOVIES' | translate"
                             icon="tv"
          >
              <div [innerHTML]="movies | emoji" class="emoji-small"></div>
          </profile-info-item>
        </div>
        <div class="mdc-layout-grid__cell--span-12">
          <profile-info-item [title]="'PROFILE_INFO.CHILDREN_WISH.LABEL' | translate"
                             icon="child-friendly"
          >
            {{childrenWishTranslation | translate }}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.SMOKER.LABEL' | translate"
                             icon="smoking-rooms"
          >
            {{user.smoker | smoker | translate }}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.ZODIAC_SIGN.LABEL' | translate"
                             icon="timeline"
          >
            {{user.zodiacSign | zodiacSign | translate }}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.LANGUAGES.LABEL' | translate"
                             icon="language"
          >
            {{languagesTranslation}}
          </profile-info-item>
          <profile-info-item [title]="'PROFILE_INFO.DESCRIPTION' | translate"
                             icon="edit"
          >
              <div [innerHTML]="user.description | emoji" class="emoji-small"></div>
          </profile-info-item>
        </div>
      </div>
    </div>
  `,
               styles: [require('../profile-info.scss')],
           })

export class ProfilePersonal {
    @Input() private user: ProfileInfo;

    get food(): string {
        return this.user.food.map(value => value.localizedNames.de).join(", ");
    }

    get music(): string {
        return this.user.music.map(value => value.localizedNames.de).join(", ");
    }

    get movies(): string {
        return this.user.movies.map(value => value.localizedNames.de).join(", ");
    }

    private get childrenTranslation(): string {
        switch (this.user.children) {
            case Children.AskMe:
                return 'PROFILE_INFO.CHILDREN.OPTIONS.ASK_ME';
            case Children.No:
                return 'PROFILE_INFO.CHILDREN.OPTIONS.NO';
            case Children.Yes:
                return 'PROFILE_INFO.CHILDREN.OPTIONS.YES';
            default:
                return '';
        }
    }

    private get childrenWishTranslation(): string {
        switch (this.user.childrenWish) {
            case ChildrenWish.AskMe:
                return 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.ASK_ME';
            case ChildrenWish.DontKnow:
                return 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.DONT_KNOW';
            case ChildrenWish.No:
                return 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.NO';
            case ChildrenWish.Yes:
                return 'PROFILE_INFO.CHILDREN_WISH.OPTIONS.YES';
            default:
                return '';
        }
    }

    private get languagesTranslation(): string {
        const languages = this.user.languages || [];
        return languages.map((cur: string) => (
                            this.translate.instant(`PROFILE_INFO.LANGUAGES.OPTIONS.${cur.toUpperCase()}`)
                        ))
                        .join(', ');
    }

    public constructor(private translate: TranslateService) {
    }
}
