import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Routes } from '../../../service/navigation.service';

@Component({
               selector: 'tab',
               template: `
                             <div [hidden]="!active">
                                 <ng-content></ng-content>
                             </div>`,
           })
export class Tab {
    @Input() public active: boolean = false;

    @Input() public badges: number = 0;

    @Input() public filterCount: number = 0;

    @Input() public route: Routes = Routes.Empty;

    @Input() public title: string = '';

    @Input() public id: string = '';

    @Input() public hidden: boolean = false;

    @Output() public onClick = new EventEmitter();
}
