import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import {
    Cacheable,
    CacheKey,
} from '../../../utils/cache';
import {
    Article,
    RssFeed,
} from './rss-feed';

@Injectable()
export class GlossaryRss extends RssFeed {
    private CDATARegex: RegExp = /<!\[CDATA\[(.*?)\]\]>/;

    // laods newst glossary article from rss-feeds
    @Cacheable(CacheKey.Glossary)
    public getEntrys(limit: number = 0, tag: string = ''): Promise<Article[]> {
        return this.httpClient
                   .get(this.getUrl(tag), { responseType: 'text' })
                   .toPromise()
                   .then((res) => {
                       const articles = this.parseGlossaryObject(res);
                       return limit == 0 ? articles : articles.slice(0, limit);
                   });
    }

    private getUrl(tag: string): string {
        return tag.isEmpty() ? `${BkConfig.glossaryUrl}feed/` : `${BkConfig.glossaryUrl}tag/${tag}/feed/`
    }

    // converts xml to glossary article object
    private convertGlossaryObject(parsedArticles): Article[] {
        const articles: Article[] = [];

        for (const parsedArticle of parsedArticles) {
            const description = parsedArticle.querySelector('description');
            const decodedDescription = this.getDecodedText(description.innerHTML);


            const article = new Article();
            article.title = parsedArticle.querySelector('title').innerHTML;
            article.link = parsedArticle.querySelector('link').innerHTML;
            article.image = parsedArticle.querySelector('mainImage').innerHTML;
            article.text = decodedDescription.replace(/<[^>]*>/g, '');
            article.category = this.getDecodedText(parsedArticle.querySelectorAll('category').item(1).innerHTML);
            article.rating = parsedArticle.querySelector('rating').innerHTML;
            article.readtime = parsedArticle.querySelector('readtime').innerHTML;

            articles.push(article);
        }

        return articles;
    }
    // converts text with "cdata" into pure plaintext
    private getDecodedText(text: string): string {
        const noLineBreaks = text.replace(/\r?\n|\r/g, '');
        const parsedText = this.CDATARegex.exec(noLineBreaks)[1];

        return parsedText.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
    }

    private parseGlossaryObject(xmlObject): Article[] {
        let parsedHTML = this.parseToHTML(xmlObject);
        parsedHTML = parsedHTML.querySelectorAll('item');
        return this.convertGlossaryObject(parsedHTML);
    }

    // parse xml to dom object
    private parseToHTML(toParseArticles): any {
        const parser = new DOMParser();
        return parser.parseFromString(toParseArticles, 'text/xml');
    }
}
