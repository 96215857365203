import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { Image } from 'BKModels';

@Component({
               selector: 'edit-images-preview',
               template: `
        <div class="edit-images-preview"
             *ngIf="open"
             (click)="emitClose()"
        >

            <div class="edit-images-preview__closer"
                 (click)="emitClose()"
            >
                <icon icon="close"></icon>
            </div>

            <div class="edit-images-preview__box">

                <img src="{{userImage.getUrl('750x1000')}}"
                     class="edit-images-preview__image"
                >
                <div class="edit-images-preview__description"
                     *ngIf="userImage.description !== ''"
                >
                    {{userImage.description}}
                </div>

            </div>

        </div>
    `,
               styles: [require('./edit-images-preview.scss')],
           })

export class EditImagesPreview {
    @Output() private close: EventEmitter<string> = new EventEmitter<string>();

    @Input() private open: boolean;

    @Input() private userImage: Image;

    private emitClose(): void {
        this.close.emit();
    }
}
