import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import { PremiumDetails } from '../../../../../models/premium-details';
import { CancelReasonPremium } from '../../../../../service/premium.service';


const cancelReasonArray = [{
    name:  'PREMIUM_CANCEL_REASON.FOUND_PARTNER_ON_BILDKONTAKTE',
    value: CancelReasonPremium.FoundPartnerBildkontakte,
    trackingLabel: 'PartnerGefunden'
}, {
    name:  'PREMIUM_CANCEL_REASON.TO_EXPENSIVE',
    value: CancelReasonPremium.Expensive,
    trackingLabel: 'ZuTeuer'
}, {
    name:  'PREMIUM_CANCEL_REASON.BASIC_IS_ENOUGH',
    value: CancelReasonPremium.BasicIsEnough,
    trackingLabel: 'ReichtBasis'
}, {
    name:  'PREMIUM_CANCEL_REASON.FOUND_PARTNER_ELSEWEHRE',
    value: CancelReasonPremium.FoundPartnerElsewhere,
    trackingLabel: 'WoandersPartner'
}, {
    name:  'PREMIUM_CANCEL_REASON.NO_SUCCESS',
    value: CancelReasonPremium.NoSuccess,
    trackingLabel: 'KeinErfolg'
}, {
    name:  'PREMIUM_CANCEL_REASON.RARELY_USE',
    value: CancelReasonPremium.RarelyUse,
    trackingLabel: 'ZuSelten'
}, {
    name:  'PREMIUM_CANCEL_REASON.UNAWARE_OF_SUBSCRIPTION',
    value: CancelReasonPremium.SubscriptionTrap,
    trackingLabel: 'NichtWissenAbo'
},
];
@Component({
               selector:  'premium-cancel-reason',
               template:  `
                              <div class="premium-cancel-reason">
                                  <div class="premium-cancel-reason__title">{{'PREMIUM.CANCEL.REASON-PAGE.TITLE' | translate}}</div>
                                  <div class="premium-cancel-reason__current-tariff">
                                      <div class="premium-cancel-reason__current-tariff__title">{{'PREMIUM.CANCEL.REASON-PAGE.SUBSCRIPTION-DETAIL.TITLE' | translate}}</div>
                                      <div class="premium-cancel-reason__current-tariff__box">
                                          <div class="premium-cancel-reason__current-tariff__box__title">
                                              {{premiumDetails.active.period.amount}} {{premiumDetails.active.period.durationTranslationKey | translate}} {{'GLOBAL.PREMIUM' | translate}}
                                              <icon class="premium-cancel-reason__current-tariff__box__title__icon" icon="crown-outline"></icon>
                                          </div>
                                          <div class="premium-cancel-reason__current-tariff__box__text">{{'PREMIUM.CANCEL.REASON-PAGE.SUBSCRIPTION-DETAIL.BOX' | translate:{ 'price': price } }}</div>
                                      </div>
                                  </div>

                                  <div class="premium-cancel-reason__text">{{'PREMIUM.CANCEL.REASON-PAGE.SELECT_YOUR_REASON' | translate}}</div>
                                  <radio-panel [small]="true" [options]="reasonArray" [(value)]="selectedReason"></radio-panel>


                                  <div class="premium-cancel-reason__text-area">
                                      <div>{{'PREMIUM.CANCEL.REASON-PAGE.MORE_NOTES' | translate}}</div>
                                      <text-area [(value)]="notes" rows="4"></text-area>
                                  </div>
                                  <div class="premium-cancel-reason__footer">
                                      <div class="premium-cancel-reason__footer__button">
                                          <raised-button (click)="onCancel()">{{'GLOBAL.CANCEL' | translate}}</raised-button>
                                          <raised-button fake-disable
                                                         [disabled]="buttonDisabled"
                                                         (click)="onConfirm()"
                                          >{{'PREMIUM.CANCEL.REASON-PAGE.CONTINUE' | translate}}</raised-button>
                                      </div>
                                      <div class="premium-cancel-reason__footer__support">
                                          <premium-support 
                                                  [noMargin]="true" 
                                                  [showEmail]="false" 
                                                  [showTitle]="false"
                                                  (clickPhoneNumber)="onSupportPhoneClick()"
                                          ></premium-support>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./premium-cancel-reason.scss')],
           })
export class PremiumCancelReason {
    private reasonArray = cancelReasonArray.shuffle();
    private selectedReason: number;
    private notes = '';

    @Input() private premiumDetails: PremiumDetails = new PremiumDetails();
    @Output() private cancel = new EventEmitter();
    @Output() private confirm = new EventEmitter();

    constructor(private trackingService: TrackingService) {
    }

    private get price() {
        if( this.premiumDetails.subscriptions.length ){
            return this.premiumDetails.subscriptions[0].price.toCurrencyString();
        }
        return '';
    }

    private get buttonDisabled() {
        return this.selectedReason === undefined;
    }

    private onSupportPhoneClick() {
        this.trackingService.hit('PremiumKuendigen', 'Kuendigungsgruende', 'Supportnummer');
    }

    private onCancel() {
        this.trackingService.hit('PremiumKuendigen', 'Kuendigungsgruende', 'Abbrechen');
        this.cancel.emit();
    }

    private onConfirm() {
        const reason = cancelReasonArray.find(it => it.value === this.selectedReason);
        this.trackingService.hit('PremiumKuendigen', 'Kuendigungsgruende', reason.trackingLabel);
        this.trackingService.hit('PremiumKuendigen', 'Kuendigen', 'Erfolgreich');
        this.confirm.emit({reason: this.selectedReason, notes: this.notes});
    }
}
