import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Conversation,
    ImageSize,
} from 'BKModels';
import * as moment from 'moment';

@Component({
               selector: 'po-box-list-chat-info',
               template: `<div class="info"
                               (click)="clickItem.emit()"
               >
                   <div class="info__time"
                        [class.list-chats__content__info__time--unread]="isUnread()"
                   >
                       {{time}}
                   </div>
                   <div class="info__badge">
                       <icon class="info__badge__pin-icon" icon="pin" *ngIf="chat.pinned"></icon>
                       <div class="info__badge__color circle" *ngIf="isUnread()">
                           <div class="circle__content">
                               {{ chat.unreadMessages | badge }}
                           </div>
                       </div>
                   </div>
               </div>`,
               styles: [require('./po-box-list-chat-info.scss')],
           })

export class PoBoxListChatInfo {
    @Input() private chat: Conversation;
    @Output() private clickItem = new EventEmitter();

    public constructor(private translate: TranslateService,) {
    }

    private isUnread(): boolean {
        return this.chat.isUnread();
    }

    private get time(): string {
        const msgDate = new Date(this.chat.lastMessageOn * 1000);
        const curDate = new Date();
        const timeDiff = msgDate.getTime() - curDate.getTime();
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        const tmp = moment.unix(this.chat.lastMessageOn);


        if (diffDays === 0) {
            return tmp.format('HH:mm');
        }
        if (diffDays === -1) {
            return this.translate.instant('CALENDER.YESTERDAY');
        }
        if (diffDays <= -2 && diffDays >= -7) {
            return tmp.format('dd.');
        }
        return tmp.format('DD.MM.YY');
    }

}
