import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector: 'radial-progress',
               template: `
        <div>
            <div class="bk-radial-progress__current"
                 [style.width]="radius * 2 + 'px'"
                 [style.height]="radius * 2 + 'px'"
                 [style.line-height]="radius * 2 + 'px'"
                 [style.color]="textColor"
            >
                {{label}}
            </div>

            <round-progress
                [current]="current"
                [max]="max"
                [stroke]="stroke"
                [radius]="radius"
                [semicircle]="semicircle"
                [rounded]="rounded"
                [responsive]="responsive"
                [clockwise]="clockwise"
                [color]="color"
                [background]="background"
                [duration]="duration"
                [animation]="animation"
                [animationDelay]="animationDelay"
            ></round-progress>
        </div>`,
               styles: [require('./radial-progress-bar.scss')],
           })

export class RadialProgressBar {
    @Input() private animation: string = 'easeOutCubic';

    @Input() private animationDelay: number = null;

    @Input() private background: string = '#EAEAEA';

    @Input() private clockwise: boolean = true;

    @Input() private color: string = '#45CCCE';

    @Input() private current: number;

    @Input() private duration: number = 500;

    @Input() private label: string;

    @Input() private max: number;

    @Output() private onRender: EventEmitter<number> = new EventEmitter();

    @Input() private radius: number = 50;

    @Input() private responsive: boolean = false;

    @Input() private rounded: boolean = false;

    @Input() private semicircle: boolean = false;

    @Input() private stroke: number = 15;

    @Input() private textColor: string;
}
