import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { SearchCriteria } from './criteria/search-criteria';
import { SearchExtended } from './extended/search-extended';
import { SearchExtendedChips } from './extended/search-extended-chips';
import { SearchExtendedCriteria } from './extended/search-extended-criteria';
import { SearchExtendedLook } from './extended/search-extended-look';
import { SearchExtendedOthers } from './extended/search-extended-others';
import { SearchExtendedPersonal } from './extended/search-extended-personal';
import { SearchEmptyList } from './list-placeholder/list-placeholder';
import { SearchQuick } from './quick/search-quick';
import { SearchQuickCriteria } from './quick/search-quick-criteria';
import { SearchHeader } from './search-header/search-header';
import { SearchHeaderMenu } from './search-header/search-header-menu';
import { SearchSubheader } from './search-header/search-subheader';
import { SearchModal } from './search-modal/search-modal';
import { SearchModalBox } from './search-modal/search-modal-box';
import { SearchNickname } from './search-nickname/search-nickname';
import { SearchRouting } from './search.routing';

import { Search } from './search/search';


@NgModule({
              imports:      [
                  ShareModule,
                  SearchRouting,
              ],
              exports:      [],
              declarations: [
                  SearchExtended,
                  SearchModalBox,
                  SearchExtendedCriteria,
                  SearchExtendedLook,
                  SearchExtendedPersonal,
                  SearchExtendedOthers,
                  SearchExtendedChips,
                  Search,
                  SearchQuickCriteria,
                  SearchQuick,
                  SearchSubheader,
                  SearchNickname,
                  SearchCriteria,
                  SearchEmptyList,
                  SearchModal,
                  SearchHeader,
                  SearchHeaderMenu,
              ],
          })
export class SearchModule {
}
