import {
    Injectable,
    Injector,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    addLoginEventListener,
    addLogoutEventListener,
    DynamicallyInjected,
} from 'BKUtils';
import {
    BehaviorSubject,
    Subscription,
} from 'rxjs';
import { PremiumDetails } from '../models/premium-details';
import { BkModalChargebackPaymentFailed } from '../shared/mat-design/bk-modals/bk-modal-chargeback-payment-failed';
import { BkPremiumModal } from '../shared/mat-design/bk-modals/bk-premium-modal';
import {
    Cacheable,
    CacheKey,
} from '../utils/cache';
import { ApiService } from './api';
import { GlobalModalService } from './global-modal.service';
import {
    NavigationService,
    QueryParameter,
    Routes,
} from './navigation.service';
import {
    PaymentService,
    StoreType,
} from './payment.service';
import { SessionService } from './session.service';
import { CurrentUserService } from './user.service';

export enum CancelReasonPremium {
    FoundPartnerBildkontakte = 0,
    Expensive = 1,
    BasicIsEnough = 2,
    FoundPartnerElsewhere = 3,
    NoSuccess = 4,
    RarelyUse = 5,
    SubscriptionTrap = 6,
}

export class FailedChargebackPaymentInfo {
    readonly accountOwner = 'Entrex e.K.';
    readonly iban = 'DE02375514400107007411';
    readonly bic = 'WELADEDLLEV';

    constructor(
        readonly reference: string,
        readonly value: number,
        readonly paymentDue: number) {}
}

@Injectable({ providedIn: 'root' })
export class PremiumService {
    protected storeType = StoreType.Unknown;

    private currentUserSubscription = new Subscription();

    private failedChargebackPaymentInfoSubject = new BehaviorSubject<FailedChargebackPaymentInfo>(null)
    failedChargebackPaymentInfo$ = this.failedChargebackPaymentInfoSubject.asObservable();

    private navigationService = new DynamicallyInjected(NavigationService, this.injector);

    public constructor(
        private apiService: ApiService,
        private currentUserService: CurrentUserService,
        private sessionService: SessionService,
        private globalModalService: GlobalModalService,
        private injector: Injector,
        private paymentService: PaymentService
    ) {
        addLoginEventListener(() => this.onLogin());
        addLogoutEventListener(() => this.onLogout());
        if (sessionService.isLoggedIn()) this.onLogin();

        this.paymentService.detectStoreType().then(type => this.storeType = type);
    }

    @Cacheable(CacheKey.PremiumDetails)
    public getDetails(): Promise<PremiumDetails> {
        return this.apiService.premium.details();
    }

    public hasSubscription(): Promise<boolean> {
        return this.getDetails().then(details => details.hasSubscription);
    }

    public cancel(reason: CancelReasonPremium, notes: string): Promise<any> {
        return this.apiService.premium.cancel(reason, notes);
    }

    private onLogin() {
        this.currentUserSubscription = this.currentUserService.currentUserObservable.subscribe(value => this.onCurrentUserUpdate(value));
    }

    private onLogout() {
        this.currentUserSubscription.unsubscribe();
        this.failedChargebackPaymentInfoSubject.next(null);
    }

    private onCurrentUserUpdate(currentUser: CurrentUser) {
        if (currentUser.invoice) {
            const info = new FailedChargebackPaymentInfo(
                currentUser.invoice.reference,
                currentUser.invoice.total,
                currentUser.invoice.dueDate * 1000);
            this.failedChargebackPaymentInfoSubject.next(info);
        }
    }

    public navigateToPremium(orderReason: OrderReason = OrderReason.None) {
        const failedChargebackPaymentInfo = this.failedChargebackPaymentInfoSubject.value;
        if (failedChargebackPaymentInfo) {
            this.globalModalService.open(BkModalChargebackPaymentFailed.typeId, failedChargebackPaymentInfo);
            return;
        }

        if (this.storeType === StoreType.GooglePlay) {
            this.globalModalService.open(BkPremiumModal.typeId, { reason: orderReason });
        } else {
            const queryParams = {};
            if (orderReason !== OrderReason.None) queryParams[QueryParameter.OrderReason] = OrderReason.getQueryString(orderReason);
            this.navigationService.get.navigateTo(Routes.PremiumLandingPage, { queryParams });
        }

    }

}
