import { Injectable } from '@angular/core';
import { BkConfig } from 'BKConfig';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';


@Injectable()
export class SingleEventsApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public list(limit: number, offset: number = 0): Promise<any> {
        return this.apiRequestService.createGet(Endpoints.singleEvents.getList, {
            body: {
                limit,
                offset,
                client_id: BkConfig.client.id,
            },
        });
    }
}
