import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes,
} from '@angular/router';
import { StartpageHeader } from './startpage-header/startpage-header';
import { Startpage } from './startpage/startpage';

const startpageRoutes: Routes = [
    {
        path:     '',
        children: [
            { path: '', component: Startpage },
            { path: '', component: StartpageHeader, outlet: 'header'},
        ],
    },
];

export const StartpageRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(startpageRoutes);
