import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    OrderReason,
    UserBaseView,
} from 'BKModels';
import {
    ModalService,
    TrackingService,
} from 'BKService';
import { PremiumDetails } from '../../../../../models/premium-details';
import { NavigationService } from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

@Component({
               selector: 'settings-account-delete-step-selected-user',
               template: `
                             <settings-account-delete-title [title]="'ACCOUNT.DELETE.STEP.SELECTED_USER.TITLE' | translate"
                                                            [subtitle]="'ACCOUNT.DELETE.STEP.SELECTED_USER.SUBTITLE' | translate"
                                                            [text]="'ACCOUNT.DELETE.STEP.SELECTED_USER.TEXT' | translate"
                             ></settings-account-delete-title>

                             <cancel-user-list-big [list]="list" (action)="openProfile($event)"></cancel-user-list-big>

                             <premium-day-box *ngIf="premium && premiumDetails.active.remainingDays > 0"
                                              [text]="translationKeyBox | translate:{ remainingDays: premiumDetails.active.remainingDays.toString() }"
                             ></premium-day-box>

                             <settings-account-delete-footer [cancelText]="'ACCOUNT.DELETE.STEP.SELECTED_USER.CANCEL' | translate"
                                                             (cancel)="cancel.emit($event);track('VerlusteAbbrechen')"
                                                             [nextText]="'ACCOUNT.DELETE.STEP.SELECTED_USER.NEXT' | translate"
                                                             (next)="next.emit($event);track('Fortfahren', 'WeiterVerluste')"
                             ></settings-account-delete-footer>
                         `,
           })
export class SettingsAccountDeleteStepSelectedUser {
    @Input() private premium: boolean = false;
    @Input() private list: UserBaseView[];
    @Output() private cancel = new EventEmitter();
    @Output() private next = new EventEmitter();
    @Input() private premiumDetails: PremiumDetails;

    constructor(
        private modalService: ModalService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private trackingService: TrackingService
    ) {}

    private get translationKeyBox(): string {
        return this.premiumDetails.active.remainingDays > 1 ? 'ACCOUNT.DELETE.REMAINING_PREMIUM.MULTIPLE' : 'ACCOUNT.DELETE.REMAINING_PREMIUM.SINGLE';
    }

    private openProfile(user: UserBaseView) {
        this.track('VerlusteZumProfil');
        if (user.isBasisView()) {
            this.premiumService.navigateToPremium(OrderReason.SelectedUserAccountDelete);
        } else {
            this.modalService.profileView.openOnStartpage(user.id);
        }
    }

    private track(label: string, action: string = 'ProzessVerlassen') {
        this.trackingService.hit('AccountLoeschen', action, label);
    }
}
