import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {
    ProfileInfo,
    Settings,
} from 'BKModels';
import {
    CommunicationService,
    CurrentUserService,
    SettingsService,
} from 'BKService';
import { Subscription } from 'rxjs';
import {
    MailSettings,
    MailSettingsService,
} from '../../../../service/mail-settings.service';

@Component({
               selector:  'setting-notification',
               template: `
                             <card-title>
                                 <div class="setting-notification__text">{{'SETTINGS.NOTIFICATION.TITLE' | translate}}</div>
                                 <div class="setting-notification__email">{{currentUserProfile.email}}</div>

                                 <settings-notification-email
                                         *ngIf="!!currentMailSettings"
                                         [settings]="currentMailSettings"
                                         (save)="save()"
                                 ></settings-notification-email>

                                 <settings-notification-push [settings]="curSettings.pushSettings"
                                                             (save)="save()"
                                 ></settings-notification-push>
                             </card-title>

                         `,
               styles: [require('./settings-notification.scss')],
           })

export class SettingNotification implements OnInit, OnDestroy {
    private curSettings: Settings = new Settings();
    private lastMailSettings: MailSettings|null = null;
    private currentMailSettings: MailSettings|null = null;

    private settingsSubscription = new Subscription();
    private mailSettingsSubscription = new Subscription();

    public constructor(
        private settingsService: SettingsService,
        private mailSettingsService: MailSettingsService,
        private communicationService: CommunicationService,
        private currentUserService: CurrentUserService) {
    }

    public ngOnInit(): void {
        this.settingsSubscription = this.settingsService
            .settings
            .subscribe((curSettings) => {
                this.curSettings = Settings.create(curSettings);
            });

        this.mailSettingsSubscription = this.mailSettingsService.mailSettings$.subscribe((settings) => {
            if (!this.currentMailSettings) this.currentMailSettings = settings;
            this.lastMailSettings = settings;
        });

        this.mailSettingsService.update();
    }

    public ngOnDestroy() {
        this.settingsSubscription.unsubscribe();
        this.mailSettingsSubscription.unsubscribe();
    }

    private get currentUserProfile(): ProfileInfo {
        return this.currentUserService.cachedProfileInfo;
    }

    private save() {
        this.settingsService.sendToApi(this.curSettings);
        this.mailSettingsService
            .setIntervals(this.currentMailSettings.allSettings())
            .then(_ => this.communicationService.growl.settings.emailSettings.success())
            .catch(reason => this.communicationService.growl.settings.emailSettings.handleError());
    }
}
