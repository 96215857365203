import {
    Component,
    Input,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import {
    CurrentUserService,
    ModalService,
    TrackingService,
} from 'BKService';
import {
    FavoriteDataSource,
    FavoriteDeletedDataSource,
} from '../../../../models/list-data-sources';
import { FavoriteService } from '../../../../service/favorite.service';

/**
 *
 * Component for the Favorite list
 */
@Component({
               selector: 'favorite-list',
               template: `
                             <div class="favorite">
                                 <favorite-empty *ngIf="isEmpty && !isLoading"
                                                 [currentUser]="currentUserService.cachedCurrentUser"
                                 ></favorite-empty>

                                 <list-container [dataSource]="listDataSource">
                                     <ng-template let-item>
                                         <favorite-item two-columns-max
                                                        *ngIf="!isDeleted(item.item.favorite.id)"
                                                        [item]="item.item"
                                                        (delete)="delete($event)"
                                                        [currentUser]="currentUserService.cachedCurrentUser"
                                                        (noticeChange)="noticeChange()"
                                                        (openProfile)="openProfile($event)"
                                                        class="list__item"
                                         ></favorite-item>

                                     </ng-template>
                                 </list-container>

                             </div>`,
               styles: [require('./favorite-list.scss')],
           })
export class FavoriteList {
    @Input() private listDataSource: FavoriteDataSource | FavoriteDeletedDataSource;

    private deletedIds: number[] = [];

    public constructor(
        private currentUserService: CurrentUserService,
        private favoriteService: FavoriteService,
        private modalService: ModalService,
        private trackingService: TrackingService
    ) {
    }

    private get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    private get isEmpty(): boolean {
        return (!!this.listDataSource && this.listDataSource.isEmpty);
    }

    private delete(user: UserBaseView) {
        this.trackingService.hit('Favoriten', 'PapierkorbAngeklickt', 'FavoritGeloescht');
        this.deletedIds.push(user.id);
        this.favoriteService
            .remove(user)
            .then();
    }

    private isDeleted(id: number): boolean {
         return this.deletedIds.some(cur => cur === id);
    }

     private noticeChange() {
    //     super.ngOnInit();
     }

    private openProfile(id: number) {
        this.modalService.profileView.open(id);
    }
}
