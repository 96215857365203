import { Component } from '@angular/core';

@Component({
               selector:  'search-list-placeholder',
               template:  `
                              <list-empty [title]="'SEARCH.FEW_RESULTS.TITLE' | translate"
                                          [text]="'SEARCH.FEW_RESULTS.TEXT' | translate"
                              ></list-empty>

                          `,
               styles: [require('./list-placeholder.scss')],
           })

export class SearchEmptyList {
}
