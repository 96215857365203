/**
 * Created by olifra on 19.05.2017.
 */
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    MatchingDecision,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import {
    ApiService,
    CommunicationService,
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../../../../service/message.service';
import { NavigationService } from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { BkDialogReportViolation } from '../../../../shared/mat-design/bk-dialogs/bk-dialog-report-violation';
import { ProfileHeader } from '../components/profile-header/profile-header';
import { ProfilePanel } from '../components/profile-panel/profile-panel';
import { ProfileViewService } from '../profile-view-service';

@Component({
               selector:  'profile-view',
               template: `
                             <ng-container>
                                 <profile-placeholder #placeholder
                                                      [user]="user"
                                                      (close)="close.emit()"
                                 ></profile-placeholder>
                                 <ng-container *ngIf="!placeholder.isShowingPlaceholder">
                                     <profile-header #profileHeader
                                                     [user]="user"
                                                     [currentUser]="currentUser"
                                                     (close)="close.emit()"
                                                     (addFavorite)="addFavorit()"
                                                     (removeFavorite)="removeFavorite()"
                                                     (addIgnore)="addIgnore()"
                                                     (report)="report()"
                                     ></profile-header>
                                     <separator class="profile-view__seperator header" thickness="1px"></separator>
                                     <profile-panel
                                             #panel
                                             [currentUser]="currentUser"
                                             [user]="user"
                                             [modal]="true"
                                             (writeMsg)="writeMsg($event)"
                                             (openGallery)="openGallery()"
                                             (decide)="decide($event)"
                                             (addFavorite)="addFavorit()"
                                             (removeFavorite)="removeFavorite()"
                                             (close)="close.emit()"
                                     ></profile-panel>
                                     <ad-slot slotName="BkRl_Profilansicht"></ad-slot>
                                     <separator class="profile-view__seperator" thickness="1px"></separator>
                                     <profile-over-me [user]="user" [currentUser]="currentUser"></profile-over-me>
                                     <separator class="profile-view__seperator" thickness="1px"></separator>
                                     <profile-search [user]="user"></profile-search>
                                     <separator class="profile-view__seperator" thickness="1px"></separator>
                                     <profile-personal [user]="user"></profile-personal>
                                 </ng-container>
                                 <bk-dialog-report-violation #reportViolation [user]="user"></bk-dialog-report-violation>
                             </ng-container>
                         `,
               styles: [require('./profile-view.scss')],
           })
// implements OnInit
export class ProfileView implements OnDestroy, OnInit {
    @ViewChild('profileHeader', { static: false }) profileHeader: ProfileHeader;
    @ViewChild('panel', { static: false }) profilePanel: ProfilePanel;
    @ViewChild('reportViolation', { static: false }) reportViolation: BkDialogReportViolation;
    @Output('openGallery') _openGallery = new EventEmitter();
    @Output() close = new EventEmitter();
    currentUser: CurrentUser = new CurrentUser();
    @Input() modal: boolean = false;
    @Input() user: ProfileInfo = new ProfileInfo();
    private unsubscribe$ = new Subject();

    constructor(
        private api: ApiService,
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private premiumService: PremiumService,
        private messageService: MessageService,
        private matchingService: MatchingService,
        private communicationService: CommunicationService,
        private profileViewService: ProfileViewService,
        private trackingService: TrackingService,
    ) {

    }

    ngOnInit() {
        this.currentUserService
            .currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((val: CurrentUser) => {
                this.currentUser = val;
            });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    decide(decision: MatchingDecision) {
        switch (decision) {
            case MatchingDecision.Yes:
                this.trackingService.hit('ProfilAnsehen', 'MatchingSpielen', 'Ja');
                break;
            case MatchingDecision.No:
                this.trackingService.hit('ProfilAnsehen', 'MatchingSpielen', 'Nein');
                break;
            case MatchingDecision.Maybe:
                this.trackingService.hit('ProfilAnsehen', 'MatchingSpielen', 'Vielleicht');
                break;
        }

        this.matchingService.setDecision(this.user, decision);
        this.matchingService.removeMatchedItemFromQueue(this.user);
        this.user.decisionOwner = decision;
    }

    openGallery() {
        const ownProfile = this.currentUser.id === this.user.id;
        if (this.currentUser.isPremium() || ownProfile) {
            this._openGallery.emit(ownProfile);
        } else {
            this.premiumService.navigateToPremium(OrderReason.AllImages);
        }
    }

    writeMsg(msg: string) {
        this.trackingService.hit('ProfilAnsehen', 'NachrichtSchreiben', 'NachrichtVersendet');
        const conversationId = `${this.currentUser.id}-${this.user.id}`;
        this.profilePanel.disableMessages();
        this.messageService.send(conversationId, msg).then(val => {
            this.communicationService.growl.message.send.handleSuccess(this.user);
            this.profilePanel.clearMessage();
            this.profilePanel.closeMessageInput();

            if (!this.user.conversation) {
                this.trackingService.hit('Aktivitaet', 'KonversationBegonnen', 'NachrichtVersendet');
            }
        }).catch(err => {
            this.communicationService.growl.message.send.handleError(err.errno, this.user);
        }).finally(() => {
            this.profilePanel.enableMessages();
        });
    }

    removeFavorite() {
        this.profileViewService
            .favorite
            .remove(this.user)
            .then(() => {
                this.user.favorite = false;
            });
    }

    addFavorit() {
        this.profileViewService
            .favorite
            .add(this.user)
            .then(() => {
                this.user.favorite = true;
            });
    }

    addIgnore() {
        this.profileViewService
            .ignore
            .add(this.user)
            .then(() => {
                this.user.ignore = true;
            });
    }

    report() {
        this.reportViolation.open();
    }

}
