import { Injectable } from '@angular/core';
import {
    DialogState,
    ProfileInfo,
} from 'BKModels';
import {
    ApiService,
    CommunicationService,
} from 'BKService';
import { FavoriteService } from '../../../service/favorite.service';
import { IgnoreService } from '../../../service/ignore.service';

export enum ProfileViewStates {
    ProfileView,
    ImageGallery,
    WriteMsg,
    WriteFavoriteNotice,
    FavoriteView,
}


class ProfileState {
    private state: ProfileViewStates = ProfileViewStates.ProfileView;

    public isFavoriteView(): boolean {
        return this.state === ProfileViewStates.FavoriteView;
    }

    public isImageGallery(): boolean {
        return this.state === ProfileViewStates.ImageGallery;
    }

    public isProfileView(): boolean {
        return this.state === ProfileViewStates.ProfileView;
    }

    public isWriteFavoriteNotice(): boolean {
        return this.state === ProfileViewStates.WriteFavoriteNotice;
    }

    public isWriteMsg(): boolean {
        return this.state === ProfileViewStates.WriteMsg;
    }

    public openFavoriteView() {
        this.state = ProfileViewStates.FavoriteView;
    }

    public openGallery() {
        this.state = ProfileViewStates.ImageGallery;
    }

    public openProfileView() {
        this.state = ProfileViewStates.ProfileView;
    }

    public openWirteMsg() {
        this.state = ProfileViewStates.WriteMsg;
    }

    public openWriteFavoriteNotice() {
        this.state = ProfileViewStates.WriteFavoriteNotice;
    }
}


@Injectable()
export class ProfileViewService {
    public dialogOpen = {
        favorite:      new DialogState(),
        noPic:         new DialogState(),
        notValidated:  new DialogState(),
        ignore:        new DialogState(),
    };

    public state = new ProfileState();

    public ignore = {
        add:    (user: ProfileInfo) => this.ignoreService.add(user)
                                           .then(() => this.communicationService.growl.ignore.add.handleSuccess(user))
                                           .catch(()=> this.communicationService.growl.ignore.add.handleError(user)),
        remove: (user: ProfileInfo) => this.ignoreService.remove(user)
                                           .then((res) => {
                                               this.state.openProfileView();
                                               this.communicationService.growl.ignore.remove.handleSuccess(user)
                                               return res;
                                           })
                                           .catch(() => this.communicationService.growl.ignore.remove.handleError(user)),
    };

    public favorite = {
        add:    (user: ProfileInfo) => this.favoriteService.add(user)
                                           .then((res) => {
                                               this.dialogOpen.favorite.open();
                                               return res;
                                           }),
        remove: (user: ProfileInfo) => this.favoriteService
                                           .remove(user)
                                           .then((res) => {
                                               this.state.openProfileView();
                                               return res;
                                           }),
    };

    public constructor(private favoriteService: FavoriteService, private ignoreService: IgnoreService, private communicationService: CommunicationService) {
    }

    public init() {
        this.state = new ProfileState();
    }
}
