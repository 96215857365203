import { State } from '../../../models/state';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';

export class FeedbackApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public feedback(feedback: string): Promise<State> {
        return this.apiRequestService
            .createPost(Endpoints.feedback, { body: { feedback } })
            .then(res => State.create(res['data']));
    }

}
