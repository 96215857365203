import {
    Component,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import {
    ImageCropperComponent,
    ImageTransform,
} from 'ngx-image-cropper';

@Component({
               selector:      'cropper',
               template:      `
                                  <div class="cropper">
<!-- Commented out because zooming is not supported. See below for more info.
                                      <div class="cropper__hint" *ngIf="scaleFactor > 1">
                                          <icon icon="info-outline"></icon>
                                          {{"CROPPER.MAGNIFY_HINT" | translate}}
                                      </div>
-->
                                      <image-cropper #cropper
                                                     class="image-cropper"
                                                     [imageURL]="imageUrl"
                                                     format="jpeg"
                                                     [autoCrop]="false"
                                                     [aspectRatio]="1/1"
                                                     [canvasRotation]="rotation"
                                                     [maintainAspectRatio]="true"
                                                     [cropperMinHeight]="500"
                                                     [cropperMinWidth]="500"
                                                     [transform]="imageTransform"
                                      ></image-cropper>
                                      
                                      <div class="cropper__controls">
                                          <div class="cropper__controls__control">
                                              <div class="cropper__controls__label">{{'CROPPER.ROTATE' | translate}}:</div>
                                              <icon-button icon="rotate-left" color (click)="rotateLeft()"></icon-button>
                                              <icon-button icon="rotate-right" color (click)="rotateRight()"></icon-button>
                                          </div>

                                          <div class="cropper__controls__control">
                                              <div class="cropper__controls__label">{{'CROPPER.MIRROR' | translate}}</div>
                                              <icon-button icon="mirror" color (click)="isMirrored = !isMirrored"></icon-button>
                                          </div>

<!-- Commented out because zooming is supported but not moving the image. This makes it complicated if the part of the image to be selected is not straight in the middle.
                                          <div class="cropper__controls__control">
                                              <div class="cropper__controls__label">{{'CROPPER.ZOOM' | translate}}:</div>
                                              <icon-button (click)="scaleFactor = scaleFactor - 0.2" icon="remove-circle-outline" color [disabled]="scaleFactor <= 1"></icon-button>
                                              <icon-button (click)="scaleFactor = scaleFactor + 0.2" icon="add-circle-outline" color [disabled]="scaleFactor >= 3"></icon-button>
                                          </div>
-->
                                      </div>
                                  </div>
                              `,
               styles:     [require('./cropper.scss')],
               encapsulation: ViewEncapsulation.None,

           })

export class Cropper {
    @ViewChild('cropper') private cropper: ImageCropperComponent;

    @Input() imageUrl: string;

    isMirrored = false;

    private _scaleFactor = 1;

    imageTransform: ImageTransform = {};

    get scaleFactor(): number {
        return this._scaleFactor;
    }

    set scaleFactor(v: number) {
        this._scaleFactor = v;
        if (this._scaleFactor < 1) this.scaleFactor = 1;
        else if (this._scaleFactor > 3) this.scaleFactor = 3;

        this.imageTransform = {
            rotate: this.imageTransform.rotate,
            scale: this._scaleFactor,
            flipH: this.imageTransform.flipH,
            flipV: this.imageTransform.flipV
        };
    }

    private _rotation = 0;
    get rotation(): number {
        return this._rotation;
    }

    set rotation(v: number) {
        this._rotation = v % 4;
        this.imageTransform = {
            rotate: this._rotation * 90,
            scale: this.imageTransform.scale,
            flipH: this.imageTransform.flipH,
            flipV: this.imageTransform.flipV
        };
    }

    getCroppedImage(): string {
        const event = this.cropper.crop();
        return event.base64;
    }

    rotateLeft() {
        this.rotation -= 1;
    }

    rotateRight() {
        this.rotation += 1;
    }

}
