export class ImageUploadResult {
    public state = false;
    public imageName = '';

    public static create(data: ImageUploadResult): ImageUploadResult {
        const res = new ImageUploadResult();

        res.state = data.state;
        res.imageName = data.imageName;

        return res;
    }
}
