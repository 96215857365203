import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCDialog } from '@material/dialog/component';
import { DialogBase } from './dialog-base';

@Component({
               selector:  'dialog-box',
               template:  `
                              <div #dialog
                                   class="mdc-dialog dialog-box"
                                   role="alertdialog"
                                   aria-modal="true"
                              >
                                  <div class="mdc-dialog__container">
                                      <div class="mdc-dialog__surface dialog-box__surface">
                                          <p *ngIf="!title.isEmpty()"
                                             class="mdc-dialog__title dialog-box__title"
                                             [class.dialog-box__title--info]="type == 'info'"
                                             [class.dialog-box__title--warning]="type == 'warning'"
                                             [class.dialog-box__title--error]="type == 'error'"
                                             [class.dialog-box__title--success]="type == 'success'"
                                          >
                                              <icon class="dialog-box__icon"
                                                    [icon]="icon"
                                              ></icon>{{title}}
                                          </p>
                                          <div class="mdc-dialog__content dialog-box__content">
                                              <ng-content></ng-content>
                                          </div>

                                          <footer class="mdc-dialog__actions dialog-box__actions" *ngIf="!rejectText.isEmpty() || !acceptText.isEmpty()">
                                              <flat-button *ngIf="!rejectText.isEmpty()"
                                                           (click)="onReject($event)"
                                                           tabindex="0"
                                              >
                                                  {{rejectText}}
                                              </flat-button>
                                              <raised-button *ngIf="!acceptText.isEmpty()"
                                                           (click)="onAccept($event)"
                                                           [disabled]="acceptDisabled"
                                                           tabindex="0"
                                              >
                                                  {{acceptText}}
                                              </raised-button>
                                          </footer>
                                      </div>
                                  </div>
                                  <div class="mdc-dialog__scrim"></div>
                              </div>
                          `,
               styles: [require('./dialog-box.scss')],
           })

export class DialogBox extends DialogBase {
    @Output('onAccept') private _onAccept = new EventEmitter();
    @Output('onReject') private _onReject = new EventEmitter();
    @Input() private acceptText: string = '';
    @Input() private acceptDisabled: boolean = false;
    @Input() private rejectText: string = '';
    @Input() private title: string = '';
    @Input() private type: DialogType = '';

    private get icon() {
        switch (this.type) {
            case 'info':
                return 'info';
            case 'success':
                return 'check-circle';
            case 'warning':
                return 'error';
            case 'error':
                return 'cancel';
            default:
                return '';
        }
    }

    private onAccept($event) {
        $event.stopPropagation();
        this._onAccept.emit($event);
        this.close();
    }

    private onReject($event) {
        $event.stopPropagation();
        this._onReject.emit($event);
        this.close();
    }
}
