import {
    Component,
    Input,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import {
    ModalService,
    TrackingService,
} from 'BKService';

@Component({
               selector:  'premium-cancel-single-list',
               template:  `
                              <div class="premium-cancel-single-list__headline">
                                  {{'PREMIUM.CANCEL.SELECTED_SINGLES.TITLE' | translate}}
                              </div>
                              <cancel-user-list-big [list]="list" (action)="openProfile($event)"></cancel-user-list-big>
                          `,
               styles: [require('./premium-cancel-single-list.scss')],
           })

export class PremiumCancelSingleList {
    @Input() private list: UserBaseView[];

    constructor(
        private modalService: ModalService,
        private trackingService: TrackingService) {
    }

    private openProfile(user: UserBaseView) {
        this.trackingService.hit('PremiumKuendigen', 'ProzessVerlassen', 'VerlusteZumProfil');
        this.modalService.profileView.openOnStartpage(user.id);
    }
}
