import {
    Component,
    Input,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';

@Component({
               selector:  'profile-matching-answer',
               template:  `
                              <div class="profile-matching-answer">
                                  <div class="profile-matching-answer__title">{{'MATCHING.YOUR_ANSWER' | translate }}</div>
                                  <profile-matching-decision [type]="user.decisionOwner"></profile-matching-decision>
                              </div>
                          `,
               styles: [require('./profile-matching-answer.scss')],
           })

export class ProfileMatchingAnswer {
    @Input() private user: ProfileInfo;
}
