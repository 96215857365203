import { Component } from '@angular/core';

@Component({
               selector: 'profile-edit-header',
               template: `
                             <intern-default-header [title]="'PROFILE_EDIT.SUBHEADER.TITLE' | translate" [closeable]="shouldShowNavigation">
                                 <navigation-list class="is-hidden-desktop" *ngIf="shouldShowNavigation"></navigation-list>
                             </intern-default-header>`,
           })
export class ProfileEditHeader {
    private get shouldShowNavigation(): boolean {
        var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        return !iOS;
    }
}
