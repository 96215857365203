import { Component } from '@angular/core';
import { ProfileEditBoxBase } from '../profile-edit-box/profile-edit-box-base';

@Component({
               selector: 'profile-edit-look',
               template: `
        <profile-edit-box [title]="'PROFILE-EDIT.TITLE.LOOK'|translate"
                          [group]="group"
                          (save)="save($event)"
                          (reset)="reset($event)"
        >
            <form [formGroup]="group">
                <profile-edit-row slider [label]="'PROFILE_INFO.SIZE' | translate">
                    <range [min]="80"
                              [max]="220"
                              [tooltips]="[true]"
                              [connect]="[true, false]"
                              formControlName="size"
                              [formControl]="group.controls.size"
                    ></range>
                </profile-edit-row>

                <profile-edit-row>
                    <dropdown [placeholder]="'PROFILE_INFO.BODYSHAPE' | translate" formControlName="figure">
                        <dropdown-option *ngFor="let key of 5 | range" [value]="key"
                                         [selected]="key == getGroupValue('figure')"
                        >
                            {{'PROFILE_INFO.BODYSHAPE.OPTIONS.' + key  | translate }}
                        </dropdown-option>
                    </dropdown>
                </profile-edit-row>

                <profile-edit-row>
                    <dropdown [placeholder]="'PROFILE_INFO.HAIR_COLOR.LABEL' | translate" formControlName="haircolor">
                        <ng-container *ngFor="let key of 10 | range">
                            <dropdown-option *ngIf="key > 0"
                                             [value]="key"
                                             [selected]="key == getGroupValue('haircolor')"
                            >
                                {{'PROFILE_INFO.HAIR_COLOR.OPTIONS.' + key  | translate }}
                            </dropdown-option>
                        </ng-container>
                    </dropdown>
                </profile-edit-row>

                <profile-edit-row>
                    <dropdown [placeholder]="'PROFILE_INFO.EYE_COLOR.LABEL' | translate" formControlName="eyecolor">
                        <ng-container *ngFor="let key of 9 | range">
                            <dropdown-option *ngIf="key > 0"
                                             [value]="key"
                                             [selected]="key == getGroupValue('eyecolor')"
                            >
                                {{'PROFILE_INFO.EYE_COLOR.OPTIONS.' + key  | translate }}
                            </dropdown-option>
                        </ng-container>
                    </dropdown>
                </profile-edit-row>

            </form>
        </profile-edit-box>
    `,
           })

export class ProfileEditLook extends ProfileEditBoxBase {

}
