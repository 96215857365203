import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NGRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { SettingsIgnoreList } from './settings-ignore/settings-ignore-list';
import { SettingsAccountDeleteHeader } from './settings-account-delete/settings-account-delete-header/settings-account-delete-header';
import { SettingsAccountDelete } from './settings-account-delete/settings-account-delete/settings-account-delete';
import { SettingsChangeSepa } from './settings-change-sepa/settings-change-sepa';
import { SettingsChangeSepaHeader } from './settings-change-sepa/settings-change-sepa-header';
import { SettingsDebitAgain } from './settings-debit-again/settings-debit-again';
import { SettingsDebitAgainHeader } from './settings-debit-again/settings-debit-again-header';
import { SettingsHeader } from './settings-header/settings-header';
import { SettingsAccount } from './settings-account/settings-account';
import { SettingNotification } from './settings-notification/settings-notification';
import { SettingsChangePassword } from './settings-password-change/settings-change-password';
import { SettingsPrivacy } from './settings-privacy/settings-privacy';
import { Settings } from './settings/settings';


const SettingRoutes: NGRoutes = [
    {
        path:     '',
        children: [
            { path: '', component: SettingsHeader, outlet: 'header' },
            {
                path:      '',
                component: Settings,
                children:  [
                    { path: routeName(Routes.SettingsAccount), component: SettingsAccount },
                    { path: routeName(Routes.SettingsPassword), component: SettingsChangePassword},
                    { path: routeName(Routes.SettingsNotification), component: SettingNotification},
                    { path: routeName(Routes.SettingsPrivacy), component: SettingsPrivacy},
                    { path: routeName(Routes.SettingsIgnoreList), component: SettingsIgnoreList},
                ],
            },
        ],
    },
    {
        path:     routeName(Routes.SettingChangeSepa),
        children: [
            { path: '', component: SettingsChangeSepaHeader, outlet: 'header'},
            { path: '', component: SettingsChangeSepa },
        ],
    },
    {
        path: routeName(Routes.SettingsDebitAgain),
        children: [
            { path: '', component: SettingsDebitAgainHeader, outlet: 'header'},
            { path: '', component: SettingsDebitAgain },
        ]
    },
    {
        path: routeName(Routes.DeleteProfile),
        children: [
            { path: '', component: SettingsAccountDeleteHeader, outlet: 'header'},
            { path: '', component: SettingsAccountDelete },
        ]
    }
];


export const SettingRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(SettingRoutes);
