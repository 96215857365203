import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Switch } from '../../../../../shared/mat-design/switch/switch';

/**
 * Items der Seitennavigation
 */
@Component({
               selector:  'bk-side-nav-switch-item',
               template:  `
                              <div class="side-nav-item mdc-ripple-surface mdc-ripple-surface--primary" (click)="itemClick()">
                                  <div class="side-nav-item__glyph">
                                      <ng-content select="[glyph]"></ng-content>
                                  </div>

                                  <div class="side-nav-item__text">
                                      <div class="side-nav-item__text__name">
                                          <ng-content></ng-content>
                                      </div>
                                      <div class="side-nav-item__text__caption">
                                          <ng-content select="[caption]"></ng-content>
                                      </div>
                                      <div *ngIf="showLink" class="side-nav-item__link">
                                          <a (click)="linkClick.emit($event)">
                                              <ng-content select="[linkContent]"></ng-content>
                                          </a>
                                      </div>
                                  </div>

                                  <switch #switchElement [(value)]="state" [disabled]="disabled" class="side-nav-item__switch"></switch>
                              </div>
                          `,
               styles: [require('./side-nav-switch-item.scss')],
           })
export class SideNavSwitchItem {
    @ViewChild('switchElement', { static: true }) private switchElement: Switch;
    @Input() private icon: string = '';
    @Input() private disabled: boolean = false;
    @Input() private showLink: boolean = false;
    @Output() private stateChange = new EventEmitter();
    @Output() private linkClick = new EventEmitter();

    private _state: boolean;
    @Input()
    private get state(): boolean {
        return this._state;
    }

    private set state(value: boolean) {
        if (value === this._state) return;
        this._state = value;
        this.stateChange.emit(value);
    }

    private itemClick() {
        this.switchElement.writeValue(!this.state);
    }
}
