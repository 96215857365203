import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
} from '@angular/forms';
import {
    BillingAddress,
    SepaAccount,
} from 'BKModels';
import {
    BicValidator,
    IbanValidator,
    required,
} from 'BKShared/Validator';
import {
    formatIban,
    growShrink,
} from 'BKUtils';
import { TextField } from '../text-field/text-field';

@Component({
               selector:   'payment-debit',
               template:   `
                               <div class="payment-debit" [formGroup]="form">
                                   <div class="payment-debit__row payment-debit__row--name">
                                       <text-field class="left"
                                                   [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.FORENAME' | translate"
                                                   formControlName="forename"
                                                   [valid]="valid('forename')"
                                                   [errorText]="errorText('forename') | translate"
                                       ></text-field>
                                       <text-field formControlName="surname"
                                                   class="right"
                                                   [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.SURNAME' | translate"
                                                   [valid]="valid('surname')"
                                                   [errorText]="errorText('surname') | translate"
                                       ></text-field>
                                   </div>
                                   <div class="payment-debit__row">
                                       <text-field [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.ACCOUNT_HOLDER' | translate"
                                                   formControlName="account_holder"
                                                   [valid]="valid('account_holder')"
                                                   [errorText]="errorText('account_holder') | translate"
                                       ></text-field>
                                   </div>
                                   <div class="payment-debit__row">
                                       <text-field formControlName="iban"
                                                   #iban
                                                   [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.IBAN' | translate"
                                                   [valid]="valid('iban')"
                                                   (valueChange)="ibanChange($event)"
                                                   [errorText]="errorText('iban')"
                                       ></text-field>
                                   </div>
                                   <div class="payment-debit__row" *ngIf="bicShown">
                                       <text-field [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.BIC' | translate"
                                                   #bic
                                                   formControlName="bic"
                                                   [valid]="valid('bic')"
                                                   (valueChange)="bicChanged($event)"
                                                   [errorText]="errorText('bic')"
                                       ></text-field>
                                   </div>
                                   <div *ngIf="secondStep" [@growShrink]="false">
                                       <div class="payment-debit__row--subtitle">{{'PREMIUM.BOOKING.PAYMENT.DEBIT.BILLING_ADDRESS' | translate}}</div>
                                       <div class="payment-debit__row payment-debit__row--street">
                                           <text-field class="left"
                                                       [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.STREET' | translate"
                                                       formControlName="street"
                                                       [valid]="valid('street')"
                                                       [errorText]="errorText('street') | translate"
                                           ></text-field>
                                           <text-field class="right"
                                                       [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.HOUSE_NUMBER' | translate"
                                                       formControlName="house_number"
                                                       [valid]="valid('house_number')"
                                                       [errorText]="errorText('house_number') | translate"
                                           ></text-field>
                                       </div>
                                       <div class="payment-debit__row payment-debit__row--place">
                                           <text-field class="left"
                                                       [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.POSTAL_CODE' | translate"
                                                       formControlName="postal_code"
                                                       [valid]="valid('postal_code')"
                                                       [errorText]="errorText('postal_code') | translate"
                                           ></text-field>
                                           <text-field class="right"
                                                       [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.CITY' | translate"
                                                       formControlName='city'
                                                       [valid]="valid('city')"
                                                       [errorText]="errorText('city') | translate"
                                           ></text-field>
                                       </div>
                                       <div class="payment-debit__row">
                                           <text-field [placeholder]="'PREMIUM.BOOKING.PAYMENT.DEBIT.PHONE_NUMBER' | translate"
                                                       formControlName="phone_number"
                                                       [valid]="valid('phone_number')"
                                                       [errorText]="errorText('phone_number') | translate"
                                           ></text-field>
                                       </div>
                                   </div>
                               </div>
                           `,
               styles:  [require('./payment-debit.scss')],
               animations: [growShrink],
           })

export class PaymentDebit implements OnInit {
    @ViewChild('iban', { static: true }) private ibanInput: TextField;
    @ViewChild('bic', { static: true }) private bicInput: TextField;


    private billingAddress: BillingAddress = new BillingAddress();
    @Output() private billingAddressChange = new EventEmitter();
    @Input('billingAddress') private set _billingAddress(newValue) {
        this.setBillingAddress(newValue, !this.billingAddress.equal(newValue))
    }
    private setBillingAddress(newValue, emitEvent: boolean = true) {
        if (this.form) this.form.patchValue(newValue, { emitEvent });
        this.billingAddress = newValue;
        this.billingAddressChange.emit(newValue);
    }


    private sepaAccount: SepaAccount = new SepaAccount();
    @Output() private sepaAccountChange = new EventEmitter();

    @Input('sepaAccount') private set _sepaAccount(newValue) {
        this.setSepaAccount(newValue, !this.sepaAccount.equal(newValue));
    }
    private setSepaAccount(newValue, emitEvent: boolean = true) {
        if (this.form) this.form.patchValue(newValue, { emitEvent });
        this.sepaAccount = newValue;
        this.sepaAccountChange.emit(newValue);
    }

    public form: FormGroup;
    public secondStep: boolean = false;


    public constructor(private formBuilder: FormBuilder) {
    }


    public ngOnInit(): void {
        this.createForm();
    }

    public firstInputsValid(): boolean {
        return this.form.controls['forename'].valid && this.form.controls['surname'].valid && this.form.controls['account_holder'].valid && this.form.controls['iban'].valid && this.form.controls['bic'].valid;
    }

    private createForm() {
        const iban = new FormControl(this.sepaAccount.iban, [IbanValidator.validate]);

        this.form = this.formBuilder.group({
                                               'forename':       [this.billingAddress.forename, [required]],
                                               'surname':        [this.billingAddress.surname, [required]],
                                               'account_holder': [this.sepaAccount.account_holder],
                                               'iban':           iban,
                                               'bic':            [this.sepaAccount.bic, [BicValidator.requierd(iban), BicValidator.validate]],
                                               'street':         [this.billingAddress.street, [required]],
                                               'house_number':   [this.billingAddress.house_number, [required]],
                                               'postal_code':    [this.billingAddress.postal_code, [required]],
                                               'city':           [this.billingAddress.city, [required]],
                                               'phone_number':   [this.billingAddress.phone_number, [required]],
                                           });

        this.form.valueChanges.subscribe((value) => {
            value.iban = formatIban(value.iban);
            value.bic = this.formatBic(value.bic);
            this.setBillingAddress(BillingAddress.create(value), false);
            this.setSepaAccount(SepaAccount.create(value), false);
        });
    }

    public markFormPristine() {
        for (let controlKey in this.form.controls) {
            this.form.controls[controlKey].markAsPristine();
        }
    }

    public markFormDirty() {
        for (let controlKey in this.form.controls) {
            this.form.controls[controlKey].markAsDirty();
        }
    }

    private valid(controlName: string): boolean {
        return !this.form.controls[controlName].errors || this.form.controls[controlName].pristine;
    }

    private errorText(controlName: string): string {
        const errors = this.form.controls[controlName].errors;
        return errors ? Object.keys(errors)[0] : '';
    }

    private ibanChange(iban: string) {
        const formatedIban = formatIban(iban, '');
        if (iban !== formatedIban) {
            let createPos = this.ibanInput.caretPosition;
            if (formatedIban.charAt(createPos - 1) == ' ' && formatedIban.length - iban.length == 1) createPos = createPos + 1;
            this.form.patchValue({ iban: formatedIban });
            this.ibanInput.caretPosition = createPos;
        }
    }

    private bicChanged(bic: string) {
        const formatedBic = this.formatBic(bic);
        if (bic !== formatedBic) {
            const createPos = this.bicInput.caretPosition;
            this.form.patchValue({ bic: formatedBic });
            this.bicInput.caretPosition = createPos;
        }
    }

    private formatBic(bic: string) {
        return bic.toUpperCase().trim();
    }


    private get bicShown(): boolean {
        if (!this.form.value.bic.isEmpty()) return true;
        const iban = this.form.value.iban.toString().toUpperCase();
        return iban.length >= 2 && iban.indexOf('DE') === -1;
    }
}
