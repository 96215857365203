export enum GrowlType {
    Positive,
    Info,
    Warning,
    Negative
}

export class BkPush {
    public readonly expire: number;


    public constructor(
        public readonly title: string,
        public readonly text: string,
        public readonly link,
        public readonly picType: 'img' | 'icon',
        public readonly pic: string,
        public readonly badge: number = 0,
    ) {
        this.expire = Date.now() + 10000;
    }
}

export class Growl {
    public readonly expire: number;

    public readonly text: string;

    public readonly title: string;

    public readonly type: GrowlType;

    public constructor(
        type: GrowlType,
        title: string,
        text: string,
    ) {
        this.type = type;
        this.text = text;
        this.title = title;
        this.expire = Date.now() + 4000;
    }
}
