/**
 * Created by olifra on 10.05.2017.
 */
import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
} from '@angular/forms';
import {
    ApiResponseFrame,
    ResetState,
} from 'BKModels';
import { ApiService } from 'BKService';
import {
    PasswordValidator,
    required,
} from 'BKShared/Validator';
import { Logger } from 'BKUtils';

@Component({
               selector: 'password-recovery',
               template: `
        <form [formGroup]="resetPasswordForm">
            <form-input-wrapper>
                <h3 class="bk-reset-password__wrapper--title">{{'PASSWORD_RECOVERY.CHANGE_PASSWORD' | translate}}</h3>
            </form-input-wrapper>


            <form-input-wrapper>
                <div class="bk-reset-password__wrapper">
                    <p class="bk-reset-password__wrapper">
                        {{'PASSWORD_RECOVERY.PASSWORD_SHOULD_BE' | translate}}
                    </p>
                </div>
            </form-input-wrapper>

            <div class="bk-reset-password__wrapper">
                <form-input-wrapper>
                    <text-field id="password"
                                type="password"
                                [placeholder]="'PASSWORD_RECOVERY.PASSWORD.NEW' | translate"
                                formControlName="password"
                                [valid]="!invalid('password')"
                    ></text-field>
                </form-input-wrapper>
            </div>
            <div class="bk-reset-password__wrapper">
                <form-input-wrapper [control]="resetPasswordForm.controls.passwordRepetition ">

                    <text-field id="passwordRepetition"
                                type="password"
                                [placeholder]="'PASSWORD_RECOVERY.PASSWORD.REPETITION' | translate"
                                formControlName="passwordRepetition"
                                [valid]="!invalid('passwordRepetition')"
                    ></text-field>
                </form-input-wrapper>
            </div>
            <raised-button class="bk-reset-password__button"
                           [disabled]="!isFormValid()"
                           (click)="sendNewPassword()"
            >
                {{'PASSWORD_RECOVERY.SEND' | translate}}
            </raised-button>
            <form-input-wrapper></form-input-wrapper>
        </form>
    `,
               styles: [require('../container/password-recovery.scss')],
           })
export class PasswordRecoveryComponent {
    private resetPasswordForm: FormGroup;

    @Output() private setState: EventEmitter<any> = new EventEmitter<any>();

    @Input() private token: string = '';

    public constructor(
        private fb: FormBuilder,
        private api: ApiService,
    ) {
        this.createForm();
    }

    private createForm() {
        const password = new FormControl('', [PasswordValidator.validate]);
        this.resetPasswordForm = this.fb
                                     .group({
                                                password,
                                                passwordRepetition: [
                                                    '',
                                                    [required, PasswordValidator.repetition(password)],
                                                ],
                                            });
    }

    private invalid(controlName: string): boolean {
        if (!this.resetPasswordForm.controls.hasOwnProperty(controlName)) {
            throw new Error(`${controlName} is not a valid control`);
        }
        return (this.resetPasswordForm.controls[controlName].errors
            && !this.resetPasswordForm.controls[controlName].pristine);
    }

    private isFormValid(): boolean {
        const password = this.resetPasswordForm.controls.password.errors;
        const passwordRepetition = this.resetPasswordForm.controls.passwordRepetition.errors;
        return password === null && passwordRepetition === null;
    }

    private sendNewPassword() {
        if (this.resetPasswordForm.status === 'INVALID') {
            // eslint-disable-next-line no-console
            Logger.error('error');
        } else {
            const password = this.resetPasswordForm.getRawValue();
            this.api
                .password
                .changePasswordToken(password, this.token)
                .then((res: ApiResponseFrame<any>) => {
                    this.setState
                        .emit(ResetState.changeSuccessfull);
                })
                .catch((errorRes: any) => {
                    if (errorRes.data.formErrors.token) {
                        this.setState
                            .emit(ResetState.invalidLink);
                    }
                });
        }
    }
}
