import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    getValueAccessorProvider,
    SliderFormatter,
    ValueAccessorBase,
} from 'BKBaseClass';
import { SliderFormatterBase } from 'BKModels';
import * as noUiSlider from 'nouislider';

/*
Ia a Wrapper for the nouislider https://refreshless.com/nouislider/
 */

@Component({
               selector:  'range',
               styles: [require('./range.scss')],
               template:  `
                              <div [attr.disabled]="disabled ? true : undefined"
                                   [class.noUi-target--no-margin-left]="noMarginLeft"
                                   style="margin-top: 48px"
                              ></div>
                          `,

               providers: [
                   getValueAccessorProvider(Range),
               ],
           })
export class Range extends ValueAccessorBase<number | number[]> implements AfterContentInit, OnChanges, OnDestroy {
    @Input() private behaviour: string = 'tap-drag';

    @Input() private config: any = {};

    @Input() private connect: boolean[];

    @Output() private end: EventEmitter<any> = new EventEmitter(true);

    @Input() private formControl: FormControl;

    @Input() private format: SliderFormatterBase;

    private handles: any[];
    private innerChange: boolean = false;
    @Input() private keyboard: boolean;
    @Input() private limit: number;
    @Input() private margin: number = 0;
    @Input() private max: number;
    @Input() private min: number = 0;
    @Input() private minSelect: number = this.min;
    @Input() private ngModel: number | number[];
    private onChange: any = Function.prototype;
    @Input() private onKeydown: any;
    private onTouched: any = Function.prototype;
    @Input() private padding: number = 0;
    @Input() private pageSteps: number;
    @Input() private pips: any = null;
    @Output() private set: EventEmitter<any> = new EventEmitter(true);
    @Output() private slide: EventEmitter<any> = new EventEmitter(true);
    private slider: any;
    @Output() private start: EventEmitter<any> = new EventEmitter(true);
    @Input() private step: number = 1;
    @Input() private tooltips: boolean[];
    @Output() private update: EventEmitter<any> = new EventEmitter(true);
    @Input() private noMarginLeft: boolean = false;

    public constructor(private el: ElementRef) {
        super();
    }

    public ngAfterContentInit(): void {
        setTimeout(() => {
            const inputsConfig = {
                behaviour: this.config.behaviour || this.behaviour || 'tap-drag',
                connect:   this.config.connect || this.connect,
                format:    this.config.format || this.format || new SliderFormatter(),
                keyboard:  this.config.keyboard || this.keyboard,
                limit:     this.config.limit || this.limit,
                onKeydown: this.config.onKeydown || this.onKeydown,
                pageSteps: this.config.pageSteps || this.pageSteps,
                padding:   this.config.padding || this.padding,
                margin:    this.config.margin || this.margin,
                range:     this.config.range || {
                    min: this.min,
                    max: this.max,
                },
                start:     this.getStartValue(),
                step:      this.config.step || this.step,
                tooltips:  this.config.tooltips || this.tooltips,
                pips:      this.config.pips || this.pips,
            };


            this.slider = noUiSlider.create(
                this.el.nativeElement.querySelector('div'),
                Object.assign(this.config, inputsConfig),
            );
            this.handles = [].slice.call(this.el.nativeElement.querySelectorAll('.noUi-handle'));

            this.addKeyboardEvents();
            this.addSliderEvents();
        });
    }

    public ngOnChanges(changes: any) {
        if (this.slider && (changes.min || changes.max || changes.step)) {
            setTimeout(() => {
                this.slider
                    .updateOptions({
                                       range: {
                                           min: this.min,
                                           max: this.max,
                                       },
                                       step:  this.step,
                                   });
            });
        }
    }

    public ngOnDestroy(): void {
        Object.values(this.handles)
            .forEach(handle => {
                handle.removeEventListener('click', handle.focus);
                if (this.config.onKeydown === undefined) {
                    handle.removeEventListener('keydown', this.defaultKeyHandler);
                } else {
                    handle.removeEventListener('keydown', this.config.onKeydown);
                }
            })
    }

    public writeValue(value: any): void {
        if (this.slider && !this.innerChange) this.slider.set(value);

        super.writeValue(value);
        this.innerChange = false;
    }

    private addKeyboardEvents() {
        if (this.config.keyboard) {
            if (this.config.pageSteps === undefined) {
                this.config.pageSteps = 10;
            }

            Object.values(this.handles)
                  .forEach(handle => {
                      handle.setAttribute('tabindex', 0);
                      handle.addEventListener('click', handle.focus);
                      if (this.config.onKeydown === undefined) {
                          handle.addEventListener('keydown', this.defaultKeyHandler);
                      } else {
                          handle.addEventListener('keydown', this.config.onKeydown);
                      }
                  });
        }
    }


    private addSliderEvents() {
        this.slider.on('set', (values: string[], handle: number, unencoded: number[]) => {
            this.eventHandler(this.set, values, handle, unencoded);
        });

        this.slider.on('update', (values: string[], handle: number, unencoded: number[]) => {
            this.innerChange = true;
            const val = this.toValues(values);
            if (this.minSelect && val < this.minSelect) {
                this.slider.set(this.minSelect);
            } else {
                this.update.emit(val);
            }
        });

        this.slider.on('change', (values: string[], handle: number, unencoded: number[]) => {
            this.value = this.toValues(values);
        });

        this.slider.on('slide', (values: string[], handle: number, unencoded: number[]) => {
            this.eventHandler(this.slide, values, handle, unencoded);
        });

        this.slider.on('start', (values: string[], handle: number, unencoded: number[]) => {
            this.start.emit(this.toValues(values));
        });

        this.slider.on('end', (values: string[], handle: number, unencoded: number[]) => {
            this.end.emit(this.toValues(values));
        });
    }

    private defaultKeyHandler(e: KeyboardEvent) {
        const stepSize: any[] = this.slider.steps();
        const index = parseInt((e.target as HTMLElement).getAttribute('data-handle'));
        let sign = 1;
        let multiplier: number = 1;
        let step = 0;
        let delta = 0;

        switch (e.key) {
            case 'PageDown':
                multiplier = this.config.pageSteps;
            // eslint-disable-next-line no-fallthrough
            case 'ArrowDown':
            case 'ArrowLeft':
                sign = -1;
                step = stepSize[index][0];
                e.preventDefault();
                break;

            case 'PageUp':
                multiplier = this.config.pageSteps;
            // eslint-disable-next-line no-fallthrough
            case 'ArrowUp':
            case 'ArrowRight':
                step = stepSize[index][1];
                e.preventDefault();
                break;
            default:
                break;
        }

        delta = sign * multiplier * step;
        let newValue: number | number[];

        if (Array.isArray(this.value)) {
            newValue = [].concat(this.value);
            newValue[index] += delta;
        } else {
            newValue = this.value + delta;
        }

        this.innerChange = true;

        this.slider.set(newValue);
    }

    private eventHandler(emitter: EventEmitter<any>, values: string[], handle: number, unencoded: number[]) {
        const v = this.toValues(values);
        let emitEvents = false;
        if (this.value === undefined) {
            this.value = v;
            return;
        }
        if (Array.isArray(v) && this.value[handle] !== v[handle]) {
            emitEvents = true;
        }
        if (!Array.isArray(v) && this.value !== v) {
            emitEvents = true;
        }
        if (emitEvents) {
            emitter.emit(v);
            this.onChange(v);
        }
        if (Array.isArray(v)) {
            this.value[handle] = v[handle];
        } else {
            this.value = v;
        }
    }

    private getStartValue() {
        if (this.formControl) {
            return this.formControl.value;
        }
        if (this.value) {
            return this.value;
        }
        return this.ngModel;
    }

    private toValues(values: string[]): any | any[] {
        const v = values.map(this.config.format.from);
        return (v.length === 1 ? v[0] : v);
    }
}
