import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    ApiService,
    CurrentUserService,
} from 'BKService';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    NavigationService,
    Routes,
} from '../../service/navigation.service';
import { SessionService } from '../../service/session.service';


enum VerificationState {
    Processing= 'processing',
    Success = 'success',
    Failed = 'failed',
}


@Component({
               selector: 'verify-change-email',
               template: `
                             <bk-header-out>
                             </bk-header-out>
                             <div class="background">
                                 <card-title class="card">
                                     <div class="content">
                                         <loading-spinner *ngIf="isProcessing; else resultBlock"></loading-spinner>
                                         <ng-template #resultBlock>
                                             <div class="title">{{ title|translate }}</div>
                                             <div class="subtitle">{{ subtitle|translate }}</div>
                                             <div [class]="image" [attr.data-alt]="imageAlt|translate"></div>
                                             <div class="text">{{ text|translate:{'email': email} }}</div>
                                             <raised-button class="button" (click)="buttonAction()">{{ buttonText|translate }}</raised-button>
                                             <premium-support [showEmail]="false"></premium-support>
                                         </ng-template>
                                     </div>
                                 </card-title>
                                 <premium-landing-page-footer class="footer"></premium-landing-page-footer>
                             </div>
                         `,
                styles: [require('./verify-email-change.component.scss')]
           })
export class VerifyEmailChangeComponent {
    private unsubscribe$ = new Subject();
    private token: string;
    private verifyState: VerificationState = VerificationState.Processing;

    private assetKeys = {
        title: {
            [VerificationState.Failed]: 'VERIFY_EMAIL.TITLE.FAILED',
            [VerificationState.Success]: 'VERIFY_EMAIL.TITLE.SUCCESS',
        },
        subtitle: {
            [VerificationState.Failed]: 'VERIFY_EMAIL.SUBTITLE.FAILED',
            [VerificationState.Success]: 'VERIFY_EMAIL.SUBTITLE.SUCCESS',
        },
        text: {
            [VerificationState.Failed]: 'VERIFY_EMAIL.TEXT.FAILED',
            [VerificationState.Success]: 'VERIFY_EMAIL.TEXT.SUCCESS',
        },
        buttonText: {
            [VerificationState.Failed]: 'VERIFY_EMAIL.BUTTON.FAILED',
            [VerificationState.Success]: 'VERIFY_EMAIL.BUTTON.SUCCESS',
        },
        image: {
            [VerificationState.Failed]: 'image-failed',
            [VerificationState.Success]: 'image-success',
        },
        imageAlt: {
            [VerificationState.Failed]: 'VERIFY_EMAIL.IMAGE_ALT.FAILED',
            [VerificationState.Success]: 'VERIFY_EMAIL.IMAGE_ALT.SUCCESS',
        },
    };

    private get isProcessing(): boolean {
        return this.verifyState == VerificationState.Processing;
    }

    private get title(): string {
        return this.assetKeys.title[this.verifyState];
    }

    private get subtitle(): string {
        return this.assetKeys.subtitle[this.verifyState];
    }

    private get image(): string {
        return this.assetKeys.image[this.verifyState];
    }

    private get imageAlt(): string {
        return this.assetKeys.imageAlt[this.verifyState];
    }

    private get text(): string {
        return this.assetKeys.text[this.verifyState];
    }

    private get buttonText(): string {
        return this.assetKeys.buttonText[this.verifyState];
    }

    private email: string = "";

    public constructor(private activeRoute: ActivatedRoute,
                       private navigationService: NavigationService,
                       private apiService: ApiService,
                       private sessionService: SessionService,
                       private currentUserService: CurrentUserService,
    ) {
    }

    public ngOnInit(): void {
        this.activeRoute.params
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(params => {
                this.token = decodeURIComponent(params.token);
                this.apiService.verifyEmail(this.token).then((response)=>{
                    if (!response.error && response.data.state){
                        this.email = response.data.email;
                        this.verifyState = VerificationState.Success;
                    }else{
                        this.verifyState = VerificationState.Failed;
                    }

                    if(this.sessionService.isLoggedIn()) {
                        this.currentUserService.loadCurrentUser();
                    }
                }).catch(()=>{
                    this.verifyState =  VerificationState.Failed;
                })
            });
    }

    private buttonAction() {
        switch (this.verifyState) {
            case  VerificationState.Success:
                this.navigationService.navigateTo(Routes.MyProfile);
                break;
            case VerificationState.Failed:
                this.navigationService.navigateTo(Routes.MyProfile);
        }
    }
}
