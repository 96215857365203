import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'premium-table-header',
               template: `
                             <div class="premium-table-header">
                                 <div class="premium-table-header__function">{{'PREMIUM.COLOUM_BADGE.FUNCTION' | translate}}</div>
                                 <premium-column-badge class="premium-table-header__check" [active]="!isPremium" [premiumVariant]="false"></premium-column-badge>
                                 <premium-column-badge class="premium-table-header__check" [active]="isPremium" [premiumVariant]="true"></premium-column-badge>
                             </div>`,
               styles: [require('./premium-table-header.scss')],
           })

export class PremiumTableHeader {
    @Input('premium') private isPremium: boolean = false;
}
