import {
    Component,
    Input,
} from '@angular/core';

@Component({
               selector: 'list-checked',
               template: `
        <div class="list-checked">
            <ul class="list-checked__list">
                <li class="list-checked__list__item"
                    *ngFor="let item of list"
                >
                    <div class="list-checked__list__item__icon">
                        <icon icon="check"></icon>
                    </div>
                    {{item}}
                </li>
            </ul>
        </div>`,
               styles: [require('./list-checked.scss')],
           })

export class ListChecked {
    @Input() private list: string[] = [];
}
