import { Injectable } from '@angular/core';
import { ApiResponseFrame } from 'BKModels';
import { Offer } from '../models/offer';
import { ApiService } from './api';

@Injectable({ providedIn: 'root' })
export class OfferService {
    public constructor(private apiService: ApiService) {
    }

    public loadOffer(): Promise<Offer[]> {
        return this.apiService
                   .offer
                   .offers();
    }

    public triggerCanceledPurchaseOffer(): Promise<ApiResponseFrame<any>> {
        return this.apiService.offer.triggerCanceledPurchaseOffer();
    }
}