import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    CurrentUser,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import { getScrollContainer } from 'BKUtils';
import * as html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentType } from '../../../../../models/product';
import { Tariff } from '../../../../../models/tariff';
import { ListService } from '../../../../../service/list.service';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { PaymentService } from '../../../../../service/payment.service';


@Component({
               selector:  'premium-purchase-after-sale',
               template:  `
                              <premium-booking-page-header></premium-booking-page-header>
                              <div class="premium-purchase-after-sale">
                                  <div class="premium-purchase-after-sale__welcoming">
                                      <div class="premium-purchase-after-sale__title">{{titleKey | translate:currentUser}}</div>
                                      <div class="premium-purchase-after-sale__text" [innerHTML]="textKey | translate:currentUserProfileInfo | markdown"></div>

                                      <premium-purchase-after-sale-delaybox *ngIf="!currentUser.isPremium()"></premium-purchase-after-sale-delaybox>

                                      <div class="premium-purchase-after-sale__lists">
                                          <div class="premium-purchase-after-sale__lists__title">{{listTitleKey | translate}}</div>
                                          <div class="premium-purchase-after-sale__lists-container"
                                               [class.premium-purchase-after-sale__lists-container__links]="!currentUser.isPremium()"
                                          >
                                              <ng-container *ngIf="currentUser.isPremium(); else elseBox">
                                                  <premium-purchase-after-sale-list [title]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.PROFILE_VISITOR.TITLE' | translate"
                                                                                    [emptyText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.PROFILE_VISITOR.EMPTY' | translate"
                                                                                    [buttonText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.PROFILE_VISITOR.BUTTON' | translate"
                                                                                    [route]="Routes.ProfileVisitors"
                                                                                    [list]="profileVisitors"
                                                                                    trackingLabel="ProfilbesucherEntdecken"
                                                  ></premium-purchase-after-sale-list>
                                                  <premium-purchase-after-sale-list [title]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.MATCH-PARTNER.TITLE' | translate"
                                                                                    [emptyText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.MATCH-PARTNER.EMPTY' | translate "
                                                                                    [buttonText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.MATCH-PARTNER.BUTTON' | translate"
                                                                                    [route]="Routes.MatchingPartner"
                                                                                    [emptyRoute]="Routes.Matching"
                                                                                    [list]="matchPartners"
                                                                                    trackingLabel="MatchpartnerEntdecken"
                                                  ></premium-purchase-after-sale-list>
                                                  <premium-purchase-after-sale-list [title]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.SELECTED_USER.TITLE' | translate"
                                                                                    [emptyText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.SELECTED_USER.EMPTY' | translate"
                                                                                    [buttonText]="'PREMIUM.PURCHASE.SUCCESSFUL.LIST.SELECTED_USER.BUTTON' | translate"
                                                                                    [route]="Routes.Startpage"
                                                                                    [list]="selectedUsers"
                                                                                    trackingLabel="PassendeSinglesEntdecken"
                                                  ></premium-purchase-after-sale-list>
                                              </ng-container>

                                              <ng-template #elseBox>

                                                  <div class="premium-purchase-after-sale__lists-container__links">
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          <a [routerLink]="navigationService.getRoute(Routes.ProfileVisitors)">
                                                              {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.PROFILE-VISITORS' | translate}}
                                                          </a>
                                                      </div>
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          <a [routerLink]="navigationService.getRoute(Routes.MatchingPartner)">
                                                              {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.MATCHPARTNER' | translate}}
                                                          </a>
                                                      </div>
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          <a>{{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.SELECTED-SINGLES' | translate}} </a>
                                                      </div>
                                                  </div>
                                                  <div class="premium-purchase-after-sale__lists-container__links">
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.ALL-PROFILE-IMAGES' | translate}}
                                                      </div>
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.EXTENDED-SEARCH' | translate}}
                                                      </div>
                                                      <div class="premium-purchase-after-sale__lists__link">
                                                          <icon class="premium-purchase-after-sale__lists__link__icon" icon="check"></icon>
                                                          {{'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LINK.NO-ADS' | translate}}
                                                      </div>
                                                  </div>
                                              </ng-template>

                                          </div>
                                      </div>
                                      <div class="premium-purchase-after-sale__lists-footer">
                                          <div class="premium-purchase-after-sale__lists-footer__separator">
                                              <div class="premium-purchase-after-sale__lists-footer__separator__line"></div>
                                              <div class="premium-purchase-after-sale__lists-footer__separator__text">
                                                  {{'GLOBAL.OR' | translate}}
                                              </div>
                                              <div class="premium-purchase-after-sale__lists-footer__separator__line"></div>
                                          </div>
                                          <raised-button (click)="toStartpage()">{{'PREMIUM.PURCHASE.AFTER-SALE.LIST.BUTTON' | translate}}</raised-button>
                                      </div>
                                  </div>
                                  <premium-purchase-after-sale-summary *ngIf="tariff" [currentUser]="currentUser"
                                                                       id="summary"
                                                                       [product]="tariff"
                                                                       [paymentType]="paymentType"
                                                                       [startDate]="startDate"
                                                                       [purchaseDate]="purchaseDate"
                                                                       [expensiveProduct]="expensiveProduct"
                                                                       (download)="download()"
                                  ></premium-purchase-after-sale-summary>

                                  <div class="premium-purchase-after-sale__footer">
                                      <footer-links></footer-links>
                                  </div>
                              </div>
                          `,
               styles: [require('./premium-purchase-after-sale.scss')],
           })
export class PremiumPurchaseAfterSale implements OnInit {
    private unsubscribe$ = new Subject();

    private currentUser = new CurrentUser();
    private currentUserProfileInfo = new ProfileInfo();

    private profileVisitors: UserBaseView[] = [];
    private matchPartners: UserBaseView[] = [];
    private selectedUsers: UserBaseView[] = [];
    private tariff: Tariff = null;
    private startDate: Date = null;
    private purchaseDate: Date = null;
    private paymentType: PaymentType = null;
    private expensiveProduct = null;

    private Routes = Routes;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private matchingService: MatchingService,
        private listService: ListService,
        private currentUserService: CurrentUserService,
        private translation: TranslateService,
        private trackingService: TrackingService,
    ) {
    }

    public ngOnInit(): void {
        this.startDate = new Date(this.activatedRoute.snapshot.params[RouteParameter.StartDate].toInt());
        this.purchaseDate = new Date(this.activatedRoute.snapshot.params[RouteParameter.PurchaseDate].toInt());
        this.paymentType = this.activatedRoute.snapshot.params[RouteParameter.PaymentType];

        const tariffCall = this.paymentService.tariff(this.activatedRoute.snapshot.params[RouteParameter.TariffId]);
        const expensiveProductCall = this.paymentService.mostExpensivePremiumProduct;
        Promise.all([tariffCall, expensiveProductCall]).then(([tariff, expensiveProduct]) => {
            this.expensiveProduct = expensiveProduct;
            this.tariff = tariff;
            if (tariff.pricePerDay > expensiveProduct.pricePerDay) {
                this.expensiveProduct = tariff;
            }
        }).catch(err => this.navigationService.navigateTo(Routes.PremiumLandingPage));

        this.currentUserService.loadCurrentUser();
        this.currentUserService
            .currentUserObservable
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: CurrentUser) => {
                this.currentUser = res;
            });

        this.currentUserService
            .currentUserProfileInfo
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: ProfileInfo) => {
                this.currentUserProfileInfo = res;
            });

        this.listService.profileVisitors(false, 6)
            .then((value) => {
                this.profileVisitors = value;
            });
        this.listService.selectedUser(6, 0, true)
            .then((value) => {
                this.selectedUsers = value.result;
            });

        this.matchingService.matchPartner(6)
            .then((value) => {
                this.matchPartners = value;
            });

    }

    private get titleKey() {
        return this.currentUser.isPremium() ? 'PREMIUM.PURCHASE.SUCCESSFUL.TITLE' : 'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.TITLE';
    }

    private get textKey() {
        return this.currentUser.isPremium() ? 'PREMIUM.PURCHASE.SUCCESSFUL.SUBTITLE' : 'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.SUBTITLE';
    }

    private get listTitleKey() {
        return this.currentUser.isPremium() ? 'PREMIUM.PURCHASE.SUCCESSFUL.LIST.TITLE' : 'PREMIUM.PURCHASE.AFTER-SALE.DELAYED.LIST.TITLE';
    }

    private toStartpage() {
        this.trackingService.hit('Premium', 'AfterSales', 'ZurueckStartseite');
        this.navigationService.navigateTo(Routes.Startpage);
    }

    private download() {
        this.trackingService.hit('Premium', 'AfterSales', 'PdfRunterladen');

        const element = document.getElementById('summary').firstElementChild as HTMLElement;
        const date = moment(this.purchaseDate).format('DD.MM.YYYY');
        const name = this.translation.instant('PREMIUM.PURCHASE.AFTER-SALE.BILL-TITLE', { date });

        (html2canvas as any)(element, {
            scrollY: getScrollContainer().scrollTop * -1,
        }).then(canvas => {
            let imgHeight = 297; // DinA4 width in mm
            let imgWidth = canvas.width * imgHeight / canvas.height;

            if (imgWidth > 210) {
                imgWidth = 210; // DinA4 width in mm;
                imgHeight = canvas.height * imgWidth / canvas.width;
            }
            const contentDataURL = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save(`${name}.pdf`);
        });
    }
}



