import { Component } from '@angular/core';

@Component({
               selector:  'deko-star',
               template:  `
                              <div class="star">
                                  <div class="star-shape">
                                      <div class="star-shape inner-star">
                                          <div class="inner-star__text">
                                              <ng-content></ng-content>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          `,
                styles: [require('./star.scss')]
            })
export class Star{

}