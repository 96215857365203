/**
 * Created by niczou on 27.09.2017.
 */
import {
    Directive,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

@Directive({ selector: '[swipe]'})
export class Swipe implements OnInit, OnDestroy {
    @Output() private leftSwipe: EventEmitter<any> = new EventEmitter();

    @Output() private rightSwipe: EventEmitter<any> = new EventEmitter();

    private startPosX: number;

    public constructor(private eleRef: ElementRef) {
    }

    public ngOnInit(): void {
        this.eleRef.nativeElement.addEventListener('touchstart', this.touchstart)
        this.eleRef.nativeElement.addEventListener('touchend', this.touchend);
    }

    public ngOnDestroy(): void {
        this.eleRef.nativeElement.removeEventListener('touchstart', this.touchstart)
        this.eleRef.nativeElement.removeEventListener('touchend', this.touchend)
    }

    private touchend = ($event) => {
        const endPosX = $event.changedTouches[0].clientX;
        const startEndDifference = this.startPosX - endPosX;
        if (startEndDifference < -130) {
            this.leftSwipe.emit();
        } else if (startEndDifference > 130) {
            this.rightSwipe.emit();
        }
    }

    private touchstart = ($event) => {
         this.startPosX = $event.targetTouches[0].clientX;
    }
}
