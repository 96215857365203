import {
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import * as deepEqual from 'deep-equal';

export enum MessagesSettings {
    No = 'NEVER',
    Everytime = 'ALWAYS',
    Sometimes = 'RESTRICTED'
}

export enum MatchSettings {
    No = 'NEVER',
    Everytime = 'ALWAYS',
    Sometimes = 'RESTRICTED'
}

export enum ProfileVisitorSettings {
    No = 'NEVER',
    Everytime = 'ALWAYS',
    Sometimes = 'RESTRICTED'
}

export enum WeeklyActivitySummarySettings {
    No = 'NEVER',
    Everytime = 'ALWAYS'
}

export enum NewsletterSettings {
    Magazin = 'magazin',
    Travel = 'reise',
    Event = 'event',
    Offer = 'angebot',
    Adds = 'werbung'
}


export class EmailNotification {
    public adds: boolean = false;
    public magazin: boolean = false;
    public nearBySingles: boolean = false;
    public newContact: boolean = false;
    public newMatch: boolean = false;
    public newMessages: MessagesSettings = MessagesSettings.No;
    public newOpenMatch: MatchSettings = MatchSettings.No;
    public profileVisitor: ProfileVisitorSettings = ProfileVisitorSettings.No;
    public weeklyActivitySummary: WeeklyActivitySummarySettings = WeeklyActivitySummarySettings.No;
    public offer: boolean = false;
    public singleEvents: boolean = false;


    public static create(data) {
        const res = new EmailNotification();
        if (data !== null && data !== undefined) {
            res.adds = data.adds;
            res.magazin = data.magazin;
            res.nearBySingles = data.nearBySingles;
            res.newContact = data.newContact;
            res.newMatch = data.newMatch;
            res.newMessages = data.newMessages;
            res.newOpenMatch = data.newOpenMatch;
            res.profileVisitor = data.profileVisitor;
            res.weeklyActivitySummary = data.weeklyActivitySummary;

            res.offer = data.offer;
            res.singleEvents = data.singleEvents;
        }
        return res;
    }

}


export class OnlineNotification {
    public favoriteLogin: boolean = false;
    public newMessages: boolean = false;
    public profileVisitor: boolean = false;

    public static create(data) {
        const res = new OnlineNotification();
        if (data !== null && data !== undefined) {
            res.favoriteLogin = data.favoriteLogin;
            res.newMessages = data.newMessage;
            res.profileVisitor = data.profileVisitor;
        }
        return res;
    }
}


export class SettingsApiBody {
    public match: boolean = true;

    public nearUser: boolean = false;

    public newMessage: MessagesSettings = MessagesSettings.No;

    public newsletterTypes: NewsletterSettings[] = [];

    public openMatch: MatchSettings = MatchSettings.No;

    public profileVisitor: ProfileVisitorSettings = ProfileVisitorSettings.No;

    public weeklyActivitySummary: WeeklyActivitySummarySettings = WeeklyActivitySummarySettings.No;

    public privateProfile: boolean = true;

    public static create(data: SettingsApiBody) {
        const res = new SettingsApiBody();
        res.nearUser = !!data.nearUser;
        res.privateProfile = !!data.privateProfile;
        res.newMessage = data.newMessage;
        res.match = data.match;
        res.profileVisitor = data.profileVisitor;
        res.weeklyActivitySummary = data.weeklyActivitySummary;
        res.newsletterTypes = data.newsletterTypes || [];
        res.openMatch = data.openMatch;

        return res;
    }
}

export class PushSettings {
    public frequency: string = '0';

    public loginFavorite: boolean = true;

    public match: boolean = true;

    public message: boolean = true;

    public openMatch: boolean = true;

    public profileVisitor: boolean = true;

    public static create(data): PushSettings {
        const res = new PushSettings();
        if (data !== null && data !== undefined) {
            res.loginFavorite = data.loginFavorite;
            res.match = data.match;
            res.message = data.message;
            res.openMatch = data.openMatch;
            res.profileVisitor = data.profileVisitor;
        }
        return res;
    }
}

export class PushUnlock {
    public  loginFavorite: boolean = false;

    public static create(data): PushUnlock {
        const res = new PushUnlock();
        if (data !== null && data !== undefined) {
            res.loginFavorite = data.loginFavorite;
        }
        return res;
    }
}

export class SettingsApiBodyPush {
    public pushSettings: PushSettings = new PushSettings();
    public pushUnlock: PushUnlock =  new PushUnlock();

    public static create(data: SettingsApiBodyPush) {
        const res = new SettingsApiBodyPush();

        res.pushSettings = data.pushSettings;
        res.pushUnlock = data.pushUnlock;

        return res;
    }
}

export class Settings {
    public anonymous: boolean = false;

    public emailNotification: EmailNotification = new EmailNotification();

    public offline: boolean = false;

    public pushSettings: PushSettings = new PushSettings();

    public publicProfile: boolean = false;

    public addPushSettings(pushSettings: SettingsApiBodyPush) {
        this.pushSettings.profileVisitor = pushSettings.pushSettings.profileVisitor;
        this.pushSettings.loginFavorite = pushSettings.pushSettings.loginFavorite;
        this.pushSettings.match = pushSettings.pushSettings.match;
        this.pushSettings.message = pushSettings.pushSettings.message;
        this.pushSettings.openMatch = pushSettings.pushSettings.openMatch;
        this.pushSettings.frequency = pushSettings.pushSettings.frequency;
    }

    public addSettings(data: SettingsApiBody) {
        this.publicProfile = !data.privateProfile;
        this.emailNotification.nearBySingles = !!data.nearUser;
        this.emailNotification.singleEvents = data.newsletterTypes.includes(NewsletterSettings.Event);
        this.emailNotification.magazin = data.newsletterTypes.includes(NewsletterSettings.Magazin);
        this.emailNotification.offer = data.newsletterTypes.includes(NewsletterSettings.Offer);
        this.emailNotification.adds = data.newsletterTypes.includes(NewsletterSettings.Adds);

        this.emailNotification.newMessages = data.newMessage;
        this.emailNotification.newOpenMatch = data.openMatch;
        this.emailNotification.profileVisitor = data.profileVisitor;
        this.emailNotification.weeklyActivitySummary = data.weeklyActivitySummary;
    }

    public createApiBody(): SettingsApiBody {
        const res = new SettingsApiBody();

        res.nearUser = this.emailNotification.nearBySingles;
        res.privateProfile = !this.publicProfile;
        res.newsletterTypes = [];

        if (this.emailNotification.adds) res.newsletterTypes.push(NewsletterSettings.Adds);
        if (this.emailNotification.singleEvents) res.newsletterTypes.push(NewsletterSettings.Event);
        if (this.emailNotification.magazin) res.newsletterTypes.push(NewsletterSettings.Magazin);
        if (this.emailNotification.offer) res.newsletterTypes.push(NewsletterSettings.Offer);


        res.newMessage = this.emailNotification.newMessages;
        res.openMatch = this.emailNotification.newOpenMatch;
        res.profileVisitor = this.emailNotification.profileVisitor;
        res.weeklyActivitySummary = this.emailNotification.weeklyActivitySummary;
        res.match = false;

        return res;
    }

    public static create(data?: Settings) {
        const res = new Settings();
        if (data !== null && data !== undefined) {
            res.anonymous = data.anonymous;
            res.emailNotification = EmailNotification.create(data.emailNotification);
            res.offline = data.offline;
            res.pushSettings = PushSettings.create(data.pushSettings);
            res.publicProfile = data.publicProfile;
        }
        return res;
    }


    public equal(other: Settings): boolean {
        return deepEqual(this, other);
    }

}


export class SettingsChangeBase<T> {
    @Output() protected settingsChange = new EventEmitter<T>();
    @Output() protected save = new EventEmitter();

    protected _settings: T;

    @Input()
    protected get settings(): T {
        return this._settings as T;
    }

    protected set settings(val: any) {
        this._settings = val;
        this.settingsChange.emit(val);
    }
}
