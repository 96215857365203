/*
 * Created by olifra on 20.09.2017.
 */

import {
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

/**
 * Baseclass for Components that need a TwoWayBinding for a Open Property
 *
 */
export class BkOpen {
    /**
     * Change Event for open
     * Is Needed for a Working two way binding
     *
     {EventEmitter<any>}
     */
    @Output() protected openChange = new EventEmitter();

    /**
     * flag for showing if open or not
     {boolean}
     */
    protected _open: boolean;

    /**
     * Input for the open
     {boolean}
     */
    @Input()
    protected get open() {
        return this._open;
    }

    protected set open(val: boolean) {
        this._open = val;
        this.openChange.emit(val);
    }

    protected toggleOpen() {
        this.open = !this.open;
    }
}
