/**
 * Created by denjas on 07.04.2017.
 */
import { AbstractControl } from '@angular/forms';
import { isEmpty } from 'BKShared/Validator';

/**
 * Validator for the Geolocation
 */
export class GeodataValidator {
    /**
     * Validate function
     {AbstractControl} control
     {KeyValue}
     */
    public static validate(control: AbstractControl): KeyValue {
        const value = control.value.value ? control.value.value : control.value;
        if (isEmpty(value)) {
            return { 'GEODATA.VALIDATOR.REQUIRED': true };
        }

        const length: number = value ? value.length : 0;

        if (length < 4 || length > 6) {
            return {
                'GEODATA.VALIDATOR.POSTAL_CODE': true,
            };
        }
        return null;
    }
}
