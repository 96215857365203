import { ModuleWithProviders } from '@angular/core';
import {
    RouterModule,
    Routes as NgRoutes,
} from '@angular/router';
import {
    routeName,
    Routes,
} from '../../../service/navigation.service';
import { ProfileVisitorHeader } from './profile-visitor-header/profile-visitor-header';
import { ProfileVisitorsMyVisits } from './profile-visitors-my-visits/profile-visitors-my-visits.component';
import { ProfileVisitorsOthers } from './profile-visitors-others/profile-visitors-others';
import { ProfileVisitors } from './profile-visitors/profile-visitors';

const profileVisitorsRoutes: NgRoutes = [
    {
        path:     '',
        children: [
            {
                path: '', component: ProfileVisitors, children: [{
                    path:      routeName(Routes.ProfileVisitorsOtherVisits),
                    component: ProfileVisitorsOthers,
                }, {
                    path:      routeName(Routes.ProfileVisitorsMyVisits),
                    component: ProfileVisitorsMyVisits,
                },
                ],
            },
            { path: '', component: ProfileVisitorHeader, outlet: 'header' },
        ],

    },
];

export const ProfileVisitorsRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(profileVisitorsRoutes);
