import {
    Pipe,
    PipeTransform,
} from '@angular/core';

import {
    MarkdownService,
    MarkedRenderer,
} from 'ngx-markdown';

@Pipe({ name: 'inlineMarkdown' })
export class InlineMarkdownPipe implements PipeTransform {
    private renderer: MarkedRenderer;
    private inlineRenderer: MarkedRenderer;

    constructor(private markdownService: MarkdownService) {
        this.renderer = this.markdownService.renderer;
        let dummy = (value: any = null) => (value || '');
        this.inlineRenderer = new MarkedRenderer();
        this.inlineRenderer.code = dummy;
        this.inlineRenderer.blockquote = dummy;
        this.inlineRenderer.html = dummy;
        this.inlineRenderer.heading = dummy;
        this.inlineRenderer.hr = dummy;
        this.inlineRenderer.list = dummy;
        this.inlineRenderer.listitem = dummy;
        this.inlineRenderer.checkbox = dummy;
        this.inlineRenderer.table = dummy;
        this.inlineRenderer.tablerow = dummy;
        this.inlineRenderer.tablecell = dummy;
        this.inlineRenderer.paragraph = dummy;
    }

    public transform(value: string): string {
        this.markdownService.renderer = this.inlineRenderer;
        value = this.markdownService.compile(value);
        this.markdownService.renderer = this.renderer;

        return value;
    }

}