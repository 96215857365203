import { Component } from '@angular/core';

@Component({
               selector:  'loading-indicator',
               template:  `
                              <div class="loading-indicator"></div>
                          `,
               styles: [require('./loading-indicator.scss')],
           })
export class LoadingIndicator {
}
