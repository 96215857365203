import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
    ProfileInfo,
} from 'BKModels';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {
    PremiumDetails,
    SubscriptionType,
} from '../../../../models/premium-details';
import {
    NavigationService,
    Routes,
} from '../../../../service/navigation.service';
import { PremiumService } from '../../../../service/premium.service';
import { StayOnBetaService } from '../../../../service/stay-on-beta.service';
import { DialogBox } from '../../../../shared/mat-design/dialog/dialog-box';

@Component({
               selector:  'settings-account',
               template:  `
                              <card-title>
                                  <div class="account__title">
                                      <div class="account__title__text">{{'SETTINGS.ACCOUNT' | translate }}</div>
                                      <div class="account__title__id">{{'SETTINGS.ACCOUNT.MEMBER_ID' | translate:{ id: currentUser.id.toString() } }}</div>
                                  </div>
                                  <div class="account__status">{{'SETTINGS.ACCOUNT.STATUS.TITLE' | translate}} {{status | translate}}</div>

                                  <div class="account__premium" *ngIf="premiumDetails">
                                      <div class="account__premium__title account__headline" *ngIf="premiumDetails.active.expireAt">{{headline | translate}}</div>

                                      <ng-container *ngIf="hasSubscription;else expire">
                                          <settings-account-box class="account__box" *ngFor="let subscription of premiumDetails.subscriptions">
                                              <settings-account-tariff left [subscription]="subscription"></settings-account-tariff>

                                              <ng-container right>
                                                  <settings-account-payment [premiumSubscription]="subscription"></settings-account-payment>
                                                  <a class="account__cancel-link" (click)="cancelSubscription(subscription)">{{'SETTINGS.ACCOUNT.CANCEL_SUBSCRIPTION' | translate}}</a>
                                              </ng-container>
                                          </settings-account-box>
                                      </ng-container>


                                      <ng-template #expire>
                                          <settings-account-box class="account__box" *ngIf="premiumDetails.active.expireAt">
                                              <settings-account-tariff left [subscription]="premiumDetails.active"></settings-account-tariff>
                                              
                                              <div right>
                                                  <div>
                                                      <span class="account__box__expire-data">{{'SETTINGS.ACCOUNT.PREMIUM.EXPIRE_AT'|translate}}</span>
                                                      {{expireDate}}
                                                  </div>
                                                      <a (click)="extendPremium()">{{'SETTINGS.ACCOUNT.EXTENT_PREMIUM' | translate}}</a>
                                              </div>
                                          </settings-account-box>
                                      </ng-template>
                                  </div>

                                  <div class="account__profile">
                                      <div class="account__profile__title account__headline">{{'SETTINGS.ACCOUNT.PROFILE' | translate}}</div>

                                      <div class="account__profile__row">
                                          <div class="account__profile__row__title">{{'SETTINGS.ACCOUNT.PROFILE.NICKNAME' | translate}}</div>
                                          <div class="account__profile__row__content">{{currentUser.nickname}}</div>
                                      </div>
                                      <div class="account__profile__row">
                                          <div class="account__profile__row__title">{{'SETTINGS.ACCOUNT.PROFILE.EMAIL' | translate}}</div>
                                          <div class="account__profile__row__content">{{profileInfo.email}}</div>
                                      </div>
                                      <div class="account__profile__row">
                                          <div class="account__profile__row__title">{{'SETTINGS.ACCOUNT.PROFILE.BIRTHDATE' | translate}}</div>
                                          <div class="account__profile__row__content">{{birthdate}}</div>
                                      </div>
                                      <div class="account__profile__row">
                                          <div class="account__profile__row__title">{{'SETTINGS.ACCOUNT.PROFILE.STAY_ON_BETA' | translate}}</div>
                                          <div class="account__profile__row__content">
                                              <switch [(value)]="stayOnBetaService.stayOnBeta">
                                                  <a (click)="backToLegacyLink()">{{'SETTINGS.ACCOUNT.PROFILE.STAY_ON_BETA.LINK' | translate}}</a>
                                              </switch>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="account__footer">
                                      <a class="account__footer__link" (click)="track('AccountLoeschenBegonnen')" [routerLink]="navigationService.getRoute(Routes.DeleteProfile)">{{'SETTINGS.ACCOUNT.DELETE_ACCOUNT' | translate}}</a>
                                      <raised-button class="account__footer__button"
                                                     (click)="toProfile()"
                                      >{{'SETTINGS.ACCOUNT.EDIT_PROFILE' | translate}}</raised-button>
                                  </div>

                              </card-title>

                              <dialog-box #paypalDialog
                                          [acceptText]="'GLOBAL.OK' | translate"
                              >
                                  {{'PREMIUM_OVERVIEW.PAYPAL.DIALOG' | translate}}
                              </dialog-box>

                              <dialog-box #androidDialog
                                          [acceptText]="'GLOBAL.OK' | translate"
                              >
                                  {{'PREMIUM_OVERVIEW.ANDROID.DIALOG' | translate}}
                              </dialog-box>

                          `,
               styles: [require('./settings-account.scss')],
           })

export class SettingsAccount implements OnInit, OnDestroy {
    private currentUser: CurrentUser = new CurrentUser();
    private profileInfo: ProfileInfo = new ProfileInfo();
    private premiumDetails: PremiumDetails;
    private Routes = Routes;
    private hasSubscription: boolean = false;
    private currentUserSubscription = new Subscription();
    @ViewChild('paypalDialog', { static: true }) private paypalDialogBox: DialogBox;
    @ViewChild('androidDialog', { static: true }) private androidDialogBox: DialogBox;

    constructor(
        private currentUserService: CurrentUserService,
        private premiumService: PremiumService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
        private stayOnBetaService: StayOnBetaService
    ) {
    }

    public ngOnInit(): void {
        this.profileInfo = this.currentUserService.cachedProfileInfo;
        this.premiumService.getDetails().then(details => {
            this.premiumDetails = details;
            this.hasSubscription = details.hasSubscription;
        });
        this.currentUserSubscription = this.currentUserService
                                           .currentUserObservable
                                           .subscribe(value => this.currentUser = value);
    }

    public ngOnDestroy() {
        this.currentUserSubscription.unsubscribe();
    }

    private get status(): string {
        if (this.currentUser.isPremium()) {
            return 'SETTINGS.ACCOUNT.STATUS.PREMIUM';
        } else {
            return 'SETTINGS.ACCOUNT.STATUS.BASIC';
        }
    }

    private get birthdate(): string {
        return moment(this.profileInfo.birthdate).format('DD.MM.YYYY');
    }

    private get isDebit(): boolean {
        return this.premiumDetails ? this.premiumDetails.subscriptions.first().isDebit : false;
    }

    private cancelSubscription(subscription) {
        this.track('AboKuendigenBegonnen');

        switch (subscription.type) {
            case SubscriptionType.Paypal:
                this.paypalDialogBox.open();
                break;
            case SubscriptionType.PlayStore:
                this.androidDialogBox.open();
                break;
            default:
                this.navigationService.navigateTo(Routes.PremiumCancel);
        }
    }

    private get expireDate() {
        return moment(this.premiumDetails.active.expireAt).format('DD.MM.YYYY');
    }

    private get headline(): string {
        if (this.hasSubscription) {
            return 'SETTINGS.ACCOUNT.HEADLINE.SUBSCRIPTION';
        } else {
            return 'SETTINGS.ACCOUNT.HEADLINE.PREMIUM';
        }
    }

    private extendPremium() {
        this.track('JetztVerlaengernBegonnen');
        this.premiumService.navigateToPremium(OrderReason.ExtendPremium);
    }

    private toProfile() {
        this.track('ProfilBearbeiten');
        this.navigationService.navigateTo(Routes.MyProfile);
    }

    private track(label: string) {
        this.trackingService.hit('Einstellungen', 'LinkGeklickt', label);
    }

    private backToLegacyLink() {
        this.stayOnBetaService.returnToLegacy();
    }
}
