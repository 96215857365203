/**
 * Created by olifra on 26.06.2017.
 */
import { Component } from '@angular/core';

/**
 * Bildkontakebar für Corperate Identity
 */
@Component({
               selector:  'ci-bar',
               template:  `
                   <div class="bk-ci-bar">
                       <div class="bk-ci-bar__left"></div>
                       <div class="bk-ci-bar__right"></div>
                   </div>`,
               styles: [require('./ci-bar.scss')],
           })
export class CiBar {
}
