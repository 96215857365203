import {
    Component,
    OnInit,
} from '@angular/core';
import { UserBaseView } from 'BKModels';
import { CommunicationService } from 'BKService';
import { IgnoreDataSource } from '../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../service/data-source-factory';
import { GlobalModalService } from '../../../../service/global-modal.service';
import { IgnoreService } from '../../../../service/ignore.service';
import { BkModalRemovedIgnoredUser } from '../../../../shared/mat-design/bk-modals/bk-modal-removed-ignored-user';

@Component({
               selector:  'settings-ignore-list',
               template: `
                             <card class="intro-card">
                                 <div class="intro">
                                     <div class="intro-text">{{ 'IGNORE.LIST.INTRO'|translate }}</div>
                                 </div>
                             </card>
                             <loading-spinner *ngIf="isLoading"></loading-spinner>
                             <list-container *ngIf="!isLoading && !isEmpty" [dataSource]="listDataSource">
                                 <ng-template let-item>
                                     <list-item-selector two-columns-max
                                                         [listItem]="item"
                                                         (removeIgnoredUser)="remove($event)"
                                     ></list-item-selector>
                                 </ng-template>
                             </list-container>

                         `,
               styles: [require('./settings-ignore-list.scss')],
           })
export class SettingsIgnoreList implements OnInit {
    private listDataSource: IgnoreDataSource = null;

    public constructor(
        private dataSourceFactory: DataSourceFactory,
        private ignoreService: IgnoreService,
        private globalModalService: GlobalModalService,
        private communicationService: CommunicationService) {}

    public ngOnInit() {
        this.dataSourceFactory
            .createIgnoreDataSource()
            .then(dataSource => {
                this.listDataSource = dataSource;
                this.listDataSource.loadMore();
            });
    }

    private get isLoading(): boolean {
        return !!this.listDataSource ? this.listDataSource.isLoading : true;
    }

    private get isEmpty(): boolean {
        return !!this.listDataSource && this.listDataSource.isEmpty;
    }

    private remove(user: UserBaseView) {
        this.ignoreService.remove(user)
            .then(() => {
                this.globalModalService.open(BkModalRemovedIgnoredUser.typeId, user);
                this.listDataSource.remove(value => value.ignoredUser.id === user.id);
            })
            .catch(() => this.communicationService.growl.ignore.remove.handleError(user));
    }
}
