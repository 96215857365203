export enum ObTabPictureState {
    ChooseImg,
    Upload,
    Verify,
    Rejected,
}

export enum OnBoardingModalSteps {
    pictureUpload,
    profileInfo,
    verifyEmail,
    newEmail,
}

/**
 * gibt an ob die Felder gefüllt sind
 */
export class FilledFields {
    /**
     * True wenn ein Bild hochgeladen wurde
     {boolean}
     */
    public pictureUpload: boolean = false;

    /**
     * true wenn bestimmte ProfileInfos gesetzt sind
     {boolean}
     */
    public profileInfo: boolean = false;

    /**
     * true wenn die Email verifiziert wurde
     {boolean}
     */
    public verifyEmail: boolean = false;
}
