import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { MDCDialog } from '@material/dialog/component';

@Component({
               selector:  'modal',
               template:  `
                              <div #modal class="mdc-dialog modal"
                                   [class.modal--scrim-disabeld]="!backgroundClose"
                              >
                                  <div #content class="mdc-dialog__container modal__container">
                                      <div class="mdc-dialog__surface modal__surface">
                                          <div *ngIf="closeIcon" class="modal__surface__wrapper">
                                              <icon-button [tabIndex]="-1" class="modal__surface__wrapper__close-icon" icon="close" (click)="close()"></icon-button>
                                          </div>
                                          
                                          <div class="mdc-dialog__content modal__content" (scroll)="scroll.emit($event)">
                                              <ng-content></ng-content>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="mdc-dialog__scrim" (click)="close()"></div>
                              </div>
                          `,
               styles: [require('./modal.scss')],
           })

export class Modal implements AfterViewInit {
    @ViewChild('content', { static: true }) private contentRef: ElementRef;
    public modal: MDCDialog;
    @ViewChild('modal', { static: true }) private modalRef: ElementRef;
    @Output() private onClose = new EventEmitter();
    @Output() protected scroll = new EventEmitter();
    @Input() protected backgroundClose: boolean = true;
    @Input() protected closeIcon : boolean = false;

    public get isOpen(): boolean {
        if (!this.modal) return false;
        return this.modal.isOpen;
    }

    public close() {
        if (!this.modal) return;
        this.modal.close();
    }

    public ngAfterViewInit() {
        this.modal = new MDCDialog(this.modalRef.nativeElement);
        if (!this.backgroundClose) {
            this.modal.scrimClickAction = '';
            this.modal.escapeKeyAction = '';
        }
        this.modal.listen('MDCDialog:closing', () => {
            document.body.style.overflow = 'auto';
            this.onClose.emit();
        });
    }

    public open() {
        if (this.modal.isOpen) return;
        this.modal.open();
    }

    public set escapeKeyAction(val: string) {
        this.modal.escapeKeyAction = val;
    }

    public get escapeKeyAction(): string {
        return this.modal.escapeKeyAction;
    }

}
