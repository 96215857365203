import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { TrackingService } from 'BKService';
import {
    Offer,
    OfferType,
} from '../../../../models/offer';
import { Tariff } from '../../../../models/tariff';
import { NavigationService } from '../../../../service/navigation.service';
import { PaymentService } from '../../../../service/payment.service';

@Component({
               selector:  'welcome-offer',
               template:  `
                              <div class="welcome-offer">
                                  <div class="welcome-offer-header">
                                      <div class="welcome-offer-header__title">
                                          <img class="welcome-offer-header__banner" src="./assets/img/modal/welcome-banner.svg"/>
                                          <div class="welcome-offer-header__title__description">
                                              <div class="welcome-offer__text--bold" [innerText]="'OFFER.WELCOME_OFFER.MODAL.DESCRIPTION.TITLE'|translate:{'username': username}"></div>
                                              <p [innerText]="'OFFER.WELCOME_OFFER.MODAL.DESCRIPTION.THANKYOU'|translate"></p>
                                              <p [innerText]="'OFFER.WELCOME_OFFER.MODAL.DESCRIPTION.REASON'|translate"></p>
                                              <p class="welcome-offer__text--bold" [class.loading-text-placeholder-container]="tariffLoading">
                                                  <span [innerText]="'OFFER.WELCOME_OFFER.MODAL.DESCRIPTION.PRICING'|translate:{'offerPrice': offerPrice}"></span>&nbsp;
                                                  <span class="welcome-offer__old-price">{{ normalPrice }}</span>
                                                  <br/>
                                                  <span class="welcome-offer__text--blur" [innerText]="'OFFER.WELCOME_OFFER.MODAL.DESCRIPTION.REGULAR_PRICE'|translate"></span>
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="welcome-offer-grid">
                                      <div class="welcome-offer-option welcome-offer-option-a carousel-cell">
                                          <div class="welcome-offer-option__title">
                                              <div class="welcome-offer-option__title-text" [innerText]="'OFFER.WELCOME_OFFER.MODAL.BASIC_TITLE'|translate"></div>
                                              <div class="welcome-offer-option__title-bottom"></div>
                                          </div>
                                          <div class="welcome-offer-option__content">
                                              <p class="welcome-offer-option__content__name">{{ 'OFFER.WELCOME_OFFER.MODAL.BASIC_NAME'|translate }}</p>
                                              <usp-item>{{'OFFER.WELCOME_OFFER.MODAL.BASIC_USP1'|translate}}</usp-item>
                                              <usp-item>{{'OFFER.WELCOME_OFFER.MODAL.BASIC_USP2'|translate}}</usp-item>
                                              <usp-item>{{'OFFER.WELCOME_OFFER.MODAL.BASIC_USP3'|translate}}</usp-item>
                                              <usp-item type="contra">{{'OFFER.WELCOME_OFFER.MODAL.BASIC_CONTRA_USP1'|translate}}</usp-item>
                                              <usp-item type="contra">{{'OFFER.WELCOME_OFFER.MODAL.BASIC_CONTRA_USP2'|translate}}</usp-item>
                                          </div>
                                          <div class="welcome-offer-option__button-panel">
                                              <raised-button class="welcome-offer-option__content__cta"
                                                             (click)="goToBasic()"
                                              >{{ 'OFFER.WELCOME_OFFER.MODAL.BASIC.ACTION'|translate }}</raised-button>
                                          </div>
                                      </div>
                                      <div class="welcome-offer-option welcome-offer-option-b carousel-cell">
                                          <div class="welcome-offer-option__title">
                                              <div class="welcome-offer-option__title-text">{{ 'OFFER.WELCOME_OFFER.MODAL.PREMIUM_TITLE'|translate }}
                                                  <div class="welcome-offer__text--bold"
                                                       [class.loading-text-placeholder-dark]="tariffLoading">
                                                      {{ 'OFFER.WELCOME_OFFER.MODAL.PREMIUM_TITLE_SAVINGS'|translate:{ savings: discount } }}
                                                  </div>
                                              </div>
                                              <div class="welcome-offer-option__title-bottom"></div>
                                          </div>
                                          <div class="welcome-offer-option__content">
                                              <p class="welcome-offer-option__content__name">{{ 'OFFER.WELCOME_OFFER.MODAL.PREMIUM_NAME'|translate }}</p>
                                              <usp-item>
                                                  <span [innerHTML]="'OFFER.WELCOME_OFFER.MODAL.PREMIUM_USP1'|translate|inlineMarkdown"></span>
                                              </usp-item>
                                              <usp-item>
                                                  <span [innerHTML]="'OFFER.WELCOME_OFFER.MODAL.PREMIUM_USP2'|translate|inlineMarkdown"></span>
                                              </usp-item>
                                              <usp-item>
                                                  <span [innerHTML]="'OFFER.WELCOME_OFFER.MODAL.PREMIUM_USP3'|translate|inlineMarkdown"></span>
                                              </usp-item>
                                              <usp-item>
                                                  <span [innerHTML]="'OFFER.WELCOME_OFFER.MODAL.PREMIUM_USP4'|translate|inlineMarkdown"></span>
                                              </usp-item>
                                          </div>
                                          <div class="welcome-offer-option__button-panel">
                                              <raised-button class="welcome-offer-option__content__cta"
                                                             premium
                                                             (click)="goToPremium()"
                                              >{{ 'OFFER.WELCOME_OFFER.MODAL.PREMIUM.ACTION'|translate }}</raised-button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./welcome-offer.scss')],
           })
export class WelcomeOffer {
    @Input() private username: string;
    @Input() private offer: Offer;
    @Output() private close: EventEmitter<any> = new EventEmitter();

    private carousel: any = null;
    private offerTariff: Tariff = Tariff.empty;
    private expensivePremiumProduct: Tariff = Tariff.empty;

    get tariffLoading(): boolean {
        return this.offerTariff.isEmpty() || this.expensivePremiumProduct.isEmpty();
    }

    private get normalPrice(): string {
        return this.expensivePremiumProduct.formattedPrice;
    }

    private trackingLabels: { [key in OfferType]: { category: string, label: string } } = {
        [OfferType.LegacyWelcomeOffer]: { category: 'WillkommensangebotLayer', label: 'WillkoLogikAlt' },
        [OfferType.LegacyWelcomeDelayedOffer]: { category: 'WillkommensangebotLayer', label: 'WillkoLogik10TageNach' },
        [OfferType.RecurringOffer]: {category: 'should_never_happen', label: 'should_never_happen'},
        [OfferType.BirthdayOffer]: {category: 'should_never_happen', label: 'should_never_happen'},
        [OfferType.LoyaltyOffer]: {category: 'should_never_happen', label: 'should_never_happen'},
    }

    private get discount(): string {
        const discount = this.expensivePremiumProduct.getDiscount(this.offerTariff);
        if (isNaN(discount)) return '--';
        return Math.floor(discount).toString();
    }

    private get offerPrice(): string {
        return this.offerTariff.formattedPrice;
    }

    constructor(
        private paymentService: PaymentService,
        private navigationService: NavigationService,
        private trackingService: TrackingService,
    ) {
    }

    ngOnInit() {
        this.paymentService.tariff(this.offer.tariffId).then(res => this.offerTariff = res);
        this.paymentService.mostExpensivePremiumProduct.then(res => this.expensivePremiumProduct = res);
    }

    ngAfterViewInit() {
        if (window.innerWidth < 480 && !this.carousel) {
            this.carousel = new window.Flickity(window.document.querySelector('.welcome-offer-grid'), {
                prevNextButtons: false,
                percentPosition: false,
                initialIndex: 1,
            });
        }
    }

    goToPremium() {
        this.trackingService.hit(this.trackingLabels[this.offer.offerType].category, 'AngebotSichernGeklickt', this.trackingLabels[this.offer.offerType].label);
        this.navigationService.navigateToPurchasePage(this.offer.tariffId, this.offer.orderReason, this.offer.id);
    }

    goToBasic() {
        this.trackingService.hit(this.trackingLabels[this.offer.offerType].category, 'WeiterBasisGeklickt', this.trackingLabels[this.offer.offerType].label);
        this.close.emit(null);
    }
}
