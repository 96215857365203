import { Image } from './image';
import { ProfileInfo } from './user';


export enum MatchingDecision {
    NoDecision,
    Yes,
    No,
    Maybe,
}


/**
 * Matching Queue Item
 */
export class MatchingQueueItem {
    /**
     * Entscheidung des Partners
     {MatchingDecision}
     */
    private _decision: MatchingDecision = MatchingDecision.NoDecision;

    public set decision(val: MatchingDecision) {
        this._decision = val;
        this.partner.decisionPartner = val;
    }

    public get decision() : MatchingDecision {
        return this._decision
    }

    /**
     * Gallery des Partners
     {Array}
     */
    private _gallery: Image[] = [];
    public get gallery(): Image[] {
        return this._gallery
    }
    public set gallery(val: Image[]) {
        this._gallery = val;
        this.partner.fullGallery = val;
        this.partner.gallery = val.map(it => it.image);
    }


    /**
     * Profile des Partners
     {ProfileInfo}
     */public partner: ProfileInfo = new ProfileInfo();

    /**
     * Creator
     {MatchingQueueItem} item
     {MatchingQueueItem}
     */
    public static create(item ?: MatchingQueueItem): MatchingQueueItem {
        const res = new MatchingQueueItem();
        if (item !== undefined) {
            res.partner = ProfileInfo.create(item.partner);
            res.decision = item.decision;
            res.gallery = Image.createMultiple(item.gallery);
        }
        return res;
    }
}

/**
 * Matchingpartner
 */
export class MatchingPartner {
    /**
     * Entscheidung des eingeloggten Benutzers
     {MatchingDecision}
     */
    public decisionOwner: MatchingDecision = MatchingDecision.NoDecision;

    /**
     * Entscheidung des Matchingpartners
     {MatchingDecision}
     */
    public decisionPartner: MatchingDecision = MatchingDecision.NoDecision;

    /**
     * Profile des Partners
     {ProfileInfo}
     */
    public partner: ProfileInfo = new ProfileInfo();

    /**
     * Konstruktor für der Matchingpartners
     {MatchingPartner} partner
     {MatchingPartner}
     */
    public static create(partner: MatchingPartner): MatchingPartner {
        const res = new MatchingPartner();
        res.partner = partner.partner;
        res.decisionOwner = partner.decisionOwner;
        res.decisionPartner = partner.decisionPartner;
        return res;
    }
}


/**
 * Frame für die DecisionBatch
 */
export class DecisionBatch {
    /**
     * Entscheidung des eingeloggten Benutzers
     *
     {MatchingDecision}
     */
    public decision: MatchingDecision = MatchingDecision.NoDecision;

    /**
     * Id des Benutzer auf den sich die Entscheidung bezieht
     *
     {number}
     */
    public id: number;

    /**
     * constructor
     {number} _id
     {MatchingDecision} _decision
     */
    public constructor(
        _id: number,
        _decision: MatchingDecision,
    ) {
        this.id = _id;
        this.decision = _decision;
    }
}
