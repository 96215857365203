import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { InternComponentsModule } from '../intern-components';
import authenticityCheck from './authenticity-check';
import editLayout from './edit-images';
import { FoodLabel } from './label/food-label';
import { MovieLabel } from './label/movie-label';
import { MusicLabel } from './label/music-label';
import profileEdit from './profile-edit';
import { LabelBox } from './label/label-box'
import { profileEditRouting } from './profile-edit.routing';
import { ProfileEditNavigationService } from './profile-edit/profile-edit-navigation.service';

@NgModule({
              imports:      [ShareModule, profileEditRouting, InternComponentsModule],
              exports:      [],
              declarations: [
                  ...profileEdit,
                  ...authenticityCheck,
                  ...editLayout,
                  LabelBox,
                  FoodLabel,
                  MovieLabel,
                  MusicLabel

              ],
              providers:    [
                  ProfileEditNavigationService
              ],
          })
export class ProfileEditModule {


}
