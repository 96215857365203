/**
 * Created by niczou on 09.01.2018.
 */

import {
    Component,
    Input,
} from '@angular/core';
import { CurrentUser } from 'BKModels';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../service/navigation.service';

enum ProfileVisitorEmptyState {
    NoProfileImage,
    InValidation,
    EmptyList,
}

@Component({
               selector:  'profile-visitor-empty',
               template:  `
                              <ng-container [ngSwitch]="getState()">
                                  <list-empty *ngSwitchCase="ProfileVisitorEmptyState.EmptyList"
                                              img="/assets/img/empty-icon.svg"
                                              [title]="'PROFILE_VISITORS.EMPTY.ACTIVE.TITLE'|translate"
                                              [text]="'PROFILE_VISITORS.EMPTY.ACTIVE.TEXT'|translate"
                                  >
                                      <raised-button [routerLink]="myProfileLink" class="profile-visitor-empty__button">
                                          {{'PROFILE_VISITORS.EMPTY.NOT_ACTIVE.BUTTON_TEXT'|translate}}
                                      </raised-button>
                                  </list-empty>
                                  <list-empty *ngSwitchCase="ProfileVisitorEmptyState.InValidation"
                                              img="/assets/img/empty-icon.svg"
                                              [title]="'PROFILE_VISITORS.EMPTY.NOT_ACTIVE.TITLE'|translate"
                                              [text]="'PROFILE_VISITORS.EMPTY.NOT_ACTIVE.TEXT'|translate"
                                  >
                                      <raised-button [routerLink]="myProfileLink" class="profile-visitor-empty__button">
                                          {{'PROFILE_VISITORS.EMPTY.NOT_ACTIVE.BUTTON_TEXT'|translate}}
                                      </raised-button>
                                  </list-empty>
                                  <list-empty *ngSwitchCase="ProfileVisitorEmptyState.NoProfileImage"
                                              img="/assets/img/profilbesucher-leere-liste.svg"
                                              [title]="'PROFILE_VISITORS.EMPTY.NO_IMAGE.TITLE'|translate"
                                              [text]="'PROFILE_VISITORS.EMPTY.NO_IMAGE.TEXT'|translate|markdown"
                                  >
                                      <raised-button [routerLink]="myProfileLink" [fragment]="ProfileEditRouteFragment.Images" class="profile-visitor-empty__button">
                                          {{'PROFILE_VISITORS.EMPTY.NO_IMAGE.BUTTON'|translate}}
                                      </raised-button>
                                  </list-empty>
                              </ng-container>
                          `,
               styles: [require('./profile-visitor-empty.scss')],
           })
export class ProfileVisitorEmpty {
    @Input() private currentUser: CurrentUser;
    private ProfileVisitorEmptyState = ProfileVisitorEmptyState;
    private ProfileEditRouteFragment = ProfileEditRouteFragment;

    public constructor(private navigationService: NavigationService) {
    }

    private get myProfileLink() {
        return this.navigationService.getRoute(Routes.MyProfile);
    }

    private getState(): ProfileVisitorEmptyState {
        if (!this.currentUser.profileImgExist()) {
            return ProfileVisitorEmptyState.NoProfileImage;
        }

        if (this.currentUser.isInRegistrationWithImage()) {
            return ProfileVisitorEmptyState.InValidation;
        }
        return ProfileVisitorEmptyState.EmptyList;
    }
}
