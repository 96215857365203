import {
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import {
    BodyType,
    ContactWish,
    EyeColor,
    HairColor,
    ProfileInfo,
} from 'BKModels';
import { CurrentUserService } from 'BKService';
import { Label } from '../../../models/label';
import { ProfileEditService } from '../../../service/profile-edit.service';
import { ProfileService } from '../../../service/profile.service';
import { AllowedValue } from './profile-interview-single/profile-interview-single';

enum Question {
    Quote,
    Food,
    Movies,
    Music,
    Likes,
    Contact,
    BodyShape,
    HairColor,
    EyeColor,
    Description
}

enum QuestionType {
    Single,
    Tags,
    Text
}

class QuestionConfig<T, S> {
    public value: T;
    public allowed: S;
    public isSet = false;

    constructor(
        public readonly type: QuestionType,
        public readonly titleKey: string,
        public readonly infoKey: string,
        public readonly save: (T) => Promise<void>,
        public readonly load: (config: QuestionConfig<T, S>) => Promise<void>,
    ) {
    }
}

@Component({
               selector:  'profile-interview',
               template:  `
                              <ng-container>
                                  <card *ngIf="!empty" class="interview-card">
                                      <div class="container">
                                          <ng-container *ngIf="loading || !profile; else notLoading">
                                              <loading-spinner></loading-spinner>
                                          </ng-container>
                                          <ng-template #notLoading>
                                              <div class="title">{{ config[question].titleKey|translate }}</div>
                                              <div class="info">{{ config[question].infoKey|translate }}</div>

                                              <ng-container *ngIf="config[question].type === QuestionType.Text">
                                                  <profile-interview-text
                                                          [(value)]="config[question].value"
                                                          [limit]="config[question].allowed"
                                                  ></profile-interview-text>
                                              </ng-container>
                                              <ng-container *ngIf="config[question].type === QuestionType.Tags">
                                                  <profile-interview-tags
                                                          [(value)]="config[question].value"
                                                          [allowed]="config[question].allowed"
                                                  ></profile-interview-tags>
                                              </ng-container>
                                              <ng-container *ngIf="config[question].type === QuestionType.Single">
                                                  <profile-interview-single
                                                          [(value)]="config[question].value"
                                                          [allowed]="config[question].allowed"
                                                  ></profile-interview-single>
                                              </ng-container>

                                              <div class="actions">
                                                  <flat-button (click)="onSkip()">{{ 'PROFILE_INTERVIEW.SKIP'|translate }}</flat-button>
                                                  <raised-button (click)="onSave()">{{ 'PROFILE_INTERVIEW.SAVE'|translate }}</raised-button>
                                              </div>
                                          </ng-template>
                                      </div>
                                  </card>
                              </ng-container>
                          `,
               styles: [require('./profile-interview.scss')],
           })
export class ProfileInterview implements OnInit {
    @Output() noQuestions = new EventEmitter();

    QuestionType = QuestionType;

    question: Question;

    loading = true;

    profile: ProfileInfo;

    empty = false;

    config: { [key in Question]: QuestionConfig<unknown, unknown> } = {
        [Question.Quote]:       new QuestionConfig<string, number>(
            QuestionType.Text,
            'PROFILE_INTERVIEW.QUOTE.TITLE',
            'PROFILE_INTERVIEW.QUOTE.INFO',
            (v) => {
                this.profile.motto = v;
                return this.currentUserService.changeProfileInfoBundle({ motto: v });
            },
            (config) => {
                config.value = this.profile.motto;
                config.allowed = 70;
                config.isSet = config.value.length > 0;
                return Promise.resolve();
            },
        ),
        [Question.Food]:        new QuestionConfig<Label[], Label[]>(
            QuestionType.Tags,
            'PROFILE_INTERVIEW.FOOD.TITLE',
            'PROFILE_INTERVIEW.FOOD.INFO',
            (v) => {
                return this.profileEditService.saveSelectedFoodLabels(v).then();
            },
            (config) => {
                return Promise.all([
                                       this.profileEditService.getAllFoodLabels(),
                                       this.profileEditService.getSelectedFoodLabels(),
                                   ]).then(([all, selected]) => {
                    config.allowed = all;
                    config.value = selected;
                    config.isSet = config.value.length > 0;
                });
            },
        ),
        [Question.Movies]:      new QuestionConfig<Label[], Label[]>(
            QuestionType.Tags,
            'PROFILE_INTERVIEW.MOVIES.TITLE',
            'PROFILE_INTERVIEW.MOVIES.INFO',
            (v) => {
                return this.profileEditService.saveSelectedMovieLabels(v).then();
            },
            (config) => {
                return Promise.all([
                                       this.profileEditService.getAllMovieLabels(),
                                       this.profileEditService.getSelectedMovieLabels(),
                                   ]).then(([all, selected]) => {
                    config.allowed = all;
                    config.value = selected;
                    config.isSet = config.value.length > 0;
                });
            },
        ),
        [Question.Music]:       new QuestionConfig<Label[], Label[]>(
            QuestionType.Tags,
            'PROFILE_INTERVIEW.MUSIC.TITLE',
            'PROFILE_INTERVIEW.MUSIC.INFO',
            (v) => {
                return this.profileEditService.saveSelectedMusicLabels(v).then();
            },
            (config) => {
                return Promise.all([
                                       this.profileEditService.getAllMusicLabels(),
                                       this.profileEditService.getSelectedMusicLabels(),
                                   ]).then(([all, selected]) => {
                    config.allowed = all;
                    config.value = selected;
                    config.isSet = config.value.length > 0;
                });
            },
        ),
        [Question.Likes]:       new QuestionConfig<string, number>(
            QuestionType.Text,
            'PROFILE_INTERVIEW.LIKES.TITLE',
            'PROFILE_INTERVIEW.LIKES.INFO',
            (v) => {
                this.profile.like = v;
                return this.currentUserService.changeProfileInfoBundle({ like: v });
            },
            (config) => {
                config.value = this.profile.like;
                config.allowed = 255;
                config.isSet = config.value.length > 0;
                return Promise.resolve();
            },
        ),
        [Question.Contact]:     new QuestionConfig<ContactWish, AllowedValue<ContactWish>[]>(
            QuestionType.Single,
            'PROFILE_INTERVIEW.CONTACT.TITLE',
            'PROFILE_INTERVIEW.CONTACT.INFO',
            (v) => {
                this.profile.contactWish = v;
                return this.currentUserService.changeProfileInfoBundle({ contactWish: v });
            },
            (config) => {
                config.value = this.profile.contactWish;
                config.allowed = Object.keys(ContactWish)
                                       .map(v => parseInt(v))
                                       .filter(v => !isNaN(v) && v !== ContactWish.AskMe)
                                       .map(value => {
                                           return {
                                               value:   value as ContactWish,
                                               textKey: `PROFILE_INFO.CONTACT_WISH.OPTIONS.${value}`,
                                           };
                                       });
                config.isSet = config.value !== ContactWish.AskMe;
                return Promise.resolve();
            },
        ),
        [Question.BodyShape]:   new QuestionConfig<BodyType, AllowedValue<BodyType>[]>(
            QuestionType.Single,
            'PROFILE_INTERVIEW.BODYSHAPE.TITLE',
            'PROFILE_INTERVIEW.BODYSHAPE.INFO',
            (v) => {
                this.profile.figure = v;
                return this.currentUserService.changeProfileInfoBundle({ figure: v });
            },
            (config) => {
                config.value = this.profile.figure;
                config.allowed = Object.keys(BodyType)
                                       .map(v => parseInt(v))
                                       .filter(v => !isNaN(v))
                                       .filter(v => v !== BodyType.AskMe)
                                       .map(value => {
                                           return {
                                               value:   value as BodyType,
                                               textKey: `PROFILE_INFO.BODYSHAPE.OPTIONS.${value}`,
                                           };
                                       });
                config.isSet = config.value !== BodyType.AskMe;
                return Promise.resolve();
            },
        ),
        [Question.HairColor]:   new QuestionConfig<HairColor, AllowedValue<HairColor>[]>(
            QuestionType.Single,
            'PROFILE_INTERVIEW.HAIRCOLOR.TITLE',
            'PROFILE_INTERVIEW.HAIRCOLOR.INFO',
            (v) => {
                this.profile.haircolor = v;
                return this.currentUserService.changeProfileInfoBundle({ haircolor: v });
            },
            (config) => {

                config.value = this.profile.haircolor;
                config.allowed = Object.keys(HairColor)
                                       .map(v => parseInt(v))
                                       .filter(v => !isNaN(v))
                                       .map(value => {
                                           return {
                                               value:   value as HairColor,
                                               textKey: `PROFILE_INFO.HAIR_COLOR.OPTIONS.${value}`,
                                           };
                                       });
                config.isSet = config.value !== HairColor.Undefined;
                return Promise.resolve();
            },
        ),
        [Question.EyeColor]:    new QuestionConfig<EyeColor, AllowedValue<EyeColor>[]>(
            QuestionType.Single,
            'PROFILE_INTERVIEW.EYECOLOR.TITLE',
            'PROFILE_INTERVIEW.EYECOLOR.INFO',
            (v) => {
                this.profile.eyecolor = v;
                return this.currentUserService.changeProfileInfoBundle({ eyecolor: v });
            },
            (config) => {
                config.value = this.profile.eyecolor;
                config.allowed = Object.keys(EyeColor)
                                       .map(v => parseInt(v))
                                       .filter(v => !isNaN(v))
                                       .map(value => {
                                           return {
                                               value:   value as EyeColor,
                                               textKey: `PROFILE_INFO.EYE_COLOR.OPTIONS.${value}`,
                                           };
                                       });
                config.isSet = config.value !== EyeColor.Undefined;
                return Promise.resolve();
            },
        ),
        [Question.Description]: new QuestionConfig<string, number>(
            QuestionType.Text,
            'PROFILE_INTERVIEW.DESCRIPTION.TITLE',
            'PROFILE_INTERVIEW.DESCRIPTION.INFO',
            (v) => {
                this.profile.description = v;
                return this.currentUserService.changeProfileInfoBundle({ about: v });
            },
            (config) => {
                config.value = this.profile.description;
                config.allowed = 10000;
                config.isSet = config.value.length > 0;
                return Promise.resolve();
            },
        ),
    };

    public ngOnInit(): void {
        this.loading = true;
        this.currentUserService.currentUser
            .then(currentUser => this.profileService.getUserProfile(currentUser.id))
            .then(profile => this.profile = profile)
            .then(() => Promise.all(Object.keys(this.config).map(i => this.config[i].load(this.config[i]))))
            .then(() => {
                this.selectRandomQuestion();
                this.loading = false;
            });
    }

    private selectRandomQuestion(avoid: Question = null) {

        const values = Object.keys(Question)
                             .map(v => parseInt(v))
                             .filter(v => !isNaN(v) && v !== avoid && !this.config[v].isSet) as unknown as Question[];

        const index = Math.floor(Math.random() * values.length);
        if (values.isEmpty()) {
            this.empty = true;
            this.noQuestions.emit();
        } else {
            this.question = values[index];
        }
    }

    onSave() {
        this.loading = true;
        this.config[this.question].save(this.config[this.question].value)
                                  .then(() => {
                                      this.config[this.question].isSet = true;
                                      this.selectRandomQuestion(this.question);
                                  })
                                  .finally(() => {
                                      this.loading = false;
                                  });
    }

    onSkip() {
        this.selectRandomQuestion(this.question);
    }

    constructor(
        private profileEditService: ProfileEditService,
        private currentUserService: CurrentUserService,
        private profileService: ProfileService,
    ) {
    }
}
