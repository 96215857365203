import {
    Component,
    Input,
} from '@angular/core';
import { growShrink } from 'BKUtils';

@Component({
               selector: 'pay-with-qr',
               template: `
                             <div>
                                 <flat-button *ngIf="folded" (click)="folded = !folded" class="fold-link">{{ 'PAY_WITH_QR.FOLD_ACTION'|translate }}</flat-button>
                                 <div *ngIf="!folded" [@growShrink]="false" class="qr-container">
                                     <epc-qr [bic]="bic" [iban]="iban" [recipient]="recipient" [value]="formattedValue" [reference]="reference" width="240"></epc-qr>
                                     <div class="markdown first-last-nomargin qr-instructions" [innerHTML]="'PAY_WITH_QR.INSTRUCTIONS'|translate|markdown"></div>
                                 </div>
                             </div>`,
               styles: [require('./pay-with-qr.scss')],
               animations: [growShrink],
           })

export class PayWithQr {
    @Input() value: number;
    @Input() reference: string;
    @Input() recipient: string = 'Entrex e.K.';
    @Input() iban: string = 'DE02375514400107007411';
    @Input() bic: string = 'WELADEDLLEV';

    folded = true;

    get formattedValue(): string {
        return `EUR${this.value.toFixed(2)}`;
    }
}
