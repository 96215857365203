import {
    Component,
    OnInit,
} from '@angular/core';
import {
    CurrentUser,
    OrderReason,
} from 'BKModels';
import {
    CurrentUserService,
    MatchingService,
    TrackingService,
} from 'BKService';
import {
    MatchingSecondChanceDataSource,
    MatchingWantYouDataSource,
} from '../../../../../models/list-data-sources';
import { DataSourceFactory } from '../../../../../service/data-source-factory';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';
import { PremiumService } from '../../../../../service/premium.service';

enum VisibleElement {
    List,
    MissingProfilePicture,
    EmptyList
}

/**
 * MatchingWantYouComponent
 */
@Component({
               selector:  'matching-second-chance',
               template: `
                         <ng-container *ngIf="visibleElement == VisibleElement.MissingProfilePicture">
                             <list-empty img="/assets/img/picMatchingList.svg"
                                         [title]="'MATCHING.SECOND_CHANCE.MODAL.MISSING_PROFILE_PICTURE.TITLE'|translate"
                                         [text]="'MATCHING.SECOND_CHANCE.MISSING_PROFILE_PICTURE.TEXT'|translate"
                             >
                                 <div class="matching-second-chance__missing-picture__action-wrap">
                                     <raised-button [routerLink]="navigationService.getRoute(Routes.MyProfile)" 
                                                    [fragment]="ProfileEditRouteFragment.Images"
                                                    class="profile-visitor-empty__button">
                                         {{'MATCHING.SECOND_CHANCE.MISSING_PROFILE_PICTURE.ACTION'|translate}}
                                     </raised-button>
                                 </div>
                             </list-empty>
                         </ng-container>
                         <ng-container *ngIf="visibleElement == VisibleElement.EmptyList">
                             <list-empty
                                     img="assets/img/bild-upload-pic.svg"
                                     [text]="'MATCHING.SECOND_CHANCE.EMPTY_LIST.TEXT'|translate|markdown"
                                     [title]="'MATCHING.SECOND_CHANCE.EMPTY_LIST.TITLE'|translate"
                             >
                                 <raised-button
                                         class="matching-second-chance__empty-list__action-wrap"
                                         [routerLink]="navigationService.getRoute(Routes.MyProfile)"
                                         (click)="trackingService.hit('Matching', 'ZweiteChance', 'Klick: Btn: JetztProfilAusfuellen')">
                                     {{'MATCHING.SECOND_CHANCE.EMPTY_LIST.ACTION'|translate}}
                                 </raised-button>
                                 <hr class="matching-second-chance__empty-list__separator"/>
                                 <div class="matching-second-chance__empty-list__lovestory">
                                     <div class="bk-matching-partner__empty-list__lovestory__title">
                                         {{ 'MATCHING.WANT_YOU.SECOND_CHANCE.EXTRA.TITLE'|translate }}
                                     </div>
                                     <img class="matching-second-chance__empty-list__lovestory__image" src="assets/img/matching-diana-eric.png"/>
                                     <div class="matching-second-chance__empty-list__lovestory__text">
                                         {{ 'MATCHING.SECOND_CHANCE.EMPTY_LIST.EXTRA.TEXT'|translate }}
                                     </div>
                                 </div>
                             </list-empty>
                         </ng-container>
                         <ng-container *ngIf="visibleElement == VisibleElement.List">
                             <bk-matching-list-header [title]="'MATCHING.SECOND_CHANCE.HEADER.TITLE'| translate:currentUser">
                                 <div class="matching-second-chance__text">
                                     <span [innerHTML]="'MATCHING.SECOND_CHANCE.HEADER.TEXT'| translate | markdown"></span>
                                 </div>
                             </bk-matching-list-header>

                             <div class="bk-matching-second-chance">
                                 <list-container #listContainer
                                                 [dataSource]="listDataSource"
                                 >
                                     <ng-template let-item>
                                         <list-item-selector two-columns-max
                                                             [listItem]="item"
                                                             [currentUser]="currentUser"
                                                             (onOpenProfile)="onOpenProfile()"
                                                             (gotoPremium)="gotoPremium()"
                                         ></list-item-selector>
                                     </ng-template>
                                 </list-container>
                             </div>
                         </ng-container>
                         `,
               styles: [require('./matching-second-chance.scss')],
           })
export class MatchingSecondChance implements OnInit {
    currentUser: CurrentUser = new CurrentUser();
    listDataSource: MatchingSecondChanceDataSource;

    Routes = Routes;
    ProfileEditRouteFragment = ProfileEditRouteFragment;

    VisibleElement = VisibleElement;
    get visibleElement(): VisibleElement {
        if (this.currentUserValid && !this.currentUser.profileImgExist()) return VisibleElement.MissingProfilePicture;
        if (this.listDataSource && this.listDataSource.isEmpty && !this.listDataSource.isLoading) return VisibleElement.EmptyList;
        return VisibleElement.List;
    }

    private get currentUserValid(): boolean {
        return !!this.currentUser && this.currentUser.id !== 0;
    }

    public constructor(
        private currentUserService: CurrentUserService,
        private matchingService: MatchingService,
        public trackingService: TrackingService,
        public navigationService: NavigationService,
        private premiumService: PremiumService,
        private dataSourceFactory: DataSourceFactory,
    ) {
    }

    public ngOnInit() {
        this.currentUserService
            .currentUser
            .then((currentUser) => this.currentUser = currentUser);

        this.dataSourceFactory.createMatchingSecondChanceDataSource().then(dataSource => {
            this.listDataSource = dataSource;
            this.listDataSource.loadMore();
        });
    }

    onOpenProfile() {
        this.trackingService.hit('Matching', 'ZweiteChance', 'ProfilAufgerufen');
    }

    gotoPremium() {
        this.premiumService.navigateToPremium(OrderReason.WantToKnowYou);
    }
}
