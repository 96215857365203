import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {
    addNavigationFinishedListener,
    removeNavigationFinishedListener,
    setBodyNoScroll,
} from 'BKUtils';
import { BadgeCounts } from '../../../../../models/badge-counts';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';
import { SideNavFooter } from '../side-nav-footer/side-nav-footer';
import { SideNavNavigation } from '../side-nav-navigation/side-nav-navigation';

/**
 * Komponente der Seitennavigation
 */
@Component({
               selector:  'bk-side-nav',
               template:  `
        <div class="side-nav-wrapper"
             [style.min-height.px]="minSideNavHeight"
             (clickOutside)="onClickOutside($event)"
        >
            <aside class="side-nav"
                   [class.side-nav--is-open]="open"
                   #container
                   [style.height.px]="sidenavHeight"
            >
                <side-nav-navigation #nav
                                     [badgeCounts]="badgeCounts"
                ></side-nav-navigation>
                <side-nav-footer #footer
                                 class="side-nav-footer"
                                 [class.side-nav-footer--static]="scrollNeed"
                ></side-nav-footer>
            </aside>
        </div>
    `,
               styles: [require('./side-nav.scss')],
           })
export class SideNav implements AfterViewInit, OnDestroy {
    @Output() protected openChange = new EventEmitter();
    @ViewChild('container', { static: true }) private containerRef: ElementRef;
    @Input() badgeCounts: BadgeCounts = new BadgeCounts();
    @ViewChild('footer', { static: true }) private footerRef: SideNavFooter;
    @ViewChild('nav', { static: true }) private navRef: SideNavNavigation;
    @Input() private headerHeight: number;
    scrollNeed = false;

    onNavigationFinishedListener = () => {
        this.closeNavigation();
    }

    protected _open: boolean = false;

    private get quickNavHeight(): number {
        return window.innerWidth < 480 ? 72 : 0;
    }

    get minSideNavHeight() {
        return window.innerHeight - this.headerHeight - 10;
    }

    get sidenavHeight() {
        return window.innerHeight - this.headerHeight - this.quickNavHeight - 20;
    }

    @Input()
    get open(): boolean {
        return this._open;
    }

    closeNavigation()
    {
        this.open = false;
    }

    onClickOutside($event: Event) {
        if (this.open) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        this.closeNavigation();
    }

    private set open(val: boolean) {
        this._open = val;
        this.openChange.emit(this._open);
        setBodyNoScroll(val);
    }

    ngAfterViewInit(): void {
        addNavigationFinishedListener(this.onNavigationFinishedListener);
        this.onResize();
    }

    ngOnDestroy() {
        removeNavigationFinishedListener(this.onNavigationFinishedListener);
    }

    @HostListener('window:resize')
    private onResize() {
        setBodyNoScroll(false);

        setTimeout(() => {
            const navHeight = parseInt(this.navRef.height.replace('px', ''));
            const footerHeight = parseInt(this.footerRef.height.replace('px', ''));
            const containerHeight = parseInt(window.getComputedStyle(this.containerRef.nativeElement)
                                                   .height
                                                   .replace('px', ''));

            this.scrollNeed = (navHeight + footerHeight) > containerHeight;
        });
    }

}
