import {
    Component,
    OnInit,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
} from '@angular/forms';
import { LovestoryListItem } from 'BKModels';
import { LovestoryService } from '../../../../../../service/lovestory.service';

@Component({
               selector:  'lovestory-formular-header',
               template:  `
                              <div class="lovestory-formular-header">
                                  <div class="lovestory-formular-header__item">
                                      <p class="lovestory-formular-header__title">{{'PROFILE.DELETE.LOVESTORY.FORMULAR.TITLE' | translate}}</p>
                                      <p class="lovestory-formular-header__text">{{'PROFILE.DELETE.LOVESTORY.FORMULAR.TEXT' | translate}}</p>
                                  </div>
                                  <div>
                                      <p class="lovestory-formular-header__text lovestory-formular-header__text--underscore">{{'PROFILE.DELETE.LOVESTORY.FORMULAR.RECENTLY_PUBLIC' | translate}}</p>


                                      <div class="lovestory-formular-header__image-list">
                                          <div class="lovestory-formular-header__image-list__item"
                                               *ngFor="let story of lovesotryList"
                                          >
                                              <img [src]="story.mainImageUrl"
                                                   class="lovestory-formular-header__image-list__item__img"
                                                   alt=""
                                              >
                                              <p class="lovestory-formular-header__image-list__item__text">{{story.nameFemale}}
                                                  & {{story.nameMale}}</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./lovestory-formular-header.scss')],
           })

export class LovestoryFormularHeader implements OnInit {
    private lovesotryList: LovestoryListItem[] = [];

    private lovestoryForm: FormGroup = new FormGroup({
                                                         name:        new FormGroup({
                                                                                        user:    new FormControl('name.user', Validators.required),
                                                                                        partner: new FormControl('name.partner', Validators.required),
                                                                                    }),
                                                         howFindUs:   new FormGroup({
                                                                                        user:    new FormControl('howFindUs.name', Validators.required),
                                                                                        partner: new FormControl('howFindUs.partner', Validators.required),
                                                                                    }),
                                                         firstStep:   new FormGroup({
                                                                                        user:    new FormControl('firstStep.name', Validators.required),
                                                                                        partner: new FormControl('firstStep.partner', Validators.required),
                                                                                    }),
                                                         firstDate:   new FormGroup({
                                                                                        user:    new FormControl('firstDate.name', Validators.required),
                                                                                        partner: new FormControl('firstDate.partner', Validators.required),
                                                                                    }),
                                                         howContinue: new FormGroup({
                                                                                        user:    new FormControl('howContinue.name', Validators.required),
                                                                                        partner: new FormControl('howContinue.partner', Validators.required),
                                                                                    }),
                                                         recomended:  new FormGroup({
                                                                                        user:    new FormControl('recomended.name', Validators.required),
                                                                                        partner: new FormControl('recomended.partner', Validators.required),
                                                                                    }),
                                                     });

    public constructor(private lovestoryService: LovestoryService) {

    }

    public ngOnInit() {
        this.lovestoryService.getList(2, 10).then(res => this.lovesotryList = res);
    }
}
