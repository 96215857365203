import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';

@Component({
               selector: 'profile-panel-buttons',
               template: `
        <div #profilePanel class="buttons">
            <outlined-button leadingIcon="photo-upload" (click)="openGallery.emit()">{{'PROFILE_VIEW.BUTTONS.IMAGES' | translate:{imageCount: user.imageCount.toString() } }}</outlined-button>
            <outlined-button *ngIf="user.favorite" leadingIcon="star" (click)="removeFavorite.emit()"
            >{{'PROFILE_VIEW.BUTTONS.REMOVE_FAVORITE'|translate }}</outlined-button>
            <outlined-button *ngIf="!user.favorite" leadingIcon="star-border" (click)="addFavorite.emit()"
            >{{'PROFILE_VIEW.BUTTONS.ADD_FAVORITE'|translate }}</outlined-button>
            <outlined-button [disabled]="msgDisabled" (click)="writeMsg.emit()" leadingIcon="chat-outline"
            >{{'PROFILE_VIEW.BUTTONS.CHAT'|translate }}</outlined-button>

        </div>`,
               styles: [require('./profile-panel-buttons.scss')],
           })

export class ProfilePanelButtons {
    @Output() private addFavorite = new EventEmitter();
    @Output() private openGallery = new EventEmitter();
    @ViewChild('profilePanel', { static: true }) private panelRef: ElementRef<HTMLElement>;
    @Output() private removeFavorite = new EventEmitter();
    @Input() private user: ProfileInfo;
    @Input() private msgDisabled: boolean = false;
    @Output() private writeMsg = new EventEmitter();

    public get height(): number {
        return this.panelRef.nativeElement.offsetHeight;
    }
}
