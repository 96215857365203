/**
 * Created by olifra on 22.05.2017.
 */

import {
    ApiResponseFrame,
    Image,
    ImageStatus,
} from 'BKModels';

import { Endpoints } from 'BKUtils';
import { ImageUploadResult } from '../../../models/image-upload-result';
import { dataUriToFile } from '../../../utils/file-converter';
import { ApiRequestService } from '../api-request.service';

/**
 * ApiService für die Image
 */
export class ImageApi {
    /**
     * constructor
     *
     {ApiRequestService} apiRequestService
     */
    public constructor(private apiRequestService: ApiRequestService) {
    }

    public delete(imageName: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.image.delete, {
                       body: {
                           imageName,
                       },
                   });
    }

    public getGallery(userId: number): Promise<Image[]> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.image.gallery, {
                       body:  { userId },
                   })
                   .then(res => {
                       return Image.createMultiple(res.data.gallery).sort((a, b) => {
                           const sortOrder = [
                               ImageStatus.OkMainPic, ImageStatus.Ok, ImageStatus.OkButNoRating, ImageStatus.OkButDeactivate,
                               ImageStatus.OkNotMainPic, ImageStatus.OkNotMainPicButDeactivate,
                               ImageStatus.NotApproved,
                               ImageStatus.Reject, ImageStatus.RejectOld
                           ];

                           const indexA = sortOrder.indexOf(a.status);
                           const indexB = sortOrder.indexOf(b.status);

                           return indexA > indexB ? 1 : indexA < indexB ? -1 : 0;
                       });
                   });

    }

    public saveSettings(status: number, description: string, imageName: string): Promise<ApiResponseFrame<any>> {
        return this.apiRequestService
                   .createPost<ApiResponseFrame<any>>(Endpoints.image.settings, {
                       body: {
                           status,
                           description,
                           imageName,
                           reset: false,
                       },
                   });
    }

    public upload(image: any): Promise<ApiResponseFrame<ImageUploadResult>> {
        // TODO: move to a helper function
        let options = Object.assign({},
                                    ApiRequestService.defaultOption,
                                    {
                                        body: {
                                            image: dataUriToFile(image),
                                        },
                                    });

        return this.apiRequestService
                   .createPost<ApiResponseFrame<ImageUploadResult>>(Endpoints.image.upload, options)
                   .then((res: ApiResponseFrame<ImageUploadResult>) => {
                       res.data = ImageUploadResult.create(res.data);

                       return res;
                   });
    }
}
