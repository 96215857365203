import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ProfileInfo } from 'BKModels';
import { Menu } from '../../../../../shared/mat-design/menu/menu';

@Component({
               selector:  'profile-menu',
               template: `
                             <menu #menu directionX="left">
                                 <menu-item *ngIf="user.favorite; else notFavorite" (click)="removeFavorite.emit()">
                                     {{'PROFILE_VIEW.HEADER.MENU.UNFAVOR' | translate}}
                                 </menu-item>
                                 <ng-template #notFavorite>
                                     <menu-item (click)="addFavorite.emit()">
                                         {{'PROFILE_VIEW.HEADER.MENU.FAVOR' | translate}}
                                     </menu-item>
                                 </ng-template>
                                 <menu-item (click)="addIgnore.emit()">
                                     {{'PROFILE_VIEW.HEADER.MENU.IGNORE' | translate}}
                                 </menu-item>
                                 <menu-item (click)="report.emit()">
                                     {{'PROFILE_VIEW.HEADER.MENU.REPORT_PROFILE' | translate}}
                                 </menu-item>
                             </menu>
                         `,
               styles: [require('./profile-menu.scss')],
           })

export class ProfileMenu {
    @ViewChild('menu', { static: true }) public menu: Menu;
    @Output() private addFavorite = new EventEmitter();
    @Output() private addIgnore = new EventEmitter();
    @Output() private removeFavorite = new EventEmitter();
    @Output() private report = new EventEmitter();
    @Input() private user: ProfileInfo = new ProfileInfo();
}


