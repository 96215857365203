import { EditImageItem } from './edit-image-item/edit-image-item';
import { EditImagesBoxComponent } from './edit-images-box/edit-img-box.component';
import { EditImagesLayout } from './edit-images-layout/edit-images-layout';
import { EditImagesPreview } from './edit-images-preview/edit-images-preview';

export default [
    EditImagesLayout,
    EditImagesPreview,
    EditImagesBoxComponent,
    EditImageItem
];
