import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { LovestoryModalModule } from '../lovestory-modal';
import lovestoryFormularComponents from './formular';
import { LovestoryRouting } from './lovestory.routing';
import { lovestoryOverviewComponents } from './overview';

@NgModule({
              imports:      [
                  ShareModule,
                  LovestoryModalModule,
                  LovestoryRouting,
              ],
              declarations: [
                  ...lovestoryFormularComponents,
                  ...lovestoryOverviewComponents,
              ],
          })
export class LovestoryModule {
}
