import { ApiResponseFrame } from 'BKModels';
import { PremiumDetails } from '../../../models/premium-details';
import { CancelReasonPremium } from '../../premium.service';
import { Endpoints } from '../api-endpoints';
import { ApiRequestService } from '../api-request.service';


export class PremiumApi {
    public constructor(private apiRequestService: ApiRequestService) {
    }


    public details(): Promise<PremiumDetails> {
        return this.apiRequestService
                   .createGet<ApiResponseFrame<PremiumDetails>>(Endpoints.premium.details)
                   .then(frame => PremiumDetails.create(frame.data));
    }

    public cancel(reason: CancelReasonPremium, notes: string): Promise<boolean> {
        return this.apiRequestService
                   .createPost(Endpoints.premium.cancel)
                   .then(res => res['data'].state);
    }


}

