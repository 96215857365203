import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import lovestoryFormular from './formular';

@NgModule({
              imports:      [ShareModule],
              exports:      [],
              declarations: [...lovestoryFormular],
              providers:    [],
          })
export class LovestoryProfileDeleteModule {
}
