import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { getScrollContainer } from 'BKUtils';
import {
    Subject,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConversationListDataSource } from '../../../../../models/list-data-sources';
import {
    NavigationService,
    RouteParameter,
    Routes,
} from '../../../../../service/navigation.service';
import { PoBoxService } from '../../po-box-service';

export class PostboxState {
    public static readonly previousState = new PostboxState();
    public scrollTop = 0;
    public dataSource = null;

    public reset() {
        this.scrollTop = 0;
        this.dataSource = null;
    }
}

@Component({
               selector:  'po-box-list',
               template: `
                             <loading-spinner *ngIf="isEmpty && isLoading"></loading-spinner>
                             <card class="po-box-list" *ngIf="!isEmpty">
                                 <list-container #listContainer
                                                 [dataSource]="dataSource"
                                                 [listView]="true"
                                 >
                                     <ng-template let-item>
                                         <po-box-list-chat [chat]="item.item"
                                                           (updateState)="reloadMsg()"
                                                           (navigateToChat)="toChat($event)"
                                         ></po-box-list-chat>
                                     </ng-template>
                                 </list-container>
                             </card>
                         `,
               styles: [require('./po-box-list.scss')],
           })
export class PoBoxList implements OnInit, OnDestroy {
    private _dataSource: ConversationListDataSource = null;
    @Input()
    private set dataSource(dataSource: ConversationListDataSource) {
        this._dataSource = dataSource;
        PostboxState.previousState.dataSource = this.dataSource;
    }

    get dataSource(): ConversationListDataSource {
        return this._dataSource;
    }

    private state = PostboxState.previousState;
    private unsubscribe$ = new Subject();

    constructor(
        private navigationService: NavigationService,
        private poBoxService: PoBoxService,
    ) {
    }

    ngOnInit() {
        setTimeout(() => getScrollContainer().scrollTop = this.state.scrollTop);
        getScrollContainer().addEventListener('scroll', this.onScroll);

        this.poBoxService
            .reload
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.reloadMsg();
            });
    }

    ngOnDestroy() {
        getScrollContainer().removeEventListener('scroll', this.onScroll);
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onScroll() {
        PostboxState.previousState.scrollTop = getScrollContainer().scrollTop;
    }

    get isLoading(): boolean {
        return !!this.dataSource && this.dataSource.isLoading;
    }

    get isEmpty(): boolean {
        return !!this.dataSource && this.dataSource.isEmpty;
    }

    toChat(id) {
        this.navigationService.navigateTo(Routes.MessagesConversation, {
            urlParam: { [RouteParameter.ConversationId]: id },
        });
    }

    reloadMsg() {
        if (!!this.dataSource) {
            this.dataSource.reload();
        }
    }
}
