import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { MarkdownPipe } from 'ngx-markdown';
import { BypassSanitizerPipe } from './bypass-sanitizer.pipe';
import { EmojiPipe } from './emoji.pipe';
import { EscapePipe } from './escape.pipe';
import { InlineMarkdownPipe } from './inline-markdown.pipe';
import { MessagesPipe } from './messages.pipe';
import { PluralPipe } from './plural.pipe';
import { profileInfoPipes } from './profile-info';
import { RangePipe } from './range';
import { ReplacePipe } from './replace.pipe';
import { TruncatePipe } from './truncate.pipe';
import variousPipes from './various';

const pipes: any[] = [
    TruncatePipe,
    ...profileInfoPipes,
    ...variousPipes,
    EmojiPipe,
    MessagesPipe,
    PluralPipe,
    ReplacePipe,
    EscapePipe,
    RangePipe,
    BypassSanitizerPipe,
    InlineMarkdownPipe
];

@NgModule({
              imports:      [],
              exports:      [...pipes],
              declarations: [...pipes],
              providers:    [...pipes, DatePipe],
          })
export class PipesModule {
}
