import { Injectable } from '@angular/core';
import { ModalServiceBase } from 'BKBaseClass';


export class LovestoryModal extends ModalServiceBase {
    public id: any = '';

    protected param: string = 'lovestory';

    public open(id: any) {
        this.id = id;
        super.open();
    }

}
