import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { InternComponentsModule } from '../intern-components';
import { FavoriteTabbar } from './favorite-tabbar/favorite-tabbar';
import { Favorite } from './favorite/favorite';
import { FavoriteEmpty } from './favorite-empty/favorite-empty';
import { FavoriteHeader } from './favorite-header/favorite-header';
import { FavoriteItem } from './favorite-item/favorite-item';
import { FavoriteList } from './favorite-list/favorite-list';
import { FavoriteRouting } from './favorite.routing';
import { FavoriteDelete } from './tabs/favorite-delete';
import { FavoriteExist } from './tabs/favorite-exist';

@NgModule({
              imports:      [ShareModule, FavoriteRouting, InternComponentsModule],
              exports:      [FavoriteExist, FavoriteDelete],
              declarations: [Favorite, FavoriteDelete, FavoriteExist, FavoriteList, FavoriteItem, FavoriteEmpty, FavoriteHeader, FavoriteTabbar],
              providers:    [],
          })
export class FavoriteModule {
}
