import { Component } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { ApiService } from 'BKService';
import { EmailValidator } from 'BKShared/Validator';
import { openRegistration } from 'BKUtils';
import {
    NavigationService,
    Routes,
} from '../../../service/navigation.service';

export enum SendRecoveryEmailState {
    SendMail,
    Successfull,
}

/**
 * Component for sending Recoverymail
 */
@Component({
               selector: 'bk-send-recovery-mail',
               template: `
        <bk-header-out>
            <div class="header-right">
                {{'GLOBAL.NEW' | translate }}
                <a bk-link (click)="registrationClick()"
                > {{'GLOBAL.REGISTER_NOW' | translate }}</a>
            </div>
        </bk-header-out>

        <bk-section-out>
            <div class="form-container">
                <bk-form [active]="states.SendMail == actState">
                    <form [formGroup]="recoveryForm">
                        <form-input-wrapper>
                            <h3 class="form-container__main-label">
                              {{'SEND_RECOVERY_MAIL.PASSWORD_RESET' | translate}}
                            </h3>
                        </form-input-wrapper>
                        <form-input-wrapper>
                            <p class="form-container__text">
                                {{'SEND_RECOVERY_MAIL.PLEASE_ENTER_EMAIL' | translate}}
                            </p>
                        </form-input-wrapper>

                        <form-input-wrapper>
                            <text-field id="email"
                                        type="text"
                                        trimInput
                                        [placeholder]="'SEND_RECOVERY_MAIL.EMAIL' | translate"
                                        formControlName="email"
                                        [valid]="!invalid('email')"
                                        [errorText]="getErrorMsg()"
                            ></text-field>
                        </form-input-wrapper>
                        <form-input-wrapper>
                            <raised-button
                                class="form-container__button"
                                [disabled]="isInvalid()"
                                (click)="sendEmail()"
                            >
                                {{'SEND_RECOVERY_MAIL.SEND' | translate}}
                            </raised-button>
                        </form-input-wrapper>
                        <form-input-wrapper></form-input-wrapper>

                        <form-input-wrapper class="font">
                            <a bk-link
                               [routerLink]="navigationService.getRoute(Routes.Login)"
                            >{{'GLOBAL.BACK_TO_LOGIN' |translate}}</a>
                        </form-input-wrapper>
                    </form>
                </bk-form>

                <bk-form [active]="states.Successfull == actState">
                    <form-input-wrapper>
                        <h3 class="form-container__main-label">{{'SEND_RECOVERY_MAIL.EMAIL_SENDED' | translate}}</h3>
                    </form-input-wrapper>
                    <form-input-wrapper>
                        <p>{{'SEND_RECOVERY_MAIL.PROBLEM' | translate}}</p>
                    </form-input-wrapper>
                    <form-input-wrapper>
                        <ul class="form-container__list">
                            <li>
                                {{'SEND_RECOVERY_MAIL.SOLUTIONS.0' | translate}}
                            </li>
                            <li>
                                {{'SEND_RECOVERY_MAIL.SOLUTIONS.1' | translate}}
                            </li>
                            <li>
                                {{'SEND_RECOVERY_MAIL.SOLUTIONS.2' | translate}}
                            </li>
                            <li>
                                {{'SEND_RECOVERY_MAIL.SOLUTIONS.3' | translate}}
                            </li>
                        </ul>
                    </form-input-wrapper>
                    <form-input-wrapper class="font">
                        <a bk-link
                           [routerLink]="navigationService.getRoute(Routes.Login)"
                        >{{'GLOBAL.BACK_TO_LOGIN' | translate}}</a>
                    </form-input-wrapper>
                </bk-form>
            </div>
        </bk-section-out>

    `,
               styles: [
                   require('./send-recovery-mail.style.scss'),
               ],
           })
export class SendRecoveryEmailComponent {
    private errorDescription: string;
    private isError: boolean = false;
    recoveryForm: FormGroup;
    states: any = SendRecoveryEmailState;
    actState: SendRecoveryEmailState = this.states.SendMail;
    Routes = Routes;

    /**
     *
     * @param fb
     * @param api
     * @param navigationService
     */
    public constructor(
        private fb: FormBuilder,
        private api: ApiService,
        private navigationService: NavigationService,
    ) {
        this.createForm();
    }

    private createForm() {
        this.recoveryForm = this.fb
                                .group({
                                           email: ['', [EmailValidator.email]],
                                       });
    }

    private getErrorMsg(): string {
        return `${this.errorDescription}`;
    }

    private invalid(controlName: string): boolean {
        if (!this.recoveryForm.controls.hasOwnProperty(controlName)) {
            throw new Error(`${controlName} is not a valid control`);
        }
        return this.recoveryForm.controls[controlName].errors && !this.recoveryForm.controls[controlName].pristine;
    }

    private isInvalid(): boolean {
        const email = this.recoveryForm.controls.email.errors;

        if( email !== null ){
            this.errorDescription = Object.keys(email)[0];

            return true;
        }

        return false;
    }

    private sendEmail() {
        const data = this.recoveryForm.getRawValue();
        if (this.recoveryForm.status === 'INVALID') {
            if (data.email === '') {
                this.isError = true;
                this.errorDescription = 'Bitte geben sie ihre Benutzerdaten an';
            }
        } else {
            this.api
                .password
                .sendEmail(data.email);
            this.actState = this.states.Successfull;
        }
    }

    private registrationClick() {
        openRegistration();
    }
}
