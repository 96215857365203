import {
    Component,
    Input,
} from '@angular/core';
import { Article } from '../../../../../service/rss/childs/rss-feed';


@Component({
               selector:  'settings-account-delete-glossary-article',
               template:  `
                              <div class="settings-account-delete-glossary-article" (click)="openLink()">

                                  <div>
                                      <img
                                              class="settings-account-delete-glossary-article__image"
                                              [src]="this.article.image"
                                      >

                                      <div class="settings-account-delete-glossary-article__title">
                                          {{article.title}}
                                      </div>
                                  </div>

                                  <div>
                                      <div class="settings-account-delete-glossary-article__text">
                                          {{article.text}}
                                      </div>

                                      <a class="settings-account-delete-glossary-article__link" [href]="article.link">
                                          {{'ACCOUNT.DELETE.STEP.REASON.REASON_NO_SUCCESS.READ_MORE'|translate}}
                                      </a>
                                  </div>

                              </div>
                          `,
               styles: [require('./settings-account-delete-glossary-article.scss')],
           })

export class SettingsAccountDeleteGlossaryArticle {

    @Input() private article: Article;

    public constructor() {
    }

    private openLink() {
        window.open(this.article.link, '_blank');
    }
}
