import { Component, ElementRef, Input } from '@angular/core';
import { Button } from './button';


@Component({
               selector:  'raised-button, button[raised-button]',
               template:  `
                              <button #button
                                      class="mdc-button mdc-button--raised"
                                      [disabled]="disabled"
                              >
                                  <div class="mdc-button__ripple"></div>
                                  <icon *ngIf="!leadingIcon.isEmpty()" class="mdc-button__icon"
                                        [icon]="leadingIcon"
                                  ></icon>
                                  <span class="mdc-button__label">
                                      <ng-content></ng-content>
                                  </span>
                                  <icon *ngIf="!trailingIcon.isEmpty()" class="mdc-button__icon"
                                        [icon]="trailingIcon"
                                  ></icon>
                              </button>
                          `,
               styles: [require('./raised-button.scss')],
           })
export class RaisedButton extends Button {
    @Input() private leadingIcon: string = '';
    @Input() private trailingIcon: string = '';

    public constructor(elementRef: ElementRef) {
        super(elementRef)
    }
}
