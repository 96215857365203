import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { ValueAccessorBase } from 'BKBaseClass';
import { ExtendedSearchEnumValueObject } from 'BKModels';
import * as cloneDeep from 'lodash.clonedeep';
@Component({
               selector:  'search-extended-chips',
               template:  `
                              <div class="search-extended-chips">
                                  <chip *ngFor="let key of valueKeys"
                                        [select]="innerValue[key].value"
                                        (click)="click(key)"
                                  >
                                      {{translationKey + innerValue[key].enumValue | uppercase | translate }}
                                  </chip>
                              </div>
                          
                          `,
               styles: [require('./search-extended-chips.scss')],
           })

export class SearchExtendedChips {


    private innerValue: ExtendedSearchEnumValueObject;

    private get valueKeys() {
        return Object.keys(this.innerValue)
    }

    @Input() private valueTranslation = false;
    @Input() private translationKey: string;
    @Input() private possibleValues: string[];


    @Input() private set value(val: ExtendedSearchEnumValueObject){
        this.innerValue = cloneDeep(val)
    }
    @Output() private valueChange = new EventEmitter();

    private click(key) {
        this.innerValue[key].value = !this.innerValue[key].value;
        this.valueChange.emit(this.innerValue);
    }




}
