import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector: 'system-msg-action-box',
               template: `
            <div class="action-box">
                <img [src]="image" />
                <div class="action-info">
                    <div class="action-text markdown first-last-nomargin"
                         [innerHTML]="textKey|translate:params|markdown"></div>
                    <raised-button *ngIf="premiumAction" premium (click)="action.emit()">
                        {{ actionTextKey|translate }}
                    </raised-button>
                    <raised-button *ngIf="!premiumAction" (click)="action.emit()">
                        {{ actionTextKey|translate }}
                    </raised-button>
                </div>
            </div>
    `,
    styles: [require('./system-msg-action-box.scss')]
           })

export class SystemMsgActionBox {
    @Input() image: string;
    @Input() textKey: string;
    @Input() actionTextKey: string;
    @Input() premiumAction: boolean = false;
    @Input() params = {};
    @Output() action = new EventEmitter();
}
