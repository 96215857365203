/**
 * Created by olifra on 20.06.2017.
 */
import { getImageUrl } from 'BKUtils';
import { Coordinates } from '../../utils/geo-helpers';
import {
    Image,
    ImageSize,
} from '../image';
import { Label } from '../label';
import { MatchingDecision } from '../matching';
import {
    Gender,
    Online,
} from './status';

/**
 * Class for Showing the used RealCheck Method
 */
export class EcheckMethode {
    /**
     * Check by ID-CardTitle
     {boolean}
     */
    public idCard: boolean = false;

    /**
     * Check by Picture
     {boolean}
     */
    public picture: boolean = false;

    /**
     * Check by Phone
     {boolean}
     */
    public tele: boolean = false;

    /**
     * Check by Webcam
     {boolean}
     */
    public webcam: boolean = false;
}
/**
 * Base view für user
 */

export class UserBaseView implements Coordinates{
    static SystemUserId = 3;
    static SchlagerherzenUserId = 115;

    vip = false;

    age: any;
    /**
     * Ob der user BasicView ist
     {boolean}
     */
    basicView = false;
    /**
     * city
     {string}
     */
    city = '';
    /**
     * is True if Usere Deleted
     */
    deleted = true;
    /**
     * Bitmap die Angibt wie der user validiert wurde
     * - 1 : Telefon
     * - 2 : Personalausweis
     * - 4 : Webcam
     * - 8 : Foto
     *
     {number}
     */
    echeck = 0;
    /**
     * Userid
     {id}
     */
    id = 0;
    /**
     * image
     {string}
     */
    image = '';
    /**
     * nickname
     {string}
     */
    nickname = '';

    interview = '';

    /**
     * Nachrichtenempfang erlaubt
     {boolean}
     */
    messageReceive = false;

    latitude = null;
    longitude = null;

    ageFrom = 0;
    ageTill = 0;

    food: Label[] = [];
    movies: Label[] = [];
    music: Label[] = [];

    online = Online.NotAvailable;

    matchingCriteria = false;

    decisionOwner: MatchingDecision = MatchingDecision.NoDecision;

    decisionPartner: MatchingDecision = MatchingDecision.NoDecision;

    gender: Gender = Gender.None;

    searchGender: Gender = Gender.None;

    static create(data: UserBaseView): UserBaseView {
        const res = new UserBaseView();
        res.basicView = data.basicView;
        res.city = data.city;
        res.deleted = data.deleted;
        res.echeck = data.echeck;
        res.id = data.id;
        res.vip = !!data.vip;
        res.image = data.image;
        res.nickname = data.nickname;
        res.latitude = data.latitude;
        res.longitude = data.longitude;
        res.ageFrom = data.ageFrom ?? 0;
        res.ageTill = data.ageTill ?? 0;
        res.messageReceive = data.messageReceive;
        res.age = data.age;
        res.interview = data.interview;
        res.online = data.online ?? Online.NotAvailable;
        res.matchingCriteria = data.matchingCriteria ?? false;
        res.gender = data.gender || Gender.None;
        res.searchGender = data.searchGender || Gender.None;
        res.decisionOwner = data.decisionOwner || MatchingDecision.NoDecision;
        res.decisionPartner = data.decisionPartner || MatchingDecision.NoDecision;

        return res;
    }

    static createMultiple(data: UserBaseView[]): UserBaseView[] {
        return data.map(cur => UserBaseView.create(cur));
    }

    public get isMatch(): boolean {
        return (this.isOwnMatchingDecisionYes || this.isOwnMatchingDecisionMaybe)
            && (this.isPartnerMatchingDecisionYes || this.isPartnerMatchingDecisionMaybe);
    }

    public get isNoOwnerDecision(): boolean {
        return this.decisionOwner === undefined || this.decisionOwner === MatchingDecision.NoDecision;
    }

    public get isNoPartnerDecision(): boolean {
        return this.decisionPartner === undefined || this.decisionPartner === MatchingDecision.NoDecision;
    }

    public get isOwnMatchingDecisionMaybe(): boolean {
        return this.decisionOwner === MatchingDecision.Maybe;
    }

    public get isOwnMatchingDecisionNo(): boolean {
        return this.decisionOwner === MatchingDecision.No;
    }

    public get isOwnMatchingDecisionPositive(): boolean
    {
        return this.isOwnMatchingDecisionYes || this.isOwnMatchingDecisionMaybe;
    }

    public get isOwnMatchingDecisionYes(): boolean {
        return this.decisionOwner === MatchingDecision.Yes;
    }

    public get isPartnerMatchingDecisionMaybe(): boolean {
        return this.decisionPartner === MatchingDecision.Maybe;
    }

    public get isPartnerMatchingDecisionNo(): boolean {
        return this.decisionPartner === MatchingDecision.No;
    }

    public get isPartnerMatchingDecisionYes(): boolean {
        return this.decisionPartner === MatchingDecision.Yes;
    }

    /**
     * Gibt zurrück welche Echeck Metohden der user bereits gemacht hat
     {KeyValue}
     */
    getEcheckMethode(): EcheckMethode {
        const res = new EcheckMethode();
        let val = this.echeck;
        if (val >= 8) {
            res.picture = true;
            val -= 8;
        }
        if (val >= 4) {
            res.webcam = true;
            val -= 4;
        }
        if (val >= 2) {
            res.idCard = true;
            val -= 2;
        }
        if (val === 1) {
            res.tele = true;
        }

        return res;
    }

    /**
     * Gibt Url des Profilebilds zurück
     {string} resolution
     {boolean} blur
     {string}
     */
    getProfilePictureUrl(resolution: ImageSize, blurred: boolean = false): string {
        return getImageUrl(this.image, resolution, blurred);
    }

    /**
     * Ist des nur die Basis View
     {boolean}
     */
    isBasisView(): boolean {
        return !!this.basicView;
    }

    get hasCoordinates(): boolean {
        return this.latitude !== null && this.longitude !== null;
    }

    get isSystemUser(): boolean {
        return this.id === UserBaseView.SystemUserId;
    }

    get isVerified(): boolean {
        return this.echeck > 0;
    }

    get userImage(): Image {
        return Image.createMainImage(this.image);
    }

    get exists(): boolean {
        return this.id !== undefined;
    }

    get messagingEnabled(): boolean {
        return this.messageReceive;
    }

    get isOffline(): boolean {
        return this.online === Online.Offline;
    }

    get isOnline(): boolean {
        return this.online === Online.Online;
    }

    get isAway(): boolean {
        return this.online === Online.Away;
    }

    get isOnlineMobile(): boolean {
        return this.online === Online.MobilOnline;
    }

    get isAwayMobile(): boolean {
        return this.online === Online.MobilAway;
    }

    get hasOnlineState(): boolean {
        return (this.online ?? Online.NotAvailable) !== Online.NotAvailable;
    }

    get ageRange(): string {
        const ageRanges = [
            { from: 18, to: 24 },
            { from: 25, to: 34 },
            { from: 35, to: 44 },
            { from: 45, to: 54 },
            { from: 55, to: 64 },
            { from: 65 },
        ];
        return ageRanges.filter(r => this.age >= r.from && (!r.to || this.age <= r.to))
                        .map(r => r.to ? `${r.from}-${r.to}` : `${r.from}+`)
                        .first();
    }
}
