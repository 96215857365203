import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MDCSwitch } from '@material/switch';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';


@Component({
               selector: 'switch',
               template: `
                             <div #switch class="mdc-switch" [class.mdc-switch--disabled]="disabled">
                                 <div class="mdc-switch__track"></div>
                                 <div class="mdc-switch__thumb-underlay">
                                     <div class="mdc-switch__thumb">
                                         <input type="checkbox" class="mdc-switch__native-control" [disabled]="disabled" [(ngModel)]="value">
                                     </div>
                                 </div>
                             </div>
                             <label for="basic-switch">
                                 <ng-content></ng-content>
                             </label>`,
               styles: [require('./switch.scss')],
               providers: [
                   getValueAccessorProvider(Switch),
               ],
           })

export class Switch extends ValueAccessorBase<boolean> implements AfterViewInit {
    @Input() private id = uuidv4();

    private switch: MDCSwitch;
    @ViewChild('switch', { static: true }) private switchRef: ElementRef<HTMLElement>;

    @Input()
    public get value(): boolean {
        return this.innerValue;
    }
    public set value(value: boolean) {
        if(this.switch)this.switch.checked = value;
        this.writeValue(value);
    }

    public ngAfterViewInit() {
        this.switch = new MDCSwitch(this.switchRef.nativeElement);
        this.switch.checked = this.value;
    }
}
