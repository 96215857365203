import {
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

export class ValueBase<T> {
    @Output() protected valueChange: EventEmitter<any> = new EventEmitter();

    protected _value: T;

    @Input()
    protected get value(): T {
        return this._value;
    }

    protected set value(val: T) {
        this._value = val;
        this.valueChange.emit(val);
    }
}
