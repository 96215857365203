import { Component } from '@angular/core';
import { DetailedConversationUnreadCounts } from 'BKModels';
import {
    ApiService,
    CurrentUserService,
} from 'BKService';

@Component({
               selector:  'po-box-header',
               template: `
                             <intern-default-header [title]="'LAYOUT.SIDE_NAV.MESSAGES' | translate"
                                                    [closeable]="true"
                             >
                                 <po-box-header-tabbar [counts]="conversationCounts"></po-box-header-tabbar>
                             </intern-default-header>
                         `,
               styles: [require('./po-box-header.scss')],
           })

export class PoBoxHeader {
    conversationCounts: DetailedConversationUnreadCounts = new DetailedConversationUnreadCounts();

    public constructor(private currentUserService: CurrentUserService, private apiService: ApiService) {
    }

    private ngOnInit()
    {
        this.apiService.conversation.fetchDetailedConversationCounts().then(counts => {
            this.conversationCounts = counts;
            return counts;
        });
    }

}
