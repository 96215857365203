import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

//@TOO rename
@Component({
               selector: 'premium-support',
               template: `
                             <div class="premium-support">
                                 <div class="premium-support__title" *ngIf="showTitle">{{'SUPPORT_FOOTER.TITLE' | translate}}</div>
                                 <div class="premium-support__content" [class.premium-support__content--no-margin]="noMargin">
                                     <div class="premium-support__content__phone" [class.premium-support__content__phone--no-margin]="noMargin">
                                         <icon class="premium-support__content__icon" icon="phone"></icon>
                                         <div class="premium-support__content__text">
                                             <span (click)="clickPhoneNumber.emit()" [innerHTML]="'SUPPORT_FOOTER.PHONE.LINK' | translate | markdown"></span>
                                             <span [innerHTML]="'SUPPORT_FOOTER.PHONE.TIME' | translate | markdown"></span>
                                         </div>
                                         
                                     </div>
                                     <div class="premium-support__content__mail" *ngIf="showEmail">
                                         <icon class="premium-support__content__icon" icon="mail-outline"></icon>
                                         <div class="premium-support__content__text" (click)="clickMail.emit()" [innerHTML]="'SUPPORT_FOOTER.MAIL' | translate | markdown"></div>
                                     </div>
                                 </div>
                             </div>`,
               styles: [require('./premium-support.scss')],
               encapsulation: ViewEncapsulation.None,
           })
export class PremiumSupport {
    @Input() private showTitle: boolean = true;
    @Input() private showEmail: boolean = true;
    @Input() private noMargin: boolean = false;
    @Output() private clickPhoneNumber = new EventEmitter();
    @Output() private clickMail = new EventEmitter();
}
