import { NgModule } from '@angular/core';
import { AppServiceModule } from 'BKService';
import { ShareModule } from 'BKShared';
import { ImageUploadModal } from '../../../shared/mat-design/image-upload-modal/image-upload-modal';
import { ImgUpload } from './img-upload/img-upload';

const components = [ImgUpload];

@NgModule({
              imports:      [
                  ShareModule,
                  AppServiceModule,
              ],
              exports:      components,
              declarations: components,
              providers:    [],
          })
export class InternComponentsModule {
}
