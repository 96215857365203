import { SystemMsgActionBox } from './system-msg-action-box/system-msg-action-box';
import { SystemMsgBirthday } from './system-msg-birthday/system-msg-birthday';
import { SystemMsgFavBirthday } from './system-msg-fav-birthday/system-msg-fav-birthday';
import { SystemMsgHtml } from './system-msg-html/system-msg-html';
import { SystemMsgLayout } from './system-msg-layout/system-msg-layout';
import { SystemMsgWelcome } from './system-msg-welcome/system-msg-welcome';

import { SystemMsg } from './system-msg/system-msg';

export default [
    SystemMsg,
    SystemMsgActionBox,
    SystemMsgLayout,
    SystemMsgWelcome,
    SystemMsgBirthday,
    SystemMsgFavBirthday,
    SystemMsgHtml,
];
