import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    CurrentUser,
    UserBaseView,
} from 'BKModels';
import { ModalService } from 'BKService';
import { ModalDialog } from '../../../service/global-modal.service';
import { DialogBox } from '../dialog/dialog-box';

@Component({
            selector: 'bk-modal-schlagerherzen-error',
            template: `
                      <ng-container>
                          <dialog-box #dialogBox
                                      [title]="'SCHLAGERHERZEN.ERROR.TITLE'|translate"
                                      [acceptText]="'SCHLAGERHERZEN.ERROR.ACTION'|translate"
                                      [rejectText]="'SCHLAGERHERZEN.ERROR.ACTION.CLOSE'|translate"
                                      (onAccept)="openSupportProfile()"
                                      (onReject)="close()"
                          >
                              {{ 'SCHLAGERHERZEN.ERROR.TEXT'|translate }}
                          </dialog-box>
                      </ng-container>
                      `,
            styles: [require('./bk-modal-schlagerherzen-application.scss')]
           })
export class BkModalSchlagerherzenError implements ModalDialog {
    @ViewChild('dialogBox', { static: true }) dialogBox: DialogBox;

    static readonly typeId = 'BkModalSchlagerherzenError';

    constructor(private modalService: ModalService) {}

    public open(config) {
        this.dialogBox.open();
    }

    public close() {
        this.dialogBox.close();
    }

    public openSupportProfile() {
        this.close();
        this.modalService.profileView.open(UserBaseView.SchlagerherzenUserId);
    }

    public get typeId(): string {
        return BkModalSchlagerherzenError.typeId;
    }
}