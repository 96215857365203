import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({
          name: 'escape',
      })

export class EscapePipe implements PipeTransform {
    public transform(value: string): string {
        return value.replace(new RegExp('&quot;', 'g'), '"')
                    .replace(/\$host|bildkontakte(\.de)?/gi, 'bildkontakte');
    }
}
