import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

@Component({
               selector:  'cancel-list-header',
               template:  `
                              <div class="cancel-list-header">
                                  <div class="cancel-list-header__line">
                                      <icon icon="remove-circle-outline" class="cancel-list-header__icon"></icon>
                                      <a *ngIf="!noLink" (click)="action.emit($event)" class="cancel-list-header__title">{{ titleText }}</a>
                                      <div *ngIf="noLink" class="cancel-list-header__title">{{ titleText }}</div>
                                  </div>
                                  <ng-content></ng-content>
                              </div>
                          `,
               styles: [require('./cancel-list-header.scss')],
           })

export class CancelListHeader {
    @Input() private titleText: string = '';
    @Input() private noLink: boolean = false;
    @Output() private action = new EventEmitter();
}
