import { NgModule } from '@angular/core';
import { ShareModule } from 'BKShared';
import { SchlagerparadiesOverview } from './overview/schlagerparadies-overview';
import { SchlagerparadiesRouting } from './schlagerparadies.routing';
import { SchlagerparadiesSingleList } from './single-list/schlagerparadies-single-list';

@NgModule({
              imports: [
                  ShareModule,
                  SchlagerparadiesRouting
              ],
              declarations: [
                  SchlagerparadiesOverview,
                  SchlagerparadiesSingleList
              ],
              entryComponents: [],
              exports: [],
              providers: [],
          })
export class SchlagerparadiesModule {
}
