import {
    Component,
    Input,
} from '@angular/core';
import { AccountStateIcon } from '../account-menu/account-menu';


@Component({
               selector: 'account-menu-item',
               template: `
        <div class="account-menu-item">
            <div class="account-menu-item__logo">
                <icon class="account-menu-item__logo__icon" [icon]="icon"></icon>
                <div class="account-menu-item__logo__circle" *ngIf="showState !== AccountStateIcon.None"
                     [class.account-menu-item__logo__circle-notice]="showState === AccountStateIcon.Notice"
                ></div>
            </div>
            <div class="account-menu-item__title">{{label}}</div>
        </div>
    `,
               styles: [require('./account-menu-item.scss')],
           })

export class AccountMenuItem {
    @Input() private icon: string;
    @Input() private label: string;
    @Input() private showState = AccountStateIcon.None;

    private AccountStateIcon = AccountStateIcon;
}
