import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MDCCheckbox } from '@material/checkbox';
import { MDCFormField } from '@material/form-field';
import {
    getValueAccessorProvider,
    ValueAccessorBase,
} from 'BKBaseClass';
import { uuidv4 } from 'BKUtils';

@Component({
               selector: 'checkbox',
               template: `
        <div class="mdc-form-field">
            <div class="mdc-checkbox">
                <input type="checkbox"
                       class="mdc-checkbox__native-control"
                       [id]="id"
                       [(ngModel)]="value"
                />
                <div class="mdc-checkbox__background">
                    <svg class="mdc-checkbox__checkmark"
                         viewBox="0 0 24 24"
                    >
                        <path class="mdc-checkbox__checkmark-path"
                              fill="none"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"
                        />
                    </svg>
                    <div class="mdc-checkbox__mixedmark"></div>
                </div>
            </div>
            <label (click)="toggleValue()">
                <ng-content></ng-content>
            </label>
        </div>`,
               styles: [require('./checkbox.scss')],
               providers: [
                   getValueAccessorProvider(Checkbox),
               ],
           })

export class Checkbox extends ValueAccessorBase<boolean> implements AfterViewInit {
    @ViewChild('checkbox', { static: true }) private checkboxRef: ElementRef;
    @ViewChild('formField', { static: true }) private formFieldRef: ElementRef<HTMLElement>;
    @Input() private id: string = uuidv4();

    public ngAfterViewInit() {
        const checkbox = new MDCCheckbox(document.querySelector('.mdc-checkbox'));
        const formField = new MDCFormField(document.querySelector('.mdc-form-field'));
        formField.input = checkbox;
    }

    private toggleValue() {
        this.value = !this.value;
    }
}
