import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import {
    ImageSize,
    MatchingDecision,
    ProfileInfo,
    UserBaseView,
} from 'BKModels';
import {
    CurrentUserService,
    ModalService,
} from 'BKService';
import { FavoriteService } from '../../../service/favorite.service';
import { GlobalModalService } from '../../../service/global-modal.service';
import { NavigationService } from '../../../service/navigation.service';
import { BkModalProfilePictureMessage } from '../bk-modals/bk-modal-profile-picture-message';
import { BkModalWriteMessage } from '../bk-modals/bk-modal-write-message';

@Component({
               selector: 'list-default-user-item',
               template: `
                              <div
                                      class="mdc-card list-item"
                                      [class.list-item--basis]="user.isBasisView()"
                              >
                                  <div class="mdc-card__primary-action" tabindex="0" (click)="openProfileView()">
                                      <div class="mdc-card__media list-item__media">
                                          <img-with-loading-indicator class="list-item__media__image" [src]="imageUrl"></img-with-loading-indicator>
                                          <icon *ngIf="user.isBasisView()" icon="crown-outline" class="list-item__media__crown"></icon>
                                          <icon *ngIf="user.matchingCriteria" class="loyalty" icon="loyalty"></icon>
                                          <div class="list-item__heartbreak-icon" *ngIf="partnerPositiveDecision && !ownerPositiveDecision"></div>
                                      </div>
                                      <div *ngIf="user.vip" class="list-item__badge">
                                          <img class="list-item__badge__image" src="./assets/img/VipBannerIcon.svg">
                                      </div>

                                      <div class="list-item__primary" *ngIf="!user.isBasisView()">
                                          <div class="list-item__title">
                                              <online-state *ngIf="user.hasOnlineState && !user.isOffline"
                                                 type="circle"
                                                 class="list-item__online-state"
                                                 [state]="user.online"
                                                ></online-state>
                                              <span><span class="list-item__title__name">{{user.nickname}},</span> {{user.age}}</span>
                                              
                                              <icon *ngIf="hasVerifiedState && isVerified" class="list-item__verified-icon" icon="verified-user"></icon>
                                          </div>
                                          <div class="list-item__subtitle">{{user.city}}</div>
                                      </div>

                                      <div class="list-item__primary" *ngIf="user.isBasisView()">
                                          <div class="list-item__title">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                          <div class="list-item__subtitle">
                                              <span class="list-item--basis__data_disabled">&nbsp;</span>
                                          </div>
                                      </div>

                                  </div>
                                  <div class="mdc-card__actions list-item__action">
                                      <div class="mdc-card__action-buttons">
                                          <flat-button [disabled]="user.isBasisView()" (click)="openProfileView()">{{ 'LIST.TO_PROFILE'|translate }}</flat-button>
                                      </div>
                                      <div class="mdc-card__action-icons">
                                          <icon [icon]="(hasFavoriteState && isFavorite) ? 'star' : 'star-border'" class="list-item__action-icon" (click)="toggleFavorite()"></icon>
                                          <icon icon="chat-outline" class="list-item__action-icon" (click)="openConversation()"></icon>
                                      </div>
                                  </div>
                              </div>
                          `,
               styles: [require('./list-default-user-item.scss')],
           })
export class ListDefaultUserItem {
    @Input() user: UserBaseView = new UserBaseView();
    @Output() gotoPremium = new EventEmitter();
    @Output() onOpenProfile = new EventEmitter();
    @Output() onAddFavorite = new EventEmitter();
    @Output() onDeleteFavorite = new EventEmitter();
    @Output() onOpenConversation = new EventEmitter();

    get ownerPositiveDecision(): boolean {
        const decisionOwner = this.profileInfo?.decisionOwner || MatchingDecision.NoDecision;

        return decisionOwner === MatchingDecision.Yes || decisionOwner === MatchingDecision.Maybe;
    }

    get partnerPositiveDecision(): boolean {
        const decisionPartner = this.profileInfo?.decisionPartner || MatchingDecision.NoDecision;

        return decisionPartner === MatchingDecision.Yes || decisionPartner === MatchingDecision.Maybe;
    }

    get imageUrl(): string {
        return this.user.getProfilePictureUrl(ImageSize['530x530']);
    }

    get profileInfo(): ProfileInfo {
        return <ProfileInfo>this.user;
    }

    private get isVerified(): boolean {
        return this.user.isVerified;
    }

    get hasVerifiedState(): boolean {
        return !this.user.isBasisView();
    }

    get hasFavoriteState(): boolean {
        return !this.user.isBasisView();
    }

    get isFavorite(): boolean {
        return !!this.profileInfo.favorite;
    }

    get canOpenConversation(): boolean {
        return !this.user.isBasisView();
    }

    constructor(
        private modalService: ModalService,
        private navigationService: NavigationService,
        private favoriteService: FavoriteService,
        private currentUserService: CurrentUserService,
        private globalModalService: GlobalModalService,
    ) {
    }

    openProfileView() {
        if (this.user.isBasisView()) {
            this.toPremium();
            return;
        }

        this.onOpenProfile.emit();
        this.modalService.profileView.open(this.user.id);
    }

    private toPremium() {
        this.gotoPremium.emit();
    }

    toggleFavorite() {
        if (this.user.isBasisView()) {
            this.toPremium();
            return;
        }

        if (this.isFavorite) {
            this.favoriteService.remove(this.user).then(() => {
                this.profileInfo.favorite = false;
                this.onDeleteFavorite.emit();
            });
        } else {
            this.favoriteService.add(this.user).then(() => {
                this.profileInfo.favorite = true;
                this.onAddFavorite.emit();
            });
        }
    }

    openConversation() {
        if (this.user.isBasisView()) {
            this.toPremium();
            return;
        }

        this.currentUserService
            .currentUser
            .then((currentUser) => {
                if (!currentUser.profileImgExist()) {
                    this.globalModalService.open(BkModalProfilePictureMessage.typeId);
                    return;
                }

                this.globalModalService.open(BkModalWriteMessage.typeId, { currentUser: currentUser, user: this.user });
            });

        this.onOpenConversation.emit();
    }
}
