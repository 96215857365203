import {
    ActivatedRoute,
} from '@angular/router';
import { QueryParameter } from '../service/navigation.service';

// eslint-disable-next-line import/export
export enum OrderReason {
    None = 0,
    ProfileVisitor = 310,
    NewUser = 311,
    NearByUser = 312,
    NoAdvertising = 313,
    FavoriteBirthday = 314,
    MatchPartner = 315,
    WishPartner = 316,
    WantToKnowYou = 317,
    AllImages = 318,
    ExtendedSearch = 319,
    SearchRadius = 320,
    ReadingState = 321,
    IncognitoMode = 322,
    HideOnlineState = 323,
    UnlimitedMatching = 324,
    MatchingSeeMatchpartner = 325,
    OlderMessages = 326,
    MatchingOpenProfile = 327,
    PremiumServiceOverview = 328,
    SelectedUser = 329,
    RealUser = 330,
    SelectedUserAccountDelete = 331,
    PremiumExpiration = 332,
    LegacyWelcomeOffer = 333,
    LegacyWelcomeDelayedOffer = 334,
    ExtendPremium = 335,
    RecurringOffer = 336,
    ActivityList = 337,
    ActivityListPreview = 338,
    ChangeNegativeMatchDecision = 339,
    BirthdayOffer = 340,
    LoyaltyOffer = 341,
    WantToKnowYouProfile = 342,
    ChangeDecisionToYesProfile = 343,
}

// eslint-disable-next-line import/export,no-redeclare
export namespace OrderReason {
    export const getQueryString = (orderReason: OrderReason): string => `${orderReason}-${OrderReason[orderReason]}`;

    export const fromQueryString = (queryString: string): OrderReason => {
        const queryReason = queryString.split('-').first().toInt();
        if(!Object.values(OrderReason).includes(queryReason)) return OrderReason.None;
        return queryReason;
    };

    export const fromActivatedRoute = (activatedRoute: ActivatedRoute): OrderReason => {
        const paramMap = activatedRoute.snapshot.queryParamMap;
        if(paramMap.has(QueryParameter.OrderReason)) {
            const queryString = paramMap.get(QueryParameter.OrderReason);
            return fromQueryString(queryString);
        }
        return OrderReason.None;
    };
}
