
import {
    AfterViewInit,
    Component,
} from '@angular/core';
import {
    CurrentUserService,
    TrackingService,
} from 'BKService';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../../../service/navigation.service';

@Component({
               selector: 'system-msg-welcome',
               template: `
        <system-msg-layout>
            <div class="headline markdown p-nomargin"
                 [innerHTML]="'MESSAGE.SYSTEM.WELCOME.TITLE'|translate:{'username': (currentUserService.currentUserObservable|async).nickname}|markdown"></div>
            <div class="image">
                <img src="./assets/img/picHeartFirework.svg" />
            </div>
            <div class="text markdown first-last-nomargin checkbox-list" 
                 [innerHTML]="'MESSAGE.SYSTEM.WELCOME.TEXT'|translate|markdown"></div>

            <separator thickness="1px"></separator>
            <system-msg-action-box image="./assets/img/iconUpload.svg"
                                   textKey="MESSAGE.SYSTEM.WELCOME.ACTION.UPLOAD_PICTURE.TEXT"
                                   actionTextKey="MESSAGE.SYSTEM.WELCOME.ACTION.UPLOAD_PICTURE.ACTION"
                                   (action)="profileImageCta()"></system-msg-action-box>
            <separator thickness="1px"></separator>
            <system-msg-action-box image="./assets/img/iconEdit.svg"
                                   textKey="MESSAGE.SYSTEM.WELCOME.ACTION.PROFILE_EDIT.TEXT"
                                   actionTextKey="MESSAGE.SYSTEM.WELCOME.ACTION.PROFILE_EDIT.ACTION"
                                   (action)="profileEditCta()"></system-msg-action-box>

        </system-msg-layout>
    `,
    styles: [require('../system-msg-common.scss')]
           })

export class SystemMsgWelcome implements AfterViewInit {
    constructor(
        private currentUserService: CurrentUserService,
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {}

    public ngAfterViewInit(): void {
        this.trackingService.hit('conversation', 'View', 'system:willkommensnachricht');
    }

    profileImageCta() {
        this.trackingService.hit('conversation', 'Klick: Bild hochladen', 'system:willkommensnachricht');
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Images });
    }

    profileEditCta() {
        this.trackingService.hit('conversation', 'Klick: Profil ausfüllen', 'system:willkommensnachricht');
        this.navigationService.navigateTo(Routes.MyProfile, { fragment: ProfileEditRouteFragment.Look });
    }
}
