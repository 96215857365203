import {
    Component,
    ViewChild,
} from '@angular/core';
import {
    ActivatedRoute,
    Params,
} from '@angular/router';
import { ApiResponseFrame } from 'BKModels';
import { ValidationService } from 'BKService';
import {
    GlobalModalService,
    ModalDialog,
} from '../../../service/global-modal.service';
import {
    NavigationService,
    ProfileEditRouteFragment,
    Routes,
} from '../../../service/navigation.service';
import { BkModal } from './bk-modal';

@Component({
               selector: 'bk-modal-reg-code',
               template: `
                             <ng-container>
                                 <bk-modal #bkModal
                                           imageSource="/assets/img/premium-crown-with-decals.svg"
                                           titleKey="MODAL.REG_CODE.MESSAGE.TITLE"
                                           textKey="MODAL.REG_CODE.MESSAGE.TEXT"
                                           actionTextKey="MODAL.REG_CODE.MESSAGE.ACTION"
                                           (action)="action()"
                                           [closeViaLink]="false"
                                 ></bk-modal>
                             </ng-container>
                         `,
           })
export class BkModalRegCode implements ModalDialog {
    @ViewChild('bkModal', { static: true }) private bkModal: BkModal;

    static readonly typeId = 'BkModalRegCode';

    constructor(
        private navigationService: NavigationService, private activeRoute: ActivatedRoute, private modalService: GlobalModalService, private validationService: ValidationService,
    ) {
    }

    ngAfterViewInit() {
        this.activeRoute.queryParams.subscribe((param: Params) => {
            if (param.hasOwnProperty('regCode')) {
                this.validationService.validateRegCode(param['regCode']).then((response) => {
                    if (response.state && response.promotion) {
                        this.modalService.open(this.typeId);
                    }
                });

            }
        });
    }

    public action() {
        this.close();
        this.navigationService.navigateTo(Routes.PremiumOverview, { fragment: ProfileEditRouteFragment.Images });
    }

    public open(config) {
        this.bkModal.open();
    }

    public close() {
        this.bkModal.close();
    }

    public get typeId(): string {
        return BkModalRegCode.typeId;
    }
}
