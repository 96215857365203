/* eslint-disable camelcase */

import { OrderReason } from './order-reason';

export class BillingAddress {
    public city: string = '';

    public forename: string = '';

    public house_number: string = '';

    public phone_number: string = '';

    public postal_code: string = '';

    public street: string = '';

    public surname: string = '';

    public static create(data: BillingAddress): BillingAddress {
        const res = new BillingAddress();
        res.forename = data.forename || '';
        res.surname = data.surname || '';
        res.street = data.street || '';
        res.house_number = data.house_number || '';
        res.city = data.city || '';
        res.postal_code = data.postal_code || '';
        res.phone_number = data.phone_number || '';

        return res;
    }

    public isEmpty(): boolean {
        return this.city.isEmpty() && this.forename.isEmpty() &&
            this.house_number.isEmpty() && this.phone_number.isEmpty() &&
            this.postal_code.isEmpty() && this.street.isEmpty() &&
            this.surname.isEmpty();
    }

    public equal(other: BillingAddress): boolean {
        return this.city === other.city &&
            this.forename === other.forename &&
            this.house_number === other.house_number &&
            this.phone_number === other.phone_number &&
            this.postal_code === other.postal_code &&
            this.surname === other.surname &&
            this.street === other.street;
    }
}

export class DebitRequest {
    public constructor(
        public tariff_id: number = null,
        public billing_address: BillingAddress = new BillingAddress(),
        public sepa_account: SepaAccount = new SepaAccount(),
        public order_reason: OrderReason = OrderReason.None,
        public offer_id: string = '',
    ) {
        if (this.sepa_account.account_holder.isEmpty()) this.sepa_account.account_holder = `${this.billing_address.forename} ${this.billing_address.surname}`;
        if (offer_id === null) {
            this.offer_id = '';
        }
    }
}

export class SepaAccount {
    public account_holder: string = '';

    public bic: string = '';

    public iban: string = '';

    public static create(data: SepaAccount): SepaAccount {
        const res = new SepaAccount();
        res.account_holder = data.account_holder || '';
        res.bic = data.bic || '';
        res.iban = data.iban || '';
        return res;
    }

    public isEmpty(): boolean {
        return this.account_holder.isEmpty() && this.bic.isEmpty() && this.iban.isEmpty();
    }

    public equal(other: SepaAccount): boolean {
        return this.account_holder === other.account_holder &&
            this.bic === other.bic &&
            this.iban === other.iban;
    }
}

export interface CashReference {
    intendedPurpose: string
}

