import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MDCRipple } from '@material/ripple';
import { TrackingService } from 'BKService';
import {
    NavigationService,
    Routes,
} from '../../../../../service/navigation.service';

/**
 * Items der Seitennavigation
 */
@Component({
               selector:  'bk-side-nav-item',
               template:  `
                              <div class="side-nav-item mdc-ripple-surface mdc-ripple-surface--primary"
                                   [routerLink]="path"
                                   (click)="trackClick()"
                                   routerLinkActive="side-nav-item--active router-link-active"
                                   #item
                              >
                                  <div class="side-nav-item__glyph">
                                      <ng-content select="[glyph]"></ng-content>
                                  </div>

                                  <div class="side-nav-item__text">
                                      <ng-content></ng-content>
                                  </div>

                                  <badge [count]="badgeCount" class="side-nav-item__badge"></badge>
                              </div>
                          `,
               styles: [require('./side-nav-item.scss')],
           })
export class SideNavItem implements AfterViewInit {
    @ViewChild('item', { static: true }) private itemRef: ElementRef;
    @Input() private route: Routes = Routes.Empty;
    @Input() private trackingLabel: string;
    private ripple: MDCRipple;
    @Input() badgeCount: number = 0;

    get path(): string[] {
        return this.navigationService.getRoute(this.route);
    }

    public constructor(
        private navigationService: NavigationService,
        private trackingService: TrackingService
    ) {
    }

    public ngAfterViewInit() {
        this.ripple = new MDCRipple(this.itemRef.nativeElement);
    }

    trackClick() {
        this.trackingService.hit('LinkeNavi', 'TabGeklickt', this.trackingLabel);
    }
}
